import { MainActions, MainActionTypes } from './main.actions';
import { MainStateInterface, MenuInterface } from './main.model';

export const initialState: MainStateInterface = {
  getActiveSitesLoading: false,
  getActiveSitesLoaded: false,
  getNavigationMenuLoading: false,
  getNavigationMenuLoaded: false,
  sendContactSupportLoading: false,
  authorizedPages: [],
  menu: { menus: [], clientLogo: '', singleSiteAndLine: false },
  activeSites: [],
  clientLogo: null,
  singleSiteAndLine: false,
  getCustomMailGroupsLoading: false,
  getCustomMailGroupsLoaded: false,
  customMailGroups: [],
  allowedDomainsLoading: false,
  allowedDomainsLoaded: false,
  allowedDomains: [],
  activeSiteModules: {},
};

export function mainReducer(state = initialState, action: MainActions): MainStateInterface {
  switch (action.type) {
    case MainActionTypes.GetActiveSites:
      return {
        ...state,
        getActiveSitesLoading: true,
        getActiveSitesLoaded: false,
      };
    case MainActionTypes.GetActiveSitesCompleted:
      return {
        ...state,
        getActiveSitesLoading: false,
        getActiveSitesLoaded: true,
        activeSites: action.data,
      };
    case MainActionTypes.SetSiteModuleStatuses:
      return {
        ...state,
        activeSiteModules: action.data,
      };
    case MainActionTypes.GetNavigationMenu:
      return {
        ...state,
        getNavigationMenuLoading: true,
        getNavigationMenuLoaded: false,
      };

    case MainActionTypes.GetNavigationMenuCompleted:
      return {
        ...state,
        getNavigationMenuLoading: false,
        getNavigationMenuLoaded: true,
        authorizedPages: action.data.menus
          .filter((node: MenuInterface) => node.link !== '#' && !node.external)
          .map((node: MenuInterface) => node.link),
        menu: action.data,
        clientLogo: action.data.clientLogo,
        singleSiteAndLine: action.data.singleSiteAndLine,
      };

    case MainActionTypes.SendContactSupport:
      return {
        ...state,
        sendContactSupportLoading: true,
      };

    case MainActionTypes.SendContactSupportCompleted:
      return {
        ...state,
        sendContactSupportLoading: false,
      };

    case MainActionTypes.GetCustomMailGroups:
      return {
        ...state,
        ...{
          getCustomMailGroupsLoading: true,
          getCustomMailGroupsLoaded: false,
        },
      };

    case MainActionTypes.GetCustomMailGroupsCompleted:
      return {
        ...state,
        ...{
          customMailGroups: action.data,
          getCustomMailGroupsLoading: false,
          getCustomMailGroupsLoaded: true,
        },
      };

    case MainActionTypes.AllowedDomains:
      return {
        ...state,
        ...{
          allowedDomainsLoading: true,
          allowedDomainsLoaded: false,
        },
      };

    case MainActionTypes.AllowedDomainsCompleted:
      return {
        ...state,
        ...{
          allowedDomainsLoading: false,
          allowedDomainsLoaded: true,
          allowedDomains: action.data,
        },
      };

    default:
      return state;
  }
}
