import { Action } from '@ngrx/store';
import { DropdownOptionInterface } from '../../../shared/component/scw-mat-ui/scw-mat-select/scw-mat-select.model';
import { EMyReportMetricBreakdown } from '../../../view/reports/my-reports/my-report-metrics/my-report-metrics.model';

export enum FilterMyReportBreakdownActionTypes {
  SetFilterBreakdownOptions = '[Filter My Report Breakdown] Set Filter Breakdown Options',
  AddOption = '[Filter My Report Breakdown] Add Option',
  RemoveOption = '[Filter My Report Breakdown] Remove Option',
}

export class SetFilterBreakdownOptions implements Action {
  readonly type = FilterMyReportBreakdownActionTypes.SetFilterBreakdownOptions;
  constructor(public options: DropdownOptionInterface[]) {}
}

export class AddOption implements Action {
  readonly type = FilterMyReportBreakdownActionTypes.AddOption;
  constructor(public option: DropdownOptionInterface, public index: number) {}
}

export class RemoveOption implements Action {
  readonly type = FilterMyReportBreakdownActionTypes.RemoveOption;
  constructor(public id: EMyReportMetricBreakdown) {}
}

export type FilterMyReportBreakdownActions = SetFilterBreakdownOptions | AddOption | RemoveOption;
