import { Action } from '@ngrx/store';
import {
  BaseCrudResponse,
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../../../shared/model/interface/crud-response-interface.model';
import {
  IAddEditNotificationTemplate,
  INotificationTemplateCards,
  INotificationTemplate,
} from './notification-templates.model';
import { SiteCRUDInterface } from '../../../../shared/component/filter/filter.class';
import { GenericCrudRequestConstructionParameters } from '../../../../shared/model/interface/generic-api-request.model';

export const NOTIFICATION_TEMPLATE_LIST_DATA_LOADING = '[NOTIFICATION_TEMPLATE] NOTIFICATION TEMPLATE DATA LOADING';
export const NOTIFICATION_TEMPLATE_LIST_DATA_LOADED = '[NOTIFICATION_TEMPLATE] NOTIFICATION TEMPLATE DATA LOADED';
export const NOTIFICATION_TEMPLATE_SITES_DATA_LOADING =
  '[NOTIFICATION_TEMPLATE_SITES_DATA_LOADING] NOTIFICATION SITE DATA LOADING';
export const NOTIFICATION_TEMPLATE_SITES_DATA_LOADED =
  '[NOTIFICATION_TEMPLATE_SITES_DATA_LOADED] NOTIFICATION SITE DATA LOADED';
export const ADD_NOTIFICATION_TEMPLATE = '[ADD_NOTIFICATION_TEMPLATE] ADD NOTIFICATION TEMPLATE';
export const ADD_NOTIFICATION_TEMPLATE_COMPLETED =
  '[ADD_NOTIFICATION_TEMPLATE_COMPLETED] ADD NOTIFICATION TEMPLATE COMPLETED';
export const EDIT_NOTIFICATION_TEMPLATE_LOADING =
  '[EDIT_NOTIFICATION_TEMPLATE_LOADING] EDIT NOTIFICATION TEMPLATE LOADING';
export const EDIT_NOTIFICATION_TEMPLATE_COMPLETED =
  '[EDIT_NOTIFICATION_TEMPLATE_COMPLETED] EDIT NOTIFICATION TEMPLATE COMPLETED';
export const DELETE_NOTIFICATION_TEMPLATES = '[DELETE_NOTIFICATION_TEMPLATES] DELETE NOTIFICATION TEMPLATES';
export const DELETE_NOTIFICATION_TEMPLATES_COMPLETED =
  '[DELETE_NOTIFICATION_TEMPLATES_COMPLETED] DELETE NOTIFICATION TEMPLATES COMPLETED';
export const DELETE_NOTIFICATION_TEMPLATE = '[DELETE_NOTIFICATION_TEMPLATE] DELETE NOTIFICATION TEMPLATE';
export const DELETE_NOTIFICATION_TEMPLATE_COMPLETED =
  '[DELETE_NOTIFICATION_TEMPLATE_COMPLETED] DELETE NOTIFICATION TEMPLATE COMPLETED';
export const NOTIFICATION_TEMPLATES_CARDS_LOADING =
  '[NOTIFICATION_TEMPLATES_CARDS_LOADING] NOTIFICATION TEMPLATES CARDS LOADING';
export const NOTIFICATION_TEMPLATES_CARDS_LOADED =
  '[NOTIFICATION_TEMPLATES_CARDS_LOADED] NOTIFICATION TEMPLATES CARDS LOADED';
export const NOTIFICATION_TEMPLATE_FETCH_ERROR =
  '[NOTIFICATION_TEMPLATE_FETCH_ERROR] NOTIFICATION TEMPLATE FETCH ERROR';

export class NotificationTemplateListDataLoading implements Action {
  readonly type = NOTIFICATION_TEMPLATE_LIST_DATA_LOADING;

  constructor(public params: GenericCrudRequestConstructionParameters) {}
}

export class NotificationTemplateListDataLoaded implements Action {
  readonly type = NOTIFICATION_TEMPLATE_LIST_DATA_LOADED;

  constructor(public payload: GetManyResponseInterface<INotificationTemplate>) {}
}

export class NotificationTemplateSiteDataLoading implements Action {
  readonly type = NOTIFICATION_TEMPLATE_SITES_DATA_LOADING;
}

export class NotificationTemplateSiteDataLoaded implements Action {
  readonly type = NOTIFICATION_TEMPLATE_SITES_DATA_LOADED;

  constructor(public payload: ({ id: number; name: string } & Partial<SiteCRUDInterface>)[]) {}
}

export class AddNotificationTemplate implements Action {
  readonly type = ADD_NOTIFICATION_TEMPLATE;

  constructor(public notificationTemplate: IAddEditNotificationTemplate) {}
}

export class AddNotificationTemplateCompleted implements Action {
  readonly type = ADD_NOTIFICATION_TEMPLATE_COMPLETED;

  constructor(public payload: BaseOneResponseInterface<IAddEditNotificationTemplate>) {}
}

export class EditNotificationTemplate implements Action {
  readonly type = EDIT_NOTIFICATION_TEMPLATE_LOADING;

  constructor(public notificationTemplate: IAddEditNotificationTemplate, public id: number) {}
}

export class EditNotificationTemplateCompleted implements Action {
  readonly type = EDIT_NOTIFICATION_TEMPLATE_COMPLETED;

  constructor(public payload: BaseOneResponseInterface<IAddEditNotificationTemplate>) {}
}

export class DeleteNotificationTemplates implements Action {
  readonly type = DELETE_NOTIFICATION_TEMPLATES;

  constructor(public payload: number[]) {}
}

export class DeleteNotificationTemplatesCompleted implements Action {
  readonly type = DELETE_NOTIFICATION_TEMPLATES_COMPLETED;

  constructor(public response: { id: number; errorMessages?: string }[]) {}
}

export class DeleteNotificationTemplate implements Action {
  readonly type = DELETE_NOTIFICATION_TEMPLATE;

  constructor(public payload: number) {}
}

export class DeleteNotificationTemplateCompleted implements Action {
  readonly type = DELETE_NOTIFICATION_TEMPLATE_COMPLETED;

  constructor(public response: BaseCrudResponse) {}
}

export class NotificationTemplateCardsLoading implements Action {
  readonly type = NOTIFICATION_TEMPLATES_CARDS_LOADING;
}

export class NotificationTemplateCardsLoaded implements Action {
  readonly type = NOTIFICATION_TEMPLATES_CARDS_LOADED;

  constructor(public payload: INotificationTemplateCards) {}
}

export class FetchError implements Action {
  readonly type = NOTIFICATION_TEMPLATE_FETCH_ERROR;

  constructor(public payload: object) {}
}

export type NotificationTemplatesActions =
  | NotificationTemplateListDataLoading
  | NotificationTemplateListDataLoaded
  | EditNotificationTemplate
  | EditNotificationTemplateCompleted
  | AddNotificationTemplate
  | AddNotificationTemplateCompleted
  | DeleteNotificationTemplates
  | DeleteNotificationTemplatesCompleted
  | DeleteNotificationTemplate
  | DeleteNotificationTemplateCompleted
  | NotificationTemplateSiteDataLoading
  | NotificationTemplateSiteDataLoaded
  | NotificationTemplateCardsLoading
  | NotificationTemplateCardsLoaded
  | FetchError;
