import * as Actions from './stations.actions';
import { StationsStateInterface } from './stations.model';

export const stationsState: StationsStateInterface = {
  stationsDataLoaded: false,
  stationsDataLoading: false,
  stations: [],
  stationsAddLoading: false,
  stationsAddLoaded: false,
  stationsEditLoading: false,
  stationsEditLoaded: false,
  stationsBulkEditLoading: false,
  stationsBulkEditLoaded: false,
  stationsDeleteLoading: false,
  stationsDeleteLoaded: false,
  stationsBulkDeleteLoading: false,
  stationsBulkDeleteLoaded: false,
  total: null,
};

export function stationsReducer(
  state: StationsStateInterface = stationsState,
  action: Actions.StationsActions,
): StationsStateInterface {
  switch (action.type) {
    case Actions.STATIONS_DATA_LOAD:
      return {
        ...state,
        ...{
          stationsDataLoading: true,
          stationsDataLoaded: false,
        },
      };
    case Actions.STATIONS_DATA_LOADED:
      return {
        ...state,
        ...{
          stations: action.payload.data,
          total: action.payload.total,
          stationsDataLoading: false,
          stationsDataLoaded: true,
        },
      };
    case Actions.ADD_STATION:
      return {
        ...state,
        stationsAddLoading: true,
        stationsAddLoaded: false,
      };
    case Actions.ADD_STATION_COMPLETED:
      return {
        ...state,
        stationsAddLoading: false,
        stationsAddLoaded: true,
      };
    case Actions.EDIT_STATION:
      return {
        ...state,
        stationsEditLoading: true,
        stationsEditLoaded: false,
      };
    case Actions.EDIT_STATION_COMPLETED:
      return {
        ...state,
        stationsEditLoading: false,
        stationsEditLoaded: true,
      };
    case Actions.BULK_EDIT_STATIONS:
      return {
        ...state,
        stationsBulkEditLoading: true,
        stationsBulkEditLoaded: false,
      };
    case Actions.BULK_EDIT_STATIONS_COMPLETED:
      return {
        ...state,
        stationsBulkEditLoading: false,
        stationsBulkEditLoaded: true,
      };
    case Actions.DELETE_STATION:
      return {
        ...state,
        stationsDeleteLoading: true,
        stationsDeleteLoaded: false,
      };
    case Actions.DELETE_STATION_COMPLETED:
      return {
        ...state,
        stationsDeleteLoading: false,
        stationsDeleteLoaded: true,
      };
    case Actions.BULK_DELETE_STATIONS:
      return {
        ...state,
        stationsBulkDeleteLoading: true,
        stationsBulkDeleteLoaded: false,
      };
    case Actions.BULK_DELETE_STATIONS_COMPLETED:
      return {
        ...state,
        stationsBulkDeleteLoading: false,
        stationsBulkDeleteLoaded: true,
      };
    default:
      return state;
  }
}
