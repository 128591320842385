import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import * as ObjectActions from './main.actions';
import * as AppActions from '../app/actions';
import {
  GetNavigationInformationResponseInterface,
  IAllowedDomain,
  SwitchHomePageResponseInterface,
} from './main.model';
import { MonitoringService } from '../../shared/service/error-service/monitoring.service';
import { MenuService } from '../../shared/service/menu/menu.service';
import { SiteLineSelectionService } from '../site-line-selection/site-line-selection.service';
import { ResponseInterface } from '../../shared/model/interface/generic-api-response.model';
import { emptyAction, EModules, IMenuPermissions, STATIC_MAX_LIMIT_OF_CRUD, StatusesEnum } from '../../../constants';
import { SitesResponseInterface } from '../work-order-schedule/work-order-schedule.model';
import { MainService } from './main.service';
import { environment } from '../../../environments/environment';
import { GetManyResponseInterface } from '../../shared/model/interface/crud-response-interface.model';

@Injectable()
export class MainEffects {
  constructor(
    private actions$: Actions<ObjectActions.MainActions>,
    private http: HttpClient,
    private injector: Injector,
    private monitoringService: MonitoringService,
    private readonly menuService: MenuService,
    private readonly siteLineSelectionService: SiteLineSelectionService,
    private readonly mainService: MainService,
  ) {}

  private MAIN_URLS = {
    POST: {
      SEND_CONTACT_SUPPORT: environment.contactSupportUrl,
      SWITCH_HOME_PAGE: '/api/switch-home-page',
    },
  };

  getActiveSites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.MainActionTypes.GetActiveSites),
      switchMap(() => {
        let params: HttpParams = new HttpParams();
        const searchCondition: string = JSON.stringify({ statusId: { $eq: StatusesEnum.ACTIVE } });
        params = params.set('s', searchCondition);

        return from(this.siteLineSelectionService.getSitesOfSiteLineSelection(params)).pipe(
          switchMap((response: SitesResponseInterface) => {
            const activeSiteModules: Partial<IMenuPermissions['modules']> = {};
            Object.values(EModules).forEach((moduleKey: string) => {
              activeSiteModules[moduleKey] = response.data.some((site) => site.menuPermissions.modules[moduleKey]);
            });
            return of(
              new ObjectActions.GetActiveSitesCompleted(response.data, response.success),
              new ObjectActions.SetSiteModuleStatuses(activeSiteModules),
            );
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error));
          }),
        );
      }),
    ),
  );

  getNavigationInformation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.MainActionTypes.GetNavigationMenu),
      switchMap(() => {
        return from(this.menuService.getNavigationInformation()).pipe(
          switchMap((response: ResponseInterface<GetNavigationInformationResponseInterface>) => {
            return of(new ObjectActions.GetNavigationMenuCompleted(response.data, response.success));
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error));
          }),
        );
      }),
    ),
  );

  sendContactSupport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.MainActionTypes.SendContactSupport),
      switchMap((objectData: ObjectActions.SendContactSupport) => {
        const body = {
          contactType: objectData.contactType,
          textSubject: objectData.textSubject,
          textBody: objectData.textBody,
          mail: objectData.mail,
          sms: objectData.sms,
          userId: objectData.userId,
          customMailGroupId: objectData.customMailGroupId,
          fromUserId: objectData.fromUserId,
        };
        return this.http
          .post<string>(
            `${this.MAIN_URLS.POST.SEND_CONTACT_SUPPORT}/site/${objectData.siteId}/line/${objectData.lineId}`,
            body,
          )
          .pipe(
            switchMap((response) => {
              return of(new ObjectActions.SendContactSupportCompleted(response), new AppActions.HideLoader());
            }),
            catchError((error) => {
              return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
            }),
          );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  switchHomePage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.MainActionTypes.SwitchHomePage),
      switchMap((objectData: ObjectActions.SwitchHomePage) => {
        return this.http
          .post<SwitchHomePageResponseInterface>(this.MAIN_URLS.POST.SWITCH_HOME_PAGE, {
            status: objectData.status,
          })
          .pipe(
            switchMap((response) => {
              if (response.success) {
                let url = window.location.origin;
                const page = '/home';

                if (['localhost', '127.0.0.1'].indexOf(window.location.hostname) !== -1) {
                  url = `http://${window.location.hostname}:8001`;
                }

                url += page;
                window.location.href = url;
              }

              return emptyAction;
            }),
            catchError((error) => {
              return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
            }),
          );
      }),
    ),
  );

  getCustomMailGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.MainActionTypes.GetCustomMailGroups),
      switchMap((objectData: ObjectActions.GetCustomMailGroups) => {
        let params: HttpParams = new HttpParams();
        const searchCondition: string = JSON.stringify({
          siteId: { $eq: objectData.siteId },
          isCustomContactGroup: { $eq: 1 },
        });
        params = params.set('s', searchCondition).set('limit', '100');

        return this.mainService.getCustomMailGroups(params).pipe(
          switchMap((response) => {
            return of(new ObjectActions.GetCustomMailGroupsCompleted(response.data));
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error));
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error));
      }),
    ),
  );

  getAllowedDomains$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.MainActionTypes.AllowedDomains),
      switchMap((objectData: ObjectActions.AllowedDomains) => {
        const params: HttpParams = new HttpParams().set('limit', STATIC_MAX_LIMIT_OF_CRUD);

        return this.mainService.getAllowedDomains(params).pipe(
          switchMap((response: GetManyResponseInterface<IAllowedDomain>) => {
            return of(new ObjectActions.AllowedDomainsCompleted(response.data));
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error));
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error));
      }),
    ),
  );
}
