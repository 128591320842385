import { Observable, throwError, timer } from 'rxjs';
import { mergeMap, finalize } from 'rxjs/operators';

export const genericRetryStrategy = (
  {
    maxRetryAttempts = 3,
    scalingDuration = 1000,
    excludedStatusCodes = [],
  }: {
    maxRetryAttempts?: number;
    scalingDuration?: number;
    excludedStatusCodes?: number[];
  } = {},
) => (attempts: Observable<any>) => {
  return attempts.pipe(
    mergeMap((error, i) => {
      const retryAttempt = i + 1;
      if (
        !String(error.status).startsWith('5')
        || retryAttempt > maxRetryAttempts
        || excludedStatusCodes.find(e => e === error.status)
      ) {
        return throwError(error);
      }
      return timer(retryAttempt * retryAttempt * scalingDuration);
    }),
    finalize(() => {}),
  );
};
