import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { GenericCrudRequestConstructionParameters } from '../../model/interface/generic-api-request.model';
import { HelperService } from '../helper.service';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../model/interface/crud-response-interface.model';
import {
  LineDropdownOption,
  SensorBulkUpdateRequestInterface,
  SensorCRUDInterface,
  SensorCRUDRequestInterface,
  SensorFormDropdownData, SitesDropdownOption,
} from '../../../store/sensor-management/sensor-management.model';
import { map } from 'rxjs/operators';
import { DropdownOptionInterface } from '../../component/scw-mat-ui/scw-mat-select/scw-mat-select.model';

@Injectable({
  providedIn: 'root',
})
export class SensorManagementService {
  private readonly routes = {
    sensorList: '/sensor-list',
  };

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly api: string,
    public helperService: HelperService,
  ) {}

  public loadSensors(
    serviceParameters: GenericCrudRequestConstructionParameters,
  ): Observable<GetManyResponseInterface<SensorCRUDInterface>> {
    let params: HttpParams = this.helperService.insertGenericCrudRequestParameters(serviceParameters);

    for (const filters of serviceParameters?.advancedFilter?.filters ?? []) {
      switch (filters.path) {
        case 'equipment.equipmentName':
          params = params.append('join', 'equipment.equipmentName');
          break;
        case 'lineStation.name':
          params = params.append('join', 'lineStation.name');
          break;
        default:
          break;
      }
    }
    return this.http.get<GetManyResponseInterface<SensorCRUDInterface>>(`${this.api}${this.routes.sensorList}`, {
      params,
    });
  }

  public loadFormData(): Observable<SensorFormDropdownData> {
    const siteParams: HttpParams = this.helperService.insertGenericCrudRequestParameters({
      limit: 1000,
      fields: ['name', 'decimalScaleLimit'],
    });

    const lineParams: HttpParams = this.helperService.insertGenericCrudRequestParameters({
      limit: 1000,
      join: ['station', 'equipmentAssignment', 'equipmentAssignment.equipment'],
      fields: ['title', 'siteId'],
    });

    const observables: {
      sites: Observable<GetManyResponseInterface<DropdownOptionInterface>>;
      lines: Observable<GetManyResponseInterface<LineDropdownOption>>;
    } = {
      sites: this.http.get<GetManyResponseInterface<DropdownOptionInterface>>(`${this.api}/sites`, {
        params: siteParams,
      }),
      lines: this.http.get<GetManyResponseInterface<LineDropdownOption>>(`${this.api}/lines`, { params: lineParams }),
    };

    return forkJoin(observables).pipe(
      map(
        (resolvedObservables: {
          sites: GetManyResponseInterface<SitesDropdownOption>;
          lines: GetManyResponseInterface<LineDropdownOption>;
        }) => {
          return {
            sites: resolvedObservables.sites,
            lines: resolvedObservables.lines,
          };
        },
      ),
    );
  }

  public createSensor(
    requestData: Partial<SensorCRUDRequestInterface>,
  ): Observable<BaseOneResponseInterface<SensorCRUDInterface>> {
    return this.http.post<BaseOneResponseInterface<SensorCRUDInterface>>(
      `${this.api}${this.routes.sensorList}`,
      requestData,
    );
  }

  public updateSensor(
    id: number,
    requestData: Partial<SensorCRUDRequestInterface>,
  ): Observable<BaseOneResponseInterface<SensorCRUDInterface>> {
    return this.http.patch<BaseOneResponseInterface<SensorCRUDInterface>>(
      `${this.api}${this.routes.sensorList}/${id}`,
      requestData,
    );
  }

  public bulkUpdateSensor(requestData: SensorBulkUpdateRequestInterface[]): Observable<BulkResponseDataInterface> {
    return this.http.patch<BulkResponseDataInterface>(`${this.api}${this.routes.sensorList}/bulk/edit`, {
      sensorLists: requestData,
    });
  }

  public deleteSensor(id: number): Observable<void> {
    return this.http.delete<void>(`${this.api}${this.routes.sensorList}/${id}`);
  }

  public bulkDeleteSensor(ids: number[]): Observable<BulkResponseDataInterface> {
    return this.http.delete<BulkResponseDataInterface>(`${this.api}${this.routes.sensorList}/bulk/delete`, {
      body: { sensorLists: ids },
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    });
  }
}
