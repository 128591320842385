import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ExcelHelperService } from '../../../shared/service/excel/excel-helper.service';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import {
  BaseCrudResponse,
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { AlertInterface, IAlertFormData, IAlertFormRootCauseGroup } from './alerts.model';
import { forkJoin, Observable, Subject } from 'rxjs';
import { excelDateFormat, excelTimeFormat } from '../../../shared/model/enum/excel-date-format';
import { map, takeUntil } from 'rxjs/operators';
import { LineService } from '../../../shared/service/filter/line.service';
import { RootCauseGroupService } from '../../../shared/service/settings/root-cause-group/root-cause-group.service';
import { RootCauseGroupCRUDDataInterface } from '../root-cause-group/root-cause-group.model';
import { LineCRUDResponseInterface } from '../../../shared/service/filter/service.class';
import { LineCRUDInterface, SiteCRUDInterface } from '../../../shared/component/filter/filter.class';
import { SiteService } from '../../../shared/service/filter/site.service';
import { ActivitiesService } from '../../../shared/service/activities/activities.service';
import { ActivitiesInterface } from '../../../shared/model/interface/activities.model';
import { MainService } from '../../main/main.service';
import { InterfaceCustomMailGroupInterface } from '../../main/main.model';
import { HelperService } from '../../../shared/service/helper.service';

@Injectable({
  providedIn: 'root',
})
export class AlertsService {
  private timezone: string = 'utc';
  private dateFormat$: string;
  private timeFormat$: string;
  private readonly destroySubject: Subject<boolean> = new Subject<boolean>();
  private readonly routes = {
    alerts: `${this.baseUrl}/alerts`,
    bulkDelete: `${this.baseUrl}/alerts/bulk/delete`,
  };

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly excelHelper: ExcelHelperService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly lineService: LineService,
    private readonly rootCauseGroupsService: RootCauseGroupService,
    private readonly siteService: SiteService,
    private readonly activitiesService: ActivitiesService,
    private readonly mainService: MainService,
    private readonly helperService: HelperService,
  ) {
    this.store
      .select('user')
      .pipe(takeUntil(this.destroySubject))
      .subscribe((state) => {
        if (state.isUserLoaded) {
          this.timezone = state.timezone;

          if (state.locale !== '') {
            this.dateFormat$ = excelDateFormat[state.locale];
            this.timeFormat$ = excelTimeFormat[state.locale];
          }
          this.destroySubject.next(true);
          this.destroySubject.complete();
        }
      });
  }

  public getAlerts(params: HttpParams): Observable<GetManyResponseInterface<AlertInterface>> {
    return this.http.get<GetManyResponseInterface<AlertInterface>>(this.routes.alerts, { params });
  }

  public deleteAlerts(alerts: number[] | number): Observable<BaseCrudResponse | BulkResponseDataInterface> {
    if (Array.isArray(alerts) && alerts.length > 1) {
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        body: {
          alerts,
        },
      };

      return this.http.delete<BulkResponseDataInterface>(`${this.routes.bulkDelete}`, httpOptions);
    }

    return this.http.delete<BaseCrudResponse>(`${this.routes.alerts}/${alerts[0]}`);
  }

  public getFormData(httpParams: HttpParams): Observable<IAlertFormData> {
    const observables: {
      rootCauseGroups: Observable<GetManyResponseInterface<RootCauseGroupCRUDDataInterface>>;
      lineId: Observable<LineCRUDResponseInterface>;
      siteId: Observable<GetManyResponseInterface<SiteCRUDInterface>>;
      activities: Observable<GetManyResponseInterface<ActivitiesInterface>>;
      notificationContacts: Observable<GetManyResponseInterface<InterfaceCustomMailGroupInterface>>;
    } = {
      siteId: this.siteService.getSites(httpParams.set('fields', 'name')),
      lineId: this.lineService.getLines(
        httpParams.set('fields', 'title,siteId,activityIds').set('join', 'lineTypeName||lineType'),
      ),
      rootCauseGroups: this.rootCauseGroupsService.getRootCauseGroups(
        httpParams
          .set('fields', 'name,siteId')
          .append('join', 'task||activityId,lineId,title')
          .set('groupBy', 'Task.siteId,Task.lineId,Task.activityId,Task.rootCauseGroupId')
          .set('s', JSON.stringify({ 'Task.id': { $notnull: true } })),
      ),
      activities: this.activitiesService.getActivities(httpParams.set('fields', 'name,activityType')),
      notificationContacts: this.mainService.getCustomMailGroups(httpParams),
    };

    return forkJoin(observables).pipe(
      map(
        (resolvedObservables: {
          rootCauseGroups: GetManyResponseInterface<
            RootCauseGroupCRUDDataInterface & { task: { id: number, activityId: number; lineId: number }[] }
          >;
          lineId: LineCRUDResponseInterface;
          siteId: GetManyResponseInterface<SiteCRUDInterface>;
          activities: GetManyResponseInterface<ActivitiesInterface>;
          notificationContacts: GetManyResponseInterface<InterfaceCustomMailGroupInterface>;
        }) => {
          return {
            siteId: resolvedObservables.siteId.data.map(({ id, name }: Partial<SiteCRUDInterface>) => {
              return { id, name };
            }).sort(this.helperService.dropdownOptionCompareFunction),
            rootCauseGroups: resolvedObservables.rootCauseGroups.data.map(
              ({
                id,
                name,
                siteId,
                task,
              }: Partial<RootCauseGroupCRUDDataInterface & { task: { activityId: number; lineId: number }[] }>) => {
                return {
                  id,
                  name,
                  siteId,
                  task,
                } as IAlertFormRootCauseGroup;
              },
            ),
            lineId: resolvedObservables.lineId.data
              .map(({ id, title, siteId, activityIds, lineTypeName }: Partial<LineCRUDInterface>) => {
                return {
                  id,
                  siteId,
                  name: title,
                  activityIds: activityIds?.split(',').map((id: string) => Number(id)),
                  departmentName: lineTypeName.lineType,
                };
              })
              .sort(this.helperService.dropdownOptionCompareFunction),
            activities: resolvedObservables.activities.data.map(
              ({ id, name, activityType }: Partial<ActivitiesInterface>) => {
                return { id, name, activityType };
              },
            ),
            notificationContacts: resolvedObservables.notificationContacts.data.map(({ id, siteId, title }) => {
              return { id, siteId, name: title };
            }),
          };
        },
      ),
    );
  }

  createAlert(alert: AlertInterface): Observable<BaseOneResponseInterface<AlertInterface>> {
    return this.http.post<BaseOneResponseInterface<AlertInterface>>(this.routes.alerts, alert);
  }

  updateAlert(alert: AlertInterface, id: number): Observable<BaseOneResponseInterface<AlertInterface>> {
    return this.http.patch<BaseOneResponseInterface<AlertInterface>>(`${this.routes.alerts}/${id}`, alert);
  }
}
