import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PageHeaderInterface } from './page-header.model';
import { take } from 'rxjs/operators';

@Injectable()
export class PageHeaderService implements OnDestroy {
  public pageHeader: BehaviorSubject<PageHeaderInterface> = new BehaviorSubject({
    icon: null,
    titleKey: null,
    title: null,
    fullScreenButton: false,
    fullScreenTargetElementId: null,
    isTabPage: false,
  });

  setHeader(pageHeader: PageHeaderInterface): void {
    this.pageHeader.next(pageHeader);
  }

  patchHeader(pageHeader: Partial<PageHeaderInterface>): void {
    this.pageHeader.pipe(take(1)).subscribe((value) => {
      this.pageHeader.next({
        ...value,
        ...pageHeader,
      });
    });
  }

  ngOnDestroy(): void {
    this.pageHeader.unsubscribe();
  }
}
