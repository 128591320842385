<ng-template [ngIf]="pageMode === ePageMode.VIEW">
  <scw-mat-small-button (onClick)="onModeChange(ePageMode.EDIT)">
    <em class="fas fa-pen mr-1"></em>
    {{ pageModeEditTranslation }}
  </scw-mat-small-button>
</ng-template>
<ng-template [ngIf]="pageMode === ePageMode.EDIT && displayEditModeButtons">
  <scw-mat-small-button class="save-button mr-3" (onClick)="onModeChange(ePageMode.VIEW, true)">
    <em class="fas fa-save mr-1"></em>
    {{ pageModeSaveTranslation }}
  </scw-mat-small-button>
  <scw-mat-small-button (onClick)="onModeChange(ePageMode.VIEW)">
    {{ pageModeCancelTranslation }}
  </scw-mat-small-button>
</ng-template>
