import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as ObjectActions from './sensor.actions';
import * as AppActions from '../../app/actions';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { SensorCRUDRequestInterface } from '../../sensor-management/sensor-management.model';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import { SensorManagementService } from '../../../shared/service/sensor-management/sensor-management.service';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable()
export class FilterSensorEffects {
  private SENSORLIST = {
    GET: {
      DATA_URL: '/sensor-list',
    },
  };
  constructor(
    private actions$: Actions<ObjectActions.FilterSensorActions>,
    public service: SensorManagementService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly api: string,
  ) {}

  $loadSensors = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.FilterSensorActionTypes.StartLoadFilterSensors),
      switchMap((objectData: ObjectActions.StartLoadFilterSensors) => {
        let params: HttpParams = new HttpParams();
        params = params.set('s', objectData.options.s).set('limit', '1000');

        return this.http.get<any>(`${this.api}${this.SENSORLIST.GET.DATA_URL}`, { params }).pipe(
          switchMap((response: GetManyResponseInterface<SensorCRUDRequestInterface>) => {
            return of(new ObjectActions.FilterSitesLoaded(response.data), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );
}
