import { AlertsStateInterface } from './alerts.model';
import * as Actions from './alerts.actions';

export const alertState: AlertsStateInterface = {
  alertsLoading: false,
  alertsLoaded: false,
  alertData: null,
  alertsData: null,
  alertsDeleteLoading: false,
  alertsDeleteLoaded: false,
  addAlertLoading: false,
  addAlertLoaded: false,
  editAlertLoading: false,
  editAlertLoaded: false,
  bulkEditAlertLoading: false,
  bulkEditAlertLoaded: false,
  filters: null,
  tableSettings: [],
  alertsFormDataLoading: false,
  alertsFormDataLoaded: false,
  formOptionsData: null,
  alertsTasksDataLoading: false,
  alertsTasksDataLoaded: false,
  tasksData: null,
};

export function alertReducer(
  state: AlertsStateInterface = alertState,
  action: Actions.AlertsActions,
): AlertsStateInterface {
  switch (action.type) {
    case Actions.SET_SELECTED_FILTERS:
      return {
        ...state,
        filters: action.filters,
      };
    case Actions.ALERTS_DATA_LOADING:
      return {
        ...state,
        ...{
          alertsLoading: true,
          alertsLoaded: false,
        },
      };
    case Actions.ALERTS_DATA_LOADED:
      return {
        ...state,
        ...{
          alertsData: action.payload,
          alertsLoading: false,
          alertsLoaded: true,
        },
      };
    case Actions.DELETE_ALERTS:
      return {
        ...state,
        alertsDeleteLoading: true,
        alertsDeleteLoaded: false,
      };
    case Actions.DELETE_ALERTS_COMPLETED:
      return {
        ...state,
        alertsDeleteLoading: false,
        alertsDeleteLoaded: true,
      };
    case Actions.ADD_ALERT:
      return {
        ...state,
        addAlertLoading: true,
        addAlertLoaded: false,
      };
    case Actions.ADD_ALERT_COMPLETED:
      return {
        ...state,
        addAlertLoading: false,
        addAlertLoaded: true,
      };
    case Actions.EDIT_ALERT:
      return {
        ...state,
        editAlertLoading: true,
        editAlertLoaded: false,
      };
    case Actions.EDIT_ALERT_COMPLETED:
      return {
        ...state,
        editAlertLoading: false,
        editAlertLoaded: true,
      };
    case Actions.ALERTS_FORM_DATA_LOADING:
      return {
        ...state,
        ...{
          alertsFormDataLoading: true,
          alertsFormDataLoaded: false,
        },
      };
    case Actions.ALERTS_FORM_DATA_LOADED:
      return {
        ...state,
        ...{
          formOptionsData: action.payload,
          alertsFormDataLoading: false,
          alertsFormDataLoaded: true,
        },
      };
    case Actions.ALERTS_TASKS_DATA_LOADING:
      return {
        ...state,
        ...{
          alertsTasksDataLoading: true,
          alertsTasksDataLoaded: false,
        },
      };
    case Actions.ALERTS_TASKS_DATA_LOADED:
      return {
        ...state,
        ...{
          tasksData: action.payload,
          alertsTasksDataLoading: false,
          alertsTasksDataLoaded: true,
        },
      };
    case Actions.SET_TABLE_SETTINGS:
      return {
        ...state,
        tableSettings: action.payload,
      };
    default:
      return state;
  }
}
