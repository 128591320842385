import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as ObjectActions from './work-order-performance.actions';
import { of } from 'rxjs';
import * as AppActions from '../../app/actions';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import { WorkOrderPerformanceService } from './work-order-performance.service';
import { GetWorkOrderResponseInterface } from '../../work-order-schedule/work-order-schedule.model';
import {
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { ActivityLogsResponseInterface, IActivityLog } from '../root-cause-analysis/root-cause-analysis.model';
import {
  IExpectedSpeed,
  IOeeCalculationResult,
  IWorkOrderComment,
  IWorkOrderLaborAssetInformation,
  IWorkOrderPhaseInformation,
} from './work-order-performance.model';
import { HelperService } from 'src/app/shared/service/helper.service';
import { EntityTranslatorService } from '../../../shared/service/entity-translator/entity-translator.service';
import { emptyAction } from '../../../../constants';

@Injectable()
export class WorkOrderPerformanceEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly helperService: HelperService,
    public service: WorkOrderPerformanceService,
    private readonly entityTranslatorService: EntityTranslatorService,
  ) {}

  getWorkOrderPerformanceData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.WORK_ORDER_INFORMATION_LOADING),
      switchMap((objectData: ObjectActions.WorkOrderInformationLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getWorkOrderInformation(objectData.params).pipe(
          switchMap((response: GetWorkOrderResponseInterface) => {
            this.entityTranslatorService.translate(response.data);
            return of(new ObjectActions.WorkOrderInformationLoaded(response.data), new AppActions.HideLoader());
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchDataError(err));
          }),
        );
      }),
      catchError((err) => {
        return of(new ObjectActions.FetchDataError(err));
      }),
    ),
  );

  getWorkOrderActivityLogs = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.WORK_ORDER_ACTIVITY_LOGS_LOADING),
      switchMap((objectData: ObjectActions.WorkOrderActivityLogsLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getWorkOrderActivityLogs(objectData.params).pipe(
          switchMap((response: BaseOneResponseInterface<ActivityLogsResponseInterface>) => {
            response.data.rows.forEach((activityLog: IActivityLog) => {
              this.entityTranslatorService.translate(activityLog);
              this.service.setWorkOrderPerformanceTaskDescription(activityLog);
            });

            return of(new ObjectActions.WorkOrderActivityLogsLoaded(response.data.rows), new AppActions.HideLoader());
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchDataError(err));
          }),
        );
      }),
      catchError((err) => {
        return of(new ObjectActions.FetchDataError(err));
      }),
    ),
  );

  getWorkOrderOeeCalculationResult = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.WORK_ORDER_OEE_RESULT_LOADING),
      switchMap((objectData: ObjectActions.WorkOrderOeeResultLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getOeeCalculationResult(objectData.params).pipe(
          switchMap((response: GetManyResponseInterface<IOeeCalculationResult>) => {
            return of(new ObjectActions.WorkOrderOeeResultLoaded(response.data[0]), new AppActions.HideLoader());
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchDataError(err));
          }),
        );
      }),
      catchError((err) => {
        return of(new ObjectActions.FetchDataError(err));
      }),
    ),
  );

  getWorkOrderPhaseInformation = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.WORK_ORDER_PHASE_INFORMATION_LOADING),
      switchMap((objectData: ObjectActions.WorkOrderPhaseInformationLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getPhaseInformation(objectData.params).pipe(
          switchMap((response: BaseOneResponseInterface<IWorkOrderPhaseInformation>) => {
            return of(new ObjectActions.WorkOrderPhaseInformationLoaded(response.data), new AppActions.HideLoader());
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchDataError(err));
          }),
        );
      }),
      catchError((err) => {
        return of(new ObjectActions.FetchDataError(err));
      }),
    ),
  );

  getWorkOrderLaborInformation = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.WORK_ORDER_LABOR_INFORMATION_LOADING),
      switchMap((objectData: ObjectActions.WorkOrderLaborInformationLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getLaborInformation(objectData.params).pipe(
          switchMap((response: BaseOneResponseInterface<IWorkOrderLaborAssetInformation[]>) => {
            response.data.forEach((workOrderLaborAssetInformation: IWorkOrderLaborAssetInformation) =>
              this.entityTranslatorService.translate(workOrderLaborAssetInformation),
            );

            return of(new ObjectActions.WorkOrderLaborInformationLoaded(response.data), new AppActions.HideLoader());
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchDataError(err));
          }),
        );
      }),
      catchError((err) => {
        return of(new ObjectActions.FetchDataError(err));
      }),
    ),
  );

  getProductTargetSpeed = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.PRODUCT_TARGET_SPEED_LOADING),
      switchMap((objectData: ObjectActions.ProductTargetSpeedLoading) => {
        return this.service.getProductExpectedSpeed(objectData.params).pipe(
          switchMap((response: BaseOneResponseInterface<IExpectedSpeed>) => {
            return of(new ObjectActions.ProductTargetSpeedLoaded(response.data));
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchDataError(err));
          }),
        );
      }),
      catchError((err) => {
        return of(new ObjectActions.FetchDataError(err));
      }),
    ),
  );

  downloadLaborAssetInformationExcel = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DOWNLOAD_LABOR_ASSET_INFORMATION_EXCEL),
      switchMap((objectData: ObjectActions.DownloadLaborAssetInformationExcel) => {
        this.service.downloadLaborAssetInformationExcel(objectData.data, objectData.excelType);
        return emptyAction;
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchDataError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getWorkOrderComments = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.COMMENTS_LOADING),
      switchMap((objectData: ObjectActions.CommentsLoading) => {
        return this.service.getWorkOrderComments(objectData.workOrderId).pipe(
          switchMap((response: GetManyResponseInterface<IWorkOrderComment>) => {
            return of(new ObjectActions.CommentsLoaded(response.data));
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchDataError(err));
          }),
        );
      }),
      catchError((err) => {
        return of(new ObjectActions.FetchDataError(err));
      }),
    ),
  );
}
