import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, Subscription, throwError, timer } from 'rxjs';
import { catchError, retryWhen, take, tap } from 'rxjs/operators';
import { AuthGuardService } from './shared/service/auth-guard/auth-guard.service';
import { ToastrService, IndividualConfig } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { genericRetryStrategy } from './shared/helper/rxjs-utils';
import { Router } from '@angular/router';
import * as AppActions from './store/app/actions';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from './store/oee.reducer';
import { environment } from '../environments/environment';
import { SwUpdate } from '@angular/service-worker';
import { CheckForUpdateService } from './check-for-update.service';
import * as _ from 'lodash';
import { ErrorMessageService } from './shared/service/error-message.service';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  private checkForUpdateSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private everyMinuteTimer$: Observable<number> = null;
  private generalWarningToastParams: Partial<IndividualConfig> = {
    closeButton: false,
    progressBar: false,
    disableTimeOut: true,
    tapToDismiss: false,
    positionClass: 'toast-bottom-right',
  };
  private generalErrorToastParams: Partial<IndividualConfig> = {
    closeButton: true,
    progressBar: true,
    positionClass: 'toast-bottom-right',
  };

  constructor(
    public authGuardService: AuthGuardService,
    private toast: ToastrService,
    private translate: TranslateService,
    private store: Store<oeeAppReducer.OeeAppState>,
    public router: Router,
    private update: SwUpdate,
    private readonly errorMessageService: ErrorMessageService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const clonedRequest = req.clone({
      headers: req.headers.set('x-version', environment.version),
    });

    return next.handle(clonedRequest).pipe(
      tap(() => {}),
      retryWhen(
        genericRetryStrategy({
          maxRetryAttempts: 5,
          excludedStatusCodes: [400, 401, 500, 503],
        }),
      ),
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          switch (err.status) {
            case 401:
              this.authGuardService.redirectToLogin();
              localStorage.clear();
              break;
            case 403:
              this.router.navigate(['/error/forbidden']);
              break;
            case 428:
              const fiveMinutesInMs: number = 300000;
              setTimeout(() => window.location.reload(), fiveMinutesInMs);

              if (this.checkForUpdateSubject.getValue() === null) {
                this.everyMinuteTimer$ = timer(0, 60 * 1000);
                this.checkForUpdateSubject.next(this.everyMinuteTimer$.subscribe(() => this.update.checkForUpdate()));
                const translateSubscription: Subscription = this.translate
                  .get(['general.versionUpdate', 'general.warning'])
                  .subscribe((translations) => {
                    this.toast
                      .show(
                        translations['general.versionUpdate'],
                        translations['general.warning'],
                        { ...this.generalWarningToastParams, ...{ enableHtml: true } },
                        'toast-warning',
                      )
                      .onTap.pipe(take(1))
                      .subscribe(() =>
                        CheckForUpdateService.unregisterServiceWorkerAndRefresh().then(() =>
                          window.location.reload(),
                        ),
                      );

                    translateSubscription.unsubscribe();
                  });
                this.store.dispatch(new AppActions.ShowMask());
              }
              break;
            case 0:
            case 504:
              break;
            default:
              if (err.status === 503) {
                if (err?.error?.message?.[0]?.constraints?.systemUnderMaintenance) {
                  this.store.dispatch(new AppActions.SetMaintenanceModeData({ isUnderMaintenance: true }));
                  break;
                }
              }

              let errorMessage = this.translate.instant('general.defaultErrorMessage');
              if (typeof err.message !== 'undefined' && [null, ''].indexOf(err.message) === -1) {
                errorMessage = err.message;
              }

              if (
                typeof err.error !== 'undefined' &&
                err.error !== null &&
                typeof err.error.message !== 'undefined' &&
                [null, ''].indexOf(err.error.message) === -1
              ) {
                const objMessage: boolean | string = _.get(err.error, 'message.message', false);
                errorMessage = err.error.message;

                if (typeof err.error.message === 'object' && objMessage) {
                  errorMessage = objMessage;
                }
              }

              if (Array.isArray(errorMessage)) {
                try {
                  errorMessage.forEach((message) => {
                    Object.keys(message.constraints).forEach((errorKey) => {
                      this.translate
                        .get(`apiErrorMessages.properties.${message.property}`)
                        .subscribe((property: string) => {
                          this.translate
                            .get(`apiErrorMessages.${errorKey}`, {
                              property,
                              option: this.errorMessageService.getOption(errorKey, message),
                              ...this.errorMessageService.additionalOptions(errorKey, message),
                            })
                            .subscribe((translatedErrorMessage: string) => {
                              if (translatedErrorMessage !== undefined) {
                                this.toast.error(
                                  translatedErrorMessage,
                                  this.translate.instant('general.failed'),
                                  this.generalErrorToastParams,
                                );
                              } else {
                                this.toast.error(
                                  this.translate.instant('general.error'),
                                  this.translate.instant('general.failed'),
                                  this.generalErrorToastParams,
                                );
                              }
                            });
                        });
                    });
                  });
                } catch (e) {
                  this.toast.error(
                    this.translate.instant('general.error'),
                    this.translate.instant('general.failed'),
                    this.generalErrorToastParams,
                  );
                }
              } else {
                this.toast.error(errorMessage, this.translate.instant('general.failed'), this.generalErrorToastParams);
              }

              break;
          }
        }

        const errorUrl = _.get(err, 'error.target.__zone_symbol__xhrURL', false);

        if (errorUrl && err.status === 0) {
          err.message = err.message.replace(/ *\([^)]*\) */g, ` (${errorUrl})`);
        }

        /*
         * Hide loaders in case of page freeze.
         */
        this.store.dispatch(new AppActions.HideTopLoader());
        this.store.dispatch(new AppActions.HideLoader());
        return throwError(err);
      }),
    );
  }
}
