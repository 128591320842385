import { Action } from '@ngrx/store';
import { TabRowInterface } from '../../../shared/component/side-config-bar/side-config-bar.model';
import { GenericCrudRequestConstructionParameters } from '../../../shared/model/interface/generic-api-request.model';
import { AlertInterface, IAlertFormData, IAlertsFilters } from './alerts.model';
import {
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { TaskCRUDInterface } from '../tasks/tasks.model';

export const ALERTS_DATA_LOADING = '[ALERTS] ALERTS DATA LOADING';
export const ALERTS_DATA_LOADED = '[ALERTS] ALERTS DATA LOADED';
export const ALERTS_FETCH_ERROR = '[ALERTS] ALERTS ACTION FETCH ERROR';
export const DELETE_ALERTS = '[ALERTS] ALERTS ACTION DELETE ALERTS';
export const DELETE_ALERTS_COMPLETED = '[ALERTS] ALERTS ACTION DELETE ALERTS COMPLETED';
export const ADD_ALERT = '[ALERTS] ADD ALERT';
export const ADD_ALERT_COMPLETED = '[ALERTS] ADD ALERT COMPLETED';
export const EDIT_ALERT = '[ALERTS] EDIT ALERT';
export const EDIT_ALERT_COMPLETED = '[ALERTS] EDIT ALERT COMPLETED';
export const SET_SELECTED_FILTERS = '[ALERTS] Set Selected Filters';
export const SET_TABLE_SETTINGS = '[ALERTS] SET TABLE SETTINGS';
export const ALERTS_FORM_DATA_LOADING = '[ALERTS] ALERTS FORM DATA LOADING';
export const ALERTS_FORM_DATA_LOADED = '[ALERTS] ALERTS FORM DATA LOADED';
export const ALERTS_TASKS_DATA_LOADING = '[ALERTS] ALERTS TASKS DATA LOADING';
export const ALERTS_TASKS_DATA_LOADED = '[ALERTS] ALERTS TASKS DATA LOADED';

export class AlertsDataLoading implements Action {
  readonly type = ALERTS_DATA_LOADING;

  constructor(public query: GenericCrudRequestConstructionParameters) {}
}

export class AlertsDataLoaded implements Action {
  readonly type = ALERTS_DATA_LOADED;

  constructor(public payload: GetManyResponseInterface<AlertInterface>) {}
}

export class DeleteAlerts implements Action {
  readonly type = DELETE_ALERTS;

  constructor(public payload: number[]) {}
}

export class DeleteAlertsCompleted implements Action {
  readonly type = DELETE_ALERTS_COMPLETED;

  constructor(public response: ({ id: number } & { errorMessages?: string })[]) {}
}

export class SetSelectedFilters implements Action {
  readonly type = SET_SELECTED_FILTERS;

  constructor(public filters: IAlertsFilters) {}
}

export class AddAlert implements Action {
  readonly type = ADD_ALERT;

  constructor(public alert: AlertInterface) {}
}

export class AddAlertCompleted implements Action {
  readonly type = ADD_ALERT_COMPLETED;

  constructor(public payload: BaseOneResponseInterface<AlertInterface>) {}
}

export class EditAlert implements Action {
  readonly type = EDIT_ALERT;

  constructor(public alert: AlertInterface, public alertId: number) {}
}

export class EditAlertCompleted implements Action {
  readonly type = EDIT_ALERT_COMPLETED;

  constructor(public payload: BaseOneResponseInterface<AlertInterface>) {}
}

export class AlertsFormDataLoading implements Action {
  readonly type = ALERTS_FORM_DATA_LOADING;
}

export class AlertsFormDataLoaded implements Action {
  readonly type = ALERTS_FORM_DATA_LOADED;

  constructor(public payload: IAlertFormData) {}
}

export class AlertsTasksDataLoading implements Action {
  readonly type = ALERTS_TASKS_DATA_LOADING;

  constructor(public siteIds: number[], public lineIds: number[], public activityIds: number[]) {}
}

export class AlertsTasksDataLoaded implements Action {
  readonly type = ALERTS_TASKS_DATA_LOADED;

  constructor(public payload: Partial<TaskCRUDInterface>[]) {}
}

export class FetchError implements Action {
  readonly type = ALERTS_FETCH_ERROR;

  constructor(public payload: object) {}
}

export class SetTableSettings implements Action {
  readonly type = SET_TABLE_SETTINGS;

  constructor(public payload: TabRowInterface[], public setAsDefault: boolean) {}
}

export type AlertsActions =
  | AlertsDataLoaded
  | AlertsDataLoading
  | DeleteAlerts
  | SetSelectedFilters
  | DeleteAlertsCompleted
  | AddAlert
  | AddAlertCompleted
  | EditAlert
  | EditAlertCompleted
  | AlertsFormDataLoading
  | AlertsFormDataLoaded
  | AlertsTasksDataLoading
  | AlertsTasksDataLoaded
  | SetTableSettings
  | FetchError;
