import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as ObjectActions from './advanced-filter.actions';
import * as oeeAppReducer from '../oee.reducer';
import { AdvancedFilterService } from '../../shared/component/filter/advanced-filter/advanced-filter.service';
import { User } from '../user/model';
import { BaseOneResponseInterface } from '../../shared/model/interface/crud-response-interface.model';
import {
  IAdvancedFilterDefaultsResponse,
  IDefaultFilters,
} from '../../shared/component/filter/advanced-filter/advanced-filter.model';
import { of } from 'rxjs';
import * as _ from 'lodash';

@Injectable()
export class AdvancedFilterEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly advancedFilterService: AdvancedFilterService,
  ) {}

  private userId: string;

  public getDefaultFilters = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LOAD_DEFAULT_FILTERS),
      switchMap(() => {
        this.store
          .select('user')
          .pipe(take(1))
          .subscribe((user: User) => {
            this.userId = user.userId;
          });

        return this.advancedFilterService.getDefaultFilters(this.userId).pipe(
          switchMap((response: BaseOneResponseInterface<IAdvancedFilterDefaultsResponse>) => {
            const payload: IDefaultFilters = _.get(response.data, 'advancedFilterConfig', null);
            return of(new ObjectActions.DefaultFiltersLoaded(payload));
          }),
        );
      }),
    ),
  );
}
