export interface CronJobsBaseFrequency {
  none: number;
  hour: number;
  day: number;
  week: number;
  month: number;
  year: number;
}

export interface CronJobsConfig {
  multiple?: boolean;
  bootstrap?: boolean;
  option?: CronJobBaseOptions;
}

export interface CronJobBaseOptions {
  none?: boolean | undefined;
  minute?: boolean | undefined;
  hour?: boolean | undefined;
  day?: boolean | undefined;
  week?: boolean | undefined;
  month?: boolean | undefined;
  year?: boolean | undefined;
}

export interface CronJobsFrequency {
  baseFrequency: number;
  minutes: number[];
  hours: number[];
  daysOfMonth: number[];
  daysOfWeek: number[];
  months: number[];
}

export interface CronJobsSelectOption {
  value: number;
  type?: OptionType;
  label: string | number;
}

export enum OptionType {
  hour,
  day,
  week,
  month,
  year,
}

export interface CronJobsValidationConfig {
  validate?: boolean;
}

export interface ScwCronInputRule {
  required?: boolean;
  message?: string;
}
