import { Action } from '@ngrx/store';
import { WorkOrderCRUDInterface } from '../../../shared/component/filter/filter.class';
import { HttpOptionInterface } from '../filter.model';
import { ApiServer } from '../../../shared/component/filter/dropdown/dropdown.model';

export enum FilterWorkOrderActionTypes {
  StartLoadFilterWorkOrders = '[Filter Work Order] Load Filter WorkOrders',
  FilterWorkOrdersLoaded = '[Filter Work Order] Filter WorkOrders Loaded',
  AppendWorkOrder = '[Filter Work Order] Append Work Order',
  ResetWorkOrderStore = '[Filter Work Order] Reset Work Orders Store',
}

export class StartLoadFilterWorkOrders implements Action {
  readonly type = FilterWorkOrderActionTypes.StartLoadFilterWorkOrders;
  constructor(public options: HttpOptionInterface, public server: ApiServer = ApiServer.NestJS) {}
}

export class FilterWorkOrdersLoaded implements Action {
  readonly type = FilterWorkOrderActionTypes.FilterWorkOrdersLoaded;
  constructor(public data: WorkOrderCRUDInterface[]) {}
}

export class AppendWorkOrder implements Action {
  readonly type = FilterWorkOrderActionTypes.AppendWorkOrder;
  constructor(public workOrder: WorkOrderCRUDInterface) {}
}

export class ResetWorkOrderStore implements Action {
  readonly type = FilterWorkOrderActionTypes.ResetWorkOrderStore;
  constructor() {}
}

export type FilterWorkOrderActions =
  | StartLoadFilterWorkOrders
  | FilterWorkOrdersLoaded
  | AppendWorkOrder
  | ResetWorkOrderStore;
