import * as Actions from './root-cause-analysis.actions';
import { RootCauseAnalysisStateInterface } from './root-cause-analysis.model';

export const rootCauseAnalysisInitialState: RootCauseAnalysisStateInterface = {
  isRootCauseAnalysisDataLoading: false,
  isRootCauseAnalysisDataLoaded: false,
  isRootCauseAnalysisEquipmentChartVisible: false,
  isRootCauseAnalysisStackChartVisible: false,
  isChartDataLoading: false,
  isChartDataLoaded: false,
  showManyDataWarning: false,
  rootCauseAnalysisData: [],
  chartData: [],
  regularChartData: [],
  dataProgress: 0,
};

export function rootCauseAnalysisReducer(
  state: RootCauseAnalysisStateInterface = rootCauseAnalysisInitialState,
  action: Actions.RootCauseAnalysisActions,
): RootCauseAnalysisStateInterface {
  switch (action.type) {
    case Actions.START_ROOT_CAUSE_ANALYSIS_DATA_LOADING:
      return {
        ...state,
        dataProgress: 0,
        showManyDataWarning: false,
        rootCauseAnalysisData: [],
        isRootCauseAnalysisDataLoading: true,
        isRootCauseAnalysisDataLoaded: false,
      };

    case Actions.ROOT_CAUSE_ANALYSIS_DATA_LOADED:
      return {
        ...state,
        rootCauseAnalysisData: action.data,
        showManyDataWarning: false,
        isRootCauseAnalysisDataLoaded: true,
        isRootCauseAnalysisDataLoading: false,
      };

    case Actions.ROOT_CAUSE_ANALYSIS_FETCH_DATA:
      return {
        ...state,
        rootCauseAnalysisData: [],
        showManyDataWarning: false,
        isRootCauseAnalysisDataLoaded: false,
        isRootCauseAnalysisDataLoading: true,
      };

    case Actions.ROOT_CAUSE_ANALYSIS_DATA_PROGRESS_SET:
      return {
        ...state,
        dataProgress: action.progress,
      };

    case Actions.ROOT_CAUSE_ANALYSIS_CHART_DATA_LOAD:
      return {
        ...state,
        isChartDataLoading: true,
        isChartDataLoaded: false,
      };

    case Actions.ROOT_CAUSE_ANALYSIS_CHART_DATA_LOADED:
      return {
        ...state,
        chartData: action.data,
        ...(action.isRegularMode ? { regularChartData: action.data } : {}),
        isChartDataLoaded: true,
        isChartDataLoading: false,
      };

    case Actions.ROOT_CAUSE_ANALYSIS_MANY_DATA_WARNING_SHOW:
      return {
        ...state,
        showManyDataWarning: true,
        rootCauseAnalysisData: [],
        isRootCauseAnalysisDataLoaded: true,
        isRootCauseAnalysisDataLoading: false,
      };

    case Actions.ROOT_CAUSE_ANALYSIS_FETCH_ERROR:
      return {
        ...state,
        showManyDataWarning: false,
        isRootCauseAnalysisDataLoaded: true,
        isRootCauseAnalysisDataLoading: false,
        rootCauseAnalysisData: [],
      };

    case Actions.ROOT_CAUSE_ANALYSIS_CHART_FETCH_ERROR:
      return {
        ...state,
        isChartDataLoaded: true,
        isChartDataLoading: false,
        chartData: [],
      };

    case Actions.ROOT_CAUSE_ANALYSIS_COMPARISON_MODE_CHART_VISIBILITY:
      return {
        ...state,
        isRootCauseAnalysisEquipmentChartVisible: action.isRootCauseAnalysisEquipmentChartVisible,
        isRootCauseAnalysisStackChartVisible: action.isRootCauseAnalysisStackChartVisible,
        isRootCauseAnalysisDataLoading: false,
        isRootCauseAnalysisDataLoaded: false,
      };

    default:
      return state;
  }
}
