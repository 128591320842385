import * as Actions from './kpi-view.actions';
import { KpiViewStateInterface } from './kpi-view.model';

const initialState: KpiViewStateInterface = {
  kpiViewWidgetOeeDataLoading: false,
  kpiViewWidgetOeeDataLoaded: false,
  kpiViewWidgetOeeData: null,
  kpiViewWidgetWorkOrderCountDataLoading: false,
  kpiViewWidgetWorkOrderCountDataLoaded: false,
  kpiViewWidgetWorkOrderCountData: null,
  kpiViewLaborHoursDataLoaded: false,
  kpiViewLaborHoursDataLoading: false,
  kpiViewLaborHoursData: null,
  kpiViewChartOeeDataLoaded: false,
  kpiViewChartOeeDataLoading: false,
  kpiViewChartOeeData: null,
  kpiViewChartWorkOrderCountDataLoaded: false,
  kpiViewChartWorkOrderCountDataLoading: false,
  kpiViewChartWorkOrderCountData: null,
  tableSettings: [],
};

export function kpiViewReducer(
  state: KpiViewStateInterface = initialState,
  action: Actions.KpiViewActions,
): KpiViewStateInterface {
  switch (action.type) {
    case Actions.KPI_VIEW_WIDGET_OEE_DATA_LOADING:
      return {
        ...state,
        ...{
          kpiViewWidgetOeeDataLoading: true,
          kpiViewWidgetOeeDataLoaded: false,
        },
      };
    case Actions.KPI_VIEW_WIDGET_OEE_DATA_LOADED:
      return {
        ...state,
        ...{
          kpiViewWidgetOeeDataLoading: false,
          kpiViewWidgetOeeDataLoaded: true,
          kpiViewWidgetOeeData: action.data,
        },
      };
    case Actions.KPI_VIEW_WIDGET_OEE_DATA_CLEAR:
      return {
        ...state,
        ...{
          kpiViewWidgetOeeDataLoading: false,
          kpiViewWidgetOeeDataLoaded: false,
          kpiViewWidgetOeeData: null,
        },
      };
    case Actions.KPI_VIEW_WIDGET_WORK_ORDER_COUNT_DATA_LOADING:
      return {
        ...state,
        ...{
          kpiViewWidgetWorkOrderCountDataLoading: true,
          kpiViewWidgetWorkOrderCountDataLoaded: false,
        },
      };
    case Actions.KPI_VIEW_WIDGET_WORK_ORDER_COUNT_DATA_LOADED:
      return {
        ...state,
        ...{
          kpiViewWidgetWorkOrderCountDataLoading: false,
          kpiViewWidgetWorkOrderCountDataLoaded: true,
          kpiViewWidgetWorkOrderCountData: action.data,
        },
      };
    case Actions.KPI_VIEW_WIDGET_WORK_ORDER_COUNT_DATA_CLEAR:
      return {
        ...state,
        ...{
          kpiViewWidgetWorkOrderCountDataLoading: false,
          kpiViewWidgetWorkOrderCountDataLoaded: false,
          kpiViewWidgetWorkOrderCountData: null,
        },
      };
    case Actions.KPI_VIEW_CHART_OEE_DATA_LOADING:
      return {
        ...state,
        ...{
          kpiViewChartOeeDataLoading: true,
          kpiViewChartOeeDataLoaded: false,
        },
      };
    case Actions.KPI_VIEW_CHART_OEE_DATA_LOADED:
      return {
        ...state,
        ...{
          kpiViewChartOeeDataLoading: false,
          kpiViewChartOeeDataLoaded: true,
          kpiViewChartOeeData: action.data,
        },
      };
    case Actions.KPI_VIEW_CHART_OEE_DATA_CLEAR:
      return {
        ...state,
        ...{
          kpiViewChartOeeDataLoading: false,
          kpiViewChartOeeDataLoaded: false,
          kpiViewChartOeeData: null,
        },
      };
    case Actions.KPI_VIEW_CHART_WORK_ORDER_COUNT_DATA_LOADING:
      return {
        ...state,
        ...{
          kpiViewChartWorkOrderCountDataLoading: true,
          kpiViewChartWorkOrderCountDataLoaded: false,
        },
      };
    case Actions.KPI_VIEW_CHART_WORK_ORDER_COUNT_DATA_LOADED:
      return {
        ...state,
        ...{
          kpiViewChartWorkOrderCountDataLoading: false,
          kpiViewChartWorkOrderCountDataLoaded: true,
          kpiViewChartWorkOrderCountData: action.data,
        },
      };
    case Actions.KPI_VIEW_CHART_WORK_ORDER_COUNT_DATA_CLEAR:
      return {
        ...state,
        ...{
          kpiViewChartWorkOrderCountDataLoading: false,
          kpiViewChartWorkOrderCountDataLoaded: false,
          kpiViewChartWorkOrderCountData: null,
        },
      };
    case Actions.KPI_VIEW_LABOR_HOURS_DATA_LOADING:
      return {
        ...state,
        ...{
          kpiViewLaborHoursDataLoading: true,
          kpiViewLaborHoursDataLoaded: false,
        },
      };
    case Actions.KPI_VIEW_LABOR_HOURS_DATA_LOADED:
      return {
        ...state,
        ...{
          kpiViewLaborHoursDataLoading: false,
          kpiViewLaborHoursDataLoaded: true,
          kpiViewLaborHoursData: action.data,
        },
      };
    case Actions.KPI_VIEW_LABOR_HOURS_DATA_CLEAR:
      return {
        ...state,
        ...{
          kpiViewLaborHoursDataLoading: false,
          kpiViewLaborHoursDataLoaded: false,
          kpiViewLaborHoursData: null,
        },
      };
    case Actions.SET_TABLE_SETTINGS:
      return {
        ...state,
        ...{
          tableSettings: action.payload,
        },
      };
    case Actions.KPI_VIEW_CLEAR_CHILD_COMPONENT_STATES:
    case Actions.KPI_VIEW_FETCH_ERROR:
      return {
        ...state,
        ...{
          kpiViewWidgetOeeDataLoading: false,
          kpiViewWidgetOeeDataLoaded: false,
          kpiViewWidgetWorkOrderCountDataLoading: false,
          kpiViewWidgetWorkOrderCountDataLoaded: false,
          kpiViewChartOeeDataLoading: false,
          kpiViewChartOeeDataLoaded: false,
          kpiViewChartWorkOrderCountDataLoading: false,
          kpiViewChartWorkOrderCountDataLoaded: false,
          kpiViewLaborHoursDataLoading: false,
          kpiViewLaborHoursDataLoaded: false,
        },
      };
    default:
      return state;
  }
}
