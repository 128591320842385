import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  children?: ChildrenItems[];
}

export interface MainMenuItems {
  state: string;
  short_label?: string;
  main_state?: string;
  target?: boolean;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

export interface Menu {
  label: string;
  main: MainMenuItems[];
}

const MENUITEMS = [
  {
    label: 'Dashboards',
    main: [
      {
        state: 'dashboards',
        name: 'Dashboards',
        type: 'sub',
        icon: 'fa fa-tachometer',
        children: [
          {
            state: 'livedashboard',
            name: 'Line View',
            type: 'link',
          },
          {
            state: 'batchdashboard',
            name: 'Work Order View',
            type: 'link',
          },
          {
            state: 'kpidashboard',
            name: 'KPI View',
            type: 'link',
          },
          {
            state: 'activityview',
            name: 'Activity View',
            type: 'link',
          },
          {
            state: 'weeklyperformanceview',
            name: 'Weekly Performance View',
            type: 'link',
          },
        ],
      },
    ],
  },
  {
    label: 'Reports',
    main: [
      {
        state: 'reports',
        name: 'Reports',
        type: 'sub',
        icon: 'fa fa-file-text-o',
        children: [
          {
            state: '#',
            name: 'OEE Reports',
            type: 'sub',
            children: [
              {
                state: '#',
                name: 'Summary OEE Report',
                type: 'link',
              },
              {
                state: '#',
                name: 'Site OEE Report',
                type: 'link',
              },
              {
                state: '#',
                name: 'OEE Deep-dive Analysis',
                type: 'link',
              },
              {
                state: '#',
                name: 'OEE Calendar',
                type: 'link',
              },
            ],
          },
          {
            state: '#',
            name: 'Performance & Utilization Reports',
            type: 'sub',
            children: [
              {
                state: '#',
                name: 'Work Order Performance',
                type: 'link',
              },
              {
                state: '#',
                name: 'Task Performance',
                type: 'link',
              },
              {
                state: '#',
                name: 'Capacity Report',
                type: 'link',
              },
              {
                state: '#',
                name: 'Daily Performance Summary',
                type: 'link',
              },
              {
                state: '#',
                name: 'Root Cause Analysis',
                type: 'link',
              },
              {
                state: '#',
                name: 'Maintenance Performance',
                type: 'link',
              },
            ],
          },
          {
            state: '#',
            name: 'Activity & Log Based Reports',
            type: 'sub',
            children: [
              {
                state: '#',
                name: 'Activity Logs',
                type: 'link',
              },
              {
                state: '#',
                name: 'Activity Timeline',
                type: 'link',
              },
              {
                state: '#',
                name: 'Exception Report',
                type: 'link',
              },
              {
                state: '#',
                name: 'Sensor Report',
                type: 'link',
              },
            ],
          }, {
            state: '#',
            name: 'Finance Reports',
            type: 'sub',
            children: [
              {
                state: '#',
                name: 'Finance Report',
                type: 'link',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    label: 'Schedule',
    main: [
      {
        state: 'schedule',
        name: 'Schedule',
        type: 'sub',
        icon: 'fa fa-calendar',
        children : [
          {
            state: '#',
            name: 'Work Order Schedule',
            type: 'link',
          },
        ],
      },
    ],
  },
  {
    label: 'Settings',
    main: [
      {
        state: 'settings',
        name: 'Settings',
        type: 'sub',
        icon: 'fa fa-cogs',
        children : [
          {
            state: '#',
            name: 'General',
            type: 'sub',
            children :[
              {
                state: '#',
                name: 'Users',
                type: 'link',
              },
              {
                state: '#',
                name: 'Sites',
                type: 'link',
              },
              {
                state: '#',
                name: 'Activities',
                type: 'link',
              },
              {
                state: '#',
                name: 'Tasks',
                type: 'link',
              },
              {
                state: '#',
                name: 'KPI Targets',
                type: 'link',
              },
            ],
          },
          {
            state: '#',
            name: 'Product (Material)',
            type: 'sub',
            children: [
              {
                state: '#',
                name: 'Products (Materials)',
                type: 'link',
              },
              {
                state: '#',
                name: 'Product Speed',
                type: 'link',
              },
              {
                state: '#',
                name: 'Customers',
                type: 'link',
              },
            ],
          },
          {
            state: '#',
            name: 'Resource',
            type: 'sub',
            children: [
              {
                state: '#',
                name: 'Lines',
                type: 'link',
              },
              {
                state: '#',
                name: 'Resource Groups',
                type: 'link',
              },
              // {
              //   state: '#',
              //   name: 'Line Types',
              //   type: 'link',
              // },
              {
                state: '#',
                name: 'Shifts',
                type: 'link',
              },
              {
                state: '#',
                name: 'Crew Management',
                type: 'link',
              },
            ],
          },
          {
            state: '#',
            name: 'Hardware',
            type: 'sub',
            children: [
              {
                state: '#',
                name: 'Equipment Management',
                type: 'link',
              },
              {
                state: '#',
                name: 'Sensor Management',
                type: 'link',
              },
            ],
          },
          {
            state: '#',
            name: 'Notification',
            type: 'sub',
            children: [
              {
                state: '#',
                name: 'Notification Center',
                type: 'link',
              },
              {
                state: '#',
                name: 'Alerts',
                type: 'link',
              },
            ],
          },
        ],
      },
    ],
  },
];

@Injectable()
export class MenuItems {
  getAll(): Menu[] {
    return MENUITEMS;
  }
}
