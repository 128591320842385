import { Inject, Injectable } from '@angular/core';
import { FilterServiceInterface, RootCauseTypeCRUDResponseInterface } from './service.class';
import { HttpClient, HttpParams } from '@angular/common/http';
import { RootCauseTypeCRUDInterface } from '../../component/filter/filter.class';

@Injectable({
  providedIn: 'root',
})
export class RootCauseTypeService implements FilterServiceInterface {
  constructor(public http: HttpClient, @Inject('API_BASE_URL') private readonly baseUrl: string) {}

  getData(options?: HttpParams): Promise<RootCauseTypeCRUDInterface[]> {
    return new Promise((resolve, reject) => {
      this.http
        .get(`${this.baseUrl}/root-cause-types`, { params: options })
        .subscribe((response: RootCauseTypeCRUDResponseInterface) => {
          if (response.hasOwnProperty('data')) {
            resolve(response.data);
          } else {
            reject();
          }
        });
    });
  }
}
