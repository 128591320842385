import { SensorStateInterface } from './sensor-management.model';
import { SensorActions, SensorActionTypes } from './sensor-management.actions';

export const initialState: SensorStateInterface = {
  sensorsLoading: false,
  sensorsLoaded: false,
};

export function sensorReducer(
  state: SensorStateInterface = initialState,
  action: SensorActions,
): SensorStateInterface {
  switch (action.type) {
    case SensorActionTypes.LoadSensors:
      return {
        ...state,
        sensorsLoading: true,
        sensorsLoaded: false,
      };

    case SensorActionTypes.SensorsLoaded:
      return {
        ...state,
        sensorsLoading: false,
        sensorsLoaded: true,
      };

    default:
      return state;
  }
}
