import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  GetManyHomeScreenMetricCrudResponse,
  GetOneHomeScreenMetricCrudResponse, GetSitesCrudResponse,
  HomeScreenMetricSetInterface,
} from './home-screen-metric-settings.model';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class HomeScreenMetricService {
  constructor(public http: HttpClient, @Inject('API_BASE_URL') private baseUrl: string) {}

  private readonly HOME_SCREEN_METRICS = `${this.baseUrl}/home-metric-sets`;

  getHomeScreenMetricSets(httpParams: HttpParams): Observable<GetManyHomeScreenMetricCrudResponse> {
    return this.http.get<GetManyHomeScreenMetricCrudResponse>(this.HOME_SCREEN_METRICS, {
      params: httpParams,
    });
  }

  createHomeScreenMetricSets(
    requestBody: HomeScreenMetricSetInterface,
  ): Observable<GetOneHomeScreenMetricCrudResponse> {
    return this.http.post<GetOneHomeScreenMetricCrudResponse>(this.HOME_SCREEN_METRICS, requestBody);
  }

  deleteHomeScreenMetricSets(id: number): Observable<GetOneHomeScreenMetricCrudResponse> {
    return this.http.delete<GetOneHomeScreenMetricCrudResponse>(`${this.HOME_SCREEN_METRICS}/${id}`);
  }

  getHomeScreenMetricsTreeList(httpParams: HttpParams, siteId: number | null = null): Observable<GetSitesCrudResponse> {
    const observables = [
      this.http.get<any>(`${this.baseUrl}/sites`, {
        params: new HttpParams().set('limit', '1000'),
      }),
      ...(siteId ? [this.http.get<any>(`${this.baseUrl}/lines`, { params: httpParams })] : []),
    ];

    return forkJoin(observables).pipe(
      map((resolvedObservables) => {
        const [sites, lines] = resolvedObservables;
        const linesGroupedBySiteId = _.groupBy(_.get(lines, 'data', []), 'siteId');

        for (const site of sites.data) {
          Object.assign(site, {
            line: _.get(linesGroupedBySiteId, site.id, []),
          });
        }

        return sites;
      }),
    );
  }

  updateHomeScreenMetricSet(
    id: number,
    requestBody: HomeScreenMetricSetInterface,
  ): Observable<GetOneHomeScreenMetricCrudResponse> {
    return this.http.patch<GetOneHomeScreenMetricCrudResponse>(`${this.HOME_SCREEN_METRICS}/${id}`, requestBody);
  }

  getHomeScreenMetricInformation(id: number, params: HttpParams): Observable<GetOneHomeScreenMetricCrudResponse> {
    return this.http.get<GetOneHomeScreenMetricCrudResponse>(`${this.HOME_SCREEN_METRICS}/${id}`, {
      params,
    });
  }
}
