import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import * as ObjectActions from './startOfDay.action';
import { User } from '../../user/model';
import moment from 'moment';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../oee.reducer';
import { DropdownOptionsInterface } from '../../../view/settings/tasks/tasks.model';

@Injectable()
export class FilterStartOfDayEffects {
  loadFilterStartOfDay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.FilterStartOfDayActionTypes.StartLoadFilterStartOfDay),
      map(() => {
        const formattedHours = this.getHoursWithUserFormat();
        return new ObjectActions.FilterStartOfDayLoaded(formattedHours);
      }),
    ),
  );

  private getHoursWithUserFormat(): DropdownOptionsInterface[] {
    const data: DropdownOptionsInterface[] = [];

    const userStore = this.store.select('user').subscribe((state: User) => {
      for (let i = 0; i < 24; i += 1) {
        const hour = moment().set({ hour: i, minute: 0, second: 0 }).locale(state.locale).format('LT');
        data.push({ id: i, name: hour });
      }
    });
    userStore.unsubscribe();

    return data;
  }

  constructor(private actions$: Actions<ObjectActions.FilterStartOfDayActions>, private store: Store<OeeAppState>) {}
}
