import { AssetViewStateInterface } from './asset-view.model';
import * as Actions from './asset-view.actions';
import * as _ from 'lodash';

export const assetViewState: AssetViewStateInterface = {
  assetViewDashboardMailExportLoaded: false,
  assetViewDashboardMailExportLoading: false,
  dashboardData: null,
  assetViewDashboardDataLoaded: false,
  assetViewDashboardDataLoading: false,
  assetViewFilterDataLoaded: false,
  assetViewFilterDataLoading: false,
  filters: null,
};

export function assetViewReducer(
  state: AssetViewStateInterface = assetViewState,
  action: Actions.AssetViewActions,
): AssetViewStateInterface {
  switch (action.type) {
    case Actions.ASSET_VIEW_FILTER_DATA_LOADING:
      return {
        ...state,
        ...{ assetViewFilterDataLoading: true, assetViewFilterDataLoaded: false },
      };
    case Actions.ASSET_VIEW_FILTER_DATA_LOADED:
      return {
        ...state,
        ...{
          filters: _.cloneDeep(action.payload.data),
          assetViewFilterDataLoading: false,
          assetViewFilterDataLoaded: true,
        },
      };
    case Actions.ASSET_VIEW_DASHBOARD_DATA_LOADING:
      return {
        ...state,
        ...{ assetViewDashboardDataLoading: true, assetViewDashboardDataLoaded: false },
      };
    case Actions.ASSET_VIEW_DASHBOARD_DATA_LOADED:
      return {
        ...state,
        ...{
          dashboardData: _.cloneDeep(action.payload.data),
          assetViewDashboardDataLoading: false,
          assetViewDashboardDataLoaded: true,
        },
      };
    case Actions.ASSET_VIEW_DASHBOARD_MAIL_EXPORT_LOADING:
      return {
        ...state,
        ...{ assetViewDashboardMailExportLoading: true, assetViewDashboardMailExportLoaded: false },
        ...{ assetViewFilterDataLoading: false, assetViewFilterDataLoaded: false },
      };
    case Actions.ASSET_VIEW_DASHBOARD_MAIL_EXPORT_LOADED:
      return {
        ...state,
        ...{
          assetViewDashboardMailExportLoading: false,
          assetViewDashboardMailExportLoaded: true,
        },
      };
    case Actions.ASSET_VIEW_FETCH_ERROR:
      return {
        ...state,
      };
    default:
      return state;
  }
}
