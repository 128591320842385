import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { forkJoin, from, of } from 'rxjs';
import * as ObjectActions from './scheduler-resource-setting.actions';
import { HttpClient, HttpParams } from '@angular/common/http';
import { PresetLinesInterface } from './scheduler-resource-setting.model';
import { SchedulerResourceSettingService } from '../../shared/service/scheduler/scheduler-resource-setting.service';
import * as AppActions from '../app/actions';
import { PlanDataService } from '../../shared/service/line-availability/plan-data.service';
import { ActivitiesService } from '../../shared/service/activities/activities.service';
import {
  LineAvailabilityPlanDataInterface,
  LineAvailabilityResponseInterface,
  TableQueryParams,
} from '../line-availability/line-availability.model';
import { ActivitiesInterface } from '../../shared/model/interface/activities.model';
import { generateHttpOption } from '../../shared/helper/app-helper';
import { HttpOptionInterface } from '../filter/filter.model';
import { ErrorMessageService } from '../../shared/service/error-message.service';
import { TranslateService } from '@ngx-translate/core';
import {
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../shared/model/interface/crud-response-interface.model';
import { HelperService } from '../../shared/service/helper.service';
import { LineCRUDResponseInterface } from '../../shared/service/filter/service.class';
import { SiteCRUDInterface } from '../../shared/component/filter/filter.class';
import { SiteService } from '../../shared/service/filter/site.service';
import { LineService } from '../../shared/service/filter/line.service';
import * as _ from 'lodash';

@Injectable()
export class SchedulerResourceSettingEffects {
  constructor(
    private actions$: Actions<ObjectActions.SchedulerResourceSettingActions>,
    private http: HttpClient,
    private schedulerResourceSettingService: SchedulerResourceSettingService,
    private planDataService: PlanDataService,
    private activitiesService: ActivitiesService,
    private readonly errorMessageService: ErrorMessageService,
    private readonly translate: TranslateService,
    private readonly helperService: HelperService,
    private readonly siteService: SiteService,
    private readonly lineService: LineService,
  ) {}

  $fetchLineViewFilterData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SchedulerResourceSettingActionTypes.LoadSitesAndLines),
      switchMap(() => {
        const httpParams = new HttpParams().set('limit', '1000');

        return forkJoin([
          this.siteService.getSites(httpParams),
          this.lineService.getLines(httpParams.append('join', 'lineTypeName')),
        ]).pipe(
          switchMap((response: (GetManyResponseInterface<SiteCRUDInterface> | LineCRUDResponseInterface)[]) => {
            return of(
              new ObjectActions.SitesAndLinesLoaded({
                sites: _.get(response, '0.data', []),
                lines: _.get(response, '1.data', []),
              }),
            );
          }),
        );
      }),
    ),
  );

  $loadPresets = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SchedulerResourceSettingActionTypes.LoadPresets),
      switchMap((loadPreset: ObjectActions.LoadPresets) => {
        const siteFilter = { 'schSettingLine.line.siteId': { $eq: loadPreset.siteId } };

        let params = new HttpParams()
          .append('join', 'schScenario||id,name')
          .append('join', 'schSettingLine||lineId')
          .append('join', 'schSettingLine.line||siteId');

        if (loadPreset.siteId !== -1) {
          params = params.set('s', JSON.stringify(siteFilter));
        }
        params = params.set('fields', 'id,name').set('limit', '500');

        return this.schedulerResourceSettingService.getResourceSettings(params).pipe(
          switchMap((resData) => {
            return of(new ObjectActions.PresetsLoaded(resData.data), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  $loadSchedulerShiftPlans = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SchedulerResourceSettingActionTypes.LoadSchedulerResourceSettingShiftPlans),
      switchMap((objectData: ObjectActions.LoadSchedulerResourceSettingShiftPlans) => {
        let dataParameters: TableQueryParams = {
          page: objectData.page,
          pageSize: objectData.limit,
        };

        if (objectData.search !== undefined) {
          dataParameters = {
            ...dataParameters,
            search: objectData.search,
          };
        }

        return from(this.planDataService.getData(dataParameters)).pipe(
          switchMap((response: LineAvailabilityResponseInterface<LineAvailabilityPlanDataInterface>) => {
            return of(new ObjectActions.SchedulerResourceSettingShiftPlansLoaded(response.data));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  $GetSelectedPreset = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SchedulerResourceSettingActionTypes.GetSelectedPreset),
      switchMap((actionData: ObjectActions.GetSelectedPreset) => {
        const params = new HttpParams()
          .append('join', 'schSettingItem')
          .append('join', 'schSettingLine')
          .append('fields', 'id,name');

        return this.schedulerResourceSettingService.getResourceSetting(actionData.id, params).pipe(
          switchMap((resData) => {
            return of(new ObjectActions.GetSelectedPresetLoaded(resData.data), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  $loadActivities = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SchedulerResourceSettingActionTypes.LoadActivities),
      switchMap((objectData: ObjectActions.LoadActivities) => {
        const dataParameters: HttpOptionInterface = {
          page: objectData.page,
          limit: objectData.limit,
          s: objectData.search,
        };
        const params = generateHttpOption(dataParameters);
        return this.activitiesService.getActivities(params).pipe(
          switchMap((response: GetManyResponseInterface<ActivitiesInterface>) => {
            return of(new ObjectActions.ActivitiesLoaded(response.data));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  $DeleteSelectedPreset = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SchedulerResourceSettingActionTypes.DeleteSelectedPreset),
      switchMap((actionData: ObjectActions.DeleteSelectedPreset) => {
        return this.schedulerResourceSettingService.deleteResourceSetting(actionData.id).pipe(
          switchMap(() => {
            return of(new ObjectActions.DeleteSelectedPresetLoaded(), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  $CreatedPreset = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SchedulerResourceSettingActionTypes.CreatePreset),
      switchMap((actionData: ObjectActions.CreatePreset) => {
        return this.schedulerResourceSettingService.createResourceSetting(actionData.payload.name).pipe(
          switchMap((resData) => {
            const schSettingItem = {
              ...HelperService.cloneDeep(actionData.payload.schSettingItem),
              ...{
                settingId: resData.data.id,
              },
            };
            const schSettingLines = {
              schSettingLines: HelperService.cloneDeep(actionData.payload.schSettingLine).map(
                (line: PresetLinesInterface) => Object.assign(line, { settingId: resData.data.id }),
              ),
            };

            return of(
              new ObjectActions.CreatePresetItems(schSettingItem),
              new ObjectActions.CreatePresetLines(schSettingLines),
              new ObjectActions.CreatePresetLoaded(resData.data),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  $CreatedPresetItems = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SchedulerResourceSettingActionTypes.CreatePresetItems),
      switchMap((actionData: ObjectActions.CreatePresetItems) => {
        return this.schedulerResourceSettingService.createResourceSettingItems(actionData.payload).pipe(
          switchMap(() => {
            return of(new ObjectActions.CreatePresetItemsLoaded());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  $CreatedPresetLines = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SchedulerResourceSettingActionTypes.CreatePresetLines),
      switchMap((actionData: ObjectActions.CreatePresetLines) => {
        return this.schedulerResourceSettingService.createResourceSettingLines(actionData.payload).pipe(
          switchMap((response) => {
            this.toastBulkErrors(response);

            return of(new ObjectActions.CreatePresetLinesLoaded());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  $UpdatedPreset = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SchedulerResourceSettingActionTypes.UpdatePreset),
      switchMap((actionData: ObjectActions.UpdatePreset) => {
        const { id, name } = actionData.payload;
        return this.schedulerResourceSettingService.updateResourceSetting(id, name).pipe(
          switchMap((resData) => {
            const schSettingItem = {
              ...actionData.payload.schSettingItem,
              ...{
                settingId: resData.data.id,
              },
            };
            delete schSettingItem.id;

            return of(
              new ObjectActions.UpdatePresetItems(actionData.payload.schSettingItem.id, schSettingItem),
              new ObjectActions.UpdatePresetLines({ schSettingLines: actionData.payload.updatedLines }),
              new ObjectActions.CreatePresetLines({ schSettingLines: actionData.payload.newLines }),
              new ObjectActions.DeletePresetLines(actionData.payload.removedLines),
              new ObjectActions.UpdatePresetLoaded(resData.data),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  $UpdatedPresetItems = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SchedulerResourceSettingActionTypes.UpdatePresetItems),
      switchMap((actionData: ObjectActions.UpdatePresetItems) => {
        return this.schedulerResourceSettingService
          .updateResourceSettingItems(actionData.id, actionData.presetItems)
          .pipe(
            switchMap(() => {
              return of(new ObjectActions.UpdatePresetItemsLoaded(), new AppActions.HideLoader());
            }),
            catchError((errorRes) => {
              return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
            }),
          );
      }),
    ),
  );

  $UpdatedPresetLines = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SchedulerResourceSettingActionTypes.UpdatePresetLines),
      switchMap((actionData: ObjectActions.UpdatePresetLines) => {
        return this.schedulerResourceSettingService.updateResourceSettingLines(actionData.payload).pipe(
          switchMap((response) => {
            this.toastBulkErrors(response);

            return of(new ObjectActions.UpdatePresetLinesLoaded(), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  $DeletedPresetLines = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SchedulerResourceSettingActionTypes.DeletePresetLines),
      switchMap((actionData: ObjectActions.DeletePresetLines) => {
        return this.schedulerResourceSettingService.deleteResourceSettingLines(actionData.ids).pipe(
          switchMap((response) => {
            this.toastBulkErrors(response);

            return of(new ObjectActions.DeletePresetLinesLoaded(), new ObjectActions.LoadPresets(false));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  private toastBulkErrors(response: BulkResponseDataInterface): void {
    if (response && response.data) {
      this.errorMessageService.getTranslatedErrorMessage(response.data);
      for (const message of response.data) {
        if (!message.success) {
          this.helperService.showToastMessage(
            message.success,
            this.translate.instant('general.failed'),
            (message.message || []).join(', '),
          );
        }
      }
    }
  }
}
