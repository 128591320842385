import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { WorkOrdersSummaryResponseInterface } from '../../../store/work-orders-summary/work-orders-summary.model';

@Injectable({
  providedIn: 'root',
})
export class GetWorkOrdersSummaryService {
  constructor(public http: HttpClient, @Inject('API_BASE_URL') private readonly baseUrl: string) {}

  public getWorkOrdersSummaryAndTaskGroups(parameters: HttpParams): Promise<WorkOrdersSummaryResponseInterface> {
    return new Promise((resolve, reject) => {
      this.http
        .get(`${this.baseUrl}/get-work-orders-summary-and-task-groups/`, {
          params: parameters,
        })
        .subscribe(
          (response: WorkOrdersSummaryResponseInterface) => {
            const success = response.success;
            if (success) {
              resolve(response);
            } else {
              reject();
            }
          },
          (error: HttpErrorResponse) => {
            reject(error);
          },
        );
    });
  }
}
