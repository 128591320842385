import { TreeCheckboxComponentNodeInterface } from '../../shared/component/tree-checkbox/tree-checkbox.component';

export interface HomeScreenMetricSettingsInterface {
  homeScreenMetricSets: HomeScreenMetricSetInterface[];
  homeScreenMetricSetDataCount: number;
  homeScreenMetricSetCurrentPage: number;
  homeScreenMetricSetRowsPerPage: number;
  homeScreenMetricsDataLoading: boolean;
  startHomeScreenMetricsDataLoaded: boolean;
  homeScreenMetricCopyLoading: boolean;
  homeScreenMetricCopyLoaded: boolean;
  homeScreenMetricDeleteLoading: boolean;
  homeScreenMetricDeleteLoaded: boolean;
  pagination: PaginationInterface;
  homeScreenMetricOneResponse: GetOneHomeScreenMetricCrudResponse;
  homeScreenMetricTreeListLoading: boolean;
  homeScreenMetricTreeListLoaded: boolean;
  homeScreenMetricUpdateLoading: boolean;
  homeScreenMetricUpdateLoaded: boolean;
  homeScreenMetricCreateLoading: boolean;
  homeScreenMetricCreateLoaded: boolean;
  homeScreenMetricInformationLoading: boolean;
  startHomeScreenMetricInformationLoaded: boolean;
  homeScreenMetricTreeList: HomeScreenMetricTreeListInterface;
  homeScreenMetricSelected: HomeScreenMetricSetInterface;
}

export interface HomeScreenMetricSetInterface {
  id?: number;
  name: string;
  siteId?: number;
  description?: string;
  isDefault?: boolean;
  homeMetricSetId?: number;
  homeMetricSetAssignment?: HomeScreenMetricAssignmentInterface[];
  homeMetricSetItem?: HomeMetricSetItemInterface[];
}

export interface HomeScreenMetricAssignmentInterface {
  id?: number;
  siteId?: number;
  lineId?: number;
  line?: HomeScreenMetricAssignedLineInterface[];
  homeMetricSet?: HomeScreenMetricSetInterface;
  homeMetricSetId?: number;
}

export interface HomeScreenMetricAssignedLineInterface {
  id: number;
  title: string;
}

export interface BaseCrudResponse {
  success: boolean;
  date: string;
  message?: {
    statusCode?: number;
    error?: string;
    message?: string;
  };
}

export interface GetManyHomeScreenMetricCrudResponse extends BaseCrudResponse {
  data?: HomeScreenMetricSetInterface[];
  count?: number;
  page?: number;
  total?: number;
  pageCount?: number;
}

export interface GetOneHomeScreenMetricCrudResponse extends BaseCrudResponse {
  data?: HomeScreenMetricSetInterface;
}

export interface GetSitesCrudResponse extends BaseCrudResponse {
  data?: HomeScreenMetricSiteInterface[];
  count?: number;
  page?: number;
  total?: number;
  pageCount?: number;
}

export interface PaginationInterface {
  page: number;
  sort: string;
  limit: number;
}

export interface HomeScreenMetricTreeListInterface {
  sites: TreeCheckboxComponentNodeInterface[];
  departmentsAndLines: TreeCheckboxComponentNodeInterface[];
}

export interface HomeScreenMetricSiteInterface {
  id: number;
  name?: string;
  checked?: boolean;
  line?: HomeScreenMetricLineInterface[];
}

export interface HomeScreenMetricLineInterface {
  id: number;
  title?: string;
  name?: string;
  checked: boolean;
  homeMetricSetAssignment: HomeScreenMetricAssignmentInterface;
  lineTypeName: HomeScreenMetricLineTypeInterface;
  status: {
    description: string;
  };
}

export interface HomeScreenMetricLineTypeInterface {
  id: number;
  lineType?: string;
  order?: number;
}

export interface HomeMetricSetItemInterface {
  id?: number;
  homeMetricSetId?: string;
  order?: number;
  metric?: string;
}

export interface GetManyHomeMetricSetAssignmentCrudResponse extends BaseCrudResponse {
  data?: HomeScreenMetricAssignmentInterface[];
  count?: number;
  page?: number;
  total?: number;
  pageCount?: number;
}

export interface DatatableParamsInterface {
  page?: number;
  rowsPerPage?: number;
  search?: string;
  sort?: {
    column?: string;
    type?: string;
  };
}

export interface PhaseDurationsInterface {
  preRunDuration: number;
  preRunTarget: string;
  preRunActivity: string;
  runDuration: number;
  runTarget: string;
  runRunActivity: string;
  postRunDuration: number;
  postRunTarget: string;
  postRunActivity: string;
  totalDuration: number;
  totalDurationTarget: number;
  selectedPhase: number;
}

export interface PhaseDurationsResponseInterface {
  data: PhaseDurationInterface[];
}

export enum PhaseDurationTypeEnum {
  preRunDuration = 'preRunDuration',
  runDuration = 'runDuration',
  postRunDuration = 'postRunDuration',
  totalDuration = 'totalDuration',
}

export interface PhaseDurationInterface {
  phaseName: string;
  phaseDuration: string;
  phaseTargetDuration: string;
  isCurrent: boolean;
  activityName: string;
  phaseDurationType: PhaseDurationTypeEnum;
}

export interface PhaseDurationMetricInterface {
  phaseTimer: string;
  phaseText: string;
  phaseDurationText: string;
  phaseDurationRateCapped: number;
  phaseDurationRate: string;
  phaseDurationTextClass: string;
  roundedTarget: string;
  progressBarClass: string;
  phaseDurationTextIcon: string;
  unit: string;
}
