import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as ObjectActions from './sensor-management.actions';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../oee.reducer';
import { SensorManagementService } from '../../shared/service/sensor-management/sensor-management.service';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../shared/model/interface/crud-response-interface.model';
import { LineDropdownOption, SensorCRUDInterface, SensorFormDropdownData } from './sensor-management.model';
import * as AppActions from '../app/actions';
import { GenericCrudRequestConstructionParameters } from '../../shared/model/interface/generic-api-request.model';
import { SensorTypeEnum } from '../../../constants';
import { EntityTranslatorService } from '../../shared/service/entity-translator/entity-translator.service';
import { IEquipmentAssignment } from '../settings/equipment-assignment/equipment-assignment.model';

@Injectable()
export class SensorEffects {
  constructor(
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly actions$: Actions<ObjectActions.SensorActions>,
    private readonly service: SensorManagementService,
    private translatorService: EntityTranslatorService,
  ) {}

  $loadSensors = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SensorActionTypes.LoadSensors),
      switchMap((objectData: ObjectActions.LoadSensors) => {
        return this.service.loadSensors(objectData.parameters).pipe(
          switchMap((response: GetManyResponseInterface<SensorCRUDInterface>) => {
            return of(new ObjectActions.SensorsLoaded(response));
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error));
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error));
      }),
    ),
  );

  $loadFormDropdownData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SensorActionTypes.LoadFormDropdownData),
      switchMap(() => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.loadFormData().pipe(
          switchMap((response: SensorFormDropdownData) => {
            response.lines.data.forEach((lineDropdownOption: LineDropdownOption) => {
              lineDropdownOption.equipmentAssignment.forEach((equipmentAssignment: IEquipmentAssignment) => {
                this.translatorService.translate(equipmentAssignment);
              });
            });

            return of(new ObjectActions.FormDropdownDataLoaded(response), new AppActions.HideLoader());
          }),
          catchError(() => {
            return of(new AppActions.HideLoader());
          }),
        );
      }),
      catchError(() => {
        return of(new AppActions.HideLoader());
      }),
    ),
  );

  $createSensor = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SensorActionTypes.CreateSensor),
      switchMap((objectData: ObjectActions.CreateSensor) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.createSensor(objectData.requestParameters).pipe(
          switchMap((response: BaseOneResponseInterface<SensorCRUDInterface>) => {
            return of(new ObjectActions.CreateSensorCompleted(response));
          }),
          catchError(() => {
            return of(new AppActions.HideLoader());
          }),
        );
      }),
      catchError(() => {
        return of(new AppActions.HideLoader());
      }),
    ),
  );

  $updateSensor = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SensorActionTypes.UpdateSensor),
      switchMap((objectData: ObjectActions.UpdateSensor) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.updateSensor(objectData.id, objectData.requestParameters).pipe(
          switchMap((response: BaseOneResponseInterface<SensorCRUDInterface>) => {
            return of(new ObjectActions.UpdateSensorCompleted(response));
          }),
          catchError(() => {
            return of(new AppActions.HideLoader());
          }),
        );
      }),
      catchError(() => {
        return of(new AppActions.HideLoader());
      }),
    ),
  );

  $bulkUpdateSensor = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SensorActionTypes.BulkUpdateSensor),
      switchMap((objectData: ObjectActions.BulkUpdateSensor) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.bulkUpdateSensor(objectData.requestParameters).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            return of(new ObjectActions.BulkUpdateSensorCompleted(response));
          }),
          catchError(() => {
            return of(new AppActions.HideLoader());
          }),
        );
      }),
      catchError(() => {
        return of(new AppActions.HideLoader());
      }),
    ),
  );

  $deleteSensor = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SensorActionTypes.DeleteSensor),
      switchMap((objectData: ObjectActions.DeleteSensor) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.deleteSensor(objectData.id).pipe(
          switchMap(() => {
            return of(new ObjectActions.DeleteSensorCompleted());
          }),
          catchError(() => {
            return of(new AppActions.HideLoader());
          }),
        );
      }),
      catchError(() => {
        return of(new AppActions.HideLoader());
      }),
    ),
  );

  $bulkDeleteSensor = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SensorActionTypes.BulkDeleteSensor),
      switchMap((objectData: ObjectActions.BulkDeleteSensor) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.bulkDeleteSensor(objectData.ids).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            return of(new ObjectActions.BulkDeleteSensorCompleted(response));
          }),
          catchError(() => {
            return of(new AppActions.HideLoader());
          }),
        );
      }),
      catchError(() => {
        return of(new AppActions.HideLoader());
      }),
    ),
  );

  $loadCounterSensors = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SensorActionTypes.LoadCounterSensors),
      switchMap((objectData: ObjectActions.LoadCounterSensors) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const search = {
          search: {
            searchText: objectData.searchText,
            searchedFields: ['sensorId'],
          },
        };

        const crudParams: GenericCrudRequestConstructionParameters = {
          ...(objectData.searchText ? search : {}),
          filters: [
            {
              field: 'sensorType',
              ids: [SensorTypeEnum.GOOD_COUNTER, SensorTypeEnum.MID_COUNTER, SensorTypeEnum.TOTAL_COUNTER],
            },
            {
              field: 'lineId',
              ids: [objectData.lineId],
            },
          ],
        };
        return this.service.loadSensors(crudParams).pipe(
          switchMap((response: GetManyResponseInterface<SensorCRUDInterface>) => {
            return of(new ObjectActions.CounterSensorsLoaded(response), new AppActions.HideLoader());
          }),
          catchError(() => {
            return of(new AppActions.HideLoader());
          }),
        );
      }),
      catchError(() => {
        return of(new AppActions.HideLoader());
      }),
    ),
  );
}
