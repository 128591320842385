import { IMyReportsState } from '../../../view/reports/my-reports/my-reports.model';
import * as Actions from './my-reports.actions';

export const myReportsState: IMyReportsState = {
  myReportsData: null,
  myReportsLoading: false,
  myReportsLoaded: false,
};

export function myReportsReducer(
  state: IMyReportsState = myReportsState,
  action: Actions.TMyReportsActions,
): IMyReportsState {
  switch (action.type) {
    case Actions.MyReportsActionTypes.FetchMyReportsData:
      return {
        ...state,
        myReportsLoading: true,
        myReportsLoaded: false,
      };
    case Actions.MyReportsActionTypes.FetchMyReportsDataCompleted:
      return {
        ...state,
        myReportsLoading: false,
        myReportsLoaded: true,
        myReportsData: action.payload,
      };
    default:
      return state;
  }
}
