import * as Actions from './advanced-filter.actions';
import { IAdvancedFilterStore } from './advanced-filter.model';

export const advancedFilterInitialState: IAdvancedFilterStore = {
  isDefaultFiltersLoading: false,
  isDefaultFiltersLoaded: false,
  defaultFilters: {},
};

export const advancedFilterReducer = (
  state: IAdvancedFilterStore = advancedFilterInitialState,
  action: Actions.AdvancedFilterActions,
) => {
  switch (action.type) {
    case Actions.LOAD_DEFAULT_FILTERS:
      return {
        ...state,
        ...{ isDefaultFiltersLoading: true, isDefaultFiltersLoaded: false },
      };

    case Actions.DEFAULT_FILTERS_LOADED:
      return {
        ...state,
        ...{ isDefaultFiltersLoading: false, isDefaultFiltersLoaded: true, defaultFilters: { ...action.payload } },
      };

    default:
      return state;
  }
};
