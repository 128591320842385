import { Action } from '@ngrx/store';
import {
  CostFactorResponseInterface,
  CreateCostFactorInterface,
  EditCostFactorInterface,
  EditPlanInterface,
  EditShiftInterface,
  ExceptionPlanDataInterface,
  LineAvailabilityPlanCostFactorInterface,
  LineAvailabilityPlanDataInterface,
  LineAvailabilityPlanItemInterface,
  LineAvailabilityResponseInterface,
  LineAvailabilityShiftDataInterface,
  PlanInterface,
  ShiftInterface,
  TableQueryParams,
} from './line-availability.model';
import { HttpErrorResponse } from '@angular/common/http';
import { ItemTypeEnum } from '../../shared/service/line-availability/plan-item.service';
import { EventModel } from '@bryntum/scheduler';
import { Task } from '../../view/line-availability/line-availability.model';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
} from '../../shared/model/interface/crud-response-interface.model';

export enum LineAvailabilityActionTypes {
  LoadLineAvailabilityShiftsData = '[LineAvailability] Load Shifts Data',
  LineAvailabilityShiftsDataLoaded = '[LineAvailability] Shifts Data Loaded',
  CreateShift = '[LineAvailability] Create Shift',
  CreateShiftLoaded = '[LineAvailability] Create Shift Loaded',
  EditShift = '[LineAvailability] Edit Shift',
  EditShiftCompleted = '[LineAvailability] Edit Shift Completed',
  DeleteShift = '[LineAvailability] Delete Shift',
  DeleteShiftCompleted = '[LineAvailability] Delete Shift Completed',
  DeleteShifts = '[LineAvailability] Deletes Shift',
  DeleteShiftsCompleted = '[LineAvailability] Delete Shifts Completed',
  LoadLineAvailabilityPlansData = '[LineAvailability] Load Plans Data',
  LineAvailabilityPlansDataLoaded = '[LineAvailability] Plans Data Loaded',
  LoadLineAvailabilityCostFactorsData = '[LineAvailability] Load Cost Factor Data',
  LineAvailabilityCostFactorsDataLoaded = '[LineAvailability] Cost Factor Data Loaded',
  LoadLineAvailabilityExceptionPlan = '[LineAvailability] Load Exception Plans',
  LineAvailabilityExceptionPlanLoaded = '[LineAvailability] Exception Plans Loaded',
  LoadLineAvailabilityExceptionPlanItems = '[LineAvailability] Load Exception Plan Items',
  LineAvailabilityExceptionPlanItemsLoaded = '[LineAvailability] Exception Plan Items Loaded',
  LoadLineAvailabilityDefaultPlanItems = '[LineAvailability] Load Default Plan Items',
  LineAvailabilityDefaultPlanItemsLoaded = '[LineAvailability] Default Plan Items Loaded',
  DefaultPlanItemsPrepared = '[LineAvailability] Default Plan Items Prepared',
  ExceptionPlanItemsPrepared = '[LineAvailability] Exception Plan Items Prepared',
  SavePlanItems = '[LineAvailability] Save Default Plan Items',
  SavePlanItemsLoaded = '[LineAvailability] Save Default Plan Items Loaded',
  DeleteExceptionPlan = '[LineAvailability] Delete Exception Plan',
  DeleteExceptionPlanCompleted = '[LineAvailability] Delete Exception Plan Completed',
  CreatePlan = '[LineAvailability] Create Plan',
  CreatePlanLoaded = '[LineAvailability] Create Plan Loaded',
  CreateExceptionPlan = '[LineAvailability] Create Exception Plan',
  CreateExceptionPlanLoaded = '[LineAvailability] Create Exception Plan Loaded',
  EditPlan = '[LineAvailability] Edit Plan',
  EditPlanLoaded = '[LineAvailability] Edit Plan Loaded',
  EditExceptionPlan = '[LineAvailability] Edit Exception Plan',
  EditExceptionPlanLoaded = '[LineAvailability] Edit Exception Plan Loaded',
  DeletePlan = '[LineAvailability] Delete Plan',
  DeletePlanCompleted = '[LineAvailability] Delete Plan Completed',
  DeletePlans = '[LineAvailability] Delete Plans',
  DeletePlansCompleted = '[LineAvailability] Delete Plans Completed',
  SetSelectedPlan = '[LineAvailability] Set Selected Plan',
  CreateCostFactor = '[LineAvailability] Create Cost Factor',
  CreateCostFactorLoaded = '[LineAvailability] Create Cost Factor Loaded',
  EditCostFactor = '[LineAvailability] Edit Cost Factor',
  EditCostFactorCompleted = '[LineAvailability] Edit Cost Factor Completed',
  DeleteCostFactor = '[LineAvailability] Delete Cost Factor',
  DeleteCostFactorCompleted = '[LineAvailability] Delete Cost Factor Completed',
  DeleteCostFactors = '[LineAvailability] Delete Cost Factors',
  DeleteCostFactorsCompleted = '[LineAvailability] Delete Cost Factors Completed',
  ClearState = '[LineAvailability] Clear State',
  ClearSelectedPlan = '[LineAvailability] Clear Selected Plan',
  CheckExceptionDatesInvalid = '[LineAvailability] Check Exception Dates',
  PlanFetchError = '[LineAvailability] Plan Fetch Error',
  FetchError = '[LineAvailability] Fetch Error',
}

export class LoadLineAvailabilityShiftsData implements Action {
  readonly type = LineAvailabilityActionTypes.LoadLineAvailabilityShiftsData;

  constructor(
    public query: TableQueryParams = {
      page: 1,
      pageSize: 1000,
      query: null,
      search: null,
      searchObject: null,
      join: 'costFactor',
    },
    public isGanttShift: boolean = false,
  ) {}
}

export class LineAvailabilityShiftsDataLoaded implements Action {
  readonly type = LineAvailabilityActionTypes.LineAvailabilityShiftsDataLoaded;

  constructor(
    public payload: LineAvailabilityResponseInterface<LineAvailabilityShiftDataInterface>,
    public isGanttShift: boolean = false,
  ) {}
}

export class CreateShift implements Action {
  readonly type = LineAvailabilityActionTypes.CreateShift;

  constructor(public payload: ShiftInterface) {}
}

export class CreateShiftLoaded implements Action {
  readonly type = LineAvailabilityActionTypes.CreateShiftLoaded;

  constructor(public payload: BaseOneResponseInterface<LineAvailabilityShiftDataInterface>) {}
}

export class EditShift implements Action {
  readonly type = LineAvailabilityActionTypes.EditShift;

  constructor(public payload: EditShiftInterface) {}
}

export class EditShiftCompleted implements Action {
  readonly type = LineAvailabilityActionTypes.EditShiftCompleted;

  constructor(public payload: EditShiftInterface) {}
}

export class DeleteShift implements Action {
  readonly type = LineAvailabilityActionTypes.DeleteShift;

  constructor(public id: number) {}
}

export class DeleteShiftCompleted implements Action {
  readonly type = LineAvailabilityActionTypes.DeleteShiftCompleted;

  constructor(public id: number) {}
}

export class LoadLineAvailabilityPlansData implements Action {
  readonly type = LineAvailabilityActionTypes.LoadLineAvailabilityPlansData;

  constructor(
    public query: TableQueryParams = {
      page: 1,
      pageSize: 1000,
    },
  ) {}
}

export class LineAvailabilityPlansDataLoaded implements Action {
  readonly type = LineAvailabilityActionTypes.LineAvailabilityPlansDataLoaded;

  constructor(public payload: LineAvailabilityResponseInterface<LineAvailabilityPlanDataInterface>) {}
}

export class LoadLineAvailabilityExceptionPlan implements Action {
  readonly type = LineAvailabilityActionTypes.LoadLineAvailabilityExceptionPlan;

  constructor(
    public planId: number,
    public query: TableQueryParams = {
      page: 1,
      pageSize: 10,
    },
  ) {}
}

export class LineAvailabilityExceptionPlanLoaded implements Action {
  readonly type = LineAvailabilityActionTypes.LineAvailabilityExceptionPlanLoaded;

  constructor(public payload: LineAvailabilityResponseInterface<ExceptionPlanDataInterface>) {}
}

export class LoadLineAvailabilityCostFactorsData implements Action {
  readonly type = LineAvailabilityActionTypes.LoadLineAvailabilityCostFactorsData;

  constructor(
    public query: TableQueryParams = {
      page: 1,
      pageSize: 1000,
    },
    public siteIds: number[] = [],
  ) {}
}

export class LineAvailabilityCostFactorsDataLoaded implements Action {
  readonly type = LineAvailabilityActionTypes.LineAvailabilityCostFactorsDataLoaded;

  constructor(public payload: LineAvailabilityPlanCostFactorInterface) {}
}

export class CreatePlan implements Action {
  readonly type = LineAvailabilityActionTypes.CreatePlan;

  constructor(public payload: PlanInterface) {}
}

export class CreatePlanLoaded implements Action {
  readonly type = LineAvailabilityActionTypes.CreatePlanLoaded;

  constructor(public payload: LineAvailabilityPlanDataInterface) {}
}

export class CreateExceptionPlan implements Action {
  readonly type = LineAvailabilityActionTypes.CreateExceptionPlan;

  constructor(public payload: ExceptionPlanDataInterface, public planItems: Task[]) {}
}

export class CreateExceptionPlanLoaded implements Action {
  readonly type = LineAvailabilityActionTypes.CreateExceptionPlanLoaded;

  constructor(public payload: ExceptionPlanDataInterface) {}
}

export class EditPlan implements Action {
  readonly type = LineAvailabilityActionTypes.EditPlan;

  constructor(public id: number, public payload: EditPlanInterface) {}
}

export class EditPlanLoaded implements Action {
  readonly type = LineAvailabilityActionTypes.EditPlanLoaded;

  constructor(public payload: LineAvailabilityPlanDataInterface) {}
}

export class EditExceptionPlan implements Action {
  readonly type = LineAvailabilityActionTypes.EditExceptionPlan;

  constructor(public id: number, public payload: ExceptionPlanDataInterface, public planItems: Task[]) {}
}

export class CheckExceptionDatesInvalid implements Action {
  readonly type = LineAvailabilityActionTypes.CheckExceptionDatesInvalid;

  constructor(public isValid: boolean = false) {}
}

export class EditExceptionPlanLoaded implements Action {
  readonly type = LineAvailabilityActionTypes.EditExceptionPlanLoaded;

  constructor(public id: number, public payload: ExceptionPlanDataInterface) {}
}

export class DeletePlan implements Action {
  readonly type = LineAvailabilityActionTypes.DeletePlan;

  constructor(public id: number) {}
}

export class DeletePlanCompleted implements Action {
  readonly type = LineAvailabilityActionTypes.DeletePlanCompleted;

  constructor(public id: number) {}
}

export class DeletePlans implements Action {
  readonly type = LineAvailabilityActionTypes.DeletePlans;

  constructor(public planIds: number[]) {}
}

export class DeletePlansCompleted implements Action {
  readonly type = LineAvailabilityActionTypes.DeletePlansCompleted;

  constructor(public response: BulkResponseDataInterface) {}
}

export class DeleteExceptionPlan implements Action {
  readonly type = LineAvailabilityActionTypes.DeleteExceptionPlan;

  constructor(public id: number) {}
}

export class DeleteExceptionPlanCompleted implements Action {
  readonly type = LineAvailabilityActionTypes.DeleteExceptionPlanCompleted;

  constructor(public id: number) {}
}

export class SetSelectedPlan implements Action {
  readonly type = LineAvailabilityActionTypes.SetSelectedPlan;

  constructor(public id: number) {}
}

export class CreateCostFactor implements Action {
  readonly type = LineAvailabilityActionTypes.CreateCostFactor;

  constructor(public payload: CreateCostFactorInterface) {}
}

export class CreateCostFactorLoaded implements Action {
  readonly type = LineAvailabilityActionTypes.CreateCostFactorLoaded;

  constructor(public payload: CostFactorResponseInterface) {}
}

export class EditCostFactor implements Action {
  readonly type = LineAvailabilityActionTypes.EditCostFactor;

  constructor(public payload: EditCostFactorInterface) {}
}

export class EditCostFactorCompleted implements Action {
  readonly type = LineAvailabilityActionTypes.EditCostFactorCompleted;

  constructor(public payload: EditCostFactorInterface) {}
}

export class DeleteCostFactor implements Action {
  readonly type = LineAvailabilityActionTypes.DeleteCostFactor;

  constructor(public id: number) {}
}

export class DeleteCostFactorCompleted implements Action {
  readonly type = LineAvailabilityActionTypes.DeleteCostFactorCompleted;

  constructor(public id: number) {}
}

export class DeleteCostFactors implements Action {
  readonly type = LineAvailabilityActionTypes.DeleteCostFactors;

  constructor(public costFactorIds: number[]) {}
}

export class DeleteCostFactorsCompleted implements Action {
  readonly type = LineAvailabilityActionTypes.DeleteCostFactorsCompleted;

  constructor(public response: BulkResponseDataInterface) {}
}

export class DeleteShifts implements Action {
  readonly type = LineAvailabilityActionTypes.DeleteShifts;

  constructor(public shiftIds: number[]) {}
}

export class DeleteShiftsCompleted implements Action {
  readonly type = LineAvailabilityActionTypes.DeleteShiftsCompleted;

  constructor(public response: BulkResponseDataInterface) {}
}

export class LoadLineAvailabilityDefaultPlanItems implements Action {
  readonly type = LineAvailabilityActionTypes.LoadLineAvailabilityDefaultPlanItems;

  constructor(
    public planId: number,
    public query: TableQueryParams = {
      page: 1,
      pageSize: 1000,
    },
  ) {}
}

export class LineAvailabilityDefaultPlanItemsLoaded implements Action {
  readonly type = LineAvailabilityActionTypes.LineAvailabilityDefaultPlanItemsLoaded;

  constructor(public payload: LineAvailabilityResponseInterface<LineAvailabilityPlanItemInterface>) {}
}

export class LoadLineAvailabilityExceptionPlanItems implements Action {
  readonly type = LineAvailabilityActionTypes.LoadLineAvailabilityExceptionPlanItems;

  constructor(
    public exceptionPlanId: number,
    public query: TableQueryParams = {
      page: 1,
      pageSize: 1000,
    },
  ) {}
}

export class LineAvailabilityExceptionPlanItemsLoaded implements Action {
  readonly type = LineAvailabilityActionTypes.LineAvailabilityExceptionPlanItemsLoaded;

  constructor(public payload: LineAvailabilityResponseInterface<LineAvailabilityPlanItemInterface>) {}
}

export class SavePlanItems implements Action {
  readonly type = LineAvailabilityActionTypes.SavePlanItems;

  constructor(
    public payload: Task[],
    public planId: {
      planId: number;
      exceptionPlanId?: number;
    },
    public itemType: ItemTypeEnum,
  ) {}
}

export class SavePlanItemsLoaded implements Action {
  readonly type = LineAvailabilityActionTypes.SavePlanItemsLoaded;

  constructor(public payload: EventModel[], public itemType: ItemTypeEnum, public success: boolean) {}
}

export class DefaultPlanItemsPrepared implements Action {
  readonly type = LineAvailabilityActionTypes.DefaultPlanItemsPrepared;

  constructor(public status: boolean) {}
}

export class ExceptionPlanItemsPrepared implements Action {
  readonly type = LineAvailabilityActionTypes.ExceptionPlanItemsPrepared;

  constructor(public status: boolean) {}
}

export class ClearState implements Action {
  readonly type = LineAvailabilityActionTypes.ClearState;
}

export class ClearSelectedPlan implements Action {
  readonly type = LineAvailabilityActionTypes.ClearSelectedPlan;
}

export class PlanFetchError implements Action {
  readonly type = LineAvailabilityActionTypes.PlanFetchError;

  constructor(public payload: HttpErrorResponse) {}
}

export class FetchError implements Action {
  readonly type = LineAvailabilityActionTypes.FetchError;

  constructor(public payload: any, public actionType?: LineAvailabilityActionTypes) {}
}

export type LineAvailabilityActions =
  | LoadLineAvailabilityShiftsData
  | LineAvailabilityShiftsDataLoaded
  | CreateShift
  | CreateShiftLoaded
  | EditShift
  | EditShiftCompleted
  | DeleteShift
  | DeleteShiftCompleted
  | DeleteShifts
  | DeleteShiftsCompleted
  | LoadLineAvailabilityPlansData
  | LineAvailabilityPlansDataLoaded
  | LoadLineAvailabilityCostFactorsData
  | LineAvailabilityCostFactorsDataLoaded
  | LoadLineAvailabilityExceptionPlan
  | LineAvailabilityExceptionPlanLoaded
  | DeleteExceptionPlan
  | DeleteExceptionPlanCompleted
  | CreateExceptionPlan
  | CreateExceptionPlanLoaded
  | EditExceptionPlan
  | EditExceptionPlanLoaded
  | EditPlan
  | EditPlanLoaded
  | DeletePlan
  | DeletePlanCompleted
  | DeletePlans
  | DeletePlansCompleted
  | CreatePlan
  | CreatePlanLoaded
  | SetSelectedPlan
  | CreateCostFactor
  | CreateCostFactorLoaded
  | EditCostFactor
  | PlanFetchError
  | EditCostFactorCompleted
  | DeleteCostFactor
  | DeleteCostFactorCompleted
  | DeleteCostFactors
  | DeleteCostFactorsCompleted
  | LoadLineAvailabilityDefaultPlanItems
  | LineAvailabilityDefaultPlanItemsLoaded
  | SavePlanItems
  | SavePlanItemsLoaded
  | DefaultPlanItemsPrepared
  | LoadLineAvailabilityExceptionPlanItems
  | LineAvailabilityExceptionPlanItemsLoaded
  | ExceptionPlanItemsPrepared
  | ClearSelectedPlan
  | CheckExceptionDatesInvalid
  | ClearState
  | FetchError;
