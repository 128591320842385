import { Inject, Injectable } from '@angular/core';
import { FilterServiceInterface } from './service.class';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TaskFilterCRUDInterface } from '../../component/filter/filter.class';
import { GetManyResponseInterface } from '../../model/interface/crud-response-interface.model';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../../store/oee.reducer';
import * as _ from 'lodash';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TaskService implements FilterServiceInterface {
  private userLanguage$: string = '';
  private readonly destroySubject: Subject<boolean> = new Subject<boolean>();
  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private store: Store<OeeAppState>,
  ) {}

  private readonly routes = {
    tasks: '/tasks/authorized-tasks',
  };

  public getData(params?: HttpParams): Promise<TaskFilterCRUDInterface[]> {
    this.store
      .select('user')
      .pipe(takeUntil(this.destroySubject))
      .subscribe((state) => {
        if (state.isUserLoaded) {
          this.userLanguage$ = state.language;
        }
        this.destroySubject.next(true);
        this.destroySubject.complete();
      });
    const getOptions = JSON.parse(params.get('s'));
    let addedEntityTranslationOptions: HttpParams = null;
    const isSearchFilter: boolean = !_.isNil(_.get(getOptions, '$and[0].title'));

    if (isSearchFilter) {
      const httpParams: { [key: string]: object[] } = getOptions;
      const searchText: string = httpParams['$and'][0]['title']['$cont'];
      httpParams['$and'][0]['$or'] = [];
      httpParams['$and'][0]['$or'].push(
        {
          title: httpParams['$and'][0]['title'],
        },
        {
          $and: [
            { 'entityTranslations.language': { $eq: this.userLanguage$ } },
            { 'entityTranslations.value': { $cont: searchText } },
          ],
        },
      );
      delete httpParams['$and'][0]['title'];
      addedEntityTranslationOptions = params.set('s', JSON.stringify(httpParams));
      addedEntityTranslationOptions = addedEntityTranslationOptions.append('searchText', searchText);
    }

    return new Promise((resolve, reject) => {
      this.http
        .get(`${this.baseUrl}${this.routes.tasks}`, { params: isSearchFilter ? addedEntityTranslationOptions : params })
        .subscribe((response: GetManyResponseInterface<TaskFilterCRUDInterface>) => {
          if (response.hasOwnProperty('data')) {
            resolve(response.data);
          } else {
            reject();
          }
        });
    });
  }

  public setTaskOriginalTitle(task: TaskFilterCRUDInterface) {
    task.originalTitle = task.title;
  }
}
