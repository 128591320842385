import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import sweetalert2 from 'sweetalert2';

// eslint-disable-next-line
export function OnDestroyDecorator<T extends { new (...args: any[]): {} }>(constructor: T): void {
  const onDestroy: any = constructor.prototype?.ngOnDestroy;

  if (typeof onDestroy === 'function') {
    constructor.prototype.ngOnDestroy = function () {
      onDestroy.apply(this, arguments);

      const propertyNames: string[] = Object.getOwnPropertyNames(this);

      for (const property of propertyNames) {
        if (!this[property]) {
          continue;
        }

        if (this[property] instanceof NgbModalRef) {
          this[property]?.close();
        }
      }

      sweetalert2?.close();
    };
  }
}
