import * as Actions from './activity-review.actions';
import { ActivityReviewInterface } from './activity-review.model';
import { defaultInitialState } from '../../shared/model/enum/generic-effect-constants';
import * as _ from 'lodash';

export const activityReviewInitialState: ActivityReviewInterface = {
  activityHistoryData: defaultInitialState,
  activityHistoryDataLoading: false,
  activityHistoryDataLoaded: false,
  currentLineData: null,
  currentLineDataLoading: false,
  currentLineDataLoaded: false,
  isLoaded: false,
  isLoading: false,
  updateData: null,
  dateUpdateResponse: null,
};

export function activityReviewReducer(
  state: ActivityReviewInterface = activityReviewInitialState,
  action: Actions.ActivityReviewActions,
) {
  switch (action.type) {
    case Actions.START_ACTIVITY_HISTORY_DATA_LOADING:
      return {
        ...state,
        activityHistoryDataLoading: true,
        activityHistoryDataLoaded: false,
      };
    case Actions.ACTIVITY_HISTORY_DATA_LOADED:
      return {
        ...state,
        activityHistoryDataLoaded: true,
        activityHistoryDataLoading: false,
        activityHistoryData: { ...action.payload },
      };
    case Actions.START_CURRENT_LINE_DATA_LOADING:
      return {
        ...state,
        currentLineDataLoading: true,
        currentLineDataLoaded: false,
      };
    case Actions.CURRENT_LINE_DATA_LOADED:
      return {
        ...state,
        currentLineDataLoaded: true,
        currentLineDataLoading: false,
        currentLineData: { ...action.payload },
      };
    case Actions.UPDATE_ACTIVITY_DATES:
      return {
        ...state,
        isLoaded: false,
        isLoading: true,
        updateData: { ...action.updateData },
      };
    case Actions.DATES_UPDATED:
      return {
        ...state,
        updateData: null,
        isLoaded: true,
        isLoading: false,
        dateUpdateResponse: { ...action.payload },
      };
    case Actions.UPDATE_RESULT_SHOWN:
      return {
        ...state,
        dateUpdateResponse: null,
      };
    case Actions.FETCH_ERROR:
      const loadedActions = {
        [Actions.DATES_UPDATED]: { isLoaded: true, isLoading: false },
      };

      const actionType = action.actionType || 'noAction';

      return _.assign(_.cloneDeep(state), loadedActions[actionType] || {});
    default:
      return state;
  }
}
