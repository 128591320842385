import { Action } from '@ngrx/store';
import { SensorListInterface, SensorReportsDataInterface, TableQueryInterface } from './sensor-reports.model';
import { LineCRUDInterface } from '../../../shared/component/filter/filter.class';

export const START_SENSOR_LIST_LOADING = '[SENSOR REPORTS] START SENSOR LIST LOADING';
export const START_SENSOR_LIST_LOADED = '[SENSOR REPORTS] START SENSOR LIST LOADED';
export const GET_LINES = '[SENSOR REPORTS] LINES DATA LOADING';
export const GET_LINES_COMPLETED = '[SENSOR REPORTS] LINES DATA COMPLETED';
export const START_SENSOR_REPORTS_DATA_LOADING = '[SENSOR REPORTS] START SENSOR REPORTS DATA LOADING';
export const START_SENSOR_REPORTS_DATA_LOADED = '[SENSOR REPORTS] START SENSOR REPORTS DATA LOADED';
export const CLEAR_STATE = '[SENSOR REPORTS] CLEAR STATE';
export const FETCH_ERROR = '[SENSOR REPORTS] FETCH ERROR';

export class StartSensorListLoading {
  readonly type = START_SENSOR_LIST_LOADING;

  constructor(public payload: TableQueryInterface) {}
}

export class StartSensorListLoaded {
  readonly type = START_SENSOR_LIST_LOADED;

  constructor(public payload: SensorListInterface[]) {}
}

export class GetLines implements Action {
  readonly type = GET_LINES;
}

export class GetLinesCompleted implements Action {
  readonly type = GET_LINES_COMPLETED;

  constructor(public payload: LineCRUDInterface[]) {}
}

export class StartSensorReportsDataLoading {
  readonly type = START_SENSOR_REPORTS_DATA_LOADING;

  constructor(public payload: TableQueryInterface) {}
}

export class StartSensorReportsDataLoaded {
  readonly type = START_SENSOR_REPORTS_DATA_LOADED;

  constructor(public payload: SensorReportsDataInterface[]) {}
}

export class ClearState implements Action {
  readonly type = CLEAR_STATE;
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;

  constructor(public payload: object) {}
}

export type SensorReportsActions =
  | StartSensorListLoading
  | StartSensorListLoaded
  | GetLines
  | GetLinesCompleted
  | StartSensorReportsDataLoading
  | StartSensorReportsDataLoaded
  | ClearState
  | FetchError;
