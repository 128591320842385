import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FilterServiceInterface , ShiftCRUDResponseInterface }  from '../service.class';
import { ShiftCRUDInterface } from '../../../component/filter/filter.class';

@Injectable({
  providedIn: 'root',
})
export class ShiftService implements FilterServiceInterface {
  constructor(public http: HttpClient, @Inject('API_BASE_URL') private baseUrl: string) {}

  getData(): Promise<ShiftCRUDInterface[]> {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.baseUrl}/scheduler/shifts`).subscribe((response: ShiftCRUDResponseInterface) => {
        if (response.hasOwnProperty('data')) {
          resolve(response.data);
          return response.data;
        }
        reject();
        return null;
      });
    });
  }
}
