import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import {
  FilterProductFamilyActionTypes,
  FilterProductFamilyActions,
  FilterProductFamilyLoaded,
  StartLoadFilterProductFamily,
} from './product-family.actions';
import { ProductService } from '../../../shared/service/filter/product.service';
import { generateHttpOption } from '../../../shared/helper/app-helper';
import { HelperService } from '../../../shared/service/helper.service';

@Injectable()
export class FilterProductFamilyEffects {
  constructor(private actions$: Actions<FilterProductFamilyActions>, public service: ProductService) {}

  loadFilterProductFamily$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilterProductFamilyActionTypes.StartLoadFilterProductFamily),
      switchMap((objectDataReadOnly: StartLoadFilterProductFamily) => {
        const objectData = HelperService.cloneDeep(objectDataReadOnly);
        const searchObject: object[] = JSON.parse(objectData.options.s).$and;
        searchObject.push({ productFamily: { $notnull: true } });

        objectData.options.s = JSON.stringify({ $and: searchObject });
        const params = generateHttpOption(objectData.options);

        return from(this.service.getData(params)).pipe(
          switchMap((response) => {
            return of(new FilterProductFamilyLoaded(response));
          }),
          catchError(() => {
            return of(new FilterProductFamilyLoaded([]));
          }),
        );
      }),
    ),
  );
}
