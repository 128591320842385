import { Action } from '@ngrx/store';
import { SkillMatrixFilterInterface, SkillMatrixInterface } from './skill-matrix.model';

export const SKILL_MATRIX_DATA_LOADING = '[SKILL MATRIX] SKILL MATRIX DATA LOADING';
export const SKILL_MATRIX_DATA_LOADED = '[SKILL MATRIX] SKILL MATRIX DATA LOADED';
export const RESET_SKILL_MATRIX_DATA = '[SKILL MATRIX] RESET SKILL MATRIX DATA';
export const FETCH_DATA_ERROR = '[SKILL MATRIX] FETCH DATA ERROR';

export class SkillMatrixDataLoading implements Action {
  readonly type = SKILL_MATRIX_DATA_LOADING;

  constructor(public params: SkillMatrixFilterInterface) {}
}

export class SkillMatrixDataLoaded implements Action {
  readonly type = SKILL_MATRIX_DATA_LOADED;

  constructor(public payload: SkillMatrixInterface) {}
}

export class ResetSkillMatrixData implements Action {
  readonly type = RESET_SKILL_MATRIX_DATA;
}

export class FetchDataError implements Action {
  readonly type = FETCH_DATA_ERROR;

  constructor(public payload: object) {}
}

export type SkillMatrixActions = SkillMatrixDataLoading | SkillMatrixDataLoaded | ResetSkillMatrixData | FetchDataError;
