import * as Actions from './activity-logs-table.actions';
import { IActivityLogsTableState } from './activity-logs-table.model';

export const activityLogsTableInitialState: IActivityLogsTableState = {
  fileDownloadStatus: false,
};

export function activityLogsTableReducer(
  state: IActivityLogsTableState = activityLogsTableInitialState,
  action: Actions.ActivityLogsTableActions,
): IActivityLogsTableState {
  switch (action.type) {
    case Actions.FETCH_DATA_ERROR:
      return {
        ...state,
        fileDownloadStatus: false,
      };

    case Actions.ACTIVITY_LOGS_TABLE_DOWNLOAD_EXCEL:
      return {
        ...state,
        fileDownloadStatus: true,
      };

    case Actions.ACTIVITY_LOGS_TABLE_DOWNLOAD_EXCEL_COMPLETED:
      return {
        ...state,
        fileDownloadStatus: false,
      };

    default:
      return state;
  }
}
