import { NotificationScopeStateInterface } from './notification-scope.model';
import { NotificationScopeActions, NotificationScopeActionTypes } from './notification-scope.actions';

export const initialState: NotificationScopeStateInterface = {
  notificationScopesLoading: false,
  notificationScopesLoaded: false,
};

export function notificationScopeReducer(
  state: NotificationScopeStateInterface = initialState,
  action: NotificationScopeActions,
): NotificationScopeStateInterface {
  switch (action.type) {
    case NotificationScopeActionTypes.LoadNotificationScopes:
      return {
        ...state,
        notificationScopesLoading: true,
        notificationScopesLoaded: false,
      };

    case NotificationScopeActionTypes.NotificationScopesLoaded:
      return {
        ...state,
        notificationScopesLoading: false,
        notificationScopesLoaded: true,
      };

    default:
      return state;
  }
}
