import { Inject, Injectable } from '@angular/core';
import { forkJoin, Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../../store/oee.reducer';
import { map, take, takeUntil } from 'rxjs/operators';
import { User } from '../../../store/user/model';
import {
  SiteLineFilterDataInterface,
  SiteLineFilterDataForRequestsInterface,
} from '../../../store/global-view/global-view.model';
import { GetManyResponseInterface } from '../../model/interface/crud-response-interface.model';
import {
  LineInterface,
  SiteInterface,
} from '../../../store/line-view-work-order-summary/line-view-work-order-summary.model';
import {
  IComponentConfiguration,
  UserConfigurationStateInterface,
} from '../../../store/user-configuration/user-configuration.model';

@Injectable({
  providedIn: 'root',
})
export class GlobalViewService {
  private readonly GLOBAL_VIEW = {
    GET: {
      SITE_URL: `${this.baseUrl}/sites`,
      LINE_URL: `${this.baseUrl}/lines`,
      GLOBAL_VIEW_DATA_URL: `${this.baseUrl}/lines/global-view-data`,
    },
  };

  private timezone: string = 'utc';
  private dateFormat: string;
  private timeFormat: string;
  private readonly destroySubject: Subject<boolean> = new Subject<boolean>();
  private readonly statusActive: string = JSON.stringify({ status: 1 });

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
  ) {
    this.store
      .select('user')
      .pipe(takeUntil(this.destroySubject))
      .subscribe((userState: User) => {
        if (userState.isUserLoaded) {
          this.timezone = userState.timezone;
          this.dateFormat = userState.dateFormat;
          this.timeFormat = userState.timeFormat;
          this.destroySubject.next(true);
          this.destroySubject.complete();
        }
      });
  }

  public getFiltersAndSettings(): Observable<SiteLineFilterDataInterface> {
    const baseHttpParams: HttpParams = new HttpParams().append('limit', '1000');
    const observables: Observable<
      GetManyResponseInterface<SiteInterface> | GetManyResponseInterface<LineInterface>
    >[] = [
      this.http.get<GetManyResponseInterface<SiteInterface>>(this.GLOBAL_VIEW.GET.SITE_URL, {
        params: baseHttpParams.append('s', this.statusActive),
      }),
      this.http.get<GetManyResponseInterface<LineInterface>>(this.GLOBAL_VIEW.GET.LINE_URL, {
        params: baseHttpParams.append('join', 'lineTypeName').append('s', this.statusActive),
      }),
    ];

    return forkJoin(observables).pipe(
      map(
        (array): SiteLineFilterDataInterface => {
          let userConfiguration: IComponentConfiguration[] | undefined = [];

          this.store
            .select('userConfigurationStore')
            .pipe(take(1))
            .subscribe((userConfigurationState: UserConfigurationStateInterface): void => {
              userConfiguration = userConfigurationState.userConfigurationData.GlobalViewComponent;
            });

          const [siteResponse, linesResponse] = array;
          let filterObject: SiteLineFilterDataInterface = {
            siteIds: [],
            lineIds: [],
          };

          if (userConfiguration !== undefined) {
            const filters: IComponentConfiguration = userConfiguration.find(
              (configuration: IComponentConfiguration): boolean => configuration.name === 'filters',
            );
            const sitesConfiguration: IComponentConfiguration =
              filters === undefined
                ? undefined
                : filters.children.find(
                    (configuration: IComponentConfiguration): boolean => configuration.name === 'siteIds',
                  ) || null;
            const linesConfiguration: IComponentConfiguration =
              filters === undefined
                ? undefined
                : filters.children.find(
                    (configuration: IComponentConfiguration): boolean => configuration.name === 'lineIds',
                  ) || null;

            filterObject = Object.assign(filterObject, {
              siteIds: sitesConfiguration === undefined ? null : sitesConfiguration.value,
              lineIds: linesConfiguration === undefined ? null : linesConfiguration.value,
            });
          } else {
            filterObject = Object.assign(filterObject, {
              siteIds: siteResponse.data.map((site: SiteInterface | LineInterface) => site.id),
              lineIds: linesResponse.data.map((line: SiteInterface | LineInterface) => line.id),
            });
          }

          return filterObject;
        },
      ),
    );
  }

  public getGlobalViewSiteData(body: SiteLineFilterDataForRequestsInterface) {
    return this.http.post(this.GLOBAL_VIEW.GET.GLOBAL_VIEW_DATA_URL, body, {
      headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
    });
  }
}
