import packageJson from '../../package.json';

export const defaultEnvironmentConfigs = {
  version: packageJson.version,
  production: true,
  apiUrl: '/',
  ssoUrl: 'https://df-sso.scm3d.com',
  ssoAuthUrl: '/selfclient/auth/',
  ssoSilentAuthUrl: '/selfclient/extend/',
  ssoLogoutUrl: '/selfclient/logout',
  ssoSilentAuthReturnMessage: 'Successfully Authenticated',
  sensorStatusUrl: 'https://autopilot.scm3d.com/api/lines/{{lineId}}/sensor-statuses',
  notificationFeedTokenUrl: 'https://customreports.scm3d.com/api/getFeedToken',
  notificationListUrl: 'https://customreports.scm3d.com/api/getFeedData',
  signalRHubUrl: 'https://autopilot.scm3d.com/signalr',
  bookDemoUrl: 'https://scw.ai/book-demo/',
  requestInfoUrl: 'https://scw.ai/digital-factory/#DF-requestinfo',
  contactSupportUrl: 'https://autopilot.scm3d.com/contact-support',
  customReportsUrl: 'https://customreports.scm3d.com',
  digitalFormsUrl: 'https://forms.scm3d.com/embed/landingpage/{{orgUnitType}}/{{orgUnitId}}/{{token}}',
  checkPaymentStatusUrl: 'https://toolbox.scm3d.com/check-payment-status',
  weavyUserAuthUrl: 'https://toolbox.scm3d.com/weavy-user-auth',
  knowledgeBaseUrl: 'https://toolbox.scm3d.com/knowledge-base',
  weavyUnreadMessageUrl: 'https://chat.scm3d.com/api/conversations/unread',
};
