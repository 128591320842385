import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ObjectActions from './tasks.actions';
import { catchError, switchMap } from 'rxjs/operators';
import { SiteService } from '../../../shared/service/filter/site.service';
import { of, from } from 'rxjs';
import * as AppActions from '../../app/actions';
import { HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import { ErrorMessageService } from '../../../shared/service/error-message.service';
import {
  BaseCrudResponse,
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { ExcelHelperService } from '../../../shared/service/excel/excel-helper.service';
import { TasksService } from './tasks.service';
import { AddTaskInterface, EditTaskInterface, TaskCRUDInterface } from './tasks.model';
import { LineCRUDInterface } from '../../../shared/component/filter/filter.class';
import { ActivityTypes } from '../../../shared/model/enum/activity-types';
import { ActivitiesInterface } from '../../../shared/model/interface/activities.model';
import { TaskBulkOperationResponseInterface } from '../../../view/settings/tasks/tasks.model';
import { MergedArrayDataInterface } from '../../../../constants.model';
import { LineService } from '../../../shared/service/line/line.service';
import { ActivitiesService } from '../../../shared/service/activities/activities.service';
import { EquipmentListService } from '../../../shared/service/equipment-list/equipment-list.service';
import { RootCauseGroupService } from '../../../shared/service/settings/root-cause-group/root-cause-group.service';
import { RootCauseGroupGetOneInterface } from '../root-cause-group/root-cause-group.model';
import { EquipmentListInterface } from '../equipment-lists/equipment-lists.model';
import { EntityTranslatorService } from 'src/app/shared/service/entity-translator/entity-translator.service';
import { emptyAction } from '../../../../constants';

@Injectable()
export class TasksEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: TasksService,
    private readonly siteService: SiteService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly errorMessageService: ErrorMessageService,
    private readonly excelHelperService: ExcelHelperService,
    private readonly equipmentService: EquipmentListService,
    private readonly activitiesService: ActivitiesService,
    private readonly lineService: LineService,
    private readonly rootCauseGroupService: RootCauseGroupService,
    private translatorService: EntityTranslatorService,
  ) {}

  getSiteData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SITES_DATA_LOADING),
      switchMap(() => {
        const httpParams: HttpParams = new HttpParams().set('limit', 1000);

        return from(this.siteService.getData(httpParams)).pipe(
          switchMap((response) => {
            return of(new ObjectActions.SiteDataLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getTaskDataForDatatable = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.TASK_DATA_FOR_DATATABLE_LOADING),
      switchMap((payload: ObjectActions.TaskDataForDatatableLoading) => {
        return this.service.getTasksForDataTable(this.service.prepareTasksQuery(payload.params)).pipe(
          switchMap((response) => {
            response.data.forEach((task: TaskCRUDInterface) => this.translatorService.translate(task));

            return of(new ObjectActions.TaskDataForDatatableLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes));
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes));
      }),
    ),
  );

  getTaskRangeData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.TASK_RANGE_DATA_LOADING),
      switchMap((payload: ObjectActions.TaskRangeDataLoading) => {
        const tasksHttpParams: HttpParams = new HttpParams().set('matchingLineAndActivityId', true);

        return this.service.getTasksForDataTable(this.service.prepareTasksQuery(payload.params), tasksHttpParams).pipe(
          switchMap((response: GetManyResponseInterface<TaskCRUDInterface>) => {
            return of(new ObjectActions.TaskRangeDataLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes));
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes));
      }),
    ),
  );

  downloadTaskExcel = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DOWNLOAD_EXCEL),
      switchMap((objectData: ObjectActions.DownloadExcel) => {
        this.store.dispatch(new AppActions.ShowLoader());
        this.service.downloadExcel(
          objectData.withData,
          objectData.filters,
          this.service.prepareTasksQuery(objectData.tableQuery),
        );
        return emptyAction;
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  uploadExcel = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.UPLOAD_EXCEL),
      switchMap((objectData: ObjectActions.UploadExcel) => {
        this.store.dispatch(new AppActions.ShowLoader());

        objectData.tasks.forEach((item: TaskCRUDInterface) => delete item.withoutWorkOrder);

        return this.service.uploadExcel(objectData.tasks).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            this.errorMessageService.getTranslatedErrorMessage(response.data);
            const mergedArray = this.excelHelperService.mergeBulkResponseWithRequestData<TaskCRUDInterface>(
              response,
              objectData.tasks,
            );
            return of(new ObjectActions.UploadExcelCompleted(mergedArray), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getLines = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_LINES),
      switchMap((objectData: ObjectActions.GetLines) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const search = {
          statusId: 1,
          siteId: objectData.siteId,
        };
        const httpParams = new HttpParams()
          .set('s', JSON.stringify(search))
          .set('limit', '1000')
          .set('join', 'lineTypeName');

        return this.lineService.getLines(httpParams).pipe(
          switchMap((response: GetManyResponseInterface<Partial<LineCRUDInterface>>) => {
            return of(new ObjectActions.GetLinesCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getActivities = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_ACTIVITIES),
      switchMap(() => {
        this.store.dispatch(new AppActions.ShowLoader());
        const httpParams: HttpParams = new HttpParams()
          .set('s', JSON.stringify({ activityType: { $ne: ActivityTypes.RUN_TIME } }))
          .set('limit', '1000');

        return this.activitiesService.getActivities(httpParams).pipe(
          switchMap((response: GetManyResponseInterface<ActivitiesInterface>) => {
            response.data.forEach((activity: ActivitiesInterface) => this.translatorService.translate(activity));

            return of(new ObjectActions.GetActivitiesCompleted(response));
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getEquipments = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_EQUIPMENTS),
      switchMap((objectData: ObjectActions.GetEquipments) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const httpParams: HttpParams = new HttpParams()
          .set('join', 'equipmentAssignment')
          .append(
            's',
            JSON.stringify({
              siteId: { $eq: String(objectData.siteId) },
              'equipmentAssignment.lineId': { $eq: String(objectData.lineId) },
            }),
          )
          .set('limit', '1000');

        return this.equipmentService.getEquipmentLists(httpParams).pipe(
          switchMap((response: GetManyResponseInterface<EquipmentListInterface>) => {
            response.data.forEach((equipment: EquipmentListInterface) => this.translatorService.translate(equipment));

            return of(new ObjectActions.GetEquipmentsCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getRootCauseGroups = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_ROOT_CAUSE_GROUPS),
      switchMap((objectData: ObjectActions.GetRootCauseGroups) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const httpParams: HttpParams = new HttpParams()
          .set('s', JSON.stringify({ siteId: { $eq: objectData.siteId } }))
          .set('limit', '1000');

        return this.rootCauseGroupService.getRootCauseGroups(httpParams).pipe(
          switchMap((response: GetManyResponseInterface<RootCauseGroupGetOneInterface>) => {
            return of(new ObjectActions.GetRootCauseGroupsCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  addTask = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ADD_TASK),
      switchMap((objectData: ObjectActions.AddTask) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.addTask(objectData.task).pipe(
          switchMap((response: BaseOneResponseInterface<AddTaskInterface>) => {
            return of(new ObjectActions.AddTaskCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  deleteTasks = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DELETE_TASKS),
      switchMap((objectData: ObjectActions.DeleteTasks) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.deleteTasks(objectData.payload).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            const responseMessages: BaseCrudResponse[] =
              'data' in response && Array.isArray(response.data)
                ? response.data
                : [response as BulkResponseDataInterface];
            this.errorMessageService.getTranslatedErrorMessage(responseMessages as BulkResponseDataInterface[]);
            const mergedArray: MergedArrayDataInterface[] = this.excelHelperService.mergeBulkResponseWithRequestData(
              { ...response, data: responseMessages },
              objectData.payload.map((item) => {
                return { id: item };
              }),
              true,
            );
            return of(
              new ObjectActions.DeleteTasksCompleted(objectData.payload.length > 1, mergedArray),
              new AppActions.HideLoader(),
            );
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  editTask = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.EDIT_TASK),
      switchMap((objectData: ObjectActions.EditTask) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.editTask(objectData.task, objectData.taskId).pipe(
          switchMap((response: BaseOneResponseInterface<EditTaskInterface>) => {
            return of(new ObjectActions.EditTaskCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  bulkEditTask = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.BULK_EDIT_TASK),
      switchMap((objectData: ObjectActions.BulkEditTask) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.bulkEditTask(objectData.tasks).pipe(
          switchMap((response: GetManyResponseInterface<TaskBulkOperationResponseInterface>) => {
            const responseMessages = 'data' in response && Array.isArray(response.data) ? response.data : [response];
            this.errorMessageService.getTranslatedErrorMessage(responseMessages);
            const mergedArray: MergedArrayDataInterface[] = this.excelHelperService.mergeBulkResponseWithRequestData(
              { ...response, data: responseMessages },
              objectData.tasks.map((item) => {
                return { id: item.id };
              }),
              true,
            );
            return of(new ObjectActions.BulkEditTaskCompleted(mergedArray), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );
}
