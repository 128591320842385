import { Action } from '@ngrx/store';
import {
  ITaskPerformanceDataAndCurrentShifts,
  ITaskPerformanceFilter,
  ITaskPerformanceRequestParams,
} from './task-performance.model';
import { ExcelColumnDefinitionInterface } from '../../../shared/service/excel/excel-helper.service';
import { ITaskPerformanceTableData } from '../../../view/reports/task-performance/task-performance.model';

export const TASK_PERFORMANCE_DATA_LOADING = '[TASK PERFORMANCE] TASK PERFORMANCE DATA LOADING';
export const TASK_PERFORMANCE_DATA_LOADED = '[TASK PERFORMANCE] TASK PERFORMANCE DATA LOADED';
export const TASK_PERFORMANCE_FETCH_DATA = '[TASK PERFORMANCE] TASK PERFORMANCE FETCH DATA';
export const TASK_PERFORMANCE_DOWNLOAD_EXCEL = '[TASK PERFORMANCE] TASK PERFORMANCE DOWNLOAD EXCEL';
export const TASK_PERFORMANCE_DOWNLOAD_EXCEL_COMPLETED = '[TASK PERFORMANCE] TASK PERFORMANCE DOWNLOAD EXCEL COMPLETED';
export const FETCH_DATA_ERROR = '[TASK PERFORMANCE] FETCH DATA ERROR';

export class TaskPerformanceDataLoading implements Action {
  readonly type = TASK_PERFORMANCE_DATA_LOADING;

  constructor(public params: ITaskPerformanceFilter) {}
}

export class TaskPerformanceDataLoaded implements Action {
  readonly type = TASK_PERFORMANCE_DATA_LOADED;

  constructor(public payload: ITaskPerformanceDataAndCurrentShifts) {}
}

export class TaskPerformanceFetchData implements Action {
  readonly type = TASK_PERFORMANCE_FETCH_DATA;

  constructor(public params: ITaskPerformanceRequestParams, public activityHistoryTotalCount: number) {}
}

export class TaskPerformanceDownloadExcel implements Action {
  readonly type = TASK_PERFORMANCE_DOWNLOAD_EXCEL;

  constructor(
    public worksheetsColumnDefinitions: ExcelColumnDefinitionInterface[][],
    public excelData: ITaskPerformanceTableData[],
  ) {}
}

export class TaskPerformanceDownloadExcelCompleted implements Action {
  readonly type = TASK_PERFORMANCE_DOWNLOAD_EXCEL_COMPLETED;
}

export class FetchDataError implements Action {
  readonly type = FETCH_DATA_ERROR;

  constructor(public payload: object) {}
}

export type TaskPerformanceActions =
  | TaskPerformanceDataLoading
  | TaskPerformanceDataLoaded
  | TaskPerformanceFetchData
  | TaskPerformanceDownloadExcel
  | TaskPerformanceDownloadExcelCompleted
  | FetchDataError;
