import { GetFeedTokenInterface, NotificationList } from '../../shared/service/notification/notification.service';
import { OeeEnum } from '../../../constants';
import { GetCurrentUserResponseClientInterface, GetCurrentUserResponseDefaultSiteInterface } from '../app/model';

export interface IUserMenuPermissions {
  activeMenuItemIds: number[];
  landingMenuItemId?: number;
}

// TODO client's number code is added to the store temporarily
export interface User {
  username?: string;
  userId?: string;
  name?: string;
  surname?: string;
  userLevelId?: string;
  age?: number;
  siteId?: number;
  defaultSite?: string;
  siteName?: string;
  lineId?: number;
  lineName?: string;
  language?: string;
  timezone?: string;
  email?: string;
  isVerifiedByAdmin?: boolean;
  dateFormat?: string;
  dateTimeFormat?: string;
  timeFormat?: string;
  timeFormatHourAndMinute?: string;
  locale?: string;
  decimalSeparator?: '.' | ',';
  thousandSeparator?: '.' | ',';
  clientCode?: string;
  clientNumber?: string;
  dataInterval?: number;
  clientOeeFormula?: string;
  isDigitalFormsActive?: boolean;
  isLaborTrackerActive?: boolean;
  isAssetTrackerActive?: boolean;
  isStationTrackerActive?: boolean;
  updateCurrentUserLoading?: boolean;
  updateCurrentUserLoaded?: boolean;
  isUserLoaded?: boolean;
  isUserLoading?: boolean;
  errors?: any[];
  isTokenRefreshed?: boolean;
  isFeedTokenLoading?: boolean;
  isFeedTokenLoaded?: boolean;
  notificationToken?: GetFeedTokenInterface;
  notificationsLoading?: boolean;
  notificationsLoaded?: boolean;
  notifications?: NotificationList;
  authorizedSites?: string;
  authorizedLines?: string;
  fullName?: string;
  expectedSpeed?: number;
  unitId?: number;
  decimalScaleLimit: number;
  siteDecimalScaleLimit: number;
  isNonVerifiedUserPassActive?: boolean;
  defaultSiteInfo?: GetCurrentUserResponseDefaultSiteInterface;
  menuPermissions?: IUserMenuPermissions;
  clientInfo?: GetCurrentUserResponseClientInterface;
  logbookAccessUrl: string;
  logbookAccess: number;
}

export enum UserLevels {
  ADMIN = 1,
  EXECUTIVE = 2,
  SUPERVISOR = 4,
  LINE_LEADER = 16,
  OPERATOR = 32,
  PLANNER = 64,
}

export interface UserLevelInterface {
  id: UserLevels;
  name: string;
}
export type UserLevelKeyType = 'admin' | 'executive' | 'supervisor' | 'lineLeader' | 'operator' | 'planner';
export type UserLevelType = { [key in UserLevelKeyType]: UserLevelInterface };

export const userLevelObject: UserLevelType = {
  admin: { id: UserLevels.ADMIN, name: 'admin' },
  executive: { id: UserLevels.EXECUTIVE, name: 'executive' },
  supervisor: { id: UserLevels.SUPERVISOR, name: 'supervisor' },
  lineLeader: { id: UserLevels.LINE_LEADER, name: 'line leader' },
  operator: { id: UserLevels.OPERATOR, name: 'operator' },
  planner: { id: UserLevels.PLANNER, name: 'planner' },
};

export interface CustomDateFormat {
  parseInput: string;
  fullPickerInput: string;
  datePickerInput: string;
  timePickerInput: string;
  monthYearLabel: string;
  dateA11yLabel: string;
  monthYearA11yLabel: string;
}

export enum AdminLevels {
  GLOBAL_ADMIN = 'globalAdmin',
  SITE_ADMIN = 'siteAdmin',
}
