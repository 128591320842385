import { WorkOrdersSummaryDataInterface, WorkOrdersSummaryStateInterface, TaskGroupDataInterface } from './work-orders-summary.model';
import { WorkOrdersSummaryActions, WorkOrdersSummaryActionTypes } from './work-orders-summary.actions';
import { HelperService } from '../../shared/service/helper.service';

export const workOrdersSummaryInitialState: WorkOrdersSummaryStateInterface = {
  shiftIsLoaded: false,
  shiftIsLoading: false,
  workOrdersSummaryIsLoaded: false,
  workOrdersSummaryIsLoading: false,
  commentsIsLoading: false,
  workOrdersSummaryData: [],
  totalDurations: null,
  taskGroups: [],
  shifts: [],
  isFiltersLoading: false,
  isFiltersLoaded: false,
  isFiltersSaving: false,
  filters: null,
  selectedFilters: null,
  setSelectedFiltersIsLoading: false,
  columnNames: null,
  shiftComments: [],
  errors: [],
};

export function workOrdersSummaryReducer(
  state: WorkOrdersSummaryStateInterface = workOrdersSummaryInitialState,
  action: WorkOrdersSummaryActions,
) {
  switch (action.type) {
    case WorkOrdersSummaryActionTypes.StartWorkOrdersSummaryDataLoading:
      return {
        ...state,
        shiftIsLoaded: false,
        shiftIsLoading: true,
      };

    case WorkOrdersSummaryActionTypes.WorkOrdersSummaryDataLoaded:
      return {
        ...state,
        shiftIsLoaded: true,
        shiftIsLoading: false,
        shifts: action.payload.shifts,
        isShiftButtonDisabled: action.payload.disabled,
      };

    case WorkOrdersSummaryActionTypes.GetShiftDataAndGroups:
      return {
        ...state,
        workOrdersSummaryIsLoading: true,
        workOrdersSummaryIsLoaded: false,
      };

    case WorkOrdersSummaryActionTypes.GetShiftDataAndGroupsLoaded:
      const workOrdersSummaryData = action.payload.data.data;

      return {
        ...state,
        workOrdersSummaryData,
        workOrdersSummaryIsLoading: false,
        workOrdersSummaryIsLoaded: true,
        taskGroups: action.payload.data.taskGroups,
        columnNames: action.payload.data.columnNames,
        shiftComments: action.payload.data.shiftComments,
        totalDurations: action.payload.data.totalDurations,
      };

    case WorkOrdersSummaryActionTypes.StartFiltersSaving:
      return {
        ...state,
        isFiltersSaving: true,
      };

    case WorkOrdersSummaryActionTypes.FiltersSaved:
      return {
        ...state,
        isFiltersSaving: false,
      };

    case WorkOrdersSummaryActionTypes.GetFiltersAndSettings:
      return {
        ...state,
        isFiltersLoading: true,
        isFiltersLoaded: false,
      };

    case WorkOrdersSummaryActionTypes.GetFiltersAndSettingsLoaded:
      return {
        ...state,
        filters: action.payload,
        isFiltersLoading: false,
        isFiltersLoaded: true,
      };

    case WorkOrdersSummaryActionTypes.SetSelectedFilters:
      return {
        ...state,
        setSelectedFiltersIsLoading: true,
        selectedFilters: action.filters,
      };

    case WorkOrdersSummaryActionTypes.SetSelectedFiltersLoaded:
      return {
        ...state,
        setSelectedFiltersIsLoading: false,
      };

    case WorkOrdersSummaryActionTypes.ClearState:
      return { ...workOrdersSummaryInitialState };

    case WorkOrdersSummaryActionTypes.FetchError:
      const errors = [...state.errors, action.payload];
      return {
        ...state,
        errors,
        isLoaded: false,
        isLoading: false,
        workOrdersSummaryIsLoading: false,
        commentsIsLoading: false,
        isFiltersLoading: false,
        isFiltersSaving: false,
      };

    default:
      return state;
  }
}
