import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { OeeCalendarService } from './oee-calendar.service';
import * as ObjectActions from './oee-calendar.actions';
import * as oeeAppReducer from '../../oee.reducer';
import { Store } from '@ngrx/store';
import { catchError, switchMap } from 'rxjs/operators';
import * as AppActions from '../../app/actions';
import { of } from 'rxjs';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { mysqlDateFormat } from '../../../shared/helper/date';
import {
  ESqlMode,
  IOeeCalculationRequest,
  IOeeCalculationResult,
} from '../work-order-performance/work-order-performance.model';
import { OeeService } from '../../../shared/service/oee/oee.service';
import { IOeeCalendarChartColorData, IOeeCalendarFilter } from './oee-calendar.model';
import { HttpParams } from '@angular/common/http';
import * as _ from 'lodash';

@Injectable()
export class OeeCalendarEffects {
  constructor(
    private readonly actions$: Actions,
    public readonly oeeCalendarService: OeeCalendarService,
    public readonly oeeService: OeeService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
  ) {}

  getOeeCalendarData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.OEE_CALENDAR_DATA_LOADING),
      switchMap((objectData: ObjectActions.OeeCalendarDataLoading) => {
        const requestParams: IOeeCalendarFilter = _.cloneDeep(objectData.params);
        const body: IOeeCalculationRequest = {
          ...(Array.isArray(requestParams.sites) ? { sites: requestParams.sites } : {}),
          ...(Array.isArray(requestParams.lines) ? { lines: requestParams.lines } : {}),
          startDate: requestParams.dateRange.startDate?.format(mysqlDateFormat),
          endDate: requestParams.dateRange.endDate?.add(1, 'day').format(mysqlDateFormat),
          sqlMode: ESqlMode.DAY,
          useReplica: requestParams.useReplica,
        };

        this.store.dispatch(new AppActions.ShowLoader());

        return this.oeeCalendarService.getData(body).pipe(
          switchMap((response: GetManyResponseInterface<IOeeCalculationResult>) => {
            return of(new ObjectActions.OeeCalendarDataLoaded(response.data));
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchDataError(err), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((err) => {
        return of(new ObjectActions.FetchDataError(err), new AppActions.HideLoader());
      }),
    ),
  );

  getOeeChartColorData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.OEE_CHART_COLOR_DATA_LOADING),
      switchMap((objectData: ObjectActions.OeeChartColorDataLoading) => {
        let params: HttpParams = new HttpParams()
          .append('join', 'line')
          .append('join', 'line.site')
          .append('page', 1)
          .append('limit', 1000)
          .append('sort', 'id,DESC');

        const filter: object = {
          target_type: 'OEE',
        };

        if (Array.isArray(objectData.params.sites)) {
          filter['line.siteId'] = { $in: objectData.params.sites };
        }

        if (Array.isArray(objectData.params.lines)) {
          filter['line_id'] = { $in: objectData.params.lines };
        }

        if (objectData.params.dateRange.startDate && objectData.params.dateRange.endDate) {
          const startDate: string = objectData.params.dateRange.startDate.format(mysqlDateFormat);
          const endDate: string = objectData.params.dateRange.endDate.format(mysqlDateFormat);
          filter['target_period'] = { $between: [startDate, endDate] };
        }

        params = params.append('s', JSON.stringify(filter));

        this.store.dispatch(new AppActions.ShowLoader());

        return this.oeeService.getOeeCalendarChartColorData(params).pipe(
          switchMap((response: IOeeCalendarChartColorData[]) => {
            return of(new ObjectActions.OeeChartColorDataLoaded(response));
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchDataError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((err) => {
        return of(new ObjectActions.FetchDataError(err), new AppActions.HideLoader());
      }),
    ),
  );
}
