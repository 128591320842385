import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ObjectActions from './my-reports.actions';
import * as AppActions from '../../app/actions';
import { catchError, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import { MyReportsService } from './my-reports.service';
import { of } from 'rxjs';
import { IMyReport } from '../../../view/reports/my-reports/my-reports.model';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { HttpParams } from '@angular/common/http';
import { HelperService } from '../../../shared/service/helper.service';

@Injectable()
export class MyReportsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly service: MyReportsService,
    private readonly helperService: HelperService,
  ) {}

  $fetchMyReports = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.MyReportsActionTypes.FetchMyReportsData),
      switchMap((objectData: ObjectActions.FetchMyReportsData) => {
        this.store.dispatch(new AppActions.ShowLoader());

        const params: HttpParams = this.helperService.insertGenericCrudRequestParameters(objectData.parameters);

        return this.service.getMyReports(params).pipe(
          switchMap((response: GetManyResponseInterface<IMyReport>) => {
            return of(new ObjectActions.FetchMyReportsDataCompleted(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  $updateMyReport = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.MyReportsActionTypes.UpdateMyReport),
      switchMap((objectData: ObjectActions.UpdateMyReport) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.updateMyReport(objectData.id, objectData.requestPayload).pipe(
          switchMap((response: BaseOneResponseInterface<IMyReport>) => {
            return of(new ObjectActions.UpdateMyReportCompleted(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  $createNewMyReport = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.MyReportsActionTypes.CreateNewMyReport),
      switchMap((objectData: ObjectActions.CreateNewMyReport) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.createNewMyReport(objectData.requestPayload).pipe(
          switchMap((response: BaseOneResponseInterface<IMyReport>) => {
            return of(new ObjectActions.CreateNewMyReportCompleted(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  $deleteMyReport = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.MyReportsActionTypes.DeleteMyReport),
      switchMap((objectData: ObjectActions.DeleteMyReport) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.deleteMyReport(objectData.id).pipe(
          switchMap((response: GetManyResponseInterface<IMyReport>) => {
            return of(new ObjectActions.DeleteMyReportCompleted(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  $bulkDeleteMyReports = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.MyReportsActionTypes.BulkDeleteMyReport),
      switchMap((objectData: ObjectActions.BulkDeleteMyReport) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.bulkDeleteMyReports(objectData.ids).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            return of(new ObjectActions.BulkDeleteMyReportCompleted(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  $cloneMyReport = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.MyReportsActionTypes.CloneMyReport),
      switchMap((objectData: ObjectActions.CloneMyReport) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.cloneMyReport(objectData.id, objectData.requestPayload).pipe(
          switchMap((response: BaseOneResponseInterface<IMyReport>) => {
            return of(new ObjectActions.CloneMyReportCompleted(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );
}
