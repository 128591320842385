import { Action } from '@ngrx/store';
import { LaborPerformanceResponseInterface } from './labor-performance.model';
import { HttpErrorResponse } from '@angular/common/http';

export enum LaborPerformanceActionTypes {
  StartLaborPerformanceDataLoading = '[Labor Performance] START LABOR PERFORMANCE DATA LOADING.',
  LaborPerformanceDataLoaded = '[Labor Performance] LABOR PERFORMANCE DATA LOADED.',
  ClearState = '[Labor Performance] CLEAR STATE',
  FetchError = '[Labor Performance] FETCH ERROR',
}

export class StartLaborPerformanceDataLoading implements Action {
  readonly type = LaborPerformanceActionTypes.StartLaborPerformanceDataLoading;

  constructor(
    public payload: {
      startDate: string,
      endDate: string,
      siteIds: string,
      departmentIds: string,
      lineIds: string,
      userIds: string,
    },
  ) {
  }
}

export class LaborPerformanceDataLoaded implements Action {
  readonly type = LaborPerformanceActionTypes.LaborPerformanceDataLoaded;

  constructor(public payload: LaborPerformanceResponseInterface) {
  }
}

export class ClearState implements Action {
  readonly type = LaborPerformanceActionTypes.ClearState;
}

export class FetchError implements Action {
  readonly type = LaborPerformanceActionTypes.FetchError;

  constructor(public payload: HttpErrorResponse) {
  }
}

export type LaborPerformanceActions =
  | StartLaborPerformanceDataLoading
  | LaborPerformanceDataLoaded
  | ClearState
  | FetchError;
