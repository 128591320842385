import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  ActivitiesInterface,
  ActivityInactiveChangeInterface,
  ActivitySubTypeInterface,
  ActivityTypeInterface,
} from '../../model/interface/activities.model';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../model/interface/crud-response-interface.model';
import {
  ActivitiesDownloadExcelFiltersInterface,
  ActivitiesLookupsInterface,
  ActivityBulkSaveManyInterface,
  ActivityInterface,
} from '../../../store/settings/activities/activities.model';
import * as moment from 'moment-timezone';
import {
  CellTypes,
  CreateExcelInterface,
  CreateExcelSheetInterface,
  ExcelColumnWidthEnum,
  ExcelHelperService,
  ExcelSheetTypeEnum,
} from '../excel/excel-helper.service';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../../store/oee.reducer';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { excelDateFormat, excelTimeFormat } from '../../model/enum/excel-date-format';
import { ValueType, Workbook, Worksheet } from 'exceljs';
import * as ObjectActions from '../../../store/settings/activities/activities.actions';
import * as _ from 'lodash';
import { IActivitiesExcelData } from '../../../view/settings/activities/activities.model';

@Injectable({
  providedIn: 'root',
})
export class ActivitiesService {
  private ACTIVITIES_BASE_URL = '/activities/';
  private ACTIVITIES = {
    GET: {
      GET_ALL_ACTIVITIES: `${this.baseUrl}${this.ACTIVITIES_BASE_URL}`,
    },
    DELETE: {
      DELETE_ACTIVITY: `${this.baseUrl}${this.ACTIVITIES_BASE_URL}`,
    },
    POST: {
      CREATE_ACTIVITY: `${this.baseUrl}${this.ACTIVITIES_BASE_URL}`,
    },
    PATCH: {
      EDIT_ACTIVITY: `${this.baseUrl}${this.ACTIVITIES_BASE_URL}`,
      GET_USED_AREAS_OF_INACTIVATED_ACTIVITY: `${this.baseUrl}${this.ACTIVITIES_BASE_URL}get-used-areas-of-inactivated-activity/`,
    },
    BULK: {
      DELETE: `${this.baseUrl}${this.ACTIVITIES_BASE_URL}bulk/delete`,
      SAVE: `${this.baseUrl}${this.ACTIVITIES_BASE_URL}bulk/save`,
      EDIT: `${this.baseUrl}${this.ACTIVITIES_BASE_URL}bulk/edit`,
    },
  };

  private readonly ACTIVITY_TYPES_BASE_URL = 'activity-type';
  private readonly ACTIVITY_TYPES = {
    GET: {
      GET_ALL_ACTIVITY_TYPES: `${this.baseUrl}/${this.ACTIVITY_TYPES_BASE_URL}`,
    },
  };

  private readonly ACTIVITY_SUBTYPES_BASE_URL = 'activity-subtypes';
  private readonly ACTIVITY_SUBTYPES = {
    GET: {
      GET_ALL_ACTIVITY_SUBTYPES: `${this.baseUrl}/${this.ACTIVITY_SUBTYPES_BASE_URL}`,
    },
  };

  private timezone: string = 'utc';
  private dateFormat$: string;
  private timeFormat$: string;
  private readonly destroySubject: Subject<boolean> = new Subject<boolean>();

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly excelHelper: ExcelHelperService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly translate: TranslateService,
  ) {
    this.store
      .select('user')
      .pipe(takeUntil(this.destroySubject))
      .subscribe((state) => {
        if (state.isUserLoaded) {
          this.timezone = state.timezone;
          if (state.locale !== '') {
            this.dateFormat$ = excelDateFormat[state.locale];
            this.timeFormat$ = excelTimeFormat[state.locale];
          }
          this.destroySubject.next(true);
          this.destroySubject.complete();
        }
      });
  }

  public getOneActivity(id: number, options?: HttpParams): Observable<BaseOneResponseInterface<ActivitiesInterface>> {
    return this.http.get<BaseOneResponseInterface<ActivitiesInterface>>(`${this.ACTIVITIES.GET.GET_ALL_ACTIVITIES}${id}`, {
      params: options,
    });
  }

  public getActivities(options?: HttpParams): Observable<GetManyResponseInterface<ActivitiesInterface>> {
    return this.http.get<GetManyResponseInterface<ActivitiesInterface>>(this.ACTIVITIES.GET.GET_ALL_ACTIVITIES, {
      params: options,
    });
  }

  public patchActivityData(
    siteId: number,
    payload: Partial<ActivitiesInterface>,
    params?: HttpParams,
  ): Observable<GetManyResponseInterface<ActivitiesInterface>> {
    return this.http.patch<GetManyResponseInterface<ActivitiesInterface>>(
      `${this.ACTIVITIES.PATCH.EDIT_ACTIVITY}${siteId}`,
      payload,
      { params },
    );
  }

  public useActivityInAlertsAndLines(
    activityId: number,
    params?: HttpParams,
  ): Observable<BaseOneResponseInterface<ActivityInactiveChangeInterface>> {
    return this.http.get<BaseOneResponseInterface<ActivityInactiveChangeInterface>>(
      `${this.ACTIVITIES.PATCH.GET_USED_AREAS_OF_INACTIVATED_ACTIVITY}${activityId}`,
      { params },
    );
  }

  public patchActivitiesData(
    payload: Partial<ActivitiesInterface>[],
    params?: HttpParams,
  ): Observable<BulkResponseDataInterface> {
    return this.http.patch<BulkResponseDataInterface>(this.ACTIVITIES.BULK.EDIT, { activities: payload }, { params });
  }

  public createActivity(
    payload: Partial<ActivitiesInterface>,
  ): Observable<GetManyResponseInterface<ActivitiesInterface>> {
    return this.http.post<GetManyResponseInterface<ActivitiesInterface>>(this.ACTIVITIES.POST.CREATE_ACTIVITY, payload);
  }

  public deleteActivity(activityId: number): Observable<GetManyResponseInterface<ActivitiesInterface>> {
    return this.http.delete<GetManyResponseInterface<ActivitiesInterface>>(
      `${this.ACTIVITIES.DELETE.DELETE_ACTIVITY}${activityId}`,
    );
  }

  public deleteActivities(activityIds: number[]): Observable<BulkResponseDataInterface> {
    return this.http.delete<BulkResponseDataInterface>(this.ACTIVITIES.BULK.DELETE, {
      body: { activities: activityIds },
    });
  }

  public getActivityTypes(options?: HttpParams): Observable<GetManyResponseInterface<ActivityTypeInterface>> {
    return this.http.get<GetManyResponseInterface<ActivityTypeInterface>>(
      this.ACTIVITY_TYPES.GET.GET_ALL_ACTIVITY_TYPES,
      {
        params: options,
      },
    );
  }

  public getActivitySubTypes(options?: HttpParams): Observable<GetManyResponseInterface<ActivitySubTypeInterface>> {
    return this.http.get<GetManyResponseInterface<ActivitySubTypeInterface>>(
      this.ACTIVITY_SUBTYPES.GET.GET_ALL_ACTIVITY_SUBTYPES,
      {
        params: options,
      },
    );
  }

  public uploadExcel(activities: ActivityBulkSaveManyInterface): Observable<BulkResponseDataInterface> {
    return this.http.post<BulkResponseDataInterface>(this.ACTIVITIES.BULK.SAVE, activities);
  }

  public downloadActivityExcel(
    withData: boolean,
    filters: ActivitiesDownloadExcelFiltersInterface,
    withErrorColumn: boolean = false,
    data?: ActivityInterface[],
  ): void {
    const httpParams: HttpParams = new HttpParams().set('limit', String(filters.limit));
    const sourcesObject: { [key: string]: Observable<any> } = {
      activities:
        withData && !data ? this.getActivities(httpParams.set('page', filters.selectedDownloadOffset)) : of(data || []),
    };

    forkJoin(sourcesObject).subscribe((response: any) => {
      const sheetTitle = this.translate.instant('excel.items.activities');
      const excelName = `${sheetTitle} ${moment()
        .tz(this.timezone)
        .format(this.dateFormat$)}`;
      const excelData: ActivityInterface[] = response.activities?.data ?? response.activities;
      const excelOptions: CreateExcelInterface = this.getActivityExcelColumns(filters, withErrorColumn);

      if (withData) {
        excelOptions.data = this.fillActivitiesDropDownData(excelData, filters);
      }

      const worksheets: CreateExcelSheetInterface[] = [
        {
          withData,
          sheetTitle,
          sheetType: ExcelSheetTypeEnum.TABLE,
          params: excelOptions,
          isDisabledColumnsFirstLine: true,
        },
      ];

      this.excelHelper.createExcel(excelName, worksheets, this.timezone, this.dateFormat$, this.timeFormat$).then(
        () => {
          this.store.dispatch(new ObjectActions.DownloadActivityExcelCompleted());
        },
        () => {
          this.store.dispatch(new ObjectActions.FetchError({}));
        },
      );
    });
  }

  private getActivityExcelColumns(lookups: ActivitiesLookupsInterface, withErrorColumn: boolean): CreateExcelInterface {
    const booleanDropdownOptions = this.excelHelper.getExcelBooleanDropdownOptions();
    const excelColumns: CreateExcelInterface = {
      columns: [
        {
          header: 'id',
          key: 'id',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          dataValidation: {
            type: CellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('tasks.listView.headers.activity'),
          key: 'name',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          dataValidation: {
            type: CellTypes.CUSTOM,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('general.excel.column.activityType'),
          key: 'activityType',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: _.get(lookups, 'activityTypes', []),
            prop: 'name',
            dataProperty: 'activityTypeOption.name',
            dataId: 'activityTypeOption.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('general.excel.column.activityDetail'),
          key: 'activitySubtype',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: _.get(lookups, 'activitySubtypes', []),
            prop: 'name',
            dataProperty: 'activitySubtypeOption.name',
            dataId: 'activitySubtypeOption.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
        },
        {
          header: this.translate.instant('general.excel.column.activityPhase'),
          key: 'activityPlannedType',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: _.get(lookups, 'activityPlannedTypes', []),
            prop: 'name',
            dataProperty: 'activityPlannedTypeOption.name',
            dataId: 'activityPlannedTypeOption.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
        },
        {
          header: this.translate.instant('general.excel.column.countdownTimer'),
          key: 'countdownTimer',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.Boolean,
          dropdownOptions: {
            data: booleanDropdownOptions,
            prop: 'name',
            dataProperty: 'countdownTimerOption.name',
            dataId: 'countdownTimer.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('general.excel.column.active'),
          key: 'active',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.Boolean,
          dropdownOptions: {
            data: booleanDropdownOptions,
            prop: 'name',
            dataProperty: 'activeOption.name',
            dataId: 'active.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
      ],
    };

    this.excelHelper.prepareExcelColumns(excelColumns.columns, withErrorColumn);

    return excelColumns;
  }

  async getActivitiesFromExcel(file: File): Promise<null | IActivitiesExcelData> {
    const workbook: Workbook = await this.excelHelper.getExcelWorkBookFromFile(file);
    const activitiesSheet: Worksheet = workbook.getWorksheet(this.translate.instant('excel.items.activities'));

    if (_.isNil(activitiesSheet)) {
      return null;
    }

    const { columns } = this.getActivityExcelColumns(null, false);
    const columnKeys = this.excelHelper.getSheetColumnKeys(columns);

    return {
      activitiesData: {
        activities: this.excelHelper.getExcelRowsFromWorkSheet<ActivityInterface>(activitiesSheet, columnKeys, {
          dateFormat: this.dateFormat$,
          timeFormat: this.timeFormat$,
          timezone: this.timezone,
        }),
      },
    };
  }

  private fillActivitiesDropDownData(excelData: ActivityInterface[], filters: ActivitiesDownloadExcelFiltersInterface) {
    const booleanDropdownOptions = this.excelHelper.getExcelBooleanDropdownOptions();

    return excelData.map((item) => {
      return {
        ...item,
        countdownTimerOption: !_.isNil(item.countdownTimer)
          ? _.find(booleanDropdownOptions, { id: String(item.countdownTimer) })
          : null,
        activeOption: !_.isNil(item.active) ? _.find(booleanDropdownOptions, { id: String(item.active) }) : null,
        activityTypeOption: !_.isNil(item.activityType)
          ? _.find(filters.activityTypes, { id: item.activityType })
          : null,
        activitySubtypeOption: !_.isNil(item.activitySubtype)
          ? _.find(filters.activitySubtypes, { id: item.activitySubtype })
          : null,
        activityPlannedTypeOption: !_.isNil(item.activityPlannedType)
          ? _.find(filters.activityPlannedTypes, { id: item.activityPlannedType })
          : null,
      };
    });
  }
}
