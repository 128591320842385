import { Inject, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {
  ILineInfoThatWorkOrder,
  PhaseCommentCrudUpdateResponseInterface,
  PhaseCommentsResponseInterface,
  TreeChartServiceInterface,
} from './home.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  GetManyHomeMetricSetAssignmentCrudResponse,
  PhaseDurationInterface,
  PhaseDurationMetricInterface,
  PhaseDurationsInterface,
} from '../home-screen-metric-settings/home-screen-metric-settings.model';
import { TranslateService } from '@ngx-translate/core';
import { activityStyles } from '../../shared/helper/home-helper';
import { ShiftSummaryCommentObjectPropertyTypes } from '../../shared/model/enum/shift-summary-comment-object-property-types';
import { BaseCrudResponse, BaseOneResponseInterface } from '../../shared/model/interface/crud-response-interface.model';
import { DatatableHeaderInterface } from '../../shared/component/datatable/datatable.model';
import { EColumnWidth } from '../../shared/service/datatable/datatable.model';
import { HelperService } from '../../shared/service/helper.service';
import { KPI_METRICS_DECIMAL_COUNT } from '../../../constants';
import { DecimalHelper } from '../../shared/helper/decimal/decimal-helper';

@Injectable()
export class HomeActivityTreeChartService {
  notify: Subject<TreeChartServiceInterface> = new Subject<TreeChartServiceInterface>();
  private HOME_SCREEN_METRICS = {
    GET: {
      DATA_URL: 'home-metric-set-assignments',
    },
  };
  private PHASE_DURATIONS = {
    GET: {
      DATA_URL: 'phase-metrics',
    },
  };
  private PHASE_COMMENTS = {
    CRUD: {
      DATA_URL: '/comments',
    },
  };
  private readonly LINE_INFORMATION = {
    GET: {
      DATA_URL: '/work-orders/get-line-info-of-work-order',
    },
  };
  private readonly phaseCommentFromWorkOrderURL: string = '/work-orders';
  private readonly splitWorkOrderOnLineURL: string = '/split-current-work-order';
  private readonly splitWorkOrderOnLinePredictedWorkOrderNumberURL: string = '/predicted-work-order-number-for-split';
  private targetDurationInMilliseconds: number;

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private baseUrl: string,
    private translate: TranslateService,
    private helperService: HelperService,
    private readonly decimalHelper: DecimalHelper,
  ) {}

  onNotify(event: TreeChartServiceInterface): void {
    this.notify.next(event);
  }

  getHomeScreenMetricItems(httpParams: HttpParams): Observable<GetManyHomeMetricSetAssignmentCrudResponse> {
    return this.http.get<GetManyHomeMetricSetAssignmentCrudResponse>(
      `${this.baseUrl}/${this.HOME_SCREEN_METRICS.GET.DATA_URL}`,
      {
        params: httpParams,
      },
    );
  }

  getPhaseDuration(lineId: number): Observable<BaseOneResponseInterface<PhaseDurationsInterface>> {
    return this.http.get<BaseOneResponseInterface<PhaseDurationsInterface>>(
      `${this.baseUrl}/lines/${lineId}/${this.PHASE_DURATIONS.GET.DATA_URL}`,
    );
  }

  public getPhaseDurationInfo(
    phaseDuration: PhaseDurationInterface,
    userTimezone: string,
    lineTimer: string,
    shouldIgnorePhaseTimer: boolean,
  ): PhaseDurationMetricInterface {
    const phaseTimeAndTotalSeconds = this.updatePhaseTimer(phaseDuration, userTimezone, lineTimer);
    const phaseTimer = phaseTimeAndTotalSeconds[0] as string;
    const totalSeconds = phaseTimeAndTotalSeconds[1] as number;
    const phaseDurationRate = this.phaseDurationPercentage(phaseDuration, totalSeconds, false);
    const phaseDurationRateCapped = this.phaseDurationPercentage(phaseDuration, totalSeconds, true);
    const phaseText = this.getPhaseText(phaseDuration);
    const phaseDurationText = this.translate.instant('homeScreenMetrics.items.phaseDuration', {
      activityOrder: phaseText,
    });
    let phaseDurationTextIcon = null;

    if (phaseDuration.isCurrent) {
      phaseDurationTextIcon = shouldIgnorePhaseTimer
        ? 'fa fa-pause-circle-o grey-text fa-lg'
        : 'fa fa-clock-o text-info fa-lg';
    }

    const roundedTarget =
      phaseDuration.phaseTargetDuration === null || lineTimer === null
        ? null
        : this.decimalHelper.toFixedValue(phaseDuration.phaseTargetDuration);

    const targetExceeded =
      phaseDuration.phaseTargetDuration !== null &&
      this.targetDurationInMilliseconds !== null &&
      this.targetDurationInMilliseconds - totalSeconds * 1000 < 0;
    const color = targetExceeded ? 'downTime' : 'default';
    const progressBarClass = targetExceeded ? 'bg-c-red' : 'bg-c-grey';
    const styles = activityStyles(color);
    const phaseDurationTextClass = styles.activityDurationTextClass;
    const unit = this.translate.instant('general.shortHour');

    return {
      phaseText,
      phaseDurationText,
      phaseDurationTextIcon,
      roundedTarget,
      phaseTimer,
      progressBarClass,
      phaseDurationTextClass,
      unit,
      phaseDurationRate: this.decimalHelper.toFixedValue(phaseDurationRate.toString(), 2),
      phaseDurationRateCapped: Math.round(phaseDurationRateCapped),
    };
  }

  public phaseDurationPercentage(
    phaseDuration: PhaseDurationInterface,
    totalSeconds: number,
    isLimited: boolean,
  ): number {
    const target: number = Number(phaseDuration.phaseTargetDuration);
    if (!target) {
      return 0;
    }

    const oneHour: number = 3600000;
    this.targetDurationInMilliseconds = target * oneHour;

    if (this.targetDurationInMilliseconds === 0) {
      return 0;
    }

    const percentage: number = (((totalSeconds * 1000) / this.targetDurationInMilliseconds) * 100);

    if (isLimited && percentage > 100) {
      return 100;
    }

    if (isLimited && percentage < 0) {
      return 0;
    }

    return percentage;
  }

  private getPhaseText(phaseDuration: PhaseDurationInterface): string {
    let activity = phaseDuration.activityName;

    if (activity === null) {
      switch (phaseDuration.phaseName) {
        case 'post-run':
          activity = this.translate.instant('homeScreenMetrics.activityOrderTypes.post');
          break;
        case 'run':
          activity = this.translate.instant('homeScreenMetrics.activityOrderTypes.run');
          break;
        case 'pre-run':
          activity = this.translate.instant('homeScreenMetrics.activityOrderTypes.pre');
          break;
        default:
          activity = this.translate.instant('homeScreenMetrics.activityOrderTypes.total');
          break;
      }
    }

    return activity;
  }

  updatePhaseTimer(
    phaseDuration: PhaseDurationInterface,
    userTimezone: string,
    lineTimer: string,
  ): (string | number)[] {
    const totalSeconds: number = Number(phaseDuration.phaseDuration);

    if (!phaseDuration.phaseDuration || lineTimer === null) {
      return [null, null];
    }

    const phaseTimer: string = this.decimalHelper.divide(totalSeconds.toString(), String(3600));

    return [this.decimalHelper.toFixedValue(phaseTimer), totalSeconds];
  }

  public getPhaseComments(workOrderId: number): Observable<BaseOneResponseInterface<PhaseCommentsResponseInterface>> {
    const url: string = `${this.phaseCommentFromWorkOrderURL}/${workOrderId}/comments`;

    return this.http.get<BaseOneResponseInterface<PhaseCommentsResponseInterface>>(`${this.baseUrl}${url}`);
  }

  public updatePhaseComment(id: number, message: null | string): Observable<PhaseCommentCrudUpdateResponseInterface> {
    return this.http.patch<PhaseCommentCrudUpdateResponseInterface>(
      `${this.baseUrl}${this.PHASE_COMMENTS.CRUD.DATA_URL}/${id}`,
      {
        commentMessage: message,
      },
    );
  }

  public deletePhaseComment(id: number): Observable<PhaseCommentCrudUpdateResponseInterface> {
    return this.http.delete<PhaseCommentCrudUpdateResponseInterface>(
      `${this.baseUrl}${this.PHASE_COMMENTS.CRUD.DATA_URL}/${id}`,
    );
  }

  public getLineInfoOfSelectedWorkOrder(
    id: number,
    lineId: number,
  ): Observable<BaseOneResponseInterface<ILineInfoThatWorkOrder>> {
    return this.http.get<BaseOneResponseInterface<ILineInfoThatWorkOrder>>(
      `${this.baseUrl}${this.LINE_INFORMATION.GET.DATA_URL}/${id}/${lineId}`,
    );
  }

  public createPhaseComment(
    siteId: number,
    workOrderId: number,
    message: null | string,
    phaseType: ShiftSummaryCommentObjectPropertyTypes,
  ): Observable<PhaseCommentCrudUpdateResponseInterface> {
    return this.http.post<PhaseCommentCrudUpdateResponseInterface>(
      `${this.baseUrl}${this.PHASE_COMMENTS.CRUD.DATA_URL}`,
      {
        siteId,
        workOrderId: Number(workOrderId),
        objectProperty: phaseType,
        objectId: workOrderId,
        objectType: 'work_order_schedule',
        commentTypeId: 3,
        commentMessage: message,
      },
    );
  }

  public getTableHeaders(translate: TranslateService): DatatableHeaderInterface[] {
    return [
      {
        value: 'header',
        name: '',
        sortable: false,
        width: EColumnWidth.checkbox,
      },
      {
        value: 'actual',
        name: translate.instant('main.batchClose.actualCount'),
        sortable: false,
      },
      {
        value: 'standard',
        name: translate.instant('main.batchClose.standards'),
        sortable: false,
      },
      {
        value: 'variance',
        name: translate.instant('main.batchClose.variance'),
        sortable: false,
      },
      {
        value: 'variancePercentage',
        name: `${translate.instant('main.batchClose.variance')}%`,
        sortable: false,
      },
    ];
  }

  public splitWorkOrderOnLine(id: number): Observable<BaseCrudResponse> {
    return this.http.post<BaseCrudResponse>(`${this.baseUrl}/lines/${id}${this.splitWorkOrderOnLineURL}`, {});
  }

  public getSplitWorkOrderOnLinePredictedWorkOrderNumber(
    id: number,
  ): Observable<BaseOneResponseInterface<string>> {
    return this.http.get<BaseOneResponseInterface<string>>(
      `${this.baseUrl}/lines/${id}${this.splitWorkOrderOnLinePredictedWorkOrderNumberURL}`,
    );
  }
}
