import * as Actions from './capacity.actions';
import { CapacityStateInterface } from './capacity.model';

export const capacityInitialState: CapacityStateInterface = {
  isCapacityDataLoading: false,
  isCapacityDataLoaded: false,
  capacityData: [],
};

export function capacityReducer(
  state: CapacityStateInterface = capacityInitialState,
  action: Actions.CapacityActions,
): CapacityStateInterface {
  switch (action.type) {
    case Actions.CAPACITY_DATA_LOADING:
      return {
        ...state,
        isCapacityDataLoading: true,
        isCapacityDataLoaded: false,
      };

    case Actions.CAPACITY_DATA_LOADED:
      return {
        ...state,
        isCapacityDataLoaded: true,
        isCapacityDataLoading: false,
        capacityData: action.payload.data,
      };

    case Actions.FETCH_ERROR:
      return {
        ...state,
        isCapacityDataLoaded: false,
        isCapacityDataLoading: false,
      };
    default:
      return state;
  }
}
