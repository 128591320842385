<div class="offline-sensor-item row">
  <div class="sensor-data-display col-lg-10">
    <span>{{ data.sensorName }} - {{ sensorTypeText }}</span>
    <span>{{ data.sensorId }}</span>
  </div>
  <span class="col-lg-2">
    <em
      [ngClass]="offlineIndicatorIconClass"
      popoverTitle="{{ 'alertControl.offlineSensorStatus.offlineSensorDate' | translate }}"
      ngbPopover="{{ data.lastCommDate }}"
      [autoClose]="'outside'"
      [disablePopover]="offlineIndicatorIconClass !== 'fas fa-circle offline-sensor'"
      container="body"
    ></em>
  </span>
</div>
