import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScwMatWizardStepComponent } from './scw-mat-wizard-step.component';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ScwMatButtonModule } from '../scw-mat-button/scw-mat-button.module';

@NgModule({
  imports: [CommonModule, MatCardModule, MatProgressBarModule, ScwMatButtonModule],
  exports: [ScwMatWizardStepComponent],
  declarations: [ScwMatWizardStepComponent],
})
export class ScwMatWizardStepModule {}
