import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  DeletePlanCRUDResponseInterface,
  ExceptionPlanDataInterface,
  LineAvailabilityResponseInterface,
  SchedulerPlanCRUDResponseInterface,
  TableQueryParams,
} from '../../../store/line-availability/line-availability.model';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { mysqlDateFormat } from '../../helper/date';

@Injectable({
  providedIn: 'root',
})
export class ExceptionPlanDataService {
  constructor(public http: HttpClient, @Inject('API_BASE_URL') private baseUrl: string) {}

  private SCHEDULER_EXCEPTION_PLAN_BASE_URL = '/scheduler/shift-exceptions';
  private SCHEDULER_EXCEPTION_PLAN = {
    GET: {
      GET_ALL_PLANS: `${this.baseUrl}${this.SCHEDULER_EXCEPTION_PLAN_BASE_URL}`,
    },
    DELETE: {
      DELETE_PLAN: `${this.baseUrl}${this.SCHEDULER_EXCEPTION_PLAN_BASE_URL}`,
    },
    POST: {
      CREATE_PLAN: `${this.baseUrl}${this.SCHEDULER_EXCEPTION_PLAN_BASE_URL}`,
    },
    PATCH: {
      EDIT_PLAN: `${this.baseUrl}${this.SCHEDULER_EXCEPTION_PLAN_BASE_URL}`,
    },
  };

  public getData(
    planId: number,
    query: TableQueryParams = {
      page: 1,
      pageSize: 10,
    },
  ): Observable<LineAvailabilityResponseInterface<ExceptionPlanDataInterface>> {
    let searchString = '';
    const queryString: string[] = [`page=${query.page}`, `limit=${query.pageSize}`];
    const searchObj = {};

    if (query.hasOwnProperty('search') && query.search.length > 0) {
      _.set(searchObj, 'name.$cont', query.search);
    }

    _.set(searchObj, 'endDate.$gte', moment().format(mysqlDateFormat));
    _.set(searchObj, 'planId.$eq', planId);
    queryString.push(`s=${JSON.stringify(searchObj)}`);
    queryString.forEach((params: string) => (searchString += `${params}&`));

    if (query.hasOwnProperty('orderDesc') && query.hasOwnProperty('orderBy')) {
      searchString += `&sort=${query.orderBy},${query.orderDesc ? 'DESC' : 'ASC'}`;
    }
    return this.http.get<LineAvailabilityResponseInterface<ExceptionPlanDataInterface>>(
      `${this.SCHEDULER_EXCEPTION_PLAN.GET.GET_ALL_PLANS}?${searchString}`,
    );
  }

  public deleteData(id: number): Observable<DeletePlanCRUDResponseInterface<ExceptionPlanDataInterface>> {
    return this.http.delete<DeletePlanCRUDResponseInterface<ExceptionPlanDataInterface>>(
      `${this.SCHEDULER_EXCEPTION_PLAN.DELETE.DELETE_PLAN}/${id}`,
    );
  }

  public createData(
    payload: ExceptionPlanDataInterface,
  ): Observable<SchedulerPlanCRUDResponseInterface<ExceptionPlanDataInterface>> {
    return this.http.post<SchedulerPlanCRUDResponseInterface<ExceptionPlanDataInterface>>(
      `${this.SCHEDULER_EXCEPTION_PLAN.POST.CREATE_PLAN}`,
      payload,
    );
  }

  public editData(
    id: number,
    payload: ExceptionPlanDataInterface,
  ): Observable<SchedulerPlanCRUDResponseInterface<ExceptionPlanDataInterface>> {
    return this.http.patch<SchedulerPlanCRUDResponseInterface<ExceptionPlanDataInterface>>(
      `${this.SCHEDULER_EXCEPTION_PLAN.PATCH.EDIT_PLAN}/${id}`,
      payload,
    );
  }

  public checkIfExceptionExists(
    startDate: string,
    endDate: string,
    planId: number,
    id?: number,
  ): Observable<LineAvailabilityResponseInterface<ExceptionPlanDataInterface>> {
    const searchQuery: any = {
      $and: [
        {
          planId: {
            $eq: planId,
          },
        },
        {
          $or: [
            {
              $and: [
                {
                  startDate: {
                    $lte: endDate,
                  },
                },
                {
                  startDate: {
                    $gte: startDate,
                  },
                },
              ],
            },
            {
              $and: [
                {
                  endDate: {
                    $lte: endDate,
                  },
                },
                {
                  endDate: {
                    $gte: startDate,
                  },
                },
              ],
            },
            {
              $and: [
                {
                  startDate: {
                    $gte: startDate,
                  },
                },
                {
                  endDate: {
                    $lte: endDate,
                  },
                },
              ],
            },
            {
              $and: [
                {
                  startDate: {
                    $lte: startDate,
                  },
                },
                {
                  endDate: {
                    $gte: endDate,
                  },
                },
              ],
            },
          ],
        },
      ],
    };

    if (id !== undefined) {
      searchQuery.$and.push({ id: { $ne: id } });
    }

    return this.http.get<LineAvailabilityResponseInterface<ExceptionPlanDataInterface>>(
      `${this.SCHEDULER_EXCEPTION_PLAN.GET.GET_ALL_PLANS}?s=${JSON.stringify(searchQuery)}`,
    );
  }
}
