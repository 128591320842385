import { Action } from '@ngrx/store';
import { WorkOrderCreateRequestInterface } from './work-order.model';
import { GetWorkOrderNumbersDataResponseInterface, WorkOrderUpdateDataInterface } from '../home/home.model';
import { BaseOneResponseInterface } from '../../shared/model/interface/crud-response-interface.model';
import { WorkOrderScheduleInterface } from '../work-order-schedule/work-order-schedule.model';

export enum WorkOrderActionTypes {
  GetWorkOrder = '[WorkOrder] Get Work Order',
  GetWorkOrderCompleted = '[WorkOrder] Get Work Order Completed',
  CreateWorkOrder = '[WorkOrder] Create Work Order',
  CreateWorkOrderCompleted = '[WorkOrder] Create Work Order Completed',
  UpdateWorkOrder = '[WorkOrder] Update Work Order',
  UpdateWorkOrderCompleted = '[WorkOrder] Update Work Order Completed',
  FetchError = '[Home] Fetch Error',
}

export class CreateWorkOrder implements Action {
  readonly type = WorkOrderActionTypes.CreateWorkOrder;

  constructor(public payload: WorkOrderCreateRequestInterface) {}
}

export class CreateWorkOrderCompleted implements Action {
  readonly type = WorkOrderActionTypes.CreateWorkOrderCompleted;

  constructor(public response: GetWorkOrderNumbersDataResponseInterface) {}
}

export class UpdateWorkOrder implements Action {
  readonly type = WorkOrderActionTypes.UpdateWorkOrder;

  constructor(public payload: WorkOrderUpdateDataInterface, public id: number) {}
}

export class UpdateWorkOrderCompleted implements Action {
  readonly type = WorkOrderActionTypes.UpdateWorkOrderCompleted;

  constructor(public response: GetWorkOrderNumbersDataResponseInterface) {}
}

export class GetWorkOrder implements Action {
  readonly type = WorkOrderActionTypes.GetWorkOrder;

  constructor(public id: number) {}
}

export class GetWorkOrderCompleted implements Action {
  readonly type = WorkOrderActionTypes.GetWorkOrderCompleted;

  constructor(public response: BaseOneResponseInterface<WorkOrderScheduleInterface>) {}
}

export class FetchError implements Action {
  readonly type = WorkOrderActionTypes.FetchError;

  constructor(public payload: any[]) {}
}

export type WorkOrderActions =
  | CreateWorkOrder
  | CreateWorkOrderCompleted
  | UpdateWorkOrder
  | UpdateWorkOrderCompleted
  | GetWorkOrder
  | GetWorkOrderCompleted
  | FetchError;
