import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { ResponseArrayInterface } from '../../../../shared/model/interface/generic-api-response.model';
import { DepartmentBulkResponseInterface, IDatatableParams, IDepartment, IProductionUnit } from './departments.model';
import { DatatableHeaderInterface } from '../../../../shared/component/datatable/datatable.model';
import { EColumnWidth } from '../../../../shared/service/datatable/datatable.model';
import { TranslateService } from '@ngx-translate/core';
import { FilterCardOptionInterface } from '../../../../shared/component/filter/filter.class';
import { DropdownComponent } from '../../../../shared/component/filter/dropdown/dropdown.component';
import { Statuses } from '../../../../shared/component/filter/filterable-objects.class';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../../shared/model/interface/crud-response-interface.model';
import { EFilterDropdownElements } from '../../../filter/filter.model';

@Injectable({
  providedIn: 'root',
})
export class DepartmentsService {
  private routes = {
    lineType: '/line-types',
    lineTypeProductionUnit: '/line-type-production-units',
    bulkEdit: '/line-types/bulk/edit',
    bulkDelete: '/line-types/bulk/delete',
  };

  public tableQuery: IDatatableParams = {
    page: 1,
    pageSize: 10,
    orderBy: 'id',
    orderDesc: true,
  };

  public getTableHeaders(translate: TranslateService): DatatableHeaderInterface[] {
    return [
      {
        value: null,
        name: '',
        sortable: false,
        width: EColumnWidth.checkbox,
      },
      {
        value: 'order',
        name: translate.instant('general.dataTable.header.order'),
        selected: true,
      },
      {
        value: 'lineType',
        name: translate.instant('general.dataTable.header.departmentName'),
        selected: true,
      },
      {
        value: 'productionUnitName',
        name: translate.instant('general.dataTable.header.productionUnit'),
        selected: true,
      },
      {
        value: 'status',
        name: translate.instant('general.dataTable.header.status'),
        selected: true,
      },
      {
        value: 'description',
        name: translate.instant('general.dataTable.header.description'),
        selected: true,
      },
    ];
  }

  public getFilterOptions(translate: TranslateService): FilterCardOptionInterface {
    return {
      rows: [
        [
          {
            type: DropdownComponent,
            cls: 'col-md-3 col-sm-4',
            object: Statuses,
            elementId: EFilterDropdownElements.statusSingleSelectDropdown,
            outputOptions: {
              filterObjectId: 'status',
              filterObjectProp: 'id',
              returnFilterObjectAllProp: false,
            },
            options: {
              text: translate.instant('filterCard.status.dropdownPlaceHolder'),
              isRequired: false,
              isStaticDropdown: true,
              singleSelection: true,
            },
          },
        ],
      ],
    };
  }

  constructor(public http: HttpClient, @Inject('API_BASE_URL') private api: string) {}

  public getDepartments(params: HttpParams): Observable<ResponseArrayInterface<IDepartment>> {
    return this.http.get<ResponseArrayInterface<IDepartment>>(`${this.api}${this.routes.lineType}`, { params });
  }

  public getProductionUnits(params: HttpParams): Observable<ResponseArrayInterface<IProductionUnit>> {
    return this.http.get<ResponseArrayInterface<IProductionUnit>>(`${this.api}${this.routes.lineTypeProductionUnit}`, {
      params,
    });
  }

  public addDepartment(department: IDepartment): Observable<BaseOneResponseInterface<IDepartment>> {
    return this.http.post<BaseOneResponseInterface<IDepartment>>(`${this.api}${this.routes.lineType}`, department);
  }

  public editDepartments(
    department: IDepartment,
    departmentId: number,
  ): Observable<BaseOneResponseInterface<IDepartment>> {
    return this.http.patch<BaseOneResponseInterface<IDepartment>>(
      `${this.api}${this.routes.lineType}/${departmentId}`,
      department,
    );
  }

  public bulkEditDepartments(
    payload: Partial<IDepartment>[],
    params?: HttpParams,
  ): Observable<GetManyResponseInterface<DepartmentBulkResponseInterface>> {
    return this.http.patch<GetManyResponseInterface<DepartmentBulkResponseInterface>>(
      `${this.api}${this.routes.bulkEdit}`,
      { lineTypes: payload },
      { params },
    );
  }

  public deleteDepartment(departmentId: number): Observable<BulkResponseDataInterface> {
    return this.http.delete<BulkResponseDataInterface>(`${this.api}${this.routes.lineType}/${departmentId}`);
  }

  public bulkDeleteDepartments(departmentId: number[]): Observable<BulkResponseDataInterface> {
    return this.http.delete<BulkResponseDataInterface>(`${this.api}${this.routes.bulkDelete}`, {
      body: { lineTypes: departmentId },
    });
  }
}
