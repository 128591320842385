import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseOneResponseInterface } from '../../shared/model/interface/crud-response-interface.model';
import { IDigitalFormToken } from './digital-form.model';

@Injectable({
  providedIn: 'root',
})
export class DigitalFormService {
  constructor(public http: HttpClient, @Inject('API_BASE_URL') private readonly api: string) {}

  private readonly routes = {
    digitalForms: `${this.api}/lines/:lineId/digital-forms-token`,
  };

  public getDigitalFormsToken(lineId: number): Observable<BaseOneResponseInterface<IDigitalFormToken>> {
    return this.http.get<BaseOneResponseInterface<IDigitalFormToken>>(
      this.routes.digitalForms.replace(':lineId', String(lineId)),
    );
  }
}
