import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  AddScenarioInterface,
  BulkEditScenarioRequestInterface,
  CreateScenarioResponseInterface,
  DatatableDataRequestParameterInterface,
  DeleteScenarioResponseInterface,
  ScenarioBulkResponse,
  ScenarioBulkResponseDataInterface,
  ScenarioListResponseInterface,
  UpdateScenarioRequestInterface,
  UpdateScenarioResponseInterface,
} from '../../../store/scheduler-scenario/scheduler-scenario.model';
import {
  FieldTypes,
  IFilterOutput,
  QueryTypes,
  TargetEndpoints,
} from '../../component/filter/advanced-filter/advanced-filter.model';
import { AdvancedFilterService } from '../../component/filter/advanced-filter/advanced-filter.service';
import * as _ from 'lodash';
import { BaseOneResponseInterface } from '../../model/interface/crud-response-interface.model';
import { ActiveStatuses } from '../../../../constants';

@Injectable({
  providedIn: 'root',
})
export class SchedulerScenarioService {
  private readonly routes = {
    scenarios: '/scheduler/scenarios',
    bulkEdit: '/scheduler/scenarios/bulk/edit',
    bulkDelete: '/scheduler/scenarios/bulk/delete',
  };

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly api: string,
    private readonly advancedFilterService: AdvancedFilterService,
  ) {}

  public loadScenarios(
    serviceParameters: DatatableDataRequestParameterInterface,
  ): Observable<ScenarioListResponseInterface> {
    let params: HttpParams = new HttpParams();

    params = params
      .set('page', String(serviceParameters.page))
      .set('limit', String(serviceParameters.pageSize))
      .append('join', 'schSetting||name')
      .append('join', 'user||fullName')
      .append('join', 'owner||fullName')
      .append('join', 'site||name,menuPermissions');

    if (typeof serviceParameters.sortBy !== 'undefined' && typeof serviceParameters.sortType !== 'undefined') {
      params = params.set('sort', `${serviceParameters.sortBy},${serviceParameters.sortType}`);
    }

    if (serviceParameters.search !== undefined || (serviceParameters.siteIds !== -1 &&
      serviceParameters.siteIds !== undefined) || (serviceParameters.planned !== -1 &&
      serviceParameters.planned !== undefined)) {
      params = params.append('s', this.getScenarioSearchParams(serviceParameters));
    }

    if (!_.isEmpty(serviceParameters.advancedFilter)) {
      const queryStringFilters: IFilterOutput[] = serviceParameters.advancedFilter.filters.filter(
        (filter: IFilterOutput) => {
          return filter.queryType === QueryTypes.withinRequestQueryString;
        },
      );

      queryStringFilters.forEach((filter: IFilterOutput) => {
        if (filter.type === FieldTypes.predefined) {
          params = params.set(filter.path, _.get(filter.value, `[0][${filter.searchBy}]`, ''));
        } else {
          params = params.set(filter.path, filter.value);
        }
      });
    }

    return this.http.get<ScenarioListResponseInterface>(`${this.api}${this.routes.scenarios}`, {
      params,
    });
  }

  private getScenarioSearchParams(payload: DatatableDataRequestParameterInterface): string {
    const searchParams: { [key: string]: any } = {
      $and: [
        payload.siteIds !== -1
          ? {
            siteId: {
              $in: payload.siteIds,
            },
          }
          : {},
        payload.planned !== -1
          ? {
            planned: {
              $in: payload.planned,
            },
          }
          : {},
        payload.deployed !== -1
          ? {
            deployed: {
              $in: payload.deployed,
            },
          }
          : {},
        {
          $or: [
            {
              name: {
                $cont: `${payload.search}`,
              },
            },
            {
              scenarioNumber: !_.isNaN(Number(payload.search))
                ? {
                  $eq: `${payload.search}`,
                }
                : {},
            },
          ],
        },
      ],
    };

    if (payload.advancedFilter) {
      const advancedFilter: IFilterOutput[] = payload.advancedFilter.filters;

      for (const filter of advancedFilter) {
        if (filter.queryType !== QueryTypes.withinAdvancedFilterParams) {
          continue;
        }

        if (filter.type === FieldTypes.predefined) {
          searchParams['$and'].push(
            this.advancedFilterService.generateQuery(
              filter.path,
              filter.type,
              filter.operator.name,
              filter.operator.type,
              TargetEndpoints.NestJSCrud,
              filter.value[0][filter.searchBy],
            ),
          );
        } else {
          searchParams['$and'].push(
            this.advancedFilterService.generateQuery(
              filter.path,
              filter.type,
              filter.operator.name,
              filter.operator.type,
              TargetEndpoints.NestJSCrud,
              filter.value,
            ),
          );
        }
      }
    }

    return JSON.stringify(searchParams);
  }

  public createScenario(
  addScenario: AddScenarioInterface,
  ): Observable<CreateScenarioResponseInterface> {
    return this.http.post<CreateScenarioResponseInterface>(`${this.api}${this.routes.scenarios}`, {
      ...addScenario,
    });
  }

  public updateScenario(
    id: number,
    scenario: UpdateScenarioRequestInterface,
  ): Observable<UpdateScenarioResponseInterface> {
    const params: HttpParams = new HttpParams()
      .append('join', 'schSetting||name')
      .append('join', 'schSetting.schSettingLine.line')
      .append(
        's',
        JSON.stringify({
          'schSetting.schSettingLine.line.statusId': { $eq: 1 },
        }),
      );

    return this.http.patch<UpdateScenarioResponseInterface>(
      `${this.api}${this.routes.scenarios}/${id}`,
      {
        ...scenario,
      },
      {
        params,
      },
    );
  }

  public bulkEditScenarios(scenarios: BulkEditScenarioRequestInterface[]):
                                              Observable<BaseOneResponseInterface<ScenarioBulkResponse[]>> {
    return this.http.patch<BaseOneResponseInterface<ScenarioBulkResponse[]>>(
      `${this.api}${this.routes.bulkEdit}`, { scenarios });
  }

  public deleteScenario(id: number): Observable<DeleteScenarioResponseInterface> {
    return this.http.delete<DeleteScenarioResponseInterface>(`${this.api}${this.routes.scenarios}/${id}`);
  }

  public deleteScenarios(scenario: number[]): Observable<ScenarioBulkResponseDataInterface> {
    if (Array.isArray(scenario) && scenario.length > 1) {
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        body: {
          scenarios: scenario,
        },
      };
      return this.http.delete<ScenarioBulkResponseDataInterface>(`${this.api}${this.routes.bulkDelete}`, httpOptions);
    }
    return this.http.delete<ScenarioBulkResponseDataInterface>(`${this.api}${this.routes.scenarios}/${scenario[0]}`);
  }

  public getUserHttpParams(userId: number, searchText: string = null, siteId?: number): HttpParams {
    const orFilter: { [key: string]: unknown[]} = { $or: [] };
    let queryParams: HttpParams = new HttpParams().set('fields', 'id,fullName').set('limit', 20);

    if (searchText === null && userId !== null) {
      orFilter['$or'].push({ id: { $eq: userId } });
    }

    if (searchText !== null) {
      orFilter['$or'].push({ fullName: { $cont: searchText } });
    }

    queryParams = queryParams.set(
      's',
      JSON.stringify({
        $and: [
          orFilter,
          {
            isActive: {
              $eq: ActiveStatuses.ACTIVE,
            },
          },
        ],
      }),
    );

    if (!_.isNil(siteId)) {
      queryParams = queryParams.set('siteId', siteId);
    }

    return queryParams;
  }
}
