export enum EStackSkillMatrixChartFilterDropdown {
  PRODUCT = 'product',
  PRODUCT_FAMILY = 'productFamily',
  LINE = 'line',
  USER = 'user',
  ASCENDING = 'asc',
  DESCENDING = 'desc',
}

export interface IStackSkillMatrixChartFilterDropdownOptions {
  id: EStackSkillMatrixChartFilterDropdown;
  name: string;
}
