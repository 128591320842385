import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PhasesInterfaceResponseInterface } from './phase-selection.model';

@Injectable({
  providedIn: 'root',
})
export class PhaseSelectionService {
  private PHASES = {
    GET: {
      DATA_URL: '/phases',
    },
  };

  private LINES = {
    GET: {
      DATA_URL: '/lines',
    },
  };

  constructor(public http: HttpClient, @Inject('API_BASE_URL') private baseUrl: string) {}

  public getPhases(lineId: number): Observable<PhasesInterfaceResponseInterface> {
    return this.http.get<PhasesInterfaceResponseInterface>(
      `${this.baseUrl}${this.LINES.GET.DATA_URL}/${lineId}${this.PHASES.GET.DATA_URL}`,
    );
  }
}
