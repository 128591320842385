import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScwMatCronInputComponent } from './scw-mat-cron-input/scw-mat-cron-input.component';
import { CronPosixService } from './services/cron-posix.service';
import { CronDataService } from './services/cron-data.service';
import { ScwMatSelectModule } from '../scw-mat-select/scw-mat-select.module';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, ScwMatSelectModule, MatFormFieldModule],
  declarations: [ScwMatCronInputComponent],
  exports: [ScwMatCronInputComponent],
  providers: [CronDataService, CronPosixService],
})
export class ScwMatCronInputModule {}
