import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import {
  IUserConfiguration,
  IUserConfigurationResponse,
} from '../../../store/user-configuration/user-configuration.model';
import { Observable } from 'rxjs';
import { ResponseInterface } from '../../model/interface/generic-api-response.model';
import { IObjectConfiguration } from '../../../store/line-view-work-order-summary/line-view-work-order-summary.model';

interface BaseResponseInterface {
  success: boolean;
  data: {};
}

export interface SavePageFiltersAndSettingsResponseInterface extends BaseResponseInterface {}

export interface GetFilterInterface {
  id: string;
  key: string;
  value: string;
}

export interface GetPageFiltersAndSettingsResponseInterface extends BaseResponseInterface {
  data: GetFilterInterface[];
}

export interface FilterBodyInterface {
  page: string;
  filtersAndSettings: FiltersAndSettingsInterface[];
}

interface FiltersAndSettingsInterface {
  key: string;
  value: string;
}

interface FilterDataInterface {
  [key: string]: Object[];
}

export interface PreparedFilterDataInterface {
  [key: string]: number[] | string[];
}

@Injectable({
  providedIn: 'root',
})
export class PageFiltersAndSettingsService {
  constructor(public http: HttpClient, @Inject('API_BASE_URL') private readonly baseUrl: string) {}

  private filterUrls = {
    GET: {
      FILTER_URL: `${this.baseUrl}/user-filters-and-settings`,
    },
    POST: {
      FILTER_URL: '/api/user-filters-and-settings',
    },
  };

  private readonly routes = {
    accountSettings: `${this.baseUrl}/users/account-settings`,
  };

  savePageFiltersAndSettings(filterBody: FilterBodyInterface): Promise<SavePageFiltersAndSettingsResponseInterface> {
    return new Promise((resolve, reject) => {
      this.http.post(this.filterUrls.POST.FILTER_URL, filterBody).subscribe(
        (response: SavePageFiltersAndSettingsResponseInterface) => {
          if (response.success) {
            resolve(response);
          } else {
            reject();
          }
        },
        (error: HttpErrorResponse) => {
          reject(error);
        },
      );
    });
  }

  public getUserConfigurationData(): Observable<ResponseInterface<IUserConfigurationResponse>> {
    const params: HttpParams = new HttpParams().set('fields', 'configuration');
    return this.http.get<ResponseInterface<IUserConfigurationResponse>>(this.routes.accountSettings, { params });
  }

  public updateUserConfigurationData(
    configuration: IUserConfiguration,
  ): Observable<ResponseInterface<IUserConfigurationResponse>> {
    return this.http.patch<ResponseInterface<IUserConfigurationResponse>>(
      this.routes.accountSettings,
      {
        configuration,
      }
    );
  }

  public static getObjectConfigurationOfUser(userOptions: string[], defaultOptions: string[]): IObjectConfiguration[] {
    return userOptions
      .map((name: string) => {
        return { name, selected: true };
      })
      .concat(
        defaultOptions
          .filter((name: string) => !userOptions.includes(name))
          .map((name: string) => {
            return { name, selected: false };
          }),
      );
  }
}
