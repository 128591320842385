import { EventModel, EventStore, ProjectModel } from '@bryntum/scheduler';
import { LineAvailabilityEventRecord } from './default-week-plan/default-week-plan.model';
import { IFormValue } from '../settings/equipment-assets/equipment-types/equipment-types.model';
import { IDefaultSelectItem } from '../../shared/component/scw-mat-ui/scw-mat-select/scw-mat-select.model';

export class Task extends EventModel implements LineAvailabilityEventRecord {
  static get $$name() {
    return 'Task';
  }

  override durationMS: any;
  isDisabledZone?: boolean;
  isCalculateDisabledZone?: boolean;
  relatedDisabledZone?: string | number;
  suspendDisableZoneResize?: boolean;
  data: any;
  static override get defaults(): TaskInterface {
    return {
      durationUnit: DurationUnit.SECOND,
      suspendDisableZoneResize: false,
      isDisabledZone: false,
      isCalculateDisabledZone: false,
    };
  }
  static override get fields() {
    return [
      { name: 'shiftId' },
      { name: 'durationMS', dataSource: 'durationMS' },
      { name: 'isDisabledZone', dataSource: 'isDisabledZone' },
      { name: 'isCalculateDisabledZone', dataSource: 'isCalculateDisabledZone' },
      { name: 'relatedDisabledZone', dataSource: 'relatedDisabledZone' },
      { name: 'suspendDisableZoneResize' },
      { name: 'isNotAvailable', dataSource: 'isNotAvailable' },
    ];
  }
}

export class TaskStore extends EventStore {
  static get defaultConfig() {
    return {
      modelClass: Task,
      project: new ProjectModel(),
    };
  }
}

export interface TaskInterface {
  durationUnit: string;
  suspendDisableZoneResize: boolean;
  isDisabledZone: boolean;
  isCalculateDisabledZone: boolean;
}

export interface OnModalActionInterface {
  modalType: string;
  data?: {
    planId: number;
  };
}

export enum EPlanManagementFields {
  name = 'name',
  siteIds = 'siteIds',
  isOfficial = 'isOfficial',
}

export enum EWeekDays {
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
  Sunday = 7,
}

export enum ECostFactorFields {
  name = 'name',
  siteIds = 'siteIds',
  factor = 'factor',
}

export enum EShiftFields {
  siteIds = 'siteIds',
  name = 'name',
  costFactorId = 'costFactorId',
  isNotAvailable = 'isNotAvailable',
}

export type IPlanManagementForm = {
  [key in EPlanManagementFields]: IFormValue;
};

export type ICostFactorForm = {
  [key in ECostFactorFields]: IFormValue;
};

export type IShiftForm = {
  [key in EShiftFields]: IFormValue;
};

export interface LineAvailabilityQueryParams {
  page: number;
  pageSize: number;
  search: string;
}

export enum DurationUnit {
  MILLISECOND = 'millisecond',
  SECOND = 'second',
  MINUTE = 'minute',
  HOUR = 'hour',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  QUARTER = 'quarter',
  YEAR = 'year',
}
