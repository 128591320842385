import { IObjectWithEntityTranslations } from 'src/app/shared/service/entity-translator/entity-translator.model';

import { TaskCRUDInterface } from '../tasks/tasks.model';
import { ITaskEquipmentDetail } from '../../equipment-task/equipment-task.model';

export interface TreeNodesStateInterface {
  treeNodesDataLoaded: boolean;
  treeNodesDataLoading: boolean;
  treeNodes: ITreeNode[];
  treeNodesAddLoading: boolean;
  treeNodesAddLoaded: boolean;
  treeNodesEditLoading: boolean;
  treeNodesEditLoaded: boolean;
  treeNodesDeleteLoading: boolean;
  treeNodesDeleteLoaded: boolean;
}

export interface ITreeNode extends IObjectWithEntityTranslations {
  id: number;
  rootType: ETreeRootType;
  rootAddress: IRootAddress;
  name: string;
  parentId: number;
  parentName?: string;
}

export interface IGetTreeNodes {
  rootType: ETreeRootType;
  rootAddress: IRootAddress;
  parentId?: number;
  search?: string;
}

export enum ETreeRootType {
  TASK_TREE = 'task_tree',
}

export class IRootAddress {
  lineId: number;
  activityId: number;
}

export type TSkippedTreeNodeMetadata = { id: number; name: string };

export interface ISkippedTreeNodeResponse {
  activityTasks: TaskCRUDInterface[];
  skippedTreeNodeMetadata: TSkippedTreeNodeMetadata[];
  currentTreeNodeData: { current?: ITreeNode; children: ITreeNode[]; tasks: ITaskEquipmentDetail[]; isRoot: boolean };
}
