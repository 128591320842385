import { ILookupState } from './lookups.model';
import * as LookupActions from './lookups.actions';

export const lookupsInitialState: ILookupState = {
  isLookupsDataLoading: false,
  isLookupsDataLoaded: false,
  lookupData: [],
};

export function lookupsReducer(
  state: ILookupState = lookupsInitialState,
  action: LookupActions.LookupActions,
): ILookupState {
  switch (action.type) {
    case LookupActions.START_LOOKUPS_DATA_LOADING:
      return {
        ...state,
        isLookupsDataLoading: true,
        isLookupsDataLoaded: false,
      };
    case LookupActions.LOOKUPS_DATA_LOADED:
      return {
        ...state,
        isLookupsDataLoading: false,
        isLookupsDataLoaded: true,
        lookupData: action.payload,
      };
    case LookupActions.FETCH_DATA_ERROR:
      return {
        ...state,
        isLookupsDataLoading: false,
        isLookupsDataLoaded: true,
        lookupData: [],
      };
    default:
      return state;
  }
}
