<mat-radio-group [(ngModel)]="inputModel" (ngModelChange)="onNgModelChange()">
  <mat-radio-button
    class="scw-mat-radio-button"
    *ngFor="let option of options"
    [class.vertical-alignment]="alignment === 'vertical'"
    [disabled]="option.disabled || false"
    [value]="option.value"
    [labelPosition]="labelPosition"
  >
    {{ option.text }}
  </mat-radio-button>
</mat-radio-group>
