import { Action } from '@ngrx/store';
import {
  DailyPerformanceSummaryFilterInterface,
  DailyPerformanceSummaryInterface, DailyPerformanceSummaryWorkOrderInterface,
} from './daily-performance-summary.model';

export const DAILY_PERFORMANCE_SUMMARY_DATA_LOADING =
  '[DAILY PERFORMANCE SUMMARY] DAILY PERFORMANCE SUMMARY DATA LOADING';
export const DAILY_PERFORMANCE_SUMMARY_DATA_LOADED =
  '[DAILY PERFORMANCE SUMMARY] DAILY PERFORMANCE SUMMARY DATA LOADED';
export const DAILY_PERFORMANCE_SUMMARY_WORK_ORDER_LOADING =
  '[DAILY PERFORMANCE SUMMARY] DAILY PERFORMANCE SUMMARY WORK ORDER LOADING';
export const DAILY_PERFORMANCE_SUMMARY_WORK_ORDER_LOADED =
  '[DAILY PERFORMANCE SUMMARY] DAILY PERFORMANCE SUMMARY WORK ORDER LOADED';
export const RESET_DAILY_PERFORMANCE_SUMMARY_DATA = '[DAILY PERFORMANCE SUMMARY] RESET DAILY PERFORMANCE SUMMARY DATA';
export const FETCH_DATA_ERROR = '[DAILY PERFORMANCE SUMMARY] FETCH DATA ERROR';
export const FETCH_WORK_ORDER_ERROR = '[DAILY PERFORMANCE SUMMARY] FETCH WORK ORDER ERROR';

export class DailyPerformanceSummaryDataLoading implements Action {
  readonly type = DAILY_PERFORMANCE_SUMMARY_DATA_LOADING;

  constructor(public params: DailyPerformanceSummaryFilterInterface) {}
}

export class DailyPerformanceSummaryDataLoaded implements Action {
  readonly type = DAILY_PERFORMANCE_SUMMARY_DATA_LOADED;

  constructor(public payload: DailyPerformanceSummaryInterface) {}
}
export class DailyPerformanceSummaryWorkOrderLoading implements Action {
  readonly type = DAILY_PERFORMANCE_SUMMARY_WORK_ORDER_LOADING;

  constructor(public params: DailyPerformanceSummaryFilterInterface) {}
}

export class DailyPerformanceSummaryWorkOrderLoaded implements Action {
  readonly type = DAILY_PERFORMANCE_SUMMARY_WORK_ORDER_LOADED;

  constructor(public payload: DailyPerformanceSummaryWorkOrderInterface) {}
}

export class ResetDailyPerformanceSummaryData implements Action {
  readonly type = RESET_DAILY_PERFORMANCE_SUMMARY_DATA;
}

export class FetchDataError implements Action {
  readonly type = FETCH_DATA_ERROR;

  constructor(public payload: object) {}
}
export class FetchWorkOrderError implements Action {
  readonly type = FETCH_WORK_ORDER_ERROR;

  constructor(public payload: object) {}
}

export type DailyPerformanceSummaryActions =
  | DailyPerformanceSummaryDataLoading
  | DailyPerformanceSummaryDataLoaded
  | DailyPerformanceSummaryWorkOrderLoading
  | DailyPerformanceSummaryWorkOrderLoaded
  | ResetDailyPerformanceSummaryData
  | FetchDataError
  | FetchWorkOrderError;
