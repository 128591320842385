import { Injectable } from '@angular/core';
import { AssetViewService } from './asset-view.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ObjectActions from './asset-view.actions';
import { catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import * as AppActions from '../../store/app/actions';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../oee.reducer';
import { AssetDashboardGetOneInterface, AssetDashboardResponseInterface } from './asset-view.model';
import { EntityTranslatorService } from '../../shared/service/entity-translator/entity-translator.service';
import { BaseOneResponseInterface } from '../../shared/model/interface/crud-response-interface.model';

@Injectable()
export class AssetViewEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: AssetViewService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly entityTranslatorService: EntityTranslatorService,
  ) {}

  getFilters = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ASSET_VIEW_FILTER_DATA_LOADING),
      switchMap(() => {
        return this.service.getFiltersAndSettings().pipe(
          switchMap((response) => {
            return of(new ObjectActions.AssetViewFilterDataLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getDashboardData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ASSET_VIEW_DASHBOARD_DATA_LOADING),
      switchMap((payload: ObjectActions.AssetViewDashboardDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const httpParams = new HttpParams()
          .append('siteIds', payload.filter.siteIds.join(','))
          .append('lineIds', payload.filter.lineIds.join(','));
        return this.service.getAssetDashboardData(httpParams).pipe(
          switchMap((response: BaseOneResponseInterface<AssetDashboardResponseInterface>) => {
            response.data.assets.forEach((asset: AssetDashboardGetOneInterface) => {
              this.entityTranslatorService.translate(asset);
            });

            return of(new ObjectActions.AssetViewDashboardDataLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  exportDashboardDataAsMail = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ASSET_VIEW_DASHBOARD_MAIL_EXPORT_LOADING),
      switchMap((payload: ObjectActions.AssetViewDashboardMailExportLoading) => {
        return this.service.sendAssetViewMail(payload.payload).pipe(
          switchMap(() => {
            return of(new ObjectActions.AssetViewDashboardMailExportLoaded());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );
}
