import { Action } from '@ngrx/store';
import {
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { CustomerInterface } from '../../../view/settings/customers/customers.model';
import {
  AddCustomerInterface,
  BulkEditCustomerInterface,
  CustomerBulkResponse,
  CustomerDeleteResponseInterface,
  CustomerTableQueryInterface,
} from './customers.model';

export const CUSTOMERS_DATA_LOADING = '[CUSTOMERS] CUSTOMER DATA LOADING';
export const CUSTOMERS_DATA_LOADED = '[CUSTOMERS] CUSTOMER DATA LOADED';
export const ADD_CUSTOMER = '[CUSTOMERS] CUSTOMER ADD CUSTOMER';
export const ADD_CUSTOMER_COMPLETED = '[CUSTOMERS] ADD CUSTOMER COMPLETED';
export const EDIT_CUSTOMER = '[CUSTOMERS] CUSTOMER EDIT CUSTOMER';
export const EDIT_CUSTOMER_COMPLETED = '[CUSTOMERS] EDIT CUSTOMER COMPLETED';
export const BULK_EDIT_CUSTOMER = '[CUSTOMERS] CUSTOMER BULK EDIT CUSTOMER';
export const BULK_EDIT_CUSTOMER_COMPLETED = '[CUSTOMERS] BULK EDIT CUSTOMER COMPLETED';
export const DELETE_CUSTOMERS = '[CUSTOMERS] DELETE CUSTOMERS';
export const DELETE_CUSTOMERS_COMPLETED = '[CUSTOMERS] DELETE CUSTOMERS COMPLETED';
export const FETCH_ERROR = '[CUSTOMERS] FETCH ERROR';

export class CustomersDataLoading implements Action {
  readonly type = CUSTOMERS_DATA_LOADING;

  constructor(public tableQuery: CustomerTableQueryInterface) {}
}

export class CustomersDataLoaded implements Action {
  readonly type = CUSTOMERS_DATA_LOADED;

  constructor(public payload: GetManyResponseInterface<CustomerInterface>) {}
}

export class AddCustomer implements Action {
  readonly type = ADD_CUSTOMER;

  constructor(public customer: AddCustomerInterface) {}
}

export class AddCustomerCompleted implements Action {
  readonly type = ADD_CUSTOMER_COMPLETED;

  constructor(public payload: BaseOneResponseInterface<AddCustomerInterface>) {}
}

export class EditCustomer implements Action {
  readonly type = EDIT_CUSTOMER;

  constructor(public customer: AddCustomerInterface, public id: number) {}
}

export class EditCustomerCompleted implements Action {
  readonly type = EDIT_CUSTOMER_COMPLETED;

  constructor(public payload: BaseOneResponseInterface<AddCustomerInterface>) {}
}

export class BulkEditCustomer implements Action {
  readonly type = BULK_EDIT_CUSTOMER;

  constructor(public customers: BulkEditCustomerInterface[]) {}
}

export class BulkEditCustomerCompleted implements Action {
  readonly type = BULK_EDIT_CUSTOMER_COMPLETED;

  constructor(public customers: BaseOneResponseInterface<CustomerBulkResponse[]>) {}
}

export class DeleteCustomers implements Action {
  readonly type = DELETE_CUSTOMERS;

  constructor(public payload: number[]) {}
}

export class DeleteCustomersCompleted implements Action {
  readonly type = DELETE_CUSTOMERS_COMPLETED;

  constructor(public isBulk: boolean, public response: CustomerDeleteResponseInterface) {}
}
export class FetchError implements Action {
  readonly type = FETCH_ERROR;
  constructor(public payload: object) {}
}

export type CustomersActions =
  | CustomersDataLoading
  | CustomersDataLoaded
  | AddCustomer
  | AddCustomerCompleted
  | EditCustomer
  | EditCustomerCompleted
  | BulkEditCustomer
  | BulkEditCustomerCompleted
  | DeleteCustomers
  | DeleteCustomersCompleted
  | FetchError;
