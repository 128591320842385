import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-filter-update',
  templateUrl: './filter-update.component.html',
  styleUrls: ['./filter-update.component.scss'],
})
export class FilterUpdateComponent implements OnChanges {
  private autoApplyCount: number = 1;
  private autoAppliedCount: number = 0;

  ngOnChanges(changes: SimpleChanges): void {
    if (
      this.autoApply &&
      !_.get(changes, 'invalid.currentValue', true) &&
      this.autoApplyCount !== this.autoAppliedCount
    ) {
      this.autoAppliedCount += 1;
      this.onUpdateClicked();
    }
  }

  @Input() invalid: boolean = false;
  @Input() enableSetAsDefault: boolean = false;
  @Input() message: string = this.translate.instant('filterUpdate.filterUpdateText', {
    object: this.translate.instant('filterUpdate.objectTypes.table'),
  });
  @Input() autoApply: boolean = false;
  @Input() buttonText: string = this.translate.instant('filterUpdate.update');
  @Input() setAsDefaultButtonText: string = this.translate.instant('filterUpdate.setAsDefault');
  @Output() clickedUpdateButtonEvent = new EventEmitter();
  @Output() clickedSetAsDefaultButtonEvent = new EventEmitter();

  constructor(private translate: TranslateService) {}

  public onUpdateClicked(): void {
    this.clickedUpdateButtonEvent.emit();
  }

  public onSetAsDefaultClicked(): void {
    if (!this.enableSetAsDefault) {
      return;
    }
    this.clickedSetAsDefaultButtonEvent.emit();
  }
}
