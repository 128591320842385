import { Action } from '@ngrx/store';

export enum ActivityButtonActionTypes {
  DigitalFormsTokenLoad = '[DIGITAL FORM] DIGITAL FORMS TOKEN LOAD',
  DigitalFormsTokenLoaded = '[DIGITAL FORM] DIGITAL FORMS TOKEN LOADED',
  FetchError = '[DIGITAL FORMS] Fetch Error',
}

export class DigitalFormsTokenLoad implements Action {
  readonly type = ActivityButtonActionTypes.DigitalFormsTokenLoad;

  constructor(public lineId: number) {}
}

export class DigitalFormsTokenLoaded implements Action {
  readonly type = ActivityButtonActionTypes.DigitalFormsTokenLoaded;

  constructor(public token: string) {}
}

export class FetchError implements Action {
  readonly type = ActivityButtonActionTypes.FetchError;

  constructor(public payload: any) {}
}

export type DigitalFormActions = DigitalFormsTokenLoad | DigitalFormsTokenLoaded | FetchError;
