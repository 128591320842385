import { EFilterDropdownElements, IDependPropertyValue } from './filter.model';
import { Action } from '@ngrx/store';

export enum FilterActionTypes {
  SetFilter = '[Filter] Set Filter',
  ClearFilter = '[Filter] Clear Filter',
}

export class SetFilter implements Action {
  readonly type = FilterActionTypes.SetFilter;

  constructor(public elementId: EFilterDropdownElements, public value: IDependPropertyValue) {}
}

export class ClearFilter implements Action {
  readonly type = FilterActionTypes.ClearFilter;
}

export type FilterActions = SetFilter | ClearFilter;
