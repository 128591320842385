import { Action } from '@ngrx/store';
import { ExcelColumnDefinitionInterface } from '../../shared/service/excel/excel-helper.service';
import { ActivityLogsQueryParams } from '../../view/reports/activity-logs/activity-logs.model';
import { IActivityLog } from '../reports/root-cause-analysis/root-cause-analysis.model';

export const ACTIVITY_LOGS_TABLE_DOWNLOAD_EXCEL = '[ACTIVITY LOGS TABLE] ACTIVITY LOGS TABLE DOWNLOAD EXCEL';
export const ACTIVITY_LOGS_TABLE_DOWNLOAD_EXCEL_COMPLETED =
  '[ACTIVITY LOGS TABLE] ACTIVITY LOGS TABLE DOWNLOAD EXCEL COMPLETED';
export const FETCH_DATA_ERROR = '[ACTIVITY LOGS TABLE] FETCH DATA ERROR';

export class ActivityLogsTableDownloadExcel implements Action {
  readonly type = ACTIVITY_LOGS_TABLE_DOWNLOAD_EXCEL;

  constructor(
    public params: ActivityLogsQueryParams,
    public worksheetsColumnDefinitions: ExcelColumnDefinitionInterface[][],
    public data: IActivityLog[],
  ) {}
}

export class ActivityLogsTableDownloadExcelCompleted implements Action {
  readonly type = ACTIVITY_LOGS_TABLE_DOWNLOAD_EXCEL_COMPLETED;
}

export class FetchDataError implements Action {
  readonly type = FETCH_DATA_ERROR;

  constructor(public payload: object) {}
}

export type ActivityLogsTableActions =
  | ActivityLogsTableDownloadExcel
  | ActivityLogsTableDownloadExcelCompleted
  | FetchDataError;
