import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FilterComponentInterface, SimpleOutputOptionsInterface } from '../filter.class';
import { DateRangeDropsTypes, DateRangeOpensTypes } from '../../date-range-picker/date-range-picker.model';
import { TranslateService } from '@ngx-translate/core';
import { FilterDateRangePickerInterface } from './filter-date-range-picker.model';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { ScwMatDatepickerComponent } from '../../scw-mat-ui/scw-mat-datepicker/scw-mat-datepicker.component';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './filter-date-range-picker.component.html',
  styleUrls: [],
})
export class FilterDateRangePickerComponent implements OnInit, OnDestroy, FilterComponentInterface {
  outputOptions: SimpleOutputOptionsInterface;
  outputSubject: Subject<any>;
  @ViewChild('filterDateRangePickerContainer', { read: ViewContainerRef, static: true })
  container: ViewContainerRef;
  @Input() defaultSettings: FilterDateRangePickerInterface;
  public disabledSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  elementID: string;
  public defaultPickerSettings: FilterDateRangePickerInterface = {
    alwaysShowCalendars: true,
    autoApply: false,
    className: '',
    wide: true,
    closeOnAutoApply: false,
    customRangeDirection: false,
    disabled: false,
    isRequired: false,
    drops: DateRangeDropsTypes.down,
    emptyWeekRowClass: '',
    firstDayOfNextMonthClass: '',
    firstMonthDayClass: '',
    isCustomDate: () => {
      return false;
    },
    isInvalidDate: () => {
      return false;
    },
    keepCalendarOpeningWithRange: true,
    lastDayOfPreviousMonthClass: '',
    lastMonthDayClass: '',
    lockStartDate: false,
    maxDate: null,
    minDate: null,
    opens: DateRangeOpensTypes.right,
    placeholder: this.translate.instant('dateRangePicker.placeholder'),
    readonly: true,
    showCancel: true,
    showClearButton: true,
    showCustomRangeLabel: true,
    showISOWeekNumbers: true,
    showRangeLabelOnInput: false,
    showWeekNumbers: false,
    singleDatePicker: false,
    timePicker: false,
    timePickerIncrement: 1,
    timePickerSeconds: false,
    dateLimit: null,
  };
  private datePickerComponent: ScwMatDatepickerComponent;
  private selectedDates;
  private disabledSubjectSubscription: Subscription;
  public isDisabled: boolean = false;
  public dependedOptionConfiguration = [];
  private element: ComponentRef<ScwMatDatepickerComponent>;
  private componentFactory: ComponentFactory<ScwMatDatepickerComponent>;

  constructor(private translate: TranslateService, private componentFactoryResolver: ComponentFactoryResolver) {}
  ngOnInit(): void {
    this.defaultPickerSettings = {
      ...this.defaultSettings,
    };
    this.componentFactory = this.componentFactoryResolver.resolveComponentFactory(ScwMatDatepickerComponent);
    this.createComponent();
    this.disabledSubjectSubscription = this.disabledSubject.asObservable().subscribe((disabledStatus: boolean) => {
      this.isDisabled = disabledStatus;
      this.element.instance['disabled'] = disabledStatus;
    });
    this.element.instance['disabled'] = this.isDisabled;
    this.element.instance['noPadding'] = true;
  }

  private createComponent(): void {
    this.container.clear();
    this.element = this.container.createComponent(this.componentFactory);
    Object.keys(this.defaultPickerSettings).forEach((property) => {
      this.element.instance[property] = this.defaultPickerSettings[property];
    });
    this.datePickerComponent = this.element.instance;
    this.datePickerComponent.inputModelChange.subscribe((data) => {
      this.selectedDates = data;
      this.outputSubject.next(this.getFiltersOutputs());
    });
  }

  public getFiltersOutputs(): any {
    const output = {};
    output[this.outputOptions.filterObjectId] = this.selectedDates;
    this.publishValue(this.selectedDates);
    return output;
  }

  public subscribeDependedOptionListener(configuration: any): void {
    configuration.dependedOptionListener.asObservable().subscribe((configuration) => {
      if (
        this.elementID === configuration?.dependedElementId &&
        configuration?.dependedOption in this.defaultPickerSettings
      ) {
        this.defaultPickerSettings[configuration?.dependedOption] = configuration?.getDependentValue(
          configuration?.value,
        );
        if (configuration?.additionalChanges) {
          this.defaultPickerSettings = {
            ...this.defaultPickerSettings,
            ...configuration?.additionalChanges(this.defaultPickerSettings[configuration?.dependedOption]),
          };
        }
        this.createComponent();
      }
    });
  }

  public publishValue(value): void {
    this.dependedOptionConfiguration.forEach((config) => {
      if (config.submit) {
        config.dependedOptionListener.next({
          ...config,
          value,
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.disabledSubjectSubscription.unsubscribe();
    this.disabledSubject.complete();

    this.dependedOptionConfiguration?.forEach((option) => {
      option.dependedOptionListener?.complete();
    });
  }
}
