import { TabRowInterface } from '../../shared/component/side-config-bar/side-config-bar.model';

export interface UserConfigurationStateInterface {
  userConfigurationDataLoading: boolean;
  userConfigurationDataLoaded: boolean;
  userConfigurationData: IUserConfiguration;
  temporaryMode: boolean;
}

export interface IUserConfigurationResponse {
  configuration: IUserConfiguration | null;
}

export interface IComponentConfiguration {
  name: string;
  value?: ConfigurationValueDataTypes;
  children?: IComponentConfiguration[];
}

export type IUserConfiguration = {
  [key in ComponentNamesForUserConfiguration]?: IComponentConfiguration[];
};

export type ConfigurationValueDataTypes = string | string[] | number | number[] | boolean | TabRowInterface[];

export enum ComponentNamesForUserConfiguration {
  LineView = 'LineViewComponent',
  LineViewWorkOrderSummary = 'LineViewWorkOrderSummaryComponent',
  LineViewWorkOrderSummaryGlobalMode = 'LineViewWorkOrderSummaryGlobalModeComponent',
  AssetLogs = 'AssetLogsComponent',
  LaborLogs = 'LaborLogsComponent',
  ShiftSummary = 'ShiftSummaryComponent',
  ShiftSummaryFilters = 'ShiftSummaryComponentFilters',
  WorkOrderSchedule = 'WorkOrdersComponent',
  WorkOrderScheduleDashboardMode = 'WorkOrdersComponentDashboardMode',
  WorkOrderSummary = 'WorkOrderSummaryComponent',
  WorkOrderSummaryFilters = 'WorkOrderSummaryComponentFilters',
  AssetView = 'AssetViewComponent',
  ProductSettings = 'ProductSettingsComponent',
  TaskSettings = 'TaskSettingsComponent',
  SchedulerScenario = 'SchedulerScenarioComponent',
  UserSettings = 'UserSettingsComponent',
  ActivityLogs = 'ActivityLogsComponent',
  ProductionReviewRegularMode = 'ProductionReviewRegularModeComponent',
  ProductionReviewDashboardMode = 'ProductionReviewDashboardModeComponent',
  KpiViewComponent = 'KpiViewComponent',
  KpiViewComponentDashboardMode = 'KpiViewComponentDashboardMode',
  AlertSettings = 'AlertSettingsComponent',
  GanttViewSettings = 'GanttViewSettingsComponent',
  GanttDashboardViewSettings = 'GanttDashboardViewSettingsComponent',
  GlobalView = 'GlobalViewComponent',
  LaborSchedulerSettings = 'LaborSchedulerSettingsComponent',
  ActivityView = 'ActivityViewComponent',
}
