import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { ResponseArrayInterface } from '../../../../shared/model/interface/generic-api-response.model';
import { IStation, StationBulkResponseInterface } from './stations.model';
import { DatatableInterface, EColumnWidth } from '../../../../shared/service/datatable/datatable.model';
import { TranslateService } from '@ngx-translate/core';
import { DatatableHeaderInterface } from '../../../../shared/component/datatable/datatable.model';
import { FilterCardOptionInterface } from '../../../../shared/component/filter/filter.class';
import { DropdownComponent } from '../../../../shared/component/filter/dropdown/dropdown.component';
import { Line, Site } from '../../../../shared/component/filter/filterable-objects.class';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../../shared/model/interface/crud-response-interface.model';
import { EFilterDropdownElements } from '../../../filter/filter.model';

@Injectable({
  providedIn: 'root',
})
export class StationsService {
  private routes = {
    lineStation: '/line-stations',
    bulkEdit: '/line-stations/bulk/edit',
    bulkDelete: '/line-stations/bulk/delete',
  };

  public tableQuery: DatatableInterface = {
    page: 1,
    pageSize: 10,
    orderBy: 'id',
    orderDesc: true,
  };

  public getTableHeaders(translate: TranslateService): DatatableHeaderInterface[] {
    return [
      {
        value: null,
        name: '',
        sortable: false,
        width: EColumnWidth.checkbox,
      },
      {
        value: 'siteName',
        name: translate.instant('general.dataTable.header.site'),
        sortable: false,
        selected: true,
      },
      {
        value: 'lineName',
        name: translate.instant('general.dataTable.header.lineName'),
        selected: true,
      },
      {
        value: 'name',
        name: translate.instant('general.dataTable.header.stationName'),
        selected: true,
      },
    ];
  }

  public getFilterOptions(translate: TranslateService): FilterCardOptionInterface {
    return {
      rows: [
        [
          {
            elementId: EFilterDropdownElements.siteMultiSelectDropdown,
            type: DropdownComponent,
            cls: 'col-md-3 col-sm-4',
            object: Site,
            outputOptions: {
              filterObjectId: 'siteIds',
              filterObjectProp: 'id',
              returnFilterObjectAllProp: false,
            },
            options: {
              singleSelection: false,
              badgeShowLimit: 1,
              text: translate.instant('filterCard.site.dropdownPlaceHolder'),
              isRequired: false,
              getInitData: true,
            },
          },
          {
            elementId: EFilterDropdownElements.lineMultiSelectDropdown,
            type: DropdownComponent,
            cls: 'col-md-3 col-sm-4',
            object: Line,
            outputOptions: {
              filterObjectId: 'lineIds',
              filterObjectProp: 'id',
              returnFilterObjectAllProp: false,
            },
            options: {
              text: translate.instant('filterCard.line.dropdownPlaceHolder'),
              noDataLabel: translate.instant('filterCard.dropdown.labelForSiteDepends'),
              isRequired: false,
              getInitData: true,
            },
          },
        ],
      ],
    };
  }

  constructor(public http: HttpClient, @Inject('API_BASE_URL') private api: string) {}

  public getStations(params: HttpParams): Observable<ResponseArrayInterface<IStation>> {
    return this.http.get<ResponseArrayInterface<IStation>>(`${this.api}${this.routes.lineStation}`, { params });
  }

  public addStation(station: IStation): Observable<BaseOneResponseInterface<IStation>> {
    return this.http.post<BaseOneResponseInterface<IStation>>(`${this.api}${this.routes.lineStation}`, station);
  }

  public editStations(station: IStation, stationId: number): Observable<BaseOneResponseInterface<IStation>> {
    return this.http.patch<BaseOneResponseInterface<IStation>>(
      `${this.api}${this.routes.lineStation}/${stationId}`,
      station,
    );
  }

  public bulkEditStations(
    payload: Partial<IStation>[],
    params?: HttpParams,
  ): Observable<GetManyResponseInterface<StationBulkResponseInterface>> {
    return this.http.patch<GetManyResponseInterface<StationBulkResponseInterface>>(
      `${this.api}${this.routes.bulkEdit}`,
      { lineStations: payload },
      { params },
    );
  }

  public deleteStation(stationId: number): Observable<BulkResponseDataInterface> {
    return this.http.delete<BulkResponseDataInterface>(`${this.api}${this.routes.lineStation}/${stationId}`);
  }

  public bulkDeleteStations(stationId: number[]): Observable<BulkResponseDataInterface> {
    return this.http.delete<BulkResponseDataInterface>(`${this.api}${this.routes.bulkDelete}`, {
      body: { lineStations: stationId },
    });
  }
}
