import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as ObjectActions from './work-order.actions';
import { WorkOrderCRUDInterface } from '../../../shared/component/filter/filter.class';
import { generateHttpOption } from '../../../shared/helper/app-helper';
import { WorkOrderService } from '../../../shared/service/filter/work-order.service';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';

@Injectable()
export class FilterWorkOrderEffects {
  loadFilterWorkOrders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.FilterWorkOrderActionTypes.StartLoadFilterWorkOrders),
      switchMap((objectData: ObjectActions.StartLoadFilterWorkOrders) => {
        const params = generateHttpOption(objectData.options);
        return this.workOrderService.getData(params).pipe(
          switchMap((response: GetManyResponseInterface<WorkOrderCRUDInterface>) => {
            return of(new ObjectActions.FilterWorkOrdersLoaded(response.data));
          }),
          catchError(() => {
            return of(new ObjectActions.FilterWorkOrdersLoaded([]));
          }),
        );
      }),
      catchError(() => {
        return of(new ObjectActions.FilterWorkOrdersLoaded([]));
      }),
    ),
  );

  constructor(
    private actions$: Actions<ObjectActions.FilterWorkOrderActions>,
    public workOrderService: WorkOrderService,
  ) {}
}
