import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import * as ObjectActions from './activity-logs-table.actions';
import { of } from 'rxjs';
import * as AppActions from '../app/actions';
import { ActivityLogsService } from '../reports/activity-logs/activity-logs.service';
import { emptyAction } from '../../../constants';

@Injectable()
export class ActivityLogsTableEffects {
  constructor(private readonly actions$: Actions, public activityLogService: ActivityLogsService) {}

  downloadActivityLogsTableExcel = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ACTIVITY_LOGS_TABLE_DOWNLOAD_EXCEL),
      switchMap((objectData: ObjectActions.ActivityLogsTableDownloadExcel) => {
        this.activityLogService.downloadExcel(
          objectData.params,
          objectData.worksheetsColumnDefinitions,
          false,
          objectData.data,
        );
        return emptyAction;
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchDataError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );
}
