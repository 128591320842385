import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SiteService } from '../../../../shared/service/filter/site.service';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../../oee.reducer';
import * as NotificationTemplateActions from './notification-templates.actions';
import { catchError, switchMap } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { of } from 'rxjs';
import * as AppActions from '../../../app/actions';
import {
  BaseCrudResponse,
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../../shared/model/interface/crud-response-interface.model';
import {
  IAddEditNotificationTemplate,
  INotificationTemplateCards,
  INotificationTemplate,
} from './notification-templates.model';
import { NotificationTemplateService } from './notification-template.service';
import { ErrorMessageService } from '../../../../shared/service/error-message.service';
import { ExcelHelperService } from '../../../../shared/service/excel/excel-helper.service';
import { HelperService } from '../../../../shared/service/helper.service';

@Injectable()
export class NotificationTemplatesEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly siteService: SiteService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly notificationTemplateService: NotificationTemplateService,
    private readonly errorMessageService: ErrorMessageService,
    private readonly excelHelperService: ExcelHelperService,
    private readonly helperService: HelperService,
  ) {}

  getSiteData = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationTemplateActions.NOTIFICATION_TEMPLATE_SITES_DATA_LOADING),
      switchMap(() => {
        const httpParams: HttpParams = new HttpParams().set('limit', '1000');

        return this.siteService.getSites(httpParams).pipe(
          switchMap((response) => {
            return of(new NotificationTemplateActions.NotificationTemplateSiteDataLoaded(response.data));
          }),
          catchError((errorRes) => {
            return of(new NotificationTemplateActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new NotificationTemplateActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getNotificationTemplateData = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationTemplateActions.NOTIFICATION_TEMPLATE_LIST_DATA_LOADING),
      switchMap((payloadReadOnly: NotificationTemplateActions.NotificationTemplateListDataLoading) => {
        const payload = HelperService.cloneDeep(payloadReadOnly);
        payload.params.join = ['site||name'];

        return this.notificationTemplateService
          .getNotificationTemplates(this.helperService.insertGenericCrudRequestParameters(payload.params))
          .pipe(
            switchMap((response: GetManyResponseInterface<INotificationTemplate>) => {
              return of(new NotificationTemplateActions.NotificationTemplateListDataLoaded(response));
            }),
            catchError((errorRes) => {
              return of(new NotificationTemplateActions.FetchError(errorRes));
            }),
          );
      }),
      catchError((errorRes) => {
        return of(new NotificationTemplateActions.FetchError(errorRes));
      }),
    ),
  );

  getTemplateCards = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationTemplateActions.NOTIFICATION_TEMPLATES_CARDS_LOADING),
      switchMap((payload) => {
        return this.notificationTemplateService.getNotificationTemplateCards().pipe(
          switchMap((response: INotificationTemplateCards) => {
            return of(new NotificationTemplateActions.NotificationTemplateCardsLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new NotificationTemplateActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new NotificationTemplateActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  editNotificationTemplate = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationTemplateActions.EDIT_NOTIFICATION_TEMPLATE_LOADING),
      switchMap((objectData: NotificationTemplateActions.EditNotificationTemplate) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.notificationTemplateService
          .editNotificationTemplate(objectData.notificationTemplate, objectData.id)
          .pipe(
            switchMap((response: BaseOneResponseInterface<IAddEditNotificationTemplate>) => {
              return of(
                new NotificationTemplateActions.EditNotificationTemplateCompleted(response),
                new AppActions.HideLoader(),
              );
            }),
            catchError((error) => {
              return of(new NotificationTemplateActions.FetchError(error), new AppActions.HideLoader());
            }),
          );
      }),
      catchError((errorRes) => {
        return of(new NotificationTemplateActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  addNotificationTemplate = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationTemplateActions.ADD_NOTIFICATION_TEMPLATE),
      switchMap((objectData: NotificationTemplateActions.AddNotificationTemplate) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.notificationTemplateService.addNotificationTemplate(objectData.notificationTemplate).pipe(
          switchMap((response: BaseOneResponseInterface<IAddEditNotificationTemplate>) => {
            return of(
              new NotificationTemplateActions.AddNotificationTemplateCompleted(response),
              new AppActions.HideLoader(),
            );
          }),
          catchError((error) => {
            return of(new NotificationTemplateActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new NotificationTemplateActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  deleteNotificationTemplates = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationTemplateActions.DELETE_NOTIFICATION_TEMPLATES),
      switchMap((objectData: NotificationTemplateActions.DeleteNotificationTemplates) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.notificationTemplateService.deleteNotificationTemplates(objectData.payload).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            this.errorMessageService.getTranslatedErrorMessage(response.data);
            const mergedArray = this.excelHelperService.mergeBulkResponseWithRequestData(
              response,
              objectData.payload.map((item) => {
                return { id: item };
              }),
              true,
            );
            return of(
              new NotificationTemplateActions.DeleteNotificationTemplatesCompleted(mergedArray),
              new AppActions.HideLoader(),
            );
          }),
          catchError((error) => {
            return of(new NotificationTemplateActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new NotificationTemplateActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  deleteNotificationTemplate = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationTemplateActions.DELETE_NOTIFICATION_TEMPLATE),
      switchMap((objectData: NotificationTemplateActions.DeleteNotificationTemplate) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.notificationTemplateService.deleteNotificationTemplate(objectData.payload).pipe(
          switchMap((response: BaseCrudResponse) => {
            return of(
              new NotificationTemplateActions.DeleteNotificationTemplateCompleted(response),
              new AppActions.HideLoader(),
            );
          }),
          catchError((error) => {
            return of(new NotificationTemplateActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new NotificationTemplateActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );
}
