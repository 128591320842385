import {
  SchedulerResourceSettingActions,
  SchedulerResourceSettingActionTypes,
} from './scheduler-resource-setting.actions';
import {
  SchedulerResourceSettingsStateInterface,
} from './scheduler-resource-setting.model';

export const initialState: SchedulerResourceSettingsStateInterface = {
  selectedPreset: null,
  presets: [],
  presetsLoaded: false,
  presetsLoading: false,
  lines: [],
  sites: [],
  sitesLinesLoaded: false,
  sitesLinesLoading: false,
  schedulerShiftPlans: [],
  schedulerShiftPlansLoading: false,
  schedulerShiftPlansLoaded: false,
  presetLoading: false,
  presetLoaded: false,
  activities: [],
  activitiesLoading: false,
  activitiesLoaded: false,
  deletePresetLoading: false,
  deletePresetLoaded: false,
  createPresetLoading: false,
  createPresetLoaded: false,
  updatePresetLoading: false,
  updatePresetLoaded: false,
  updatePresetItemsLoading: false,
  updatePresetItemsLoaded: false,
  createPresetItemsLoading: false,
  createPresetItemsLoaded: false,
  updatePresetLinesLoading: false,
  updatePresetLinesLoaded: false,
  createPresetLinesLoading: false,
  createPresetLinesLoaded: false,
  deletePresetLinesLoading: false,
  deletePresetLinesLoaded: false,
};

export function schedulerResourceSettingsReducer(
  state: SchedulerResourceSettingsStateInterface = initialState,
  action: SchedulerResourceSettingActions,
): SchedulerResourceSettingsStateInterface {

  switch (action.type) {
    case SchedulerResourceSettingActionTypes.LoadSitesAndLines:
      return {
        ...state,
        sitesLinesLoading: true,
        sitesLinesLoaded: false,
      };

    case SchedulerResourceSettingActionTypes.SitesAndLinesLoaded:
      return {
        ...state,
        sitesLinesLoading: false,
        sitesLinesLoaded: true,
        sites: action.payload.sites,
        lines: action.payload.lines,
      };

    case SchedulerResourceSettingActionTypes.LoadPresets:
      return {
        ...state,
        presetsLoading: true,
        presetsLoaded: false,
      };

    case SchedulerResourceSettingActionTypes.PresetsLoaded:
      return {
        ...state,
        ...{
          presets: action.payload,
        },
        presetsLoading: false,
        presetsLoaded: true,
      };

    case SchedulerResourceSettingActionTypes.SetSelectedPreset:
      return {
        ...state,
        selectedPreset: action.payload,
      };

    case SchedulerResourceSettingActionTypes.LoadSchedulerResourceSettingShiftPlans:
      return {
        ...state,
        schedulerShiftPlansLoading: true,
        schedulerShiftPlansLoaded: false,
      };

    case SchedulerResourceSettingActionTypes.SchedulerResourceSettingShiftPlansLoaded:
      return {
        ...state,
        schedulerShiftPlansLoading: false,
        schedulerShiftPlansLoaded: true,
        schedulerShiftPlans: action.payload,
      };

    case SchedulerResourceSettingActionTypes.GetSelectedPreset:
      return {
        ...state,
        ...{
          selectedPreset: null,
        },
        presetLoading: true,
        presetLoaded: false,
      };

    case SchedulerResourceSettingActionTypes.GetSelectedPresetLoaded:
      return {
        ...state,
        ...{
          selectedPreset: action.payload,
        },
        presetLoading: false,
        presetLoaded: true,
      };

    case SchedulerResourceSettingActionTypes.LoadActivities:
      return {
        ...state,
        activitiesLoading: true,
        activitiesLoaded: false,
      };

    case SchedulerResourceSettingActionTypes.ActivitiesLoaded:
      return {
        ...state,
        activitiesLoading: false,
        activitiesLoaded: true,
        activities: action.payload,
      };

    case SchedulerResourceSettingActionTypes.DeleteSelectedPreset:
      return {
        ...state,
        deletePresetLoading: true,
        deletePresetLoaded: false,
      };

    case SchedulerResourceSettingActionTypes.DeleteSelectedPresetLoaded:
      return {
        ...state,
        ...{
          selectedPreset: null,
          presetLoaded: false,
        },
        deletePresetLoading: false,
        deletePresetLoaded: true,
      };

    case SchedulerResourceSettingActionTypes.CreatePreset:
      return {
        ...state,
        createPresetLoading: true,
        createPresetLoaded: false,
      };

    case SchedulerResourceSettingActionTypes.CreatePresetLoaded:
      return {
        ...state,
        ...{
          selectedPreset: action.payload,
        },
        createPresetLoading: false,
        createPresetLoaded: true,
      };

    case SchedulerResourceSettingActionTypes.UpdatePreset:
      return {
        ...state,
        updatePresetLoading: true,
        updatePresetLoaded: false,
      };

    case SchedulerResourceSettingActionTypes.UpdatePresetLoaded:
      return {
        ...state,
        ...{
          selectedPreset: action.payload,
        },
        updatePresetLoading: false,
        updatePresetLoaded: true,
      };

    case SchedulerResourceSettingActionTypes.CreatePresetItems:
      return {
        ...state,
        createPresetItemsLoading: true,
        createPresetItemsLoaded: false,
      };

    case SchedulerResourceSettingActionTypes.CreatePresetItemsLoaded:
      return {
        ...state,
        createPresetItemsLoading: false,
        createPresetItemsLoaded: true,
      };

    case SchedulerResourceSettingActionTypes.UpdatePresetItems:
      return {
        ...state,
        updatePresetItemsLoading: true,
        updatePresetItemsLoaded: false,
      };

    case SchedulerResourceSettingActionTypes.UpdatePresetItemsLoaded:
      return {
        ...state,
        updatePresetItemsLoading: false,
        updatePresetItemsLoaded: true,
      };

    case SchedulerResourceSettingActionTypes.CreatePresetLines:
      return {
        ...state,
        createPresetLinesLoading: true,
        createPresetLinesLoaded: false,
      };

    case SchedulerResourceSettingActionTypes.CreatePresetLinesLoaded:
      return {
        ...state,
        createPresetLinesLoading: false,
        createPresetLinesLoaded: true,
      };

    case SchedulerResourceSettingActionTypes.UpdatePresetLines:
      return {
        ...state,
        updatePresetLinesLoading: true,
        updatePresetLinesLoaded: false,
      };

    case SchedulerResourceSettingActionTypes.UpdatePresetLinesLoaded:
      return {
        ...state,
        updatePresetLinesLoading: false,
        updatePresetLinesLoaded: true,
      };

    case SchedulerResourceSettingActionTypes.DeletePresetLines:
      return {
        ...state,
        deletePresetLinesLoading: true,
        deletePresetLinesLoaded: false,
      };

    case SchedulerResourceSettingActionTypes.DeletePresetLinesLoaded:
      return {
        ...state,
        deletePresetLinesLoading: false,
        deletePresetLinesLoaded: true,
      };

    case SchedulerResourceSettingActionTypes.ClearSelectedPreset:
      return {
        ...state,
        presetLoading: false,
        presetLoaded: true,
        selectedPreset: null,
      };

    default:
      return state;
  }
}
