import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import * as ObjectActions from './alert-pause.actions';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../oee.reducer';
import { AlertPauseService } from './alert-pause.service';
import { AlertPauseGetResponseInterface, AlertPauseUpdateResponseInterface } from './alert-pause.model';
import * as AppActions from '../app/actions';

@Injectable()
export class AlertPauseEffects {
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private store: Store<oeeAppReducer.OeeAppState>,
    private service: AlertPauseService,
  ) {}

  startAlertPauseDataLoading = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.AlertPauseActionTypes.StartGetAlertPauseDataLoading),
      exhaustMap((objectData: ObjectActions.StartGetAlertPauseDataLoading) => {
        return this.service.getAlertPause(objectData.parameters.lineId).pipe(
          map((resData: AlertPauseGetResponseInterface) => {
            return new ObjectActions.StartGetAlertPauseDataLoaded(resData);
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  startUpdateAlertPauseDataLoading = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.AlertPauseActionTypes.StartUpdateAlertPauseDataLoading),
      switchMap((objectData: ObjectActions.StartUpdateAlertPauseDataLoading) => {
        return this.service.updateAlertPause(objectData.parameters, objectData.lineId).pipe(
          map((resData: AlertPauseUpdateResponseInterface) => {
            return new ObjectActions.StartUpdateAlertPauseDataLoaded(resData);
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes));
          }),
        );
      }),
    ),
  );
}
