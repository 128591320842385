import { FilterActivitiesActions, FilterActivitiesActionTypes } from './activity.actions';
import { AlteredActivityCRUDInterface } from '../../../shared/component/filter/filter.class';

export interface FilterActivityState {
  isLoading: boolean;
  isLoaded: boolean;
  data: AlteredActivityCRUDInterface[];
}

export const initialFilterActivityState: FilterActivityState = {
  isLoaded: false,
  isLoading: false,
  data: [],
};

export function filterActivityReducer(
  state: FilterActivityState = initialFilterActivityState,
  action: FilterActivitiesActions,
): FilterActivityState {
  switch (action.type) {
    case FilterActivitiesActionTypes.StartLoadFilterActivities:
      return { ...state, isLoading: true };
    case FilterActivitiesActionTypes.FilterActivitiesLoaded:
      return { ...state, data: action.data, isLoaded: true, isLoading: false };
    case FilterActivitiesActionTypes.ResetActivitiesStore:
      return { ...state, data: [], isLoaded: false, isLoading: false };
    default:
      return state;
  }
}
