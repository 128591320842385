import { Action } from '@ngrx/store';
import { IWeavyJWT } from './weavy.model';

export enum WeavyActionTypes {
  JWT_LOADING = '[WEAVY] JWT DATA LOADING',
  JWT_LOADED = '[WEAVY] JWT DATA LOADED',
  INITIALIZE_WEAVY = '[WEAVY] START WEAVY INITIALIZATION',
  WEAVY_INITIALIZED = '[WEAVY] WEAVY INITIALIZED',
  RESET_WEAVY_STATE = '[WEAVY] RESET WEAVY STATE',
  WEAVY_ERROR = '[WEAVY] WEAVY ERROR',
  FETCH_ERROR = '[WEAVY] FETCH ERROR',
}

export class JwtLoading implements Action {
  readonly type = WeavyActionTypes.JWT_LOADING;

  constructor(public username: string, public client: string) {}
}

export class JwtLoaded implements Action {
  readonly type = WeavyActionTypes.JWT_LOADED;

  constructor(public payload: IWeavyJWT) {}
}

export class WeavyInitialized implements Action {
  readonly type = WeavyActionTypes.WEAVY_INITIALIZED;
}

export class ResetWeavyState implements Action {
  readonly type = WeavyActionTypes.RESET_WEAVY_STATE;
}

export class WeavyError implements Action {
  readonly type = WeavyActionTypes.WEAVY_ERROR;
}

export class FetchError implements Action {
  readonly type = WeavyActionTypes.FETCH_ERROR;

  constructor(public payload: any) {}
}

export type WeavyActions =
  | JwtLoading
  | JwtLoaded
  | WeavyInitialized
  | ResetWeavyState
  | WeavyError
  | FetchError;
