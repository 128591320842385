import { Action } from '@ngrx/store';
import { RootCauseTypeCRUDInterface } from '../../../shared/component/filter/filter.class';
import { HttpOptionInterface } from '../filter.model';
import { ApiServer } from '../../../shared/component/filter/dropdown/dropdown.model';

export enum FilterRootCauseTypeActionType {
  StartLoadFilterRootCauseTypes = '[Filter RootCauseType] Load Filter Root Cause Types',
  FilterRootCauseTypesLoaded = '[Filter RootCauseType] Filter Root Cause Types Loaded',
}

export class StartLoadFilterRootCauseTypes implements Action {
  readonly type = FilterRootCauseTypeActionType.StartLoadFilterRootCauseTypes;
  constructor(public options: HttpOptionInterface, public server: ApiServer = ApiServer.NestJS) {}
}
export class FilterRootCauseTypesLoaded implements Action {
  readonly type = FilterRootCauseTypeActionType.FilterRootCauseTypesLoaded;
  constructor(public data: RootCauseTypeCRUDInterface[]) {}
}

export type FilterRootCauseTypeAction = StartLoadFilterRootCauseTypes | FilterRootCauseTypesLoaded;
