import {
  Component,
  OnDestroy,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  AfterViewInit,
  OnInit,
} from '@angular/core';
import { WeavyService } from '../../service/weavy/weavy.service';
import { IWeavyState } from '../../../store/weavy/weavy.model';
import * as WeavyActions from '../../../store/weavy/weavy.actions';
import { ActionsSubject, Store } from '@ngrx/store';
import * as oeeAppReducer from '../../../store/oee.reducer';
import { Subscription } from 'rxjs';
import { User } from '../../../store/user/model';
import { ofType } from '@ngrx/effects';
import { WeavySpaces } from 'src/constants';
import { IWeavySpaceInformation } from './weavy.interface';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
import { HelperService } from '../../service/helper.service';

@Component({
  selector: 'app-weavy',
  template: '<div #weavyContainer class="weavy-container"></div>',
  styleUrls: ['./weavy.component.scss'],
})
export class WeavyComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() appType: string = 'messenger';
  @Input() appKey: string = 'angular-chat';
  @Input() appName: string = 'Angular Chat';
  @Output() unreadMessageCount: EventEmitter<number> = new EventEmitter<number>();
  @Output() weavyHasError: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('weavyContainer') weavyContainer!: ElementRef;

  private weavy: any;
  private weavySpace: any;
  private weavyApp: any;
  private readonly storeSubscriptions: Subscription[] = [];
  private readonly defaultWeavySpaceInformation: IWeavySpaceInformation = {
    spaceName: 'SCW Global Space',
    key: 'scw-global',
  };
  private readonly weavySpaceInformation: IWeavySpaceInformation = _.get(
    WeavySpaces,
    environment.ssoEnv,
    this.defaultWeavySpaceInformation,
  );
  private currentUserUsername$: string;
  private currentUserClient$: string;

  constructor(
    private weavyService: WeavyService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private storeActions: ActionsSubject,
  ) {}

  public ngOnInit(): void {
    this.storeSubscriptions.push(
      this.store.select('user').subscribe((state: User) => {
        if (!state.isUserLoading && state.isUserLoaded) {
          this.currentUserUsername$ = state.username;
          this.currentUserClient$ = state.clientNumber;

          if (!WeavyService.jwtToken) {
            this.store.dispatch(new WeavyActions.JwtLoading(this.currentUserUsername$, this.currentUserClient$));
          }
        }
      }),
      this.store.select('weavyStore').subscribe((state: IWeavyState) => {
        if (!state.jwtIsLoading && state.jwtIsLoaded && !state.weavyIsLoading && !state.weavyIsLoaded) {
          this.weavyService.initializeWeavy(state.jwt);
        }
      }),
    );
  }

  public ngAfterViewInit(): void {
    this.storeSubscriptions.push(
      this.storeActions
        .pipe(ofType(WeavyActions.WeavyActionTypes.WEAVY_INITIALIZED))
        .subscribe(() => {
          this.weavyHasError.emit(false);

          this.weavy = WeavyService.weavy;

          this.weavySpace = this.weavy.space({
            id: this.weavySpaceInformation.id,
            name: this.weavySpaceInformation.spaceName,
          });

          this.weavyApp = this.weavySpace.app({
            type: this.appType,
            key: this.appKey,
            name: this.appName,
            container: this.weavyContainer!.nativeElement,
          });

          this.weavy.on('badge', (e, data) => {
            this.unreadMessageCount.emit(data.conversations);
          });
        }),
      this.storeActions
        .pipe(ofType(WeavyActions.WeavyActionTypes.WEAVY_ERROR))
        .subscribe((payload: WeavyActions.WeavyError) => {
          this.weavyHasError.emit(true);
        }),
    );
  }

  public ngOnDestroy(): void {
    if (this.weavyApp) {
      this.weavyApp.remove();
    }

    this.storeSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });

    this.store.dispatch(new WeavyActions.ResetWeavyState());
  }
}
