import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IEquipmentBrand } from '../../../../store/settings/equipment-brands/equipment-brands.model';
import {
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../model/interface/crud-response-interface.model';

@Injectable({
  providedIn: 'root',
})
export class EquipmentBrandsService {
  private EQUIPMENT_BRANDS_BASE_URL: string = 'equipment-brands';
  private EQUIPMENT_BRANDS = {
    GET: {
      GET_ALL_EQUIPMENT_BRANDS: `${this.baseUrl}/${this.EQUIPMENT_BRANDS_BASE_URL}`,
    },
    DELETE: {
      DELETE_EQUIPMENT_BRAND: `${this.baseUrl}/${this.EQUIPMENT_BRANDS_BASE_URL}`,
    },
    POST: {
      CREATE_EQUIPMENT_BRAND: `${this.baseUrl}/${this.EQUIPMENT_BRANDS_BASE_URL}`,
    },
    PATCH: {
      EDIT_EQUIPMENT_BRAND: `${this.baseUrl}/${this.EQUIPMENT_BRANDS_BASE_URL}`,
    },
    BULK: {
      DELETE: `${this.baseUrl}/${this.EQUIPMENT_BRANDS_BASE_URL}/bulk/delete`,
      SAVE: `${this.baseUrl}/${this.EQUIPMENT_BRANDS_BASE_URL}/bulk/save`,
      EDIT: `${this.baseUrl}/${this.EQUIPMENT_BRANDS_BASE_URL}/bulk/edit`,
    },
  };

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL')
    private readonly baseUrl: string,
  ) {}

  public getEquipmentBrands(options?: HttpParams): Observable<GetManyResponseInterface<IEquipmentBrand>> {
    return this.http.get<GetManyResponseInterface<IEquipmentBrand>>(
      this.EQUIPMENT_BRANDS.GET.GET_ALL_EQUIPMENT_BRANDS,
      {
        params: options,
      },
    );
  }

  public patchEquipmentBrandData(
    id: number,
    payload: Partial<IEquipmentBrand>,
    params?: HttpParams,
  ): Observable<GetManyResponseInterface<IEquipmentBrand>> {
    return this.http.patch<GetManyResponseInterface<IEquipmentBrand>>(
      `${this.EQUIPMENT_BRANDS.PATCH.EDIT_EQUIPMENT_BRAND}/${id}`,
      payload,
      { params },
    );
  }

  public patchEquipmentBrandsData(
    payload: Partial<IEquipmentBrand>[],
    params?: HttpParams,
  ): Observable<BulkResponseDataInterface> {
    return this.http.patch<BulkResponseDataInterface>(
      this.EQUIPMENT_BRANDS.BULK.EDIT,
      { equipmentBrands: payload },
      { params },
    );
  }

  public createEquipmentBrand(
    payload: Partial<IEquipmentBrand>,
  ): Observable<GetManyResponseInterface<IEquipmentBrand>> {
    return this.http.post<GetManyResponseInterface<IEquipmentBrand>>(
      this.EQUIPMENT_BRANDS.POST.CREATE_EQUIPMENT_BRAND,
      payload,
    );
  }

  public deleteEquipmentBrand(id: number): Observable<GetManyResponseInterface<IEquipmentBrand>> {
    return this.http.delete<GetManyResponseInterface<IEquipmentBrand>>(
      `${this.EQUIPMENT_BRANDS.DELETE.DELETE_EQUIPMENT_BRAND}/${id}`,
    );
  }

  public deleteEquipmentBrands(ids: number[]): Observable<BulkResponseDataInterface> {
    return this.http.delete<BulkResponseDataInterface>(this.EQUIPMENT_BRANDS.BULK.DELETE, {
      body: { equipmentBrands: ids },
    });
  }
}
