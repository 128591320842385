import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  BaseCrudResponse,
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../../shared/model/interface/crud-response-interface.model';
import {
  IAddEditNotificationTemplate,
  INotificationTemplateCards,
  INotificationTemplate,
} from './notification-templates.model';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NotificationTemplateService {
  private readonly routes = {
    notificationTemplates: `${this.baseUrl}/notifications/email-templates`,
    notificationTemplateCards: `${environment.customReportsUrl}/email-reports/cards`,
    bulkDelete: `${this.baseUrl}/notifications/email-templates/bulk/delete`,
  };

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
  ) {}

  public getNotificationTemplates(
    httpParams: HttpParams,
  ): Observable<GetManyResponseInterface<INotificationTemplate>> {
    return this.http.get<GetManyResponseInterface<INotificationTemplate>>(this.routes.notificationTemplates, {
      params: httpParams,
    });
  }

  public getNotificationTemplateCards(): Observable<INotificationTemplateCards> {
    return this.http.get<INotificationTemplateCards>(this.routes.notificationTemplateCards);
  }

  public editNotificationTemplate(
    notificationTemplate: IAddEditNotificationTemplate,
    id: number,
  ): Observable<BaseOneResponseInterface<IAddEditNotificationTemplate>> {
    return this.http.patch<BaseOneResponseInterface<IAddEditNotificationTemplate>>(
      `${this.routes.notificationTemplates}/${id}`,
      notificationTemplate,
    );
  }

  public addNotificationTemplate(
    notificationTemplate: IAddEditNotificationTemplate,
  ): Observable<BaseOneResponseInterface<IAddEditNotificationTemplate>> {
    return this.http.post<BaseOneResponseInterface<IAddEditNotificationTemplate>>(
      `${this.routes.notificationTemplates}`,
      notificationTemplate,
    );
  }

  public deleteNotificationTemplates(notificationTemplates: number[]): Observable<BulkResponseDataInterface> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: {
        emailTemplates: notificationTemplates,
      },
    };
    return this.http.delete<BulkResponseDataInterface>(`${this.routes.bulkDelete}`, httpOptions);
  }

  public deleteNotificationTemplate(notificationTemplate: number): Observable<BaseCrudResponse> {
    return this.http.delete<BaseCrudResponse>(`${this.routes.notificationTemplates}/${notificationTemplate}`);
  }
}
