import { Action } from '@ngrx/store';
import { GenericCrudRequestConstructionParameters } from '../../../shared/model/interface/generic-api-request.model';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { IMyReport, IMyReportRequestPayload } from '../../../view/reports/my-reports/my-reports.model';

export enum MyReportsActionTypes {
  FetchMyReportsData = '[MyReports] Fetch MyReports Data',
  FetchMyReportsDataCompleted = '[MyReports] Fetch MyReports Data Completed',
  CreateNewMyReport = '[MyReports] Create New My Report',
  CreateNewMyReportCompleted = '[MyReports] Create New My Report Completed',
  UpdateMyReport = '[MyReports] Update My Report',
  UpdateMyReportCompleted = '[MyReports] Update My Report Completed',
  DeleteMyReport = '[MyReports] Delete My Report',
  DeleteMyReportCompleted = '[MyReports] Delete My Report Completed',
  CloneMyReport = '[MyReports] Clone My Report',
  CloneMyReportCompleted = '[MyReports] Clone My Report Completed',
  BulkDeleteMyReport = '[MyReports] Bulk Delete My Reports',
  BulkDeleteMyReportCompleted = '[MyReports] Bulk Delete My Reports Completed',
  FetchError = '[MyReports] Fetch Error',
}

export class FetchMyReportsData implements Action {
  readonly type = MyReportsActionTypes.FetchMyReportsData;

  constructor(public parameters: GenericCrudRequestConstructionParameters) {}
}

export class FetchMyReportsDataCompleted implements Action {
  readonly type = MyReportsActionTypes.FetchMyReportsDataCompleted;

  constructor(public payload: GetManyResponseInterface<IMyReport>) {}
}

export class CreateNewMyReport implements Action {
  readonly type = MyReportsActionTypes.CreateNewMyReport;

  constructor(public requestPayload: IMyReportRequestPayload) {}
}

export class CreateNewMyReportCompleted implements Action {
  readonly type = MyReportsActionTypes.CreateNewMyReportCompleted;

  constructor(public payload: BaseOneResponseInterface<IMyReport>) {}
}

export class UpdateMyReport implements Action {
  readonly type = MyReportsActionTypes.UpdateMyReport;

  constructor(public id: number, public requestPayload: IMyReportRequestPayload) {}
}

export class UpdateMyReportCompleted implements Action {
  readonly type = MyReportsActionTypes.UpdateMyReportCompleted;

  constructor(public payload: BaseOneResponseInterface<IMyReport>) {}
}

export class DeleteMyReport implements Action {
  readonly type = MyReportsActionTypes.DeleteMyReport;

  constructor(public id: number) {}
}

export class DeleteMyReportCompleted implements Action {
  readonly type = MyReportsActionTypes.DeleteMyReportCompleted;

  constructor(public response: GetManyResponseInterface<IMyReport>) {}
}

export class BulkDeleteMyReport implements Action {
  readonly type = MyReportsActionTypes.BulkDeleteMyReport;

  constructor(public ids: number[]) {}
}

export class BulkDeleteMyReportCompleted implements Action {
  readonly type = MyReportsActionTypes.BulkDeleteMyReportCompleted;

  constructor(public payload: BulkResponseDataInterface) {}
}

export class CloneMyReport implements Action {
  readonly type = MyReportsActionTypes.CloneMyReport;

  constructor(public id: number, public requestPayload: Omit<IMyReportRequestPayload, 'id'>) {}
}

export class CloneMyReportCompleted implements Action {
  readonly type = MyReportsActionTypes.CloneMyReportCompleted;

  constructor(public payload: BaseOneResponseInterface<IMyReport>) {}
}

export class FetchError implements Action {
  readonly type = MyReportsActionTypes.FetchError;

  constructor(public payload: object) {}
}

export type TMyReportsActions =
  | FetchMyReportsData
  | FetchMyReportsDataCompleted
  | CreateNewMyReport
  | CreateNewMyReportCompleted
  | UpdateMyReport
  | UpdateMyReportCompleted
  | DeleteMyReport
  | DeleteMyReportCompleted
  | BulkDeleteMyReport
  | BulkDeleteMyReportCompleted
  | CloneMyReport
  | CloneMyReportCompleted
  | FetchError;
