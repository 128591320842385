<app-maintenance *ngIf="isUnderMaintenance; else routerOutlet"></app-maintenance>

<ng-template #routerOutlet>
  <router-outlet>
    <app-mask></app-mask>
    <app-spinner></app-spinner>
    <page-loader></page-loader>
    <top-loader></top-loader>
  </router-outlet>
</ng-template>

<div class="scw-splash" *ngIf="guardLoading">
  <div class="d-flex flex-column justify-content-center align-items-center">
    <img
      id="splash-icon"
      style="filter: unset !important"
      src="assets/favicon/favicon-96x96.png"
      height="96"
      width="96"
      alt="SCW"
    />
  </div>
</div>
