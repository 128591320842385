import { Action } from '@ngrx/store';
import { ApiServer } from '../../../shared/component/filter/dropdown/dropdown.model';
import { DateRangeFilterInterface } from '../../../view/reports/check-in-logs/check-in-logs.model';
import { IDateShifts } from '../../production-review/production-review.model';

export enum FilterShiftActionTypes {
  StartLoadFilterShifts = '[Filter Shift] Load Filter Shifts',
  FilterShiftsLoaded = '[Filter Shift] Filter Shifts Loaded',
  GetShifts = '[Filter Shift] Get Shifts',
  GotShifts = '[Filter Shift] Got Shifts',
  FetchError = '[Filter Shift] Fetch Error',
}

export class StartLoadFilterShifts implements Action {
  readonly type = FilterShiftActionTypes.StartLoadFilterShifts;
  constructor(
    public server: ApiServer = ApiServer.NestJS,
    public dateRange?: DateRangeFilterInterface,
    public siteIds?: number[],
    public shouldIncludePlanName: boolean = false,
  ) {}
}
export class FilterShiftsLoaded implements Action {
  readonly type = FilterShiftActionTypes.FilterShiftsLoaded;
  constructor(public data) {}
}

export class GetShifts implements Action {
  readonly type = FilterShiftActionTypes.GetShifts;

  constructor(public payload: { selectedDate: string; lineId: number }) {}
}

export class GotShifts implements Action {
  readonly type = FilterShiftActionTypes.GotShifts;

  constructor(public payload: IDateShifts[]) {}
}

export class FetchError implements Action {
  readonly type = FilterShiftActionTypes.FetchError;

  constructor(public payload: object) {}
}

export type FilterShiftActions = StartLoadFilterShifts | FilterShiftsLoaded | GetShifts | GotShifts | FetchError;
