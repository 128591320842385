<div class="card-body">
  <div class="row comparison-filter-card-block">
    <h6>{{ 'general.rootCauseAnalysis.comparisonMode' | translate }}</h6>
    <div class="d-flex align-items-center">
      <p>{{ 'general.rootCauseAnalysis.comparisonFilterInformationMessage' | translate }}</p>
      <scw-mat-select
        class="comparison-dropdown"
        [text]="'reports.rootCauseAnalysis.comparisonMode.itemTypeToCompare' | translate"
        [data]="comparisonFilterCardConfiguration"
        [(inputModel)]="selectedComparisonFilterCard"
        (onDeSelect)="comparisonGroupTypeChanged()"
        (inputModelChange)="comparisonGroupTypeChanged()"
      ></scw-mat-select>
    </div>
  </div>
</div>
