import { FilterActions, FilterActionTypes } from './filter.actions';
import { EFilterDropdownElements, IDependPropertyValue, IDropdownData } from './filter.model';
import { HelperService } from '../../shared/service/helper.service';

export interface IFilterState {
  data: IDropdownData[];
}

export const initialFilterActivityState: IFilterState = {
  data: [],
};

export function filterReducer(state: IFilterState = initialFilterActivityState, action: FilterActions): IFilterState {
  switch (action.type) {
    case FilterActionTypes.SetFilter:
      return { ...state, data: setFilter(...HelperService.argumentClone(action.elementId, action.value, state.data)) };

    case FilterActionTypes.ClearFilter:
      return {
        ...state,
        data: [],
      };
    default:
      return state;
  }
}

function setFilter(
  elementId: EFilterDropdownElements,
  value: IDependPropertyValue,
  stateData: IDropdownData[],
): IDropdownData[] {
  const dropdownFilter: IDropdownData | undefined = stateData.find(
    (item: IDropdownData) => item.elementId === elementId,
  );

  if (!dropdownFilter) {
    stateData.push({
      elementId,
      values: [value],
    });

    return stateData;
  }

  const valuesWithoutRequestProperty: IDependPropertyValue[] = dropdownFilter.values.filter(
    (item: IDependPropertyValue) => item.property !== value.property,
  );

  dropdownFilter.values = valuesWithoutRequestProperty.concat(value);

  return stateData;
}
