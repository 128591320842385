import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../../../shared/model/interface/crud-response-interface.model';
import {
  BulkEditKpiTargetsInterface,
  EditKpiTargetInterface,
  KpiTargetBulkResponse,
  KpiTargetInterface,
} from './kpi-targets.model';
import { Observable, Subscription } from 'rxjs';
import * as _ from 'lodash';
import { HelperService } from '../../../../shared/service/helper.service';
import { IKpiTargetsSiteLineTree } from '../../../../view/settings/kpi-metrics/kpi-targets/kpi-targets.model';
import moment from 'moment';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../../oee.reducer';
import { LineCRUDInterface, SiteCRUDInterface } from '../../../../shared/component/filter/filter.class';
import { FilterLineState } from '../../../filter/line/line.reducer';
import { FilterSiteState } from '../../../filter/site/site.reducer';

@Injectable({
  providedIn: 'root',
})
export class KpiTargetsService {
  private readonly routes = {
    kpiTargets: `${this.baseUrl}/targets`,
    bulkEdit: `${this.baseUrl}/targets/bulk/edit`,
  };

  public lineSubscription: Subscription;
  public siteSubscription: Subscription;

  protected lines: LineCRUDInterface[] = [];
  protected sites: SiteCRUDInterface[] = [];

  constructor(
    public readonly http: HttpClient,
    @Inject('API_BASE_URL')
    private readonly baseUrl: string,
    private readonly helperService: HelperService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
  ) {
    this.lineSubscription = this.store.select('lineFilter').subscribe((state: FilterLineState) => {
      if (state.isLoaded) {
        this.lines = state.data;
      }
    });

    this.siteSubscription = this.store.select('siteFilter').subscribe((state: FilterSiteState) => {
      if (state.isLoaded) {
        this.sites = state.data;
      }
    });
  }

  public getKpiTargets(params: HttpParams): Observable<GetManyResponseInterface<KpiTargetInterface>> {
    return this.http.get<GetManyResponseInterface<KpiTargetInterface>>(this.routes.kpiTargets, {
      params,
    });
  }

  public editKpiTarget(
    lineId: number,
    kpiTargets: EditKpiTargetInterface,
  ): Observable<BaseOneResponseInterface<EditKpiTargetInterface>> {
    return this.http.patch<BaseOneResponseInterface<EditKpiTargetInterface>>(
      `${this.routes.kpiTargets}/${lineId}`,
      kpiTargets,
    );
  }

  public bulkEditKpiTarget(
    targets: BulkEditKpiTargetsInterface[],
  ): Observable<GetManyResponseInterface<KpiTargetBulkResponse>> {
    return this.http.patch<GetManyResponseInterface<KpiTargetBulkResponse>>(this.routes.bulkEdit, { targets });
  }

  private rangeOfYears(start: number, end: number): number[] {
    return Array(end - start + 1)
      .fill(start)
      .map((year: number, index: number) => year + index);
  }

  private getMonths(
    isFirstYear: boolean,
    isLastYear: boolean,
    startDate: moment.Moment,
    endDate: moment.Moment,
  ): number[] {
    const INDEX_OFFSET: 1 = 1;
    const RANGE_OFFSET: 1 = 1;

    if (startDate.year() === endDate.year()) {
      return _.range(startDate.month() + INDEX_OFFSET, endDate.month() + INDEX_OFFSET + RANGE_OFFSET) as number[];
    }

    if (isFirstYear) {
      return _.range(startDate.month() + INDEX_OFFSET, 12 + RANGE_OFFSET) as number[];
    }

    if (isLastYear) {
      return _.range(1, endDate.month() + INDEX_OFFSET + RANGE_OFFSET) as number[];
    }

    return Array.from({ length: 12 }, (_, i) => i + INDEX_OFFSET);
  }

  public generateMissingTargetsByYears(
    targets: KpiTargetInterface[],
    startDate: moment.Moment,
    endDate: moment.Moment,
    site: { id: number; name: string },
    line: { id: number; title: string },
  ): KpiTargetInterface[] {
    const targetsClone: KpiTargetInterface[] = _.cloneDeep(targets) ?? [];

    const years: number[] = this.rangeOfYears(startDate.year(), endDate.year());

    let isFirstYear: boolean = true;
    let isLastYear: boolean = false;

    for (const year of years) {
      if (years.indexOf(year) === years.length - 1) {
        isLastYear = true;
      }

      for (const month of this.getMonths(isFirstYear, isLastYear, startDate, endDate)) {
        if (
          targetsClone.some(
            (target: KpiTargetInterface): boolean =>
              target.targetPeriod === `${year}/${month}` ||
              target.targetPeriod === `${year}/${month.toLocaleString(undefined, { minimumIntegerDigits: 2 })}`,
          )
        ) {
          continue;
        }

        targetsClone.push({
          id: null,
          lineId: line.id,
          oeeTarget: '0',
          batchSizeTarget: '0',
          laborCostTarget: '0',
          scheduleTarget: '0',
          targetPeriod: `${year}/${month.toLocaleString(undefined, { minimumIntegerDigits: 2 })}`,
          line: {
            id: line.id,
            title: line.title,
          },
          site: {
            id: site.id,
            name: site.name,
          },
        });
      }

      if (isFirstYear) {
        isFirstYear = false;
      }
    }

    return _.orderBy(
      targetsClone,
      [(item: KpiTargetInterface) => moment(_.get(item, 'targetPeriod', moment()), ['YYYY/M', 'YYYY/MM']).toDate()],
      ['ascending'],
    ) as KpiTargetInterface[];
  }

  private insertMissingLines(
    currentLines: { [line: string]: KpiTargetInterface[] },
    totalLines: number[],
  ): { [line: string]: KpiTargetInterface[] } {
    const cloneCurrentLines: { [line: string]: KpiTargetInterface[] } = _.cloneDeep(currentLines);

    totalLines.forEach((line) => {
      if (cloneCurrentLines.hasOwnProperty(line)) {
        return;
      }

      cloneCurrentLines[line] = [];
    });

    return cloneCurrentLines;
  }

  private parseSelectedLineIds(lineIds: number | number[]): number[] {
    if (typeof lineIds === 'number') {
      return [lineIds];
    }

    if (Array.isArray(lineIds)) {
      return lineIds;
    }

    return [];
  }

  public generateMissingKpiTargets(
    response: GetManyResponseInterface<KpiTargetInterface>,
    startDate: moment.Moment,
    endDate: moment.Moment,
    selectedLineIds: number | number[],
    selectedSiteIds: number | number[],
  ): GetManyResponseInterface<KpiTargetInterface> {
    const cloneResponse: GetManyResponseInterface<KpiTargetInterface> = _.cloneDeep(response);

    const groupedBySiteLine: IKpiTargetsSiteLineTree = this.helperService.nest<IKpiTargetsSiteLineTree>(
      cloneResponse.data,
      ['site.id', 'line.id'],
    );

    if (_.isEmpty(groupedBySiteLine) && Array.isArray(selectedSiteIds)) {
      groupedBySiteLine[selectedSiteIds[0]] = {};
    }

    const targets: KpiTargetInterface[] = [];
    Object.keys(groupedBySiteLine).forEach((site: string): void => {
      const sitesLines: LineCRUDInterface[] = this.lines.filter(
        (line: LineCRUDInterface) => line.siteId === Number(site),
      );
      const sitesLinesIds: number[] =
        selectedLineIds === undefined || selectedLineIds === -1
          ? sitesLines.map((sitesLine: LineCRUDInterface) => sitesLine.id)
          : this.parseSelectedLineIds(selectedLineIds);

      Object.keys(this.insertMissingLines(groupedBySiteLine[site], sitesLinesIds)).forEach((line: string): void => {
        targets.push(
          ...this.generateMissingTargetsByYears(
            groupedBySiteLine[site][line],
            startDate,
            endDate,
            {
              id: Number(site),
              name: this.sites.find((value) => value.id === Number(site)).name,
            },
            { id: Number(line), title: sitesLines.find((value: LineCRUDInterface) => value.id === Number(line)).title },
          ),
        );
      });
    });

    cloneResponse.data = targets;

    return cloneResponse;
  }
}
