import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ObjectActions from './stations.actions';
import { catchError, switchMap } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { from, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../../oee.reducer';
import { StationsService } from './stations.service';
import { ResponseArrayInterface } from '../../../../shared/model/interface/generic-api-response.model';
import { IStation, StationBulkResponseInterface } from './stations.model';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../../shared/model/interface/crud-response-interface.model';
import * as AppActions from '../../../app/actions';

@Injectable()
export class StationsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly stationsService: StationsService,
  ) {}

  getStations = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.STATIONS_DATA_LOAD),
      switchMap((object: ObjectActions.StationsDataLoad) => {
        const andConditions = [];
        let httpParams = new HttpParams()
          .append('join', 'line||title,siteId')
          .append('page', String(object.tableQuery.page))
          .append('limit', String(object.tableQuery.pageSize || 1000));

        if (object.tableQuery.orderBy && object.tableQuery.orderDesc !== undefined) {
          httpParams = httpParams.set(
            'sort',
            `${object.tableQuery.orderBy},${object.tableQuery.orderDesc ? 'DESC' : 'ASC'}`,
          );
        }

        if (object.tableQuery.search) {
          andConditions.push({ name: { $cont: object.tableQuery.search } });
        }

        if (object.tableQuery.siteIds && object.tableQuery.siteIds.length > 0) {
          andConditions.push({ 'line.siteId': { $in: object.tableQuery.siteIds } });
        }

        if (object.tableQuery.lineIds && object.tableQuery.lineIds.length > 0) {
          andConditions.push({ lineId: { $in: object.tableQuery.lineIds } });
        }

        if (andConditions.length > 0) {
          httpParams = httpParams.set('s', JSON.stringify({ $and: andConditions }));
        }

        return this.stationsService.getStations(httpParams).pipe(
          switchMap((response: ResponseArrayInterface<IStation>) => {
            return of(new ObjectActions.StationsDataLoaded(response));
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  addStation = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ADD_STATION),
      switchMap((objectData: ObjectActions.AddStation) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.stationsService.addStation(objectData.station).pipe(
          switchMap((response: BaseOneResponseInterface<IStation>) => {
            return of(new ObjectActions.AddStationCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  editStation = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.EDIT_STATION),
      switchMap((object: ObjectActions.EditStation) => {
        return this.stationsService.editStations(object.station, object.stationId).pipe(
          switchMap((response: BaseOneResponseInterface<IStation>) => {
            return of(new ObjectActions.EditStationCompleted(response));
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  bulkEditStations = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.BULK_EDIT_STATIONS),
      switchMap((objectData: ObjectActions.BulkEditStations) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.stationsService.bulkEditStations(objectData.stations).pipe(
          switchMap((response: GetManyResponseInterface<StationBulkResponseInterface>) => {
            return of(new ObjectActions.BulkEditStationsCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  deleteStation = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DELETE_STATION),
      switchMap((objectData: ObjectActions.DeleteStation) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return from(this.stationsService.deleteStation(objectData.stationId)).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            return of(new ObjectActions.DeleteStationCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  deleteStations = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.BULK_DELETE_STATIONS),
      switchMap((objectData: ObjectActions.BulkDeleteStations) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return from(this.stationsService.bulkDeleteStations(objectData.stationIds)).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            return of(new ObjectActions.BulkDeleteStationsCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );
}
