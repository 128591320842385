import { AlertPauseActions, AlertPauseActionTypes } from './alert-pause.actions';
import { AlertPauseStateInterface } from './alert-pause.model';

export const alertPauseInitialState: AlertPauseStateInterface = {
  isLoading: false,
  isLoaded: false,
  snoozeUntil: '',
  errors: [],
};

export function alertPauseReducer(
  state: AlertPauseStateInterface = alertPauseInitialState,
  action: AlertPauseActions,
) {
  switch (action.type) {
    case AlertPauseActionTypes.StartGetAlertPauseDataLoading:
    case AlertPauseActionTypes.StartUpdateAlertPauseDataLoading:
      return {
        ...state,
        isLoading: true,
      };
    case AlertPauseActionTypes.StartGetAlertPauseDataLoaded:
    case AlertPauseActionTypes.StartUpdateAlertPauseDataLoaded:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        snoozeUntil: action.payload.data.snoozeUntil,
      };
    case AlertPauseActionTypes.FetchError:
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        errors: action.payload,
      };
    default:
      return state;
  }
}
