import { ManualCountModel } from './model';
import * as Actions from './actions';

const initialState: ManualCountModel = {
  createManualCountLoading: false,
  createManualCountLoaded: false,
  setManualCountLoading: false,
  setManualCountLoaded: false,
  isManualCountCommentsSaveStarted: false,
  isManualCountCommentsSaveCompleted: false,
  manualCountCommentSaveResponse: null,
  isManualCountDataLoaded: false,
  isManualCountDataLoading: false,
  manualCountData: null,
};

export function manualCountReducer(
  state: ManualCountModel = initialState,
  action: Actions.ManualCountActions,
): ManualCountModel {
  switch (action.type) {
    case Actions.SET_WORK_ORDER_MANUAL_COUNT_FORM:
      return {
        ...state,
        ...{
          isManualCountAddFormSubmitted: action['isManualCountAddFormSubmitted'],
        },
      };

    case Actions.SET_HOURLY_COUNT:
    case Actions.SET_BULK_HOURLY_COUNT:
      return {
        ...state,
        setManualCountLoading: true,
        setManualCountLoaded: false,
      };

    case Actions.SET_HOURLY_COUNT_COMPLETED:
    case Actions.SET_BULK_HOURLY_COUNT_COMPLETED:
    case Actions.CREATE_MANUAL_COUNT_FAILED:
    case Actions.SET_HOURLY_COUNT_FAILED:
      return {
        ...state,
        setManualCountLoading: false,
        setManualCountLoaded: true,
      };

    case Actions.CREATE_MANUAL_COUNT:
      return {
        ...state,
        createManualCountLoading: true,
        createManualCountLoaded: false,
      };

    case Actions.CREATE_MANUAL_COUNT_COMPLETED:
      return {
        ...state,
        createManualCountLoading: false,
        createManualCountLoaded: true,
      };
    case Actions.SAVE_MANUAL_COUNT_COMMENTS:
      return {
        ...state,
        isManualCountCommentsSaveStarted: true,
        isManualCountCommentsSaveCompleted: false,
      };

    case Actions.MANUAL_COUNT_COMMENTS_SAVED:
      return {
        ...state,
        isManualCountCommentsSaveStarted: false,
        isManualCountCommentsSaveCompleted: true,
        manualCountCommentSaveResponse: action.response.data,
      };

    case Actions.MANUAL_COUNT_REVIEW_DATA_LOADING:
      return {
        ...state,
        isManualCountDataLoading: true,
        isManualCountDataLoaded: false,
      };

    case Actions.MANUAL_COUNT_REVIEW_DATA_LOADED:
      return {
        ...state,
        isManualCountDataLoading: false,
        isManualCountDataLoaded: true,
        manualCountData: action.payload,
      };

    case Actions.FETCH_DATA_ERROR:
      return {
        ...state,
        isManualCountCommentsSaveStarted: false,
        isManualCountCommentsSaveCompleted: true,
      };

    case Actions.RESET_MANUAL_COUNT_STORE:
      return {
        createManualCountLoading: false,
        createManualCountLoaded: false,
        setManualCountLoading: false,
        setManualCountLoaded: false,
        manualCountCommentSaveResponse: null,
        isManualCountCommentsSaveStarted: false,
        isManualCountCommentsSaveCompleted: false,
        isManualCountDataLoaded: false,
        isManualCountDataLoading: false,
        manualCountData: null,
      };

    default:
      return state;
  }
}
