import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ScwMatCheckBoxSize } from './scw-mat-checkbox.model';

@Component({
  selector: 'scw-mat-checkbox',
  templateUrl: './scw-mat-checkbox.component.html',
  styleUrls: ['./scw-mat-checkbox.component.scss'],
})
export class ScwMatCheckboxComponent {
  @Input() inputModel: boolean = false;
  @Input() disabled: boolean = false;
  @Input() id: string;
  @Input() labelPosition: 'before' | 'after';
  @Input() name: string;
  @Input() required: boolean = false;
  @Input() value: string;
  @Input() size: ScwMatCheckBoxSize = 'md';
  @Output() onChange: EventEmitter<MatCheckboxChange> = new EventEmitter<MatCheckboxChange>();
  @Output() inputModelChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public onChangeEmitter($event: MatCheckboxChange): void {
    this.onChange.emit($event);
  }

  public onNgModelChange(): void {
    this.inputModelChange.emit(this.inputModel);
  }

  public toggle(): void {
    this.inputModel = !this.inputModel;
    this.onNgModelChange();
  }
}
