import { Inject, Injectable } from '@angular/core';
import { FilterServiceInterface } from './service.class';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { ObservationCRUDInterface, ServiceResponseInterface } from '../../component/filter/filter.class';

@Injectable({
  providedIn: 'root',
})
export class ObservationService implements FilterServiceInterface {
  constructor(public http: HttpClient, @Inject('API_BASE_URL') private baseUrl: string) {}

  getData(options?: HttpParams): Promise<ObservationCRUDInterface[]> {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.baseUrl}/comment-types`, { params: options }).subscribe(
        (response: ServiceResponseInterface<ObservationCRUDInterface>) => {
          if (response.success) {
            resolve(response.data);
          } else {
            reject();
          }
        },
        (error: HttpErrorResponse) => {
          reject(error);
        },
      );
    });
  }
}
