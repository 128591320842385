import { FilterCheckInLogSiteActionTypes, FilterCheckInLogSiteActions } from './site.actions';
import { SiteCRUDInterface } from '../../../shared/component/filter/filter.class';

export interface FilterCheckInLogSiteState {
  isLoading: boolean;
  isLoaded: boolean;
  data: SiteCRUDInterface[];
}

export const initialFilterCheckInLogSiteState: FilterCheckInLogSiteState = {
  isLoaded: false,
  isLoading: false,
  data: [],
};

export function filterCheckInLogSiteReducer(
  state = initialFilterCheckInLogSiteState,
  action: FilterCheckInLogSiteActions,
): FilterCheckInLogSiteState {
  switch (action.type) {
    case FilterCheckInLogSiteActionTypes.StartLoadFilterCheckInLogSites:
      return { ...state, isLoading: true };

    case FilterCheckInLogSiteActionTypes.FilterCheckInLogSitesLoaded:
      return { ...state, data: action.data, isLoaded: true, isLoading: false };

    default:
      return state;
  }
}
