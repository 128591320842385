import { Inject, Injectable } from '@angular/core';
import { FilterServiceInterface } from './service.class';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LineTypeNameInterface } from '../../component/filter/filter.class';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class DepartmentService implements FilterServiceInterface {
  constructor(public http: HttpClient, @Inject('API_BASE_URL') private readonly baseUrl: string) {}

  getData(options?: HttpParams): Promise<LineTypeNameInterface[]> {
    const url = `${this.baseUrl}/lines?join=lineTypeName`;
    return new Promise((resolve, reject) => {
      const result:LineTypeNameInterface[] = [];
      this.http
        .get(url, { params: options })
        .subscribe((response: any) => {
          if (response.hasOwnProperty('data')) {
            response.data.forEach((item) => {
              if (item.lineTypeName) {
                result.push({
                  ...(item.lineTypeName as LineTypeNameInterface),
                  siteId: item.siteId as number,
                });
              }
            });
            // eslint-disable-next-line
            resolve(_.uniqBy(result, (data) => data.id));
          } else {
            reject();
          }
        });
    });
  }
}
