import { WeavyActions, WeavyActionTypes } from './weavy.actions';
import { IWeavyState } from './weavy.model';

export const weavyInitialState: IWeavyState = {
  jwtIsLoading: false,
  jwtIsLoaded: false,
  weavyIsLoading: false,
  weavyIsLoaded: false,
  jwt: {
    success: null,
    token: '',
  },
  errors: [],
};

export function weavyReducer(state: IWeavyState = weavyInitialState, action: WeavyActions) {
  switch (action.type) {
    case WeavyActionTypes.JWT_LOADING:
      return {
        ...state,

        jwtIsLoading: true,
        jwtIsLoaded: false,
      };

    case WeavyActionTypes.JWT_LOADED:
      return {
        ...state,
        jwt: action.payload,
        jwtIsLoading: false,
        jwtIsLoaded: true,
      };

    case WeavyActionTypes.WEAVY_INITIALIZED:
      return {
        ...state,
        weavyIsLoading: false,
        weavyIsLoaded: true,
      };

    case WeavyActionTypes.RESET_WEAVY_STATE:
      return {
        ...state,
        weavyIsLoading: false,
        weavyIsLoaded: false,
      };

    case WeavyActionTypes.WEAVY_ERROR:
      return {
        ...state,
      };

    case WeavyActionTypes.FETCH_ERROR:
      return {
        ...state,
        payload: action.payload,
      };

    default:
      return state;
  }
}
