import { Moment } from 'moment';
import { IOeeCalculationResultFormatted } from '../../../shared/model/interface/generic-api-response.model';
import { Subject } from 'rxjs';
import { TabRowInterface } from '../../../shared/component/side-config-bar/side-config-bar.model';

export interface KpiViewStateInterface {
  kpiViewWidgetOeeDataLoading: boolean;
  kpiViewWidgetOeeDataLoaded: boolean;
  kpiViewWidgetOeeData: IKpiViewWidgetOeeData;
  kpiViewWidgetWorkOrderCountDataLoading: boolean;
  kpiViewWidgetWorkOrderCountDataLoaded: boolean;
  kpiViewWidgetWorkOrderCountData: IKpiViewWorkOrderCountAndTarget;
  kpiViewChartOeeDataLoading: boolean;
  kpiViewChartOeeDataLoaded: boolean;
  kpiViewChartOeeData: IKpiViewOeeAndGoodCountTrend;
  kpiViewChartWorkOrderCountDataLoading: boolean;
  kpiViewChartWorkOrderCountDataLoaded: boolean;
  kpiViewChartWorkOrderCountData: IKpiViewWorkOrderTrend[];
  kpiViewLaborHoursDataLoading: boolean;
  kpiViewLaborHoursDataLoaded: boolean;
  kpiViewLaborHoursData: IKpiViewLaborHoursTrend;
  tableSettings: TabRowInterface[];
}

export interface IKpiViewParameters {
  start: Moment | string;
  end: Moment | string;
  sites: -1 | number[];
  lines: -1 | number[];
  groupBy: 'WEEK' | 'MONTH' | 'INTERVAL';
}

export interface IKpiViewOEEParameters extends Omit<IKpiViewParameters, 'start' | 'end' | 'groupBy'> {
  startDate: Moment | string;
  endDate: Moment | string;
  sqlMode: 'WEEK' | 'MONTH' | 'INTERVAL';
}

export interface IKpiViewWorkOrderCountAndTarget {
  workOrderCount: string;
  averageTarget: string | null;
}

export interface IKpiViewLaborHoursTrend {
  laborHours: IKpiViewLaborHours[];
  averageTarget: string | null;
  isLaborTrackerInactiveOnAllSites: boolean;
}

export interface IKpiViewWorkOrderTrend {
  intervalName: string;
  workOrderCount: string;
  standardTarget: string;
  scheduledWorkOrderCount: string;
}

export interface IKpiViewWidgetOeeData {
  oeeData: IOeeCalculationResultFormatted;
  oeeAverageTarget: string | null;
  goodCountAverageTarget: string | null;
}

export interface IKpiViewOeeAndGoodCountAverageTarget {
  oeeAverageTarget: string | null;
  goodCountAverageTarget: string | null;
}

export interface IKpiViewOeeAndGoodCountTrend {
  oeeTrend: IOeeCalculationResultFormatted[];
  goodCountTrend: IKpiViewGoodCountTrend[];
}

export interface IKpiViewGoodCountTrend {
  intervalName: string;
  standardTarget: number;
  scheduledTarget: number;
}

export interface IKpiViewLaborHours {
  intervalName: string;
  regularTime: string;
  overTime: string;
  standardTarget: string;
}

export interface IKpiViewInjectData {
  componentType: KpiViewComponents;
  reloadDataSubject: Subject<IKpiViewParameters>;
  siteDecimalScaleLimitSubject: Subject<number>;
  userDecimalScaleLimitSubject: Subject<number>;
  parameters: IKpiViewParameters;
}

export enum KpiViewComponents {
  oeeAndTarget = 'oeeAndTarget',
  workOrderCountAndTarget = 'workOrderCountAndTarget',
  goodCountAndTarget = 'goodCountAndTarget',
  overtimeAndTarget = 'overtimeAndTarget',
  oeeTrend = 'oeeTrend',
  workOrderTrend = 'workOrderTrend',
  goodCountTrend = 'goodCountTrend',
  laborHoursTrend = 'laborHoursTrend',
}

export interface IKpiViewChildComponents {
  subscriptionAction: (state: KpiViewStateInterface) => void;
  loadData: () => void;
  label: string;
  clearState?: () => void;
  info?: string;
}

export interface IKpiViewChildComponentWidget extends IKpiViewChildComponents {
  avgLabel: string;
}

export interface ISite {
  siteId: number;
  siteName: string;
  decimalScaleLimit:number;
}
