import * as Actions from './searchable-dropdown.actions';
import { SearchableDropdownStateInterface } from './searchable-dropdown.model';

const initialState: SearchableDropdownStateInterface = {
  configuration: null,
  selectedData: {
    data: null,
    editedColumnElementId: null,
  },
  formIsSubmitted: false,
};

export function searchableDropdownReducer(
  state: SearchableDropdownStateInterface = initialState,
  action: Actions.SearchableDropdownActions,
): SearchableDropdownStateInterface {
  switch (action.type) {
    case Actions.SET_CONFIGURATION:
      return {
        ...state,
        configuration: action.configuration,
      };

    case Actions.DATA_IS_SET:
      return {
        ...state,
        selectedData: {
          data: {
            ...state.selectedData.data,
            ...action.selectedData.data,
          },
          editedColumnElementId: action.selectedData.editedColumnElementId,
        },
      };

    case Actions.RESET_SEARCHABLE_DROPDOWN_STORE:
      return {
        configuration: null,
        selectedData: {
          data: null,
          editedColumnElementId: null,
        },
        formIsSubmitted: false,
      };

    case Actions.SET_FORM_IS_SUBMITTED:
      return {
        ...state,
        formIsSubmitted: action.newValue,
      };
    default:
      return state;
  }
}
