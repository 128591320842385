import { Action } from '@ngrx/store';
import {
  SelectedFilters,
  ShiftInformationInterface,
  WorkOrdersSummaryFilterInterface,
  WorkOrdersSummaryResponseInterface,
  WorkOrdersSummaryShiftResponseDataInterface,
} from './work-orders-summary.model';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { PreparedFilterDataInterface } from '../../shared/service/page-filters-and-settings/page-filters-and-settings.service';

export enum WorkOrdersSummaryActionTypes {
  StartWorkOrdersSummaryDataLoading = '[Work Orders Summary] START SHIFT SUMMARY DATA LOADING',
  WorkOrdersSummaryDataLoaded = '[Work Orders Summary] SHIFT SUMMARY DATA LOADED',
  GetShiftDataAndGroups = '[Work Orders Summary] GET SHIFT DATA AND GROUPS',
  GetShiftDataAndGroupsLoaded = '[Work Orders Summary] GET SHIFT DATA AND GROUPS LOADED',
  StartFiltersSaving = '[Work Orders Summary] START FILTERS SAVING',
  FiltersSaved = '[Work Orders Summary] FILTERS SAVED',
  GetFiltersAndSettings = '[Work Orders Summary] GET FILTERS AND SETTINGS',
  GetFiltersAndSettingsLoaded = '[Work Orders Summary] GET FILTERS AND SETTINGS LOADED',
  SetSelectedFilters = '[Work Orders Summary] SET SELECTED FILTERS',
  SetSelectedFiltersLoaded = '[Work Orders Summary] SET SELECTED FILTERS LOADED',
  ClearState = '[Work Orders Summary] CLEAR STATE',
  FetchError = '[Work Orders Summary] FETCH ERROR',
}

export class StartWorkOrdersSummaryDataLoading implements Action {
  readonly type = WorkOrdersSummaryActionTypes.StartWorkOrdersSummaryDataLoading;
  constructor(public payload: HttpParams) {}
}

export class WorkOrdersSummaryDataLoaded implements Action {
  readonly type = WorkOrdersSummaryActionTypes.WorkOrdersSummaryDataLoaded;

  constructor(public payload: WorkOrdersSummaryShiftResponseDataInterface) {}
}

export class GetShiftDataAndGroups implements Action {
  readonly type = WorkOrdersSummaryActionTypes.GetShiftDataAndGroups;
  constructor(
    public siteId: number,
    public departmentId: number,
    public shiftInformation: ShiftInformationInterface,
  ) {}
}

export class GetShiftDataAndGroupsLoaded implements Action {
  readonly type = WorkOrdersSummaryActionTypes.GetShiftDataAndGroupsLoaded;

  constructor(public payload: WorkOrdersSummaryResponseInterface) {}
}

export class StartFiltersSaving implements Action {
  readonly type = WorkOrdersSummaryActionTypes.StartFiltersSaving;

  constructor(public payload: WorkOrdersSummaryFilterInterface) {}
}

export class FiltersSaved implements Action {
  readonly type = WorkOrdersSummaryActionTypes.FiltersSaved;

  constructor(public payload: any) {}
}

export class GetFiltersAndSettings implements Action {
  readonly type = WorkOrdersSummaryActionTypes.GetFiltersAndSettings;
}

export class GetFiltersAndSettingsLoaded implements Action {
  readonly type = WorkOrdersSummaryActionTypes.GetFiltersAndSettingsLoaded;
  constructor(public payload: PreparedFilterDataInterface) {}
}

export class SetSelectedFilters implements Action {
  readonly type = WorkOrdersSummaryActionTypes.SetSelectedFilters;
  constructor(public filters: SelectedFilters) {}
}

export class SetSelectedFiltersLoaded implements Action {
  readonly type = WorkOrdersSummaryActionTypes.SetSelectedFiltersLoaded;
}

export class ClearState implements Action {
  readonly type = WorkOrdersSummaryActionTypes.ClearState;
}

export class FetchError implements Action {
  readonly type = WorkOrdersSummaryActionTypes.FetchError;

  constructor(public payload: HttpErrorResponse) {}
}

export type WorkOrdersSummaryActions =
  | StartWorkOrdersSummaryDataLoading
  | WorkOrdersSummaryDataLoaded
  | GetShiftDataAndGroups
  | GetShiftDataAndGroupsLoaded
  | StartFiltersSaving
  | FiltersSaved
  | GetFiltersAndSettings
  | GetFiltersAndSettingsLoaded
  | SetSelectedFilters
  | SetSelectedFiltersLoaded
  | ClearState
  | FetchError;
