import { FilterSourceObjectActions, FilterSourceObjectActionTypes } from './source-object.actions';
import { GetDistinctCheckInObjectsInterfaceData } from '../../check-in/check-in.model';

export interface SourceObjectFilterState {
  isLoading: boolean;
  isLoaded: boolean;
  data: GetDistinctCheckInObjectsInterfaceData[];
}

export const initialSourceObjectFilterState: SourceObjectFilterState = {
  isLoaded: false,
  isLoading: false,
  data: [],
};

export function sourceObjectFilterReducer(
  state = initialSourceObjectFilterState,
  action: FilterSourceObjectActions,
): SourceObjectFilterState {
  switch (action.type) {
    case FilterSourceObjectActionTypes.StartLoadFilterSourceObjects:
      return { ...state, isLoading: true };

    case FilterSourceObjectActionTypes.FilterSourceObjectsLoaded:
      return { ...state, data: action.data, isLoaded: true, isLoading: false };

    default:
      return state;
  }
}
