import { FilterTaskActions, FilterTaskActionTypes } from './task.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { TaskFilterCRUDInterface } from '../../../shared/component/filter/filter.class';

export interface FilterTaskState {
  isLoading: boolean;
  isLoaded: boolean;
  data: TaskFilterCRUDInterface[];
  errors: HttpErrorResponse[];
}

export const initialFilterTaskState: FilterTaskState = {
  isLoaded: false,
  isLoading: false,
  data: [],
  errors: [],
};

export function filterTaskReducer(
  state: FilterTaskState = initialFilterTaskState,
  action: FilterTaskActions,
): FilterTaskState {
  switch (action.type) {
    case FilterTaskActionTypes.StartLoadFilterTasks:
      return { ...state, isLoaded: false, isLoading: true };

    case FilterTaskActionTypes.FilterTasksLoaded:
      return { ...state, data: action.data, isLoaded: true, isLoading: false };

    case FilterTaskActionTypes.ResetTaskStore:
      return { ...state, data: [], isLoaded: false, isLoading: false };

    case FilterTaskActionTypes.FetchError:
      const errors = [...state.errors, action.payload];

      return { ...state, errors, isLoaded: false, isLoading: false };

    default:
      return state;
  }
}
