import { Action } from '@ngrx/store';
import { ActivityTypeInterface } from '../../../shared/model/interface/activities.model';
import { HttpOptionInterface } from '../filter.model';

export enum FilterActivityTypeActionTypes {
  StartLoadFilterActivityType = '[Filter Activity Type] Load Filter Activity Type',
  FilterActivityTypeLoaded = '[Filter Activity Type] Filter Activity Loaded',
  ResetActivityTypeStore = '[Filter Activity Type] Reset data stored in Activity Type store',
}

export class StartLoadFilterActivityTypes implements Action {
  readonly type = FilterActivityTypeActionTypes.StartLoadFilterActivityType;

  constructor(public options: HttpOptionInterface) {}
}

export class FilterActivityTypesLoaded implements Action {
  readonly type = FilterActivityTypeActionTypes.FilterActivityTypeLoaded;

  constructor(public data: ActivityTypeInterface[]) {}
}

export class ResetActivityTypesStore implements Action {
  readonly type = FilterActivityTypeActionTypes.ResetActivityTypeStore;
}

export type FilterActivityTypesActions =
  | StartLoadFilterActivityTypes
  | FilterActivityTypesLoaded
  | ResetActivityTypesStore;
