import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LaborPerformanceResponseInterface } from '../../../store/reports/labor-performance/labor-performance.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LaborPerformanceService {
  constructor(public http: HttpClient, @Inject('API_BASE_URL') private readonly baseUrl: string) {
  }

  public getLaborPerformanceData(parameters: HttpParams): Observable<LaborPerformanceResponseInterface> {
    return this.http
      .get<LaborPerformanceResponseInterface>(`${this.baseUrl}/products-labors-assets-hours/labor-performance-data`, {
        params: parameters,
      });
  }
}
