import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { LineAvailabilityActions, SavePlanItems } from './line-availability.actions';
import * as ObjectActions from '../line-availability/line-availability.actions';
import { ShiftService } from '../../shared/service/line-availability/shift.service';
import {
  CostFactorResponseInterface,
  DeletePlanCRUDResponseInterface,
  ExceptionPlanDataInterface,
  LineAvailabilityCostFactorDataInterface,
  LineAvailabilityPlanCostFactorInterface,
  LineAvailabilityPlanDataInterface,
  LineAvailabilityPlanItemInterface,
  LineAvailabilityResponseInterface,
  LineAvailabilityShiftDataInterface,
  SchedulerPlanCRUDResponseInterface,
} from './line-availability.model';
import { PlanDataService } from '../../shared/service/line-availability/plan-data.service';
import { CostFactorService } from '../../shared/service/line-availability/cost-factor.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ExceptionPlanDataService } from '../../shared/service/line-availability/exception-plan-data.service';
import { ItemTypeEnum, PlanItemService } from '../../shared/service/line-availability/plan-item.service';
import { BulkResponseDataInterface } from '../../shared/model/interface/crud-response-interface.model';
import { HelperService } from '../../shared/service/helper.service';
import * as AppActions from '../app/actions';
import { Action, Store } from '@ngrx/store';
import * as oeeAppReducer from '../oee.reducer';

@Injectable()
export class LineAvailabilityEffects {
  constructor(
    private readonly actions$: Actions<LineAvailabilityActions>,
    private readonly helperService: HelperService,
    public shiftService: ShiftService,
    public planDataService: PlanDataService,
    public exceptionPlanService: ExceptionPlanDataService,
    public costFactorService: CostFactorService,
    public planItemService: PlanItemService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
  ) {}

  $loadShiftsData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LineAvailabilityActionTypes.LoadLineAvailabilityShiftsData),
      mergeMap((objectData: ObjectActions.LoadLineAvailabilityShiftsData) => {
        return this.shiftService.getData(objectData.query).pipe(
          switchMap((response: LineAvailabilityResponseInterface<LineAvailabilityShiftDataInterface>) => {
            return of(new ObjectActions.LineAvailabilityShiftsDataLoaded(response, objectData.isGanttShift));
          }),
        );
      }),
    ),
  );

  $createShift = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LineAvailabilityActionTypes.CreateShift),
      switchMap((objectData: ObjectActions.CreateShift) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.shiftService.createData(objectData.payload).pipe(
          switchMap((response) => {
            return of(new ObjectActions.CreateShiftLoaded(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  $editShift = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LineAvailabilityActionTypes.EditShift),
      switchMap((objectData: ObjectActions.EditShift) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.shiftService.editData(objectData.payload).pipe(
          switchMap((response: LineAvailabilityResponseInterface<LineAvailabilityShiftDataInterface>) => {
            return of(
              new ObjectActions.EditShiftCompleted({
                ...objectData.payload,
              }),
              new AppActions.HideLoader(),
            );
          }),
          catchError((error) => {
            return of(
              new ObjectActions.FetchError(error, ObjectActions.LineAvailabilityActionTypes.EditShift),
              new AppActions.HideLoader(),
            );
          }),
        );
      }),
      catchError((error) => {
        return of(
          new ObjectActions.FetchError(error, ObjectActions.LineAvailabilityActionTypes.EditShift),
          new AppActions.HideLoader(),
        );
      }),
    ),
  );

  $deleteShift = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LineAvailabilityActionTypes.DeleteShift),
      switchMap((objectData: ObjectActions.DeleteShift) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.shiftService.deleteData(objectData.id).pipe(
          switchMap((response: LineAvailabilityResponseInterface<LineAvailabilityShiftDataInterface>) => {
            return of(new ObjectActions.DeleteShiftCompleted(objectData.id), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  $deleteShifts = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LineAvailabilityActionTypes.DeleteShifts),
      switchMap((objectData: ObjectActions.DeleteShifts) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.shiftService.deleteShifts(objectData.shiftIds).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            return of(new ObjectActions.DeleteShiftsCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  $loadPlansData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LineAvailabilityActionTypes.LoadLineAvailabilityPlansData),
      switchMap((objectData: ObjectActions.LoadLineAvailabilityPlansData) => {
        return from(this.planDataService.getData(objectData.query)).pipe(
          switchMap((response: LineAvailabilityResponseInterface<LineAvailabilityPlanDataInterface>) => {
            return of(new ObjectActions.LineAvailabilityPlansDataLoaded(response));
          }),
          catchError((errorRes: HttpErrorResponse) => {
            return of(new ObjectActions.PlanFetchError(errorRes));
          }),
        );
      }),
    ),
  );

  $deletePlan = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LineAvailabilityActionTypes.DeletePlan),
      switchMap((objectData: ObjectActions.DeletePlan) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return from(this.planDataService.deleteData(objectData.id)).pipe(
          switchMap((response: DeletePlanCRUDResponseInterface<LineAvailabilityPlanDataInterface>) => {
            return of(new ObjectActions.DeletePlanCompleted(objectData.id), new AppActions.HideLoader());
          }),
          catchError((errorRes: HttpErrorResponse) => {
            return of(new ObjectActions.PlanFetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  $deletePlans = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LineAvailabilityActionTypes.DeletePlans),
      switchMap((objectData: ObjectActions.DeletePlans) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.planDataService.deletePlans(objectData.planIds).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            return of(new ObjectActions.DeletePlansCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  $loadCostFactorData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LineAvailabilityActionTypes.LoadLineAvailabilityCostFactorsData),
      switchMap((objectData: ObjectActions.LoadLineAvailabilityCostFactorsData) => {
        return this.costFactorService.getData(objectData.query, objectData.siteIds).pipe(
          switchMap((response: LineAvailabilityPlanCostFactorInterface) => {
            return of(new ObjectActions.LineAvailabilityCostFactorsDataLoaded(response));
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error));
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error));
      }),
    ),
  );

  $createPlan = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LineAvailabilityActionTypes.CreatePlan),
      switchMap((objectData: ObjectActions.CreatePlan) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return from(this.planDataService.createData(objectData.payload)).pipe(
          switchMap((response: SchedulerPlanCRUDResponseInterface<LineAvailabilityPlanDataInterface>) => {
            return of(new ObjectActions.CreatePlanLoaded(response.data), new AppActions.HideLoader());
          }),
          catchError((errorRes: HttpErrorResponse) => {
            return of(new ObjectActions.PlanFetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  $editPlan = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LineAvailabilityActionTypes.EditPlan),
      switchMap((objectData: ObjectActions.EditPlan) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return from(this.planDataService.editData(objectData.id, objectData.payload)).pipe(
          switchMap((response: SchedulerPlanCRUDResponseInterface<LineAvailabilityPlanDataInterface>) => {
            return of(new ObjectActions.EditPlanLoaded(response.data), new AppActions.HideLoader());
          }),
          catchError((errorRes: HttpErrorResponse) => {
            return of(new ObjectActions.PlanFetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  $createCostFactor = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LineAvailabilityActionTypes.CreateCostFactor),
      switchMap((objectData: ObjectActions.CreateCostFactor) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.costFactorService.createData(objectData.payload).pipe(
          switchMap((response: CostFactorResponseInterface) => {
            return of(new ObjectActions.CreateCostFactorLoaded(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  $editCostFactor = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LineAvailabilityActionTypes.EditCostFactor),
      switchMap((objectData: ObjectActions.EditCostFactor) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.costFactorService.editData(objectData.payload).pipe(
          switchMap((response: SchedulerPlanCRUDResponseInterface<LineAvailabilityCostFactorDataInterface>) => {
            const errorResponse: BulkResponseDataInterface = {
              success: response.success,
              data: [],
            };

            for (const item of response.data.schedulerCostFactorsSiteAssignment) {
              const { success, message } = item;

              if (success !== undefined && success === false) {
                errorResponse.data.push({
                  success,
                  message,
                });
              }
            }

            this.helperService.toastBulkErrors(errorResponse);

            return of(
              new ObjectActions.EditCostFactorCompleted({
                ...objectData.payload,
              }),
              new AppActions.HideLoader(),
            );
          }),
          catchError((error) => {
            return of(
              new ObjectActions.FetchError(error, ObjectActions.LineAvailabilityActionTypes.EditCostFactor),
              new AppActions.HideLoader(),
            );
          }),
        );
      }),
      catchError((error) => {
        return of(
          new ObjectActions.FetchError(error, ObjectActions.LineAvailabilityActionTypes.EditCostFactor),
          new AppActions.HideLoader(),
        );
      }),
    ),
  );

  $deleteCostFactor = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LineAvailabilityActionTypes.DeleteCostFactor),
      switchMap((objectData: ObjectActions.DeleteCostFactor) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.costFactorService.deleteData(objectData.id).pipe(
          switchMap((response: LineAvailabilityPlanCostFactorInterface) => {
            return of(new ObjectActions.DeleteCostFactorCompleted(objectData.id), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  $deleteCostFactors = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LineAvailabilityActionTypes.DeleteCostFactors),
      switchMap((objectData: ObjectActions.DeleteCostFactors) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.costFactorService.deleteCostFactors(objectData.costFactorIds).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            return of(new ObjectActions.DeleteCostFactorsCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  $loadExceptionData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LineAvailabilityActionTypes.LoadLineAvailabilityExceptionPlan),
      switchMap((objectData: ObjectActions.LoadLineAvailabilityExceptionPlan) => {
        return this.exceptionPlanService.getData(objectData.planId, objectData.query).pipe(
          switchMap((response: LineAvailabilityResponseInterface<ExceptionPlanDataInterface>) => {
            return of(new ObjectActions.LineAvailabilityExceptionPlanLoaded(response));
          }),
          catchError((errorRes: HttpErrorResponse) => {
            return of(new ObjectActions.PlanFetchError(errorRes));
          }),
        );
      }),
    ),
  );

  $deleteExceptionPlan = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LineAvailabilityActionTypes.DeleteExceptionPlan),
      switchMap((objectData: ObjectActions.DeleteExceptionPlan) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.exceptionPlanService.deleteData(objectData.id).pipe(
          switchMap((response: DeletePlanCRUDResponseInterface<ExceptionPlanDataInterface>) => {
            return of(new ObjectActions.DeleteExceptionPlanCompleted(objectData.id), new AppActions.HideLoader());
          }),
          catchError((errorRes: HttpErrorResponse) => {
            return of(new ObjectActions.PlanFetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  $createExceptionPlan = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LineAvailabilityActionTypes.CreateExceptionPlan),
      switchMap((objectData: ObjectActions.CreateExceptionPlan) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.exceptionPlanService
          .checkIfExceptionExists(objectData.payload.startDate, objectData.payload.endDate, objectData.payload.planId)
          .pipe(
            switchMap((response: LineAvailabilityResponseInterface<ExceptionPlanDataInterface>) => {
              if (response.success && response.count === 0) {
                return this.exceptionPlanService.createData(objectData.payload).pipe(
                  switchMap((response: SchedulerPlanCRUDResponseInterface<ExceptionPlanDataInterface>) => {
                    if (response.success) {
                      const actionsWillBeExecute: Action[] = [
                        new ObjectActions.CreateExceptionPlanLoaded(response.data),
                      ];

                      if (objectData.planItems.length) {
                        actionsWillBeExecute.push(
                          new SavePlanItems(
                            objectData.planItems,
                            {
                              planId: response.data.planId,
                              exceptionPlanId: response.data.id,
                            },
                            ItemTypeEnum.EXCEPTION_PLAN_ITEM,
                          ),
                        );
                      }

                      return of(...actionsWillBeExecute);
                    }
                    return of(null);
                  }),
                  catchError((errorRes: HttpErrorResponse) => {
                    return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
                  }),
                );
              }
              return of(new ObjectActions.CheckExceptionDatesInvalid(false), new AppActions.HideLoader());
            }),
          );
      }),
    ),
  );

  $editExceptionPlan = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LineAvailabilityActionTypes.EditExceptionPlan),
      switchMap((objectData: ObjectActions.EditExceptionPlan) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.exceptionPlanService
          .checkIfExceptionExists(
            objectData.payload.startDate,
            objectData.payload.endDate,
            objectData.payload.planId,
            objectData.id,
          )
          .pipe(
            switchMap((response: LineAvailabilityResponseInterface<ExceptionPlanDataInterface>) => {
              if (response.success && response.count === 0) {
                return this.exceptionPlanService.editData(objectData.id, objectData.payload).pipe(
                  switchMap((response: SchedulerPlanCRUDResponseInterface<ExceptionPlanDataInterface>) => {
                    if (response.success) {
                      const actionsWillBeExecute: Action[] = [
                        new ObjectActions.EditExceptionPlanLoaded(objectData.id, objectData.payload),
                      ];

                      if (objectData.planItems.length) {
                        actionsWillBeExecute.push(
                          new SavePlanItems(
                            objectData.planItems,
                            {
                              planId: response.data.planId,
                              exceptionPlanId: objectData.id,
                            },
                            ItemTypeEnum.EXCEPTION_PLAN_ITEM,
                          ),
                        );
                      }

                      return of(...actionsWillBeExecute);
                    }
                    return of(null);
                  }),
                  catchError((errorRes: HttpErrorResponse) => {
                    return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
                  }),
                );
              }
              return of(new ObjectActions.CheckExceptionDatesInvalid(false), new AppActions.HideLoader());
            }),
          );
      }),
    ),
  );

  $loadPlanItem = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LineAvailabilityActionTypes.LoadLineAvailabilityDefaultPlanItems),
      switchMap((objectData: ObjectActions.LoadLineAvailabilityDefaultPlanItems) => {
        return this.planItemService.getData(objectData.planId, ItemTypeEnum.SHIFT_PLAN_ITEM, objectData.query).pipe(
          switchMap((response: LineAvailabilityResponseInterface<LineAvailabilityPlanItemInterface>) => {
            return of(new ObjectActions.LineAvailabilityDefaultPlanItemsLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes: HttpErrorResponse) => {
            return of(new ObjectActions.PlanFetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  $loadExceptionPlanItem = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LineAvailabilityActionTypes.LoadLineAvailabilityExceptionPlanItems),
      switchMap((objectData: ObjectActions.LoadLineAvailabilityExceptionPlanItems) => {
        return this.planItemService
          .getData(objectData.exceptionPlanId, ItemTypeEnum.EXCEPTION_PLAN_ITEM, objectData.query)
          .pipe(
            switchMap((response: LineAvailabilityResponseInterface<LineAvailabilityPlanItemInterface>) => {
              return of(
                new ObjectActions.LineAvailabilityExceptionPlanItemsLoaded(response),
                new AppActions.HideLoader(),
              );
            }),
            catchError((errorRes: HttpErrorResponse) => {
              return of(new ObjectActions.PlanFetchError(errorRes), new AppActions.HideLoader());
            }),
          );
      }),
    ),
  );

  $createPlanItem = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LineAvailabilityActionTypes.SavePlanItems),
      switchMap((objectData: ObjectActions.SavePlanItems) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.planItemService.savePlanItems(objectData.payload, objectData.planId, objectData.itemType).pipe(
          switchMap((response: LineAvailabilityResponseInterface<LineAvailabilityPlanItemInterface>) => {
            return of(
              new ObjectActions.SavePlanItemsLoaded(objectData.payload, objectData.itemType, response.success),
              new AppActions.HideLoader(),
            );
          }),
          catchError((errorRes: HttpErrorResponse) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );
}
