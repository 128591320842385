import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ObjectActions from './activity-logs.actions';
import { catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as AppActions from '../../app/actions';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import { ActivityLogsService } from './activity-logs.service';
import { BaseOneResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { ActivityLogInterface, ActivityLogReportInterface } from './activity-logs.model';
import { EntityTranslatorService } from '../../../shared/service/entity-translator/entity-translator.service';
import { emptyAction } from '../../../../constants';
import { HelperService } from '../../../shared/service/helper.service';

@Injectable()
export class ActivityLogsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: ActivityLogsService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly entityTranslatorService: EntityTranslatorService,
  ) {}

  getActivityLogData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ACTIVITY_LOGS_DATA_LOADING),
      switchMap((payload: ObjectActions.ActivityLogsDataLoading) => {
        const data = this.service.formatActivityLogParams(payload.params);

        return this.service.getActivityLogs(data).pipe(
          switchMap((response: BaseOneResponseInterface<ActivityLogReportInterface>) => {
            response.data.rows = response.data.rows.map((activityLog: ActivityLogInterface) => {
              this.entityTranslatorService.translate(activityLog);
              this.service.setActivityLogProductInfo(activityLog);
              this.service.setActivityLogTaskName(activityLog);

              return {
                ...activityLog,
                isNotEditable: activityLog.end === 'Ongoing',
              };
            });
            return of(new ObjectActions.ActivityLogsDataLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes));
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes));
      }),
    ),
  );

  downloadActivityExcel = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ACTIVITY_LOGS_DOWNLOAD_EXCEL),
      switchMap((objectData: ObjectActions.ActivityLogsDownloadExcel) => {
        this.service.downloadExcel(
          HelperService.cloneDeep(objectData.params),
          HelperService.cloneDeep(objectData.worksheetsColumnDefinitions),
          objectData.fileDownloadType === 'csv',
        );
        return emptyAction;
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );
}
