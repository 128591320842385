import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

export interface GetFeedTokenInterface {
  apiKey: string;
  appId: string;
  feedtoken: string;
  feeduser: string;
  feedname: string;
}

export interface NotificationList {
  results: NotificationInterface[];
  next: string;
  duration: string;
  unseen: number;
  unread: number;
  id_lt?: string;
}

export interface NotificationInterface {
  activities: ActivityInterface[];
  activity_count: number;
  actor_count: number;
  created_at: string;
  group: string;
  id: string;
  is_read: boolean;
  is_seen: boolean;
  updated_at: string;
  verb: string;
  hrTime: string;
  verbText: string;
  scw_iconBgColor: string;
  scw_iconName: string;
  scw_iconColor: string;
  scw_iconType: string;
  hrText: string;
}

export interface ActivityInterface {
  actor: string;
  date: string;
  foreign_id: string;
  id: string;
  object: string;
  origin: string;
  target: string;
  time: string;
  verb: string;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(public http: HttpClient) {}

  public getFeedToken(): Observable<GetFeedTokenInterface> {
    return this.http.get<GetFeedTokenInterface>(environment.notificationFeedTokenUrl);
  }

  public getNotifications(justCount: boolean = false, nextId: string = null): Observable<NotificationList> {
    let params = new HttpParams();
    let limit: number = 10;

    if (justCount) {
      limit = 0;
    }

    if (nextId) {
      params = params.append('id_lt', nextId);
    }

    params = params.append('limit', String(limit));

    return this.http.get<NotificationList>(environment.notificationListUrl, {
      params,
    });
  }
}
