import * as Actions from './equipment-types.actions';
import { IEquipmentTypesState } from './equipment-types.model';

export const equipmentTypesState: IEquipmentTypesState = {
  totalEquipmentTypesDataCount: 0,
  isEquipmentTypesLoading: false,
  isEquipmentTypesLoaded: false,
  equipmentTypes: [],
};

export function equipmentTypesReducer(
  state: IEquipmentTypesState = equipmentTypesState,
  action: Actions.EquipmentTypesActions,
): IEquipmentTypesState {
  switch (action.type) {
    case Actions.LOAD_EQUIPMENT_TYPES_DATA:
      return {
        ...state,
        isEquipmentTypesLoading: true,
        isEquipmentTypesLoaded: false,
      };

    case Actions.EQUIPMENT_TYPES_DATA_LOADED:
      return {
        ...state,
        isEquipmentTypesLoading: false,
        isEquipmentTypesLoaded: true,
        equipmentTypes: action.payload.data,
        totalEquipmentTypesDataCount: action.payload.total,
      };

    default:
      return state;
  }
}
