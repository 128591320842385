import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import {
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { IOeeCalculationResultFormatted } from '../../../shared/model/interface/generic-api-response.model';
import {
  IKpiViewLaborHoursTrend,
  IKpiViewOeeAndGoodCountAverageTarget,
  IKpiViewOeeAndGoodCountTrend,
  IKpiViewOEEParameters,
  IKpiViewWidgetOeeData,
  IKpiViewWorkOrderCountAndTarget,
  IKpiViewWorkOrderTrend,
} from './kpi-view.model';
import { map } from 'rxjs/operators';
import moment from 'moment';
import { mysqlDateFormat } from '../../../shared/helper/date';

@Injectable({
  providedIn: 'root',
})
export class KpiViewService {
  constructor(public http: HttpClient, @Inject('API_BASE_URL') private readonly baseUrl: string) {}

  private readonly routes = {
    workOrderCountAndTarget: `${this.baseUrl}/lines/kpi-dashboard/scheduled-work-order-count-average-target`,
    workOrderCountTrend: `${this.baseUrl}/lines/kpi-dashboard/work-order-trend`,
    laborHours: `${this.baseUrl}/lines/kpi-dashboard/labor-hours-trend`,
    oeeTrend: `${this.baseUrl}/lines/kpi-dashboard/oee-trend`,
    oeeAndGoodCountAverageTargets: `${this.baseUrl}/lines/kpi-dashboard/oee-and-good-count-average-targets`,
    oee: `${this.baseUrl}/oee-calculation/calculate-oee`,
  };

  public getOeeData(body: IKpiViewOEEParameters): Observable<GetManyResponseInterface<IOeeCalculationResultFormatted>> {
    return this.http.post<GetManyResponseInterface<IOeeCalculationResultFormatted>>(this.routes.oee, body, {
      headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
    });
  }

  public getOeeAndTargetData(body: IKpiViewOEEParameters, params: HttpParams): Observable<IKpiViewWidgetOeeData> {
    const observables: {
      oeeAndGoodCountAverageTargets: Observable<BaseOneResponseInterface<IKpiViewOeeAndGoodCountAverageTarget>>;
      oeeData: Observable<GetManyResponseInterface<IOeeCalculationResultFormatted>>;
    } = {
      oeeData: this.getOeeData(this.formatOeeRequestBody(body)),
      oeeAndGoodCountAverageTargets: this.http.get<BaseOneResponseInterface<IKpiViewOeeAndGoodCountAverageTarget>>(
        this.routes.oeeAndGoodCountAverageTargets,
        {
          params,
        },
      ),
    };

    return forkJoin(observables).pipe(
      map(
        (response: {
          oeeAndGoodCountAverageTargets: BaseOneResponseInterface<IKpiViewOeeAndGoodCountAverageTarget>;
          oeeData: GetManyResponseInterface<IOeeCalculationResultFormatted>;
        }) => {
          return {
            ...response.oeeAndGoodCountAverageTargets.data,
            oeeData: response.oeeData.data?.[0],
          };
        },
      ),
    );
  }

  public getWorkOrderCountAndTarget(
    params: HttpParams,
  ): Observable<BaseOneResponseInterface<IKpiViewWorkOrderCountAndTarget>> {
    return this.http.get<BaseOneResponseInterface<IKpiViewWorkOrderCountAndTarget>>(
      this.routes.workOrderCountAndTarget,
      { params },
    );
  }

  public getLaborHours(params: HttpParams): Observable<BaseOneResponseInterface<IKpiViewLaborHoursTrend>> {
    return this.http.get<BaseOneResponseInterface<IKpiViewLaborHoursTrend>>(this.routes.laborHours, { params });
  }

  public getWorkOrderCountTrend(params: HttpParams): Observable<BaseOneResponseInterface<IKpiViewWorkOrderTrend[]>> {
    return this.http.get<BaseOneResponseInterface<IKpiViewWorkOrderTrend[]>>(this.routes.workOrderCountTrend, {
      params,
    });
  }

  public getOeeAndGoodCountTrend(
    params: HttpParams,
  ): Observable<BaseOneResponseInterface<IKpiViewOeeAndGoodCountTrend>> {
    return this.http.get<BaseOneResponseInterface<IKpiViewOeeAndGoodCountTrend>>(this.routes.oeeTrend, {
      params,
    });
  }

  private formatOeeRequestBody(body: IKpiViewOEEParameters): IKpiViewOEEParameters {
    return {
      ...body,
      endDate: moment(body.endDate)
        .format(mysqlDateFormat),
    };
  }
}
