import { Action } from '@ngrx/store';
import {
  DailyProductionPerformanceDataInterface,
  DailyProductionPerformanceFilterInterface,
} from './daily-production-performance.model';

export const DAILY_PRODUCTION_PERFORMANCE_SET_FILTER = '[DAILY PRODUCTION PERFORMANCE] SET FILTER';
export const DAILY_PRODUCTION_PERFORMANCE_DATA_LOADING = '[DAILY PRODUCTION PERFORMANCE] DATA LOADING';
export const DAILY_PRODUCTION_PERFORMANCE_DATA_LOADED = '[DAILY PRODUCTION PERFORMANCE] DATA LOADED';
export const DAILY_PRODUCTION_PERFORMANCE_FETCH_ERROR = '[DAILY PRODUCTION PERFORMANCE] FETCH ERROR';
export const DAILY_PRODUCTION_PERFORMANCE_CLEAR_STATE = '[DAILY PRODUCTION PERFORMANCE] CLEAR STATE';

export class DailyProductionPerformanceSetFilter implements Action {
  readonly type = DAILY_PRODUCTION_PERFORMANCE_SET_FILTER;

  constructor(public filter: DailyProductionPerformanceFilterInterface) {}
}

export class DailyProductionPerformanceDataLoading implements Action {
  readonly type = DAILY_PRODUCTION_PERFORMANCE_DATA_LOADING;

  constructor(public params: DailyProductionPerformanceFilterInterface) {}
}

export class DailyProductionPerformanceDataLoaded implements Action {
  readonly type = DAILY_PRODUCTION_PERFORMANCE_DATA_LOADED;

  constructor(public data: DailyProductionPerformanceDataInterface) {}
}

export class DailyProductionPerformanceFetchError implements Action {
  readonly type = DAILY_PRODUCTION_PERFORMANCE_FETCH_ERROR;

  constructor(public payload: any[]) {}
}

export class DailyProductionPerformanceClearState implements Action {
  readonly type = DAILY_PRODUCTION_PERFORMANCE_CLEAR_STATE;
}

export type DailyProductionPerformanceActions =
  | DailyProductionPerformanceDataLoading
  | DailyProductionPerformanceDataLoaded
  | DailyProductionPerformanceFetchError
  | DailyProductionPerformanceClearState;
