import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  CostFactorResponseInterface,
  CreateCostFactorInterface,
  EditCostFactorInterface,
  LineAvailabilityCostFactorDataInterface,
  LineAvailabilityPlanCostFactorInterface,
  SchedulerPlanCRUDResponseInterface,
  TableQueryParams,
} from '../../../store/line-availability/line-availability.model';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { BulkResponseDataInterface } from '../../model/interface/crud-response-interface.model';

@Injectable({
  providedIn: 'root',
})
export class CostFactorService {
  constructor(public http: HttpClient, @Inject('API_BASE_URL') private api: string) {}

  private routes = {
    costFactors: '/scheduler/cost-factors',
    bulkDelete: '/bulk/delete',
  };

  public getData(
    query: TableQueryParams = {
      page: 1,
      pageSize: 10,
    },
    siteIds: number[],
  ): Observable<LineAvailabilityPlanCostFactorInterface> {
    let searchString = '';
    const queryString: string[] = [`page=${query.page}`, `limit=${query.pageSize}`];

    if (siteIds.length > 0) {
      queryString.push(
        'join=schedulerCostFactorsSiteAssignment',
        `filter=schedulerCostFactorsSiteAssignment.siteId||$in||${siteIds.join(',')}`,
      );
    }

    if (query.hasOwnProperty('search') && query.search.length > 0) {
      const searchObj = {};
      _.set(searchObj, 'name.$cont', query.search);
      queryString.push(`s=${JSON.stringify(searchObj)}`);
    }

    queryString.forEach((params: string) => (searchString += `${params}&`));

    if (query.hasOwnProperty('orderDesc') && query.hasOwnProperty('orderBy')) {
      searchString += `sort=${query.orderBy},${query.orderDesc ? 'DESC' : 'ASC'}`;
    }

    return this.http.get<LineAvailabilityPlanCostFactorInterface>(
      `${this.api}${this.routes.costFactors}?${searchString}`,
    );
  }

  public editData(
    data: EditCostFactorInterface,
  ): Observable<SchedulerPlanCRUDResponseInterface<LineAvailabilityCostFactorDataInterface>> {
    return this.http.patch<SchedulerPlanCRUDResponseInterface<LineAvailabilityCostFactorDataInterface>>(
      `${this.api}${this.routes.costFactors}/${data.id}`,
      {
        schedulerCostFactorsSiteAssignments: data.schedulerCostFactorsSiteAssignment,
        name: data.name,
        factor: data.factor,
      },
    );
  }

  public createData(data: CreateCostFactorInterface): Observable<CostFactorResponseInterface> {
    const { name, factor, siteIds } = data;
    const schedulerCostFactorsSiteAssignments = [];

    for (const siteId of siteIds) {
      schedulerCostFactorsSiteAssignments.push({ siteId: Number(siteId) });
    }

    const requestParameters = {
      name,
      schedulerCostFactorsSiteAssignments,
      factor: factor,
    };

    return this.http.post<CostFactorResponseInterface>(`${this.api}${this.routes.costFactors}`, requestParameters);
  }

  public deleteData(id: number): Observable<LineAvailabilityPlanCostFactorInterface> {
    return this.http.delete<LineAvailabilityPlanCostFactorInterface>(`${this.api}${this.routes.costFactors}/${id}`);
  }

  public deleteCostFactors(ids: number[]): Observable<BulkResponseDataInterface> {
    return this.http.delete<BulkResponseDataInterface>(
      `${this.api}${this.routes.costFactors}${this.routes.bulkDelete}`,
      {
        body: { factors: ids },
      },
    );
  }
}
