import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IAllowedDomain, InterfaceCustomMailGroupInterface } from './main.model';
import { GetManyResponseInterface } from '../../shared/model/interface/crud-response-interface.model';

@Injectable({
  providedIn: 'root',
})
export class MainService {
  private readonly ROUTES = {
    GET_CUSTOM_MAIL_GROUPS: '/notifications/mail-groups',
    ALLOWED_DOMAINS: '/allowed-domains',
  };

  constructor(public http: HttpClient, @Inject('API_BASE_URL') private readonly baseUrl: string) {}

  public getCustomMailGroups(
    params: HttpParams,
  ): Observable<GetManyResponseInterface<InterfaceCustomMailGroupInterface>> {
    return this.http.get<GetManyResponseInterface<InterfaceCustomMailGroupInterface>>(
      `${this.baseUrl}${this.ROUTES.GET_CUSTOM_MAIL_GROUPS}`,
      {
        params,
      },
    );
  }

  public getAllowedDomains(params: HttpParams): Observable<GetManyResponseInterface<IAllowedDomain>> {
    return this.http.get<GetManyResponseInterface<IAllowedDomain>>(`${this.baseUrl}${this.ROUTES.ALLOWED_DOMAINS}`, {
      params,
    });
  }
}
