import * as Actions from './check-in.actions';
import { CheckInStateInterface } from './check-in.model';
const initialState: CheckInStateInterface = {
  checkInHomeDataLoading: false,
  checkInHomeDataLoaded: false,
  checkInLogDataLoading: false,
  checkInLogDataLoaded: false,
  checkInBulkEditLoading: false,
  checkInBulkEditLoaded: false,
  checkInBulkEditResponse: {
    data: [],
    success: false,
  },
  checkInLoading: false,
  checkInLoaded: false,
  checkInBulkLoading: false,
  checkInBulkLoaded: false,
  checkOutLoading: false,
  checkOutLoaded: false,
  checkInHomeData: { data: {} },
  checkInLogData: { data: {} },
  checkOutUpdateOngoingLoaded: false,
  checkOutUpdateOngoingLoading: false,
  checkInResponse: null,
  checkInBulkResponse: null,
  checkOutResponse: null,
  checkOutUpdateOngoingResponse: null,
};

export function checkInReducer(
  state: CheckInStateInterface = initialState,
  action: Actions.CheckInActions,
): CheckInStateInterface {
  switch (action.type) {
    case Actions.CHECK_IN_HOME_DATA_LOADING:
      return {
        ...state,
        checkInHomeDataLoading: true,
        checkInHomeDataLoaded: false,
      };
    case Actions.CHECK_IN_HOME_DATA_LOADED:
      return {
        ...state,
        checkInHomeDataLoading: false,
        checkInHomeDataLoaded: true,
        checkInHomeData: action.data,
      };
    case Actions.CHECK_IN_LOG_DATA_LOADING:
      return {
        ...state,
        ...{
          checkInLogDataLoading: true,
          checkInLogDataLoaded: false,
        },
      };
    case Actions.CHECK_IN_LOG_DATA_LOADED:
      return {
        ...state,
        checkInLogDataLoading: false,
        checkInLogDataLoaded: true,
        checkInLogData: action.data,
      };

    case Actions.CHECK_IN_DATA_LOADING:
      return {
        ...state,
        checkInLoading: true,
        checkInLoaded: false,
        checkInBulkLoading: false,
        checkInBulkLoaded: false,
        checkInResponse: null,
        checkInBulkResponse: null,
      };
    case Actions.CHECK_IN_DATA_LOADED:
      return {
        ...state,
        checkInLoading: false,
        checkInLoaded: true,
        checkInResponse: action.payload,
      };

    case Actions.CHECK_IN_BULK_DATA_LOADING:
      return {
        ...state,
        checkInBulkLoading: true,
        checkInBulkLoaded: false,
        checkInLoading: false,
        checkInLoaded: false,
        checkInBulkResponse: null,
      };
    case Actions.CHECK_IN_BULK_DATA_LOADED:
      return {
        ...state,
        checkInBulkLoading: false,
        checkInBulkLoaded: true,
        checkInBulkResponse: action.payload,
      };

    case Actions.CHECK_OUT_DATA_LOADING:
      return {
        ...state,
        checkOutLoading: true,
        checkOutLoaded: false,
        checkOutResponse: null,
      };
    case Actions.CHECK_OUT_DATA_LOADED:
      return {
        ...state,
        checkOutLoading: false,
        checkOutLoaded: true,
        checkOutResponse: action.payload,
      };
    case Actions.CHECK_IN_HOME_DATA_FETCH_ERROR:
      return {
        ...state,
        checkInHomeDataLoading: false,
        checkInHomeDataLoaded: true,
      };

    case Actions.CHECK_IN_LOG_DATA_FETCH_ERROR:
      return {
        ...state,
        checkInLogDataLoading: false,
        checkInLogDataLoaded: true,
      };

    case Actions.CHECK_IN_OPERATION_FETCH_ERROR:
      return {
        ...state,
        checkInLoading: false,
        checkInLoaded: true,
        checkInBulkLoading: false,
        checkInBulkLoaded: true,
        checkOutLoading: false,
        checkOutLoaded: true,
      };

    case Actions.CHECK_OUT_UPDATE_ONGOING_LOADING:
      return {
        ...state,
        ...{
          checkOutUpdateOngoingLoading: true,
          checkOutUpdateOngoingLoaded: false,
          checkOutUpdateOngoingResponse: null,
        },
      };

    case Actions.CHECK_OUT_UPDATE_ONGOING_LOADED:
      return {
        ...state,
        ...{
          checkOutUpdateOngoingLoading: false,
          checkOutUpdateOngoingLoaded: true,
          checkOutUpdateOngoingResponse: action.payload,
        },
      };

    case Actions.CHECK_IN_BULK_EDIT_CHECK_IN_LOADING:
    case Actions.CHECK_IN_BULK_CHECK_OUT_LOADING:
      return {
        ...state,
        ...{
          checkInBulkEditLoading: true,
          checkInBulkEditLoaded: false,
        },
      };

    case Actions.CHECK_IN_BULK_EDIT_CHECK_IN_LOADED:
    case Actions.CHECK_IN_BULK_CHECK_OUT_LOADED:
      return {
        ...state,
        ...{
          checkInBulkEditLoading: false,
          checkInBulkEditLoaded: true,
          checkInBulkEditResponse: action.payload,
        },
      };

    case Actions.RESET_STORE:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
