import { Component, Input, OnInit } from '@angular/core';
import { OfflineSensorStatusDataInterface } from '../../../store/offline-sensor-status/offline-sensor-status.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'offline-sensor-indicator',
  templateUrl: './offline-sensor-indicator.component.html',
  styleUrls: ['./offline-sensor-indicator.component.scss'],
})
export class OfflineSensorIndicatorComponent implements OnInit {
  @Input() data: OfflineSensorStatusDataInterface;

  public offlineIndicatorIconClass: string;
  public sensorTypeText: string = '';

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    switch (this.data.offlineStatus) {
      case 'offline':
        this.offlineIndicatorIconClass = 'fas fa-circle offline-sensor';
        break;
      case 'online':
        this.offlineIndicatorIconClass = 'fas fa-circle online-sensor';
        break;
      default:
        this.offlineIndicatorIconClass = 'fas fa-circle unknown-sensor';
        break;
    }

    switch (this.data.sensorType) {
      case 'good_counter':
        this.sensorTypeText = this.translate.instant('sensorTypes.sensor_type_good_counter');
        break;
      case 'total_counter':
        this.sensorTypeText = this.translate.instant('sensorTypes.sensor_type_total_counter');
        break;
      case 'vibration':
        this.sensorTypeText = this.translate.instant('sensorTypes.sensor_type_vibration');
        break;
      case 'voltage':
        this.sensorTypeText = this.translate.instant('sensorTypes.sensor_type_voltage');
        break;
      case 'card_reader':
        this.sensorTypeText = this.translate.instant('sensorTypes.sensor_type_card_reader');
        break;
      case 'mid_counter':
        this.sensorTypeText = this.translate.instant('sensorTypes.sensor_type_mid_counter');
        break;
      case 'sub_sensor':
        this.sensorTypeText = this.translate.instant('sensorTypes.sensor_type_sub_sensor');
        break;
      default:
        this.sensorTypeText = '';
        break;
    }
  }
}
