import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Subject } from 'rxjs';
import { MessageAction } from '../../model/enum/message-action';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../../store/oee.reducer';
import { take } from 'rxjs/operators';
import { User } from '../../../store/user/model';
import { Router } from '@angular/router';
import { SignalrBroadcastInterface } from './signalr.model';
import { ToastrService } from 'ngx-toastr';
import * as AppActions from '../../../store/app/actions';

@Injectable({
  providedIn: 'root',
})
export class SignalRService {
  public broadcastMessage: Subject<SignalrBroadcastInterface> = new Subject<SignalrBroadcastInterface>();
  public connectionIdSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  get hubConnection(): signalR.HubConnection {
    return this.signalRHubConnection;
  }
  private userId: number = null;

  private signalRHubConnection: signalR.HubConnection;

  constructor(
    private readonly http: HttpClient,
    private readonly store: Store<OeeAppState>,
    private readonly router: Router,
    private readonly toast: ToastrService,
  ) {
    this.buildConnection();
    this.listenBroadcastMessage();
    this.signalRHubConnection.onclose(this.startConnection);
    this.signalRHubConnection.onreconnected((connectionId: string) => {
      this.connectionIdSubject.next(connectionId);
      this.store
        .select('user')
        .pipe(take(1))
        .subscribe((state: User) => {
          this.addToGroup('page-refresh', state.clientCode);
          this.addToGroup('maintenance', state.clientCode);
          this.addToGroup('user', state.clientCode);
          this.userId = Number(state.userId);

          if (this.router.url === '/home') {
            this.addToGroup('line', state.lineId);
          }
        });
    });
  }

  public buildConnection(): void {
    this.signalRHubConnection = new signalR.HubConnectionBuilder()
      .withUrl(environment.signalRHubUrl)
      .configureLogging(signalR.LogLevel.None)
      .withAutomaticReconnect()
      .build();
  }

  public async startConnection(): Promise<void> {
    try {
      this.listenBroadcastMessage();
      await this.signalRHubConnection.start();
      this.connectionIdSubject.next(this.signalRHubConnection.connectionId);
      this.store
        .select('user')
        .pipe(take(1))
        .subscribe((state: User) => {
          this.addToGroup('page-refresh', state.clientCode);
          this.addToGroup('maintenance', state.clientCode);
          this.addToGroup('user', state.clientCode);
          this.userId = Number(state.userId);
        });
    } catch (err) {
      const threeSeconds: number = 3000;
      setTimeout(this.startConnection, threeSeconds);
    }
  }

  public addToGroup(objectType: string, objectId: number | string): void {
    if (this.signalRHubConnection.connectionId && environment.production) {
      this.http
        .get(
          `${environment.signalRHubUrl}/add-to-group/${objectType}/${objectId}/${this.signalRHubConnection.connectionId}`,
        )
        .subscribe(() => {});
    }
  }

  private listenBroadcastMessage(): void {
    this.signalRHubConnection.on('broadcastMessage', (message: number, additionalData: any) => {
      if (message === MessageAction.PAGE_RELOAD) {
        return window.location.reload();
      }

      if (message === MessageAction.TOAST_MESSAGE && additionalData.userId === this.userId) {
        this.toast.warning(additionalData.message, additionalData.title, {
          ...{
            closeButton: true,
            progressBar: false,
            disableTimeOut: true,
            tapToDismiss: true,
            positionClass: 'toast-bottom-right',
          },
          ...{ enableHtml: true },
        });
        return;
      }

      if (message === MessageAction.MAINTENANCE) {
        this.store.dispatch(
          new AppActions.SetMaintenanceModeData({
            ...additionalData,
          }),
        );

        return;
      }

      this.broadcastMessage.next({
        message,
        additionalData,
      });
    });
  }
}
