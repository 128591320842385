import { TabRowInterface } from '../../../shared/component/side-config-bar/side-config-bar.model';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { BulkResponseDataInterface, ResponseInterface } from '../../work-order-schedule/work-order-schedule.model';
import { ScwMatInputRule } from '../../../shared/component/scw-mat-ui/scw-mat-input/scw-mat-input.model';
import { GenericCrudRequestConstructionParameters } from '../../../shared/model/interface/generic-api-request.model';
import { DropdownOptionsInterface } from '../../../view/settings/tasks/tasks.model';

export interface CommentLogsStateInterface {
  isCommentLogsDataLoading: boolean;
  isCommentLogsDataLoaded: boolean;
  isCommentLogsDeleteRowsLoading: boolean;
  isCommentLogsDeleteRowsLoaded: boolean;
  isCommentLogRowForCommentFormLoading: boolean;
  isCommentLogRowForCommentFormLoaded: boolean;
  isCommentLogRowEditLoading: boolean;
  isCommentLogRowEditLoaded: boolean;
  commentLogsData: GetManyResponseInterface<ICommentLogsRawData>;
  commentLogData: IGetCommentLogRowResponse;
  filters: any;
  tableSettings: TabRowInterface[];
}

export interface ICommentLogsRawData {
  id: number;
  objectType: string;
  objectId: number;
  objectProperty: string;
  commentDate: string;
  commentMessage: string;
  commentTypeId: number;
  shiftDay: string;
  shiftId: number;
  createdAt: string;
  commentType: ICommentLogsCommentType;
  shift: ICommentLogsSchedulerShift;
  site: ICommentLogsSite;
  workOrder: ICommentLogsWorkOrder;
  user: ICommentLogsUser;
  editableFields?: string | string[];
}

export interface ICommentLogDataTable {
  siteName: string;
  workOrderName: string;
  commentType: string;
  commentTypeRaw: string;
  objectType: string;
  objectName: string;
  objectProperty: string;
  commentDate: string;
  commentMessage: string;
  commentMessageRaw: string;
  commentMessageFixedLength: string;
  shiftName: string;
  shiftDay: string;
  createdAt: string;
  createdBy: string;
  id: number;
  error?: string | string[];
  editableFields?: string | string[];
}

export interface ICommentLogsCommentType {
  category: string;
}

export interface ICommentLogsSite {
  name: string;
}

export interface ICommentLogsSchedulerShift {
  name: string;
}

export interface ICommentLogsWorkOrder {
  woNumber: string;
}

export interface ICommentLogsUser {
  fullName: string;
}

export interface ICommentLogsDeleteResponse {
  data?: BulkResponseDataInterface[];
  date: Date;
  success: boolean;
  message?: string;
}

export interface IGetCommentLogRowResponse extends ResponseInterface {
  data: ICommentLogsRawData;
}

export interface ICommentInformationFormSubmitInterface {
  commentMessage: {
    isEnabled: boolean;
    value: string;
    dropdownValue?: DropdownOptionsInterface[];
    commentTypeId?: number;
    rules?: ScwMatInputRule[];
  };
}

export interface ICommentLogsTableSortingParameters {
  siteName: string;
  workOrderName: string;
  shiftName: string;
}

export interface ICommentLogsTableFilteringParameters {
  siteId: string;
  workOrderId: string;
  objectType: string;
  commentType: string;
}

export interface ICommentLogsFilterCard extends GenericCrudRequestConstructionParameters {
  siteId?: number[];
  workOrderId?: number[];
  selectedObjectTypes?: string[];
  selectedCommentTypes?: string[];
}

export enum ECommentLogsObjectType {
  department = 'department',
  lines = 'lines',
  workOrderSchedule = 'work_order_schedule',
  productionCounts = 'production_counts',
}

export enum ECommentLogsObjectName {
  department = 'department',
  lines = 'line',
  production_counts = 'workOrder',
  work_order_schedule = 'workOrder',
}

export enum ECommentLogsScopeName {
  department = 'lineType',
  lines = 'title',
  production_counts = 'woNumber',
  work_order_schedule = 'woNumber',
}

export enum ECommentLogsCommentType {
  maintenanceComments = 'maintenance_comments',
  supervisorComments = 'supervisor_comments',
  operatorComments = 'operator_comments',
  productionCountComments = 'production_count_comments',
  shiftQuality = 'shift_quality',
  shiftGoalHit = 'shift_goal_hit',
  shiftDataQuality = 'shift_data_quality',
  shiftSafety = 'shift_safety',
  shiftEndStatusComments = 'shift_end_status_comments',
  shiftActionItemComments = 'shift_action_item_comments',
  nextShiftGoalComments = 'next_shift_goal_comments',
  shiftTopDownTimeActivity = 'shift_top_down_time_activity',
  shiftGoalComments = 'shift_goal_comments',
}
