import { Action } from '@ngrx/store';
import { ProductCRUDInterface } from '../../../shared/component/filter/filter.class';
import { HttpOptionInterface } from '../filter.model';

export enum IndependentFilterProductActionTypes {
  StartLoadIndependentFilterProducts =
    '[Independent Filter Product] Load Independent Filter Products',
  SetSelectedProductOptions =
    '[Independent Filter Product] Set Selected Product Options',
  IndependentFilterProductsLoaded =
    '[Independent Filter Product] Independent Filter Products Loaded',
  ResetIndependentProductStore =
    '[Independent Filter Product] Reset store data for independent product filter',
}

export class StartLoadIndependentFilterProducts implements Action {
  readonly type = IndependentFilterProductActionTypes.StartLoadIndependentFilterProducts;
  constructor(public options: HttpOptionInterface) {}
}
export class SetSelectedProductOptions implements Action {
  readonly type = IndependentFilterProductActionTypes.SetSelectedProductOptions;
  constructor(public selectedProducts: ProductCRUDInterface[]) {}
}
export class IndependentFilterProductsLoaded implements Action {
  readonly type = IndependentFilterProductActionTypes.IndependentFilterProductsLoaded;
  constructor(public data: ProductCRUDInterface[]) {}
}
export class ResetIndependentProductStore implements Action {
  readonly type = IndependentFilterProductActionTypes.ResetIndependentProductStore;
  constructor() {}
}

export type IndependentFilterProductActions =
  | StartLoadIndependentFilterProducts
  | SetSelectedProductOptions
  | IndependentFilterProductsLoaded
  | ResetIndependentProductStore;
