import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SiteCRUDInterface } from '../../../../shared/component/filter/filter.class';
import {
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../../shared/model/interface/crud-response-interface.model';
import { ISitesCrudResponse } from '../../../../store/settings/sites/sites.model';

@Injectable({
  providedIn: 'root',
})
export class SitesService {
  constructor(private readonly http: HttpClient, @Inject('API_BASE_URL') private readonly api: string) {}

  public getSites(params?: HttpParams): Observable<GetManyResponseInterface<SiteCRUDInterface>> {
    return this.http.get<GetManyResponseInterface<SiteCRUDInterface>>(`${this.api}/sites`, {
      params,
    });
  }

  public patchSiteData(
    siteId: number,
    payload: Partial<SiteCRUDInterface>,
    params?: HttpParams,
  ): Observable<ISitesCrudResponse> {
    return this.http.patch<ISitesCrudResponse>(`${this.api}/sites/${siteId}`, payload, { params });
  }

  public patchSitesData(
    payload: Partial<SiteCRUDInterface>[],
    params?: HttpParams,
  ): Observable<BulkResponseDataInterface> {
    return this.http.patch<BulkResponseDataInterface>(`${this.api}/sites/bulk/edit`, { sites: payload }, { params });
  }

  public createSite(payload: Partial<SiteCRUDInterface>): Observable<ISitesCrudResponse> {
    return this.http.post<ISitesCrudResponse>(`${this.api}/sites`, payload);
  }

  public deleteSite(siteId: number): Observable<ISitesCrudResponse> {
    return this.http.delete<ISitesCrudResponse>(`${this.api}/sites/${siteId}`);
  }

  public deleteSites(siteIds: number[]): Observable<BulkResponseDataInterface> {
    return this.http.delete<BulkResponseDataInterface>(`${this.api}/sites/bulk/delete`, {
      body: { sites: siteIds },
    });
  }
}
