import { Action } from '@ngrx/store';
import {
  GetDistinctCheckInObjectsInterfaceData,
} from '../../check-in/check-in.model';

export enum FilterSourceObjectActionTypes {
  StartLoadFilterSourceObjects = '[Filter Source Object] Load Filter SourceObjects',
  FilterSourceObjectsLoaded = '[Filter Source Object] Filter SourceObjects Loaded',
}

export class StartLoadFilterSourceObjects implements Action {
  readonly type = FilterSourceObjectActionTypes.StartLoadFilterSourceObjects;
  constructor(public sourceObjectType: number, public lineId?: number) {}
}
export class FilterSourceObjectsLoaded implements Action {
  readonly type = FilterSourceObjectActionTypes.FilterSourceObjectsLoaded;
  constructor(public data: GetDistinctCheckInObjectsInterfaceData[]) {}
}

export type FilterSourceObjectActions = StartLoadFilterSourceObjects | FilterSourceObjectsLoaded;
