import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {
  CellTypes,
  CreateExcelInterface,
  CreateExcelSheetInterface,
  EDependentBehavior,
  ExcelColumnWidthEnum,
  ExcelHelperService,
  ExcelSheetTypeEnum,
  IDataWithExcelId,
  twoDigitNumberFormat,
} from '../../../shared/service/excel/excel-helper.service';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import { TranslateService } from '@ngx-translate/core';
import {
  BaseCrudResponse,
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { forkJoin, Observable, Subject } from 'rxjs';
import * as moment from 'moment-timezone';
import { excelDateFormat, excelTimeFormat } from '../../../shared/model/enum/excel-date-format';
import * as _ from 'lodash';
import { ValueType, Workbook, Worksheet } from 'exceljs';
import * as ObjectActions from './tasks.actions';
import { ECommonLanguageKeys, StatusesEnum, TrueFalse, YesNoLanguageKeys } from '../../../../constants';
import {
  AddTaskInterface,
  BulkEditTaskInterface,
  CheckboxDropdownsInterface,
  EditTaskInterface,
  ExcelDropdownDataWithDescription,
  ExcelDropdownDataWithDescriptionFormatted,
  SimplifiedDataInterface,
  SimplifiedEquipmentInterface,
  SimplifiedLineInterface,
  SimplifiedTaskInterface,
  TaskCRUDInterface,
  TaskDataForExcelInterface,
  TaskDeleteResponseInterface,
  TaskExcelDataInterface,
  TasksDownloadExcelFiltersInterface,
  TasksExcelDropdownContentInterface,
} from './tasks.model';
import { ActivityTypes } from '../../../shared/model/enum/activity-types';
import { takeUntil } from 'rxjs/operators';
import { TaskBulkOperationResponseInterface, TaskQueryParams } from '../../../view/settings/tasks/tasks.model';
import { HelperService } from '../../../shared/service/helper.service';
import { EquipmentListService } from '../../../shared/service/equipment-list/equipment-list.service';
import { ActivitiesService } from '../../../shared/service/activities/activities.service';
import { LineService } from '../../../shared/service/line/line.service';
import { SitesService } from '../../../shared/service/settings/sites/sites.service';
import { RootCauseGroupService } from '../../../shared/service/settings/root-cause-group/root-cause-group.service';
import { LineCRUDInterface, SiteCRUDInterface } from '../../../shared/component/filter/filter.class';
import { ActivitiesInterface } from '../../../shared/model/interface/activities.model';
import { EquipmentListInterface } from '../equipment-lists/equipment-lists.model';
import { RootCauseGroupGetOneInterface } from '../root-cause-group/root-cause-group.model';
import { IEquipmentEquipmentAssignmentJoin } from '../equipments/equipments.model';
import { DecimalHelper } from '../../../shared/helper/decimal/decimal-helper';
import { ResponseArrayInterface } from '../../../shared/model/interface/generic-api-response.model';
import { ETreeRootType, ITreeNode } from '../tree-nodes/tree-nodes.model';
import { PhaseOptionNames } from '../../../shared/model/enum/phase-options';
import { IExcelDropDown } from '../../../shared/model/interface/generic.model';

@Injectable({
  providedIn: 'root',
})
export class TasksService {
  private readonly urls = {
    TASK_URL: `${this.baseUrl}/tasks`,
    BULK_SAVE_URL: `${this.baseUrl}/tasks/bulk/save`,
    BULK_DELETE_URL: `${this.baseUrl}/tasks/bulk/delete`,
    BULK_EDIT_URL: `${this.baseUrl}/tasks/bulk/edit`,
    TREE_NODES_URL: `${this.baseUrl}/tree-nodes`,
  };
  private timezone: string = 'utc';
  private dateFormat$: string;
  private timeFormat$: string;
  private readonly destroySubject: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly http: HttpClient,
    @Inject('API_BASE_URL')
    private readonly baseUrl: string,
    private readonly excelHelper: ExcelHelperService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly translate: TranslateService,
    private readonly helperService: HelperService,
    private readonly equipmentService: EquipmentListService,
    private readonly activitiesService: ActivitiesService,
    private readonly lineService: LineService,
    private readonly siteService: SitesService,
    private readonly rootCauseGroupService: RootCauseGroupService,
    private readonly decimalHelper: DecimalHelper,
  ) {
    this.store
      .select('user')
      .pipe(takeUntil(this.destroySubject))
      .subscribe((state) => {
        if (state.isUserLoaded) {
          this.timezone = state.timezone;
          if (state.locale !== '') {
            this.dateFormat$ = excelDateFormat[state.locale];
            this.timeFormat$ = excelTimeFormat[state.locale];
          }
          this.destroySubject.next(true);
          this.destroySubject.complete();
        }
      });
  }

  public getTasks(params: HttpParams): Observable<GetManyResponseInterface<TaskCRUDInterface>> {
    return this.http.get<GetManyResponseInterface<TaskCRUDInterface>>(this.urls.TASK_URL, { params });
  }

  public getTasksForDataTable(data: any, params?: HttpParams): Observable<GetManyResponseInterface<TaskCRUDInterface>> {
    return this.http.post<GetManyResponseInterface<TaskCRUDInterface>>(this.urls.TASK_URL, data, {
      ...(params ? { params } : {}),
      headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
    });
  }

  public getTreeNodes(): Observable<ResponseArrayInterface<ITreeNode>> {
    const params: HttpParams = new HttpParams().append('rootType', ETreeRootType.TASK_TREE);
    return this.http.get<ResponseArrayInterface<ITreeNode>>(this.urls.TREE_NODES_URL, { params });
  }

  public downloadExcel(
    withData: boolean,
    filters: TasksDownloadExcelFiltersInterface,
    tasksQueryData: any,
    withErrorColumn: boolean = false,
    data?: (TaskCRUDInterface | SimplifiedTaskInterface)[],
  ): void {
    const activityParams: HttpParams = new HttpParams()
      .set('s', JSON.stringify({ activityType: { $ne: ActivityTypes.RUN_TIME } }))
      .set('limit', filters.limit);

    let siteParams: HttpParams = new HttpParams().set('fields', 'id,name').set('limit', filters.limit);
    let lineParams: HttpParams = new HttpParams()
      .set('fields', 'id,title,siteId,activityIds')
      .set('limit', filters.limit);
    let equipmentParams: HttpParams = new HttpParams()
      .set('fields', 'id,equipmentName,equipmentAssignment')
      .set('join', 'equipmentAssignment')
      .set('limit', filters.limit);
    let rootCauseGroupParams = new HttpParams().set('fields', 'id,name,siteId').set('limit', filters.limit);

    if (filters.siteIds !== -1) {
      siteParams = siteParams.set('s', JSON.stringify({ id: { $in: filters.siteIds } }));
      lineParams = lineParams.set('s', JSON.stringify({ siteId: { $in: filters.siteIds } }));
      equipmentParams = equipmentParams.set('s', JSON.stringify({ siteId: { $in: filters.siteIds } }));
      rootCauseGroupParams = rootCauseGroupParams.set('s', JSON.stringify({ siteId: { $in: filters.siteIds } }));
    }

    const observables: [
      Observable<GetManyResponseInterface<Pick<LineCRUDInterface, 'id' | 'title' | 'siteId' | 'activityIds'>>>,
      Observable<GetManyResponseInterface<Pick<SiteCRUDInterface, 'id' | 'name'>>>,
      Observable<GetManyResponseInterface<ActivitiesInterface>>,
      Observable<
        GetManyResponseInterface<
          Pick<
            EquipmentListInterface & IEquipmentEquipmentAssignmentJoin,
            'id' | 'equipmentName' | 'equipmentAssignment'
            >
          >
        >,
      Observable<GetManyResponseInterface<RootCauseGroupGetOneInterface>>,
      Observable<ResponseArrayInterface<ITreeNode>>,
      Observable<GetManyResponseInterface<Partial<TaskCRUDInterface>>>?,
    ] = [
      this.lineService.getLines(lineParams),
      this.siteService.getSites(siteParams),
      this.activitiesService.getActivities(activityParams),
      this.equipmentService.getEquipmentLists(equipmentParams),
      this.rootCauseGroupService.getRootCauseGroups(rootCauseGroupParams),
      this.getTreeNodes(),
    ];

    if (withData && !data) {
      const tasksHttpParams: HttpParams = new HttpParams().set('matchingLineAndActivityId', true);

      observables.push(
        this.getTasksForDataTable(
          { ...tasksQueryData, per_page: filters.limit, page: filters.selectedDownloadOffset },
          tasksHttpParams,
        ),
      );
    }

    forkJoin(observables).subscribe((responseList) => {
      const lines: Pick<LineCRUDInterface, 'id' | 'title' | 'siteId' | 'activityIds'>[] = _.get(
        responseList,
        '0.data',
        [],
      ).sort(this.helperService.dropdownOptionCompareFunction);
      const sites: Pick<SiteCRUDInterface, 'id' | 'name'>[] = _.get(responseList, '1.data', []);
      const activities: ActivitiesInterface[] = _.get(responseList, '2.data', []);
      const equipments: Pick<
        EquipmentListInterface & IEquipmentEquipmentAssignmentJoin,
        'id' | 'equipmentName' | 'equipmentAssignment'
        >[] = _.get(responseList, '3.data', []);
      const rootCauseGroups: RootCauseGroupGetOneInterface[] = _.get(responseList, '4.data', []);
      const taskFolders: ITreeNode[] = _.get(responseList, '5.data', []);
      const statuses: ExcelDropdownDataWithDescription[] = [
        { id: StatusesEnum.ACTIVE, description: this.translate.instant(ECommonLanguageKeys.ACTIVE) },
        { id: StatusesEnum.INACTIVE, description: this.translate.instant(ECommonLanguageKeys.INACTIVE) },
      ];

      const checkboxFieldDropdown: IExcelDropDown<string>[] = [
        { id: TrueFalse['1'], description: this.translate.instant(YesNoLanguageKeys.YES) },
        { id: TrueFalse['0'], description: this.translate.instant(YesNoLanguageKeys.NO) },
      ];
      const sheetTitle: string = this.translate.instant('excel.items.tasks');
      const excelName: string = `${sheetTitle} ${moment().tz(this.timezone).format(this.dateFormat$)}`;

      let equipmentsWithExcelId: (Pick<
        EquipmentListInterface & IEquipmentEquipmentAssignmentJoin,
        'id' | 'equipmentName' | 'equipmentAssignment'
      > &
        IDataWithExcelId)[] = ExcelHelperService.generateDependentOptions(
        {
          data: equipments,
          key: 'id',
          label: 'equipmentName',
        },
        {
          data: lines,
          key: 'id',
          label: 'title',
        },
      );
      equipmentsWithExcelId = equipmentsWithExcelId.filter((equipment) => {
        return equipment.equipmentAssignment.some(
          (assignment) => String(assignment.lineId) === equipment.excelId.split('-')[0],
        );
      });

      const activitiesWithExcelId: SimplifiedDataInterface[] = ExcelHelperService.generateDependentOptions(
        {
          data: activities,
          key: 'id',
          label: 'name',
        },
        {
          data: lines,
          key: 'id',
          label: 'title',
          relatesToField: 'activityIds',
        },
      );

      const linesWithExcelId: Pick<
        LineCRUDInterface,
        'id' | 'title' | 'siteId' | 'activityIds'
      >[] = ExcelHelperService.generateDependentOptions(
        { data: lines, key: 'siteId', label: 'title' },
        { data: sites, key: 'id', label: 'name' },
        EDependentBehavior.MAP,
      );

      const rootCauseGroupsWithExcelId: RootCauseGroupGetOneInterface[] = ExcelHelperService.generateDependentOptions(
        { data: rootCauseGroups, key: 'siteId', label: 'name' },
        { data: sites, key: 'id', label: 'name' },
        EDependentBehavior.MAP,
      );

      const formattedTaskFolders: SimplifiedDataInterface[] = this.generateTaskFolderOptions(
        taskFolders,
        lines,
        activities,
      );

      const taskPhases: SimplifiedDataInterface[] = [
        { id: PhaseOptionNames.preRun, name: this.translate.instant('tasks.taskPhase.preRun') },
        { id: PhaseOptionNames.postRun, name: this.translate.instant('tasks.taskPhase.postRun') },
      ];

      const content: TasksExcelDropdownContentInterface = {
        sites,
        statuses,
        lines: linesWithExcelId,
        rootCauseGroups: rootCauseGroupsWithExcelId,
        taskFolders: formattedTaskFolders,
        trueFalse: checkboxFieldDropdown,
        activities: activitiesWithExcelId,
        equipments: equipmentsWithExcelId,
        phases: taskPhases,
      };

      let excelData: TaskDataForExcelInterface[] = [];

      if (withData) {
        excelData = !data
          ? _.get(responseList, '6.data', []).map((task: SimplifiedTaskInterface) =>
              this.getExcelFormattedData(task, checkboxFieldDropdown, content),
            )
          : data.map((task: SimplifiedTaskInterface) =>
              this.getExcelFormattedData(task, checkboxFieldDropdown, content),
            );
      }

      const excelOptions: CreateExcelInterface = this.getExcelColumns(content, withErrorColumn);

      if (withData) {
        excelOptions.data = excelData;
      }

      const worksheets: CreateExcelSheetInterface[] = [
        {
          withData,
          sheetTitle,
          sheetType: ExcelSheetTypeEnum.TABLE,
          params: excelOptions,
          isDisabledColumnsFirstLine: true,
          excelRowFormatLimit: 5001,
        },
      ];

      this.excelHelper
        .createExcel(excelName, worksheets, this.timezone, this.dateFormat$, this.timeFormat$, false)
        .then(
          () => {
            this.store.dispatch(new ObjectActions.DownloadExcelCompleted());
          },
          () => {
            this.store.dispatch(new ObjectActions.FetchError({}));
          },
        );
    });
  }

  private getExcelColumns(content: TasksExcelDropdownContentInterface, withErrorColumn: boolean): CreateExcelInterface {
    const numberInputTitle: string = 'general.excel.column.promptNumberInputTitle';
    const decimalInputPrompt: string = 'general.excel.column.promptDecimalInput';

    const excelColumns: CreateExcelInterface = {
      columns: [
        {
          header: this.translate.instant('excel.headers.siteName'),
          key: 'siteId',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: content.sites,
            prop: 'name',
            dataProperty: 'site.name',
            dataId: 'site.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('excel.headers.line'),
          key: 'lineId',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: content.lines,
            prop: 'excelLabel',
            dataProperty: 'line.excelLabel',
            dataId: 'line.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('excel.headers.activity'),
          key: 'activityId',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: content.activities,
            prop: 'excelLabel',
            dataProperty: 'activity.excelLabel',
            dataId: 'activity.excelId',
            primaryKeyColumnWidth: 15,
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('excel.headers.task'),
          key: 'title',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          dataValidation: {
            type: CellTypes.CUSTOM,
          },
          isRequired: true,
        },
        {
          header: 'id',
          key: 'id',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          dataValidation: {
            type: CellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('excel.headers.status'),
          key: 'statusId',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: content.statuses,
            prop: 'description',
            dataProperty: 'statusDropdown.description',
            dataId: 'statusDropdown.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('excel.headers.order'),
          key: 'order',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.Number,
          style: { numFmt: '0' },
          maxLength: 11,
          allowPunctuation: true,
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            formulae: [],
            errorStyle: 'Error',
            showInputMessage: true,
            promptTitle: this.translate.instant('general.excel.column.promptNumberInputTitle', {
              field: this.translate.instant('general.excel.column.order'),
            }),
            prompt: this.translate.instant('general.excel.column.promptNumberInput', {
              field: this.translate.instant('general.excel.column.order'),
            }),
          },
        },
        {
          header: this.translate.instant('tasks.taskPhase.label'),
          key: 'phaseId',
          width: 20,
          type: ValueType.String,
          dropdownOptions: {
            data: content.phases,
            prop: 'name',
            dataProperty: 'phase.name',
            dataId: 'phase.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
        },
        {
          header: this.translate.instant('excel.headers.equipment'),
          key: 'equipmentId',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: content.equipments,
            prop: 'excelLabel',
            dataProperty: 'equipment.excelLabel',
            dataId: 'equipment.excelId',
            primaryKeyColumnWidth: 15,
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
        },
        {
          header: this.translate.instant('excel.headers.rootCauseGroup'),
          key: 'rootCauseGroupId',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: content.rootCauseGroups,
            prop: 'excelLabel',
            dataProperty: 'rootCauseGroup.excelLabel',
            dataId: 'rootCauseGroup.id',
            primaryKeyColumnWidth: 20,
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
        },
        {
          header: this.translate.instant('task.taskInformationForm.taskTreeParentId.label'),
          key: 'taskTreeParentId',
          width: 20,
          type: ValueType.String,
          dropdownOptions: {
            data: content.taskFolders,
            prop: 'taskTreeParentId',
            dataProperty: 'treeNode.name',
            dataId: 'treeNode.id',
            primaryKeyColumnWidth: 20,
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
        },
        {
          header: this.translate.instant('excel.headers.target'),
          key: 'target',
          width: ExcelColumnWidthEnum.DECIMAL,
          type: ValueType.Number,
          style: { numFmt: '0.000000000000000###############' },
          allowPunctuation: true,
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            formulae: [],
            errorStyle: 'Error',
            showInputMessage: true,
            promptTitle: this.translate.instant(numberInputTitle, {
              field: this.translate.instant('excel.headers.target'),
            }),
            prompt: this.translate.instant(decimalInputPrompt, {
              field: this.translate.instant('excel.headers.target'),
            }),
          },
          isDecimalNumber: true,
        },
        {
          header: this.translate.instant('excel.headers.lcl'),
          key: 'lcl',
          width: ExcelColumnWidthEnum.DECIMAL,
          type: ValueType.Number,
          style: { numFmt: '0.000000000000000###############' },
          allowPunctuation: true,
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            formulae: [],
            errorStyle: 'Error',
            showInputMessage: true,
            promptTitle: this.translate.instant(numberInputTitle, {
              field: this.translate.instant('excel.headers.lcl'),
            }),
            prompt: this.translate.instant(decimalInputPrompt, {
              field: this.translate.instant('excel.headers.lcl'),
            }),
          },
          isRequired: true,
          isDecimalNumber: true,
        },
        {
          header: this.translate.instant('excel.headers.ucl'),
          key: 'ucl',
          width: ExcelColumnWidthEnum.DECIMAL,
          type: ValueType.Number,
          style: { numFmt: '0.000000000000000###############' },
          allowPunctuation: true,
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            formulae: [],
            errorStyle: 'Error',
            showInputMessage: true,
            promptTitle: this.translate.instant(numberInputTitle, {
              field: this.translate.instant('excel.headers.ucl'),
            }),
            prompt: this.translate.instant(decimalInputPrompt, {
              field: this.translate.instant('excel.headers.ucl'),
            }),
          },
          isRequired: true,
          isDecimalNumber: true,
        },
        {
          header: this.translate.instant('excel.headers.batchFinalizer'),
          key: 'batchFinalizer',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: content.trueFalse,
            prop: 'description',
            dataProperty: 'batchFinalizerDropdown.description',
            dataId: 'batchFinalizerDropdown.id',
            primaryKeyColumnWidth: 25,
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('excel.headers.ignoreSensor'),
          key: 'ignoreSensor',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: content.trueFalse,
            prop: 'description',
            dataProperty: 'ignoreSensorDropdown.description',
            dataId: 'ignoreSensorDropdown.id',
            primaryKeyColumnWidth: 30,
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('excel.headers.ignorePhase'),
          key: 'ignorePhase',
          width: ExcelColumnWidthEnum.PHASE,
          type: ValueType.String,
          dropdownOptions: {
            data: content.trueFalse,
            prop: 'description',
            dataProperty: 'ignorePhaseDropdown.description',
            dataId: 'ignorePhaseDropdown.id',
            primaryKeyColumnWidth: 33,
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('excel.headers.isMissingData'),
          key: 'isMissingData',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: content.trueFalse,
            prop: 'description',
            dataProperty: 'isMissingDataDropdown.description',
            dataId: 'isMissingDataDropdown.id',
            primaryKeyColumnWidth: 28,
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('excel.headers.isCommentRequired'),
          key: 'isCommentRequired',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: content.trueFalse,
            prop: 'description',
            dataProperty: 'isCommentRequiredDropdown.description',
            dataId: 'isCommentRequiredDropdown.id',
            primaryKeyColumnWidth: 40,
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('excel.headers.withoutWorkOrder'),
          key: 'withoutWorkOrder',
          width: 20,
          type: ValueType.String,
          dropdownOptions: {
            data: content.trueFalse,
            prop: 'description',
            dataProperty: 'withoutWorkOrderDropdown.description',
            dataId: 'withoutWorkOrderDropdown.id',
            primaryKeyColumnWidth: 40,
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('excel.headers.description'),
          key: 'description',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          dataValidation: {
            type: CellTypes.CUSTOM,
          },
        },
      ],
    };

    this.excelHelper.prepareExcelColumns(excelColumns.columns, withErrorColumn);

    return excelColumns;
  }

  public async getTasksFromExcel(
    file: File,
  ): Promise<{ siteData: SimplifiedDataInterface[]; taskData: { tasks: TaskCRUDInterface[] } } | null> {
    const workbook: Workbook = await this.excelHelper.getExcelWorkBookFromFile(file);
    const taskSheet: Worksheet = workbook.getWorksheet(this.translate.instant('excel.items.tasks'));
    const siteIdDataSheet: Worksheet = workbook.getWorksheet('siteIdDataSheet');
    const dataSheetsToBeValidated = [
      taskSheet,
      siteIdDataSheet,
      workbook.getWorksheet('lineIdDataSheet'),
      workbook.getWorksheet('equipmentIdDataSheet'),
      workbook.getWorksheet('activityIdDataSheet'),
      workbook.getWorksheet('statusIdDataSheet'),
      workbook.getWorksheet('batchFinalizerDataSheet'),
      workbook.getWorksheet('ignorePhaseDataSheet'),
      workbook.getWorksheet('ignoreSensorDataSheet'),
      workbook.getWorksheet('isMissingDataDataSheet'),
      workbook.getWorksheet('isCommentRequiredDataSheet'),
      workbook.getWorksheet('withoutWorkOrderDataSheet'),
    ];

    if (!dataSheetsToBeValidated.every((dataSheet) => dataSheet)) {
      return null;
    }

    const siteColumns = {
      id: {
        key: 'id',
        type: ValueType.String,
        dataValidationType: CellTypes.CUSTOM,
      },
      name: {
        key: 'name',
        type: ValueType.String,
        dataValidationType: CellTypes.CUSTOM,
      },
    };

    const sites: SimplifiedDataInterface[] = this.excelHelper.getExcelRowsFromWorkSheet<SimplifiedDataInterface>(
      siteIdDataSheet,
      siteColumns,
    );

    if (!sites.length) {
      return null;
    }

    const { columns } = this.getExcelColumns(
      {
        sites: null,
        lines: [],
        activities: [],
        equipments: [],
        rootCauseGroups: [],
        statuses: [],
        trueFalse: [],
        taskFolders: [],
        phases: [],
      },
      false,
    );
    const columnKeys = this.excelHelper.getSheetColumnKeys(columns);
    const excelTasks: TaskExcelDataInterface[] = this.excelHelper.getExcelRowsFromWorkSheet<TaskExcelDataInterface>(
      taskSheet,
      columnKeys,
      {
        dateFormat: this.dateFormat$,
        timeFormat: this.timeFormat$,
        timezone: this.timezone,
      },
    );

    const taskRequestData: TaskCRUDInterface[] = excelTasks.map((task: TaskExcelDataInterface) => {
      const equipmentId: number = !task.equipmentId ? null : Number(task.equipmentId.split('-')[1]);
      const activityId: number = !task.activityId ? null : Number(task.activityId.split('-')[1]);

      return {
        ...task,
        equipmentId,
        activityId,
        batchFinalizer: task.batchFinalizer !== null ?  Boolean(TrueFalse[task.batchFinalizer]) : null,
        isMissingData: task.isMissingData !== null ? Boolean(TrueFalse[task.isMissingData]) : null,
        isCommentRequired: task.isCommentRequired !== null ?  Boolean(TrueFalse[task.isCommentRequired]) : null,
        withoutWorkOrder: task.withoutWorkOrder !== null ? Boolean(TrueFalse[task.withoutWorkOrder]) : null,
        ignoreSensor: task.ignoreSensor !== null ? Boolean(TrueFalse[task.ignoreSensor]) : null,
        ignorePhase: task.ignorePhase !== null ? Boolean(TrueFalse[task.ignorePhase]) : null,
        target: task.target ? task.target : null,
        statusId: task.statusId,
      };
    });

    return {
      taskData: {
        tasks: taskRequestData,
      },
      siteData: sites,
    };
  }

  public uploadExcel(tasks: TaskCRUDInterface[]): Observable<BulkResponseDataInterface> {
    return this.http.post<BulkResponseDataInterface>(this.urls.BULK_SAVE_URL, { tasks });
  }

  private getExcelFormattedData(
    task: SimplifiedTaskInterface,
    checkboxFieldDropdown: IExcelDropDown<string>[],
    content: TasksExcelDropdownContentInterface,
  ): TaskDataForExcelInterface {
    const line: LineCRUDInterface = _.find(content.lines, { id: task.lineId });
    const site: SiteCRUDInterface = _.find(content.sites, { id: task.siteId });
    const selectedEquipment: SimplifiedEquipmentInterface = _.find(content.equipments, { id: task.equipmentId });
    let equipment = null;

    if (
      selectedEquipment &&
      selectedEquipment.equipmentAssignment.some((assignment) => assignment.lineId === task.lineId)
    ) {
      equipment = {
        ...selectedEquipment,
        ...ExcelHelperService.generateDependentOptionProperties(
          selectedEquipment.id,
          selectedEquipment.equipmentName,
          line.id,
          line.title,
        ),
      };
    }

    const selectedActivity: ActivitiesInterface =
      line !== undefined && line.activityIds.split(',').includes(String(task.activityId))
        ? _.find(content.activities, { id: task.activityId })
        : null;
    let activity = null;

    if (selectedActivity) {
      activity = {
        ...selectedActivity,
        ...ExcelHelperService.generateDependentOptionProperties(
          task.activityId,
          selectedActivity.name,
          line.id,
          line.title,
        ),
      };
    }

    return {
      ...task,
      ...this.getCheckboxDropdowns(task, checkboxFieldDropdown),
      line,
      activity,
      equipment,
      site,
      treeNode:  _.find(content.taskFolders, { id: task.taskTreeParentId }),
      rootCauseGroup: _.find(content.rootCauseGroups, { id: task.rootCauseGroupId }),
      activityId: `${task.lineId}-${task.activityId}`,
      equipmentId: `${task.lineId}-${task.equipmentId}`,
      statusDropdown: _.find(content.statuses, { id: task.statusId }),
      target: task.target ? this.decimalHelper.removeTrailingZeros(task.target) : null,
      ucl: task.ucl ? this.decimalHelper.removeTrailingZeros(task.ucl) : null,
      lcl: task.lcl ? this.decimalHelper.removeTrailingZeros(task.lcl) : null,
      isMissingData: task.isMissingData !== null ? HelperService.numberToBooleanString(task.isMissingData) : null,
      isCommentRequired: task.isCommentRequired !== null ? TrueFalse[Number(task.isCommentRequired)] : null,
      batchFinalizer: task.batchFinalizer !== null ? HelperService.numberToBooleanString(task.batchFinalizer) : null,
      ignoreSensor: task.ignoreSensor !== null ? HelperService.numberToBooleanString(task.ignoreSensor) : null,
      ignorePhase: task.ignorePhase !== null ? HelperService.numberToBooleanString(task.ignorePhase) : null,
      phase: _.find(content.phases, { id: task.phaseId }),
      meta: task.meta,
    };
  }

  private getCheckboxDropdowns(
    task: SimplifiedTaskInterface,
    checkboxFieldDropdown: IExcelDropDown<string>[],
  ): CheckboxDropdownsInterface {
    const missingData =
      task.isMissingData !== null ? _.find(checkboxFieldDropdown, { id: TrueFalse[Number(task.isMissingData)] }) : null;
    const commentRequiredData =
      task.isCommentRequired !== null
        ? _.find(checkboxFieldDropdown, { id: TrueFalse[Number(task.isCommentRequired)] })
        : null;
    const batchFinalizer =
      task.batchFinalizer !== null
        ? _.find(checkboxFieldDropdown, { id: TrueFalse[Number(task.batchFinalizer)] })
        : null;
    const ignoreSensor =
      task.ignoreSensor !== null ? _.find(checkboxFieldDropdown, { id: TrueFalse[Number(task.ignoreSensor)] }) : null;
    const ignorePhase =
      task.ignorePhase !== null ? _.find(checkboxFieldDropdown, { id: TrueFalse[Number(task.ignorePhase)] }) : null;

    const withoutWorkOrderData =
      task.meta.withoutWorkOrder !== null
        ? _.find(checkboxFieldDropdown, { id: TrueFalse[Number(task.meta.withoutWorkOrder)] })
        : null;

    return {
      isMissingDataDropdown: {
        ...missingData,
        id: missingData === null ? null : TrueFalse[missingData.id],
      },
      isCommentRequiredDropdown: {
        ...commentRequiredData,
        id: commentRequiredData === null ? null : TrueFalse[commentRequiredData.id],
      },
      batchFinalizerDropdown: {
        ...batchFinalizer,
        id: batchFinalizer === null ? null : TrueFalse[batchFinalizer.id],
      },
      ignoreSensorDropdown: {
        ...ignoreSensor,
        id: ignoreSensor === null ? null : TrueFalse[ignoreSensor.id],
      },
      ignorePhaseDropdown: { ...ignorePhase, id: ignorePhase === null ? null : TrueFalse[ignorePhase.id] },
      withoutWorkOrderDropdown: {
        ...withoutWorkOrderData,
        id: withoutWorkOrderData === null ? null : TrueFalse[withoutWorkOrderData.id],
      },
    };
  }

  public addTask(product: AddTaskInterface): Observable<BaseOneResponseInterface<AddTaskInterface>> {
    return this.http.post<BaseOneResponseInterface<AddTaskInterface>>(`${this.urls.TASK_URL}`, product);
  }

  public deleteTasks(task: number[] | number): Observable<BulkResponseDataInterface | BaseCrudResponse> {
    if (Array.isArray(task) && task.length > 1) {
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        body: {
          tasks: task,
        },
      };
      return this.http.delete<BulkResponseDataInterface>(`${this.urls.BULK_DELETE_URL}`, httpOptions);
    }
    return this.http.delete<BaseCrudResponse>(`${this.urls.TASK_URL}/${task[0]}`);
  }

  public editTask(task: EditTaskInterface, taskId: number): Observable<BaseOneResponseInterface<EditTaskInterface>> {
    return this.http.patch<BaseOneResponseInterface<EditTaskInterface>>(`${this.urls.TASK_URL}/${taskId}`, task);
  }

  public bulkEditTask(
    tasks: BulkEditTaskInterface[],
  ): Observable<GetManyResponseInterface<TaskBulkOperationResponseInterface>> {
    return this.http.patch<GetManyResponseInterface<TaskBulkOperationResponseInterface>>(`${this.urls.BULK_EDIT_URL}`, {
      tasks,
    });
  }

  public prepareTasksQuery(params: TaskQueryParams) {
    const join = [
      { field: 'line', select: ['title', 'selectedTaskId', 'activityIds'] },
      { field: 'activity', select: ['name', 'activityType'] },
      { field: 'equipment', select: ['equipmentName'] },
      { field: 'rootCauseGroup', select: ['name'] },
      { field: 'equipmentAssignment', select: ['orderNumber'] },
      { field: 'treeNode', select: ['id', 'name'] },
    ];
    const groupBy = 'Task.id,Task.equipment_id';
    const perPage = params.perPage ? Number(params.perPage) : 10;
    const page = params.page;
    const advancedFilter = params.advancedFilter;
    const sites = params.site;
    const lines = params.line;
    const activities = params.activity;
    const search = { $and: [] };

    search.$and.push({ 'activity.activityType': { $ne: 'runTime' } });

    if (sites.length) {
      search.$and.push({ siteId: { $in: sites } });
    }

    if (lines.length) {
      search.$and.push({ lineId: { $in: lines } });
    }

    if (activities.length) {
      search.$and.push({ activityId: { $in: activities } });
    }
    if (advancedFilter) {
      for (const filter of advancedFilter) {
        search.$and.push(filter);
      }
    }

    if (params.search) {
      search.$and.push(params.search);
    }

    let sort = params.sort;

    if (params.sort[0].field === '') {
      sort = [{ field: 'id', order: 'desc' }];
    }

    return {
      join,
      groupBy,
      sort,
      search,
      searchText: params.searchText,
      page,
      per_page: perPage,
    };
  }

  private generateTaskFolderOptions(
    folders: ITreeNode[],
    lines: SimplifiedLineInterface[],
    activities: SimplifiedDataInterface[],
  ): SimplifiedDataInterface[] {
    const output: SimplifiedDataInterface[] = [];

    for (const folder of folders) {
      const line: SimplifiedLineInterface = lines.find(
        (line: SimplifiedLineInterface) => folder.rootAddress.lineId === line.id,
      );
      const activity: SimplifiedDataInterface = activities.find(
        (activity: SimplifiedDataInterface) => folder.rootAddress.activityId === activity.id,
      );
      const activityIdsOfLine: number[] =
        line && activity ? line.activityIds.split(',').map((id: string) => Number(id)) : [];
      const isValidFolder: boolean = activityIdsOfLine.includes(folder.rootAddress.activityId);

      if (isValidFolder) {
        output.push({
          id: folder.id,
          name: `${folder.name} (${line.title} - ${activity.name})`,
        });
      }
    }

    return output;
  }
}
