import { Action } from '@ngrx/store';
import { ActivityCRUDInterface, AlteredActivityCRUDInterface } from '../../../shared/component/filter/filter.class';
import { HttpOptionInterface } from '../filter.model';

export enum FilterActivitiesActionTypes {
  StartLoadFilterActivities = '[Filter Activity] Load Filter Activity',
  FilterActivitiesLoaded = '[Filter Activity] Filter Activity Loaded',
  ResetActivitiesStore = '[Filter Activity] Reset data stored in Activity store',
}

export class StartLoadFilterActivities implements Action {
  readonly type = FilterActivitiesActionTypes.StartLoadFilterActivities;

  constructor(public options: HttpOptionInterface) {}
}

export class FilterActivitiesLoaded implements Action {
  readonly type = FilterActivitiesActionTypes.FilterActivitiesLoaded;

  constructor(public data: AlteredActivityCRUDInterface[]) {}
}

export class ResetActivitiesStore implements Action {
  readonly type = FilterActivitiesActionTypes.ResetActivitiesStore;
}

export type FilterActivitiesActions = StartLoadFilterActivities | FilterActivitiesLoaded | ResetActivitiesStore;
