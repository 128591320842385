import { Action } from '@ngrx/store';
import { OfflineSensorStatusDataInterface } from './offline-sensor-status.model';
import { HomeActionTypes } from '../home/home.actions';

export const GET_OFFLINE_SENSOR_STATUS = '[OfflineSensorStatus] Get sensor offline status';
export const GET_OFFLINE_SENSOR_STATUS_COMPLETED = '[OfflineSensorStatus] Get sensor offline status completed';

export class GetSensorOfflineStatus implements Action {
  readonly type = GET_OFFLINE_SENSOR_STATUS;

  constructor(public lineId: number) {}
}

export class GetSensorOfflineStatusCompleted implements Action {
  readonly type = GET_OFFLINE_SENSOR_STATUS_COMPLETED;

  constructor(public payload: OfflineSensorStatusDataInterface[]) {}
}

export class FetchError implements Action {
  readonly type = HomeActionTypes.FetchError;

  constructor(public payload: any[]) {}
}

export type OfflineSensorStatusActions = GetSensorOfflineStatus | GetSensorOfflineStatusCompleted | FetchError;
