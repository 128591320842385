import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-forbidden-scheduler',
  templateUrl: './forbidden-scheduler.component.html',
  styleUrls: ['./forbidden-scheduler.component.scss'],
})
export class ForbiddenSchedulerComponent implements OnInit {
  constructor(public translate: TranslateService) {}

  ngOnInit(): void {}
}
