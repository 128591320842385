import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FilterCardComponent } from './filter-card.component';
import { DropdownComponent } from '../dropdown/dropdown.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { SiteService } from '../../../service/filter/site.service';
import { LineService } from '../../../service/filter/line.service';
import { DateType, Line, Product, Site, WorkOrderStatus } from '../filterable-objects.class';
import { FilterDateRangePickerComponent } from '../filter-date-range-picker/filter-date-range-picker.component';
import { DateRangePickerComponent } from '../../date-range-picker/date-range-picker.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SearchBoxComponent } from '../search-box/search-box.component';
import { FilterUpdateComponent } from '../../filter-update/filter-update.component';
import { ScwMatUiModule } from '../../scw-mat-ui/scw-mat-ui.module';
import { AdvancedFilterComponent } from '../advanced-filter/advanced-filter.component';
import { ButtonGroupModule } from '../button-group/button-group.module';
import { TranslateModule } from '@ngx-translate/core';
import { ComparisonFilterCardComponent } from '../comparison-filter-card/comparison-filter-card.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    AngularMultiSelectModule,
    NgxDaterangepickerMd,
    MatProgressBarModule,
    ScwMatUiModule,
    TranslateModule,
    ButtonGroupModule,
  ],
  declarations: [
    FilterCardComponent,
    DropdownComponent,
    FilterUpdateComponent,
    FilterDateRangePickerComponent,
    AdvancedFilterComponent,
    ComparisonFilterCardComponent,
  ],
  exports: [
    FilterCardComponent,
    FilterUpdateComponent,
    ButtonGroupModule,
    ComparisonFilterCardComponent,
  ],
  providers: [Line, Site, Product, DateType, WorkOrderStatus, LineService, SiteService],
})
export class FilterCardModule {}
