import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { IMyReport, IMyReportRequestPayload } from '../../../view/reports/my-reports/my-reports.model';

export enum MyReportsRoutes {
  BASE_MY_REPORTS = 'baseMyReports',
}

const basePath = '/my-reports';

@Injectable({
  providedIn: 'root',
})
export class MyReportsService {
  private readonly routes: Record<MyReportsRoutes, string> = {
    [MyReportsRoutes.BASE_MY_REPORTS]: `${this.baseUrl}/${basePath}`,
  };

  constructor(private readonly http: HttpClient, @Inject('API_BASE_URL') private readonly baseUrl: string) {}

  public getMyReports(params: HttpParams): Observable<GetManyResponseInterface<IMyReport>> {
    return this.http.get<GetManyResponseInterface<IMyReport>>(this.routes[MyReportsRoutes.BASE_MY_REPORTS], { params });
  }

  public updateMyReport(
    id: number,
    requestPayload: Partial<IMyReportRequestPayload>,
  ): Observable<BaseOneResponseInterface<IMyReport>> {
    return this.http.patch<BaseOneResponseInterface<IMyReport>>(
      `${this.routes[MyReportsRoutes.BASE_MY_REPORTS]}/${id}`,
      requestPayload,
    );
  }

  public createNewMyReport(requestPayload: IMyReportRequestPayload): Observable<BaseOneResponseInterface<IMyReport>> {
    return this.http.post<BaseOneResponseInterface<IMyReport>>(
      `${this.routes[MyReportsRoutes.BASE_MY_REPORTS]}`,
      requestPayload,
    );
  }

  public deleteMyReport(id: number): Observable<GetManyResponseInterface<IMyReport>> {
    return this.http.delete<GetManyResponseInterface<IMyReport>>(
      `${this.routes[MyReportsRoutes.BASE_MY_REPORTS]}/${id}`,
    );
  }

  public bulkDeleteMyReports(ids: number[]): Observable<BulkResponseDataInterface> {
    return this.http.delete<BulkResponseDataInterface>(`${this.routes[MyReportsRoutes.BASE_MY_REPORTS]}/bulk/delete`, {
      body: { myReports: ids },
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    });
  }

  public cloneMyReport(
    id: number,
    requestPayload: Omit<IMyReportRequestPayload, 'id'>,
  ): Observable<BaseOneResponseInterface<IMyReport>> {
    return this.http.post<BaseOneResponseInterface<IMyReport>>(
      `${this.routes[MyReportsRoutes.BASE_MY_REPORTS]}/clone-report/${id}`,
      requestPayload,
    );
  }
}
