import { SchedulerScenarioActions, SchedulerScenarioActionTypes } from './scheduler-scenario.actions';
import { ScenariosStateInterface } from './scheduler-scenario.model';

export const initialState: ScenariosStateInterface = {
  scenarios: [],
  scenariosLoaded: false,
  scenariosLoading: false,
  scenarioTableParameters: {
    count: 0,
    page: 1,
    pageCount: 0,
    total: 0,
  },
  userLoaded: false,
  userLoading: false,
  createScenarioLoading: false,
  createScenarioLoaded: false,
  updateScenarioLoading: false,
  updateScenarioLoaded: false,
  cloneScenarioLoading: false,
  cloneScenarioLoaded: false,
  deleteScenarioLoading: false,
  deleteScenarioLoaded: false,
  scenariosSiteDataLoading: false,
  scenariosSiteDataLoaded: false,
  bulkEditScenarioLoading: false,
  bulkEditScenarioLoaded: false,
  usersLoaded: false,
  usersLoading: false,
  users: [],
  tableSettings: [],
};

export function schedulerScenarioReducer(
  state: ScenariosStateInterface = initialState,
  action: SchedulerScenarioActions,
): ScenariosStateInterface {
  switch (action.type) {
    case SchedulerScenarioActionTypes.LoadSchedulerScenarios:
      return {
        ...state,
        scenariosLoading: true,
        scenariosLoaded: false,
      };

    case SchedulerScenarioActionTypes.SchedulerScenariosLoaded:
      return {
        ...state,
        scenariosLoading: false,
        scenariosLoaded: true,
        scenarios: action.payload.data,
        scenarioTableParameters: {
          count: action.payload.count,
          page: action.payload.page,
          pageCount: action.payload.pageCount,
          total: action.payload.total,
        },
      };

    case SchedulerScenarioActionTypes.LoadSchedulerScenarioUsers:
      return {
        ...state,
        userLoading: true,
        userLoaded: false,
      };

    case SchedulerScenarioActionTypes.SchedulerScenarioUsersLoaded:
      return {
        ...state,
        userLoading: false,
        userLoaded: true,
        users: action.payload,
      };

    case SchedulerScenarioActionTypes.CreateSchedulerScenario:
      return {
        ...state,
        createScenarioLoading: true,
        createScenarioLoaded: false,
      };

    case SchedulerScenarioActionTypes.SchedulerScenarioCreated:
      return {
        ...state,
        createScenarioLoading: false,
        createScenarioLoaded: true,
      };

    case SchedulerScenarioActionTypes.UpdateSchedulerScenario:
      return {
        ...state,
        updateScenarioLoading: true,
        updateScenarioLoaded: false,
      };

    case SchedulerScenarioActionTypes.SchedulerScenarioUpdated:
      return {
        ...state,
        updateScenarioLoading: false,
        updateScenarioLoaded: true,
      };

    case SchedulerScenarioActionTypes.CloneSchedulerScenario:
      return {
        ...state,
        cloneScenarioLoading: true,
        cloneScenarioLoaded: false,
      };

    case SchedulerScenarioActionTypes.SchedulerScenarioCloned:
      return {
        ...state,
        cloneScenarioLoading: false,
        cloneScenarioLoaded: true,
      };

    case SchedulerScenarioActionTypes.DeleteSchedulerScenario:
      return {
        ...state,
        deleteScenarioLoading: true,
        deleteScenarioLoaded: false,
      };

    case SchedulerScenarioActionTypes.BulkDeleteSchedulerScenario:
      return {
        ...state,
        deleteScenarioLoading: true,
        deleteScenarioLoaded: false,
      };

    case SchedulerScenarioActionTypes.BulkDeleteSchedulerScenarioCompleted:
      return {
        ...state,
        deleteScenarioLoading: false,
        deleteScenarioLoaded: true,
      };

    case SchedulerScenarioActionTypes.SchedulerScenarioDeleted:
      return {
        ...state,
        deleteScenarioLoading: false,
        deleteScenarioLoaded: true,
      };

    case SchedulerScenarioActionTypes.SchedulerSiteDataLoading:
      return {
        ...state,
        ...{
          scenariosSiteDataLoading: true,
          scenariosSiteDataLoaded: false,
        },
      };

    case SchedulerScenarioActionTypes.SchedulerSiteDataLoaded:
      return {
        ...state,
        ...{
          siteData: action.payload,
          scenariosSiteDataLoading: false,
          scenariosSiteDataLoaded: true,
        },
      };

    case SchedulerScenarioActionTypes.BulkEditScenario:
      return {
        ...state,
        ...{
          bulkEditScenarioLoading: true,
          bulkEditScenarioLoaded: false,
        },
      };

    case SchedulerScenarioActionTypes.BulkEditScenarioCompleted:
      return {
        ...state,
        ...{
          bulkEditScenarioLoading: false,
          bulkEditScenarioLoaded: true,
        },
      };

    case SchedulerScenarioActionTypes.LoadScenarioUsers:
      return {
        ...state,
        ...{
          usersLoading: true,
          usersLoaded: false,
        },
      };

    case SchedulerScenarioActionTypes.ScenarioUsersLoaded:
      return {
        ...state,
        ...{
          usersLoading: false,
          usersLoaded: true,
        },
      };

    case SchedulerScenarioActionTypes.SetTableSettings:
      return {
        ...state,
        tableSettings: action.payload,
      };

    default:
      return state;
  }
}
