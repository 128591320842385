import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot, GuardsCheckEnd,
  GuardsCheckStart, NavigationCancel,
  NavigationEnd,
  Router,
  UrlSegment,
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { OeeAppState } from './store/oee.reducer';
import * as AppActions from './store/app/actions';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { PageHeaderService } from './shared/service/page-header/page-header.service';
import { BreadcrumbInterface, PageHeaderInterface } from './shared/service/page-header/page-header.model';
import { environment } from '../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { User } from './store/user/model';
import { takeWhile } from 'rxjs/operators';
import * as MainActions from './store/main/main.actions';
import { BROWSER_TITLE_SUFFIX } from '../constants';
import { SignalRService } from './shared/service/signalr/signalr.service';
import * as UserConfigurationActions from './store/user-configuration/user-configuration.actions';
import { window } from 'ngx-bootstrap/utils';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'SCW OEE Tracker';
  private url: string;
  private refresherTimeout = null;
  private tenMinInMs = 600000;
  private isTokenLoaded: boolean = false;
  public guardLoading: boolean = false;
  public isUnderMaintenance: boolean = false;
  private appSubscription: Subscription;

  private setTimeoutForSilentRefresh(): void {
    clearTimeout(this.refresherTimeout);
    this.refresherTimeout = setTimeout(() => {
      this.renewToken(false);
    }, this.tenMinInMs);
  }

  constructor(
    public translate: TranslateService,
    private router: Router,
    private store: Store<OeeAppState>,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private toast: ToastrService,
    private pageHeaderService: PageHeaderService,
    private cookieService: CookieService,
    private http: HttpClient,
    private readonly signalRService: SignalRService,
  ) {
    this.url = `${environment.ssoUrl}${environment.ssoSilentAuthUrl}${environment.ssoEnv}`;
    window.addEventListener('online', () => {
      window.location.reload();
      this.store.dispatch(new AppActions.HideMask());
    });

    window.addEventListener('offline', () => {
      this.store.dispatch(new AppActions.ShowMask());
      this.toast.warning(
        this.translate.instant('general.internetErrorMessage'),
        this.translate.instant('general.warning'),
        {
          closeButton: false,
          progressBar: false,
          disableTimeOut: true,
          tapToDismiss: false,
          positionClass: 'toast-bottom-right',
        },
      );
    });
  }

  ngOnInit(): void {
    (async () => {
      await this.signalRService.startConnection();
    })();

    this.store.dispatch(new MainActions.GetNavigationMenu());
    this.store.dispatch(new MainActions.GetActiveSites());
    this.store.dispatch(new UserConfigurationActions.GetUserConfigurationLoading());
    this.store
      .select('user')
      .pipe(takeWhile(() => !this.isTokenLoaded))
      .subscribe((state: User) => {
        if (state.isTokenRefreshed) {
          this.isTokenLoaded = state.isTokenRefreshed;
          this.renewToken();
        }
      });
    this.appSubscription = this.store.select('app').subscribe((state) => {
      this.isUnderMaintenance = state.maintenanceModeData?.isUnderMaintenance ?? false;

      if (!this.isUnderMaintenance && state.maintenanceModeData?.originURL) {
        const newURL = new URL(state.maintenanceModeData?.originURL);

        if (newURL.hostname !== window.location.hostname) {
          window.location.replace(window.location.href.replace(window.location.origin, newURL.origin));
        }
      }
    });

    this.router.events.subscribe((event) => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }

      window.scrollTo(0, 0);
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const configuration: PageHeaderInterface[] = this.getPageConfiguration(
          this.router.routerState,
          this.router.routerState.root,
        );

        if (configuration.length === 0 || configuration[0].title === undefined) {
          return;
        }

        const root = this.router.routerState.snapshot.root;
        const breadcrumbs: BreadcrumbInterface[] = [];
        this.addBreadcrumb(root, [], breadcrumbs);

        const pageConfig: PageHeaderInterface = configuration[0];
        const pageConfigTitle = this.translate.instant(`pageTitles.${pageConfig.title}`);
        this.titleService.setTitle(pageConfigTitle + BROWSER_TITLE_SUFFIX);
        this.setHeader({
          ...pageConfig,
          breadcrumbs,
          title: pageConfigTitle,
          titleKey: pageConfig.title,
        });
      }

      if (event instanceof GuardsCheckStart) {
        this.guardLoading = true;
      }

      if (event instanceof GuardsCheckEnd || event instanceof NavigationCancel) {
        this.guardLoading = false;
      }
    });
  }

  private getPageConfiguration(state, parent: ActivatedRoute): PageHeaderInterface[] {
    const data: PageHeaderInterface[] = [];
    if (parent && parent.snapshot.data && parent.snapshot.data['title']) {
      data.push(parent.snapshot.data);
    }

    if (state && parent) {
      data.push(...this.getPageConfiguration(state, state.firstChild(parent)));
    }

    return data;
  }

  private setHeader(config: PageHeaderInterface): void {
    let pageHeader: PageHeaderInterface = {
      title: null,
      titleKey: null,
      icon: null,
    };

    if (config.setPageHeader !== undefined && config.setPageHeader) {
      pageHeader = {
        title: config.title || null,
        titleKey: config.titleKey,
        icon: config.icon || null,
        fullScreenButton: config.fullScreenButton || false,
        fullScreenTargetElementId: config.fullScreenTargetElementId || null,
        breadcrumbs: config.breadcrumbs || [],
        isTabPage: config.isTabPage,
        showPageConfiguration: config.showPageConfiguration || false,
        showCountDownButton: config.showCountDownButton || false,
        showFilterBarVisibilityButton: config.showFilterBarVisibilityButton || false,
        showPrintFunctionalityButton: config.showPrintFunctionalityButton || false,
      };
    }

    this.pageHeaderService.setHeader(pageHeader);
  }

  private renewToken(forTimeOutSetup: boolean = true): void {
    if (forTimeOutSetup) {
      this.setTimeoutForSilentRefresh();
      return;
    }

    this.http
      .get<any>(this.url)
      .pipe()
      .subscribe((resData: { success: boolean }) => {
        if (resData.success) {
          this.setTimeoutForSilentRefresh();
        }
      });
  }

  private addBreadcrumb(route: ActivatedRouteSnapshot, parentUrls: string[], breadcrumbs: any[]): void {
    if (route) {
      const routeUrls = parentUrls.concat(route.url.map((url: UrlSegment) => url.path));

      if (route.data['enableBreadcrumbs']) {
        const routeUrlAsString = `/${routeUrls.join('/')}`;
        const breadcrumb: BreadcrumbInterface = {
          label: this.translate.instant(`pageTitles.${route.data['title']}`),
          url: routeUrlAsString,
          disabled: this.router.url === routeUrlAsString,
        };
        breadcrumbs.push(breadcrumb);
      }

      this.addBreadcrumb(route.firstChild, routeUrls, breadcrumbs);
    }
  }

  public ngOnDestroy(): void {
    this.appSubscription?.unsubscribe();
  }
}
