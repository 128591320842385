import { FilterActivityTypeActionTypes, FilterActivityTypesActions } from './activity-type.actions';
import { ActivityTypeInterface } from '../../../shared/model/interface/activities.model';

export interface FilterActivityTypesState {
  isLoading: boolean;
  isLoaded: boolean;
  data: ActivityTypeInterface[];
}

export const initialFilterActivityState: FilterActivityTypesState = {
  isLoaded: false,
  isLoading: false,
  data: [],
};

export function filterActivityTypeReducer(
  state: FilterActivityTypesState = initialFilterActivityState,
  action: FilterActivityTypesActions,
): FilterActivityTypesState {
  switch (action.type) {
    case FilterActivityTypeActionTypes.StartLoadFilterActivityType:
      return { ...state, isLoading: true };
    case FilterActivityTypeActionTypes.FilterActivityTypeLoaded:
      return { ...state, data: action.data, isLoaded: true, isLoading: false };
    case FilterActivityTypeActionTypes.ResetActivityTypeStore:
      return { ...state, data: [], isLoaded: false, isLoading: false };
    default:
      return state;
  }
}
