import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../oee.reducer';
import * as ObjectActions from './home-screen-metric-settings.actions';
import { catchError, switchMap, take } from 'rxjs/operators';
import { of } from 'rxjs';
import * as AppActions from '../app/actions';
import {
  GetManyHomeScreenMetricCrudResponse,
  GetOneHomeScreenMetricCrudResponse,
  GetSitesCrudResponse,
  HomeMetricSetItemInterface,
  PaginationInterface,
} from './home-screen-metric-settings.model';
import { HomeScreenMetricService } from './home-screen-metric-settings.service';

@Injectable()
export class HomeScreenMetricSettingsEffects {
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private store: Store<oeeAppReducer.OeeAppState>,
    public service: HomeScreenMetricService,
  ) {}

  getHomeScreenMetricsData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.HOME_SCREEN_METRICS_DATA_LOADING),
      switchMap((objectData: ObjectActions.HomeScreenMetricsDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        let params: HttpParams = new HttpParams();
        params = params
          .append('join', 'homeMetricSetItem')
          .append('join', 'homeMetricSetAssignment')
          .append('join', 'homeMetricSetAssignment.line')
          .set('page', objectData.payload.page.toString())
          .set('limit', objectData.payload.limit.toString())
          .set('sort', objectData.payload.sort);

        return this.service.getHomeScreenMetricSets(params).pipe(
          switchMap((resData: GetManyHomeScreenMetricCrudResponse) => {
            return of(new ObjectActions.StartHomeScreenMetricsDataLoaded(resData), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  deleteHomeScreenMetricData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DELETE_HOME_SCREEN_METRIC),
      switchMap((objectData: ObjectActions.DeleteHomeScreenMetric) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.deleteHomeScreenMetricSets(objectData.id).pipe(
          switchMap((resData: GetOneHomeScreenMetricCrudResponse) => {
            return of(
              new ObjectActions.DeleteHomeScreenMetricCompleted(resData),
              new ObjectActions.HomeScreenMetricsDataLoading(this.getPagination()),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  copyHomeScreenMetricData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.COPY_HOME_SCREEN_METRIC_LOADING),
      switchMap((objectData: ObjectActions.CopyHomeScreenMetricLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const { name, siteId, description } = objectData.payload;
        const homeMetricSetItem = objectData.payload.homeMetricSetItem.map((item: HomeMetricSetItemInterface) => {
          const { metric, order } = item;

          return { metric, order };
        });
        const options = { name, siteId, description, homeMetricSetItem };

        return this.service.createHomeScreenMetricSets(options).pipe(
          switchMap((resData: GetOneHomeScreenMetricCrudResponse) => {
            return of(
              new ObjectActions.CopyHomeScreenMetricLoaded(resData),
              new ObjectActions.HomeScreenMetricsDataLoading(this.getPagination()),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  getHomeScreenMetricsTreeList = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.TREE_LIST_LOADING),
      switchMap((objectData: ObjectActions.HomeScreenMetricSetTreeListLoading) => {
        let params: HttpParams = new HttpParams()
          .set('fields', 'title,siteId')
          .append('join', 'status')
          .append('join', 'homeMetricSetAssignment||lineId')
          .append('join', 'homeMetricSetAssignment.homeMetricSet||name,description')
          .append('join', 'lineTypeName||lineType,status')
          .append('limit', '1000');

        if (objectData.metricSet.siteId) {
          params = params.set('s', JSON.stringify({ siteId: { $eq: objectData.metricSet.siteId } }));
        }

        return this.service.getHomeScreenMetricsTreeList(params, objectData.metricSet.siteId).pipe(
          switchMap((resData: GetSitesCrudResponse) => {
            return of(new ObjectActions.HomeScreenMetricSetTreeListLoaded(resData));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  getHomeScreenMetricInformation = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.HOME_SCREEN_METRIC_INFORMATION_LOADING),
      switchMap((objectData: ObjectActions.HomeScreenMetricInformationLoading) => {
        const params: HttpParams = new HttpParams()
          .append('join', 'homeMetricSetItem||metric,order,homeMetricSetId')
          .append('join', 'homeMetricSetAssignment||lineId,homeMetricSetId')
          .append('join', 'homeMetricSetAssignment.line||title,siteId,statusId,lineType')
          .append('join', 'homeMetricSetAssignment.line.status')
          .set('sort', 'homeMetricSet.homeMetricSetItem.order,ASC');

        return this.service.getHomeScreenMetricInformation(objectData.id, params).pipe(
          switchMap((resData: GetOneHomeScreenMetricCrudResponse) => {
            return of(new ObjectActions.StartHomeScreenMetricInformationLoaded(resData));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  createHomeScreenMetricData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.CREATE_HOME_SCREEN_METRIC),
      switchMap((objectData: ObjectActions.CreateHomeScreenMetric) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.createHomeScreenMetricSets(objectData.payload).pipe(
          switchMap((resData: GetOneHomeScreenMetricCrudResponse) => {
            return of(
              new ObjectActions.CreateHomeScreenMetricCompleted(resData),
              new ObjectActions.HomeScreenMetricsDataLoading(this.getPagination()),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  updateHomeScreenMetricData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.UPDATE_HOME_SCREEN_METRIC),
      switchMap((objectData: ObjectActions.UpdateHomeScreenMetric) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.updateHomeScreenMetricSet(objectData.id, objectData.payload).pipe(
          switchMap((resData: GetOneHomeScreenMetricCrudResponse) => {
            return of(
              new ObjectActions.UpdateHomeScreenMetricCompleted(resData),
              new ObjectActions.HomeScreenMetricsDataLoading(this.getPagination()),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  private getPagination(): PaginationInterface {
    let pagination: PaginationInterface;

    this.store
      .select('homeScreenMetricSettingsStore')
      .pipe(take(1))
      .subscribe((homeScreenMetric) => {
        pagination = homeScreenMetric.pagination;
      });

    return pagination;
  }
}
