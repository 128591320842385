import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as ObjectActions from './kpi-view.actions';
import { of } from 'rxjs';
import { KpiViewService } from './kpi-view.service';
import { BaseOneResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import * as AppActions from '../../app/actions';
import {
  IKpiViewLaborHoursTrend,
  IKpiViewOeeAndGoodCountTrend,
  IKpiViewOEEParameters,
  IKpiViewWorkOrderCountAndTarget,
  IKpiViewWorkOrderTrend,
} from './kpi-view.model';
import { HelperService } from '../../../shared/service/helper.service';

@Injectable()
export class KpiViewEffects {
  constructor(
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly actions$: Actions,
    private readonly http: HttpClient,
    private readonly service: KpiViewService,
  ) {}

  getOeeAndTargetData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.KPI_VIEW_WIDGET_OEE_DATA_LOADING),
      switchMap((objectData: ObjectActions.KpiViewWidgetOeeDataLoading) => {
        const { groupBy, start, end, ...parameters } = objectData.parameters;
        const httpParams: HttpParams = HelperService.createHttpParamsWithObject({ start, end, ...parameters });
        const body: IKpiViewOEEParameters = {
          ...parameters,
          startDate: start,
          endDate: end,
          lines: parameters.lines === -1 ? null : parameters.lines,
          sites: parameters.sites === -1 ? null : parameters.sites,
          sqlMode: groupBy,
        };

        return this.service.getOeeAndTargetData(body, httpParams).pipe(
          switchMap((response) => {
            return of(new ObjectActions.KpiViewWidgetOeeDataLoaded(response), new AppActions.HideTopLoader());
          }),
          catchError((err: unknown) => {
            return of(new ObjectActions.KpiViewFetchError(err));
          }),
        );
      }),
      catchError((err: unknown) => {
        return of(new ObjectActions.KpiViewFetchError(err));
      }),
    ),
  );

  getWorkOrderCountAndTarget = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.KPI_VIEW_WIDGET_WORK_ORDER_COUNT_DATA_LOADING),
      switchMap((objectData: ObjectActions.KpiViewWidgetWorkOrderCountDataLoading) => {
        return this.service
          .getWorkOrderCountAndTarget(HelperService.createHttpParamsWithObject(objectData.parameters))
          .pipe(
            switchMap((response: BaseOneResponseInterface<IKpiViewWorkOrderCountAndTarget>) => {
              return of(
                new ObjectActions.KpiViewWidgetWorkOrderCountDataLoaded(response.data),
                new AppActions.HideTopLoader(),
              );
            }),
            catchError((err: unknown) => {
              return of(new ObjectActions.KpiViewFetchError(err));
            }),
          );
      }),
      catchError((err: unknown) => {
        return of(new ObjectActions.KpiViewFetchError(err));
      }),
    ),
  );

  getLaborHours = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.KPI_VIEW_LABOR_HOURS_DATA_LOADING),
      switchMap((objectData: ObjectActions.KpiViewLaborHoursDataLoading) => {
        return this.service.getLaborHours(HelperService.createHttpParamsWithObject(objectData.parameters)).pipe(
          switchMap((response: BaseOneResponseInterface<IKpiViewLaborHoursTrend>) => {
            return of(new ObjectActions.KpiViewLaborHoursDataLoaded(response.data), new AppActions.HideTopLoader());
          }),
          catchError((err: unknown) => {
            return of(new ObjectActions.KpiViewFetchError(err));
          }),
        );
      }),
      catchError((err: unknown) => {
        return of(new ObjectActions.KpiViewFetchError(err));
      }),
    ),
  );

  getOeeTrendData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.KPI_VIEW_CHART_OEE_DATA_LOADING),
      switchMap((objectData: ObjectActions.KpiViewChartOeeDataLoading) => {
        return this.service
          .getOeeAndGoodCountTrend(HelperService.createHttpParamsWithObject(objectData.parameters))
          .pipe(
            switchMap((response: BaseOneResponseInterface<IKpiViewOeeAndGoodCountTrend>) => {
              return of(new ObjectActions.KpiViewChartOeeDataLoaded(response.data), new AppActions.HideTopLoader());
            }),
            catchError((err: unknown) => {
              return of(new ObjectActions.KpiViewFetchError(err));
            }),
          );
      }),
      catchError((err: unknown) => {
        return of(new ObjectActions.KpiViewFetchError(err));
      }),
    ),
  );

  getWorkOrderCountTrend = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.KPI_VIEW_CHART_WORK_ORDER_COUNT_DATA_LOADING),
      switchMap((objectData: ObjectActions.KpiViewChartWorkOrderCountDataLoading) => {
        return this.service
          .getWorkOrderCountTrend(HelperService.createHttpParamsWithObject(objectData.parameters))
          .pipe(
            switchMap((response: BaseOneResponseInterface<IKpiViewWorkOrderTrend[]>) => {
              return of(
                new ObjectActions.KpiViewChartWorkOrderCountDataLoaded(response.data),
                new AppActions.HideTopLoader(),
              );
            }),
            catchError((err: unknown) => {
              return of(new ObjectActions.KpiViewFetchError(err));
            }),
          );
      }),
      catchError((err: unknown) => {
        return of(new ObjectActions.KpiViewFetchError(err));
      }),
    ),
  );
}
