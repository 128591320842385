import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import {
  IndependentFilterProductActions,
  IndependentFilterProductActionTypes,
  IndependentFilterProductsLoaded,
  StartLoadIndependentFilterProducts,
} from './no-dependency-product.actions';
import { ProductService } from '../../../shared/service/filter/product.service';
import { generateHttpOption } from '../../../shared/helper/app-helper';

@Injectable()
export class IndependentFilterProductEffects {
  loadIndependentFilterProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IndependentFilterProductActionTypes.StartLoadIndependentFilterProducts),
      switchMap((objectData: StartLoadIndependentFilterProducts) => {
        const params = generateHttpOption(objectData.options);
        return from(this.service.getData(params)).pipe(
          switchMap((response) => {
            return of(new IndependentFilterProductsLoaded(response));
          }),
          catchError(() => {
            return of(new IndependentFilterProductsLoaded([]));
          }),
        );
      }),
    ),
  );

  constructor(private actions$: Actions<IndependentFilterProductActions>, public service: ProductService) {}
}
