import * as Actions from './number-input.actions';
import { NumberInputStateInterface } from './number-input.model';

const initialState: NumberInputStateInterface = {
  configuration: null,
  formIsSubmitted: false,
};

export function numberInputReducer(
  state: NumberInputStateInterface = initialState,
  action: Actions.NumberInputActions,
): NumberInputStateInterface {
  switch (action.type) {
    case Actions.SET_CONFIGURATION:
      return {
        ...state,
        configuration: action.configuration,
      };

    case Actions.SET_FORM_IS_SUBMITTED:
      return {
        ...state,
        formIsSubmitted: action.newValue,
      };

    case Actions.RESET_NUMBER_INPUT_STORE:
      return {
        configuration: null,
        formIsSubmitted: false,
      };

    default:
      return state;
  }
}
