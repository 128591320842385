import * as Actions from './offline-sensor-status.actions';
import { OfflineSensorStatusStateInterface } from './offline-sensor-status.model';

export const offlineSensorStatusInitialState: OfflineSensorStatusStateInterface = {
  sensorStatus: [],
  sensorStatusLoaded: false,
  sensorStatusLoading: false,
};

export function offlineSensorStatusReducer(
  state: OfflineSensorStatusStateInterface = offlineSensorStatusInitialState,
  action: Actions.OfflineSensorStatusActions,
): OfflineSensorStatusStateInterface {
  switch (action.type) {
    case Actions.GET_OFFLINE_SENSOR_STATUS:
      return {
        ...state,
        sensorStatusLoading: true,
        sensorStatusLoaded: false,
      };
    case Actions.GET_OFFLINE_SENSOR_STATUS_COMPLETED:
      return {
        ...state,
        sensorStatus: action.payload,
        sensorStatusLoading: false,
        sensorStatusLoaded: true,
      };
    default:
      return state;
  }
}
