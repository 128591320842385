import { Action } from '@ngrx/store';
import { IOeeCalendarChartColorData, IOeeCalendarFilter } from './oee-calendar.model';
import { IOeeCalculationResult } from '../work-order-performance/work-order-performance.model';

export const OEE_CALENDAR_DATA_LOADING = '[OEE CALENDAR] OEE CALENDAR DATA LOADING';
export const OEE_CALENDAR_DATA_LOADED = '[OEE CALENDAR] OEE CALENDAR DATA LOADED';
export const OEE_CHART_COLOR_DATA_LOADING = '[OEE CALENDAR] OEE CHART COLOR DATA LOADING';
export const OEE_CHART_COLOR_DATA_LOADED = '[OEE CALENDAR] OEE CHART COLOR DATA LOADED';
export const RESET_OEE_CALENDAR_DATA = '[OEE CALENDAR] RESET OEE CALENDAR DATA';
export const FETCH_DATA_ERROR = '[OEE CALENDAR] FETCH DATA ERROR';

export class OeeCalendarDataLoading implements Action {
  readonly type = OEE_CALENDAR_DATA_LOADING;

  constructor(public params: IOeeCalendarFilter) {}
}

export class OeeCalendarDataLoaded implements Action {
  readonly type = OEE_CALENDAR_DATA_LOADED;

  constructor(public payload: IOeeCalculationResult[]) {}
}

export class OeeChartColorDataLoading implements Action {
  readonly type = OEE_CHART_COLOR_DATA_LOADING;

  constructor(public params: IOeeCalendarFilter) {}
}

export class OeeChartColorDataLoaded implements Action {
  readonly type = OEE_CHART_COLOR_DATA_LOADED;

  constructor(public payload: IOeeCalendarChartColorData[]) {}
}

export class ResetOeeCalendarData implements Action {
  readonly type = RESET_OEE_CALENDAR_DATA;
}

export class FetchDataError implements Action {
  readonly type = FETCH_DATA_ERROR;

  constructor(public payload: object) {}
}

export type OeeCalendarActions =
  | OeeCalendarDataLoading
  | OeeCalendarDataLoaded
  | OeeChartColorDataLoading
  | OeeChartColorDataLoaded
  | ResetOeeCalendarData
  | FetchDataError;
