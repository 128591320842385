import { Observable } from 'rxjs';
import moment from 'moment';

export type TCacheOptions = {
  ttl?: number;
};

export type TCacheItem<T> = { expires: Date; value: Observable<T> };
export type TCacheItems<T> = Record<string, TCacheItem<T>>;

export interface ICacheService<T> {
  setValue(key: string, value: Observable<T>, options?: TCacheOptions): Observable<T>;
  getValue(key: string): Observable<T>;
  removeValue(key: string): void;
  clear(): void;
}

export abstract class AbstractCacheService<T> implements ICacheService<T> {
  protected readonly DEFAULT_CACHE_TTL_MINUTES = 1;
  protected MAX_CACHE_SIZE = 500;

  private cache: TCacheItems<T> = {};

  private cacheMap: string[] = [];

  setValue(key: string, value: Observable<T>, options?: TCacheOptions): Observable<T> {
    const expiresAt = moment(new Date())
      .add(options?.ttl || this.DEFAULT_CACHE_TTL_MINUTES, 'minutes')
      .toDate();

    this.cache[key] = { value, expires: expiresAt };
    this.cacheMap.push(key);

    if (this.cacheMap.length > this.MAX_CACHE_SIZE) {
      this.removeOldestItem();
    }

    return value;
  }

  getValue(key: string): Observable<T> | undefined {
    const item: TCacheItem<T> | undefined = this.cache[key] ?? undefined;

    if (!item) {
      return undefined;
    }

    if (moment(new Date()).isAfter(item.expires)) {
      this.removeValue(key);
      return undefined;
    }

    return item.value;
  }

  removeValue(key: string): void {
    delete this.cache[key];
  }

  clear(): void {
    this.cache = {};
  }

  private removeOldestItem(): void {
    const oldestKey = this.cacheMap.shift();
    if (oldestKey) {
      this.removeValue(oldestKey);
    }
  }
}
