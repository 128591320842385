import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import { ErrorMessageService } from '../../../shared/service/error-message.service';
import { ExcelHelperService } from '../../../shared/service/excel/excel-helper.service';
import { EquipmentAssignmentService } from '../../../shared/service/settings/equipment-assignment/equipment-assignment.service';
import * as ObjectActions from '../equipment-assignment/equipment-assignment.actions';
import { catchError, switchMap } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { combineLatest, from, of } from 'rxjs';
import * as AppActions from '../../app/actions';
import {
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { IEquipmentAssignment } from './equipment-assignment.model';
import { LineService } from '../../../shared/service/filter/line.service';
import { LineCRUDResponseInterface } from '../../../shared/service/filter/service.class';
import { EntityTranslatorService } from '../../../shared/service/entity-translator/entity-translator.service';
import { User } from '../../user/model';
import { emptyAction } from '../../../../constants';

@Injectable()
export class EquipmentAssignmentEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: EquipmentAssignmentService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly errorMessageService: ErrorMessageService,
    private readonly excelHelperService: ExcelHelperService,
    private readonly lineService: LineService,
    private translatorService: EntityTranslatorService,
  ) {}

  getTotalDataCount = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LOAD_EQUIPMENT_ASSIGNMENT_DATA_COUNT),
      switchMap((objectData: ObjectActions.LoadExcelDataCount) => {
        this.store.dispatch(new AppActions.ShowLoader());

        const httpParams: HttpParams = new HttpParams()
          .set('limit', 1)
          .set('fields', 'id')
          .set('withEntityTranslations', 'false')
          .set('s', JSON.stringify({ siteId: objectData.siteId }));

        return this.service.getEquipmentAssignment(httpParams).pipe(
          switchMap((response: GetManyResponseInterface<IEquipmentAssignment>) => {
            return of(new ObjectActions.ExcelDataCountLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getLines = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_LINES),
      switchMap(() => {
        this.store.dispatch(new AppActions.ShowLoader());
        const httpParams: HttpParams = new HttpParams().set('fields', 'title,siteId').set('limit', '500');

        return this.lineService.getLines(httpParams).pipe(
          switchMap((response: LineCRUDResponseInterface) => {
            return of(new ObjectActions.GetLinesCompleted(response.data), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  getEquipmentAssignmentsData = createEffect(() =>
    combineLatest([
      this.actions$.pipe(ofType(ObjectActions.LOAD_EQUIPMENT_ASSIGNMENTS_DATA)),
      this.store.select('user'),
    ]).pipe(
      switchMap(([payload, state]: [payload: ObjectActions.LoadEquipmentAssignmentsData, state: User]) => {
        const { page, pageSize, sort, search, siteId, lineId } = payload.requestParams;

        const searchParams = { $and: [] };

        if (search !== '') {
          searchParams.$and.push({
            $or: [
              { 'equipment.site.name': { $cont: search } },
              { 'equipment.equipmentName': { $cont: search } },
              { 'line.title': { $cont: search } },
              { orderNumber: { $cont: search } },
              {
                $and: [
                  { 'entityTranslations.language': { $eq: state.language } },
                  { 'entityTranslations.value': { $cont: search } },
                ],
              },
            ],
          });
        }

        if (siteId !== undefined && siteId !== -1) {
          searchParams.$and.push({ 'line.siteId': { $in: siteId } });
        }

        if (lineId !== undefined && lineId !== -1) {
          searchParams.$and.push({ lineId: { $in: lineId } });
        }

        if (searchParams.$and.length === 0) {
          delete searchParams.$and;
        }

        let httpParams: HttpParams = new HttpParams()
          .append('join', 'line')
          .append('join', 'equipment')
          .append('join', 'equipment.site')
          .set('page', String(page))
          .set('limit', String(pageSize))
          .set('s', JSON.stringify(searchParams))
          .set('searchText', search);

        if (sort) {
          const direction: 'DESC' | 'ASC' = sort.type === 'descending' ? 'DESC' : 'ASC';
          httpParams = httpParams.set('sort', `${sort.column},${direction}`);
        }

        return this.service.getEquipmentAssignment(httpParams).pipe(
          switchMap((response: GetManyResponseInterface<IEquipmentAssignment>) => {
            response.data.forEach((equipmentAssignment: IEquipmentAssignment) =>
              this.translatorService.translate(equipmentAssignment),
            );

            return of(new ObjectActions.LoadedEquipmentAssignmentsData(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes));
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes));
      }),
    ),
  );

  editSingleEquipmentData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SINGLE_EDIT_EQUIPMENT_ASSIGNMENTS_DATA),
      switchMap((objectData: ObjectActions.SingleEditEquipmentAssignmentsData) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return from(this.service.patchEquipmentAssignmentData(objectData.equipmentId, objectData.payload)).pipe(
          switchMap((response) => {
            return of(
              new ObjectActions.EditedSingleEditEquipmentAssignmentsData(response),
              new AppActions.HideLoader(),
            );
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchError(err), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  editEquipmentAssignmentsData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.EDIT_EQUIPMENT_ASSIGNMENTS_DATA),
      switchMap((objectData: ObjectActions.EditEquipmentAssignmentsData) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return from(this.service.patchEquipmentAssignmentsData(objectData.payload)).pipe(
          switchMap((response) => {
            return of(new ObjectActions.EditedEquipmentAssignmentsData(response), new AppActions.HideLoader());
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchError(err), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  createEquipment = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.CREATE_EQUIPMENT_ASSIGNMENT),
      switchMap((objectData: ObjectActions.CreateEquipmentAssignment) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return from(this.service.createEquipmentAssignment(objectData.payload)).pipe(
          switchMap((response) => {
            return of(new ObjectActions.CreatedEquipmentAssignment(response), new AppActions.HideLoader());
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchError(err), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  deleteEquipment = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DELETE_EQUIPMENT_ASSIGNMENT),
      switchMap((objectData: ObjectActions.DeleteEquipmentAssignment) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return from(this.service.deleteEquipmentAssignment(objectData.equipmentAssignmentId)).pipe(
          switchMap((response) => {
            return of(new ObjectActions.DeletedEquipmentAssignment(response), new AppActions.HideLoader());
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchError(err), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  deleteEquipmentAssignments = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DELETE_EQUIPMENT_ASSIGNMENTS),
      switchMap((objectData: ObjectActions.DeleteEquipmentAssignments) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return from(this.service.deleteEquipmentAssignments(objectData.equipmentAssignmentIds)).pipe(
          switchMap((response) => {
            return of(new ObjectActions.DeletedEquipmentAssignments(response), new AppActions.HideLoader());
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchError(err), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  downloadEquipmentAssignmentExcel = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DOWNLOAD_EQUIPMENT_ASSIGNMENT_EXCEL),
      switchMap((objectData: ObjectActions.DownloadEquipmentAssignmentExcel) => {
        this.store.dispatch(new AppActions.ShowLoader());
        this.service.downloadEquipmentAssignmentExcel(objectData.filters, undefined, {
          withData: objectData.withData,
          withErrorColumn: false,
        });
        return emptyAction;
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  downloadEquipmentAssignmentExcelCompleted = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DOWNLOAD_EQUIPMENT_ASSIGNMENT_EXCEL_COMPLETED),
      switchMap(() => {
        return of(new AppActions.HideLoader());
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  uploadExcel = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.UPLOAD_EQUIPMENT_ASSIGNMENT_EXCEL),
      switchMap((objectData: ObjectActions.UploadEquipmentAssignmentExcel) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.uploadExcel(objectData.equipmentAssignment).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            this.errorMessageService.getTranslatedErrorMessage(response.data);
            const mergedArray = this.excelHelperService.mergeBulkResponseWithRequestData<IEquipmentAssignment>(
              response,
              objectData.equipmentAssignment.equipmentAssignments,
            );

            return of(
              new ObjectActions.UploadEquipmentAssignmentExcelCompleted(mergedArray),
              new AppActions.HideLoader(),
            );
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );
}
