import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ColorDropdownOptionInterface, TagCRUDRequestInterface, TagFormDropdownData, TagInterface } from './tags.model';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { GenericCrudRequestConstructionParameters } from '../../../shared/model/interface/generic-api-request.model';
import { HelperService } from '../../../shared/service/helper.service';
import { DropdownOptionInterface } from '../../../shared/component/scw-mat-ui/scw-mat-select/scw-mat-select.model';

@Injectable({
  providedIn: 'root',
})
export class TagsService {
  private readonly routes = {
    tags: `${this.baseUrl}/tags`,
    bulkDelete: `${this.baseUrl}/tags/bulk/delete`,
    sites: `${this.baseUrl}/sites`,
  };

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    public helperService: HelperService,
  ) {}

  public getTags(
    serviceParameters: GenericCrudRequestConstructionParameters,
  ): Observable<GetManyResponseInterface<TagInterface>> {
    const params: HttpParams = this.helperService.insertGenericCrudRequestParameters(serviceParameters);

    return this.http.get<GetManyResponseInterface<TagInterface>>(this.routes.tags, { params });
  }

  public createTag(status: TagCRUDRequestInterface): Observable<BaseOneResponseInterface<TagCRUDRequestInterface>> {
    return this.http.post<BaseOneResponseInterface<TagCRUDRequestInterface>>(`${this.routes.tags}`, status);
  }

  public updateTag(
    id: number,
    requestData: Partial<TagCRUDRequestInterface>,
  ): Observable<BaseOneResponseInterface<TagCRUDRequestInterface>> {
    return this.http.patch<BaseOneResponseInterface<TagCRUDRequestInterface>>(`${this.routes.tags}/${id}`, requestData);
  }

  public deleteTag(id: number): Observable<GetManyResponseInterface<TagInterface>> {
    return this.http.delete<GetManyResponseInterface<TagInterface>>(`${this.routes.tags}/${id}`);
  }

  public bulkDeleteTag(ids: number[]): Observable<BulkResponseDataInterface> {
    return this.http.delete<BulkResponseDataInterface>(`${this.routes.tags}/bulk/delete`, {
      body: { tags: ids },
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    });
  }

  public loadFormData(): Observable<TagFormDropdownData> {
    const siteParams: HttpParams = this.helperService.insertGenericCrudRequestParameters({
      limit: 1000,
      fields: ['name'],
      sort: [{ column: 'name', type: 'ascending' }],
    });

    const observables: {
      sites: Observable<GetManyResponseInterface<DropdownOptionInterface>>;
    } = {
      sites: this.http.get<GetManyResponseInterface<DropdownOptionInterface>>(`${this.routes.sites}`, {
        params: siteParams,
      }),
    };

    return forkJoin(observables).pipe(
      map((resolvedObservables: { sites: GetManyResponseInterface<DropdownOptionInterface> }) => {
        return {
          sites: resolvedObservables.sites,
        };
      }),
    );
  }

  public getColorData(): ColorDropdownOptionInterface[] {
    return [
      { id: 0, backgroundColor: '#DD332B' },
      { id: 1, backgroundColor: '#FF726B' },
      { id: 2, backgroundColor: '#6FC764' },
      { id: 3, backgroundColor: '#FF9347' },
      { id: 4, backgroundColor: '#FFD600' },
      { id: 5, backgroundColor: '#BDBDBD' },
      { id: 6, backgroundColor: '#448AFF' },
      { id: 7, backgroundColor: '#AC80F7' },
      { id: 8, backgroundColor: '#6FCECE' },
      { id: 9, backgroundColor: '#922D61' },
      { id: 10, backgroundColor: '#B6E757' },
      { id: 11, backgroundColor: '#98361D' },
    ];
  }
}
