import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import { BulkResponseDataInterface } from '../../shared/model/interface/crud-response-interface.model';
import { ResponseArrayInterface, ResponseInterface } from '../../shared/model/interface/generic-api-response.model';
import { IActivityHistory } from '../../shared/service/activity-history/activity-history.model';
import { ActivityHistoryService } from '../../shared/service/activity-history/activity-history.service';
import { LineService } from '../../shared/service/line/line.service';
import { UpdateMultipleActivityDatesRequestInterface } from '../../view/activity-review/activity-review.model';
import * as AppActions from '../app/actions';
import { ICurrentLine } from '../line/model';
import * as oeeAppReducer from '../oee.reducer';
import * as ObjectActions from './activity-review.actions';
import { UpdateMultipleActivitiesResponseInterface } from './activity-review.model';
import { EntityTranslatorService } from '../../shared/service/entity-translator/entity-translator.service';

@Injectable()
export class ActivityReviewEffects {
  private updateData: UpdateMultipleActivityDatesRequestInterface;

  constructor(
    private readonly actions$: Actions,
    private readonly http: HttpClient,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    @Inject('API_BASE_URL')
    private readonly baseUrl: string,
    private readonly activityHistoryService: ActivityHistoryService,
    private readonly lineService: LineService,
    private readonly entityTranslation: EntityTranslatorService,
  ) {}

  private ACTIVITY_REVIEW = {
    UPDATE: {
      DATA_URL: 'activity-histories/bulk/edit',
    },
    DELETE: {
      DATA_URL: 'activity-histories/bulk/delete',
    },
  };

  getActivityHistoryData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_ACTIVITY_HISTORY_DATA_LOADING),
      switchMap((objectData: ObjectActions.StartActivityHistoryDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.activityHistoryService
          .getActivities(objectData.searchObject, objectData.page, objectData.limit)
          .pipe(
            switchMap((response: ResponseArrayInterface<IActivityHistory>) => {
              response.data.forEach((activityHistory: IActivityHistory) => {
                this.entityTranslation.translate(activityHistory);
              });

              return of(
                new ObjectActions.ActivityHistoryDataLoaded(response),
                objectData.stallLoader ? new AppActions.ShowLoader() : new AppActions.HideLoader(),
                objectData.stallLoader ? new AppActions.ShowTopLoader() : new AppActions.HideTopLoader(),
              );
            }),
            catchError(() => {
              return of(new AppActions.HideLoader(), new AppActions.HideTopLoader());
            }),
          );
      }),
      catchError(() => {
        return of(new AppActions.HideLoader(), new AppActions.HideTopLoader());
      }),
    ),
  );

  getCurrentLineData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_CURRENT_LINE_DATA_LOADING),
      switchMap((objectData: ObjectActions.StartCurrentLineDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.lineService.getOngoingActivityOfLine(objectData.lineId).pipe(
          switchMap((response: ResponseInterface<ICurrentLine>) => {
            this.entityTranslation.translate(response.data);

            return of(
              new ObjectActions.CurrentLineDataLoaded(response.data),
              objectData.stallLoader ? new AppActions.ShowLoader() : new AppActions.HideLoader(),
              objectData.stallLoader ? new AppActions.ShowTopLoader() : new AppActions.HideTopLoader(),
            );
          }),
          catchError(() => {
            return of(new AppActions.HideLoader(), new AppActions.HideTopLoader());
          }),
        );
      }),
      catchError(() => {
        return of(new AppActions.HideLoader(), new AppActions.HideTopLoader());
      }),
    ),
  );

  updateActivityDate = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.UPDATE_ACTIVITY_DATES),
      switchMap((objectData: ObjectActions.UpdateActivityDates) => {
        this.store.dispatch(new AppActions.ShowLoader());

        this.store
          .select('activityReview')
          .pipe(take(1))
          .subscribe((activityReview) => {
            this.updateData = activityReview.updateData;
          });

        return this.http
          .patch<UpdateMultipleActivitiesResponseInterface>(
            `${this.baseUrl}/${this.ACTIVITY_REVIEW.UPDATE.DATA_URL}`,
            this.updateData,
            {},
          )
          .pipe(
            switchMap((response: UpdateMultipleActivitiesResponseInterface) => {
              return of(
                new ObjectActions.DatesUpdated(response),
                objectData.stallLoader ? new AppActions.ShowLoader() : new AppActions.HideLoader(),
              );
            }),
            catchError((errorRes) => {
              return of(
                new ObjectActions.FetchError(errorRes, ObjectActions.DATES_UPDATED),
                new AppActions.HideLoader(),
                new AppActions.HideTopLoader(),
              );
            }),
          );
      }),
      catchError(() => {
        return of(new AppActions.HideLoader(), new AppActions.HideTopLoader());
      }),
    ),
  );

  bulkDelete = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.BULK_DELETE_ACTIVITIES),
      switchMap((objectData: ObjectActions.BulkDeleteActivities) => {
        this.store.dispatch(new AppActions.ShowLoader());

        const httpOptions = {
          body: { activityIds: objectData.activityIds },
          headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        };

        return this.http
          .delete<BulkResponseDataInterface>(`${this.baseUrl}/${this.ACTIVITY_REVIEW.DELETE.DATA_URL}`, httpOptions)
          .pipe(
            switchMap((response: BulkResponseDataInterface) => {
              return of(new ObjectActions.BulkDeleteActivitiesDone(response), new AppActions.HideLoader());
            }),
            catchError(() => {
              return of(new AppActions.HideLoader(), new AppActions.HideTopLoader());
            }),
          );
      }),
      catchError(() => {
        return of(new AppActions.HideLoader(), new AppActions.HideTopLoader());
      }),
    ),
  );
}
