import { Action } from '@ngrx/store';
import { BaseOneResponseInterface } from '../../shared/model/interface/crud-response-interface.model';
import {
  AssetDashboardMailBodyInterface,
  AssetDashboardResponseInterface,
  FilterParamsInterface,
  IWidgetData,
} from './asset-view.model';
import { TabRowInterface } from '../../shared/component/side-config-bar/side-config-bar.model';

export const ASSET_VIEW_FILTER_DATA_LOADING = '[ASSET-VIEW] ASSET VIEW FILTER DATA LOADING';
export const ASSET_VIEW_FILTER_DATA_LOADED = '[ASSET-VIEW] ASSET VIEW FILTER DATA LOADED';
export const ASSET_VIEW_DASHBOARD_DATA_LOADING = '[ASSET-VIEW] ASSET VIEW DASHBOARD DATA LOADING';
export const ASSET_VIEW_DASHBOARD_DATA_LOADED = '[ASSET-VIEW] ASSET VIEW DASHBOARD DATA LOADED';
export const ASSET_VIEW_DASHBOARD_MAIL_EXPORT_LOADING = '[ASSET-VIEW] ASSET VIEW DASHBOARD MAIL EXPORT LOADING';
export const ASSET_VIEW_DASHBOARD_MAIL_EXPORT_LOADED = '[ASSET-VIEW] ASSET VIEW DASHBOARD MAIL EXPORT LOADED';
export const ASSET_VIEW_FETCH_ERROR = '[ASSET-VIEW] ASSET VIEW DASHBOARD FETCH ERROR';
export const SET_TABLE_SETTINGS = '[ASSET-VIEW] SET TABLE SETTINGS';
export const ASSET_VIEW_SET_FILTER_SETTINGS = '[ASSET-VIEW] SET FILTER SETTINGS';

export class AssetViewFilterDataLoading implements Action {
  readonly type = ASSET_VIEW_FILTER_DATA_LOADING;

  constructor() {}
}

export class AssetViewFilterDataLoaded implements Action {
  readonly type = ASSET_VIEW_FILTER_DATA_LOADED;

  constructor(public payload: { data: IWidgetData }) {}
}

export class AssetViewDashboardDataLoading implements Action {
  readonly type = ASSET_VIEW_DASHBOARD_DATA_LOADING;

  constructor(public filter: FilterParamsInterface) {}
}

export class AssetViewDashboardDataLoaded implements Action {
  readonly type = ASSET_VIEW_DASHBOARD_DATA_LOADED;

  constructor(public payload: BaseOneResponseInterface<AssetDashboardResponseInterface>) {}
}

export class AssetViewDashboardMailExportLoading implements Action {
  readonly type = ASSET_VIEW_DASHBOARD_MAIL_EXPORT_LOADING;

  constructor(public payload: AssetDashboardMailBodyInterface) {}
}

export class AssetViewDashboardMailExportLoaded implements Action {
  readonly type = ASSET_VIEW_DASHBOARD_MAIL_EXPORT_LOADED;

  constructor() {}
}

export class SetTableSettings implements Action {
  readonly type = SET_TABLE_SETTINGS;
  constructor(public payload: TabRowInterface[], public setAsDefault: boolean) {}
}

export class SetFilterSettings implements Action {
  readonly type = ASSET_VIEW_SET_FILTER_SETTINGS;
  constructor(public siteIds: number[], public lineIds: number[], public setAsDefault: boolean) {}
}

export class FetchError implements Action {
  readonly type = ASSET_VIEW_FETCH_ERROR;

  constructor(public payload: object) {}
}

export type AssetViewActions =
  | AssetViewFilterDataLoading
  | AssetViewFilterDataLoaded
  | AssetViewDashboardDataLoading
  | AssetViewDashboardDataLoaded
  | AssetViewDashboardMailExportLoading
  | AssetViewDashboardMailExportLoaded
  | SetFilterSettings
  | FetchError;
