import { Inject, Injectable } from '@angular/core';
import { FilterServiceInterface, ProductCRUDResponseInterface } from './service.class';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ProductCRUDInterface } from '../../component/filter/filter.class';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../../store/oee.reducer';

@Injectable({
  providedIn: 'root',
})
export class ProductService implements FilterServiceInterface {
  constructor(
    private store: Store<OeeAppState>,
    public http: HttpClient,
    @Inject('API_BASE_URL') private baseUrl: string,
  ) {}

    getData(options?: HttpParams): Promise<ProductCRUDInterface[]> {
    return new Promise((resolve, reject) => {
      this.http
        .get(`${this.baseUrl}/products?join=site`, { params: options })
        .subscribe((response: ProductCRUDResponseInterface) => {
          if (response.hasOwnProperty('data')) {
            resolve(response.data);
          } else {
            reject();
          }
        });
    });
  }
  public getProducts(params?: HttpParams): Observable<ProductCRUDInterface> {
    return this.http.get<ProductCRUDInterface>(`${this.baseUrl}/products`, {
      params,
    });
  }

  public getProductObjects(params?: HttpParams): Observable<ProductCRUDResponseInterface> {
    return this.http.get<ProductCRUDResponseInterface>(`${this.baseUrl}/products`, {
      params,
    });
  }

}
