import { IComparisonFilterCardState } from './comparison-filter-card.model';
import { ComparisonFilterCardActions, ComparisonFilterCardActionTypes } from './comparison-filter-card.actions';

export const initialComparisonFilterCardState: IComparisonFilterCardState = {
  comparisonGroupType: null,
};

export function comparisonFilterCardReducer(
  state: IComparisonFilterCardState = initialComparisonFilterCardState,
  action: ComparisonFilterCardActions,
): IComparisonFilterCardState {
  switch (action.type) {
    case ComparisonFilterCardActionTypes.SetComparisonGroupType:
      return { ...state, comparisonGroupType: action.comparisonGroupType };
    default:
      return state;
  }
}
