import {
  HomeScreenMetricLineInterface,
  HomeScreenMetricSettingsInterface,
  HomeScreenMetricSiteInterface,
  HomeScreenMetricTreeListInterface,
} from './home-screen-metric-settings.model';
import * as Actions from './home-screen-metric-settings.actions';
import * as _ from 'lodash';
import { TreeCheckboxComponentNodeInterface } from '../../shared/component/tree-checkbox/tree-checkbox.component';

export const homeScreenMetricInitialState: HomeScreenMetricSettingsInterface = {
  homeScreenMetricSets: [],
  homeScreenMetricSetDataCount: 0,
  homeScreenMetricSetCurrentPage: 1,
  homeScreenMetricSetRowsPerPage: 20,
  homeScreenMetricsDataLoading: false,
  startHomeScreenMetricsDataLoaded: false,
  homeScreenMetricCopyLoading: false,
  homeScreenMetricCopyLoaded: false,
  homeScreenMetricDeleteLoading: false,
  homeScreenMetricDeleteLoaded: false,
  pagination: {
    page: 1,
    sort: 'name,ASC',
    limit: 10,
  },
  homeScreenMetricOneResponse: null,
  homeScreenMetricTreeListLoading: false,
  homeScreenMetricTreeListLoaded: false,
  homeScreenMetricTreeList: {
    sites: [],
    departmentsAndLines: [],
  },
  homeScreenMetricUpdateLoading: false,
  homeScreenMetricUpdateLoaded: false,
  homeScreenMetricCreateLoading: false,
  homeScreenMetricCreateLoaded: false,
  homeScreenMetricInformationLoading: false,
  startHomeScreenMetricInformationLoaded: false,
  homeScreenMetricSelected: null,
};

export function homeScreenMetricSettingsReducer(
  state: HomeScreenMetricSettingsInterface = homeScreenMetricInitialState,
  action: Actions.HomeScreenMetricSettingsActions,
) {
  switch (action.type) {
    case Actions.HOME_SCREEN_METRICS_DATA_LOADING:
      return {
        ...state,
        ...{
          pagination: {
            page: action.payload.page,
            sort: action.payload.sort,
            limit: action.payload.limit,
          },
        },
        homeScreenMetricsDataLoading: true,
        startHomeScreenMetricsDataLoaded: false,
      };
    case Actions.START_HOME_SCREEN_METRICS_DATA_LOADED:
      return {
        ...state,
        ...{
          homeScreenMetricSets: action.payload.data,
          homeScreenMetricSetDataCount: action.payload.total === 0 ? 1 : action.payload.total,
          homeScreenMetricSetCurrentPage: action.payload.page,
          homeScreenMetricSetRowsPerPage: action.payload.count,
        },
        homeScreenMetricsDataLoading: false,
        startHomeScreenMetricsDataLoaded: true,
      };
    case Actions.COPY_HOME_SCREEN_METRIC_LOADING:
      return {
        ...state,
        homeScreenMetricCopyLoading: true,
        homeScreenMetricCopyLoaded: false,
      };
    case Actions.COPY_HOME_SCREEN_METRIC_LOADED:
      return {
        ...state,
        ...{
          homeScreenMetricOneResponse: action.payload,
        },
        homeScreenMetricCopyLoading: false,
        homeScreenMetricCopyLoaded: true,
      };
    case Actions.DELETE_HOME_SCREEN_METRIC:
      return {
        ...state,
        homeScreenMetricDeleteLoading: true,
        homeScreenMetricDeleteLoaded: false,
      };
    case Actions.DELETE_HOME_SCREEN_METRIC_COMPLETED:
      return {
        ...state,
        ...{
          homeScreenMetricOneResponse: action.payload,
        },
        homeScreenMetricDeleteLoading: false,
        homeScreenMetricDeleteLoaded: true,
      };
    case Actions.TREE_LIST_LOADING:
      return {
        ...state,
        ...{
          homeScreenMetricSelected: action.metricSet,
        },
        homeScreenMetricTreeListLoading: true,
        homeScreenMetricTreeListLoaded: false,
      };
    case Actions.TREE_LIST_LOADED:
      const homeScreenMetricTreeList: HomeScreenMetricTreeListInterface = {
        sites: [],
        departmentsAndLines: [],
      };
      const lines: TreeCheckboxComponentNodeInterface[] = [];
      action.payload.data.forEach((site: HomeScreenMetricSiteInterface) => {
        const homeScreenMetricSites: TreeCheckboxComponentNodeInterface = {
          id: site.id,
          name: site.name,
          siteId: site.id,
          checked: Number(site.id) === Number(state.homeScreenMetricSelected.siteId),
        };
        for (const line of site.line) {
          const homeScreenMetricLines: TreeCheckboxComponentNodeInterface = {
            id: line.id,
            name: line.title,
            lineId: line.id,
            siteId: site.id,
            checked:
              line.homeMetricSetAssignment !== null &&
              state.homeScreenMetricSelected !== null &&
              state.homeScreenMetricSelected.id === line.homeMetricSetAssignment.homeMetricSet.id,
            lineTypeId: line.lineTypeName.id,
            lineType: line.lineTypeName.lineType,
            hints: [
              {
                label: line.homeMetricSetAssignment !== null ? line.homeMetricSetAssignment.homeMetricSet.name : null,
                labelClass: line.homeMetricSetAssignment !== null ? 'label-warning' : 'label-default',
              },
              {
                label: line.status.description === 'inactive' ? line.status.description : null,
                labelClass: line.status.description === 'inactive' ? 'label-default' : null,
              },
            ],
          };
          lines.push(homeScreenMetricLines);
        }

        homeScreenMetricTreeList.sites.push(homeScreenMetricSites);
      });

      if (
        !state.homeScreenMetricSelected.siteId &&
        homeScreenMetricTreeList.sites &&
        homeScreenMetricTreeList.sites.length !== 0
      ) {
        homeScreenMetricTreeList.sites[0].checked = true;
        state.homeScreenMetricSelected.siteId = homeScreenMetricTreeList.sites[0].siteId;
      }

      const departments = _.groupBy(lines, (line: TreeCheckboxComponentNodeInterface) => line.lineType);
      homeScreenMetricTreeList.departmentsAndLines = _.map(departments, (value, key) => {
        const department: TreeCheckboxComponentNodeInterface = {
          id: value[0].lineTypeId,
          lineTypeId: value[0].lineTypeId,
          name: key,
          checked: value.find((line: TreeCheckboxComponentNodeInterface) => line.checked === false) === undefined,
          children: value,
          indeterminate: value.find((line: TreeCheckboxComponentNodeInterface) => line.checked === true) !== undefined,
        };

        if (department.checked) {
          department.indeterminate = false;
        }

        return department;
      });
      return {
        ...state,
        ...{
          homeScreenMetricTreeList,
        },
        homeScreenMetricTreeListLoading: false,
        homeScreenMetricTreeListLoaded: true,
      };
    case Actions.UPDATE_HOME_SCREEN_METRIC:
      return {
        ...state,
        homeScreenMetricUpdateLoading: true,
        homeScreenMetricUpdateLoaded: false,
      };
    case Actions.UPDATE_HOME_SCREEN_METRIC_COMPLETED:
      return {
        ...state,
        ...{
          homeScreenMetricOneResponse: action.payload,
        },
        homeScreenMetricUpdateLoading: false,
        homeScreenMetricUpdateLoaded: true,
      };
    case Actions.CREATE_HOME_SCREEN_METRIC:
      return {
        ...state,
        homeScreenMetricCreateLoading: true,
        homeScreenMetricCreateLoaded: false,
      };
    case Actions.CREATE_HOME_SCREEN_METRIC_COMPLETED:
      return {
        ...state,
        ...{
          homeScreenMetricOneResponse: action.payload,
        },
        homeScreenMetricCreateLoading: false,
        homeScreenMetricCreateLoaded: true,
      };
    case Actions.HOME_SCREEN_METRIC_INFORMATION_LOADING:
      return {
        ...state,
        homeScreenMetricInformationLoading: true,
        startHomeScreenMetricInformationLoaded: false,
      };
    case Actions.HOME_SCREEN_METRIC_INFORMATION_LOADED:
      return {
        ...state,
        ...{
          homeScreenMetricSelected: action.payload.data,
        },
        homeScreenMetricInformationLoading: false,
        startHomeScreenMetricInformationLoaded: true,
      };
    case Actions.CLEAR_TO_STATE:
      return Object.assign({}, homeScreenMetricInitialState);
    default:
      return state;
  }
}
