import * as Actions from './performance-deep-dive.actions';
import { IPerformanceDeepDiveState } from './performance-deep-dive.model';

export const performanceDeepDiveInitialState: IPerformanceDeepDiveState = {
  isPerformanceDeepDiveActivityLogsLoading: false,
  isPerformanceDeepDiveActivityLogsLoaded: false,
  activityLogs: [],
};

export function performanceDeepDiveReducer(
  state: IPerformanceDeepDiveState = performanceDeepDiveInitialState,
  action: Actions.PerformanceDeepDiveActions,
): IPerformanceDeepDiveState {
  switch (action.type) {
    default:
      return state;
  }
}
