import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as UserActions from './../../../store/user/actions';
import * as AppActions from './../../../store/app/actions';
import * as LineActions from './../../../store/line/actions';
import * as SiteLineSelectionActions from './../../../store/site-line-selection/site-line-selection.actions';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as ProductionReviewActions from '../../../store/production-review/production-review.actions';
import * as ProductionReviewListViewActions from '../../../store/production-review/production-review-list-view.actions';
import { OeeAppState } from '../../../store/oee.reducer';
import { Subscription } from 'rxjs';
import * as HomeActions from './../../../store/home/home.actions';
import {
  LineInterface,
  SiteInterface,
  SiteLineSelectionDataInterface,
  SiteLineSelectionInterface,
} from '../../../store/site-line-selection/site-line-selection.model';
import * as _ from 'lodash';

@Component({
  selector: 'app-site-line-selection',
  templateUrl: './site-line-selection.component.html',
  styleUrls: ['./site-line-selection.component.scss', '../../../../styles.scss'],
})
export class SiteLineSelectionComponent implements OnInit, OnDestroy {
  @Input() actionType: string;

  private storeSubscriptions: Subscription[] = [];
  public updateCurrentUserLoading$: boolean;
  public defaultSite$: number = null;
  public site$: number = null;
  public line$: number = null;
  public searchString: string = '';

  constructor(
    private http: HttpClient,
    private store: Store<OeeAppState>,
    private router: Router,
    private modal: NgbModal,
    private translate: TranslateService,
  ) {}

  public selectedSiteId: number;
  public selectedSiteName: string;
  public selectedLineId: number;
  public selectedLineName: string;
  public linesOfSelectedSite = [];
  public lineTypesOfSelectedSite: Partial<SiteLineSelectionDataInterface>[] = [];
  public errors: Partial<SiteLineSelectionInterface>[] = [];
  public sites: Partial<SiteInterface>[] = [];
  public linesAndLineTypes: SiteLineSelectionDataInterface[] = [];

  onClickSite(siteId: number, siteName: string): void {
    this.selectedSiteId = siteId;
    this.selectedSiteName = siteName;
    this.selectedLineId = null;
    this.lineTypesOfSelectedSite = [];
    this.errors = [];
    this.linesOfSelectedSite = [];

    this.linesAndLineTypes.forEach((lineType, lineTypeIndex) => {
      const linesOfSite = this.linesAndLineTypes[lineTypeIndex].line.filter((line) => line.siteId === siteId);
      this.linesOfSelectedSite = this.linesOfSelectedSite.concat(linesOfSite);

      if (linesOfSite.length > 0) {
        this.lineTypesOfSelectedSite.push({
          id: this.linesAndLineTypes[lineTypeIndex].id,
          lineType: this.linesAndLineTypes[lineTypeIndex].lineType,
        });
      }
    });
    this.linesOfSelectedSite = this.sortLines(this.linesOfSelectedSite);
    this.lineTypesOfSelectedSite = this.sortLineTypes(this.lineTypesOfSelectedSite);

    if (this.linesOfSelectedSite.length === 1 &&
      (this.searchString === '' ||
        this.linesOfSelectedSite[0].title.toLowerCase().includes(this.searchString.toLowerCase()))) {
      this.onClickLine(this.linesOfSelectedSite[0].id, this.linesOfSelectedSite[0].title);
    }

    if (this.linesOfSelectedSite.length === 0) {
      this.errors.push(this.translate.instant('siteLineSelection.lineError'));
    }
  }

  onClickLine(lineId, lineName: string): void {
    this.selectedLineId = lineId;
    this.selectedLineName = lineName;
  }

  selectSiteAndLine() {
    if (this.selectedSiteId !== null && this.selectedLineId !== null && this.updateCurrentUserLoading$ === false) {
      this.store.dispatch(
        new UserActions.UpdateCurrentUser(this.selectedSiteId, this.selectedLineId, this.router.url !== '/home'),
      );
      this.store.dispatch(new HomeActions.ResetPhaseDurationOnSiteLineSelection());
    }
  }

  ngOnInit() {
    this.store.dispatch(new SiteLineSelectionActions.LoadSitesLines());
    this.storeSubscriptions.push(
      this.store.select('user').subscribe((state) => {
        this.defaultSite$ = state.defaultSite !== null ? Number(state.defaultSite) : null;
        this.site$ = state.siteId;
        this.line$ = state.lineId;
        this.updateCurrentUserLoading$ = state.updateCurrentUserLoading;

        if (state.updateCurrentUserLoaded && !state.updateCurrentUserLoading) {
          if (this.router.url === '/home') {
            this.store.dispatch(new LineActions.UpdateActivity({ lineChanged: true }));
            this.modal.dismissAll();
          } else {
            let redirectUrl = '/home';
            if (this.router.url !== '/select-site-and-line') {
              redirectUrl = this.router.url;
            }
            this.modal.dismissAll();
            this.router.navigate([redirectUrl]).then(() => {
              this.store.dispatch(new AppActions.HideLoader());
            });
          }

          if (this.modal !== undefined) {
            this.modal.dismissAll();
          }

          this.store.dispatch(new ProductionReviewActions.ClearToState());
          this.store.dispatch(new ProductionReviewListViewActions.ClearState());
        }
      }),
      this.store.select('siteLineSelectionStore').subscribe((state) => {
        if (state.isLinesOfSiteLinesSelectionLoaded) {
          this.sites = state.sites;
          this.linesAndLineTypes = _(state.linesAndLineTypes)
            .groupBy('lineType')
            .map((items, lineType) => {
              return {
                id: parseInt(lineType, 10),
                lineType: items[0].lineTypeName.lineType,
                line: _.map(items),
              };
            })
            .value();

          if (!_.isNil(this.site$) && !_.isNil(this.line$)) {
            const selectedSite = _.find(this.sites, { id: this.site$ });

            this.onClickSite(selectedSite?.id, selectedSite?.name);

            const selectedLine = _.find(this.linesOfSelectedSite, { id: this.line$ });

            this.onClickLine(selectedLine?.id, selectedLine?.title);

            return;
          }

          if (this.sites.length === 1) {
            this.onClickSite(this.sites[0].id, this.sites[0].name);
            this.selectSiteAndLine();
            return;
          }

          if (this.sites.length > 1 && this.defaultSite$ !== null) {
            const defaultSite = _.find(this.sites, { id: this.defaultSite$ });

            if (!_.isNil(defaultSite)) {
              this.onClickSite(defaultSite.id, defaultSite.name);
            }
          }
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.storeSubscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

  public sortLines(lines: LineInterface[]): LineInterface[] {
    lines.sort((a, b) => {
      return a.title.localeCompare(b.title, undefined, {
        numeric: true,
        sensitivity: 'base',
      });
    });
    return lines;
  }

  public sortLineTypes(
    lineTypes: Partial<SiteLineSelectionDataInterface>[],
  ): Partial<SiteLineSelectionDataInterface>[] {
    lineTypes.sort((a, b) => {
      return a.lineType.toLocaleLowerCase().localeCompare(b.lineType.toLocaleLowerCase(), undefined, {
        numeric: true,
        sensitivity: 'base',
      });
    });
    return lineTypes;
  }

  public onSearch(): void {
    this.selectedLineId = null;
    this.selectedLineName = null;
  }
}
