import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  ProductSpeedAnalysisInterface,
  SetProductSpeedInterface,
  SetProductSpeedResponseInterface,
} from '../../../store/reports/product-speed-analysis/product-speed-analysis.model';
import { GetManyResponseInterface } from '../../model/interface/crud-response-interface.model';
import { AddProductSpeedResponseInterface } from '../../../store/settings/product-speeds/product-speeds.model';

@Injectable({
  providedIn: 'root',
})
export class ProductSpeedAnalysisService {
  constructor(public http: HttpClient, @Inject('API_BASE_URL') private readonly api: string) {}

  private readonly routes = {
    productSpeedAnalysis: `${this.api}/product-speeds/product-speed-analysis`,
    setProductSpeed: `${this.api}/product-speeds/product-speed/save`,
  };

  public productSpeedAnalysisDataLoading(
    params: HttpParams,
  ): Observable<GetManyResponseInterface<ProductSpeedAnalysisInterface>> {
    return this.http.get<GetManyResponseInterface<ProductSpeedAnalysisInterface>>(this.routes.productSpeedAnalysis, {
      params,
    });
  }

  public setProductSpeed(productSpeed: SetProductSpeedInterface): Observable<AddProductSpeedResponseInterface> {
    return this.http.post<SetProductSpeedResponseInterface>(`${this.routes.setProductSpeed}`, productSpeed);
  }

  public static setProductSpeedAnalysisProductName(productSpeedAnalysis: ProductSpeedAnalysisInterface): void {
    productSpeedAnalysis.productName = `${productSpeedAnalysis.productName} - ${productSpeedAnalysis.productDescription}`;
  }
}
