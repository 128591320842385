import { EquipmentListsStateInterface } from './equipment-lists.model';
import * as Actions from './equipment-lists.actions';

export const equipmentListState: EquipmentListsStateInterface = {
  equipmentListsEquipmentListDataLoading: false,
  equipmentListsEquipmentListDataLoaded: false,
  equipmentListsSiteDataLoading: false,
  equipmentListsSiteDataLoaded: false,
  siteData: [],
  equipmentListData: null,
  fileDownloadStatus: false,
  excelUploadResult: [],
  isUploadExcelLoading: false,
  isUploadExcelLoaded: false,
};

export function equipmentListReducer(
  state: EquipmentListsStateInterface = equipmentListState,
  action: Actions.EquipmentListsActions,
): EquipmentListsStateInterface {
  switch (action.type) {
    case Actions.EQUIPMENT_LISTS_EQUIPMENT_LIST_DATA_LOADING:
      return {
        ...state,
        ...{
          equipmentListsEquipmentListDataLoading: true,
          equipmentListsEquipmentListDataLoaded: false,
        },
      };
    case Actions.EQUIPMENT_LISTS_EQUIPMENT_LIST_DATA_LOADED:
      return {
        ...state,
        ...{
          equipmentListData: action.payload,
          equipmentListsEquipmentListDataLoading: false,
          equipmentListsEquipmentListDataLoaded: true,
        },
      };
    case Actions.EQUIPMENT_LISTS_SITES_DATA_LOADING:
      return {
        ...state,
        ...{
          equipmentListsSiteDataLoading: true,
          equipmentListsSiteDataLoaded: false,
        },
      };
    case Actions.EQUIPMENT_LISTS_SITES_DATA_LOADED:
      return {
        ...state,
        ...{
          siteData: action.payload,
          equipmentListsSiteDataLoading: false,
          equipmentListsSiteDataLoaded: true,
        },
      };
    case Actions.DOWNLOAD_EQUIPMENT_LIST_EXCEL:
      return {
        ...state,
        ...{
          fileDownloadStatus: true,
        },
      };
    case Actions.DOWNLOAD_EQUIPMENT_LIST_EXCEL_COMPLETED:
      return {
        ...state,
        ...{
          fileDownloadStatus: false,
        },
      };
    case Actions.UPLOAD_EQUIPMENT_LIST_EXCEL:
      return {
        ...state,
        ...{
          isUploadExcelLoading: true,
          isUploadExcelLoaded: false,
        },
      };
    case Actions.UPLOAD_EQUIPMENT_LIST_EXCEL_COMPLETED:
      return {
        ...state,
        ...{
          excelUploadResult: action.payload,
          isUploadExcelLoading: false,
          isUploadExcelLoaded: true,
        },
      };
    default:
      return state;
  }
}
