import { Action } from '@ngrx/store';
import {
  ETableType,
  IExpectedSpeed,
  IExpectedSpeedFilter,
  IOeeCalculationResult,
  IWorkOrderComment,
  IWorkOrderLaborAssetInformation,
  IWorkOrderLaborAssetTableInformation,
  IWorkOrderPerformanceFilter,
  IWorkOrderPhaseInformation,
} from './work-order-performance.model';
import { WorkOrderInterface } from '../../work-order-schedule/work-order-schedule.model';
import { IActivityLog } from '../root-cause-analysis/root-cause-analysis.model';

export const WORK_ORDER_INFORMATION_LOADING = '[WORK ORDER PERFORMANCE] WORK ORDER INFORMATION LOADING';
export const WORK_ORDER_INFORMATION_LOADED = '[WORK ORDER PERFORMANCE] WORK ORDER INFORMATION LOADED';
export const WORK_ORDER_ACTIVITY_LOGS_LOADING = '[WORK ORDER PERFORMANCE] WORK ORDER ACTIVITY LOGS LOADING';
export const WORK_ORDER_ACTIVITY_LOGS_LOADED = '[WORK ORDER PERFORMANCE] WORK ORDER ACTIVITY LOGS LOADED';
export const WORK_ORDER_OEE_RESULT_LOADING = '[WORK ORDER PERFORMANCE] WORK ORDER OEE CALCULATION RESULT LOADING';
export const WORK_ORDER_OEE_RESULT_LOADED = '[WORK ORDER PERFORMANCE] WORK ORDER OEE CALCULATION RESULT LOADED';
export const WORK_ORDER_PHASE_INFORMATION_LOADING = '[WORK ORDER PERFORMANCE] WORK ORDER PHASE INFORMATION LOADING';
export const WORK_ORDER_PHASE_INFORMATION_LOADED = '[WORK ORDER PERFORMANCE] WORK ORDER PHASE INFORMATION LOADED';
export const WORK_ORDER_LABOR_INFORMATION_LOADING = '[WORK ORDER PERFORMANCE] WORK ORDER LABOR INFORMATION LOADING';
export const WORK_ORDER_LABOR_INFORMATION_LOADED = '[WORK ORDER PERFORMANCE] WORK ORDER LABOR INFORMATION LOADED';
export const PRODUCT_TARGET_SPEED_LOADING = '[WORK ORDER PERFORMANCE] PRODUCT TARGET SPEED LOADING';
export const PRODUCT_TARGET_SPEED_LOADED = '[WORK ORDER PERFORMANCE] PRODUCT TARGET SPEED LOADED';
export const COMMENTS_LOADING = '[WORK ORDER PERFORMANCE] COMMENTS LOADING';
export const COMMENTS_LOADED = '[WORK ORDER PERFORMANCE] COMMENTS LOADED';
export const DOWNLOAD_LABOR_ASSET_INFORMATION_EXCEL = '[WORK ORDER PERFORMANCE] DOWNLOAD LABOR ASSET INFORMATION EXCEL';
export const FETCH_DATA_ERROR = '[WORK ORDER PERFORMANCE] FETCH DATA ERROR';

export class WorkOrderInformationLoading implements Action {
  readonly type = WORK_ORDER_INFORMATION_LOADING;

  constructor(public params: IWorkOrderPerformanceFilter) {}
}

export class WorkOrderInformationLoaded implements Action {
  readonly type = WORK_ORDER_INFORMATION_LOADED;

  constructor(public payload: WorkOrderInterface) {}
}

export class WorkOrderActivityLogsLoading implements Action {
  readonly type = WORK_ORDER_ACTIVITY_LOGS_LOADING;

  constructor(public params: IWorkOrderPerformanceFilter) {}
}

export class WorkOrderActivityLogsLoaded implements Action {
  readonly type = WORK_ORDER_ACTIVITY_LOGS_LOADED;

  constructor(public payload: IActivityLog[]) {}
}

export class WorkOrderOeeResultLoading implements Action {
  readonly type = WORK_ORDER_OEE_RESULT_LOADING;

  constructor(public params: IWorkOrderPerformanceFilter) {}
}

export class WorkOrderOeeResultLoaded implements Action {
  readonly type = WORK_ORDER_OEE_RESULT_LOADED;

  constructor(public payload: IOeeCalculationResult) {}
}

export class WorkOrderPhaseInformationLoading implements Action {
  readonly type = WORK_ORDER_PHASE_INFORMATION_LOADING;

  constructor(public params: IWorkOrderPerformanceFilter) {}
}

export class WorkOrderPhaseInformationLoaded implements Action {
  readonly type = WORK_ORDER_PHASE_INFORMATION_LOADED;

  constructor(public payload: IWorkOrderPhaseInformation) {}
}

export class WorkOrderLaborInformationLoading implements Action {
  readonly type = WORK_ORDER_LABOR_INFORMATION_LOADING;

  constructor(public params: IWorkOrderPerformanceFilter) {}
}

export class WorkOrderLaborInformationLoaded implements Action {
  readonly type = WORK_ORDER_LABOR_INFORMATION_LOADED;

  constructor(public payload: IWorkOrderLaborAssetInformation[]) {}
}

export class ProductTargetSpeedLoading implements Action {
  readonly type = PRODUCT_TARGET_SPEED_LOADING;

  constructor(public params: IExpectedSpeedFilter) {}
}

export class ProductTargetSpeedLoaded implements Action {
  readonly type = PRODUCT_TARGET_SPEED_LOADED;

  constructor(public payload: IExpectedSpeed) {}
}

export class CommentsLoading implements Action {
  readonly type = COMMENTS_LOADING;

  constructor(public workOrderId: number) {}
}

export class CommentsLoaded implements Action {
  readonly type = COMMENTS_LOADED;

  constructor(public payload: IWorkOrderComment[]) {}
}

export class DownloadLaborAssetInformationExcel implements Action {
  readonly type = DOWNLOAD_LABOR_ASSET_INFORMATION_EXCEL;

  constructor(public data: IWorkOrderLaborAssetTableInformation[], public excelType: ETableType) {}
}

export class FetchDataError implements Action {
  readonly type = FETCH_DATA_ERROR;

  constructor(public payload: object) {}
}

export type WorkOrderPerformanceActions =
  | WorkOrderInformationLoading
  | WorkOrderInformationLoaded
  | WorkOrderActivityLogsLoading
  | WorkOrderActivityLogsLoaded
  | WorkOrderOeeResultLoading
  | WorkOrderOeeResultLoaded
  | WorkOrderPhaseInformationLoading
  | WorkOrderPhaseInformationLoaded
  | WorkOrderLaborInformationLoading
  | WorkOrderLaborInformationLoaded
  | ProductTargetSpeedLoading
  | ProductTargetSpeedLoaded
  | CommentsLoading
  | CommentsLoaded
  | DownloadLaborAssetInformationExcel
  | FetchDataError;
