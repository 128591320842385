import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import * as ObjectActions from './user-configuration.actions';
import { of } from 'rxjs';
import { IUserConfigurationResponse } from './user-configuration.model';
import { PageFiltersAndSettingsService } from '../../shared/service/page-filters-and-settings/page-filters-and-settings.service';
import { ResponseInterface } from '../../shared/model/interface/generic-api-response.model';
import * as AppActions from '../app/actions';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../oee.reducer';

@Injectable()
export class UserConfigurationEffects {
  constructor(
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly actions$: Actions,
    private readonly http: HttpClient,
    private readonly service: PageFiltersAndSettingsService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
  ) {}

  getLineStationData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_USER_CONFIGURATION_DATA_LOADING),
      switchMap((objectData: ObjectActions.GetUserConfigurationLoading) => {
        return this.service.getUserConfigurationData().pipe(
          switchMap((response: ResponseInterface<IUserConfigurationResponse>) => {
            return of(new ObjectActions.GetUserConfigurationLoaded(response.data.configuration));
          }),
          catchError((err) => {
            return of(new ObjectActions.UserConfigurationFetchError(err));
          }),
        );
      }),
    ),
  );

  updateLineStationData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.UPDATE_USER_CONFIGURATION_LOADING),
      switchMap((objectData: ObjectActions.UpdateUserConfigurationLoading) => {
        this.store.dispatch(new AppActions.ShowTopLoader());
        return this.service.updateUserConfigurationData(objectData.configuration).pipe(
          switchMap((response: ResponseInterface<IUserConfigurationResponse>) => {
            return of(
              new ObjectActions.UpdateUserConfigurationLoaded(response.data.configuration),
              new AppActions.HideTopLoader(),
            );
          }),
          catchError((err) => {
            return of(new ObjectActions.UserConfigurationFetchError(err));
          }),
        );
      }),
    ),
  );
}
