import { FilterProductActions, FilterProductActionTypes } from './product.actions';
import { ProductCRUDInterface } from '../../../shared/component/filter/filter.class';
import * as _ from 'lodash';

export interface FilterProductState {
  isLoading: boolean;
  isLoaded: boolean;
  data: ProductCRUDInterface[];
  totalCount: number;
  allowNewRequest: boolean;
}

export const initialFilterProductState: FilterProductState = {
  isLoaded: false,
  isLoading: false,
  data: [],
  totalCount: 0,
  allowNewRequest: false,
};

export function filterProductReducer(
  state = initialFilterProductState,
  action: FilterProductActions,
): FilterProductState {
  switch (action.type) {
    case FilterProductActionTypes.StartLoadFilterProducts:
      return { ...state, isLoading: true };

    case FilterProductActionTypes.FilterProductsLoaded:
      return {
        ...state,
        data: action.isFetchMoreRequest ? _.uniqBy(state.data.concat(action.data), 'id') : action.data,
        allowNewRequest: action.totalCount > 0 && action.data.length > 0 && action.totalCount > action.data.length,
        totalCount: action.totalCount,
        isLoaded: true,
        isLoading: false,
      };

    case FilterProductActionTypes.ResetProductStore:
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
        data: [],
      };
    default:
      return state;
  }
}
