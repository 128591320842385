import { Action } from '@ngrx/store';
import {
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { IEquipmentBrandsRequestParams } from '../../../view/settings/equipment-assets/equipment-brands/equipment-brands.model';
import { IEquipmentBrand } from './equipment-brands.model';

export const LOAD_EQUIPMENT_BRANDS_DATA = '[EQUIPMENT BRANDS] LOAD EQUIPMENT BRANDS DATA';
export const LOADED_EQUIPMENT_BRANDS_DATA = '[EQUIPMENT BRANDS] LOADED EQUIPMENT BRANDS DATA';
export const SINGLE_EDIT_EQUIPMENT_BRANDS_DATA = '[EQUIPMENT BRANDS] REQUEST SINGLE EDIT EQUIPMENT BRANDS DATA';
export const EDITED_SINGLE_EDIT_EQUIPMENT_BRANDS_DATA = '[EQUIPMENT BRANDS] EDITED SINGLE EDIT EQUIPMENT BRANDS DATA';
export const EDIT_EQUIPMENT_BRANDS_DATA = '[EQUIPMENT BRANDS] REQUEST EDIT EQUIPMENT BRANDS DATA';
export const EDITED_EQUIPMENT_BRANDS_DATA = '[EQUIPMENT BRANDS] EDITED EQUIPMENT BRANDS DATA';
export const CREATE_EQUIPMENT_BRAND = '[EQUIPMENT BRANDS] CREATE EQUIPMENT BRAND DATA';
export const CREATED_EQUIPMENT_BRAND = '[EQUIPMENT BRANDS] CREATED EQUIPMENT BRAND DATA';
export const DELETE_EQUIPMENT_BRAND = '[EQUIPMENT BRANDS] DELETE EQUIPMENT BRAND';
export const DELETED_EQUIPMENT_BRAND = '[EQUIPMENT BRANDS] DELETED EQUIPMENT BRAND';
export const DELETE_EQUIPMENT_BRANDS = '[EQUIPMENT BRANDS] DELETE EQUIPMENT BRANDS';
export const DELETED_EQUIPMENT_BRANDS = '[EQUIPMENT BRANDS] DELETED EQUIPMENT BRANDS';
export const EQUIPMENT_BRANDS_FETCH_ERROR = '[EQUIPMENT BRANDS] EQUIPMENT BRANDS ACTION FETCH ERROR';

export class LoadEquipmentBrandsData implements Action {
  readonly type = LOAD_EQUIPMENT_BRANDS_DATA;

  constructor(public requestParams: IEquipmentBrandsRequestParams) {}
}

export class LoadedEquipmentBrandsData implements Action {
  readonly type = LOADED_EQUIPMENT_BRANDS_DATA;

  constructor(public payload: GetManyResponseInterface<IEquipmentBrand>) {}
}

export class SingleEditEquipmentBrandsData implements Action {
  readonly type = SINGLE_EDIT_EQUIPMENT_BRANDS_DATA;

  constructor(public equipmentId: number, public payload: Partial<IEquipmentBrand>) {}
}

export class EditedSingleEditEquipmentBrandsData implements Action {
  readonly type = EDITED_SINGLE_EDIT_EQUIPMENT_BRANDS_DATA;

  constructor(public response: GetManyResponseInterface<IEquipmentBrand>) {}
}

export class EditEquipmentBrandsData implements Action {
  readonly type = EDIT_EQUIPMENT_BRANDS_DATA;

  constructor(public payload: Partial<IEquipmentBrand>[]) {}
}

export class EditedEquipmentBrandsData implements Action {
  readonly type = EDITED_EQUIPMENT_BRANDS_DATA;

  constructor(public response: BulkResponseDataInterface) {}
}

export class CreateEquipmentBrand implements Action {
  readonly type = CREATE_EQUIPMENT_BRAND;

  constructor(public payload: Partial<IEquipmentBrand>) {}
}

export class CreatedEquipmentBrand implements Action {
  readonly type = CREATED_EQUIPMENT_BRAND;

  constructor(public response: GetManyResponseInterface<IEquipmentBrand>) {}
}

export class DeleteEquipmentBrand implements Action {
  readonly type = DELETE_EQUIPMENT_BRAND;

  constructor(public equipmentBrandId: number) {}
}

export class DeletedEquipmentBrand implements Action {
  readonly type = DELETED_EQUIPMENT_BRAND;

  constructor(public response: GetManyResponseInterface<IEquipmentBrand>) {}
}

export class DeleteEquipmentBrands implements Action {
  readonly type = DELETE_EQUIPMENT_BRANDS;

  constructor(public equipmentBrandIds: number[]) {}
}

export class DeletedEquipmentBrands implements Action {
  readonly type = DELETED_EQUIPMENT_BRANDS;

  constructor(public response: BulkResponseDataInterface) {}
}

export class FetchError implements Action {
  readonly type = EQUIPMENT_BRANDS_FETCH_ERROR;
  constructor(public payload: object) {}
}

export type EquipmentBrandsActions =
  | LoadEquipmentBrandsData
  | LoadedEquipmentBrandsData
  | SingleEditEquipmentBrandsData
  | EditedEquipmentBrandsData
  | CreateEquipmentBrand
  | CreatedEquipmentBrand
  | DeleteEquipmentBrand
  | DeletedEquipmentBrand
  | DeleteEquipmentBrands
  | DeletedEquipmentBrands
  | FetchError;
