import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { switchMap, take, catchError, finalize } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as ObjectActions from './line-view-work-order-summary.actions';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { OeeAppState } from '../oee.reducer';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { LineViewWorkOrderSummaryService } from '../../shared/service/line-view-work-order-summary/line-view-work-order-summary.service';
import * as UserConfigurationActions from '../user-configuration/user-configuration.actions';
import * as AppActions from '../app/actions';
import * as oeeAppReducer from '../oee.reducer';
import { LineService } from '../../shared/service/filter/line.service';
import { LineCRUDResponseInterface } from '../../shared/service/filter/service.class';
import { EntityTranslatorService } from '../../shared/service/entity-translator/entity-translator.service';
import { ILinesReportInterface } from './line-view-work-order-summary.model';

export interface FilterInterface {
  selectedLines: number[];
  selectedSites: number[];
  selectedColumns: any[];
  selectedWidgets: any[];
}

@Injectable()
export class LineViewWorkOrderSummaryEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly http: HttpClient,
    private readonly toast: ToastrService,
    private readonly translate: TranslateService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly service: LineViewWorkOrderSummaryService,
    private readonly lineService: LineService,
    private readonly entityTranslatorService: EntityTranslatorService,
  ) {}

  private selectedSiteFilters;
  private selectedLineFilters;
  private isLineListVisible;
  private tableColumns: any[];
  private selectedWidgets: any[];
  private LINE_VIEW = {
    GET: {
      FILTER_URL: '/api/live-dashboard-filters-and-settings',
      DATA_URL: '/api/live-dashboard',
      EXPORT_FILE_URL: '/livedashboard',
    },
  };

  fetchLineViewFilterData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_LINE_VIEW_WORK_ORDER_SUMMARY_FILTER_DATA_LOADING),
      switchMap((objectData: ObjectActions.StartLineViewFilterDataLoading) => {
        this.store
          .select('lineViewWorkOrderSummaryStore')
          .pipe(take(1))
          .subscribe((state) => {
            this.isLineListVisible = state.isLineListVisible;
          });

        return this.service.getFiltersAndSettings(objectData.userId).pipe(
          switchMap((resData) => {
            return of(
              new ObjectActions.LineViewFilterDataLoaded(resData),
              new ObjectActions.GetLineViewDataStarted(this.isLineListVisible ? 'all' : 'card'),
            );
          }),
        );
      }),
    ),
  );

  setFilterDataLoaded = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.WORK_ORDER_SUMMARY_FILTER_DATA_GENERATED),
      switchMap((objectData: ObjectActions.SetFilterDataGenerated) => {
        return of(
          new ObjectActions.SetSelectedSiteFilters(objectData.payload.siteData),
          new ObjectActions.SetSelectedLineFilters(objectData.payload.lineData),
        );
      }),
    ),
  );

  exportLineViewFiles = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.EXPORT_LINE_VIEW_WORK_ORDER_SUMMARY_FILES),
      switchMap((objectData: ObjectActions.ExportLineViewFiles) => {
        const filters = this.getSelectedFilterTypeIds();

        this.downloadFile(objectData.exportType, filters).subscribe((response) => {
          const contentDisposition = response.headers.get('content-disposition');

          if (contentDisposition !== null) {
            const filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
            const blob = new Blob([response.body], { type: response.body.type });
            const url = window.URL.createObjectURL(blob);
            const element = document.createElement('a');
            element.href = url;
            element.download = filename;
            element.setAttribute('style', 'display:none;');
            document.body.appendChild(element);
            element.click();
          }
        });
        return of(new ObjectActions.ExportLineViewFilesDone());
      }),
    ),
  );

  getLineViewDataStarted = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_LINE_VIEW_WORK_ORDER_SUMMARY_DATA_STARTED),
      switchMap((objectData: ObjectActions.GetLineViewDataStarted) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const filters = this.getSelectedFilterTypeIds();
        if (filters.selectedLines?.length === 0 || filters.selectedSites?.length === 0) {
          const errorMessage = this.translate.instant('lineView.emptyFilterErrorMessage');
          return of(new ObjectActions.FetchDataError({ errorMessage }), new AppActions.HideLoader());
        }
        const search = {
          id: { $in: filters.selectedLines },
          siteId: { $in: filters.selectedSites },
        };
        const queryParams = new HttpParams()
          .set('reportType', encodeURI(objectData.mode === 'all' ? 'all' : 'summary'))
          .set('s', JSON.stringify(search))
          .set('columns', encodeURI(filters.selectedColumns.map((x: any) => x.name).join(',')))
          .set('widgetConfiguration', encodeURI(filters.selectedWidgets.map((x: any) => x.name).join(',')));

        return this.service.getLineViewData(queryParams).pipe(
          switchMap((resData: ILinesReportInterface) => {
            if (resData.hasOwnProperty('data') && resData['data'].hasOwnProperty('lines')) {
              resData['data']['lines'].forEach((line) => this.entityTranslatorService.translate(line));
            }

            return of(
              new ObjectActions.LineViewDataLoaded(resData['data'], objectData.mode),
              new AppActions.HideLoader(),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchDataError(errorRes), new AppActions.HideLoader());
          }),
          finalize(() => {
            this.store.dispatch(new AppActions.HideLoader());
            this.store.dispatch(new ObjectActions.CancelLoading());
          }),
        );
      }),
    ),
  );

  startFiltersAndSettingsSaving = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.WORK_ORDER_SUMMARY_START_FILTERS_AND_SETTINGS_SAVING),
      switchMap(() => {
        const filters = this.getSelectedFilterTypeIds();
        return of(
          new UserConfigurationActions.UpdateUserConfigurationLoading({
            LineViewWorkOrderSummaryComponent: [
              {
                name: 'filters',
                children: [
                  {
                    name: 'siteIds',
                    value: filters.selectedSites,
                  },
                  {
                    name: 'lineIds',
                    value: filters.selectedLines,
                  },
                ],
              },
              {
                name: 'tableColumns',
                value: filters.selectedColumns.map((column) => column.name),
              },
              {
                name: 'widgetSettings',
                value: filters.selectedWidgets.map((column) => column.name),
              },
            ],
          }),
        );
      }),
    ),
  );

  getLineCountOfSites = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LINE_COUNT_OF_SITES_LOADING),
      switchMap((objectData: ObjectActions.LineCountOfSitesLoading) => {
        const httpParams: HttpParams = new HttpParams()
          .set('limit', '1')
          .set('s', JSON.stringify({ siteId: { $in: objectData.selectedSites } }));

        return this.lineService.getLines(httpParams).pipe(
          switchMap((response: LineCRUDResponseInterface) => {
            return of(new ObjectActions.LineCountOfSitesLoaded(response.total));
          }),
        );
      }),
    ),
  );

  private downloadFile(exportType: string, filters: FilterInterface) {
    const options = {
      params: new HttpParams()
        .set('period', encodeURI(moment(new Date()).format('YYYY-MM-DD')))
        .set('selectedLines', encodeURI(filters.selectedLines.map((line) => line).join(',')))
        .set('selectedSites', encodeURI(filters.selectedSites.map((site) => site).join(',')))
        .set('periodType', 'TwelveMonth')
        .set('columns', encodeURI(filters.selectedColumns.map((column) => column.name).join(','))),
    };

    return this.http.get<any>(`${this.LINE_VIEW.GET.EXPORT_FILE_URL}/${exportType}`, {
      ...options,
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }

  private getSelectedFilterTypeIds() {
    const returnObj: FilterInterface = {
      selectedLines: [],
      selectedSites: [],
      selectedColumns: [],
      selectedWidgets: [],
    };

    this.store
      .select('lineViewWorkOrderSummaryStore')
      .pipe(take(1))
      .subscribe((state) => {
        this.selectedSiteFilters = state.sitesFilter;
        this.selectedLineFilters = state.linesFilter;
        this.tableColumns = state.tableColumns;
        this.selectedWidgets = state.widgets;
      });
    returnObj.selectedSites = this.selectedSiteFilters;
    returnObj.selectedLines = this.selectedLineFilters;

    this.tableColumns.forEach((column) => {
      if (column.selected || column.name === 'lineName') {
        returnObj.selectedColumns.push(column);
      }
    });

    if (this.selectedWidgets !== undefined) {
      this.selectedWidgets.forEach((widget) => {
        if (widget.selected) {
          returnObj.selectedWidgets.push(widget);
        }
      });
    }

    return returnObj;
  }
}
