import * as ObjectActions from './notification-groups.actions';
import * as AppActions from '../../../app/actions';
import * as oeeAppReducer from '../../../oee.reducer';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { catchError, switchMap } from 'rxjs/operators';
import { NotificationGroupsService } from './notification-groups.service';
import { ErrorMessageService } from '../../../../shared/service/error-message.service';
import { HttpParams } from '@angular/common/http';
import { SiteService } from '../../../../shared/service/filter/site.service';
import { from, of } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  BaseCrudResponse,
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../../shared/model/interface/crud-response-interface.model';
import {
  NotificationGroupInterface,
  NotificationMailRecipient,
  NotificationRecipientSystemUser,
} from './notification-groups.model';
import { UserSettingsService } from '../../users/user.service';
import { ExcelHelperService } from '../../../../shared/service/excel/excel-helper.service';

@Injectable()
export class NotificationGroupsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: NotificationGroupsService,
    private readonly siteService: SiteService,
    private readonly userService: UserSettingsService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly errorMessageService: ErrorMessageService,
    private readonly excelHelperService: ExcelHelperService,
  ) {}

  getSiteData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.NOTIFICATION_GROUPS_SITES_DATA_LOADING),
      switchMap((payload: ObjectActions.NotificationGroupsSiteDataLoading) => {
        const httpParams: HttpParams = new HttpParams().set('limit', '1000');

        return from(this.siteService.getData(httpParams)).pipe(
          switchMap((response) => {
            return of(new ObjectActions.NotificationGroupsSiteDataLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getNotificationGroupData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.NOTIFICATION_GROUPS_DATA_LOADING),
      switchMap((payload: ObjectActions.NotificationGroupsDataLoading) => {
        return this.service.getNotificationGroups(this.service.getHttpOptions(payload.payload)).pipe(
          switchMap((response: GetManyResponseInterface<NotificationGroupInterface>) => {
            return of(new ObjectActions.NotificationGroupsDataLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  deleteNotificationGroups = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DELETE_NOTIFICATION_GROUPS),
      switchMap((objectData: ObjectActions.DeleteNotificationGroups) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.deleteNotificationGroups(objectData.payload).pipe(
          switchMap((response: BaseCrudResponse | BulkResponseDataInterface) => {
            const responseMessages = 'data' in response && Array.isArray(response.data) ? response.data : [response];
            this.errorMessageService.getTranslatedErrorMessage(responseMessages);
            const mergedArray: ({ id: number } & {
              errorMessages?: string;
            })[] = this.excelHelperService.mergeBulkResponseWithRequestData(
              { ...response, data: responseMessages },
              objectData.payload.map((item) => {
                return { id: item };
              }),
              true,
            );
            return of(new ObjectActions.DeleteNotificationGroupsCompleted(mergedArray), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  addNotificationGroup = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ADD_NOTIFICATION_GROUP),
      switchMap((objectData: ObjectActions.AddNotificationGroup) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.addNotificationGroup(objectData.notificationGroup).pipe(
          switchMap((response: BaseOneResponseInterface<NotificationGroupInterface>) => {
            return of(new ObjectActions.AddNotificationGroupCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  editNotificationGroup = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.EDIT_NOTIFICATION_GROUP),
      switchMap((objectData: ObjectActions.EditNotificationGroup) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.editNotificationGroup(objectData.notificationGroup, objectData.id).pipe(
          switchMap((response: BaseOneResponseInterface<NotificationGroupInterface>) => {
            return of(new ObjectActions.EditNotificationGroupCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  getUserData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.NOTIFICATION_GROUPS_RECIPIENTS_USERS_DATA_LOADING),
      switchMap((payload: ObjectActions.NotificationGroupsRecipientsUsersDataLoading) => {
        let httpParams: HttpParams = new HttpParams()
          .set('limit', '10')
          .set('fields', 'fullName,userName,phone,email')
          .set('siteId', String(payload.siteId));

        const searchObject = {
          $and: [
            {
              $or: [
                { username: { $cont: payload.searchText } },
                { $or: [{ fullName: { $cont: payload.searchText } }] },
              ],
            },
            {
              isActive: true,
            },
            ...(payload.excludedIds?.length ? [{ id: { $notin: payload.excludedIds } }] : []),
          ],
        };

        httpParams = httpParams.set('s', JSON.stringify(searchObject));

        return this.userService.getUsers(httpParams).pipe(
          switchMap((response) => {
            return of(
              new ObjectActions.NotificationGroupsRecipientsUsersDataLoaded(
                response.data as unknown as NotificationRecipientSystemUser[],
              ),
            );
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  saveNotificationGroupsRecipients = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SAVE_NOTIFICATION_GROUPS_RECIPIENTS),
      switchMap((objectData: ObjectActions.SaveNotificationGroupsRecipients) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.saveAndDeleteNotificationGroupsRecipients(objectData).pipe(
          switchMap((response: { deleteMany: BulkResponseDataInterface; saveMany: BulkResponseDataInterface }) => {
            this.errorMessageService.getTranslatedErrorMessage(response.deleteMany.data);
            const deleteManyMergedArray: ({ id: number } & {
              errorMessages?: string;
            })[] = this.excelHelperService.mergeBulkResponseWithRequestData(
              response.deleteMany,
              objectData.deleteMany.mailRecipients.map((item) => {
                return { id: item };
              }),
              true,
            );
            this.errorMessageService.getTranslatedErrorMessage(response.saveMany.data);
            const saveManyMergedArray: (NotificationMailRecipient & {
              errorMessages?: string;
            })[] = this.excelHelperService.mergeBulkResponseWithRequestData(
              response.saveMany,
              objectData.saveMany.mailRecipients,
              true,
            );
            return of(
              new ObjectActions.SaveNotificationGroupsRecipientsCompleted(deleteManyMergedArray, saveManyMergedArray),
              new AppActions.HideLoader(),
            );
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );
}
