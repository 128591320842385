import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../../../shared/model/interface/crud-response-interface.model';
import { AddKpiCardInterface, KpiCardDeleteResponseInterface, KpiCardInterface } from './kpi-cards.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class KpiCardsService {
  private readonly routes = {
    kpiCards: `${this.baseUrl}/home-metric-sets`,
    bulkDelete: `${this.baseUrl}/home-metric-sets/bulk/delete`,
    sites: `${this.baseUrl}/sites`,
    lines: `${this.baseUrl}/lines`,
  };

  constructor(public http: HttpClient, @Inject('API_BASE_URL') private readonly baseUrl: string) {}

  public getKpiCards(params: HttpParams): Observable<GetManyResponseInterface<KpiCardInterface>> {
    return this.http.get<GetManyResponseInterface<KpiCardInterface>>(this.routes.kpiCards, {
      params,
    });
  }

  public addKpiCard(kpiCard: AddKpiCardInterface): Observable<BaseOneResponseInterface<AddKpiCardInterface>> {
    return this.http.post<BaseOneResponseInterface<AddKpiCardInterface>>(`${this.routes.kpiCards}`, kpiCard);
  }

  public editKpiCard(
    id: number,
    kpiCards: AddKpiCardInterface,
  ): Observable<BaseOneResponseInterface<AddKpiCardInterface>> {
    return this.http.patch<BaseOneResponseInterface<AddKpiCardInterface>>(`${this.routes.kpiCards}/${id}`, kpiCards);
  }

  public deleteKpiCards(kpiCard: number[] | number): Observable<KpiCardDeleteResponseInterface> {
    if (Array.isArray(kpiCard) && kpiCard.length > 1) {
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        body: {
          homeMetricSets: kpiCard,
        },
      };
      return this.http.delete<KpiCardDeleteResponseInterface>(`${this.routes.bulkDelete}`, httpOptions);
    }
    return this.http.delete<KpiCardDeleteResponseInterface>(`${this.routes.kpiCards}/${kpiCard[0]}`);
  }
}
