import * as Actions from './user-configuration.actions';
import { UserConfigurationStateInterface } from './user-configuration.model';

const initialState: UserConfigurationStateInterface = {
  userConfigurationDataLoading: false,
  userConfigurationDataLoaded: false,
  userConfigurationData: {},
  temporaryMode: false,
};

export function userConfigurationReducer(
  state: UserConfigurationStateInterface = initialState,
  action: Actions.UserConfigurationActions,
): UserConfigurationStateInterface {
  switch (action.type) {
    case Actions.GET_USER_CONFIGURATION_DATA_LOADING:
    case Actions.UPDATE_USER_CONFIGURATION_LOADING:
      return {
        ...state,
        userConfigurationDataLoading: true,
        userConfigurationDataLoaded: false,
      };
    case Actions.GET_USER_CONFIGURATION_DATA_LOADED:
    case Actions.UPDATE_USER_CONFIGURATION_LOADED:
      return {
        ...state,
        userConfigurationDataLoading: false,
        userConfigurationDataLoaded: true,
        userConfigurationData: action.payload ? action.payload : {},
      };

    case Actions.USER_CONFIGURATION_FETCH_ERROR:
      return {
        ...state,
        userConfigurationDataLoading: false,
        userConfigurationDataLoaded: true,
      };

    case Actions.UPDATE_TEMPORARY_MODE:
      return {
        ...state,
        temporaryMode: action.temporaryMode,
      };
    default:
      return state;
  }
}
