import * as Actions from './daily-performance-summary.actions';
import { DailyPerformanceSummaryStateInterface } from './daily-performance-summary.model';

export let dailyPerformanceSummaryInitialState: DailyPerformanceSummaryStateInterface;
dailyPerformanceSummaryInitialState = {
  isDailyPerformanceDataLoading: false,
  isDailyPerformanceDataLoaded: false,
  isDailyPerformanceWorkOrderLoading: false,
  isDailyPerformanceWorkOrderLoaded: false,
  dailyPerformanceSummaryData: {
    lineName: null,
    productName: null,
    packagingBatch: null,
    packagingGmid: null,
    productionOrder: null,
    plannedDowntime: [],
    productionTime: [],
    downtimeTime: [],
    setupNumberOfEmployees: null,
    setupTotalMachineHours: null,
    setupTotalLaborHours: null,
    runNumberOfEmployees: null,
    runTotalMachineHours: null,
    runTotalLaborHours: null,
    totalQuantityProduced: null,
    totalUnplannedDowntime: null,
    totalIdleTime: null,
  },
  workOrderData: {
    id: null,
    woNumber: null,
    description: null,
    quantityOrdered: null,
    goodQuantity: null,
    completed: null,
    productId: null,
    product: {
      id: null,
      productId: null,
      description: null,
    },
    lineName: null,
  },
};

export function dailyPerformanceSummaryReducer(
  state: DailyPerformanceSummaryStateInterface = dailyPerformanceSummaryInitialState,
  action: Actions.DailyPerformanceSummaryActions,
): DailyPerformanceSummaryStateInterface {
  switch (action.type) {
    case Actions.DAILY_PERFORMANCE_SUMMARY_DATA_LOADING:
      return {
        ...state,
        isDailyPerformanceDataLoading: true,
        isDailyPerformanceDataLoaded: false,
      };
    case Actions.DAILY_PERFORMANCE_SUMMARY_DATA_LOADED:
      return {
        ...state,
        isDailyPerformanceDataLoading: false,
        isDailyPerformanceDataLoaded: true,
        dailyPerformanceSummaryData: action.payload,
      };
    case Actions.DAILY_PERFORMANCE_SUMMARY_WORK_ORDER_LOADING:
      return {
        ...state,
        isDailyPerformanceWorkOrderLoading: true,
        isDailyPerformanceWorkOrderLoaded: false,
      };
    case Actions.DAILY_PERFORMANCE_SUMMARY_WORK_ORDER_LOADED:
      return {
        ...state,
        isDailyPerformanceWorkOrderLoading: false,
        isDailyPerformanceWorkOrderLoaded: true,
        workOrderData: action.payload,
      };
    case Actions.RESET_DAILY_PERFORMANCE_SUMMARY_DATA:
      return {
        ...state,
        isDailyPerformanceDataLoading: false,
        isDailyPerformanceDataLoaded: true,
        dailyPerformanceSummaryData: dailyPerformanceSummaryInitialState.dailyPerformanceSummaryData,
        workOrderData: dailyPerformanceSummaryInitialState.workOrderData,
      };
    case Actions.FETCH_DATA_ERROR:
      return {
        ...state,
        isDailyPerformanceDataLoading: false,
        isDailyPerformanceDataLoaded: true,
      };
    case Actions.FETCH_WORK_ORDER_ERROR:
      return {
        ...state,
        isDailyPerformanceWorkOrderLoading: false,
        isDailyPerformanceWorkOrderLoaded: true,
      };
    default:
      return state;
  }
}
