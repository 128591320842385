import { NgModule } from '@angular/core';
import { ScwMatSelectComponent } from './scw-mat-select.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ScwMatSearchModule } from '../scw-mat-search/scw-mat-search.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ScwMatSmallButtonModule } from '../scw-mat-small-button/scw-mat-small-button.module';
import { ScwMatButtonModule } from '../scw-mat-button/scw-mat-button.module';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatProgressBarModule,
    AngularMultiSelectModule,
    ScwMatSearchModule,
    ScwMatSmallButtonModule,
    ScwMatButtonModule,
    NgbTooltipModule,
    TranslateModule,
  ],
  exports: [ScwMatSelectComponent],
  declarations: [ScwMatSelectComponent],
})
export class ScwMatSelectModule {}
