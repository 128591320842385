import { Store } from '@ngrx/store';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import * as oeeAppReducer from '../../oee.reducer';
import { Observable } from 'rxjs';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { Inject, Injectable } from '@angular/core';
import { HelperService } from '../../../shared/service/helper.service';
import {
  ICommentLogsDeleteResponse,
  IGetCommentLogRowResponse,
  ICommentLogDataTable,
  ICommentLogsRawData,
  ICommentLogsFilterCard,
  ICommentLogsTableFilteringParameters,
  ECommentLogsObjectName,
  ECommentLogsScopeName,
} from './comment-logs.model';
import { TranslateService } from '@ngx-translate/core';
import { WorkOrderDeleteResponseInterface } from '../../../shared/service/work-order-schedule/work-order-schedule.model';
import * as moment from 'moment';
import * as _ from 'lodash';
import {
  FieldTypes,
  IFilterOutput,
  QueryTypes,
  TargetEndpoints,
} from '../../../shared/component/filter/advanced-filter/advanced-filter.model';
import { AdvancedFilterService } from '../../../shared/component/filter/advanced-filter/advanced-filter.service';

@Injectable({
  providedIn: 'root',
})
export class CommentLogsService {
  constructor(
    @Inject('API_BASE_URL') private readonly api: string,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly http: HttpClient,
    private readonly helperService: HelperService,
    private readonly translate: TranslateService,
    private readonly advancedFilterService: AdvancedFilterService,
  ) {}

  public static excludedCommentTypes: string[] = [
    'shift_quality',
    'shift_goal_hit',
    'shift_data_quality',
    'shift_safety',
  ];

  public commentLogsTableColumnsFilteringParameters: ICommentLogsTableFilteringParameters = {
    siteId: 'site.id',
    workOrderId: 'workOrder.id',
    objectType: 'objectType',
    commentType: 'commentType.category',
  };

  private readonly routes = {
    comments: '/comments',
    singleDelete: '/comments',
    bulkDelete: '/comments/bulk/delete',
    singleEdit: '/comments',
    bulkEdit: '/comments/bulk/edit',
  };

  public getCommentLogsData(params: HttpParams): Observable<GetManyResponseInterface<ICommentLogsRawData>> {
    return this.http.get<GetManyResponseInterface<any>>(`${this.api}${this.routes.comments}`, { params });
  }

  public getCommentLogData(commentId: number): Observable<IGetCommentLogRowResponse> {
    return this.http.get<IGetCommentLogRowResponse>(`${this.api}${this.routes.comments}/${commentId}`);
  }

  public editCommentLogData(comment: Partial<ICommentLogsRawData>, commentId: number): Observable<any> {
    return this.http.patch<any>(`${this.api}${this.routes.singleEdit}/${commentId}`, { ...comment });
  }

  public setCommentLogsHttpParameters(commentLogsParams: ICommentLogsFilterCard): HttpParams {
    let params: HttpParams = new HttpParams();

    if (commentLogsParams.page) {
      params = params.set('page', String(commentLogsParams.page));
    }

    if (commentLogsParams.limit) {
      params = params.set('limit', String(commentLogsParams.limit));
    }

    if (commentLogsParams.fields?.length) {
      params = params.set('fields', commentLogsParams.fields.join(','));
    }

    for (const sort of commentLogsParams.sort ?? []) {
      params = params.append('sort', `${sort.column},${sort.type === 'descending' ? 'DESC' : 'ASC'}`);
    }

    const andConditions = [];
    if (commentLogsParams.siteId && commentLogsParams.siteId.length > 0) {
      andConditions.push({
        [this.commentLogsTableColumnsFilteringParameters.siteId]: { $in: commentLogsParams.siteId },
      });
    }
    if (commentLogsParams.workOrderId && commentLogsParams.workOrderId.length > 0) {
      andConditions.push({
        [this.commentLogsTableColumnsFilteringParameters.workOrderId]: {
          $in: commentLogsParams.workOrderId,
        },
      });
    }
    if (commentLogsParams.selectedObjectTypes && commentLogsParams.selectedObjectTypes.length > 0) {
      andConditions.push({
        [this.commentLogsTableColumnsFilteringParameters.objectType]: { $in: commentLogsParams.selectedObjectTypes },
      });
    }
    if (commentLogsParams.selectedCommentTypes && commentLogsParams.selectedCommentTypes.length > 0) {
      andConditions.push({
        [this.commentLogsTableColumnsFilteringParameters.commentType]: { $in: commentLogsParams.selectedCommentTypes },
      });
    }

    const searchParamsAdvancedFilter: { [key: string]: any } = { $and: [] };

    if (commentLogsParams.advancedFilter) {
      const advancedFilter: IFilterOutput[] = commentLogsParams.advancedFilter.filters;

      for (const filter of advancedFilter) {
        if (filter.queryType !== QueryTypes.withinAdvancedFilterParams) {
          continue;
        }

        if (filter.type === FieldTypes.predefined) {
          searchParamsAdvancedFilter['$and'].push(
            this.advancedFilterService.generateQuery(
              filter.path,
              filter.type,
              filter.operator.name,
              filter.operator.type,
              TargetEndpoints.NestJSCrud,
              filter.value[0][filter.searchBy],
            ),
          );
        } else {
          searchParamsAdvancedFilter['$and'].push(
            this.advancedFilterService.generateQuery(
              filter.path,
              filter.type,
              filter.operator.name,
              filter.operator.type,
              TargetEndpoints.NestJSCrud,
              filter.value,
            ),
          );
        }
      }
    }

    if (searchParamsAdvancedFilter['$and'].length > 0) {
      andConditions.push(searchParamsAdvancedFilter);
    }

    if (andConditions.length > 0) {
      params = params.set('s', JSON.stringify({ $and: andConditions }));
    }

    params = params.set('commentLogs', true);
    params = params.append('join', 'shift');
    params = params.append('join', 'commentType');
    params = params.append('join', 'site');
    params = params.append('join', 'workOrder');
    params = params.append('join', 'user');
    params = params.append('join', 'department');
    params = params.append('join', 'line');

    return params;
  }

  public setEditableFieldsGetMany(response: GetManyResponseInterface<ICommentLogsRawData>): void {
    response.data.forEach((objectData: ICommentLogsRawData) => {
      objectData.editableFields = 'commentMessage';
    });
  }
  public setEditableFieldsGetOne(response: IGetCommentLogRowResponse): void {
    response.data.editableFields = 'commentMessage';
  }

  public deleteCommentLogsRows(commentLogs: number[] | number): Observable<ICommentLogsDeleteResponse> {
    if (Array.isArray(commentLogs) && commentLogs.length > 1) {
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        body: {
          comments: commentLogs,
        },
      };
      return this.http.delete<WorkOrderDeleteResponseInterface>(`${this.api}${this.routes.bulkDelete}`, httpOptions);
    }
    return this.http.delete<WorkOrderDeleteResponseInterface>(
      `${this.api}${this.routes.singleDelete}/${commentLogs[0]}`,
    );
  }

  public getScopeNameFromCommentLogsRowData(row: ICommentLogsRawData): string {
    let scopeName: string = null;
    let rawDataObjectTypeKey: string = null;
    let rawDataObjectTypeValue: string = null;
    if (!_.isNil(row.objectType)) {
      rawDataObjectTypeKey = ECommentLogsObjectName[row.objectType];
      rawDataObjectTypeValue = ECommentLogsScopeName[row.objectType];
    }
    if (!_.isNil(rawDataObjectTypeKey) && !_.isNil(rawDataObjectTypeValue) && !_.isNil(row[rawDataObjectTypeKey])) {
      scopeName = row[rawDataObjectTypeKey][rawDataObjectTypeValue];
    }
    return scopeName;
  }

  public getObjectTranslations(translateKey: string): string {
    const translateValue: string = this.translate.instant(translateKey);

    return translateKey === translateValue ? null : translateValue;
  }

  public getCommentMessage(row: ICommentLogsRawData): string {
    if (!CommentLogsService.excludedCommentTypes.includes(row.commentType?.category)) {
      return row.commentMessage ?? null;
    }

    let commentMessage: string = null;

    switch (row.commentType?.category) {
      case 'shift_goal_hit':
        commentMessage =
          row.commentMessage === '1' ? this.translate.instant('general.yes') : this.translate.instant('general.no');
        break;
      case 'shift_data_quality':
        switch (row.commentMessage) {
          case '0':
            commentMessage = this.translate.instant('shiftSummary.dropdown.options.toBeConfirmed');
            break;
          case '1':
            commentMessage = this.translate.instant('shiftSummary.dropdown.options.confirmedNoIssues');
            break;
          case '2':
            commentMessage = this.translate.instant('shiftSummary.dropdown.options.confirmedNeedsImprovement');
            break;
        }
        break;
      case 'shift_quality':
      case 'shift_safety':
        commentMessage =
          row.commentMessage === '1'
            ? this.translate.instant('shiftSummary.dropdown.options.incidentOccurred')
            : this.translate.instant('shiftSummary.dropdown.options.noEvents');
        break;
    }

    return commentMessage;
  }

  public formatCommentLogsData(
    data: ICommentLogsRawData[],
    dateFormat: string,
    dateTimeFormat: string,
    locale: string,
    commentMessageFixedLength: number,
  ): ICommentLogDataTable[] {
    const newData: ICommentLogDataTable[] = [];
    data.map((row: ICommentLogsRawData) => {
      newData.push({
        siteName: row.site?.name ?? null,
        workOrderName: row.workOrder?.woNumber ?? null,
        commentType: row.commentType?.category
          ? this.getObjectTranslations(`commentLogs.body.commentType.${_.camelCase(row.commentType?.category)}`) ??
            row.commentType?.category
          : null,
        commentTypeRaw: row.commentType?.category ?? null,
        objectType: row.objectType
          ? this.translate.instant(`commentLogs.body.objectType.${_.camelCase(row.objectType)}`)
          : null,
        objectName: this.getScopeNameFromCommentLogsRowData(row) ?? null,
        objectProperty: row.objectProperty
          ? this.getObjectTranslations(`commentLogs.body.objectProperty.${_.camelCase(row.objectProperty)}`) ??
            row.objectProperty
          : null,
        commentDate: row.commentDate ? moment(row.commentDate).locale(locale).format(dateTimeFormat) : null,
        commentMessage: this.getCommentMessage(row) ?? null,
        commentMessageRaw: row.commentMessage ?? null,
        commentMessageFixedLength: row.commentMessage
          ? `${row.commentMessage?.slice(0, commentMessageFixedLength)}...`
          : null,
        shiftName: row.shift?.name ?? null,
        shiftDay: row.shiftDay ? moment(row.shiftDay).locale(locale).format(dateFormat) : null,
        createdAt: row.createdAt ? moment(row.createdAt).locale(locale).format(dateTimeFormat) : null,
        createdBy: row.user?.fullName ?? null,
        id: row.id,
        editableFields: row.editableFields ?? '',
      });
    });
    return newData;
  }
}
