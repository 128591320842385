<div
  class="scw-mat-search-container"
  [class.is-button-on-left]="buttonPosition === 'left'"
  [class.is-button-on-right]="buttonPosition === 'right'"
>
  <form autocomplete="off" action="javascript:void(0)" onsubmit="return false">
    <ng-template [ngIf]="buttonPosition === 'left'" [ngIfElse]="right">
      <scw-mat-small-button
        [size]="size"
        [className]="['scw-mat-small-button-search-icon-button', 'left']"
        [disabled]="disabled"
        (onClick)="onSearchEvent('onClickButton')"
      >
        <em class="fas fa-search"></em>
      </scw-mat-small-button>
    </ng-template>
    <scw-mat-input
      [size]="size"
      [disabled]="disabled"
      [block]="block"
      [className]="className"
      [label]="label"
      [placeholder]="placeholder"
      [maxlength]="maxlengthString"
      [(inputModel)]="inputModel"
      (onKeyup)="onSearchEvent('onKeyup')"
      (onKeyupEnter)="onSearchEvent('onEnter')"
      (inputModelChange)="onInputModelChange()"
    ></scw-mat-input>
    <ng-template #right>
      <scw-mat-small-button
        [size]="size"
        [className]="[
        'scw-mat-small-button-search-icon-button',
        'right',
        size === 'xs' ? 'scw-mat-xs-search-button' : ''
      ]"
        [disabled]="disabled"
        (onClick)="onSearchEvent('onClickButton')"
      >
        <em class="fas fa-search" [ngClass]="{ 'fa-xs' : size === 'xs' }"></em>
      </scw-mat-small-button>
    </ng-template>
  </form>
</div>
