import { ResponseArrayInterface } from '../interface/generic-api-response.model';

export const defaultInitialState: ResponseArrayInterface<any> = {
  data: [],
  count: 0,
  page: 0,
  total: 0,
  pageCount: 0,
  success: false,
  date: null,
};
