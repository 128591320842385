import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  DateRangeDropsTypes,
  DateRangeInterface,
  DateRangeLocaleInterface,
  DateRangeOpensTypes,
  DateRangeRangesDefaultInterface,
  ScwMatDatepickerReturnInterface,
  ScwMatDatepickerRule,
} from './scw-mat-datepicker.model';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../../../store/oee.reducer';
import * as moment from 'moment';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { User } from '../../../../store/user/model';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
import { HelperService } from '../../../service/helper.service';
import { DateRanges } from '../../../../../constants';

@Component({
  selector: 'scw-mat-datepicker',
  templateUrl: './scw-mat-datepicker.component.html',
  styleUrls: ['./scw-mat-datepicker.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ScwMatDatepickerComponent implements DateRangeInterface, OnInit, OnDestroy {
  @ViewChild(DaterangepickerDirective) pickerDirective: DaterangepickerDirective;

  @Input() alwaysShowCalendars: boolean = true;
  @Input() autoApply: boolean = false;
  @Input() className: string = '';
  @Input() closeOnAutoApply: boolean = false;
  @Input() customRangeDirection: boolean = false;
  @Input() customStyle = null;
  @Input() disabled: boolean = false;
  @Input() drops: DateRangeDropsTypes = 'down';
  @Input() emptyWeekRowClass: string = '';
  @Input() firstDayOfNextMonthClass: string = '';
  @Input() firstMonthDayClass: string = '';
  @Input() id: string = '';
  @Input() inputModel;
  @Input() isCustomDate: () => boolean = () => {
    return false;
  };
  @Input() isInvalidDate: () => boolean = () => {
    return false;
  };
  @Input() keepCalendarOpeningWithRange: boolean = true;
  @Input() lastDayOfPreviousMonthClass: string = '';
  @Input() lastMonthDayClass: string = '';
  @Input() linkedCalendars;
  @Input() locale;
  @Input() lockStartDate: boolean = false;
  @Input() maxDate: moment.Moment = moment('2037-12-31');
  @Input() minDate: moment.Moment = moment('1981-01-01');
  @Input() opens: DateRangeOpensTypes = DateRangeOpensTypes.right;
  @Input() placeholder: string = '';
  @Input() hint: string = null;
  @Input() ranges: any;
  @Input() readonly: boolean = true;
  @Input() showCalendarIcon: boolean = true;
  @Input() showCancel: boolean = true;
  @Input() showClearButton: boolean = true;
  @Input() showCustomRangeLabel: boolean = true;
  @Input() showISOWeekNumbers: boolean = true;
  @Input() showRangeLabelOnInput: boolean = false;
  @Input() showWeekNumbers: boolean = false;
  @Input() singleDatePicker: boolean = false;
  @Input() timePicker: boolean = false;
  @Input() timePickerIncrement: number = 1;
  @Input() timePickerSeconds: boolean = false;
  @Input() emitOnNull: boolean = false;
  @Input() errorText: string;
  @Input() isValid: boolean = false;
  @Input() hasErrors: boolean = false;
  @Input() rules: ScwMatDatepickerRule[] = [];
  @Input() label: string;
  @Input() wide: boolean;
  @Input() noPadding: boolean = false;
  @Input() emitUtc: boolean = false;
  @Input() containerClass: string = '';
  @Input() rangePickerStartLabel: string;
  @Input() rangePickerEndLabel: string;
  @Input() timezone: string;
  @Input() dateLimit: number = null;

  @Output() inputModelChange: EventEmitter<any> =
    new EventEmitter<any>();
  @Output() isValidChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public timePicker24Hour: boolean = true;
  public locale$: string;
  public dateFormat$: string;
  public dateTimeFormat$: string;
  private isAnyError: boolean = false;
  private firstTime: boolean;
  private userSubscription: Subscription;

  constructor(
    private readonly store: Store<OeeAppState>,
    public readonly translate: TranslateService,
    public readonly helperService: HelperService,
  ) {}

  public getDefaultRanges(): DateRanges {
    const rangeTimes: DateRangeRangesDefaultInterface = {
      today: [moment().tz(this.timezone), moment().tz(this.timezone)],
      yesterday: [moment().tz(this.timezone).subtract(1, 'days'), moment().tz(this.timezone).subtract(1, 'days')],
      thisWeek: [moment().tz(this.timezone).startOf('week'), moment().tz(this.timezone).endOf('week')],
      lastWeek: [
        moment().tz(this.timezone).subtract(1, 'weeks').startOf('week'),
        moment().tz(this.timezone).subtract(1, 'weeks').endOf('week'),
      ],
      thisMonth: [moment().tz(this.timezone).startOf('month'), moment().tz(this.timezone).endOf('month')],
      lastMonth: [
        moment().tz(this.timezone).subtract(1, 'month').startOf('month'),
        moment().tz(this.timezone).subtract(1, 'month').endOf('month'),
      ],
      thisYear: [moment().tz(this.timezone).startOf('year'), moment().tz(this.timezone).endOf('year')],
      lastYear: [
        moment().tz(this.timezone).subtract(1, 'year').startOf('year'),
        moment().tz(this.timezone).subtract(1, 'year').endOf('year'),
      ],
    };

    return this.helperService.getDefaultRanges(rangeTimes);
  }

  public onClickIcon(): void {
    if (this.disabled) {
      return;
    }

    setTimeout(() => {
      this.pickerDirective.open();
    });
  }

  public onClickClear(): void {
    this.inputModelChange.emit(null);
  }

  public ngOnInit(): void {
    if (this.wide === undefined) {
      this.wide = !this.singleDatePicker;
    }

    this.firstTime = true;
    this.userSubscription = this.store
      .select('user')
      .pipe(take(1))
      .subscribe((state: User) => {
        this.locale$ = state.locale;
        this.dateFormat$ = state.dateFormat;
        this.dateTimeFormat$ = state.dateTimeFormat;
        this.timezone = this.timezone ?? state.timezone;
        const displayFormatValue: string = this.timePicker ? this.dateTimeFormat$ : this.dateFormat$;

        const defaultLocale: DateRangeLocaleInterface = {
          applyLabel: this.translate.instant('dateRangePicker.locale.applyLabel'),
          cancelLabel: this.translate.instant('dateRangePicker.locale.cancelLabel'),
          clearLabel: this.translate.instant('dateRangePicker.locale.clearLabel'),
          customRangeLabel: this.translate.instant('dateRangePicker.locale.customRangeLabel'),
          daysOfWeek: moment.localeData(this.locale$).weekdaysShort(),
          direction: 'ltr',
          firstDay: moment.localeData(this.locale$).firstDayOfWeek(),
          format: displayFormatValue,
          monthNames: moment.localeData(this.locale$).monthsShort(),
          separator: ' - ',
          weekLabel: this.translate.instant('dateRangePicker.locale.weekLabel'),
        };

        this.locale = { ...this.locale, ...defaultLocale };

        if (typeof this.ranges === 'undefined') {
          this.ranges = this.getDefaultRanges();
        }
      });

    if (!_.isEmpty(this.inputModel) && !_.isNil(this.inputModel.startDate) && !_.isNil(this.inputModel.endDate)) {
      if (this.emitUtc) {
        this.inputModel = {
          startDate: moment(moment.utc(this.inputModel.startDate).valueOf()),
          endDate: moment(moment.utc(this.inputModel.endDate).valueOf()),
        };
      } else {
        this.inputModel = {
          startDate: moment(this.inputModel.startDate),
          endDate: moment(this.inputModel.endDate),
        };
      }
    }
  }

  public ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
  }

  public reset(): void {
    this.inputModel = null;
    this.clearErrorMessage();
  }

  private isValidEqualizer(isValid: boolean): void {
    this.isValid = isValid;
    this.isValidChange.emit(this.isValid);
  }

  private showErrorMessage(message: string): void {
    this.isValidEqualizer(false);
    this.isAnyError = true;
    this.hasErrors = true;
    this.errorText = message ? message : '';
  }

  private clearErrorMessage(): void {
    this.isValidEqualizer(true);
    this.isAnyError = false;
    this.hasErrors = false;
    this.errorText = null;
  }

  private requiredRule(rule: ScwMatDatepickerRule): void {
    if (
      this.inputModel === null ||
      (this.inputModel.startDate === null && (!this.singleDatePicker || this.singleDatePicker)) ||
      this.inputModel.endDate === null
    ) {
      this.showErrorMessage(rule.message ?? this.translate.instant('scwMatForm.validation.required'));
    }
  }

  private checkRules(): void {
    if (this.rules.length === 0) {
      this.isValidEqualizer(true);
      return;
    }

    this.isAnyError = false;

    for (const rule of this.rules) {
      if (this.isAnyError) {
        return;
      }

      if ('required' in rule) {
        this.requiredRule(rule);
      }
    }

    if (this.isAnyError) {
      return;
    }

    this.clearErrorMessage();
  }

  private setInputModelBoundaries(): void {
    if (
      this.timePicker ||
      this.inputModel === null ||
      this.inputModel.startDate === null ||
      this.inputModel.endDate === null
    ) {
      return;
    }

    this.inputModel.startDate.set('hours', 0).set('minutes', 0).set('seconds', 0);
    this.inputModel.endDate.set('hours', 23).set('minutes', 59).set('seconds', 59);
  }

  public onNgModelChange(): void {
    this.checkRules();
    this.setInputModelBoundaries();

    const isStartEndDateNull: boolean = _.isNil(this.inputModel.startDate) && _.isNil(this.inputModel.endDate);

    if (this.emitUtc && !isStartEndDateNull) {
      this.inputModel.startDate = moment.utc(this.inputModel.startDate);
      this.inputModel.endDate = moment.utc(this.inputModel.endDate);
    }

    this.inputModelChange.emit(!isStartEndDateNull ? this.inputModel : null);
  }
}
