import { Action } from '@ngrx/store';
import { SensorTypeCRUDInterface } from '../../../shared/component/filter/filter.class';

export enum FilterSensorTypeActionTypes {
  StartLoadFilterSensorTypes = '[Filter Sensor Type] Load Filter Sensor Types',
  FilterSensorTypesLoaded = '[Filter Sensor Type] Filter Sensor Types Loaded',
}

export class StartLoadFilterSensorTypes implements Action {
  readonly type = FilterSensorTypeActionTypes.StartLoadFilterSensorTypes;
  constructor() {}
}

export class FilterSensorTypesLoaded implements Action {
  readonly type = FilterSensorTypeActionTypes.FilterSensorTypesLoaded;
  constructor(public data: SensorTypeCRUDInterface[]) {}
}

export type FilterSensorTypeActions = StartLoadFilterSensorTypes | FilterSensorTypesLoaded;
