import { DropdownOptionInterface } from '../../../shared/component/scw-mat-ui/scw-mat-select/scw-mat-select.model';
import { FilterMyReportBreakdownActions, FilterMyReportBreakdownActionTypes } from './my-report-breakdown.actions';
import * as _ from 'lodash';

export interface IFilterMyReportBreakdownState {
  data: DropdownOptionInterface[];
}

export const initialFilterMyReportBreakdownState: IFilterMyReportBreakdownState = {
  data: [],
};

export function filterMyReportBreakdownReducer(
  state = initialFilterMyReportBreakdownState,
  action: FilterMyReportBreakdownActions,
): IFilterMyReportBreakdownState {
  switch (action.type) {
    case FilterMyReportBreakdownActionTypes.SetFilterBreakdownOptions:
      return { ...state, data: action.options };

    case FilterMyReportBreakdownActionTypes.AddOption:
      const addedData: DropdownOptionInterface[] = _.cloneDeep(state.data);
      addedData.splice(action.index, 0, action.option);

      return { ...state, data: addedData };

    case FilterMyReportBreakdownActionTypes.RemoveOption:
      const removedData: DropdownOptionInterface[] = _.cloneDeep(state.data);
      _.remove(removedData, (item: DropdownOptionInterface) => item.id === action.id);

      return { ...state, data: removedData };

    default:
      return state;
  }
}
