import { ISitesStore } from './sites.model';
import * as Actions from './sites.actions';

export const sitesState: ISitesStore = {
  isSitesDataLoaded: false,
  isSitesDataLoading: false,
  sitesData: [],
  totalSitesDataCount: 0,
};

export function sitesReducer(state: ISitesStore = sitesState, action: Actions.SitesActions): ISitesStore {
  switch (action.type) {
    case Actions.LOAD_SITES_DATA:
      return {
        ...state,
        isSitesDataLoaded: false,
        isSitesDataLoading: true,
      };

    case Actions.LOADED_SITES_DATA:
      return {
        ...state,
        isSitesDataLoading: false,
        isSitesDataLoaded: true,
        sitesData: action.payload.data,
        totalSitesDataCount: action.payload.total,
      };

    default:
      return state;
  }
}
