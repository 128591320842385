import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import { Observable } from 'rxjs';
import { BaseOneResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { DailyProductionPerformanceDataInterface } from './daily-production-performance.model';

@Injectable({
  providedIn: 'root',
})
export class DailyProductionPerformanceService {
  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly api: string,
    public store: Store<oeeAppReducer.OeeAppState>,
  ) {}

  private readonly routes = {
    dailyProductionPerformance: '/lines/:lineId/daily-production-performance',
  };

  public getDailyProductionPerformanceData(
    lineId: number,
  ): Observable<BaseOneResponseInterface<DailyProductionPerformanceDataInterface>> {
    const url: string = `${this.api}${this.routes.dailyProductionPerformance.replace(':lineId', String(lineId))}`;

    return this.http.get<BaseOneResponseInterface<DailyProductionPerformanceDataInterface>>(url);
  }
}
