import { Component, Input } from '@angular/core';
import { SimpleOutputOptionsInterface } from '../filter.class';
import { Subject } from 'rxjs';
import { ScwMatButtonGroupButtons } from '../../scw-mat-ui/scw-mat-button-group/scw-mat-button-group.model';

@Component({
  selector: 'app-button-group',
  templateUrl: './button-group.component.html',
})
export class ButtonGroupComponent {
  @Input() buttons: ScwMatButtonGroupButtons[] = [];
  @Input() value: any = null;
  @Input() disabled: boolean = false;

  public outputOptions: SimpleOutputOptionsInterface;
  public outputSubject: Subject<any>;
  public elementID: string;

  public dependedOptionConfiguration = [];

  public getFiltersOutputs(): void {
    this.outputSubject.next({
      [this.outputOptions.filterObjectId]: this.value,
    });
    this.publishValue(this.value);
  }

  public publishValue(value): void {
    this.dependedOptionConfiguration.forEach((config) => {
      if (config.submit) {
        config.dependedOptionListener.next({
          ...config,
          value,
        });
      }
    });
  }

  public subscribeDependedOptionListener(configuration: any): void {
    configuration.dependedOptionListener.asObservable().subscribe((configuration) => {
      if (this.elementID === configuration?.dependedElementId && this.hasOwnProperty(configuration?.dependedOption)) {
        this[configuration?.dependedOption] = configuration?.getDependentValue(configuration?.value);
        this.getFiltersOutputs();
      }
    });
  }

  ngOnDestroy(): void {
    this.dependedOptionConfiguration?.forEach((option) => {
      option.dependedOptionListener?.complete();
    });
  }
}
