import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import * as ObjectActions from './digital-form.actions';
import { of } from 'rxjs';
import * as AppActions from '../../store/app/actions';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../oee.reducer';
import { BaseOneResponseInterface } from '../../shared/model/interface/crud-response-interface.model';
import { DigitalFormService } from './digital-form.service';
import { IDigitalFormToken } from './digital-form.model';

@Injectable()
export class DigitalFormEffects {
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private store: Store<oeeAppReducer.OeeAppState>,
    private readonly service: DigitalFormService,
  ) {}

  startActivityButtonDataLoading = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ActivityButtonActionTypes.DigitalFormsTokenLoad),
      switchMap((objectData: ObjectActions.DigitalFormsTokenLoad) => {
        this.store.dispatch(new AppActions.ShowTopLoader());

        return this.service.getDigitalFormsToken(objectData.lineId).pipe(
          switchMap((response: BaseOneResponseInterface<IDigitalFormToken>) => {
            return of(new ObjectActions.DigitalFormsTokenLoaded(response.data.token), new AppActions.HideTopLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideTopLoader());
          }),
        );
      }),
    ),
  );
}
