import { Action } from '@ngrx/store';
import {
  IActivityLog,
  IRootCauseAnalysisChartNode,
  RootCauseAnalysisFilterInterface,
} from './root-cause-analysis.model';

export const START_ROOT_CAUSE_ANALYSIS_DATA_LOADING = '[ROOT CAUSE ANALYSIS] START ROOT CAUSE ANALYSIS DATA LOADING';
export const ROOT_CAUSE_ANALYSIS_FETCH_DATA = '[ROOT CAUSE ANALYSIS] ROOT CAUSE ANALYSIS FETCH ACTIVITY HISTORY DATA';
export const ROOT_CAUSE_ANALYSIS_DATA_LOADED = '[ROOT CAUSE ANALYSIS] ROOT CAUSE ANALYSIS DATA LOADED';
export const ROOT_CAUSE_ANALYSIS_FETCH_ERROR = '[ROOT CAUSE ANALYSIS] ROOT CAUSE ANALYSIS DATA FETCH ERROR';
export const ROOT_CAUSE_ANALYSIS_CHART_FETCH_ERROR = '[ROOT CAUSE ANALYSIS] FETCH ERROR';
export const ROOT_CAUSE_ANALYSIS_CHART_DATA_LOAD = '[ROOT CAUSE ANALYSIS] START ROOT CAUSE ANALYSIS CHART DATA LOAD';
export const ROOT_CAUSE_ANALYSIS_CHART_DATA_LOADED =
  '[ROOT CAUSE ANALYSIS] START ROOT CAUSE ANALYSIS CHART DATA LOADED';
export const ROOT_CAUSE_ANALYSIS_DATA_PROGRESS_SET =
  '[ROOT CAUSE ANALYSIS] START ROOT CAUSE ANALYSIS DATA PROGRESS SET';
export const ROOT_CAUSE_ANALYSIS_MANY_DATA_WARNING_SHOW =
  '[ROOT CAUSE ANALYSIS] START ROOT CAUSE ANALYSIS MANY DATA WARNING SHOW';
export const ROOT_CAUSE_ANALYSIS_COMPARISON_MODE_CHART_VISIBILITY =
  '[ROOT CAUSE ANALYSIS] ROOT CAUSE ANALYSIS COMPARISON MODE CHART VISIBILITY';

export class StartRootCauseAnalysisDataLoading implements Action {
  readonly type = START_ROOT_CAUSE_ANALYSIS_DATA_LOADING;

  constructor(public params: RootCauseAnalysisFilterInterface, public filterActivityType: boolean = true) {}
}

export class RootCauseAnalysisDataLoaded implements Action {
  readonly type = ROOT_CAUSE_ANALYSIS_DATA_LOADED;

  constructor(public data: IActivityLog[]) {}
}

export class RootCauseAnalysisFetchData implements Action {
  readonly type = ROOT_CAUSE_ANALYSIS_FETCH_DATA;

  constructor(
    public params: RootCauseAnalysisFilterInterface,
    public totalCount: number,
    public filterActivityType: boolean,
  ) {}
}

export class RootCauseAnalysisDataError implements Action {
  readonly type = ROOT_CAUSE_ANALYSIS_FETCH_ERROR;

  constructor(public payload: object) {}
}

export class LoadRootCauseAnalysisChartData implements Action {
  readonly type = ROOT_CAUSE_ANALYSIS_CHART_DATA_LOAD;

  constructor(public params: RootCauseAnalysisFilterInterface, public isRegularMode: boolean) {}
}

export class LoadedRootCauseAnalysisChartData implements Action {
  readonly type = ROOT_CAUSE_ANALYSIS_CHART_DATA_LOADED;

  constructor(public data: IRootCauseAnalysisChartNode[], public isRegularMode: boolean) {}
}

export class SetRootCauseAnalysisProgress implements Action {
  readonly type = ROOT_CAUSE_ANALYSIS_DATA_PROGRESS_SET;

  constructor(public progress: number) {}
}

export class RootCauseAnalysisChartDataError implements Action {
  readonly type = ROOT_CAUSE_ANALYSIS_CHART_FETCH_ERROR;

  constructor(public payload: object) {}
}

export class ShowManyDataWarning implements Action {
  readonly type = ROOT_CAUSE_ANALYSIS_MANY_DATA_WARNING_SHOW;
}

export class ComparisonModeChartVisibility implements Action {
  readonly type = ROOT_CAUSE_ANALYSIS_COMPARISON_MODE_CHART_VISIBILITY;

  constructor(
    public isRootCauseAnalysisEquipmentChartVisible: boolean,
    public isRootCauseAnalysisStackChartVisible: boolean,
  ) {}
}

export type RootCauseAnalysisActions =
  | StartRootCauseAnalysisDataLoading
  | RootCauseAnalysisFetchData
  | RootCauseAnalysisDataLoaded
  | LoadRootCauseAnalysisChartData
  | LoadedRootCauseAnalysisChartData
  | SetRootCauseAnalysisProgress
  | ShowManyDataWarning
  | ComparisonModeChartVisibility
  | RootCauseAnalysisDataError
  | RootCauseAnalysisChartDataError;
