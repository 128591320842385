import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ObjectActions from './product-speeds.actions';
import { catchError, switchMap, take } from 'rxjs/operators';
import { SiteService } from '../../../shared/service/filter/site.service';
import { from, of } from 'rxjs';
import * as AppActions from '../../app/actions';
import { ProductSpeedsService } from './product-speeds.service';
import { HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import { ErrorMessageService } from '../../../shared/service/error-message.service';
import {
  AddProductSpeedResponseInterface,
  BulkEditProductSpeedResponseInterface,
  EditProductSpeedResponseInterface,
  ProductSpeedBulkSaveManyInterface,
  ProductSpeedDeleteResponseInterface,
  ProductSpeedInterface,
} from './product-speeds.model';
import {
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { ExcelHelperService } from '../../../shared/service/excel/excel-helper.service';
import { ProductsService } from '../products/products.service';
import { ProductInterface } from '../products/products.model';
import { LineService } from '../../../shared/service/line/line.service';
import { LineCRUDInterface } from '../../../shared/component/filter/filter.class';
import * as _ from 'lodash';
import { EntityTranslatorService } from 'src/app/shared/service/entity-translator/entity-translator.service';
import { User } from '../../user/model';
import { emptyAction } from '../../../../constants';

@Injectable()
export class ProductSpeedsEffects {
  private userLanguage$: string = '';

  constructor(
    private readonly actions$: Actions,
    private readonly service: ProductSpeedsService,
    private readonly siteService: SiteService,
    private readonly lineService: LineService,
    private readonly productsService: ProductsService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly errorMessageService: ErrorMessageService,
    private readonly excelHelperService: ExcelHelperService,
    private translatorService: EntityTranslatorService,
  ) {}

  getSiteData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.PRODUCT_SPEEDS_SITES_DATA_LOADING),
      switchMap(() => {
        const httpParams: HttpParams = new HttpParams().set('limit', 1000);

        return from(this.siteService.getData(httpParams)).pipe(
          switchMap((response) => {
            return of(new ObjectActions.ProductSpeedsSiteDataLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  downloadProductSpeedExcel = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DOWNLOAD_PRODUCT_SPEED_EXCEL),
      switchMap((objectData: ObjectActions.DownloadProductSpeedExcel) => {
        this.service.downloadProductSpeedExcel(
          objectData.withData,
          objectData.filters,
          this.service.prepareProductSpeedsHttpParams(objectData.tableQuery),
        );
        return emptyAction;
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  uploadExcel = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.UPLOAD_PRODUCT_SPEED_EXCEL),
      switchMap((objectData: ObjectActions.UploadProductSpeedExcel) => {
        this.store.dispatch(new AppActions.ShowLoader());

        const excelData: ProductSpeedBulkSaveManyInterface = {
          ...objectData.productSpeeds,
          productSpeeds: objectData.productSpeeds.productSpeeds.map((productSpeed: ProductSpeedInterface) => {
            return {
              ...productSpeed,
              speed: _.replace(productSpeed.speed, ',', '.'),
            };
          }),
        };

        return this.service.uploadExcel(excelData).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            this.errorMessageService.getTranslatedErrorMessage(response.data);
            const mergedArray = this.excelHelperService.mergeBulkResponseWithRequestData<ProductSpeedInterface>(
              response,
              objectData.productSpeeds.productSpeeds,
            );
            return of(new ObjectActions.UploadProductSpeedExcelCompleted(mergedArray), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  getProductSpeeds = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.PRODUCT_SPEEDS_DATA_LOADING),
      switchMap((payload: ObjectActions.ProductSpeedsDataLoading) => {
        return this.service.getProductSpeeds(this.service.prepareProductSpeedsHttpParams(payload.query)).pipe(
          switchMap((response: GetManyResponseInterface<ProductSpeedInterface>) => {
            response.data.forEach((productSpeed: ProductSpeedInterface) =>
              this.translatorService.translate(productSpeed),
            );

            return of(new ObjectActions.ProductSpeedsDataLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes));
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes));
      }),
    ),
  );

  deleteProductSpeeds = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DELETE_PRODUCT_SPEEDS),
      switchMap((objectData: ObjectActions.DeleteProductSpeeds) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.deleteProductSpeeds(objectData.payload).pipe(
          switchMap((response: ProductSpeedDeleteResponseInterface) => {
            this.errorMessageService.getTranslatedErrorMessage(response.data);
            return of(
              new ObjectActions.DeleteProductSpeedsCompleted(objectData.payload.length > 1, response),
              new AppActions.HideLoader(),
            );
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  addProductSpeed = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ADD_PRODUCT_SPEED),
      switchMap((objectData: ObjectActions.AddProductSpeed) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.addProductSpeed(objectData.productSpeed).pipe(
          switchMap((response: AddProductSpeedResponseInterface) => {
            return of(new ObjectActions.AddProductSpeedCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  editProductSpeed = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.EDIT_PRODUCT_SPEED),
      switchMap((objectData: ObjectActions.EditProductSpeed) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.editProductSpeed(objectData.productSpeed, objectData.productSpeedId).pipe(
          switchMap((response: EditProductSpeedResponseInterface) => {
            return of(new ObjectActions.EditProductSpeedCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  bulkEditProductSpeed = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.BULK_EDIT_PRODUCT_SPEED),
      switchMap((objectData: ObjectActions.BulkEditProductSpeed) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.bulkEditProductSpeed(objectData.productSpeeds).pipe(
          switchMap((response: BulkEditProductSpeedResponseInterface) => {
            return of(new ObjectActions.BulkEditProductSpeedCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  getLines = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_LINES),
      switchMap((objectData: ObjectActions.GetLines) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const search = {
          statusId: 1,
          siteId: objectData.siteId,
        };
        const httpParams: HttpParams = new HttpParams().set('s', JSON.stringify(search)).set('limit', 1000);

        return this.lineService.getLines(httpParams).pipe(
          switchMap((response: GetManyResponseInterface<LineCRUDInterface>) => {
            return of(new ObjectActions.GetLinesCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  getProducts = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_PRODUCTS),
      switchMap((objectData: ObjectActions.GetProducts) => {
        this.store.dispatch(new AppActions.ShowLoader());

        const httpParams: HttpParams = new HttpParams().set('join', 'unitType||name').append('sort', 'productId,ASC');
        const { siteId, productId, searchText } = objectData;

        const siteFilter = siteId ? { siteId: { $eq: String(siteId) } } : {};
        const orFilter = { $or: [] };
        let queryParams: HttpParams = httpParams;
        let searchParameterValue = null;

        if (!queryParams.has('limit')) {
          queryParams = queryParams.set('limit', '50');
        }

        if (searchText !== null) {
          orFilter.$or.push(
            { description: { $cont: searchText } },
            { productId: { $cont: searchText } },
            { id: { $eq: productId } },
          );
        }

        if (searchText !== null || productId !== null) {
          searchParameterValue = {
            $and: [orFilter, siteFilter],
          };
        } else {
          searchParameterValue = siteFilter;
        }

        if (!_.isNil(searchText) && searchText !== '') {
          queryParams = queryParams.append('searchText', String(searchText));
        }

        queryParams = queryParams.set('s', JSON.stringify(searchParameterValue));

        return this.productsService.getProducts(queryParams).pipe(
          switchMap((response: GetManyResponseInterface<ProductInterface>) => {
            response.data.forEach((product: ProductInterface) => this.translatorService.translate(product));

            return of(new ObjectActions.GetProductsCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );
}
