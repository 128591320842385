import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../../store/oee.reducer';
import { catchError, map } from 'rxjs/operators';
import { ScenarioResponseInterface } from '../../../store/scheduler/scheduler.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SchedulerScenarioGuard implements CanActivate {
  private readonly schedulerScenariosUrl: string = '/scheduler/scenarios';

  constructor(
    public router: Router,
    public http: HttpClient,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.http
      .get<ScenarioResponseInterface>(`${this.baseUrl}/scheduler/scenarios/${next.paramMap.get('id')}`)
      .pipe(
        map((response: ScenarioResponseInterface) => {
          return (
            (response.success && Boolean(Object.keys(response.data))) ||
            this.router.createUrlTree([this.schedulerScenariosUrl])
          );
        }),
        catchError(() => this.router.navigate([this.schedulerScenariosUrl])),
      );
  }
}
