<div class="scw-mat-container mat-form-field" [class.no-padding]="noPadding" [ngClass]="containerClass">
  <label *ngIf="label" class="input-label">{{ label }}</label>
  <div [ngClass]="withFrame ? 'pt-3 mb-3 px-3 pb-1 cron-frame' : 'p-0 m-0'">
    <div class="form d-flex flex-wrap" *ngIf="config?.bootstrap" [formGroup]="cronJobsForm">
      <label class="mr-sm-2 cron-label" for="selectBase">Every: </label>
      <scw-mat-select
        ngDefaultControl
        [rules]="rules"
        [data]="formData.dropdownOptions.baseFrequencyData"
        [(inputModel)]="formData.form.baseFrequency.value"
        (inputModelChange)="onCronFieldChanged($event, 'baseFrequency')"
        id="selectBase"
        formControlName="baseFrequency"
        class="flex-grow-1 cron-select"
        name="baseFrequency"
      >
      </scw-mat-select>
      <label class="mx-sm-2 cron-label" *ngIf="cronJobsForm.value.baseFrequency === 4">on</label>
      <scw-mat-select
        ngDefaultControl
        [rules]="rules"
        [data]="formData.dropdownOptions.daysOfWeekData"
        [(inputModel)]="formData.form.daysOfWeek.value"
        [showCheckbox]="true"
        [singleSelection]="false"
        (inputModelChange)="onCronFieldChanged($event, 'daysOfWeek')"
        *ngIf="cronJobsForm.value.baseFrequency === 4"
        formControlName="daysOfWeek"
        class="flex-grow-1 cron-select mw-20ch"
        name="daysOfWeek"
      >
      </scw-mat-select>
      <label class="mx-sm-2 cron-label" *ngIf="cronJobsForm.value.baseFrequency >= 5">on the</label>
      <scw-mat-select
        ngDefaultControl
        [rules]="rules"
        [data]="formData.dropdownOptions.daysOfMonthData"
        [(inputModel)]="formData.form.daysOfMonth.value"
        [showCheckbox]="true"
        [singleSelection]="false"
        (inputModelChange)="onCronFieldChanged($event, 'daysOfMonth')"
        *ngIf="cronJobsForm.value.baseFrequency >= 5"
        formControlName="daysOfMonth"
        class="flex-grow-1 cron-select mw-10ch"
        name="daysOfMonth"
      >
      </scw-mat-select>
      <label class="mx-sm-2 cron-label" *ngIf="cronJobsForm.value.baseFrequency === 6">of</label>
      <scw-mat-select
        ngDefaultControl
        [rules]="rules"
        [data]="formData.dropdownOptions.monthsData"
        [(inputModel)]="formData.form.months.value"
        [showCheckbox]="true"
        [singleSelection]="false"
        (inputModelChange)="onCronFieldChanged($event, 'months')"
        *ngIf="cronJobsForm.value.baseFrequency === 6"
        formControlName="months"
        class="flex-grow-1 cron-select month-select"
        name="months"
      >
      </scw-mat-select>
      <div class="break" *ngIf="cronJobsForm.value.baseFrequency >= 5"></div>
      <label class="mx-sm-2 cron-label" *ngIf="cronJobsForm.value.baseFrequency >= 2">at</label>
      <scw-mat-select
        ngDefaultControl
        [rules]="rules"
        [data]="formData.dropdownOptions.hoursData"
        [(inputModel)]="formData.form.hours.value"
        [showCheckbox]="true"
        [singleSelection]="false"
        (inputModelChange)="onCronFieldChanged($event, 'hours')"
        *ngIf="cronJobsForm.value.baseFrequency >= 3"
        formControlName="hours"
        class="flex-grow-1 cron-select mw-10ch"
        name="hours"
      >
      </scw-mat-select>
      <label class="mx-sm-2 cron-label" *ngIf="cronJobsForm.value.baseFrequency >= 3">:</label>
      <scw-mat-select
        ngDefaultControl
        [rules]="rules"
        [data]="formData.dropdownOptions.minutesData"
        [(inputModel)]="formData.form.minutes.value"
        [showCheckbox]="true"
        [singleSelection]="false"
        (inputModelChange)="onCronFieldChanged($event, 'minutes')"
        *ngIf="cronJobsForm.value.baseFrequency >= 2"
        formControlName="minutes"
        class="flex-grow-1 cron-select mw-10ch"
        name="minutes"
      >
      </scw-mat-select>
      <label class="mx-sm-2 cron-label" *ngIf="cronJobsForm.value.baseFrequency === 2">past the hour</label>
    </div>
    <label class="human-readable-expression w-100" for="selectBase" *ngIf="!isCronIncomplete">{{
      humanReadableExpression
    }}</label>
    <ng-template [ngIf]="errorText || hint">
      <mat-hint [class.error]="errorText" [class.hint]="!errorText && hint">
        <em
          class="sub-text-icon fas"
          [class.fa-exclamation-circle]="errorText"
          [class.fa-info-circle]="!errorText && hint"
        ></em>
        <ng-template [ngIf]="errorText">{{ errorText }}</ng-template>
        <ng-template [ngIf]="!errorText && hint">{{ hint }}</ng-template>
      </mat-hint>
    </ng-template>
  </div>
</div>
