const formatStartingWithDay = 'DD/MM/YYYY';
const formatStartingWithMonth = 'MM/DD/YYYY';
const formatStartingWithYearJapanll = 'YYYY年MM月DD日';
const formatStartingWithYearJapanL = 'YYYY/MM/DD';
const timeFormatWithMeridiem = 'hh:mm A';
const timeFormat = 'HH:mm';

export const excelDateFormat = {
  de: formatStartingWithDay,
  'en-au': formatStartingWithDay,
  'en-ca': formatStartingWithMonth,
  'en-nz': formatStartingWithDay,
  'en-ie': formatStartingWithDay,
  'en-gb': formatStartingWithDay,
  en: formatStartingWithMonth,
  es: formatStartingWithDay,
  fr: formatStartingWithDay,
  'fr-ca': formatStartingWithDay,
  it: formatStartingWithDay,
  pl: formatStartingWithDay,
  tr: formatStartingWithDay,
  jall: formatStartingWithYearJapanll,
  jaL: formatStartingWithYearJapanL,

};

export const excelTimeFormat = {
  de: timeFormat,
  'en-au': timeFormatWithMeridiem,
  'en-ca': timeFormatWithMeridiem,
  'en-nz': timeFormatWithMeridiem,
  'en-ie': timeFormat,
  'en-gb': timeFormat,
  en: timeFormatWithMeridiem,
  es: timeFormat,
  fr: timeFormat,
  'fr-ca': timeFormat,
  it: timeFormat,
  pl: timeFormat,
  tr: timeFormat,
  ja: timeFormat,
};

export const localeDateObject = {
  en: 'English (United States)',
  'en-au': 'English (Australia)',
  'en-ca': 'English (Canada)',
  'en-nz': 'English (New Zealand)',
  'en-ie': 'English (Ireland)',
  'en-gb': 'English (United Kingdom)',
  tr: 'Türkçe',
  de: 'Deutsch',
  es: 'Español',
  fr: 'Français',
  it: 'Italiano',
  pl: 'Polski',
  'fr-ca': 'Français (Canada)',
  ja: '日本語',
};
