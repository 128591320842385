import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { EquipmentBrandsService } from '../../../shared/service/settings/equipment-brands/equipment-brands.service';
import * as AppActions from '../../app/actions';
import * as oeeAppReducer from '../../oee.reducer';
import * as ObjectActions from './equipment-brands.actions';
import { IEquipmentBrand } from './equipment-brands.model';

@Injectable()
export class EquipmentBrandsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: EquipmentBrandsService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
  ) {}

  getEquipmentData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LOAD_EQUIPMENT_BRANDS_DATA),
      switchMap((payload: ObjectActions.LoadEquipmentBrandsData) => {
        const { page, pageSize, sort, search } = payload.requestParams;

        const searchParams = { $and: [] };

        if (search !== '') {
          searchParams.$and.push({ $or: [{ name: { $cont: search } }] });
        }

        if (searchParams.$and.length === 0) {
          delete searchParams.$and;
        }

        let httpParams: HttpParams = new HttpParams()
          .set('page', String(page))
          .set('limit', String(pageSize))
          .set('s', JSON.stringify(searchParams));

        if (sort) {
          const direction: 'DESC' | 'ASC' = sort.type === 'descending' ? 'DESC' : 'ASC';
          httpParams = httpParams.set('sort', `${sort.column},${direction}`);
        }

        return this.service.getEquipmentBrands(httpParams).pipe(
          switchMap((response: GetManyResponseInterface<IEquipmentBrand>) => {
            return of(new ObjectActions.LoadedEquipmentBrandsData(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes));
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes));
      }),
    ),
  );

  editSingleEquipmentData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SINGLE_EDIT_EQUIPMENT_BRANDS_DATA),
      switchMap((objectData: ObjectActions.SingleEditEquipmentBrandsData) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return from(this.service.patchEquipmentBrandData(objectData.equipmentId, objectData.payload)).pipe(
          switchMap((response) => {
            return of(new ObjectActions.EditedSingleEditEquipmentBrandsData(response), new AppActions.HideLoader());
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchError(err), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  editEquipmentBrandsData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.EDIT_EQUIPMENT_BRANDS_DATA),
      switchMap((objectData: ObjectActions.EditEquipmentBrandsData) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return from(this.service.patchEquipmentBrandsData(objectData.payload)).pipe(
          switchMap((response) => {
            return of(new ObjectActions.EditedEquipmentBrandsData(response), new AppActions.HideLoader());
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchError(err), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  createEquipment = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.CREATE_EQUIPMENT_BRAND),
      switchMap((objectData: ObjectActions.CreateEquipmentBrand) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return from(this.service.createEquipmentBrand(objectData.payload)).pipe(
          switchMap((response) => {
            return of(new ObjectActions.CreatedEquipmentBrand(response), new AppActions.HideLoader());
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchError(err), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  deleteEquipment = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DELETE_EQUIPMENT_BRAND),
      switchMap((objectData: ObjectActions.DeleteEquipmentBrand) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return from(this.service.deleteEquipmentBrand(objectData.equipmentBrandId)).pipe(
          switchMap((response) => {
            return of(new ObjectActions.DeletedEquipmentBrand(response), new AppActions.HideLoader());
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchError(err), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  deleteEquipmentBrands = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DELETE_EQUIPMENT_BRANDS),
      switchMap((objectData: ObjectActions.DeleteEquipmentBrands) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return from(this.service.deleteEquipmentBrands(objectData.equipmentBrandIds)).pipe(
          switchMap((response) => {
            return of(new ObjectActions.DeletedEquipmentBrands(response), new AppActions.HideLoader());
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchError(err), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );
}
