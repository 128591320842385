import * as Actions from './page-header.actions';
import { PageHeaderStateInterface } from './page-header.model';

const initialState: PageHeaderStateInterface = {
  isDashboardMode: false,
  isFilterBarVisible: false,
  isPrintAllowed: false,
  isPrintButtonDisabled: false,
  hideHeaderActions: false,
  isHeaderVisible: true,
  isEditMode: false,
  isRootCauseAnalysisComparisonMode: false,
};

export function pageHeaderReducer(
  state: PageHeaderStateInterface = initialState,
  action: Actions.PageHeaderActions,
): PageHeaderStateInterface {
  switch (action.type) {
    case Actions.UPDATE_PAGE_MODE:
      return {
        ...state,
        isDashboardMode: action.isDashboardMode,
      };
    case Actions.UPDATE_FILTER_BAR_VISIBILITY:
      return {
        ...state,
        isFilterBarVisible: action.isVisible,
      };
    case Actions.RUN_PRINT_FUNCTIONALITY:
      return {
        ...state,
        isPrintAllowed: action.isPrintAllowed,
      };
    case Actions.CHANGE_PRINT_BUTTON_STATUS:
      return {
        ...state,
        isPrintButtonDisabled: action.isPrintButtonDisabled,
      };
    case Actions.UPDATE_PAGE_HEADER_BUTTONS_VISIBILITY:
      return {
        ...state,
        ...action.isPageHeaderButtonsVisible,
      };
    case Actions.SET_PAGE_TO_EDIT_OR_VIEW_MODE:
      return {
        ...state,
        isEditMode: action.isEditMode,
      };
    case Actions.RESET_STORE_TO_DEFAULT:
      return {
        ...initialState,
      };
    case Actions.CHANGE_ROOT_CAUSE_ANALYSIS_MODE:
      return {
        ...state,
        isRootCauseAnalysisComparisonMode: action.isRootCauseAnalysisComparisonMode,
      };
    default:
      return state;
  }
}
