import { Action } from '@ngrx/store';
import { HttpOptionInterface } from '../filter.model';
import { HttpErrorResponse } from '@angular/common/http';
import { IEquipmentList } from '../../../shared/component/filter/filter.class';

export enum FilterEquipmentActionTypes {
  StartLoadFilterEquipments = '[Filter Equipment] Load Filter Equipments',
  FilterEquipmentsLoaded = '[Filter Equipment] Filter Equipments Loaded',
  ResetEquipmentStore = '[Filter Equipment] Reset data stored in equipment store',
  FetchError = '[Filter Equipment] Fetch Error',
}

export class StartLoadFilterEquipments implements Action {
  readonly type = FilterEquipmentActionTypes.StartLoadFilterEquipments;

  constructor(public options: HttpOptionInterface) {}
}

export class FilterEquipmentsLoaded implements Action {
  readonly type = FilterEquipmentActionTypes.FilterEquipmentsLoaded;

  constructor(public data: IEquipmentList[]) {}
}

export class ResetEquipmentStore implements Action {
  readonly type = FilterEquipmentActionTypes.ResetEquipmentStore;
}

export class FetchError implements Action {
  readonly type = FilterEquipmentActionTypes.FetchError;

  constructor(public payload: HttpErrorResponse) {}
}

export type FilterEquipmentActions =
  | StartLoadFilterEquipments
  | FilterEquipmentsLoaded
  | ResetEquipmentStore
  | FetchError;
