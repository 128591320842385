import { App } from './model';
import * as Actions from './actions';

export const initialState: App = {
  loading: false,
  topLoading: false,
  loadingText: null,
  cicoAccess: null,
  showTour: false,
  activityListUpdate: false,
  masking: false,
  maintenanceModeData: null,
};

export function appReducer(state: App = initialState, action: Actions.AppActions) {
  switch (action.type) {
    case Actions.SHOW_LOADER:
      return {
        ...state,
        ...{ loading: true, loadingText: action.loadingText },
      };

    case Actions.HIDE_LOADER:
      return {
        ...state,
        ...{ loading: false, loadingText: null },
      };

    case Actions.SHOW_TOP_LOADER:
      return {
        ...state,
        ...{ topLoading: true },
      };

    case Actions.HIDE_TOP_LOADER:
      return {
        ...state,
        ...{ topLoading: false },
      };

    case Actions.SET_CICO_ACCESS:
      return {
        ...state,
        ...{ cicoAccess: action.cicoAccess },
      };

    case Actions.SET_SHOW_TOUR:
      return {
        ...state,
        ...{ showTour: action.showTour },
      };

    case Actions.SET_ACTIVITY_LIST_UPDATE:
      return {
        ...state,
        ...{ activityListUpdate: action.activityListUpdate },
      };

    case Actions.SHOW_MASK:
      return {
        ...state,
        ...{ masking: true },
      };

    case Actions.HIDE_MASK:
      return {
        ...state,
        ...{ masking: false },
      };

    case Actions.SET_MAINTENANCE_MODE:
      return {
        ...state,
        ...{ maintenanceModeData: action.payload },
      };

    default:
      return state;
  }
}
