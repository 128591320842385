import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OfflineSensorStatusResponseInterface } from './offline-sensor-status.model';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OfflineSensorStatusService {
  constructor(public http: HttpClient, @Inject('API_BASE_URL') private baseUrl: string) {}

  getOfflineSensorStatus(lineId: number): Observable<OfflineSensorStatusResponseInterface> {
    return this.http.get<OfflineSensorStatusResponseInterface>(
      environment.sensorStatusUrl.replace('{{lineId}}', lineId.toString()),
    );
  }
}
