import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ScenarioItemsDeployRequestInterface, SchedulerScenarioHorizonInterface } from './scheduler-service.model';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import {
  ScenarioAdvancedFilterAddRequestInterface,
  ScenarioItemInterface,
  ScenarioItemsResponseInterface,
  ScenarioWorkOrderRulesInterface,
  ISchedulerKpiMetricInfo,
  IKpiCardDataProperties,
  ScenarioResponseInterface,
  ESchedulerOptimizationType,
  SchedulerAllOptimizationsInterface,
  SchedulerOptimizationItemInterface,
  SchedulerOptimizationResponseInterface,
  UpdateSchedulerOptimizationInterface,
} from '../../../store/scheduler/scheduler.model';
import * as _ from 'lodash';
import * as moment from 'moment';
import { mysqlDateFormat, mysqlTimestampFormat } from '../../helper/date';
import { ActivitiesService } from '../activities/activities.service';
import { ActivitiesInterface } from '../../model/interface/activities.model';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../model/interface/crud-response-interface.model';
import { createQueryParams } from '../../helper/app-helper';
import { LineAvailabilityPlanDataInterface } from '../../../store/line-availability/line-availability.model';
import { IFilter } from '../../component/filter/advanced-filter/advanced-filter.model';
import { HelperService } from '../helper.service';
import { AdvancedFilterService } from '../../component/filter/advanced-filter/advanced-filter.service';
import { ScenarioBulkResponseDataInterface } from '../../../store/scheduler-scenario/scheduler-scenario.model';
import {
  FormattedWorkOrdersResponseInterface,
  WorkOrderInterface,
  WorkOrderResponseInterface,
} from '../../../store/work-order-schedule/work-order-schedule.model';
import { PresetLinesInterface } from '../../../store/scheduler-resource-setting/scheduler-resource-setting.model';
import { EventTooltipDataInterface, TaskType } from '../../../view/scheduler/gantt-view/gantt-view.model';
import { ResponseInterface } from '../../model/interface/generic-api-response.model';
import { DateHelper } from '@bryntum/scheduler';
import { TranslateService } from '@ngx-translate/core';
import { ToFixedPipe } from '../../pipe/decimal/decimal-to-fixed.pipe';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SchedulerService {
  private readonly routes = {
    scenarios: '/scheduler/scenarios',
    scenarioItems: '/scheduler/scenario-items',
    scenarioItemsBulkCreate: '/scheduler/scenario-items/bulk/create',
    deploy: '/scheduler/scenario-items/deploy',
    scenarioShiftPlans: '/scheduler/shift-plans',
    scenarioWorkOrderRules: '/scheduler/scenarios/work-order-rules',
    optimizeScheduler: '/optimize-scheduler',
    getSchedulerOptimization: '/scheduler-optimization',
    updateSchedulerOptimization: '/update-scheduler-optimization',
  };

  public scenarioItemsLoaded: boolean = false;
  public scenarioItemsLoadedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly api: string,
    public activityService: ActivitiesService,
    private readonly helperService: HelperService,
    private readonly advancedFilterService: AdvancedFilterService,
    public translate: TranslateService,
  ) {}

  public loadScenario(scenarioId: number): Observable<ScenarioResponseInterface> {
    const queryParams: HttpParams = new HttpParams()
      .append('join', 'schSetting')
      .append('join', 'schSetting.schSettingItem')
      .append('join', 'schSetting.schSettingLine')
      .append('join', 'schSetting.schSettingLine.line||title,lineType,siteId,activityIds,statusId')
      .append('join', 'schSetting.schSettingLine.line.lineTypeName||id,lineType')
      .append(
        's',
        JSON.stringify({
          'schSetting.schSettingLine.line.lineTypeName.status': { $eq: true },
          'schSetting.schSettingLine.line.statusId': { $eq: 1 },
        }),
      )
      .append('join', 'site||decimalScaleLimit');
    return this.http.get<ScenarioResponseInterface>(`${this.api}${this.routes.scenarios}/${scenarioId}`, {
      params: queryParams,
    });
  }

  public loadScenarioItems(
    scenarioId: number,
    startDate: string = null,
    endDate: string = null,
    lineIds: number[] = [],
  ): Observable<ScenarioItemsResponseInterface> {
    let params: HttpParams = new HttpParams();

    params = params.set('limit', '1000');

    const searchObj = {};

    _.set(searchObj, '$or', [{ scenarioId: { $eq: scenarioId } }, { scenarioId: { $isnull: true } }]);

    if (startDate !== null && endDate !== null) {
      _.set(searchObj, 'startDate.$lt', [moment(endDate).format(mysqlDateFormat)]);
      _.set(searchObj, 'endDate.$gt', [moment(startDate).format(mysqlDateFormat)]);
    }

    if (lineIds.length > 0) {
      _.set(searchObj, 'lineId.$in', lineIds);
    }

    params = params.set('s', JSON.stringify(searchObj));
    params = params.set('withActual', 'true');

    return this.http.get<ScenarioItemsResponseInterface>(`${this.api}${this.routes.scenarioItems}`, {
      params,
    });
  }

  public saveScenarioItems(scenarioId: number, scenarioItems): Observable<ScenarioItemsResponseInterface> {
    return this.http.post<ScenarioItemsResponseInterface>(`${this.api}${this.routes.scenarioItemsBulkCreate}`, {
      scenarioId,
      scenarioItems,
    });
  }

  public getDownTimeActivities(activityIds?: string[]): Observable<GetManyResponseInterface<ActivitiesInterface>> {
    const searchObj = {};
    _.set(searchObj, 'activityType.$eq', 'downTimePlanned');
    _.set(searchObj, 'active.$eq', true);
    if (activityIds !== undefined) {
      _.set(searchObj, 'id.$in', activityIds);
    }

    let param = new HttpParams();
    param = param.set('s', JSON.stringify(searchObj));

    return this.activityService.getActivities(param);
  }

  public deploy(
    horizon: SchedulerScenarioHorizonInterface,
    deployedItems: ScenarioItemsDeployRequestInterface[],
  ): Observable<BulkResponseDataInterface> {
    return this.http.post<BulkResponseDataInterface>(`${this.api}${this.routes.deploy}`, {
      horizonStart: horizon.startDate.format(mysqlTimestampFormat),
      horizonEnd: horizon.endDate.format(mysqlTimestampFormat),
      lineItemGroups: deployedItems,
    });
  }

  public loadPlans(planIds: number[]): Observable<GetManyResponseInterface<LineAvailabilityPlanDataInterface>> {
    const httpOptions: { params: HttpParams } = createQueryParams(
      1,
      planIds.length,
      undefined,
      JSON.stringify({
        id: { $in: planIds },
      }),
    );
    httpOptions.params = httpOptions.params
      .append('join', 'schedulerShiftPlanItem')
      .append('join', 'schedulerShiftPlanItem.schedulerShift');

    return this.http.get<GetManyResponseInterface<LineAvailabilityPlanDataInterface>>(
      `${this.api}${this.routes.scenarioShiftPlans}`,
      httpOptions,
    );
  }

  public addScenarioWorkOrderRules(
    workOrders: ScenarioWorkOrderRulesInterface[],
  ): Observable<ScenarioBulkResponseDataInterface> {
    return this.http.post<ScenarioBulkResponseDataInterface>(
      `${this.api}${this.routes.scenarioWorkOrderRules}`,
      workOrders,
    );
  }

  public setAsDefault(
    advancedFilter: ScenarioAdvancedFilterAddRequestInterface,
  ): Observable<ScenarioResponseInterface> {
    const normalizedFilters: IFilter[] = advancedFilter.filters.map((filter: IFilter) => ({
      ...filter,
      value: this.advancedFilterService.prepareValue(filter),
    }));
    return this.http.patch<ScenarioResponseInterface>(
      `${this.api}${this.routes.scenarios}/${advancedFilter.scenarioId}`,
      {
        advancedFilterConfig: {
          [advancedFilter.pageName]: normalizedFilters,
        },
      },
    );
  }

  public formatResponse(workOrderResponse: WorkOrderResponseInterface): FormattedWorkOrdersResponseInterface {
    let { rows, ...counts } = workOrderResponse.data;
    rows = rows.map((workOrder: WorkOrderInterface) => ({
      ...workOrder,
      oeeGoodCount: workOrder.goodCount,
      oeeInitialCount: workOrder.initialCount,
    }));

    return {
      ...workOrderResponse,
      ...counts,
      data: rows,
    };
  }

  public getSchedulerKpiMetricInfo(id: number): Observable<BaseOneResponseInterface<ISchedulerKpiMetricInfo>> {
    return forkJoin<{
      kpiMetricInfo: Observable<BaseOneResponseInterface<ISchedulerKpiMetricInfo>>;
      dummyData: Observable<BaseOneResponseInterface<any>>;
    }>({
      kpiMetricInfo: this.http.get<BaseOneResponseInterface<ISchedulerKpiMetricInfo>>(
        `${this.api}${this.routes.scenarios}/${id}/kpi-metrics`,
      ),
      dummyData: this.http.get<BaseOneResponseInterface<any>>(
        '/assets/scheduler_dump_data/kpiServiceAndInventoryMockData.json',
      ),
    }).pipe(
      map((response) => {
        response.kpiMetricInfo.data.serviceInfo = response.dummyData.data.serviceInfo;
        response.kpiMetricInfo.data.inventoryInfo = response.kpiMetricInfo.data.inventoryInfo.map((item, index) => {
          return {
            ...item,
            ...response.dummyData.data.inventoryInfo[index % response.dummyData.data.inventoryInfo.length],
          };
        });

        return response.kpiMetricInfo;
      }),
    );
  }

  public saveSchedulerKpiCardConfigs(scenarioId: number, config: any): Observable<BaseOneResponseInterface<void>> {
    return this.http.patch<BaseOneResponseInterface<void>>(
      `${this.api}${this.routes.scenarios}/${scenarioId}/kpi-metrics/save-configuration`,
      config,
    );
  }

  public saveSchedulerKpiCardData(
    scenarioId: number,
    kpiCardData: IKpiCardDataProperties,
  ): Observable<BaseOneResponseInterface<void>> {
    return this.http.patch<BaseOneResponseInterface<void>>(
      `${this.api}${this.routes.scenarios}/${scenarioId}/kpi-metrics/save-scheduler-kpi-card-data`,
      kpiCardData,
    );
  }

  public optimizeScheduler(
    scenarioId: number,
    workOrderIds: number[],
    optimizationType: ESchedulerOptimizationType,
  ): Observable<boolean> {
    return this.http.post<boolean>(
      `${this.api}${this.routes.scenarios}/${scenarioId}${this.routes.optimizeScheduler}`,
      { workOrderIds, optimizationType },
    );
  }

  public getSchedulerOptimizations(
    scenarioId: number,
  ): Observable<ResponseInterface<SchedulerAllOptimizationsInterface>> {
    return this.http.get<ResponseInterface<SchedulerAllOptimizationsInterface>>(
      `${this.api}${this.routes.scenarios}/${scenarioId}${this.routes.getSchedulerOptimization}`,
    );
  }

  public updateSchedulerOptimization(
    scenarioId: number,
    params: UpdateSchedulerOptimizationInterface,
  ): Observable<ResponseInterface<boolean>> {
    return this.http.patch<ResponseInterface<boolean>>(
      `${this.api}${this.routes.scenarios}/${scenarioId}${this.routes.updateSchedulerOptimization}`,
      {
        ...params,
      },
    );
  }

  public schedulerTooltipTemplate(
    data: EventTooltipDataInterface,
    decimalScaleLimit: number,
    dateFormats: {
      dateFormat: string;
      dateTimeFormat: string;
    },
    toFixedPipe: ToFixedPipe,
  ): string {
    if (data.eventRecord.type === TaskType.unplannedWorkOrder) {
      return `
          <div *ngIf='data.eventRecord.workOrderSchedule.woNumber !== undefined'>
          <b>${this.translate.instant('scheduler.ganttView.workOrderNumber')}:</b>
          ${data.eventRecord.workOrderSchedule.woNumber} </div>
          <div>
          <b>${this.translate.instant('scheduler.ganttView.productId')}:</b>
          ${data.eventRecord.workOrderSchedule.product.productId} </div>
          <div>
          <b>${this.translate.instant('scheduler.ganttView.description')}:</b>
          ${data.eventRecord.workOrderSchedule.product.description} </div>
          <div>
          <b>${this.translate.instant('scheduler.ganttView.quantity')}:</b>
          ${toFixedPipe.transform(data.eventRecord.workOrderSchedule.quantityOrdered, decimalScaleLimit)}</div>
          <div>
          <b>${this.translate.instant('scheduler.ganttView.dueDate')}:</b>
          ${DateHelper.format(new Date(data.eventRecord.workOrderSchedule.woDueDate), dateFormats.dateFormat)}
          </div>
          <div>
          <b>${this.translate.instant('scheduler.ganttView.scheduledStart')}:</b>
          ${DateHelper.format(new Date(data.eventRecord.startDate), dateFormats.dateTimeFormat)} </div>
          <div>
          <b>${this.translate.instant('scheduler.ganttView.scheduledEnd')}:</b>
          ${DateHelper.format(new Date(data.eventRecord.endDate), dateFormats.dateTimeFormat)} </div>
          <div>
          <b>${this.translate.instant('scheduler.ganttView.duration')}:</b>
          ${toFixedPipe.transform(data.eventRecord.duration, decimalScaleLimit)} ${this.translate.instant(
        'general.shortHour',
      )}</div>
         `;
    }

    return '';
  }

  public formattedSchedulerOptimization(
    optimizationData: SchedulerOptimizationResponseInterface,
    workOrders: WorkOrderInterface[],
    lines: PresetLinesInterface[],
    activities: ActivitiesInterface[],
    scenarioId: number,
  ): ScenarioItemInterface[] {
    return optimizationData.optimizations
      .map((data: SchedulerOptimizationItemInterface) => {
        const workOrder: WorkOrderInterface | undefined = workOrders?.find(
          (wo: WorkOrderInterface) => wo.id === data.workOrderId,
        );
        const line: PresetLinesInterface | undefined = lines?.find(
          (line: PresetLinesInterface) => line.lineId === data.lineId,
        );
        const activity: ActivitiesInterface | undefined = activities?.find(
          (activity: ActivitiesInterface) => activity.id === data.activityId,
        );
        const isValid: boolean = line !== undefined && (workOrder !== undefined || activity !== undefined);

        return isValid
          ? {
              scenarioId,
              id: null,
              lineId: data.lineId,
              title: line.line.title,
              name: data.workOrderId ? workOrder.woNumber : activity.name,
              workOrderScheduleId: data?.workOrderId,
              startDate: data.startDate,
              endDate: data.endDate,
              workOrderSchedule: data?.workOrderId
                ? {
                    actualDuration: workOrder.actualDuration,
                    actualRunDate: workOrder.actualRunDate,
                    averageDuration: workOrder.averageDuration,
                    bufferDuration: workOrder.bufferDuration,
                    id: workOrder.id,
                    jobNumber: workOrder.jobNumber,
                    lineType: workOrder.lineType,
                    product: workOrder.product,
                    productId: workOrder.productId,
                    quantityOrdered: workOrder.quantityOrdered,
                    releaseDate: workOrder.releaseDate,
                    scheduledEndDate: workOrder.scheduledEndDate,
                    scheduledLine: workOrder.scheduledLine,
                    scheduledRunDate: workOrder.scheduledRunDate,
                    site: workOrder.site,
                    siteId: workOrder.siteId,
                    woDueDate: workOrder.woDueDate,
                    woNumber: workOrder.woNumber,
                  }
                : null,
              line: {
                id: line.id,
                title: line.line.title,
              },
              type: data.workOrderId ? TaskType.unplannedWorkOrder : TaskType.downTimeActivity,
              activityId: data?.activityId,
              activity: data?.activityId
                ? {
                    id: data.activityId,
                    name: activity.name,
                  }
                : null,
            }
          : null;
      })
      .filter((item: ScenarioItemInterface) => item !== null);
  }
}
