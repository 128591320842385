import { FilterDepartmentActions, FilterDepartmentActionTypes } from './department.actions';
import { LineTypeNameInterface } from '../../../shared/component/filter/filter.class';
import { HttpErrorResponse } from '@angular/common/http';

export interface FilterDepartmentState {
  isLoading: boolean;
  isLoaded: boolean;
  data: LineTypeNameInterface[];
  errors: HttpErrorResponse[];
}

export const initialFilterDepartmentState: FilterDepartmentState = {
  isLoaded: false,
  isLoading: false,
  data: [],
  errors: [],
};

export function filterDepartmentReducer(
  state: FilterDepartmentState = initialFilterDepartmentState,
  action: FilterDepartmentActions,
): FilterDepartmentState {
  switch (action.type) {
    case FilterDepartmentActionTypes.StartLoadFilterDepartments:
      return { ...state, isLoaded: false, isLoading: true };

    case FilterDepartmentActionTypes.FilterDepartmentsLoaded:
      return { ...state, data: action.data, isLoaded: true, isLoading: false };

    case FilterDepartmentActionTypes.ResetDepartmentStore:
      return { ...state, data: [], isLoaded: false, isLoading: false };

    case FilterDepartmentActionTypes.FetchError:
      const errors = [...state.errors, action.payload];
      return { ...state, errors, isLoaded: false, isLoading: false };

    default:
      return state;
  }
}
