import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { FilterSensorTypeActions, FilterSensorTypeActionTypes, FilterSensorTypesLoaded } from './sensor-type.actions';
import { SensorTypesService } from '../../../shared/service/filter/sensor-types.service';
import { SensorTypeCRUDInterface } from '../../../shared/component/filter/filter.class';

@Injectable()
export class FilterSensorTypeEffects {
  constructor(private actions$: Actions<FilterSensorTypeActions>, public service: SensorTypesService) {}

  loadFilterSensorTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilterSensorTypeActionTypes.StartLoadFilterSensorTypes),
      switchMap(() => {
        return from(this.service.getData()).pipe(
          switchMap((response: SensorTypeCRUDInterface[]) => {
            return of(new FilterSensorTypesLoaded(response));
          }),
          catchError((err) => {
            return of(new FilterSensorTypesLoaded([]));
          }),
        );
      }),
    ),
  );
}
