<label *ngIf="selectedItems.length > 0 && dropdownLabel" class="filter-dropdown-label">{{this.dropdownLabel}}</label>
<scw-mat-select
  [data]="isLoading$ ? [] : dropdownList"
  [(inputModel)]="selectedItems"
  [isLoading]="isLoading$"
  [singleSelection]="defaultSettings.singleSelection"
  [text]="defaultSettings.text"
  [limitSelection]="defaultSettings.limitSelection"
  [selectAllText]="defaultSettings.selectAllText"
  [unSelectAllText]="defaultSettings.unSelectAllText"
  [filterSelectAllText]="defaultSettings.filterSelectAllText"
  [filterUnSelectAllText]="defaultSettings.filterUnSelectAllText"
  [searchBy]="defaultSettings.searchBy"
  [groupBy]="defaultSettings.groupBy"
  [maxHeight]="defaultSettings.maxHeight"
  [badgeShowLimit]="defaultSettings.badgeShowLimit"
  [classes]="defaultSettings.classes"
  [disabled]="isLoading$"
  [searchPlaceholderText]="defaultSettings.searchPlaceholderText"
  [showCheckbox]="!defaultSettings.singleSelection"
  [noDataLabel]="isLoading$ ? ('filterCard.dropdown.loading' | translate) : defaultSettings.noDataLabel"
  [searchAutofocus]="defaultSettings.searchAutofocus"
  [lazyLoading]="defaultSettings.lazyLoading"
  [labelKey]="defaultSettings.labelKey"
  [primaryKey]="defaultSettings.primaryKey"
  [position]="defaultSettings.position"
  [autoPosition]="defaultSettings.autoPosition"
  [selectGroup]="defaultSettings.selectGroup"
  [addNewItemOnFilter]="defaultSettings.addNewItemOnFilter"
  [addNewButtonText]="defaultSettings.addNewButtonText"
  [escapeToClose]="defaultSettings.escapeToClose"
  [clearAll]="defaultSettings.clearAll"
  [noPadding]="true"
  [belongsToFilterCardComponent]="true"
  [clearSearchOnOpen]="true"
  [enableFilterSelectAll]="defaultSettings.enableFilterSelectAll"
  [enableCheckAll]="!defaultSettings.limitSelection && !defaultSettings.singleSelection && dropdownList.length > 1"
  [enableSearchFilter]="defaultSettings.enableServerSideSearch || dropdownList.length > 3"
  [showLoadMoreButton]="defaultSettings.lazyLoading && lazyLoadingConf.allowNewRequest"
  [searchBehavior]="defaultSettings.enableServerSideSearch ? 'delayed' : 'live'"
  (onOpen)="onOpen()"
  (onSelect)="onItemSelect()"
  (onDeSelect)="onItemDeselect()"
  (onSelectAll)="onSelectAll()"
  (onDeSelectAll)="onDeSelectAll()"
  (onGroupSelect)="onGroupSelect()"
  (onGroupDeSelect)="onGroupDeSelect()"
  (onSearch)="onSearch($event)"
  (onScrollToEnd)="fetchMore($event)"
></scw-mat-select>
<span
  *ngIf="defaultSettings.lazyLoading && selectedItems.length > 1 && lazyLoadingConf.totalCount > 0"
  [class.disabled]="isLoading$"
  class="lazy-loading-count-placeholder"
  >{{ selectedItems.length }}/{{ lazyLoadingConf.totalCount }}</span
>
