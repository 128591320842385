import { Action } from '@ngrx/store';
import { ActivityViewDataInterface } from './activity-view.model';

export enum EActivityViewAction {
  ACTIVITY_VIEW_DATA_LOAD = '[ACTIVITY VIEW] Get activity view data',
  ACTIVITY_VIEW_DATA_LOADED = '[ACTIVITY VIEW] Get activity view data complete',
  ACTIVITY_VIEW_FETCH_ERROR = '[ACTIVITY VIEW] Fetch Error',
}

export class ActivityViewDataLoading implements Action {
  readonly type: EActivityViewAction.ACTIVITY_VIEW_DATA_LOAD = EActivityViewAction.ACTIVITY_VIEW_DATA_LOAD;

  constructor(public lineId: number) {}
}

export class ActivityViewDataLoaded implements Action {
  readonly type: EActivityViewAction.ACTIVITY_VIEW_DATA_LOADED = EActivityViewAction.ACTIVITY_VIEW_DATA_LOADED;

  constructor(public data: ActivityViewDataInterface) {}
}

export class ActivityViewFetchError implements Action {
  readonly type: EActivityViewAction.ACTIVITY_VIEW_FETCH_ERROR = EActivityViewAction.ACTIVITY_VIEW_FETCH_ERROR;

  constructor(public payload: any[]) {}
}

export type ActivityViewActions = ActivityViewDataLoading | ActivityViewDataLoaded | ActivityViewFetchError;
