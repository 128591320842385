import { FilterSensorActions, FilterSensorActionTypes } from './sensor.actions';
import { FilterSensorState } from './sensor.model';

export const initialFilterSensorState: FilterSensorState = {
  isLoaded: false,
  isLoading: false,
  data: [],
};

export function filterSensorReducer(state = initialFilterSensorState, action: FilterSensorActions): FilterSensorState {
  switch (action.type) {
    case FilterSensorActionTypes.StartLoadFilterSensors:
      return { ...state, isLoading: true, isLoaded: false };

    case FilterSensorActionTypes.FilterSensorsLoaded:
      return { ...state, data: action.payload, isLoaded: true, isLoading: false };

    default:
      return state;
  }
}
