import * as Actions from './equipments.actions';
import { IEquipmentsState } from './equipments.model';

export const equipmentState: IEquipmentsState = {
  anonymousCards: [],
  isAnonymousCardsLoading: false,
  isAnonymousCardsLoaded: false,
  isEquipmentsDataLoading: false,
  isEquipmentsDataLoaded: false,
  equipmentsData: null,
  totalEquipmentsDataCount: 0,
  fileDownloadStatus: false,
  excelUploadResult: [],
  isUploadExcelLoading: false,
  isUploadExcelLoaded: false,
};

export function equipmentsReducer(
  state: IEquipmentsState = equipmentState,
  action: Actions.EquipmentsActions,
): IEquipmentsState {
  switch (action.type) {
    case Actions.CLEAR_STATE:
      return { ...equipmentState };

    case Actions.LOAD_ANONYMOUS_CARDS:
      return {
        ...state,
        isAnonymousCardsLoading: true,
        isAnonymousCardsLoaded: false,
      };

    case Actions.ANONYMOUS_CARDS_LOADED:
      return {
        ...state,
        isAnonymousCardsLoading: false,
        isAnonymousCardsLoaded: true,
        anonymousCards: action.payload.data,
      };

    case Actions.EQUIPMENT_DATA_COUNT_LOADED:
      return {
        ...state,
        totalEquipmentsDataCount: action.payload.total,
      };

    case Actions.LOAD_EQUIPMENTS_DATA:
      return {
        ...state,
        isEquipmentsDataLoading: true,
        isEquipmentsDataLoaded: false,
      };

    case Actions.LOADED_EQUIPMENTS_DATA:
      return {
        ...state,
        isEquipmentsDataLoading: false,
        isEquipmentsDataLoaded: true,
        equipmentsData: action.payload,
      };

    case Actions.DOWNLOAD_EQUIPMENT_EXCEL:
      return {
        ...state,
        ...{
          fileDownloadStatus: true,
        },
      };
    case Actions.DOWNLOAD_EQUIPMENT_EXCEL_COMPLETED:
      return {
        ...state,
        ...{
          fileDownloadStatus: false,
        },
      };
    case Actions.UPLOAD_EQUIPMENT_EXCEL:
      return {
        ...state,
        ...{
          isUploadExcelLoading: true,
          isUploadExcelLoaded: false,
        },
      };
    case Actions.UPLOAD_EQUIPMENT_EXCEL_COMPLETED:
      return {
        ...state,
        ...{
          excelUploadResult: action.payload,
          isUploadExcelLoading: false,
          isUploadExcelLoaded: true,
        },
      };
    default:
      return state;
  }
}
