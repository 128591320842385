import { Action } from '@ngrx/store';
import {
  CommentUpdateDataInterface,
  CommentCreateDataInterface,
  ProductionReviewTargetCRUDInterface,
  ProductionReviewTargetDataInterface,
  ProductionReviewTargetSaveData,
  ProductionReviewTargetUpdateDataInterface,
  ProductionReviewCommentCRUDInterface,
} from './production-review-list-view.model';
import { IActivityHistory } from '../../shared/service/activity-history/activity-history.model';
import { BaseOneResponseInterface } from '../../shared/model/interface/crud-response-interface.model';
import { WorkOrderHeader } from '../../view/home/work-order/work-order.model';

export const CLEAR_STATE = '[PRODUCTION_REVIEW_LIST_VIEW] CLEAR STATE';
export const START_ACTIVITY_DATA_LOADING = '[PRODUCTION_REVIEW_LIST_VIEW] START ACTIVITY DATA LOADING';
export const SET_ACTIVITY_DATA_LOADED = '[PRODUCTION_REVIEW_LIST_VIEW] SET ACTIVITY DATA LOADED';
export const CREATE_PRODUCTION_REVIEW_COMMENT_LOADING = '[PRODUCTION_REVIEW_LIST_VIEW] CREATE PRODUCTION REVIEW COMMENT LOADING';
export const CREATE_PRODUCTION_REVIEW_COMMENT_LOADED = '[PRODUCTION_REVIEW_LIST_VIEW] CREATE PRODUCTION REVIEW COMMENT LOADED';
export const UPDATE_PRODUCTION_REVIEW_COMMENT_LOADING = '[PRODUCTION_REVIEW_LIST_VIEW] UPDATE PRODUCTION REVIEW COMMENT LOADING';
export const UPDATE_PRODUCTION_REVIEW_COMMENT_LOADED = '[PRODUCTION_REVIEW_LIST_VIEW] UPDATE PRODUCTION REVIEW COMMENT LOADED';
export const CREATE_PRODUCTION_REVIEW_TARGET_LOADING =
  '[PRODUCTION_REVIEW_LIST_VIEW] CREATE PRODUCTION REVIEW TARGET LOADING';
export const CREATE_PRODUCTION_REVIEW_TARGET_LOADED =
  '[PRODUCTION_REVIEW_LIST_VIEW] CREATE PRODUCTION REVIEW TARGET LOADED';
export const UPDATE_PRODUCTION_REVIEW_TARGET_LOADING =
  '[PRODUCTION_REVIEW_LIST_VIEW] UPDATE PRODUCTION REVIEW TARGET LOADING';
export const UPDATE_PRODUCTION_REVIEW_TARGET_LOADED =
  '[PRODUCTION_REVIEW_LIST_VIEW] UPDATE PRODUCTION REVIEW TARGET LOADED';
export const DELETE_PRODUCTION_REVIEW_TARGET_LOADING =
  '[PRODUCTION_REVIEW_LIST_VIEW] DELETE PRODUCTION REVIEW TARGET LOADING';
export const DELETE_PRODUCTION_REVIEW_TARGET_LOADED =
  '[PRODUCTION_REVIEW_LIST_VIEW] DELETE PRODUCTION REVIEW TARGET LOADED';
export const BULK_SAVE_PRODUCTION_REVIEW_TARGET_LOADING =
  '[PRODUCTION_REVIEW_LIST_VIEW] BULK SAVE PRODUCTION REVIEW TARGET LOADING';
export const BULK_SAVE_PRODUCTION_REVIEW_TARGET_LOADED =
  '[PRODUCTION_REVIEW_LIST_VIEW] BULK SAVE PRODUCTION REVIEW TARGET LOADED';
export const BULK_UPDATE_PRODUCTION_REVIEW_TARGET_LOADING =
  '[PRODUCTION_REVIEW_LIST_VIEW] BULK UPDATE PRODUCTION REVIEW TARGET LOADING';
export const BULK_UPDATE_PRODUCTION_REVIEW_TARGET_LOADED =
  '[PRODUCTION_REVIEW_LIST_VIEW] BULK UPDATE PRODUCTION REVIEW TARGET LOADED';

export class ClearState implements Action {
  readonly type = CLEAR_STATE;
}

export class StartActivityDataLoading implements Action {
  readonly type = START_ACTIVITY_DATA_LOADING;

  constructor(public id: number) {}
}

export class SetActivityDataLoaded implements Action {
  readonly type = SET_ACTIVITY_DATA_LOADED;

  constructor(public payload: IActivityHistory) {}
}

export class CreateProductionReviewTargetLoading implements Action {
  readonly type = CREATE_PRODUCTION_REVIEW_TARGET_LOADING;

  constructor(public data: ProductionReviewTargetDataInterface) {}
}

export class CreateProductionReviewTargetLoaded implements Action {
  readonly type = CREATE_PRODUCTION_REVIEW_TARGET_LOADED;

  constructor(public payload: BaseOneResponseInterface<ProductionReviewTargetCRUDInterface>) {}
}

export class CreateProductionReviewCommentLoading implements Action {
  readonly type = CREATE_PRODUCTION_REVIEW_COMMENT_LOADING;

  constructor(public data: CommentCreateDataInterface) {}
}

export class CreateProductionReviewCommentLoaded implements Action {
  readonly type = CREATE_PRODUCTION_REVIEW_COMMENT_LOADED;

  constructor(public payload: BaseOneResponseInterface<ProductionReviewCommentCRUDInterface>) {}
}
export class UpdateProductionReviewCommentLoading implements Action {
  readonly type = UPDATE_PRODUCTION_REVIEW_COMMENT_LOADING;

  constructor(public data: CommentUpdateDataInterface) {}
}

export class UpdateProductionReviewCommentLoaded implements Action {
  readonly type = UPDATE_PRODUCTION_REVIEW_COMMENT_LOADED;

  constructor(public payload: BaseOneResponseInterface<ProductionReviewCommentCRUDInterface>) {}
}

export class UpdateProductionReviewTargetLoading implements Action {
  readonly type = UPDATE_PRODUCTION_REVIEW_TARGET_LOADING;

  constructor(public target: ProductionReviewTargetUpdateDataInterface) {}
}

export class UpdateProductionReviewTargetLoaded implements Action {
  readonly type = UPDATE_PRODUCTION_REVIEW_TARGET_LOADED;

  constructor(public payload: BaseOneResponseInterface<ProductionReviewTargetCRUDInterface>) {}
}

export class DeleteProductionReviewTargetLoading implements Action {
  readonly type = DELETE_PRODUCTION_REVIEW_TARGET_LOADING;

  constructor(public id: number, public workOrderHeader: WorkOrderHeader) {}
}

export class DeleteProductionReviewTargetLoaded implements Action {
  readonly type = DELETE_PRODUCTION_REVIEW_TARGET_LOADED;

  constructor(public workOrderHeader: WorkOrderHeader) {}
}

export class BulkSaveProductionReviewTargetLoading implements Action {
  readonly type = BULK_SAVE_PRODUCTION_REVIEW_TARGET_LOADING;

  constructor(public createOrUpdateParameters: ProductionReviewTargetSaveData[]) {}
}

export class BulkSaveProductionReviewTargetLoaded implements Action {
  readonly type = BULK_SAVE_PRODUCTION_REVIEW_TARGET_LOADED;

  constructor() {}
}

export class BulkUpdateProductionReviewTargetLoading implements Action {
  readonly type = BULK_UPDATE_PRODUCTION_REVIEW_TARGET_LOADING;

  constructor(public updateParameters: ProductionReviewTargetUpdateDataInterface[]) {}
}

export class BulkUpdateProductionReviewTargetLoaded implements Action {
  readonly type = BULK_UPDATE_PRODUCTION_REVIEW_TARGET_LOADED;

  constructor() {}
}

export type ProductionReviewListViewActions =
  | ClearState
  | StartActivityDataLoading
  | SetActivityDataLoaded
  | UpdateProductionReviewTargetLoading
  | UpdateProductionReviewTargetLoaded
  | DeleteProductionReviewTargetLoading
  | DeleteProductionReviewTargetLoaded
  | BulkSaveProductionReviewTargetLoading
  | BulkSaveProductionReviewTargetLoaded
  | BulkUpdateProductionReviewTargetLoading
  | BulkUpdateProductionReviewTargetLoaded
  | CreateProductionReviewTargetLoading
  | CreateProductionReviewTargetLoaded
  | CreateProductionReviewCommentLoading
  | CreateProductionReviewCommentLoaded
  | UpdateProductionReviewCommentLoading
  | UpdateProductionReviewCommentLoaded;
