import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { GlobalViewService } from '../../shared/service/global-view/global-view.service';
import * as AppActions from '../app/actions';
import * as oeeAppReducer from '../oee.reducer';
import * as GlobalViewActions from './global-view.actions';
import { catchError, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { SiteLineFilterDataInterface } from './global-view.model';

@Injectable()
export class GlobalViewEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly http: HttpClient,
    private readonly toast: ToastrService,
    private readonly translate: TranslateService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly service: GlobalViewService,
  ) {}

  getGlobalViewFilterData: Observable<
    GlobalViewActions.FilterDataLoaded | GlobalViewActions.FetchDataError | AppActions.HideLoader
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalViewActions.GLOBAL_VIEW_FILTER_DATA_LOADING),
      switchMap(() => {
        return this.service.getFiltersAndSettings().pipe(
          switchMap((siteLineFilterData: SiteLineFilterDataInterface) => {
            return of(new GlobalViewActions.FilterDataLoaded(siteLineFilterData));
          }),
          catchError((errorResponse) => {
            return of(new GlobalViewActions.FetchDataError(errorResponse), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorResponse) => {
        return of(new GlobalViewActions.FetchDataError(errorResponse), new AppActions.HideLoader());
      }),
    ),
  );

  setFilterDataLoaded: Observable<
    | GlobalViewActions.SetSelectedSiteFilters
    | GlobalViewActions.SetSelectedLineFilters
    | GlobalViewActions.FetchDataError
    | AppActions.HideLoader
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalViewActions.GLOBAL_VIEW_FILTER_DATA_GENERATED),
      switchMap((objectData: GlobalViewActions.FilterDataGenerated) => {
        return of(
          new GlobalViewActions.SetSelectedSiteFilters(objectData.payload.siteIds),
          new GlobalViewActions.SetSelectedLineFilters(objectData.payload.lineIds),
        );
      }),
      catchError((errorResponse) => {
        return of(new GlobalViewActions.FetchDataError(errorResponse), new AppActions.HideLoader());
      }),
    ),
  );

  getGlobalViewSiteData: Observable<
    GlobalViewActions.SiteDataLoaded | GlobalViewActions.FetchDataError | AppActions.HideLoader
  > = createEffect(() =>
    this.actions$.pipe(
      ofType(GlobalViewActions.GLOBAL_VIEW_SITE_DATA_LOADING),
      switchMap((objectData: GlobalViewActions.SiteDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getGlobalViewSiteData(objectData.payload).pipe(
          switchMap((responseData: any) => {
            return of(new GlobalViewActions.SiteDataLoaded(responseData.data), new AppActions.HideLoader());
          }),
          catchError((errorResponse) => {
            return of(new GlobalViewActions.FetchDataError(errorResponse), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorResponse) => {
        return of(new GlobalViewActions.FetchDataError(errorResponse), new AppActions.HideLoader());
      }),
    ),
  );
}
