import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class SchedulerScenarioDeployService {

  private readonly eventSubject: Subject<number[]> = new Subject<number[]>();

  deployLines(lineIds: number[]) {
    this.eventSubject.next(lineIds);
  }

  getEventSubject(): Subject<number[]> {
    return this.eventSubject;
  }
}
