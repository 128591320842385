import { Injectable } from '@angular/core';
import { IPageConfigurationGroup } from '../../shared/component/page-configuration/page-configuration.model';
import { EShiftSummaryGroups } from '../../view/shift-summary/shift-summary.model';
import { ITableHeader, PageConfigurationTypes } from '../../../constants.model';
import { IComponentConfiguration } from '../user-configuration/user-configuration.model';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { TabRowInterface } from '../../shared/component/side-config-bar/side-config-bar.model';
import { ColumnNames } from './shift-summary.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ShiftSummaryService {
  public readonly forRegularViewLabel: string = this.translate.instant('shiftSummary.table.headers.forRegularView');
  public readonly shiftSummaryTableDefaults: ITableHeader[] = [
    {
      value: EShiftSummaryGroups.GENERAL_INFO,
      name: this.translate.instant('shiftSummary.table.headerGroups.generalInfo'),
      colspan: 5,
      selected: true,
      sortable: true,
      parentId: EShiftSummaryGroups.COLUMN_GROUPS,
      type: PageConfigurationTypes.HEADER_GROUP,
    },
    {
      value: EShiftSummaryGroups.PHASES,
      name: this.translate.instant('shiftSummary.table.headerGroups.phases'),
      colspan: 3,
      selected: true,
      sortable: true,
      parentId: EShiftSummaryGroups.COLUMN_GROUPS,
      type: PageConfigurationTypes.HEADER_GROUP,
    },
    {
      value: 'taskGroups',
      name: this.translate.instant('shiftSummary.table.headerGroups.taskGroups'),
      colspan: 1,
      selected: true,
      sortable: true,
      parentId: EShiftSummaryGroups.COLUMN_GROUPS,
      type: PageConfigurationTypes.HEADER_GROUP,
    },
    {
      value: EShiftSummaryGroups.PHASE_LABOR_HOURS,
      name: this.translate.instant('shiftSummary.table.headerGroups.phaseLaborHours'),
      colspan: 4,
      selected: true,
      sortable: true,
      parentId: EShiftSummaryGroups.COLUMN_GROUPS,
      type: PageConfigurationTypes.HEADER_GROUP,
    },
    {
      value: EShiftSummaryGroups.SHIFT_OVERVIEW,
      name: this.translate.instant('shiftSummary.table.headerGroups.shiftOverview'),
      colspan: 6,
      selected: false,
      sortable: true,
      parentId: EShiftSummaryGroups.COLUMN_GROUPS,
      type: PageConfigurationTypes.HEADER_GROUP,
    },
    {
      value: EShiftSummaryGroups.COMMENTS,
      name: this.translate.instant('shiftSummary.table.headers.comments'),
      class: 'sticky-right-col custom-header-style',
      colspan: 2,
      selected: true,
      sortable: false,
      parentId: EShiftSummaryGroups.COLUMN_GROUPS,
      type: PageConfigurationTypes.HEADER_GROUP,
    },
    {
      value: 'lineName',
      name: this.translate.instant('shiftSummary.table.headers.lineName'),
      selected: true,
      sortable: false,
      parentId: EShiftSummaryGroups.GENERAL_INFO,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'shiftName',
      name: this.translate.instant('general.shiftReview'),
      selected: false,
      sortable: false,
      parentId: EShiftSummaryGroups.GENERAL_INFO,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'workOrder',
      name: this.translate.instant('shiftSummary.table.headers.workOrder'),
      selected: true,
      sortable: false,
      parentId: EShiftSummaryGroups.GENERAL_INFO,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'processOrder',
      name: this.translate.instant('shiftSummary.table.headers.processOrder'),
      selected: true,
      sortable: false,
      parentId: EShiftSummaryGroups.GENERAL_INFO,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'operationNumber',
      name: this.translate.instant('shiftSummary.table.headers.operationNumber'),
      selected: true,
      sortable: false,
      parentId: EShiftSummaryGroups.GENERAL_INFO,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'erp',
      name: this.translate.instant('shiftSummary.table.erp'),
      selected: true,
      sortable: false,
      parentId: EShiftSummaryGroups.GENERAL_INFO,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'productDescription',
      name: this.translate.instant('shiftSummary.table.headers.productDescription'),
      selected: true,
      sortable: false,
      parentId: EShiftSummaryGroups.GENERAL_INFO,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'descriptiveProductId',
      name: this.translate.instant('shiftSummary.table.headers.productId'),
      selected: true,
      sortable: false,
      parentId: EShiftSummaryGroups.GENERAL_INFO,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'checkedInOperators',
      name: this.translate.instant('shiftSummary.table.headers.checkedInOperators'),
      selected: true,
      sortable: false,
      parentId: EShiftSummaryGroups.GENERAL_INFO,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'laborHour',
      name: this.translate.instant('shiftSummary.table.headers.laborHour'),
      selected: true,
      sortable: false,
      parentId: EShiftSummaryGroups.GENERAL_INFO,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'preRunPhaseDuration',
      name: this.translate.instant('shiftSummary.table.headers.preRunPhaseDuration'),
      selected: true,
      sortable: false,
      parentId: EShiftSummaryGroups.PHASES,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'runPhaseDuration',
      name: this.translate.instant('shiftSummary.table.headers.runPhaseDuration'),
      selected: true,
      sortable: false,
      parentId: EShiftSummaryGroups.PHASES,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'postRunPhaseDuration',
      name: this.translate.instant('shiftSummary.table.headers.postRunPhaseDuration'),
      selected: true,
      sortable: false,
      parentId: EShiftSummaryGroups.PHASES,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'preRunCheckInDuration',
      name: this.translate.instant('shiftSummary.table.headers.preRunPhaseDuration'),
      selected: true,
      sortable: false,
      parentId: EShiftSummaryGroups.PHASE_LABOR_HOURS,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'runCheckInDuration',
      name: this.translate.instant('shiftSummary.table.headers.runPhaseDuration'),
      selected: true,
      sortable: false,
      parentId: EShiftSummaryGroups.PHASE_LABOR_HOURS,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'postRunCheckInDuration',
      name: this.translate.instant('shiftSummary.table.headers.postRunPhaseDuration'),
      selected: true,
      sortable: false,
      parentId: EShiftSummaryGroups.PHASE_LABOR_HOURS,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'changeOverPhaseDuration',
      name: this.translate.instant('shiftSummary.table.headers.changeoverPhaseDuration'),
      selected: true,
      sortable: false,
      parentId: EShiftSummaryGroups.PHASE_LABOR_HOURS,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'shiftGoalComments',
      name: this.translate.instant('shiftSummary.table.headers.goalOfShift'),
      selected: false,
      sortable: false,
      parentId: EShiftSummaryGroups.SHIFT_OVERVIEW,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'shiftEndStatusComments',
      name: this.translate.instant('shiftSummary.table.headers.statusEndOfShift'),
      selected: false,
      sortable: false,
      parentId: EShiftSummaryGroups.SHIFT_OVERVIEW,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'shiftGoalHitComments',
      name: this.translate.instant('shiftSummary.table.headers.goalHit'),
      selected: false,
      sortable: false,
      parentId: EShiftSummaryGroups.SHIFT_OVERVIEW,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'shiftTopDownTimeActivityComments',
      name: this.translate.instant('shiftSummary.table.headers.topDowntimeActivity'),
      selected: false,
      sortable: false,
      parentId: EShiftSummaryGroups.SHIFT_OVERVIEW,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'shiftActionItemComments',
      name: this.translate.instant('shiftSummary.table.headers.lessonsLearnedAndProposedActions'),
      selected: false,
      sortable: false,
      parentId: EShiftSummaryGroups.SHIFT_OVERVIEW,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'nextShiftGoalComments',
      name: this.translate.instant('shiftSummary.table.headers.goalOfNextShift'),
      selected: false,
      sortable: false,
      parentId: EShiftSummaryGroups.SHIFT_OVERVIEW,
      type: PageConfigurationTypes.TABLE,
    },
    {
      value: 'supervisorComments',
      name: this.translate.instant('shiftSummary.table.headers.supervisorComment'),
      selected: true,
      sortable: false,
      parentId: EShiftSummaryGroups.COMMENTS,
      type: PageConfigurationTypes.TABLE,
      class: 'sticky-right-cell comment-header',
    },
    {
      value: 'maintenanceComments',
      name: this.translate.instant('shiftSummary.table.headers.maintenanceComment'),
      selected: true,
      sortable: false,
      parentId: EShiftSummaryGroups.COMMENTS,
      type: PageConfigurationTypes.TABLE,
      class: 'sticky-right-cell comment-header',
    },
  ];
  public readonly shiftSummaryTableGroups: IPageConfigurationGroup<EShiftSummaryGroups>[] = [
    {
      id: EShiftSummaryGroups.COLUMN_GROUPS,
      name: this.translate.instant('pageConfiguration.modal.groups'),
      description: this.translate.instant('pageConfiguration.modal.columnGroupLabelWithoutSort'),
      icon: 'fas fa-grip-horizontal',
      values: [],
      isVisible: true,
    },
    {
      id: EShiftSummaryGroups.COLUMNS,
      name: this.translate.instant('pageConfiguration.modal.columns'),
      description: this.translate.instant('pageConfiguration.modal.columnLabelWithoutSort'),
      icon: 'fas fa-table',
      values: [],
      isVisible: true,
    },
    {
      id: EShiftSummaryGroups.GENERAL_INFO,
      name: this.translate.instant('shiftSummary.table.headerGroups.generalInfo'),
      values: [],
      isVisible: true,
    },
    {
      id: EShiftSummaryGroups.PHASES,
      name: this.translate.instant('shiftSummary.table.headerGroups.phases'),
      values: [],
      isVisible: true,
    },
    {
      id: EShiftSummaryGroups.PHASE_LABOR_HOURS,
      name: this.translate.instant('shiftSummary.table.headerGroups.phaseLaborHours'),
      values: [],
      isVisible: true,
    },
    {
      id: EShiftSummaryGroups.SHIFT_OVERVIEW,
      name: this.translate.instant('shiftSummary.table.headerGroups.shiftOverview'),
      description: `(${this.translate.instant('shiftSummary.table.headers.forLineBasedView')})`,
      values: [],
      isVisible: true,
    },
    {
      id: EShiftSummaryGroups.COMMENTS,
      name: this.translate.instant('shiftSummary.table.headers.comments'),
      values: [],
      isVisible: true,
    },
  ];
  public readonly defaultHeaderGroupsOrder: string[] = [
    null,
    ...this.shiftSummaryTableDefaults
      .filter((header: ITableHeader) => header.parentId === EShiftSummaryGroups.COLUMN_GROUPS)
      .map((header: ITableHeader) => header.value),
  ];
  public readonly defaultGeneralInfoColumns: string[] = this.shiftSummaryTableDefaults
    .filter((header: ITableHeader) => header.parentId === EShiftSummaryGroups.GENERAL_INFO)
    .map((header: ITableHeader) => header.value);
  public readonly defaultPhaseColumns: string[] = this.shiftSummaryTableDefaults
    .filter((header: ITableHeader) => header.parentId === EShiftSummaryGroups.PHASES)
    .map((header: ITableHeader) => header.value);
  public readonly defaultPhaseLaborHourColumns: string[] = this.shiftSummaryTableDefaults
    .filter((header: ITableHeader) => header.parentId === EShiftSummaryGroups.PHASE_LABOR_HOURS)
    .map((header: ITableHeader) => header.value);
  public readonly defaultShiftOverviewColumns: string[] = this.shiftSummaryTableDefaults
    .filter((header: ITableHeader) => header.parentId === EShiftSummaryGroups.SHIFT_OVERVIEW)
    .map((header: ITableHeader) => header.value);
  public readonly defaultCommentColumns: string[] = this.shiftSummaryTableDefaults
    .filter((header: ITableHeader) => header.parentId === EShiftSummaryGroups.COMMENTS)
    .map((header: ITableHeader) => header.value);
  public readonly columnsToIgnoreOnLineBasedView: string[] = [
    'erp',
    'descriptiveProductId',
    'operationNumber',
    'processOrder',
  ];
  private readonly preRunLabel: string = this.translate.instant('homeScreenMetrics.activityOrderTypes.pre');
  private readonly runLabel: string = this.translate.instant('homeScreenMetrics.activityOrderTypes.run');
  private readonly postRunLabel: string = this.translate.instant('homeScreenMetrics.activityOrderTypes.post');
  constructor(
    private readonly translate: TranslateService,
    public http: HttpClient,
  ) {}

  public prepareConfigurationGroups(
    userConfiguration: IComponentConfiguration[],
  ): IPageConfigurationGroup<EShiftSummaryGroups>[] {
    const tableConfigurationGroups: IPageConfigurationGroup<EShiftSummaryGroups>[] = _.cloneDeep(
      this.shiftSummaryTableGroups.map((configurationGroup: IPageConfigurationGroup<EShiftSummaryGroups>) => {
        return {
          ...configurationGroup,
          isVisible:
            configurationGroup.id === EShiftSummaryGroups.COLUMN_GROUPS ||
            configurationGroup.id === EShiftSummaryGroups.COLUMNS,
        };
      }),
    );
    const defaultHeaders: ITableHeader[] = _.cloneDeep(this.shiftSummaryTableDefaults);
    const selectedGroups: string[] | undefined = _.find(userConfiguration, { name: 'selectedGroups' })?.value?.map(
      (configuration: TabRowInterface) => configuration.name,
    );
    const selectedColumns: string[] | undefined = _.find(userConfiguration, { name: 'selectedColumns' })?.value?.map(
      (configuration: TabRowInterface) => configuration.name,
    );
    const columnGroupsConfiguration: IPageConfigurationGroup<EShiftSummaryGroups> = _.find(tableConfigurationGroups, {
      id: EShiftSummaryGroups.COLUMN_GROUPS,
    });

    [
      ...selectedGroups?.filter((group: string) => group !== 'comments'),
      ...['generalInfo', 'phases', 'taskGroups', 'phaseLaborHours', 'shiftOverview'].filter(
        (headerGroup: string) => !selectedGroups.includes(headerGroup),
      ),
    ].forEach((groupName: string) => {
      columnGroupsConfiguration.values.push(_.find(defaultHeaders, { value: groupName }));
    });
    columnGroupsConfiguration.values.push(_.find(defaultHeaders, { value: 'comments' }));

    columnGroupsConfiguration.values = columnGroupsConfiguration.values.map((header: ITableHeader) => {
      this.setPageConfigurationTranslations(header);

      return {
        ...header,
        selected: selectedGroups.includes(header.value),
      };
    });

    defaultHeaders.forEach((column: ITableHeader) => {
      this.setPageConfigurationTranslations(column);

      if (column.parentId === EShiftSummaryGroups.COLUMN_GROUPS) {
        return;
      }

      const group: IPageConfigurationGroup<EShiftSummaryGroups> | undefined = _.find(tableConfigurationGroups, {
        id: column.parentId,
      });
      const isColumnSelected: boolean = selectedColumns.includes(column.value);

      group.values.push({ ...column, selected: isColumnSelected });
    });

    selectedGroups.forEach((groupName: string) => {
      const group: IPageConfigurationGroup<EShiftSummaryGroups> | undefined = _.find(tableConfigurationGroups, {
        id: groupName,
      });

      if (group) {
        group.isVisible = true;
      }
    });

    return tableConfigurationGroups;
  }

  public setPageConfigurationTranslations(column: ITableHeader): void {
    switch (column.value) {
      case 'lineName':
        column.name = `${this.translate.instant('shiftSummary.table.headers.lineName')} (${this.forRegularViewLabel})`;
        break;
      case 'workOrder':
        column.name = `${this.translate.instant('shiftSummary.table.headers.workOrder')} (${this.translate.instant(
          'shiftSummary.table.headers.forLineBasedView',
        )})`;
        break;
      case 'erp':
        column.name = `${this.translate.instant('shiftSummary.table.erp')} (${this.forRegularViewLabel})`;
        break;
      case 'descriptiveProductId':
        column.name = `${this.translate.instant('shiftSummary.table.headers.productId')} (${this.forRegularViewLabel})`;
        break;
      case 'processOrder':
        column.name = `${this.translate.instant('shiftSummary.table.headers.processOrder')} (${
          this.forRegularViewLabel
        })`;
        break;
      case 'operationNumber':
        column.name = `${this.translate.instant('shiftSummary.table.headers.operationNumber')} (${
          this.forRegularViewLabel
        })`;
        break;
      case EShiftSummaryGroups.SHIFT_OVERVIEW:
        column.name = `${this.translate.instant(
          'shiftSummary.table.headerGroups.shiftOverview',
        )} (${this.translate.instant('shiftSummary.table.headers.forLineBasedView')})`;
        break;
    }
  }

  public setPhaseNames(headers: ITableHeader[], phaseNames: ColumnNames): void {
    const preRunPhaseName: string = phaseNames.preRunColumnName?.trim()
      ? phaseNames.preRunColumnName
      : this.preRunLabel;
    const runPhaseName: string = phaseNames.runColumnName?.trim() ? phaseNames.runColumnName : this.runLabel;
    const postRunPhaseName: string = phaseNames.postRunColumnName?.trim()
      ? phaseNames.postRunColumnName
      : this.postRunLabel;
    const preRunPhaseDuration: ITableHeader | undefined = _.find(headers, { value: 'preRunPhaseDuration' });
    const runPhaseDuration: ITableHeader | undefined = _.find(headers, { value: 'runPhaseDuration' });
    const postRunPhaseDuration: ITableHeader | undefined = _.find(headers, { value: 'postRunPhaseDuration' });
    const preRunCheckInDuration: ITableHeader | undefined = _.find(headers, { value: 'preRunCheckInDuration' });
    const runCheckInDuration: ITableHeader | undefined = _.find(headers, { value: 'runCheckInDuration' });
    const postRunCheckInDuration: ITableHeader | undefined = _.find(headers, { value: 'postRunCheckInDuration' });

    if (preRunPhaseName?.trim()) {
      if (preRunPhaseDuration) {
        preRunPhaseDuration.name = preRunPhaseName;
      }

      if (preRunCheckInDuration) {
        preRunCheckInDuration.name = preRunPhaseName;
      }
    }

    if (runPhaseName?.trim()) {
      if (runPhaseDuration) {
        runPhaseDuration.name = runPhaseName;
      }

      if (runCheckInDuration) {
        runCheckInDuration.name = runPhaseName;
      }
    }

    if (postRunPhaseName?.trim()) {
      if (postRunPhaseDuration) {
        postRunPhaseDuration.name = postRunPhaseName;
      }

      if (postRunCheckInDuration) {
        postRunCheckInDuration.name = postRunPhaseName;
      }
    }
  }
}
