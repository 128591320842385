import { FilterProductFamilyActions, FilterProductFamilyActionTypes } from './product-family.actions';
import { ProductCRUDInterface } from '../../../shared/component/filter/filter.class';

export interface FilterProductFamilyState {
  isLoading: boolean;
  isLoaded: boolean;
  data: ProductCRUDInterface[];
}

export const initialFilterProductFamilyState: FilterProductFamilyState = {
  isLoaded: false,
  isLoading: false,
  data: [],
};

export function filterProductFamilyReducer(
  state = initialFilterProductFamilyState,
  action: FilterProductFamilyActions,
): FilterProductFamilyState {
  switch (action.type) {
    case FilterProductFamilyActionTypes.StartLoadFilterProductFamily:
      return { ...state, isLoading: true };

    case FilterProductFamilyActionTypes.FilterProductFamilyLoaded:
      return { ...state, data: action.data, isLoaded: true, isLoading: false };

    case FilterProductFamilyActionTypes.ResetProductFamilyStore:
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
        data: [],
      };
    default:
      return state;
  }
}
