import { IActivityLog } from '../root-cause-analysis/root-cause-analysis.model';
import { WorkOrderScheduleInterface } from '../../work-order-schedule/work-order-schedule.model';
import { IObjectWithEntityTranslations } from 'src/app/shared/service/entity-translator/entity-translator.model';

export interface IWorkOrderPerformanceState {
  isWorkOrderInformationLoading: boolean;
  isWorkOrderInformationLoaded: boolean;
  isWorkOrderActivityLogsLoading: boolean;
  isWorkOrderActivityLogsLoaded: boolean;
  isWorkOrderOeeResultLoading: boolean;
  isWorkOrderOeeResultLoaded: boolean;
  isWorkOrderPhaseInformationLoading: boolean;
  isWorkOrderPhaseInformationLoaded: boolean;
  isWorkOrderLaborInformationLoading: boolean;
  isWorkOrderLaborInformationLoaded: boolean;
  isProductExpectedSpeedLoading: boolean;
  isProductExpectedSpeedLoaded: boolean;
  isCommentsLoading: boolean;
  isCommentsLoaded: boolean;
  workOrderInformation: WorkOrderScheduleInterface;
  activityLog: IActivityLog[];
  oeeResult: IOeeCalculationResult;
  phaseInformation: IWorkOrderPhaseInformation;
  laborInformation: IWorkOrderLaborAssetInformation[];
  productExpectedSpeedInformation: IExpectedSpeed;
  workOrderComments: IWorkOrderComment[];
}

export interface IWorkOrderPerformanceFilter {
  workOrderId: number[];
}

export interface IOeeCalculationResult {
  intervalStartDate: string;
  intervalEndDate: string;
  oeeStartDate: string;
  oeeEndDate: string;
  oeeStartShiftDay: string;
  oeeEndShiftDay: string;
  intervalName: string;
  totalRunTimeDuration: number;
  totalRunTimeCount: number;
  totalDownTimeDuration: number;
  totalDownTimeCount: number;
  totalIdleTimeDuration: number;
  totalIdleTimeCount: number;
  potentialQuantityBasedOnLineSpeedxActualRunTime: number;
  potentialQuantityBasedOnLineSpeedxActualDownTime: number;
  potentialQuantityBasedOnLineSpeedxActualIdleTime: number;
  potentialQuantityBasedOnProductSpeedxActualRunTime: number;
  totalDuration: number;
  totalGoodCount: string;
  totalInitialCount: string;
  totalGoodCountEffectiveDuration: number;
  totalInitialCountEffectiveDuration: number;
  goodQuantity: number;
  goodQuantityFromSensorTable: number;
  totalQuantityFromSensorTable: number;
  runTimeDurationxLineSpeed: number;
  downTimeDurationxLineSpeed: number;
  idleTimeDurationxLineSpeed: number;
  oeeFormula: string;
  availabilityPerInterval: string;
  performancePerInterval: string;
  qualityPerInterval: string;
  overallOEE: string;
  potentialQuantityBasedOnLineSpeedxLineSettingScheduleHours: number;
  totalScrapCount: string;
}

export interface IWorkOrderPhaseInformation {
  preRunDuration: number;
  runDuration: number;
  postRunDuration: number;
  preRunVariance: string;
  runVariance: string;
  postRunVariance: string;
}

export interface IWorkOrderLaborAssetInformation extends IObjectWithEntityTranslations {
  sourceTypeId: number;
  sourceObjectId: number;
  sourceObjectName: string;
  siteName: string;
  lineName: string;
  stationName: string;
  duration: string;
}

export interface IWorkOrderLaborAssetTableInformation {
  startDate?: string;
  endDate?: string;
  sourceTypeId: number;
  sourceObjectId: number;
  sourceObjectName: string;
  siteName: string;
  lineName: string;
  stationName: string;
  duration: string;
  lineId?: string;
  isOngoing?: any;
}

export interface IOeeCalculationRequest {
  sites?: number[];
  lines?: number[];
  shifts?: number[];
  workOrderId?: number;
  startDate?: string;
  endDate?: string;
  sqlMode?: ESqlMode;
  oeeType?: EOEEType;
  useReplica?: boolean;
}

export interface IExpectedSpeed {
  expectedSpeed: string;
}

export interface IExpectedSpeedFilter {
  lineIds: number[];
  productId: number;
}

export interface IWorkOrderComment {
  changedAt: string;
  changedBy: string;
  commentDate: string;
  commentMessage: string;
  commentTypeId: number;
  createdAt: string;
  createdBy: number;
  id: number;
  objectId: string;
  objectProperty: string;
  objectType: string;
  shiftDay: string;
  shiftId: number;
  siteId: number;
  workOrderId: string;
}

export enum CheckInSourceTypeNumberEnum {
  SOURCE_TYPE_USER = 1,
  SOURCE_TYPE_EQUIPMENT = 2,
}

export enum ESqlMode {
  MINUS_ONE = '-1',
  BATCH = 'BATCH',
  HOUR = 'HOUR',
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
  INTERVAL = 'INTERVAL',
}

export enum EOEEType {
  MINUS_ONE = '-1',
  A = 'a',
  AP = 'ap',
  APQ = 'apq',
}

export enum ETableType {
  LABOR = 'labor',
  ASSET = 'asset',
}
