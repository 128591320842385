import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { from, of, Subject } from 'rxjs';
import * as ObjectActions from './equipment.actions';
import { generateHttpOption } from '../../../shared/helper/app-helper';
import { EquipmentListService } from '../../../shared/service/equipment-list/equipment-list.service';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { IEquipmentList } from '../../../shared/component/filter/filter.class';
import * as _ from 'lodash';
import { EntityTranslatorService } from 'src/app/shared/service/entity-translator/entity-translator.service';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../oee.reducer';
import { User } from '../../user/model';
import { HelperService } from '../../../shared/service/helper.service';

@Injectable()
export class FilterEquipmentEffects {
  private userLanguage$: string = '';
  private readonly destroySubject: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly actions$: Actions<ObjectActions.FilterEquipmentActions>,
    public equipmentService: EquipmentListService,
    private entityTranslator: EntityTranslatorService,
    private store: Store<OeeAppState>,
  ) {}

  loadFilterSites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.FilterEquipmentActionTypes.StartLoadFilterEquipments),
      switchMap((objectDataReadOnly: ObjectActions.StartLoadFilterEquipments) => {
        const objectData = HelperService.cloneDeep(objectDataReadOnly);
        this.store
          .select('user')
          .pipe(takeUntil(this.destroySubject))
          .subscribe((state: User) => {
            if (state.isUserLoaded) {
              this.userLanguage$ = state.language;
            }
            this.destroySubject.next(true);
            this.destroySubject.complete();
          });

        const searchObject: object[] = objectData.options.s ? JSON.parse(objectData.options.s)?.$and : [];
        const equipmentFilter: object = _.find(searchObject, 'equipmentName');
        const searchText: string = _.get(equipmentFilter, 'equipmentName.$cont');

        if (equipmentFilter) {
          const equipmentIndex: number = _.indexOf(searchObject, equipmentFilter);

          searchObject.splice(equipmentIndex, 1);
          searchObject.push({
            $or: [
              { equipmentName: { $cont: searchText } },
              {
                $and: [
                  { 'entityTranslations.language': { $eq: this.userLanguage$ } },
                  { 'entityTranslations.value': { $cont: searchText } },
                ],
              },
            ],
          });
        }

        const lineFilter: object = _.find(searchObject, 'lineId');

        if (lineFilter) {
          const lineIndex: number = _.indexOf(searchObject, lineFilter);

          searchObject.splice(lineIndex, 1);
          searchObject.push({ 'equipmentAssignment.lineId': _.first(Object.values(lineFilter)) });
        }

        objectData.options.s = JSON.stringify({ $and: searchObject });

        const params = generateHttpOption(objectData.options)
          .append('join', 'site||name')
          .append('join', 'equipmentBrandRelation||name')
          .append('join', 'equipmentTypeRelation||description')
          .append('join', 'equipmentAssignment||lineId');

        return from(this.equipmentService.getEquipmentLists(params)).pipe(
          map((response: GetManyResponseInterface<IEquipmentList>): IEquipmentList[] => {
            response.data.forEach((equipment: IEquipmentList) => this.entityTranslator.translate(equipment));
            return response.data.map((data: IEquipmentList): IEquipmentList => {
              return {
                ...data,
                lineId: String(data.equipmentAssignment[0]?.lineId),
              };
            });
          }),
          switchMap((data: IEquipmentList[]) => {
            return of(new ObjectActions.FilterEquipmentsLoaded(data));
          }),
          catchError(() => {
            return of(new ObjectActions.FilterEquipmentsLoaded([]));
          }),
        );
      }),
      catchError(() => {
        return of(new ObjectActions.FilterEquipmentsLoaded([]));
      }),
    ),
  );
}
