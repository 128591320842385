import { FilterUserActions, FilterUserActionTypes } from './user.actions';
import { UsersCRUDInterface } from '../../../shared/component/filter/filter.class';

export interface FilterUserState {
  isLoading: boolean;
  isLoaded: boolean;
  data: UsersCRUDInterface[];
}

export const initialFilterUserState: FilterUserState = {
  isLoaded: false,
  isLoading: false,
  data: [],
};

export function filterUserReducer(
  state = initialFilterUserState,
  action: FilterUserActions,
): FilterUserState {
  switch (action.type) {
    case FilterUserActionTypes.StartLoadFilterUsers:
      return { ...state, isLoading: true };

    case FilterUserActionTypes.FilterUsersLoaded:
      return { ...state, data: action.data, isLoaded: true, isLoading: false };

    case FilterUserActionTypes.ResetUserStore:
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
        data: [],
      };
    default:
      return state;
  }
}
