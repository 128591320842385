import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {
  NotificationGroupInterface,
  NotificationGroupTableQueryParams,
  NotificationRecipientBulkDeleteManyInterface,
  NotificationRecipientBulkSaveManyInterface,
} from './notification-groups.model';
import { Inject, Injectable } from '@angular/core';
import {
  BaseCrudResponse,
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../../shared/model/interface/crud-response-interface.model';
import { forkJoin, Observable, of } from 'rxjs';
import { HelperService } from '../../../../shared/service/helper.service';
import * as ObjectActions from './notification-groups.actions';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NotificationGroupsService {
  private readonly NOTIFICATION_GROUP = {
    SITE_URL: `${this.baseUrl}/sites`,
    NOTIFICATION_GROUP_URL: `${this.baseUrl}/notifications/mail-groups`,
    NOTIFICATION_GROUPS_RECIPIENT_URL: `${this.baseUrl}/notifications/mail-recipients`,
    NOTIFICATION_GROUP_BULK_DELETE: `${this.baseUrl}/notifications/mail-groups/bulk/delete`,
  };

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    public helperService: HelperService,
  ) {}

  public getNotificationGroups(params: HttpParams): Observable<GetManyResponseInterface<NotificationGroupInterface>> {
    return this.http.get<GetManyResponseInterface<NotificationGroupInterface>>(
      this.NOTIFICATION_GROUP.NOTIFICATION_GROUP_URL,
      {
        params,
      },
    );
  }

  public deleteNotificationGroups(
    notificationGroup: number[] | number,
  ): Observable<BaseCrudResponse | BulkResponseDataInterface> {
    if (Array.isArray(notificationGroup) && notificationGroup.length > 1) {
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        body: {
          mailGroups: notificationGroup,
        },
      };
      return this.http.delete<BulkResponseDataInterface>(
        `${this.NOTIFICATION_GROUP.NOTIFICATION_GROUP_BULK_DELETE}`,
        httpOptions,
      );
    }
    return this.http.delete<BaseCrudResponse>(
      `${this.NOTIFICATION_GROUP.NOTIFICATION_GROUP_URL}/${notificationGroup[0]}`,
    );
  }

  public addNotificationGroup(
    notificationGroup: Partial<NotificationGroupInterface>,
  ): Observable<BaseOneResponseInterface<NotificationGroupInterface>> {
    return this.http.post<BaseOneResponseInterface<NotificationGroupInterface>>(
      `${this.NOTIFICATION_GROUP.NOTIFICATION_GROUP_URL}`,
      notificationGroup,
    );
  }

  public editNotificationGroup(
    notificationGroup: Partial<NotificationGroupInterface>,
    notificationGroupId: number,
  ): Observable<BaseOneResponseInterface<NotificationGroupInterface>> {
    return this.http.patch<BaseOneResponseInterface<NotificationGroupInterface>>(
      `${this.NOTIFICATION_GROUP.NOTIFICATION_GROUP_URL}/${notificationGroupId}`,
      notificationGroup,
    );
  }

  public saveNotificationGroupsRecipients(
    notificationGroupRecipients: NotificationRecipientBulkSaveManyInterface,
  ): Observable<BulkResponseDataInterface> {
    return this.http.post<BulkResponseDataInterface>(
      `${this.NOTIFICATION_GROUP.NOTIFICATION_GROUPS_RECIPIENT_URL}/bulk/save`,
      notificationGroupRecipients,
    );
  }

  public getHttpOptions(params: NotificationGroupTableQueryParams): HttpParams {
    return this.helperService
      .insertGenericCrudRequestParameters(params)
      .append('join', 'site||name')
      .append(
        'join',
        'notificationMailRecipient||mailGroupId,recipientMailAddress,recipientName,recipientPhoneNumber,userId',
      )
      .append('join', 'notificationMailRecipient.user');
  }

  public deleteNotificationGroupsRecipients(
    data: NotificationRecipientBulkDeleteManyInterface,
  ): Observable<BulkResponseDataInterface> {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: data,
    };
    return this.http.delete<BulkResponseDataInterface>(
      `${this.NOTIFICATION_GROUP.NOTIFICATION_GROUPS_RECIPIENT_URL}/bulk/delete`,
      httpOptions,
    );
  }

  public saveAndDeleteNotificationGroupsRecipients(
    objectData: ObjectActions.SaveNotificationGroupsRecipients,
  ): Observable<{ deleteMany: BulkResponseDataInterface; saveMany: BulkResponseDataInterface }> {
    const sourcesObject = {
      saveMany: objectData.saveMany?.mailRecipients?.length
        ? this.saveNotificationGroupsRecipients(objectData.saveMany)
        : of({ data: [] } as BulkResponseDataInterface),
      deleteMany: objectData.deleteMany?.mailRecipients?.length
        ? this.deleteNotificationGroupsRecipients(objectData.deleteMany)
        : of({ data: [] } as BulkResponseDataInterface),
    };

    return forkJoin(sourcesObject).pipe(
      map((resolvedObservables) => {
        return resolvedObservables;
      }),
    );
  }
}
