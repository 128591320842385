import {
  FETCH_ERROR,
  PRODUCT_SPEED_ANALYSIS_DATA_LOADED,
  PRODUCT_SPEED_ANALYSIS_DATA_LOADING,
  PRODUCT_SPEED_ANALYSIS_RESET,
  ProductSpeedAnalysisActions,
} from './product-speed-analysis.actions';
import { ProductSpeedAnalysisStateInterface } from './product-speed-analysis.model';

export const productSpeedAnalysisInitialState: ProductSpeedAnalysisStateInterface = {
  errors: [],
  productSpeedAnalysisDataLoading: false,
  productSpeedAnalysisDataLoaded: false,
  productSpeedAnalysisData: [],
};

export function productSpeedAnalysisReducer(
  state = productSpeedAnalysisInitialState,
  action: ProductSpeedAnalysisActions,
): ProductSpeedAnalysisStateInterface {
  switch (action.type) {

    case PRODUCT_SPEED_ANALYSIS_DATA_LOADING:
      return {
        ...state,
        productSpeedAnalysisDataLoading: true,
        productSpeedAnalysisDataLoaded: false,
      };

    case PRODUCT_SPEED_ANALYSIS_DATA_LOADED:
      return {
        ...state,
        productSpeedAnalysisDataLoading: false,
        productSpeedAnalysisDataLoaded: true,
        productSpeedAnalysisData: action.payload,
      };

    case PRODUCT_SPEED_ANALYSIS_RESET:
      return {
        ...state,
        productSpeedAnalysisDataLoading: false,
        productSpeedAnalysisDataLoaded: false,
        productSpeedAnalysisData: [],
      };

    case FETCH_ERROR:
      return {
        ...state,
        errors: action.payload,
      };

    default:
      return state;
  }
}
