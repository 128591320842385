import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ObjectActions from './tags.actions';
import { catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as AppActions from '../../app/actions';
import { TagsService } from './tags.service';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import { TagCRUDRequestInterface, TagFormDropdownData, TagInterface } from './tags.model';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';

@Injectable()
export class TagsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: TagsService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
  ) {}

  $getTags = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.TagsActionTypes.LoadTags),
      switchMap((objectData: ObjectActions.LoadTags) => {
        return this.service.getTags(objectData.parameters).pipe(
          switchMap((response: GetManyResponseInterface<TagInterface>) => {
            return of(new ObjectActions.TagsLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes));
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes));
      }),
    ),
  );

  $loadFormDropdownData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.TagsActionTypes.LoadFormDropdownData),
      switchMap(() => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.loadFormData().pipe(
          switchMap((response: TagFormDropdownData) => {
            return of(new ObjectActions.FormDropdownDataLoaded(response), new AppActions.HideLoader());
          }),
          catchError(() => {
            return of(new AppActions.HideLoader());
          }),
        );
      }),
      catchError(() => {
        return of(new AppActions.HideLoader());
      }),
    ),
  );

  $createTag = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.TagsActionTypes.CreateTag),
      switchMap((objectData: ObjectActions.CreateTag) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.createTag(objectData.requestParameters).pipe(
          switchMap((response: BaseOneResponseInterface<TagCRUDRequestInterface>) => {
            return of(new ObjectActions.CreateTagCompleted(response), new AppActions.HideLoader());
          }),
          catchError(() => {
            return of(new AppActions.HideLoader());
          }),
        );
      }),
      catchError(() => {
        return of(new AppActions.HideLoader());
      }),
    ),
  );

  $updateTag = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.TagsActionTypes.UpdateTag),
      switchMap((objectData: ObjectActions.UpdateTag) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.updateTag(objectData.id, objectData.requestParameters).pipe(
          switchMap((response: BaseOneResponseInterface<TagCRUDRequestInterface>) => {
            return of(new ObjectActions.UpdateTagCompleted(response), new AppActions.HideLoader());
          }),
          catchError(() => {
            return of(new AppActions.HideLoader());
          }),
        );
      }),
      catchError(() => {
        return of(new AppActions.HideLoader());
      }),
    ),
  );

  $deleteTag = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.TagsActionTypes.DeleteTag),
      switchMap((objectData: ObjectActions.DeleteTag) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.deleteTag(objectData.id).pipe(
          switchMap((response: GetManyResponseInterface<TagInterface>) => {
            return of(new ObjectActions.DeleteTagCompleted(response), new AppActions.HideLoader());
          }),
          catchError(() => {
            return of(new AppActions.HideLoader());
          }),
        );
      }),
      catchError(() => {
        return of(new AppActions.HideLoader());
      }),
    ),
  );

  $bulkDeleteTag = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.TagsActionTypes.BulkDeleteTag),
      switchMap((objectData: ObjectActions.BulkDeleteTag) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.bulkDeleteTag(objectData.ids).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            return of(new ObjectActions.BulkDeleteTagCompleted(response), new AppActions.HideLoader());
          }),
          catchError(() => {
            return of(new AppActions.HideLoader());
          }),
        );
      }),
      catchError(() => {
        return of(new AppActions.HideLoader());
      }),
    ),
  );
}
