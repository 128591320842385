import { Inject, Injectable } from '@angular/core';
import {
  IRequestBody,
  ITaskPerformanceActivityHistory,
  ITaskPerformanceLine,
  ITaskPerformanceRequestParams,
  ITaskPerformanceResponse,
  ITaskPerformanceShift,
  ITaskPerformanceSite,
} from './task-performance.model';
import { forkJoin, Observable, Subject } from 'rxjs';
import {
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, takeUntil, tap } from 'rxjs/operators';
import {
  CreateExcelSheetInterface,
  ExcelColumnDefinitionInterface,
  ExcelDateFormatInformationInterface,
  ExcelHelperService,
  ExcelSheetTypeEnum,
} from '../../../shared/service/excel/excel-helper.service';
import { excelDateFormat, excelTimeFormat } from '../../../shared/model/enum/excel-date-format';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as oeeAppReducer from '../../oee.reducer';
import * as moment from 'moment-timezone';
import * as AppActions from '../../app/actions';
import * as ObjectActions from './task-performance.actions';
import { ITaskPerformanceTableData } from '../../../view/reports/task-performance/task-performance.model';

@Injectable({
  providedIn: 'root',
})
export class TaskPerformanceService {
  private readonly routes = {
    lines: `${this.baseUrl}/lines`,
    activityHistories: `${this.baseUrl}/activity-histories`,
    currentShifts: `${this.baseUrl}/lines/current-shifts`,
    sites: `${this.baseUrl}/sites`,
  };
  private readonly destroySubject: Subject<boolean> = new Subject<boolean>();
  private dateFormatInformation: ExcelDateFormatInformationInterface;

  constructor(
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    public http: HttpClient,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly excelHelper: ExcelHelperService,
    private readonly translate: TranslateService,
  ) {
    let timezone: string = 'utc';
    let dateFormat$: string;
    let timeFormat$: string;

    this.store
      .select('user')
      .pipe(takeUntil(this.destroySubject))
      .subscribe((state) => {
        if (state.isUserLoaded) {
          timezone = state.timezone;

          if (state.locale !== '') {
            dateFormat$ = excelDateFormat[state.locale];
            timeFormat$ = excelTimeFormat[state.locale];
          }

          this.dateFormatInformation = {
            timezone,
            dateFormat$,
            timeFormat$,
          };

          this.destroySubject.next(true);
          this.destroySubject.complete();
        }
      });
  }

  public getTaskPerformanceData(
    taskPerformanceParams: ITaskPerformanceRequestParams,
    activityHistoryTotalCount: number,
  ): Observable<ITaskPerformanceResponse> {
    const observables: Observable<
      | GetManyResponseInterface<ITaskPerformanceLine>
      | GetManyResponseInterface<ITaskPerformanceActivityHistory>
      | GetManyResponseInterface<ITaskPerformanceShift>
      | BaseOneResponseInterface<ITaskPerformanceSite>
    >[] = [
      this.http.post<GetManyResponseInterface<ITaskPerformanceLine>>(
        this.routes.lines,
        taskPerformanceParams.lineParams,
        {
          headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
        },
      ),
      this.http.get<GetManyResponseInterface<ITaskPerformanceShift>>(this.routes.currentShifts, {
        params: taskPerformanceParams.shiftParams,
      }),
      this.http.get<BaseOneResponseInterface<ITaskPerformanceSite>>(
        `${this.routes.sites}/${taskPerformanceParams.siteId}/`,
        {
          params: taskPerformanceParams.siteParams,
        },
      ),
      ...this.getActivityHistoryObservables(taskPerformanceParams.activityHistoryParams, activityHistoryTotalCount),
    ];
    let fetchPartCount: number = 0;
    const totalPartCount: number = observables.length;

    return forkJoin(
      observables.map(
        (
          o: Observable<
            | GetManyResponseInterface<ITaskPerformanceLine>
            | GetManyResponseInterface<ITaskPerformanceActivityHistory>
            | GetManyResponseInterface<ITaskPerformanceShift>
            | BaseOneResponseInterface<ITaskPerformanceSite>
          >,
        ) =>
          o.pipe(
            tap(() => {
              fetchPartCount += 1;
              const value: number = Math.ceil((fetchPartCount / totalPartCount) * 100);

              this.store.dispatch(new AppActions.ShowLoader(`${value}%`));
            }),
          ),
      ),
    ).pipe(
      map(
        (resolvedObservables): ITaskPerformanceResponse => {
          const [linesResponse, shiftResponse, siteResponse] = resolvedObservables;
          let activityHistoryResponse: ITaskPerformanceActivityHistory[] = [];

          resolvedObservables.forEach((value, index) => {
            if (index > 2) {
              activityHistoryResponse = activityHistoryResponse.concat(value.data as ITaskPerformanceActivityHistory[]);
            }
          });

          return {
            lines: (linesResponse as GetManyResponseInterface<ITaskPerformanceLine>).data,
            activityHistories: activityHistoryResponse,
            shifts: (shiftResponse as GetManyResponseInterface<ITaskPerformanceShift>).data,
            site: (siteResponse as BaseOneResponseInterface<ITaskPerformanceSite>).data,
          };
        },
      ),
    );
  }

  public downloadExcel(
    worksheetsColumnDefinitions: ExcelColumnDefinitionInterface[][],
    data: ITaskPerformanceTableData[],
  ): void {
    const sheetTitle: string | any = this.translate.instant('pageTitles.task_performance_report');
    const excelName: string = `${sheetTitle} ${moment()
      .tz(this.dateFormatInformation.timezone)
      .format(this.dateFormatInformation.dateFormat$)}`;

    const worksheets: CreateExcelSheetInterface[] = [
      {
        sheetTitle: this.translate.instant('activityLogs.excel.readme.worksheetName'),
        sheetType: ExcelSheetTypeEnum.README,
      },
      {
        sheetTitle: this.translate.instant('activityLogs.excel.readme.templateFormatTitle'),
        sheetType: ExcelSheetTypeEnum.TABLE,
        params: {
          data,
          columns: worksheetsColumnDefinitions[0],
        },
        withData: true,
        isDisabledColumnsFirstLine: true,
        addDateTimeFormula: undefined,
        excelRowFormatLimit: data.length + 1,
      },
      {
        sheetTitle: this.translate.instant('activityLogs.excel.readme.dataAnalysisFormatTitle'),
        sheetType: ExcelSheetTypeEnum.TABLE,
        params: {
          data,
          columns: worksheetsColumnDefinitions[1],
        },
        withData: true,
        isDisabledColumnsFirstLine: true,
        addDateTimeFormula: undefined,
        excelRowFormatLimit: data.length + 1,
      },
    ];

    this.excelHelper
      .createExcel(
        excelName,
        worksheets,
        this.dateFormatInformation.timezone,
        this.dateFormatInformation.dateFormat$,
        this.dateFormatInformation.timeFormat$,
        false,
      )
      .then(
        () => {
          this.store.dispatch(new ObjectActions.TaskPerformanceDownloadExcelCompleted());
          this.store.dispatch(new AppActions.HideLoader());
        },
        () => {
          this.store.dispatch(new ObjectActions.FetchDataError({}));
          this.store.dispatch(new AppActions.HideLoader());
        },
      );
  }

  public getActivityHistoryData(
    params: IRequestBody,
  ): Observable<GetManyResponseInterface<ITaskPerformanceActivityHistory>> {
    return this.http.post<GetManyResponseInterface<ITaskPerformanceActivityHistory>>(
      this.routes.activityHistories,
      params,
      {
        headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
      },
    );
  }

  private getActivityHistoryObservables(
    params: IRequestBody,
    totalCount: number,
  ): Observable<GetManyResponseInterface<ITaskPerformanceActivityHistory>>[] {
    const partCount: number = 5000;
    const observables: Observable<GetManyResponseInterface<ITaskPerformanceActivityHistory>>[] = [];
    const observablesCountOfActivityHistory: number = Math.ceil(totalCount / partCount);

    Array.from(Array(observablesCountOfActivityHistory)).map((value, index) => {
      params.page = index + 1;
      params.offset = partCount;

      observables.push(this.getActivityHistoryData(params));
    });

    return observables;
  }
}
