import { TasksStateInterface } from './tasks.model';
import * as Actions from './tasks.actions';

export const tasksState: TasksStateInterface = {
  lineDataLoading: false,
  lineDataLoaded: false,
  activityDataLoading: false,
  activityDataLoaded: false,
  equipmentDataLoading: false,
  equipmentDataLoaded: false,
  rootCauseGroupDataLoading: false,
  rootCauseGroupDataLoaded: false,
  taskDataForDataTableLoaded: false,
  taskDataForDataTableLoading: false,
  siteDataLoading: false,
  siteDataLoaded: false,
  siteData: [],
  lineData: null,
  equipmentData: null,
  rootCauseGroupData: null,
  activityData: null,
  taskDataForDataTable: null,
  fileDownloadStatus: false,
  excelUploadResult: [],
  isUploadExcelLoading: false,
  isUploadExcelLoaded: false,
  tasksDeleteLoading: false,
  tasksDeleteLoaded: false,
  tasksEditLoading: false,
  tasksEditLoaded: false,
  tasksBulkEditLoading: false,
  tasksBulkEditLoaded: false,
  tableSettings: [],
  filters: null,
};

export function taskReducer(
  state: TasksStateInterface = tasksState,
  action: Actions.TasksActions,
): TasksStateInterface {
  switch (action.type) {
    case Actions.TASK_RANGE_DATA_LOADING:
      return {
        ...state,
        ...{
          taskDataLoading: true,
          taskDataLoaded: false,
        },
      };
    case Actions.TASK_RANGE_DATA_LOADED:
      return {
        ...state,
        ...{
          taskData: action.payload,
          taskDataLoading: false,
          taskDataLoaded: true,
        },
      };
    case Actions.TASK_DATA_FOR_DATATABLE_LOADING:
      return {
        ...state,
        ...{
          taskDataForDataTableLoading: true,
          taskDataForDataTableLoaded: false,
        },
      };
    case Actions.TASK_DATA_FOR_DATATABLE_LOADED:
      return {
        ...state,
        ...{
          taskDataForDataTable: action.payload,
          taskDataForDataTableLoading: false,
          taskDataForDataTableLoaded: true,
        },
      };
    case Actions.SITES_DATA_LOADING:
      return {
        ...state,
        ...{
          siteDataLoading: true,
          siteDataLoaded: false,
        },
      };
    case Actions.SITES_DATA_LOADED:
      return {
        ...state,
        ...{
          siteData: action.payload,
          siteDataLoading: false,
          siteDataLoaded: true,
        },
      };
    case Actions.GET_LINES:
      return {
        ...state,
        ...{
          lineDataLoading: true,
          lineDataLoaded: false,
        },
      };
    case Actions.GET_LINES_COMPLETED:
      return {
        ...state,
        ...{
          lineData: action.payload,
          lineDataLoading: false,
          lineDataLoaded: true,
        },
      };
    case Actions.GET_ACTIVITIES:
      return {
        ...state,
        ...{
          activityDataLoading: true,
          activityDataLoaded: false,
        },
      };
    case Actions.GET_ACTIVITIES_COMPLETED:
      return {
        ...state,
        ...{
          activityData: action.payload,
          activityDataLoading: false,
          activityDataLoaded: true,
        },
      };
    case Actions.GET_EQUIPMENTS:
      return {
        ...state,
        ...{
          equipmentDataLoading: true,
          equipmentDataLoaded: false,
        },
      };
    case Actions.GET_EQUIPMENTS_COMPLETED:
      return {
        ...state,
        ...{
          equipmentData: action.payload,
          equipmentDataLoading: false,
          equipmentDataLoaded: true,
        },
      };
    case Actions.GET_ROOT_CAUSE_GROUPS:
      return {
        ...state,
        ...{
          rootCauseGroupDataLoading: true,
          rootCauseGroupDataLoaded: false,
        },
      };
    case Actions.GET_ROOT_CAUSE_GROUPS_COMPLETED:
      return {
        ...state,
        ...{
          rootCauseGroupData: action.payload,
          rootCauseGroupDataLoading: false,
          rootCauseGroupDataLoaded: true,
        },
      };
    case Actions.DOWNLOAD_EXCEL:
      return {
        ...state,
        ...{
          fileDownloadStatus: true,
        },
      };
    case Actions.DOWNLOAD_EXCEL_COMPLETED:
      return {
        ...state,
        ...{
          fileDownloadStatus: false,
        },
      };
    case Actions.UPLOAD_EXCEL:
      return {
        ...state,
        ...{
          isUploadExcelLoading: true,
          isUploadExcelLoaded: false,
        },
      };
    case Actions.UPLOAD_EXCEL_COMPLETED:
      return {
        ...state,
        ...{
          excelUploadResult: action.payload,
          isUploadExcelLoading: false,
          isUploadExcelLoaded: true,
        },
      };
    case Actions.SET_TABLE_SETTINGS:
      return {
        ...state,
        tableSettings: action.payload,
      };
    case Actions.DELETE_TASKS:
      return {
        ...state,
        tasksDeleteLoading: true,
        tasksDeleteLoaded: false,
      };
    case Actions.DELETE_TASKS_COMPLETED:
      return {
        ...state,
        tasksDeleteLoading: false,
        tasksDeleteLoaded: true,
      };
    case Actions.EDIT_TASK:
      return {
        ...state,
        tasksEditLoading: true,
        tasksEditLoaded: false,
      };
    case Actions.EDIT_TASK_COMPLETED:
      return {
        ...state,
        tasksEditLoading: false,
        tasksEditLoaded: true,
      };
    case Actions.BULK_EDIT_TASK:
      return {
        ...state,
        tasksBulkEditLoading: true,
        tasksBulkEditLoaded: false,
      };
    case Actions.BULK_EDIT_TASK_COMPLETED:
      return {
        ...state,
        tasksBulkEditLoading: false,
        tasksBulkEditLoaded: true,
      };
    case Actions.CLEAR_TASK_STATE:
      return {
        ...state,
        lineDataLoaded: false,
        lineDataLoading: false,
        rootCauseGroupDataLoading: false,
        rootCauseGroupDataLoaded: false,
        equipmentDataLoading: false,
        equipmentDataLoaded: false,
        siteDataLoading: false,
        siteDataLoaded: false,
      };
    default:
      return state;
  }
}
