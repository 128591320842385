import { Action } from '@ngrx/store';
import { DropdownOptionsInterface } from '../../../view/settings/tasks/tasks.model';

export enum FilterStartOfDayActionTypes {
  StartLoadFilterStartOfDay = '[Filter Start Of Day] Start Load Filter Start Of Day',
  StartOfDaFilterLoaded = '[Filter Start Of Day] Filter Start Of Day Loaded',
}

export class StartLoadFilterStartOfDay implements Action {
  readonly type = FilterStartOfDayActionTypes.StartLoadFilterStartOfDay;
}

export class FilterStartOfDayLoaded implements Action {
  readonly type = FilterStartOfDayActionTypes.StartOfDaFilterLoaded;
  constructor(public data: DropdownOptionsInterface[]) {}
}

export type FilterStartOfDayActions = StartLoadFilterStartOfDay | FilterStartOfDayLoaded;
