import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import * as ObjectActions from './capacity.actions';
import { catchError, switchMap } from 'rxjs/operators';
import * as AppActions from '../../app/actions';
import { of } from 'rxjs';
import { CapacityService } from './capacity.service';
import { ICapacityResponseInterface } from './capacity.model';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { emptyAction } from '../../../../constants';

@Injectable()
export class CapacityEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: CapacityService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
  ) {}


  getCapacityData = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.CAPACITY_DATA_LOADING),
    switchMap((payload: ObjectActions.CapacityDataLoading) => {
      this.store.dispatch(new AppActions.ShowLoader());

      const queryParams = this.service.formatCapacityParams(payload.params);
      return this.service.fetchCapacityData(queryParams).pipe(
        switchMap((response: GetManyResponseInterface<ICapacityResponseInterface>) => {
          return of(new ObjectActions.CapacityDataLoaded(response), new AppActions.HideLoader());
        }),
        catchError((errorRes) => {
          return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
        }),
      );
    }),
    catchError((errorRes) => {
      return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
    }),
  ));


  downloadCapacityExcel = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.DOWNLOAD_CAPACITY_EXCEL),
    switchMap((objectData: ObjectActions.DownloadCapacityExcel) => {
      this.service.downloadCapacityExcel(objectData.withData, objectData.data);
      return emptyAction;
    }),
    catchError((errorRes) => {
      return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
    }),
  ));
}
