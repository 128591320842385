import { Inject, Injectable } from '@angular/core';
import { ISkillMatrixRequest, SkillMatrixRawDataInterface } from './skill-matrix.model';
import { Observable, Subject } from 'rxjs';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EStackSkillMatrixChartFilterDropdown } from '../../../view/reports/skill-matrix/skill-matrix-chart-filter/skill-matrix-chart-filter.model';

@Injectable({
  providedIn: 'root',
})
export class SkillMatrixService {
  private groupByFilterCurrentState: string = EStackSkillMatrixChartFilterDropdown.PRODUCT;
  public skillMatrixGroupByFilterCurrentState: Subject<string> = new Subject<string>();

  constructor(public http: HttpClient, @Inject('API_BASE_URL') private readonly api: string) {}

  private readonly routes = {
    skillMatrix: '/products-labors-assets-hours/skill-matrix',
  };

  public getData(params: ISkillMatrixRequest): Observable<GetManyResponseInterface<SkillMatrixRawDataInterface>> {
    return this.http.post<GetManyResponseInterface<SkillMatrixRawDataInterface>>(
      `${this.api}${this.routes.skillMatrix}`,
      params,
      {
        headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
      },
    );
  }

  public getGroupByState(): string {
    return this.groupByFilterCurrentState;
  }

  public setGroupByState(groupByState: string): void {
    if (!groupByState) {
      return;
    }

    this.groupByFilterCurrentState = groupByState;
    this.skillMatrixGroupByFilterCurrentState.next(groupByState);
  }
}
