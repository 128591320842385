import { Action } from '@ngrx/store';
import {
  AlertPauseGetResponseInterface,
  AlertPauseUpdateRequestInterface,
  AlertPauseUpdateResponseInterface,
  StartAlertPauseParametersInterface,
} from './alert-pause.model';

export enum AlertPauseActionTypes {
  StartGetAlertPauseDataLoading = '[ALERT PAUSE] START GET ALERT PAUSE DATA LOADING',
  StartGetAlertPauseDataLoaded = '[ALERT PAUSE] START GET ALERT PAUSE DATA LOADED',
  StartUpdateAlertPauseDataLoading = '[ALERT PAUSE] START UPDATE ALERT PAUSE DATA LOADING',
  StartUpdateAlertPauseDataLoaded = '[ALERT PAUSE] START UPDATE ALERT PAUSE DATA LOADED',
  FetchError = '[ALERT PAUSE] Fetch Error',
}

export class StartGetAlertPauseDataLoading implements Action {
  readonly type = AlertPauseActionTypes.StartGetAlertPauseDataLoading;

  constructor(public parameters: StartAlertPauseParametersInterface) {
  }
}

export class StartGetAlertPauseDataLoaded implements Action {
  readonly type = AlertPauseActionTypes.StartGetAlertPauseDataLoaded;

  constructor(public payload: AlertPauseGetResponseInterface) {
  }
}

export class StartUpdateAlertPauseDataLoading implements Action {
  readonly type = AlertPauseActionTypes.StartUpdateAlertPauseDataLoading;

  constructor(public parameters: AlertPauseUpdateRequestInterface, public lineId: number) {
  }
}

export class StartUpdateAlertPauseDataLoaded implements Action {
  readonly type = AlertPauseActionTypes.StartUpdateAlertPauseDataLoaded;

  constructor(public payload: AlertPauseUpdateResponseInterface) {
  }
}

export class FetchError implements Action {
  readonly type = AlertPauseActionTypes.FetchError;

  constructor(public payload: any) {
  }
}

export type AlertPauseActions =
  | StartGetAlertPauseDataLoading
  | StartGetAlertPauseDataLoaded
  | StartUpdateAlertPauseDataLoading
  | StartUpdateAlertPauseDataLoaded
  | FetchError;
