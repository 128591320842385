import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../store/oee.reducer';
import * as AppActions from '../../store/app/actions';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss'],
})
export class MaintenanceComponent implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription[] = [];

  constructor(private store: Store<OeeAppState>) {}

  ngOnInit(): void {
    const durationInMs = 1000 * 60 * 2;
    const interval = timer(durationInMs, durationInMs);

    this.subscriptions.push(
      interval.subscribe(() => {
        this.store.dispatch(new AppActions.GetMaintenanceModeData());
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription?.unsubscribe());
  }
}
