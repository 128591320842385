import { SensorReportsInterface } from './sensor-reports.model';
import * as Actions from './sensor-reports.actions';

export const sensorReportInitialState: SensorReportsInterface = {
  isSensorListLoading: false,
  isSensorListLoaded: false,
  isSensorReportsDataLoading: false,
  isSensorReportsDataLoaded: false,
  lineDataLoading: false,
  lineDataLoaded: false,
  sensorReportsData: [],
  sensorList: [],
  lineData: [],
};

export function sensorReportsReducer(
  state = sensorReportInitialState,
  action: Actions.SensorReportsActions,
): SensorReportsInterface {
  switch (action.type) {
    case Actions.START_SENSOR_LIST_LOADING:
      return {
        ...state,
        isSensorListLoading: true,
        isSensorListLoaded: false,
      };

    case Actions.START_SENSOR_LIST_LOADED:
      return {
        ...state,
        isSensorListLoading: false,
        isSensorListLoaded: true,
        sensorList: action.payload,
      };

    case Actions.GET_LINES:
      return {
        ...state,
        ...{
          lineDataLoading: true,
          lineDataLoaded: false,
        },
      };

    case Actions.GET_LINES_COMPLETED:
      return {
        ...state,
        ...{
          lineData: action.payload,
          lineDataLoading: false,
          lineDataLoaded: true,
        },
      };

    case Actions.START_SENSOR_REPORTS_DATA_LOADING:
      return {
        ...state,
        isSensorReportsDataLoading: true,
        isSensorReportsDataLoaded: false,
      };

    case Actions.START_SENSOR_REPORTS_DATA_LOADED:
      return {
        ...state,
        isSensorReportsDataLoading: false,
        isSensorReportsDataLoaded: true,
        sensorReportsData: action.payload,
      };

    case Actions.CLEAR_STATE:
      return {
        ...sensorReportInitialState,
      };

    case Actions.FETCH_ERROR:
      return {
        ...state,
        isSensorReportsDataLoading: false,
        isSensorReportsDataLoaded: true,
      };

    default:
      return state;
  }
}
