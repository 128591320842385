import { Action } from '@ngrx/store';
import {
  GroupedByTypes,
  CheckInLogCardDataInterface,
  CheckInLogTableResponseInterface,
  CheckInLogInterface,
} from './check-in-log.model';
import {
  BaseCrudResponse,
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { LineCRUDInterface } from '../../../shared/component/filter/filter.class';
import {
  CheckInLogFormRequestPayloadInterface,
  CheckInLogFormTypes,
  CheckInTableQueryParams,
} from '../../../view/reports/check-in-logs/check-in-logs.model';
import { TabRowInterface } from '../../../shared/component/side-config-bar/side-config-bar.model';

export const START_CHECK_IN_LOG_CARDS_DATA_LOADING = '[CHECK IN LOG] START CHECK IN LOG CARDS DATA LOADING';
export const CHECK_IN_LOG_CARDS_DATA_LOADED = '[CHECK IN LOG] CHECK IN LOG CARDS DATA LOADED';
export const START_CHECK_IN_LOG_TABLE_DATA_LOADING = '[CHECK IN LOG] START CHECK IN LOG TABLE DATA LOADING';
export const CHECK_IN_LOG_TABLE_DATA_LOADED = '[CHECK IN LOG] CHECK IN LOG TABLE DATA LOADED';
export const LOAD_LINES = '[CHECK IN LOG] LOAD LINES';
export const LINES_LOADED = '[CHECK IN LOG] LINES LOADED';
export const SUBMIT_CHECK_IN_LOG = '[CHECK IN LOG] SUBMIT CHECK IN LOG';
export const SUBMIT_CHECK_IN_LOG_COMPLETED = '[CHECK IN LOG] SUBMIT CHECK IN LOG COMPLETED';
export const DELETE_CHECK_IN_LOG = '[CHECK IN LOG] DELETE CHECK IN LOG';
export const DELETE_CHECK_IN_LOG_COMPLETED = '[CHECK IN LOG] DELETE CHECK IN LOG COMPLETED';
export const BULK_DELETE_CHECK_IN_LOG = '[CHECK IN LOG] BULK DELETE CHECK IN LOG';
export const BULK_DELETE_CHECK_IN_LOG_COMPLETED = '[CHECK IN LOG] BULK DELETE CHECK IN LOG COMPLETED';
export const DOWNLOAD_CHECK_IN_LOG_EXCEL = '[CHECK IN LOG] DOWNLOAD CHECKIN LOG EXCEL';
export const DOWNLOAD_CHECK_IN_LOG_EXCEL_COMPLETED = '[CHECK IN LOG] DOWNLOAD CHECKIN LOG EXCEL COMPLETED';
export const SET_TABLE_SETTINGS = '[CHECK IN LOG] SET TABLE SETTINGS';
export const CHECK_IN_LOGS_RANGE_DATA_LOADING = '[CHECK IN LOG] RANGE DATA LOADING';
export const CHECK_IN_LOGS_RANGE_DATA_LOADED = '[CHECK IN LOG] RANGE DATA LOADED';
export const FETCH_DATA_ERROR = '[CHECK IN LOG] FETCH DATA ERROR';

export class StartCheckInLogCardsDataLoading implements Action {
  readonly type = START_CHECK_IN_LOG_CARDS_DATA_LOADING;

  constructor(
    public query: CheckInTableQueryParams = {
      offset: 0,
      pageSize: 10,
    },
  ) {}
}

export class CheckInLogCardsDataLoaded implements Action {
  readonly type = CHECK_IN_LOG_CARDS_DATA_LOADED;

  constructor(public payload: CheckInLogCardDataInterface) {}
}

export class StartCheckInLogTableDataLoading implements Action {
  readonly type = START_CHECK_IN_LOG_TABLE_DATA_LOADING;

  constructor(
    public query: CheckInTableQueryParams = {
      offset: 0,
      pageSize: 10,
    },
    public groupedBy: GroupedByTypes,
  ) {}
}

export class CheckInLogTableDataLoaded implements Action {
  readonly type = CHECK_IN_LOG_TABLE_DATA_LOADED;

  constructor(public payload: CheckInLogTableResponseInterface) {}
}

export class LoadLines implements Action {
  readonly type = LOAD_LINES;
}

export class LinesLoaded implements Action {
  readonly type = LINES_LOADED;

  constructor(public payload: GetManyResponseInterface<LineCRUDInterface>) {}
}

export class SubmitCheckInLog implements Action {
  readonly type = SUBMIT_CHECK_IN_LOG;

  constructor(public formType: CheckInLogFormTypes, public checkInLogForm: CheckInLogFormRequestPayloadInterface) {}
}

export class SubmitCheckInLogCompleted implements Action {
  readonly type = SUBMIT_CHECK_IN_LOG_COMPLETED;

  constructor(public formType: CheckInLogFormTypes, public payload: BaseOneResponseInterface<CheckInLogInterface>) {}
}

export class DeleteCheckInLog implements Action {
  readonly type = DELETE_CHECK_IN_LOG;

  constructor(public id: number) {}
}

export class DeleteCheckInLogCompleted implements Action {
  readonly type = DELETE_CHECK_IN_LOG_COMPLETED;

  constructor(public payload: BaseCrudResponse) {}
}

export class BulkDeleteCheckInLog implements Action {
  readonly type = BULK_DELETE_CHECK_IN_LOG;

  constructor(public checkIns: number[]) {}
}

export class BulkDeleteCheckInLogCompleted implements Action {
  readonly type = BULK_DELETE_CHECK_IN_LOG_COMPLETED;

  constructor(public response: { id: number; errorMessages?: string }[]) {}
}

export class FetchDataError implements Action {
  readonly type = FETCH_DATA_ERROR;

  constructor(public payload: object) {}
}

export class DownloadCheckInLogsExcel implements Action {
  readonly type = DOWNLOAD_CHECK_IN_LOG_EXCEL;

  constructor(
    public queryParams: CheckInTableQueryParams,
    public groupedBy: GroupedByTypes,
  ) {}
}

export class SetTableSettings implements Action {
  readonly type = SET_TABLE_SETTINGS;

  constructor(public payload: TabRowInterface[], public setAsDefault: boolean) {}
}

export class DownloadExcelCompleted implements Action {
  readonly type = DOWNLOAD_CHECK_IN_LOG_EXCEL_COMPLETED;
}

export class CheckInLogsRangeDataLoading implements Action {
  readonly type = CHECK_IN_LOGS_RANGE_DATA_LOADING;

  constructor(public payload: CheckInTableQueryParams, public groupedBy: GroupedByTypes) {}
}

export class CheckInLogsRangeDataLoaded implements Action {
  readonly type = CHECK_IN_LOGS_RANGE_DATA_LOADED;

  constructor(public payload: CheckInLogTableResponseInterface) {
  }
}

export type CheckInLogActions =
  | StartCheckInLogCardsDataLoading
  | CheckInLogCardsDataLoaded
  | StartCheckInLogTableDataLoading
  | CheckInLogTableDataLoaded
  | LoadLines
  | LinesLoaded
  | SubmitCheckInLog
  | SubmitCheckInLogCompleted
  | DeleteCheckInLog
  | DeleteCheckInLogCompleted
  | DownloadCheckInLogsExcel
  | DownloadExcelCompleted
  | SetTableSettings
  | CheckInLogsRangeDataLoading
  | CheckInLogsRangeDataLoaded
  | FetchDataError;
