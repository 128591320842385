import * as Actions from './task-performance.actions';
import { ITaskPerformanceState } from './task-performance.model';

export const taskPerformanceInitialState: ITaskPerformanceState = {
  isTaskPerformanceDataLoading: false,
  isTaskPerformanceDataLoaded: false,
  fileDownloadStatus: false,
  taskPerformanceData: [],
  shifts: [],
};

export function taskPerformanceReducer(
  state: ITaskPerformanceState = taskPerformanceInitialState,
  action: Actions.TaskPerformanceActions,
): ITaskPerformanceState {
  switch (action.type) {
    case Actions.TASK_PERFORMANCE_DATA_LOADING:
      return {
        ...state,
        isTaskPerformanceDataLoading: true,
        isTaskPerformanceDataLoaded: false,
      };
    case Actions.TASK_PERFORMANCE_DATA_LOADED:
      return {
        ...state,
        isTaskPerformanceDataLoading: false,
        isTaskPerformanceDataLoaded: true,
        taskPerformanceData: action.payload.taskPerformanceData,
        shifts: action.payload.shifts,
      };
    case Actions.TASK_PERFORMANCE_DOWNLOAD_EXCEL:
      return {
        ...state,
        fileDownloadStatus: true,
      };
    case Actions.TASK_PERFORMANCE_DOWNLOAD_EXCEL_COMPLETED:
      return {
        ...state,
        fileDownloadStatus: false,
      };
    case Actions.FETCH_DATA_ERROR:
      return {
        ...state,
        isTaskPerformanceDataLoading: false,
        isTaskPerformanceDataLoaded: true,
      };
    default:
      return state;
  }
}
