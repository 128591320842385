import { Action } from '@ngrx/store';
import { HttpOptionInterface } from '../filter.model';
import { HttpErrorResponse } from '@angular/common/http';
import { TaskFilterCRUDInterface } from '../../../shared/component/filter/filter.class';

export enum FilterTaskActionTypes {
  StartLoadFilterTasks = '[Filter Task] Load Filter Tasks',
  FilterTasksLoaded = '[Filter Task] Filter Tasks Loaded',
  ResetTaskStore = '[Filter Task] Reset data stored in task store',
  FetchError = '[Filter Task] Fetch Error',
}

export class StartLoadFilterTasks implements Action {
  readonly type = FilterTaskActionTypes.StartLoadFilterTasks;

  constructor(public options: HttpOptionInterface) {}
}

export class FilterTasksLoaded implements Action {
  readonly type = FilterTaskActionTypes.FilterTasksLoaded;

  constructor(public data: TaskFilterCRUDInterface[]) {}
}

export class ResetTaskStore implements Action {
  readonly type = FilterTaskActionTypes.ResetTaskStore;
}

export class FetchError implements Action {
  readonly type = FilterTaskActionTypes.FetchError;

  constructor(public payload: HttpErrorResponse) {}
}

export type FilterTaskActions = StartLoadFilterTasks | FilterTasksLoaded | ResetTaskStore | FetchError;
