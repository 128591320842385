import { Injectable } from '@angular/core';
import { Router, CanActivate, UrlTree } from '@angular/router';
import { Observable, of, map, take  } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../../store/oee.reducer';
import { User } from '../../../store/user/model';

@Injectable({
  providedIn: 'root',
})
export class SiteLineGuardService implements CanActivate {
  constructor(
    public router: Router,
    public http: HttpClient,
    private store: Store<OeeAppState>,
  ) {}

  canActivate(): Observable<UrlTree | true> | UrlTree {
    const selectSiteAndLineUrlTree = this.router.createUrlTree(['/select-site-and-line'])
    const selectedSiteLine = JSON.parse(localStorage.getItem('selectedSiteLine'));
    const siteId = Number(_.get(selectedSiteLine, 'siteId', -1));
    const lineId = Number(_.get(selectedSiteLine, 'lineId', -1));

    if (siteId !== -1 && lineId !== -1) {
      return this.store.select('user').pipe(take(1), map((state: User) => {
        return !state.siteId || !state.lineId || state.siteId !== siteId || state.lineId !== lineId
          ? selectSiteAndLineUrlTree
          : true;
      }));
    }

    return selectSiteAndLineUrlTree;
  }
}
