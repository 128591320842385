import { Action } from '@ngrx/store';
import {
  BulkEditRootCauseGroupInterface,
  RootCauseGroupBulkOperationResponseInterface,
  RootCauseGroupBulkSaveManyInterface,
  RootCauseGroupCreateInterface,
  RootCauseGroupCRUDDataInterface,
  RootCauseGroupDeleteResponseInterface,
  RootCauseGroupGetOneInterface,
  RootCauseGroupTableQueryInterface,
  RootCauseGroupUpdateInterface,
  RootCauseTypeCreateUpdateInterface,
} from './root-cause-group.model';
import { RootCauseGroupDataRequestParameterInterface } from '../../../view/settings/root-cause-group/root-cause-group.model';
import { RootCauseTypeCRUDInterface } from '../../../shared/component/filter/filter.class';
import { DownloadExcelFiltersInterface } from '../../../shared/service/excel/excel-helper.service';
import {
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';

export const ROOT_CAUSE_GROUP_DATA_LOADING = '[ROOT CAUSE GROUP] Get root cause group table data';
export const ROOT_CAUSE_GROUP_DATA_LOADED = '[ROOT CAUSE GROUP] Get root cause group table data complete';
export const ROOT_CAUSE_GROUP_FILTER_DATA_LOADING = '[ROOT CAUSE GROUP] Get root cause group filter data';
export const ROOT_CAUSE_GROUP_FILTER_DATA_LOADED = '[ROOT CAUSE GROUP] Get root cause group filter data complete';
export const CREATE_ROOT_CAUSE_GROUP = '[ROOT CAUSE GROUP] Create root cause group table data';
export const CREATE_ROOT_CAUSE_GROUP_COMPLETED = '[ROOT CAUSE GROUP] Create root cause group table data complete';
export const UPDATE_ROOT_CAUSE_GROUP = '[ROOT CAUSE GROUP] Update root cause group data';
export const UPDATE_ROOT_CAUSE_GROUP_COMPLETED = '[ROOT CAUSE GROUP] Update root cause group data completed';
export const DELETE_ROOT_CAUSE_GROUP = '[ROOT CAUSE GROUP] Delete root cause group data';
export const DELETE_ROOT_CAUSE_GROUP_COMPLETED = '[ROOT CAUSE GROUP] Delete root cause group data completed';
export const UPDATE_ROOT_CAUSE_GROUPS = '[ROOT CAUSE GROUP] Update root cause groups data';
export const UPDATE_ROOT_CAUSE_GROUPS_COMPLETED = '[ROOT CAUSE GROUP] Update root cause groups data completed';
export const DELETE_ROOT_CAUSE_GROUPS = '[ROOT CAUSE GROUP] Delete root cause groups data';
export const DELETE_ROOT_CAUSE_GROUPS_COMPLETED = '[ROOT CAUSE GROUP] Delete root cause groups data completed';

export const ROOT_CAUSE_TYPE_DATA_LOADING = '[ROOT CAUSE TYPE] Get root cause type table data';
export const ROOT_CAUSE_TYPE_DATA_LOADED = '[ROOT CAUSE TYPE] Get root cause type table data complete';
export const CREATE_ROOT_CAUSE_TYPE = '[ROOT CAUSE TYPE] Create root cause type table data';
export const CREATE_ROOT_CAUSE_TYPE_COMPLETED = '[ROOT CAUSE TYPE] Create root cause type table data complete';
export const UPDATE_ROOT_CAUSE_TYPE = '[ROOT CAUSE TYPE] Update root cause type data';
export const UPDATE_ROOT_CAUSE_TYPE_COMPLETED = '[ROOT CAUSE TYPE] Update root cause type data completed';
export const DELETE_ROOT_CAUSE_TYPE = '[ROOT CAUSE TYPE] Delete root cause type data';
export const DELETE_ROOT_CAUSE_TYPE_COMPLETED = '[ROOT CAUSE TYPE] Delete root cause type data completed';
export const ROOT_CAUSE_GROUP_FETCH_ERROR = '[ROOT CAUSE GROUP] Fetch Error';
export const ROOT_CAUSE_TYPE_FETCH_ERROR = '[ROOT CAUSE TYPE] Fetch Error';
export const DELETE_ROOT_CAUSE_TYPES = '[ROOT CAUSE TYPE] Delete root cause types data';
export const DELETE_ROOT_CAUSE_TYPES_COMPLETED = '[ROOT CAUSE TYPE] Delete root cause types data completed';

export const GET_ROOT_CAUSE_GROUP_COUNT = '[ROOT CAUSE GROUP] Get root cause group count';
export const GET_ROOT_CAUSE_GROUP_COUNT_COMPLETED = '[ROOT CAUSE GROUP] Get root cause group count is completed';
export const DOWNLOAD_ROOT_CAUSE_GROUP_EXCEL = '[ROOT CAUSE GROUP] Download root cause groups excel';
export const DOWNLOAD_ROOT_CAUSE_GROUP_EXCEL_COMPLETED =
  '[ROOT CAUSE GROUP] Download root cause groups excel action completed';
export const UPLOAD_ROOT_CAUSE_GROUP_EXCEL = '[ROOT CAUSE GROUP] Upload root cause groups excel';
export const UPLOAD_ROOT_CAUSE_GROUP_EXCEL_COMPLETED =
  '[ROOT CAUSE GROUP] Upload root cause groups excel action completed';
export const CLEAR_STATE = '[ROOT CAUSE GROUP] Clear state';

export class RootCauseGroupDataLoading implements Action {
  readonly type = ROOT_CAUSE_GROUP_DATA_LOADING;

  constructor(public data: RootCauseGroupTableQueryInterface, public limit?: number) {}
}

export class RootCauseGroupDataLoaded implements Action {
  readonly type = ROOT_CAUSE_GROUP_DATA_LOADED;

  constructor(public data: GetManyResponseInterface<RootCauseGroupGetOneInterface>) {}
}

export class RootCauseGroupFilterDataLoading implements Action {
  readonly type = ROOT_CAUSE_GROUP_FILTER_DATA_LOADING;

  constructor(public siteId: number, public limit?: number) {}
}

export class RootCauseGroupFilterDataLoaded implements Action {
  readonly type = ROOT_CAUSE_GROUP_FILTER_DATA_LOADED;

  constructor(public payload: GetManyResponseInterface<RootCauseGroupGetOneInterface>) {}
}

export class CreateRootCauseGroup implements Action {
  readonly type = CREATE_ROOT_CAUSE_GROUP;

  constructor(public data: RootCauseGroupCreateInterface) {}
}

export class CreateRootCauseGroupCompleted implements Action {
  readonly type = CREATE_ROOT_CAUSE_GROUP_COMPLETED;

  constructor(public response: BaseOneResponseInterface<RootCauseGroupCRUDDataInterface>) {}
}

export class UpdateRootCauseGroup implements Action {
  readonly type = UPDATE_ROOT_CAUSE_GROUP;

  constructor(public id: number, public data: RootCauseGroupUpdateInterface) {}
}

export class UpdateRootCauseGroupCompleted implements Action {
  readonly type = UPDATE_ROOT_CAUSE_GROUP_COMPLETED;

  constructor(public response: RootCauseGroupCRUDDataInterface) {}
}

export class DeleteRootCauseGroup implements Action {
  readonly type = DELETE_ROOT_CAUSE_GROUP;

  constructor(public id: number) {}
}

export class DeleteRootCauseGroupCompleted implements Action {
  readonly type = DELETE_ROOT_CAUSE_GROUP_COMPLETED;

  constructor(public isBulk: boolean, public response: RootCauseGroupDeleteResponseInterface) {}
}

export class RootCauseTypeDataLoading implements Action {
  readonly type = ROOT_CAUSE_TYPE_DATA_LOADING;

  constructor(public data: RootCauseGroupTableQueryInterface) {}
}

export class RootCauseTypeDataLoaded implements Action {
  readonly type = ROOT_CAUSE_TYPE_DATA_LOADED;

  constructor(public data: GetManyResponseInterface<RootCauseTypeCRUDInterface>) {}
}

export class CreateRootCauseType implements Action {
  readonly type = CREATE_ROOT_CAUSE_TYPE;

  constructor(public data: RootCauseTypeCreateUpdateInterface) {}
}

export class CreateRootCauseTypeCompleted implements Action {
  readonly type = CREATE_ROOT_CAUSE_TYPE_COMPLETED;

  constructor(public response: RootCauseTypeCRUDInterface) {}
}

export class UpdateRootCauseType implements Action {
  readonly type = UPDATE_ROOT_CAUSE_TYPE;

  constructor(public id: number, public data: RootCauseTypeCreateUpdateInterface) {}
}

export class UpdateRootCauseTypeCompleted implements Action {
  readonly type = UPDATE_ROOT_CAUSE_TYPE_COMPLETED;

  constructor(public response: RootCauseTypeCRUDInterface) {}
}

export class DeleteRootCauseType implements Action {
  readonly type = DELETE_ROOT_CAUSE_TYPE;

  constructor(public id: number) {}
}

export class DeleteRootCauseTypeCompleted implements Action {
  readonly type = DELETE_ROOT_CAUSE_TYPE_COMPLETED;

  constructor(public isBulk: boolean, public response: RootCauseGroupDeleteResponseInterface) {}
}

export class GetRootCauseGroupCount implements Action {
  readonly type = GET_ROOT_CAUSE_GROUP_COUNT;
  constructor(public data: RootCauseGroupDataRequestParameterInterface) {}
}

export class GetRootCauseGroupCountCompleted implements Action {
  readonly type = GET_ROOT_CAUSE_GROUP_COUNT_COMPLETED;
  constructor(public data: GetManyResponseInterface<RootCauseGroupGetOneInterface>) {}
}

export class DownloadRootCauseGroupExcel implements Action {
  readonly type = DOWNLOAD_ROOT_CAUSE_GROUP_EXCEL;
  constructor(public withData: boolean = false, public filters: DownloadExcelFiltersInterface) {}
}

export class DownloadRootCauseGroupExcelCompleted implements Action {
  readonly type = DOWNLOAD_ROOT_CAUSE_GROUP_EXCEL_COMPLETED;
}

export class UploadRootCauseGroupExcel implements Action {
  readonly type = UPLOAD_ROOT_CAUSE_GROUP_EXCEL;

  constructor(public rootCauseGroups: RootCauseGroupBulkSaveManyInterface) {}
}

export class UploadRootCauseGroupExcelCompleted implements Action {
  readonly type = UPLOAD_ROOT_CAUSE_GROUP_EXCEL_COMPLETED;

  constructor(public payload: (RootCauseGroupCRUDDataInterface & { errorMessages?: string })[]) {}
}

export class RootCauseGroupFetchError implements Action {
  readonly type = ROOT_CAUSE_GROUP_FETCH_ERROR;

  constructor(public payload: any[]) {}
}

export class RootCauseTypeFetchError implements Action {
  readonly type = ROOT_CAUSE_TYPE_FETCH_ERROR;

  constructor(public payload: any[]) {}
}

export class ClearState implements Action {
  readonly type = CLEAR_STATE;
}

export class DeleteRootCauseGroups implements Action {
  readonly type = DELETE_ROOT_CAUSE_GROUPS;
  constructor(public payload: number[]) {}
}

export class DeleteRootCauseGroupsCompleted implements Action {
  readonly type = DELETE_ROOT_CAUSE_GROUPS_COMPLETED;
  constructor(public isBulk: boolean, public response: RootCauseGroupDeleteResponseInterface) {}
}

export class UpdateRootCauseGroups implements Action {
  readonly type = UPDATE_ROOT_CAUSE_GROUPS;

  constructor(public rootCauseGroups: BulkEditRootCauseGroupInterface[]) {}
}

export class DeleteRootCauseTypes implements Action {
  readonly type = DELETE_ROOT_CAUSE_TYPES;
  constructor(public payload: number[]) {}
}

export class DeleteRootCauseTypesCompleted implements Action {
  readonly type = DELETE_ROOT_CAUSE_TYPES_COMPLETED;
  constructor(public isBulk: boolean, public response: RootCauseGroupDeleteResponseInterface) {}
}

export class UpdateRootCauseGroupsCompleted implements Action {
  readonly type = UPDATE_ROOT_CAUSE_GROUPS_COMPLETED;

  constructor(public payload: GetManyResponseInterface<RootCauseGroupBulkOperationResponseInterface>) {}
}

export type RootCauseGroupActions =
  | RootCauseGroupDataLoading
  | RootCauseGroupDataLoaded
  | CreateRootCauseGroup
  | CreateRootCauseGroupCompleted
  | UpdateRootCauseGroup
  | UpdateRootCauseGroupCompleted
  | DeleteRootCauseGroup
  | DeleteRootCauseGroupCompleted
  | RootCauseTypeDataLoading
  | RootCauseTypeDataLoaded
  | CreateRootCauseType
  | CreateRootCauseTypeCompleted
  | UpdateRootCauseType
  | UpdateRootCauseTypeCompleted
  | DeleteRootCauseType
  | DeleteRootCauseTypeCompleted
  | GetRootCauseGroupCount
  | GetRootCauseGroupCountCompleted
  | DownloadRootCauseGroupExcel
  | DownloadRootCauseGroupExcelCompleted
  | UploadRootCauseGroupExcel
  | UploadRootCauseGroupExcelCompleted
  | RootCauseGroupFetchError
  | RootCauseTypeFetchError
  | DeleteRootCauseGroups
  | DeleteRootCauseGroupsCompleted
  | UpdateRootCauseGroups
  | UpdateRootCauseGroupsCompleted
  | DeleteRootCauseTypes
  | DeleteRootCauseTypesCompleted
  | RootCauseGroupFilterDataLoading
  | RootCauseGroupFilterDataLoaded
  | ClearState;
