import { Inject, Injectable } from '@angular/core';
import { FilterServiceInterface, WorkOrderCRUDResponseInterface } from './service.class';
import { HttpClient, HttpParams } from '@angular/common/http';
import { WorkOrderCRUDInterface } from '../../component/filter/filter.class';
import { Observable } from 'rxjs';
import { GetManyResponseInterface } from '../../model/interface/crud-response-interface.model';

@Injectable({
  providedIn: 'root',
})
export class WorkOrderService {
  private readonly url: string = `${this.baseUrl}/work-orders`;

  constructor(public http: HttpClient, @Inject('API_BASE_URL') private baseUrl: string) {}

  getData(options?: HttpParams): Observable<GetManyResponseInterface<WorkOrderCRUDInterface>> {
    return this.http.get<GetManyResponseInterface<WorkOrderCRUDInterface>>(this.url, { params: options });
  }

  public getWorkOrders(params?: HttpParams): Observable<GetManyResponseInterface<WorkOrderCRUDInterface>> {
    return this.http.get<GetManyResponseInterface<WorkOrderCRUDInterface>>(this.url, { params });
  }

  public getWorkOrder(id: number): Observable<{ id: number; name: string }> {
    return this.http.get<{ id: number; name: string }>(`${this.url}/${id}?fields=id,woNumber`);
  }
}
