import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import * as ObjectActions from './my-report-metrics.actions';
import * as AppActions from '../../app/actions';
import { MyReportsMetricsService } from './my-reports-metrics.service';
import { catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { IMyReportWithMetrics } from '../../../view/reports/my-reports/my-report-metrics/my-report-metrics.model';
import { IOeeCalculationResult } from '../work-order-performance/work-order-performance.model';

@Injectable()
export class MyReportMetricsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly service: MyReportsMetricsService,
  ) {}

  $fetchMyReportMetrics = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.EMyReportMetricsActionTypes.FetchMyReportMetricsData),
      switchMap((objectData: ObjectActions.FetchMyReportMetricsData) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getMyReportWithMetrics(objectData.id).pipe(
          switchMap((response: BaseOneResponseInterface<IMyReportWithMetrics>) => {
            return of(new ObjectActions.FetchMyReportMetricsDataCompleted(response.data), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getOeeCalculationDataHourly = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.EMyReportMetricsActionTypes.GetOeeCalculationDataHourly),
      switchMap((objectData: ObjectActions.GetOeeCalculationDataHourly) => {
        return this.service.getOeeCalculationData(objectData.filters).pipe(
          switchMap((response: GetManyResponseInterface<IOeeCalculationResult>) => {
            return of(new ObjectActions.GetOeeCalculationDataHourlyCompleted(response.data));
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchError(err));
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes));
      }),
    ),
  );

  getOeeCalculationDataDaily = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.EMyReportMetricsActionTypes.GetOeeCalculationDataDaily),
      switchMap((objectData: ObjectActions.GetOeeCalculationDataDaily) => {
        return this.service.getOeeCalculationData(objectData.filters).pipe(
          switchMap((response: GetManyResponseInterface<IOeeCalculationResult>) => {
            return of(new ObjectActions.GetOeeCalculationDataDailyCompleted(response.data));
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchError(err));
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes));
      }),
    ),
  );

  getOeeCalculationDataWeekly = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.EMyReportMetricsActionTypes.GetOeeCalculationDataWeekly),
      switchMap((objectData: ObjectActions.GetOeeCalculationDataWeekly) => {
        return this.service.getOeeCalculationData(objectData.filters).pipe(
          switchMap((response: GetManyResponseInterface<IOeeCalculationResult>) => {
            return of(new ObjectActions.GetOeeCalculationDataWeeklyCompleted(response.data));
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchError(err));
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes));
      }),
    ),
  );

  getOeeCalculationDataMonthly = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.EMyReportMetricsActionTypes.GetOeeCalculationDataMonthly),
      switchMap((objectData: ObjectActions.GetOeeCalculationDataMonthly) => {
        return this.service.getOeeCalculationData(objectData.filters).pipe(
          switchMap((response: GetManyResponseInterface<IOeeCalculationResult>) => {
            return of(new ObjectActions.GetOeeCalculationDataMonthlyCompleted(response.data));
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchError(err));
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes));
      }),
    ),
  );

  $bulkCreateNewMetrics = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.EMyReportMetricsActionTypes.BulkCreateMyReportMetrics),
      switchMap((objectData: ObjectActions.BulkCreateMyReportMetrics) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.bulkCreateNewMyReportMetric(objectData.parameters).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            return of(new ObjectActions.BulkCreateMyReportMetricsCompleted(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  $bulkUpdateNewMetrics = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.EMyReportMetricsActionTypes.BulkUpdateMyReportMetrics),
      switchMap((objectData: ObjectActions.BulkUpdateMyReportMetrics) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.bulkUpdateNewMyReportMetric(objectData.parameters).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            return of(new ObjectActions.BulkUpdateMyReportMetricsCompleted(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  $bulkDeleteMyReportMetrics = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.EMyReportMetricsActionTypes.BulkDeleteMyReportMetrics),
      switchMap((objectData: ObjectActions.BulkDeleteMyReportMetrics) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.bulkDeleteMyReportMetrics(objectData.ids).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            return of(new ObjectActions.BulkDeleteMyReportMetricsCompleted(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );
}
