import { Action } from '@ngrx/store';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { IEquipmentsRequestParams } from '../../../view/settings/equipment-assets/equipments/equipments.model';
import { IAnonymousCard } from '../users/users.model';
import { IEquipmentsDownloadExcelFilters, IEquipmentBulkSaveMany, IEquipment } from './equipments.model';

export const DOWNLOAD_EQUIPMENT_EXCEL = '[EQUIPMENTS] EQUIPMENTS ACTION DOWNLOAD EQUIPMENT EXCEL';
export const DOWNLOAD_EQUIPMENT_EXCEL_COMPLETED = '[EQUIPMENTS] EQUIPMENTS ACTION DOWNLOAD EQUIPMENT EXCEL COMPLETED';
export const UPLOAD_EQUIPMENT_EXCEL = '[EQUIPMENTS] EQUIPMENTS ACTION UPLOAD EQUIPMENT EXCEL';
export const UPLOAD_EQUIPMENT_EXCEL_COMPLETED = '[EQUIPMENTS] EQUIPMENTS ACTION UPLOAD EQUIPMENT EXCEL COMPLETED';
export const LOAD_EQUIPMENTS_DATA = '[EQUIPMENTS] LOAD EQUIPMENTS DATA';
export const LOADED_EQUIPMENTS_DATA = '[EQUIPMENTS] LOADED EQUIPMENTS DATA';
export const SINGLE_EDIT_EQUIPMENTS_DATA = '[EQUIPMENTS] REQUEST SINGLE EDIT EQUIPMENTS DATA';
export const EDITED_SINGLE_EDIT_EQUIPMENTS_DATA = '[EQUIPMENTS] EDITED SINGLE EDIT EQUIPMENTS DATA';
export const EDIT_EQUIPMENTS_DATA = '[EQUIPMENTS] REQUEST EDIT EQUIPMENTS DATA';
export const EDITED_EQUIPMENTS_DATA = '[EQUIPMENTS] EDITED EQUIPMENTS DATA';
export const CREATE_EQUIPMENT = '[EQUIPMENTS] CREATE EQUIPMENT DATA';
export const CREATED_EQUIPMENT = '[EQUIPMENTS] CREATED EQUIPMENT DATA';
export const DELETE_EQUIPMENT = '[EQUIPMENTS] DELETE EQUIPMENT';
export const DELETED_EQUIPMENT = '[EQUIPMENTS] DELETED EQUIPMENT';
export const DELETE_EQUIPMENTS = '[EQUIPMENTS] DELETE EQUIPMENTS';
export const DELETED_EQUIPMENTS = '[EQUIPMENTS] DELETED EQUIPMENTS';
export const EQUIPMENTS_FETCH_ERROR = '[EQUIPMENTS] EQUIPMENTS ACTION FETCH ERROR';
export const LOAD_EQUIPMENT_DATA_COUNT = '[EQUIPMENTS] LOAD EQUIPMENT DATA COUNT';
export const EQUIPMENT_DATA_COUNT_LOADED = '[EQUIPMENTS] EQUIPMENTS DATA COUNT LOADED';
export const LOAD_ANONYMOUS_CARDS = '[EQUIPMENTS] LOAD ANONYMOUS CARDS';
export const ANONYMOUS_CARDS_LOADED = '[EQUIPMENTS] ANONYMOUS CARDS LOADED';
export const GET_AVATAR_LOADING = '[EQUIPMENTS] GET AVATAR LOADING';
export const GET_AVATAR_LOADED = '[EQUIPMENTS] GET AVATAR LOADED';
export const GET_AVATAR_FOR_LOGS_LOADING = '[EQUIPMENTS] GET AVATAR FOR LOGS LOADING';
export const GET_AVATAR_FOR_LOGS_LOADED = '[EQUIPMENTS] GET AVATAR FOR LOGS LOADED';
export const UPLOAD_AVATAR_LOADING = '[EQUIPMENTS] UPLOAD AVATAR LOADING';
export const UPLOAD_AVATAR_LOADED = '[EQUIPMENTS] UPLOAD AVATAR LOADED';
export const DELETE_AVATAR_LOADING = '[EQUIPMENTS] DELETE AVATAR LOADING';
export const DELETE_AVATAR_LOADED = '[EQUIPMENTS] DELETE AVATAR LOADED';
export const CLEAR_STATE = '[EQUIPMENTS] CLEAR STATE';

export class LoadAnonymousCards implements Action {
  readonly type = LOAD_ANONYMOUS_CARDS;

  constructor(public site: number, public search?: string) {}
}

export class AnonymousCardsLoaded implements Action {
  readonly type = ANONYMOUS_CARDS_LOADED;

  constructor(public payload: GetManyResponseInterface<IAnonymousCard>) {}
}

export class LoadExcelDataCount implements Action {
  readonly type = LOAD_EQUIPMENT_DATA_COUNT;

  constructor(public siteId: number) {}
}

export class ExcelDataCountLoaded implements Action {
  readonly type = EQUIPMENT_DATA_COUNT_LOADED;

  constructor(public payload: GetManyResponseInterface<IEquipment>) {}
}

export class LoadEquipmentsData implements Action {
  readonly type = LOAD_EQUIPMENTS_DATA;

  constructor(public requestParams: IEquipmentsRequestParams) {}
}

export class LoadedEquipmentsData implements Action {
  readonly type = LOADED_EQUIPMENTS_DATA;

  constructor(public payload: GetManyResponseInterface<IEquipment>) {}
}

export class SingleEditEquipmentsData implements Action {
  readonly type = SINGLE_EDIT_EQUIPMENTS_DATA;

  constructor(
    public equipmentId: number,
    public payload: Partial<IEquipment>,
    public hideLoaderAfterSuccess: boolean = true,
  ) {}
}

export class EditedSingleEditEquipmentsData implements Action {
  readonly type = EDITED_SINGLE_EDIT_EQUIPMENTS_DATA;

  constructor(public response: BaseOneResponseInterface<IEquipment>) {}
}

export class EditEquipmentsData implements Action {
  readonly type = EDIT_EQUIPMENTS_DATA;

  constructor(public payload: Partial<IEquipment>[]) {}
}

export class EditedEquipmentsData implements Action {
  readonly type = EDITED_EQUIPMENTS_DATA;

  constructor(public response: BulkResponseDataInterface) {}
}

export class CreateEquipment implements Action {
  readonly type = CREATE_EQUIPMENT;

  constructor(public payload: Partial<IEquipment>, public hideLoaderAfterSuccess: boolean = true,) {}
}

export class CreatedEquipment implements Action {
  readonly type = CREATED_EQUIPMENT;

  constructor(public response: BaseOneResponseInterface<IEquipment>) {}
}

export class DeleteEquipment implements Action {
  readonly type = DELETE_EQUIPMENT;

  constructor(public equipmentId: number) {}
}

export class DeletedEquipment implements Action {
  readonly type = DELETED_EQUIPMENT;

  constructor(public response: GetManyResponseInterface<IEquipment>) {}
}

export class DeleteEquipments implements Action {
  readonly type = DELETE_EQUIPMENTS;

  constructor(public equipmentIds: number[]) {}
}

export class DeletedEquipments implements Action {
  readonly type = DELETED_EQUIPMENTS;

  constructor(public response: BulkResponseDataInterface) {}
}

export class DownloadEquipmentExcel implements Action {
  readonly type = DOWNLOAD_EQUIPMENT_EXCEL;

  constructor(public withData: boolean = false, public filters: IEquipmentsDownloadExcelFilters) {}
}

export class DownloadEquipmentExcelCompleted implements Action {
  readonly type = DOWNLOAD_EQUIPMENT_EXCEL_COMPLETED;
}

export class UploadEquipmentExcel implements Action {
  readonly type = UPLOAD_EQUIPMENT_EXCEL;

  constructor(public equipments: IEquipmentBulkSaveMany) {}
}

export class UploadEquipmentExcelCompleted implements Action {
  readonly type = UPLOAD_EQUIPMENT_EXCEL_COMPLETED;

  constructor(public payload: (IEquipment & { errorMessages?: string })[]) {}
}

export class ClearState implements Action {
  readonly type = CLEAR_STATE;
}

export class GetAvatarLoading implements Action {
  readonly type = GET_AVATAR_LOADING;

  constructor(public avatarPath: string) {}
}

export class GetAvatarLoaded implements Action {
  readonly type = GET_AVATAR_LOADED;

  constructor(public response: BaseOneResponseInterface<string>, public path: string) {}
}

export class GetAvatarForLogsLoading implements Action {
  readonly type = GET_AVATAR_FOR_LOGS_LOADING;

  constructor(public avatarPath: string) {}
}

export class GetAvatarForLogsLoaded implements Action {
  readonly type = GET_AVATAR_FOR_LOGS_LOADED;

  constructor(public response: BaseOneResponseInterface<string>, public path: string) {}
}

export class UploadAvatarLoading implements Action {
  readonly type = UPLOAD_AVATAR_LOADING;

  constructor(public id: number, public base64ImageContent: string) {}
}

export class UploadAvatarLoaded implements Action {
  readonly type = UPLOAD_AVATAR_LOADED;
}

export class DeleteAvatarLoading implements Action {
  readonly type = DELETE_AVATAR_LOADING;

  constructor(public id: number) {}
}

export class DeleteAvatarLoaded implements Action {
  readonly type = DELETE_AVATAR_LOADED;
}

export class FetchError implements Action {
  readonly type = EQUIPMENTS_FETCH_ERROR;
  constructor(public payload: object) {}
}

export type EquipmentsActions =
  | LoadAnonymousCards
  | AnonymousCardsLoaded
  | LoadExcelDataCount
  | ExcelDataCountLoaded
  | LoadEquipmentsData
  | LoadedEquipmentsData
  | DownloadEquipmentExcel
  | DownloadEquipmentExcelCompleted
  | UploadEquipmentExcel
  | UploadEquipmentExcelCompleted
  | ClearState
  | GetAvatarLoading
  | GetAvatarLoaded
  | GetAvatarForLogsLoading
  | GetAvatarForLogsLoaded
  | UploadAvatarLoading
  | UploadAvatarLoaded
  | DeleteAvatarLoading
  | DeleteAvatarLoaded
  | FetchError;
