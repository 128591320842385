import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { interval, Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../../../store/oee.reducer';
import * as PageHeaderActions from '../../../../store/page-header/page-header.actions';
import { PageHeaderStateInterface } from '../../../../store/page-header/page-header.model';
import { takeWhile } from 'rxjs/operators';
import { PageHeaderInterface } from '../../../service/page-header/page-header.model';
import { PageRefreshIntervals } from '../../../../../constants.model';

@Component({
  selector: 'app-dashboard-mode-countdown',
  templateUrl: './dashboard-mode-countdown.component.html',
  styleUrls: ['./dashboard-mode-countdown.component.scss'],
})
export class DashboardModeCountdownComponent implements OnInit, OnDestroy {
  @Input() pageHeader: PageHeaderInterface = {
    title: null,
    titleKey: null,
    icon: null,
    fullScreenButton: false,
    fullScreenTargetElementId: null,
  };
  private readonly subscriptions: Subscription[] = [];
  public countdown: number = 120;
  private countdownInitial: number = 120;
  public pageRefreshInterval: Observable<number>;
  public alive: boolean = false;
  private isDashboardMode: boolean = false;

  constructor(private readonly store: Store<oeeAppReducer.OeeAppState>) {}

  public ngOnInit(): void {
    this.countdown = PageRefreshIntervals[this.pageHeader.titleKey];
    this.countdownInitial = PageRefreshIntervals[this.pageHeader.titleKey];
    this.subscriptions.push(
      this.store.select('pageHeaderStore').subscribe((state: PageHeaderStateInterface) => {
        this.isDashboardMode = state.isDashboardMode;

        if (state.isDashboardMode) {
          this.alive = true;
          this.pageRefreshInterval = interval(1000);
          return;
        }

        this.alive = false;
        clearInterval(Number(this.pageRefreshInterval));
      }),

      this.pageRefreshInterval.pipe(takeWhile(() => this.alive)).subscribe(() => {
        this.countdown -= 1;
        this.countdown = this.countdown <= 0 ? this.countdownInitial : this.countdown;

        if (this.countdown === this.countdownInitial) {
          this.store.dispatch(new PageHeaderActions.CountdownTimeout());
        }
      }),
    );
  }

  public onRefreshPageClicked(): void {
    this.countdown = this.countdownInitial;
    this.store.dispatch(new PageHeaderActions.CountdownTimeout());
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((value: Subscription) => {
      value.unsubscribe();
    });

    this.alive = false;

    if (this.pageRefreshInterval) {
      clearInterval(Number(this.pageRefreshInterval));
    }
  }
}
