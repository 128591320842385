import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { fireClick, fireClickLeave, goLink } from '../../helper/navbar-helper';
import { MenuItemInterface } from '../../../store/main/main.model';

@Component({
  selector: 'app-main-nav-bar',
  templateUrl: './main-nav-bar.component.html',
  styleUrls: ['./main-nav-bar.component.scss', '../../../../assets/icon/icofont/css/icofont.scss'],
})
export class MainNavBarComponent {
  @Input() newMenuItems: MenuItemInterface[];
  @Input() itemBorder: boolean = false;

  private readonly heightLimit: number = 200;
  public itemBorderStyle: string;
  public subItemBorder: boolean;
  public windowWidth: number;
  public dropDownIcon: string;
  public subItemIcon: string;
  public megaMenuKeys: string[] = ['navigation_reports', 'navigation_settings'];
  public singleRowMenuKeys: string[] = [
    'navigation_dashboards',
    'shift_tracker',
    'sch_header_nav_scheduler',
    'embed_page',
  ];
  public customReportsKey: string = 'navigation_reports_custom_report';
  public myReportsKey: string = 'navigation_reports_my_reports';

  fireClick = fireClick;
  fireClickLeave = fireClickLeave;
  goLink = goLink;

  constructor(public router: Router) {
    this.itemBorderStyle = 'none';
    this.subItemBorder = true;
    this.windowWidth = window.innerWidth;
    this.dropDownIcon = 'style1';
    this.subItemIcon = 'style1';
  }

  public mouseOver(menuKey: string): void {
    if (this.megaMenuKeys.includes(menuKey)) {
      const menuBox: HTMLElement = document.getElementById(`mega-menu-${menuKey}`);
      const menuIcon: HTMLElement = document.getElementById(`mega-menu-icon-${menuKey}`);
      const clientHeight: number = menuBox.clientHeight ?? 0;
      menuIcon.style.fontSize = this.heightLimit <= clientHeight ? '200px' : '120px';
    }
  }
}
