import { Action } from '@ngrx/store';
import {
  DataInterface,
  PresetInterface,
  PresetItemsInterface,
  PresetLinesInterface,
} from './scheduler-resource-setting.model';
import { LineAvailabilityPlanDataInterface } from '../line-availability/line-availability.model';
import { ActivitiesInterface } from '../../shared/model/interface/activities.model';

export enum SchedulerResourceSettingActionTypes {
  LoadSitesAndLines = '[SchedulerResourceSetting] Load Sites and Lines',
  SitesAndLinesLoaded = '[SchedulerResourceSetting] Sites and Lines Loaded',
  LoadPresets = '[SchedulerResourceSetting] Load Presets',
  PresetsLoaded = '[SchedulerResourceSetting] Presets Loaded',
  SetSelectedPreset = '[SchedulerResourceSetting] Set Selected Preset',

  LoadSchedulerResourceSettingShiftPlans = '[SchedulerResourceSetting] Load Scheduler Shift Plans',
  SchedulerResourceSettingShiftPlansLoaded = '[SchedulerResourceSetting] Scheduler Shift Plans Loaded',
  GetSelectedPreset = '[SchedulerResourceSetting] Get Selected Preset Information',
  GetSelectedPresetLoaded = '[SchedulerResourceSetting] Get Selected Preset Information Loaded',
  DeleteSelectedPreset = '[SchedulerResourceSetting] Delete Selected Preset Information',
  DeleteSelectedPresetLoaded = '[SchedulerResourceSetting] Delete Selected Preset Information Loaded',
  CreatePreset = '[SchedulerResourceSetting] Create Preset Information',
  CreatePresetLoaded = '[SchedulerResourceSetting] Create Preset Information Loaded',
  CreatePresetItems = '[SchedulerResourceSetting] Create Preset Items',
  CreatePresetItemsLoaded = '[SchedulerResourceSetting] Create Preset Items Loaded',
  CreatePresetLines = '[SchedulerResourceSetting] Create Preset Lines',
  CreatePresetLinesLoaded = '[SchedulerResourceSetting] Create Preset Lines Loaded',
  UpdatePreset = '[SchedulerResourceSetting] Update Preset Information',
  UpdatePresetLoaded = '[SchedulerResourceSetting] Update Preset Information Loaded',
  UpdatePresetItems = '[SchedulerResourceSetting] Update Preset Items',
  UpdatePresetItemsLoaded = '[SchedulerResourceSetting] Update Preset Items Loaded',
  UpdatePresetLines = '[SchedulerResourceSetting] Update Preset Lines',
  UpdatePresetLinesLoaded = '[SchedulerResourceSetting] Update Preset Lines Loaded',
  DeletePresetLines = '[SchedulerResourceSetting] Delete Preset Lines',
  DeletePresetLinesLoaded = '[SchedulerResourceSetting] Delete Preset Lines Loaded',
  FetchError = '[SchedulerResourceSetting] Fetch Error',
  LoadActivities = '[SchedulerResourceSetting] Load Activities',
  ActivitiesLoaded = '[SchedulerResourceSetting] Activities Loaded',
  ClearSelectedPreset = '[SchedulerResourceSetting] Clear Selected Preset',
}

export class LoadSitesAndLines implements Action {
  readonly type = SchedulerResourceSettingActionTypes.LoadSitesAndLines;
}

export class SitesAndLinesLoaded implements Action {
  readonly type = SchedulerResourceSettingActionTypes.SitesAndLinesLoaded;
  constructor(public payload: DataInterface) {}
}

export class LoadPresets implements Action {
  readonly type = SchedulerResourceSettingActionTypes.LoadPresets;
  constructor(public loadSitesAndLines: boolean, public siteId: number = -1) {}
}

export class PresetsLoaded implements Action {
  readonly type = SchedulerResourceSettingActionTypes.PresetsLoaded;
  constructor(public payload: PresetInterface[]) {}
}

export class SetSelectedPreset implements Action {
  readonly type = SchedulerResourceSettingActionTypes.SetSelectedPreset;
  constructor(public payload: PresetInterface) {}
}

export class LoadSchedulerResourceSettingShiftPlans implements Action {
  readonly type = SchedulerResourceSettingActionTypes.LoadSchedulerResourceSettingShiftPlans;
  constructor(public page: number = 1, public limit: number = 10, public search?: string) {}
}

export class SchedulerResourceSettingShiftPlansLoaded implements Action {
  readonly type = SchedulerResourceSettingActionTypes.SchedulerResourceSettingShiftPlansLoaded;

  constructor(public payload: LineAvailabilityPlanDataInterface[]) {}
}

export class GetSelectedPreset implements Action {
  readonly type = SchedulerResourceSettingActionTypes.GetSelectedPreset;
  constructor(public id: number) {}
}

export class GetSelectedPresetLoaded implements Action {
  readonly type = SchedulerResourceSettingActionTypes.GetSelectedPresetLoaded;
  constructor(public payload: PresetInterface) {}
}

export class DeleteSelectedPreset implements Action {
  readonly type = SchedulerResourceSettingActionTypes.DeleteSelectedPreset;
  constructor(public id: number) {}
}

export class DeleteSelectedPresetLoaded implements Action {
  readonly type = SchedulerResourceSettingActionTypes.DeleteSelectedPresetLoaded;
}

export class CreatePreset implements Action {
  readonly type = SchedulerResourceSettingActionTypes.CreatePreset;
  constructor(public payload: PresetInterface) {}
}

export class CreatePresetLoaded implements Action {
  readonly type = SchedulerResourceSettingActionTypes.CreatePresetLoaded;
  constructor(public payload: PresetInterface) {}
}

export class CreatePresetItems implements Action {
  readonly type = SchedulerResourceSettingActionTypes.CreatePresetItems;
  constructor(public payload: PresetItemsInterface) {}
}

export class CreatePresetItemsLoaded implements Action {
  readonly type = SchedulerResourceSettingActionTypes.CreatePresetItemsLoaded;
}

export class CreatePresetLines implements Action {
  readonly type = SchedulerResourceSettingActionTypes.CreatePresetLines;
  constructor(public payload: { schSettingLines: PresetLinesInterface[] }) {}
}

export class CreatePresetLinesLoaded implements Action {
  readonly type = SchedulerResourceSettingActionTypes.CreatePresetLinesLoaded;
}

export class UpdatePreset implements Action {
  readonly type = SchedulerResourceSettingActionTypes.UpdatePreset;

  constructor(
    public payload: PresetInterface & {
      updatedLines?: Partial<PresetLinesInterface>[];
      newLines?: PresetLinesInterface[];
      removedLines?: number[];
    },
  ) {}
}

export class UpdatePresetLoaded implements Action {
  readonly type = SchedulerResourceSettingActionTypes.UpdatePresetLoaded;
  constructor(public payload: PresetInterface) {}
}

export class UpdatePresetItems implements Action {
  readonly type = SchedulerResourceSettingActionTypes.UpdatePresetItems;
  constructor(public id: number, public presetItems: PresetItemsInterface) {}
}

export class UpdatePresetItemsLoaded implements Action {
  readonly type = SchedulerResourceSettingActionTypes.UpdatePresetItemsLoaded;
}

export class UpdatePresetLines implements Action {
  readonly type = SchedulerResourceSettingActionTypes.UpdatePresetLines;
  constructor(public payload: { schSettingLines: Partial<PresetLinesInterface>[] }) {}
}

export class UpdatePresetLinesLoaded implements Action {
  readonly type = SchedulerResourceSettingActionTypes.UpdatePresetLinesLoaded;
}

export class DeletePresetLines implements Action {
  readonly type = SchedulerResourceSettingActionTypes.DeletePresetLines;
  constructor(public ids: number[]) {}
}

export class DeletePresetLinesLoaded implements Action {
  readonly type = SchedulerResourceSettingActionTypes.DeletePresetLinesLoaded;
}

export class FetchError implements Action {
  readonly type = SchedulerResourceSettingActionTypes.FetchError;

  constructor(public payload: any[]) {}
}

export class LoadActivities implements Action {
  readonly type = SchedulerResourceSettingActionTypes.LoadActivities;
  constructor(public page: number = 1, public limit: number = 100, public search?: string) {}
}

export class ActivitiesLoaded implements Action {
  readonly type = SchedulerResourceSettingActionTypes.ActivitiesLoaded;
  constructor(public payload: ActivitiesInterface[]) {}
}

export class ClearSelectedPreset implements Action {
  readonly type = SchedulerResourceSettingActionTypes.ClearSelectedPreset;
}

export type SchedulerResourceSettingActions =
  | LoadSitesAndLines
  | SitesAndLinesLoaded
  | LoadPresets
  | PresetsLoaded
  | SetSelectedPreset
  | LoadSchedulerResourceSettingShiftPlans
  | SchedulerResourceSettingShiftPlansLoaded
  | GetSelectedPreset
  | GetSelectedPresetLoaded
  | LoadActivities
  | ActivitiesLoaded
  | DeleteSelectedPreset
  | DeleteSelectedPresetLoaded
  | CreatePreset
  | CreatePresetLoaded
  | CreatePresetItems
  | CreatePresetItemsLoaded
  | CreatePresetLines
  | CreatePresetLinesLoaded
  | UpdatePreset
  | UpdatePresetLoaded
  | UpdatePresetItems
  | UpdatePresetItemsLoaded
  | UpdatePresetLines
  | UpdatePresetLinesLoaded
  | DeletePresetLines
  | DeletePresetLinesLoaded
  | ClearSelectedPreset
  | FetchError;
