import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScwMatBorderColoringComponent } from './scw-mat-border-coloring.component';

@NgModule({
  imports: [CommonModule],
  exports: [ScwMatBorderColoringComponent],
  declarations: [ScwMatBorderColoringComponent],
})
export class ScwMatBorderColoringModule {}
