import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { ColorServiceHelper } from './color.helper';
import { ComponentColors, ComponentName, CustomColorParameters, CustomColors } from './color.model';

@Injectable({
  providedIn: 'root',
})
export class ColorService {
  /* c8 ignore start */
  /** @deprecated Should use {@link ColorService.pickComponentColors} instead. */
  public pickCustomColors(
    siteIds: readonly number[],
    sitesColors: Record<number, CustomColors | undefined>,
    clientColors: CustomColors | undefined,
  ): CustomColors {
    return ColorServiceHelper.pickCustomColors(siteIds, sitesColors, clientColors);
  }
  /* c8 ignore end */

  public pickComponentColors(componentName: ComponentName, params: CustomColorParameters): ComponentColors {
    const sitesColors: Record<number, CustomColors | undefined> = _.fromPairs(
      params.allSites.map((site) => [site.id, site.configuration?.colors]),
    );
    const clientColors: CustomColors | undefined = params.client?.configuration?.colors;
    const customColors: CustomColors = ColorServiceHelper.pickCustomColors(
      params.visibleSiteIds,
      sitesColors,
      clientColors,
    );
    return ColorServiceHelper.COMPONENT_COLORS[componentName](customColors);
  }
}
