import { FilterEquipmentActions, FilterEquipmentActionTypes } from './equipment.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { IEquipmentList } from '../../../shared/component/filter/filter.class';

export interface FilterEquipmentState {
  isLoading: boolean;
  isLoaded: boolean;
  data: IEquipmentList[];
  errors: HttpErrorResponse[];
}

export const initialFilterEquipmentState: FilterEquipmentState = {
  isLoaded: false,
  isLoading: false,
  data: [],
  errors: [],
};

export function filterEquipmentReducer(
  state: FilterEquipmentState = initialFilterEquipmentState,
  action: FilterEquipmentActions,
): FilterEquipmentState {
  switch (action.type) {
    case FilterEquipmentActionTypes.StartLoadFilterEquipments:
      return { ...state, isLoaded: false, isLoading: true };

    case FilterEquipmentActionTypes.FilterEquipmentsLoaded:
      return { ...state, data: action.data, isLoaded: true, isLoading: false };

    case FilterEquipmentActionTypes.ResetEquipmentStore:
      return { ...state, data: [], isLoaded: false, isLoading: false };

    case FilterEquipmentActionTypes.FetchError:
      const errors = [...state.errors, action.payload];

      return { ...state, errors, isLoaded: false, isLoading: false };

    default:
      return state;
  }
}
