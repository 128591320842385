<mat-progress-bar mode="indeterminate" [color]="'primary'" [hidden]="!isLoading$"></mat-progress-bar>
<div class="alert-pause" [ngClass]="isLoading$ ? 'cursor-progress' : ''">
  <ng-template [ngIf]="autoPilotIsActive && !showDurationButtonGroup">
    <span>{{ 'alertControl.snooze.active' | translate }}</span>
    <span class="float-right">
      <em class="far fa-pause-circle" (click)="showDurationButtons()"></em>
      <em class="far fa-stop-circle p-l-10" (click)="updateSnoozeUntil('snooze', -1)"></em>
    </span>
  </ng-template>
  <ng-template [ngIf]="autoPilotIsActive && showDurationButtonGroup">
    <div class="row">
      <div class="col-lg-5 align-self-center">
        <span>{{ 'alertControl.snooze.pause' | translate }}</span>
      </div>
      <div class="col-lg-7 text-right m-auto">
        <div class="btn-group" role="group" aria-label="Snooze Duration">
          <button
            *ngFor="let option of snoozeOptions"
            type="button"
            class="btn btn-outline-secondary"
            (click)="updateSnoozeUntil('snooze', option.duration)"
          >
            {{ option.text }}
          </button>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template [ngIf]="!autoPilotIsActive">
    <div [ngClass]="pausedDurationMessage !== null ? 'paused-duration' : ''">
      <span class="float-right">
        <em class="far fa-play-circle" (click)="updateSnoozeUntil('stop')"></em>
      </span>
      <span>
        <ng-container *ngIf="pausedDurationMessage === null; else pausedDuration">
          {{ 'alertControl.snooze.paused' | translate }}
        </ng-container>
        <ng-template #pausedDuration>
          {{ pausedDurationMessage }}
        </ng-template>
      </span>
    </div>
  </ng-template>
</div>
