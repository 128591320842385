import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as ObjectActions from './work-order.actions';
import { GetWorkOrderNumbersDataResponseInterface } from '../home/home.model';
import * as AppActions from '../app/actions';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BaseOneResponseInterface } from '../../shared/model/interface/crud-response-interface.model';
import { WorkOrderScheduleInterface } from '../work-order-schedule/work-order-schedule.model';
import { EntityTranslatorService } from '../../shared/service/entity-translator/entity-translator.service';

@Injectable()
export class WorkOrderEffects {
  constructor(
    private actions$: Actions<ObjectActions.WorkOrderActions>,
    private http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly translatorService: EntityTranslatorService,
  ) {}

  private WORKORDER_URLS = {
    GET: {
      GET_HOME_INFORMATION: '/api/get-home-information',
      WORK_ORDER_SCHEDULE: `${this.baseUrl}/work-orders`,
    },
    POST: {
      WORK_ORDER_SCHEDULE: `${this.baseUrl}/work-orders`,
    },
    PATCH: {
      WORK_ORDER_SCHEDULE: `${this.baseUrl}/work-orders`,
    },
  };

  createWorkOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.WorkOrderActionTypes.CreateWorkOrder),
      switchMap((objectData: ObjectActions.CreateWorkOrder) => {
        return this.http
          .post<GetWorkOrderNumbersDataResponseInterface>(this.WORKORDER_URLS.POST.WORK_ORDER_SCHEDULE, {
            ...objectData.payload,
          })
          .pipe(
            switchMap((response) => {
              return of(new ObjectActions.CreateWorkOrderCompleted(response['data']), new AppActions.HideLoader());
            }),
            catchError((errorRes) => {
              return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
            }),
          );
      }),
    ),
  );

  updateWorkOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.WorkOrderActionTypes.UpdateWorkOrder),
      switchMap((objectData: ObjectActions.UpdateWorkOrder) => {
        const url = `${this.WORKORDER_URLS.PATCH.WORK_ORDER_SCHEDULE}/${objectData.id}`;
        return this.http
          .patch<GetWorkOrderNumbersDataResponseInterface>(url, {
            ...objectData.payload,
          })
          .pipe(
            switchMap((response) => {
              return of(new ObjectActions.UpdateWorkOrderCompleted(response['data']), new AppActions.HideLoader());
            }),
            catchError((errorRes) => {
              return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
            }),
          );
      }),
    ),
  );

  getWorkOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.WorkOrderActionTypes.GetWorkOrder),
      switchMap((objectData: ObjectActions.GetWorkOrder) => {
        const url = `${this.WORKORDER_URLS.GET.WORK_ORDER_SCHEDULE}/${objectData.id}`;
        let params: HttpParams = new HttpParams();
        params = params.append('join', 'product');
        return this.http
          .get<BaseOneResponseInterface<WorkOrderScheduleInterface>>(url, {
            params,
          })
          .pipe(
            switchMap((response: BaseOneResponseInterface<WorkOrderScheduleInterface>) => {
              return of(new ObjectActions.GetWorkOrderCompleted(response), new AppActions.HideLoader());
            }),
            catchError((errorRes) => {
              return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
            }),
          );
      }),
    ),
  );
}
