import { Moment } from 'moment';
import { IMyReport } from '../my-reports.model';
import { IActivityLog } from '../../../../store/reports/root-cause-analysis/root-cause-analysis.model';
import {
  IMyReportMetric,
  IMyReportMetricConfiguration,
} from './my-report-metric-chart-card/my-report-metric-chart-card.model';
import { IOeeCalculationResult } from '../../../../store/reports/work-order-performance/work-order-performance.model';

export enum EMyReportMetricBreakdown {
  HOURLY = 'hourly',
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
}

export enum EMyReportMetricList {
  PRODUCTION_AMOUNTS = 'production_amounts',
  ACTIVITIES = 'activities',
  PRODUCTIVITY = 'productivity',
}

export enum EMyReportMetricProductionAmountsType {
  INITIAL = 'initial',
  GOOD = 'good',
  SCRAP = 'scrap',
}

export enum EMyReportsMetricProductivityType {
  OEE = 'oee',
  AVAILABILITY = 'availability',
  PERFORMANCE = 'performance',
  QUALITY = 'quality',
}

export enum EMyReportMetricChartType {
  BAR = 'bar',
  STACKED_BAR = 'stacked_bar',
  LINE = 'line',
}

export enum EMyReportMetricChartAxisType {
  NUMBER = 'number',
  CATEGORY = 'category',
}

export enum EMyReportMetricDataSource {
  OEE = 'oee',
  OEE_HOURLY = 'oee_hourly',
  OEE_DAILY = 'oee_daily',
  OEE_WEEKLY = 'oee_weekly',
  OEE_MONTHLY = 'oee_monthly',
  ACTIVITY_HISTORY = 'activity_history',
}

export interface IMyReportMetricFilterData {
  breakdown: EMyReportMetricBreakdown[];
  dateRange: IDateRange;
  lines: number[] | -1;
  sites: number[] | -1;
}

export interface IDateRange {
  startDate: Moment;
  endDate: Moment;
}

export interface IMyReportWithMetrics extends IMyReport {
  myReportMetrics: IMyReportMetric[];
}

export interface IMyReportMetricCreateRequestParameters {
  name: string;
  siteId: number;
  myReportId: number;
  configuration: IMyReportMetricConfiguration;
}

export interface IMyReportMetricUpdateRequestParameters extends IMyReportMetricCreateRequestParameters {
  id: number;
}

export interface IMyReportMetricsState {
  myReportMetricsData: IMyReportWithMetrics;
  myReportMetricsLoading: boolean;
  myReportMetricsLoaded: boolean;
  selectedReportName: string | null;
  requiredChartData: IRequiredChartData;
  filterData: IMyReportMetricFilterData;
  oeeCalculationDataHourly: IOeeCalculationResult[];
  oeeCalculationDataDaily: IOeeCalculationResult[];
  oeeCalculationDataWeekly: IOeeCalculationResult[];
  oeeCalculationDataMonthly: IOeeCalculationResult[];
  oeeCalculationDataHourlyLoading: boolean;
  oeeCalculationDataHourlyLoaded: boolean;
  oeeCalculationDataDailyLoading: boolean;
  oeeCalculationDataDailyLoaded: boolean;
  oeeCalculationDataWeeklyLoading: boolean;
  oeeCalculationDataWeeklyLoaded: boolean;
  oeeCalculationDataMonthlyLoading: boolean;
  oeeCalculationDataMonthlyLoaded: boolean;
}

export type TMetricListType = number | EMyReportsMetricProductivityType | EMyReportMetricProductionAmountsType;

export interface IMetricBulkOperationCompletionStatuses {
  create: boolean;
  update: boolean;
  delete: boolean;
}

export interface IMetricChartNumberData {
  data: IGenericChartData<number>[];
}

export interface IMetricChartStringData {
  data: IGenericChartData<string>[];
}

export type TMetricChartOptions = {
  numberData: TMetricChartNumberData | TMetricChartDecimalStringData;
  categoryData: IMetricChartStringData;
  configuration: IMyReportMetricConfiguration;
};

export type TMetricChartNumberData = Record<string, IMetricChartNumberData>;
export type TMetricChartDecimalStringData = Record<string, IMetricChartStringData>;
export type TMetricChartCategoryData = Record<string, IMetricChartStringData>;

export interface IGenericChartData<T> {
  value: T;
  name?: string;
  nameLabel?: string;
  categoryName?: string;
}

export type TGroupedActivities = Record<string, IActivityLog[]>;

export interface IRequiredChartData {
  breakdownHourly: ITimedBreakdownInfo;
  breakdownDaily: ITimedBreakdownInfo;
  breakdownWeekly: ITimedBreakdownInfo;
  breakdownMonthly: ITimedBreakdownInfo;
  selectedBreakdown: EMyReportMetricBreakdown;
  userDateFormat: string;
  groupedActivitiesDataByActivityId: TGroupedActivities;
  oeeCalculationDataHourly: IOeeCalculationResult[];
  oeeCalculationDataDaily: IOeeCalculationResult[];
  oeeCalculationDataWeekly: IOeeCalculationResult[];
  oeeCalculationDataMonthly: IOeeCalculationResult[];
}

export interface ITimedBreakdownInfo {
  momentDates: Moment[];
  momentDatesFormatted: TFormattedBreakdownDate[];
}

export type TFormattedBreakdownDate = { value: string };

export type TEchartsSeries = {
  type: EMyReportMetricChartType;
  data: IGenericChartData<number>[];
  stack: string;
  barMaxWidth: number;
  label: IMetricChartSeriesLabel;
  emphasis?: Record<string, IMetricChartSeriesLabel>;
};

export interface IMetricChartSeriesLabel {
  show: boolean;
  rotate?: number;
  formatter?: echarts.LabelFormatterCallback;
}

export interface IMetricDataSourceLoadStatus {
  loading: boolean;
  loaded: boolean;
}

export interface IFormatterParam<T> {
  value: T;
  data: IGenericChartData<T>;
}

export type TNumberDataFormatterCallback = (item: IFormatterParam<number>) => string;

export enum ETargetRange {
  ONE_WEEK = 'one_week',
  ONE_MONTH = 'one_month',
}
