import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { DepartmentService } from '../../../shared/service/filter/department.service';
import * as ObjectActions from './department.actions';
import { generateHttpOption } from '../../../shared/helper/app-helper';
import { LineTypeNameInterface } from '../../../shared/component/filter/filter.class';

@Injectable()
export class FilterDepartmentEffects {
  constructor(
    private readonly actions$: Actions<ObjectActions.FilterDepartmentActions>,
    public departmentService: DepartmentService,
  ) {}

  loadFilterSites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.FilterDepartmentActionTypes.StartLoadFilterDepartments),
      switchMap((objectData: ObjectActions.StartLoadFilterDepartments) => {
        let params = generateHttpOption(objectData.options);
        params = params.delete('limit').set('limit', '1000');
        return from(this.departmentService.getData(params)).pipe(
          switchMap((response: LineTypeNameInterface[]) => {
            return of(new ObjectActions.FilterDepartmentsLoaded(response));
          }),
          catchError(() => {
            return of(new ObjectActions.FilterDepartmentsLoaded([]));
          }),
        );
      }),
    ),
  );
}
