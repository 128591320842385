import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { forkJoin, Observable, Subject } from 'rxjs';
import {
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../model/interface/crud-response-interface.model';
import * as _ from 'lodash';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment-timezone';
import * as oeeAppReducer from '../../../../store/oee.reducer';
import {
  EquipmentInterface,
  EquipmentsResponseInterface,
  IEquipmentAssignment,
  IEquipmentAssignmentBulkSaveMany,
  IEquipmentAssignmentDownloadExcelFilters,
  LineInterface,
  SiteInterface,
  TEquipmentAssignmentObservable,
} from '../../../../store/settings/equipment-assignment/equipment-assignment.model';
import { ValueType, Workbook, Worksheet } from 'exceljs';
import * as ObjectActions from '../../../../store/settings/equipment-assignment/equipment-assignment.actions';
import { LinesResponseInterface } from '../../../../store/work-order-schedule/work-order-schedule.model';
import {
  CellTypes,
  CreateExcelInterface,
  CreateExcelSheetInterface,
  ExcelColumnWidthEnum,
  ExcelHelperService,
  ExcelSheetTypeEnum,
} from '../../excel/excel-helper.service';
import { excelDateFormat, excelTimeFormat } from '../../../model/enum/excel-date-format';
import { takeUntil } from 'rxjs/operators';
import { HelperService } from '../../helper.service';

@Injectable({
  providedIn: 'root',
})
export class EquipmentAssignmentService {
  private readonly EQUIPMENT_ASSIGNMENT = {
    SITE_URL: `${this.baseUrl}/sites`,
    LINE_URL: `${this.baseUrl}/lines`,
    EQUIPMENT_LIST_URL: `${this.baseUrl}/equipment-lists`,
    EQUIPMENT_ASSIGNMENT_URL: `${this.baseUrl}/equipment-assignments`,
    EQUIPMENT_ASSIGNMENT_BULK_SAVE_URL: `${this.baseUrl}/equipment-assignments/bulk/save`,
  };
  private timezone: string = 'utc';
  private dateFormat$: string;
  private timeFormat$: string;
  private readonly destroySubject: Subject<boolean> = new Subject<boolean>();

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly excelHelper: ExcelHelperService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly translate: TranslateService,
    private readonly helperService: HelperService,
  ) {
    this.store
      .select('user')
      .pipe(takeUntil(this.destroySubject))
      .subscribe((state) => {
        if (state.isUserLoaded) {
          this.timezone = state.timezone;

          if (state.locale !== '') {
            this.dateFormat$ = excelDateFormat[state.locale];
            this.timeFormat$ = excelTimeFormat[state.locale];
          }

          this.destroySubject.next(true);
          this.destroySubject.complete();
        }
      });
  }

  public patchEquipmentAssignmentData(
    id: number,
    payload: Partial<IEquipmentAssignment>,
    params?: HttpParams,
  ): Observable<GetManyResponseInterface<IEquipmentAssignment>> {
    return this.http.patch<GetManyResponseInterface<IEquipmentAssignment>>(
      `${this.EQUIPMENT_ASSIGNMENT.EQUIPMENT_ASSIGNMENT_URL}/${id}`,
      payload,
      { params },
    );
  }

  public patchEquipmentAssignmentsData(
    payload: Partial<IEquipmentAssignment>[],
    params?: HttpParams,
  ): Observable<BulkResponseDataInterface> {
    return this.http.patch<BulkResponseDataInterface>(
      `${this.EQUIPMENT_ASSIGNMENT.EQUIPMENT_ASSIGNMENT_URL}/bulk/edit`,
      { equipmentAssignments: payload },
      { params },
    );
  }

  public createEquipmentAssignment(
    payload: Partial<IEquipmentAssignment>,
  ): Observable<GetManyResponseInterface<IEquipmentAssignment>> {
    return this.http.post<GetManyResponseInterface<IEquipmentAssignment>>(
      this.EQUIPMENT_ASSIGNMENT.EQUIPMENT_ASSIGNMENT_URL,
      payload,
    );
  }

  public deleteEquipmentAssignment(id: number): Observable<GetManyResponseInterface<IEquipmentAssignment>> {
    return this.http.delete<GetManyResponseInterface<IEquipmentAssignment>>(
      `${this.EQUIPMENT_ASSIGNMENT.EQUIPMENT_ASSIGNMENT_URL}/${id}`,
    );
  }

  public deleteEquipmentAssignments(ids: number[]): Observable<BulkResponseDataInterface> {
    return this.http.delete<BulkResponseDataInterface>(
      `${this.EQUIPMENT_ASSIGNMENT.EQUIPMENT_ASSIGNMENT_URL}/bulk/delete`,
      {
        body: { equipmentAssignments: ids },
      },
    );
  }

  public getEquipmentAssignment(params: HttpParams): Observable<GetManyResponseInterface<IEquipmentAssignment>> {
    return this.http.get<GetManyResponseInterface<IEquipmentAssignment>>(
      this.EQUIPMENT_ASSIGNMENT.EQUIPMENT_ASSIGNMENT_URL,
      { params },
    );
  }

  public getSite(id: number): Observable<SiteInterface> {
    return this.http.get<SiteInterface>(`${this.EQUIPMENT_ASSIGNMENT.SITE_URL}/${id}?fields=id,name`);
  }

  public getLines(params: HttpParams): Observable<LinesResponseInterface> {
    return this.http.get<LinesResponseInterface>(`${this.EQUIPMENT_ASSIGNMENT.LINE_URL}`, { params });
  }

  public getEquipmentLists(params: HttpParams): Observable<EquipmentsResponseInterface> {
    return this.http.get<EquipmentsResponseInterface>(`${this.EQUIPMENT_ASSIGNMENT.EQUIPMENT_LIST_URL}`, { params });
  }

  private getEquipmentAssignmentObservables(
    withData: boolean,
    filters: IEquipmentAssignmentDownloadExcelFilters,
    data?: IEquipmentAssignment[],
  ): TEquipmentAssignmentObservable {
    const siteFilter = { siteId: { $eq: String(filters.siteId) } };
    const httpParams: HttpParams = new HttpParams()
      .set('s', JSON.stringify(siteFilter))
      .set('limit', String(filters.limit));
    const observables: TEquipmentAssignmentObservable = [
      this.getSite(filters.siteId),
      this.getLines(httpParams.set('limit', '1000')),
      this.getEquipmentLists(httpParams.set('limit', '1000')),
    ];

    if (withData && !data) {
      observables.push(
        this.getEquipmentAssignment(
          httpParams
            .set('page', filters.selectedDownloadOffset)
            .append('join', 'line||title')
            .append('join', 'equipment||equipmentName'),
        ),
      );
    }

    return observables;
  }

  public downloadEquipmentAssignmentExcel(
    filters: IEquipmentAssignmentDownloadExcelFilters,
    data?: IEquipmentAssignment[],
    options = { withData: false, withErrorColumn: false },
  ): void {
    forkJoin(this.getEquipmentAssignmentObservables(options.withData, filters, data)).subscribe((responseList) => {
      const site: SiteInterface = _.get(responseList, '0.data');
      const lines: LineInterface[] = _.get(responseList, '1.data', []).sort(
        this.helperService.dropdownOptionCompareFunction,
      );
      const equipments: EquipmentInterface[] = _.get(responseList, '2.data', []);
      const sheetTitle: string = this.translate.instant('pageTitles.equipmentAssignment');
      const excelName: string = `${sheetTitle} ${moment()
        .tz(this.timezone)
        .format(this.dateFormat$)}`;
      let excelData: IEquipmentAssignment[] = EquipmentAssignmentService.getExcelData(
        options.withData,
        responseList,
        site,
      );
      const excelOptions: CreateExcelInterface = this.getEquipmentAssignmentExcelColumns(
        site,
        lines,
        equipments,
        options.withErrorColumn,
      );

      if (options.withData) {
        excelData = data ? this.getExcelErrorData(site, lines, equipments, data) : excelData;
        excelOptions.data = excelData;
      }

      const worksheets: CreateExcelSheetInterface[] = [
        {
          sheetTitle,
          sheetType: ExcelSheetTypeEnum.TABLE,
          params: excelOptions,
          withData: options.withData,
          isDisabledColumnsFirstLine: true,
          addDateTimeFormula: false,
        },
      ];

      this.excelHelper
        .createExcel(excelName, worksheets, this.timezone, this.dateFormat$, this.timeFormat$, false)
        .then(
          () => {
            this.store.dispatch(new ObjectActions.DownloadEquipmentAssignmentExcelCompleted());
          },
          () => {
            this.store.dispatch(new ObjectActions.FetchError({}));
          },
        );
    });
  }

  private static getExcelData(withData: boolean, observableResponseList, site: SiteInterface): IEquipmentAssignment[] {
    let excelData: IEquipmentAssignment[] = [];

    if (withData) {
      excelData = _.get(observableResponseList, '3.data', []);

      for (const equipmentAssignment of excelData) {
        equipmentAssignment.site = site && site.id === equipmentAssignment.siteId ? site : undefined;
      }
    }

    return excelData;
  }

  private getExcelErrorData(
    site: SiteInterface,
    lines: LineInterface[],
    equipments: EquipmentInterface[],
    data?: IEquipmentAssignment[],
  ): IEquipmentAssignment[] {
    for (const equipmentAssignment of data) {
      equipmentAssignment.site = site && site.id === equipmentAssignment.siteId ? site : undefined;
      equipmentAssignment.line = lines.find((line: LineInterface) => line.id === equipmentAssignment.lineId);
      equipmentAssignment.equipment = equipments.find(
        (equipment: EquipmentInterface) => equipment.id === equipmentAssignment.equipmentId,
      );
    }

    return data;
  }

  private getEquipmentAssignmentExcelColumns(
    site: SiteInterface,
    lines: LineInterface[],
    equipments: EquipmentInterface[],
    withErrorColumn: boolean,
  ): CreateExcelInterface {
    const excelColumns: CreateExcelInterface = {
      columns: [
        {
          header: this.translate.instant('general.excel.column.siteName'),
          key: 'siteId',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: [site],
            prop: 'site.name',
            dataProperty: 'site.name',
            dataId: 'site.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
            allowBlank: false,
            showErrorMessage: true,
            formulae: [],
            errorStyle: 'Error',
            showInputMessage: true,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('general.excel.column.line'),
          key: 'lineId',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: lines,
            prop: 'line.title',
            dataProperty: 'line.title',
            dataId: 'line.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
            allowBlank: false,
            showErrorMessage: true,
            formulae: [],
            errorStyle: 'Error',
            showInputMessage: true,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('general.excel.column.equipmentName'),
          key: 'equipmentId',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.Number,
          dropdownOptions: {
            data: equipments,
            prop: 'equipment.equipmentName',
            dataProperty: 'equipment.equipmentName',
            dataId: 'equipment.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
            allowBlank: false,
            showErrorMessage: true,
            formulae: [],
            errorStyle: 'Error',
            showInputMessage: true,
          },
          isRequired: true,
        },
        {
          header: 'id',
          key: 'id',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          dataValidation: {
            type: CellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('general.excel.column.order'),
          key: 'orderNumber',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.Number,
          style: { numFmt: '0' },
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            formulae: [],
            errorStyle: 'Error',
            showInputMessage: true,
            promptTitle: this.translate.instant('general.excel.column.promptNumberInputTitle', {
              field: this.translate.instant('general.excel.column.order'),
            }),
            prompt: this.translate.instant('general.excel.column.promptNumberInput', {
              field: this.translate.instant('general.excel.column.order'),
            }),
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('general.excel.column.startDate'),
          key: 'startDate',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.Date,
          isDateTimeFormat: true,
          style: { numFmt: '@' },
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            formulae: [null],
            showInputMessage: true,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('general.excel.column.endDate'),
          key: 'endDate',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.Date,
          isDateTimeFormat: true,
          style: { numFmt: '@' },
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            formulae: [null],
            showInputMessage: true,
          },
          isRequired: true,
        },
      ],
    };

    this.excelHelper.prepareExcelColumns(excelColumns.columns, withErrorColumn);

    return excelColumns;
  }

  public uploadExcel(equipmentAssignment: IEquipmentAssignmentBulkSaveMany): Observable<BulkResponseDataInterface> {
    return this.http.post<BulkResponseDataInterface>(
      this.EQUIPMENT_ASSIGNMENT.EQUIPMENT_ASSIGNMENT_BULK_SAVE_URL,
      equipmentAssignment,
    );
  }

  public async getEquipmentAssignmentFromExcel(
    file: File,
  ): Promise<{
    siteData: SiteInterface[];
    equipmentAssignmentData: { equipmentAssignments: IEquipmentAssignment[] };
  } | null> {
    const workbook: Workbook = await this.excelHelper.getExcelWorkBookFromFile(file);
    const equipmentAssignmentSheet: Worksheet = workbook.getWorksheet(
      this.translate.instant('pageTitles.equipmentAssignment'),
    );
    const siteIdDataSheet: Worksheet = workbook.getWorksheet('siteIdDataSheet');
    const lineIdDataSheet: Worksheet = workbook.getWorksheet('lineIdDataSheet');
    const equipmentIdDataSheet: Worksheet = workbook.getWorksheet('equipmentIdDataSheet');

    if (!equipmentAssignmentSheet || !siteIdDataSheet || !lineIdDataSheet || !equipmentIdDataSheet) {
      return null;
    }

    const siteColumns = {
      id: {
        key: 'id',
        type: ValueType.String,
        dataValidationType: CellTypes.CUSTOM,
      },
      name: {
        key: 'name',
        type: ValueType.String,
        dataValidationType: CellTypes.CUSTOM,
      },
    };

    const sites: SiteInterface[] = this.excelHelper.getExcelRowsFromWorkSheet<SiteInterface>(
      siteIdDataSheet,
      siteColumns,
    );

    if (!sites.length) {
      return null;
    }

    const { columns } = this.getEquipmentAssignmentExcelColumns(null, null, null, false);
    const columnKeys = this.excelHelper.getSheetColumnKeys(columns);

    return {
      equipmentAssignmentData: {
        equipmentAssignments: this.excelHelper.getExcelRowsFromWorkSheet<IEquipmentAssignment>(
          equipmentAssignmentSheet,
          columnKeys,
          {
            dateFormat: this.dateFormat$,
            timeFormat: this.timeFormat$,
            timezone: this.timezone,
          },
        ),
      },
      siteData: sites,
    };
  }
}
