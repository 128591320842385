import { ActivitiesStateInterface } from './activities.model';
import * as Actions from './activities.actions';

export const activityState: ActivitiesStateInterface = {
  isActivitiesDataLoading: false,
  isActivitiesDataLoaded: false,
  activitiesData: null,
  isActivityTypesLoading: false,
  isActivityTypesLoaded: false,
  activityTypes: [],
  isActivitySubTypesLoading: false,
  isActivitySubTypesLoaded: false,
  activitySubTypes: [],
  totalActivitiesDataCount: 0,
  fileDownloadStatus: false,
  excelUploadResult: [],
  isUploadExcelLoading: false,
  isUploadExcelLoaded: false,
};

export function activityReducer(
  state: ActivitiesStateInterface = activityState,
  action: Actions.ActivitiesActions,
): ActivitiesStateInterface {
  switch (action.type) {
    case Actions.ACTIVITIES_GOT_DATA_COUNT:
      return {
        ...state,
        totalActivitiesDataCount: action.payload.total,
      };

    case Actions.LOAD_ACTIVITY_TYPES:
      return {
        ...state,
        isActivityTypesLoading: true,
        isActivityTypesLoaded: false,
      };

    case Actions.LOADED_ACTIVITY_TYPES:
      return {
        ...state,
        isActivityTypesLoading: false,
        isActivityTypesLoaded: true,
        activityTypes: action.payload.data,
      };

    case Actions.LOAD_ACTIVITY_SUBTYPES:
      return {
        ...state,
        isActivitySubTypesLoading: true,
        isActivitySubTypesLoaded: false,
      };

    case Actions.LOADED_ACTIVITY_SUBTYPES:
      return {
        ...state,
        isActivitySubTypesLoading: false,
        isActivitySubTypesLoaded: true,
        activitySubTypes: action.payload.data,
      };

    case Actions.LOAD_ACTIVITIES_DATA:
      return {
        ...state,
        isActivitiesDataLoading: true,
        isActivitiesDataLoaded: false,
      };

    case Actions.LOADED_ACTIVITIES_DATA:
      return {
        ...state,
        isActivitiesDataLoading: false,
        isActivitiesDataLoaded: true,
        activitiesData: action.payload,
      };

    case Actions.DOWNLOAD_ACTIVITY_EXCEL:
      return {
        ...state,
        ...{
          fileDownloadStatus: true,
        },
      };
    case Actions.DOWNLOAD_ACTIVITY_EXCEL_COMPLETED:
      return {
        ...state,
        ...{
          fileDownloadStatus: false,
        },
      };
    case Actions.UPLOAD_ACTIVITY_EXCEL:
      return {
        ...state,
        ...{
          isUploadExcelLoading: true,
          isUploadExcelLoaded: false,
        },
      };
    case Actions.UPLOAD_ACTIVITY_EXCEL_COMPLETED:
      return {
        ...state,
        ...{
          excelUploadResult: action.payload,
          isUploadExcelLoading: false,
          isUploadExcelLoaded: true,
        },
      };
    default:
      return state;
  }
}
