import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { getApiUrl } from './utilities';
import * as _ from 'lodash';

interface TranslationResponseInterface {
  data: {
    [key: string]: string;
  }
  success: boolean;
  date: string;
}

@Injectable()
export class CustomTranslateLoader implements TranslateLoader {
  private readonly baseURL: string = getApiUrl();
  private readonly solution: string = 'df-an';

  constructor(private http: HttpClient) {}

  getTranslation(language: string): Observable<any> {
    const APIAddress = `${this.baseURL}/translations`;
    const params = {
      language,
      solution: this.solution,
    };
    const headers = new HttpHeaders().set('scw-skip-version-check', 'true');

    return this.http.get<TranslationResponseInterface>(APIAddress, { params, headers }).pipe(
      switchMap((response) => {
        const { data, success } = response;
        const translations = {};

        if (success) {
          for (const key in data) {
            _.set(translations, key, _.get(data, key, null));
          }

          return of(translations);
        } else {
          return of({});
        }
      }),
      catchError(() => {
        return of({});
      }),
    );
  }
}
