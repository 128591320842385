import { WorkOrderActions, WorkOrderActionTypes } from './work-order.actions';
import { WorkOrderStateInterface } from './work-order.model';

export const workOrderInitialState: WorkOrderStateInterface = {
  errors: [],
  createWorkOrderLoading: false,
  createWorkOrderCompleted: false,
  updateWorkOrderLoading: false,
  updateWorkOrderCompleted: false,
  workOrderData: null,
};

export function workOrderReducer(
  state = workOrderInitialState,
  action: WorkOrderActions,
): WorkOrderStateInterface {
  switch (action.type) {
    case WorkOrderActionTypes.CreateWorkOrder:
      return {
        ...state,
        createWorkOrderLoading: true,
        createWorkOrderCompleted: false,
      };
    case WorkOrderActionTypes.CreateWorkOrderCompleted:
      return {
        ...state,
        createWorkOrderLoading: false,
        createWorkOrderCompleted: true,
        workOrderData: action.response,
      };
    case WorkOrderActionTypes.UpdateWorkOrder:
      return {
        ...state,
        updateWorkOrderLoading: true,
        updateWorkOrderCompleted: false,
      };
    case WorkOrderActionTypes.UpdateWorkOrderCompleted:
      return {
        ...state,
        updateWorkOrderLoading: false,
        updateWorkOrderCompleted: true,
        workOrderData: action.response,
      };
    case WorkOrderActionTypes.FetchError:
      return {
        ...state,
        errors: action.payload,
      };

    default:
      return state;
  }
}
