import { Action } from '@ngrx/store';
import { SiteCRUDInterface } from '../../../shared/component/filter/filter.class';
import {
  AddProductInterface,
  AddProductResponseInterface,
  BulkEditProductInterface,
  CustomerInterface,
  EditProductInterface,
  EditProductResponseInterface,
  IProductsFilters,
  ProductBulkSaveManyInterface,
  ProductInterface,
  ProductsDownloadExcelFiltersInterface,
  ProductTableQueryParams,
} from './products.model';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { TabRowInterface } from '../../../shared/component/side-config-bar/side-config-bar.model';
import { MergedArrayDataInterface } from '../../../../constants.model';

export const PRODUCTS_SITES_DATA_LOADING = '[PRODUCTS] PRODUCTS ACTION SITES DATA LOADING';
export const PRODUCTS_SITES_DATA_LOADED = '[PRODUCTS] PRODUCTS ACTION SITES DATA LOADED';
export const PRODUCTS_PRODUCT_DATA_LOADING = '[PRODUCTS] PRODUCTS ACTION PRODUCT DATA LOADING';
export const PRODUCTS_PRODUCT_DATA_LOADED = '[PRODUCTS] PRODUCTS ACTION PRODUCT DATA LOADED';
export const PRODUCTS_DATA_LOADING = '[PRODUCTS] PRODUCTS DATA LOADING';
export const PRODUCTS_DATA_LOADED = '[PRODUCTS] PRODUCTS DATA LOADED';
export const DOWNLOAD_PRODUCT_EXCEL = '[PRODUCTS] PRODUCTS ACTION DOWNLOAD PRODUCT EXCEL';
export const DOWNLOAD_PRODUCT_EXCEL_COMPLETED = '[PRODUCTS] PRODUCTS ACTION DOWNLOAD PRODUCT EXCEL COMPLETED';
export const UPLOAD_PRODUCT_EXCEL = '[PRODUCTS] PRODUCTS ACTION UPLOAD PRODUCT EXCEL';
export const UPLOAD_PRODUCT_EXCEL_COMPLETED = '[PRODUCTS] PRODUCTS ACTION UPLOAD PRODUCT EXCEL COMPLETED';
export const PRODUCTS_FETCH_ERROR = '[PRODUCTS] PRODUCTS ACTION FETCH ERROR';
export const DELETE_PRODUCTS = '[PRODUCTS] PRODUCTS ACTION DELETE PRODUCTS';
export const DELETE_PRODUCTS_COMPLETED = '[PRODUCTS] PRODUCTS ACTION DELETE PRODUCTS COMPLETED';
export const PRODUCTS_CUSTOMER_DATA_LOADING = '[PRODUCTS] PRODUCTS ACTION CUSTOMER DATA LOADING';
export const PRODUCTS_CUSTOMER_DATA_LOADED = '[PRODUCTS] PRODUCTS ACTION CUSTOMER DATA LOADED';
export const ADD_PRODUCT = '[PRODUCTS] ADD PRODUCT';
export const ADD_PRODUCT_COMPLETED = '[PRODUCTS] ADD PRODUCT COMPLETED';
export const EDIT_PRODUCT = '[PRODUCTS] EDIT PRODUCT';
export const EDIT_PRODUCT_COMPLETED = '[PRODUCTS] EDIT PRODUCT COMPLETED';
export const BULK_EDIT_PRODUCT = '[PRODUCTS] BULK EDIT PRODUCT';
export const BULK_EDIT_PRODUCT_COMPLETED = '[PRODUCTS] BULK EDIT PRODUCT COMPLETED';
export const SET_SELECTED_FILTERS = '[PRODUCTS] Set Selected Filters';
export const SET_TABLE_SETTINGS = '[PRODUCTS] SET TABLE SETTINGS';

export class ProductsSiteDataLoading implements Action {
  readonly type = PRODUCTS_SITES_DATA_LOADING;

  constructor() {}
}

export class ProductsSiteDataLoaded implements Action {
  readonly type = PRODUCTS_SITES_DATA_LOADED;

  constructor(public payload: ({ id: number; name: string } & Partial<SiteCRUDInterface>)[]) {}
}

export class DownloadProductExcel implements Action {
  readonly type = DOWNLOAD_PRODUCT_EXCEL;

  constructor(
    public withData: boolean = false,
    public filters: ProductsDownloadExcelFiltersInterface,
    public tableQuery: ProductTableQueryParams,
  ) {}
}

export class DownloadProductExcelCompleted implements Action {
  readonly type = DOWNLOAD_PRODUCT_EXCEL_COMPLETED;
}

export class UploadProductExcel implements Action {
  readonly type = UPLOAD_PRODUCT_EXCEL;

  constructor(public products: ProductBulkSaveManyInterface) {}
}

export class UploadProductExcelCompleted implements Action {
  readonly type = UPLOAD_PRODUCT_EXCEL_COMPLETED;

  constructor(public payload: (ProductInterface & { errorMessages?: string })[]) {}
}

export class ProductsDataLoading implements Action {
  readonly type = PRODUCTS_DATA_LOADING;

  constructor(
    public query: ProductTableQueryParams = {
      page: 1,
      pageSize: 10,
    },
  ) {}
}

export class ProductsDataLoaded implements Action {
  readonly type = PRODUCTS_DATA_LOADED;

  constructor(public payload) {}
}

export class DeleteProducts implements Action {
  readonly type = DELETE_PRODUCTS;
  constructor(public payload: number[]) {}
}

export class DeleteProductsCompleted implements Action {
  readonly type = DELETE_PRODUCTS_COMPLETED;
  constructor(public isBulk: boolean, public response: MergedArrayDataInterface[]) {}
}

export class ProductsCustomerDataLoading implements Action {
  readonly type = PRODUCTS_CUSTOMER_DATA_LOADING;
  constructor(public siteId: number) {}
}

export class ProductsCustomerDataLoaded implements Action {
  readonly type = PRODUCTS_CUSTOMER_DATA_LOADED;

  constructor(public payload: GetManyResponseInterface<CustomerInterface>) {}
}

export class SetSelectedFilters implements Action {
  readonly type = SET_SELECTED_FILTERS;
  constructor(public filters: IProductsFilters) {}
}

export class AddProduct implements Action {
  readonly type = ADD_PRODUCT;
  constructor(public product: AddProductInterface) {}
}

export class AddProductCompleted implements Action {
  readonly type = ADD_PRODUCT_COMPLETED;
  constructor(public payload: AddProductResponseInterface) {}
}

export class EditProduct implements Action {
  readonly type = EDIT_PRODUCT;
  constructor(public product: EditProductInterface, public productId: number) {}
}

export class EditProductCompleted implements Action {
  readonly type = EDIT_PRODUCT_COMPLETED;
  constructor(public payload: EditProductResponseInterface) {}
}

export class BulkEditProduct implements Action {
  readonly type = BULK_EDIT_PRODUCT;
  constructor(public products: BulkEditProductInterface[]) {}
}

export class BulkEditProductCompleted implements Action {
  readonly type = BULK_EDIT_PRODUCT_COMPLETED;
  constructor(public payload: MergedArrayDataInterface[]) {}
}

export class FetchError implements Action {
  readonly type = PRODUCTS_FETCH_ERROR;
  constructor(public payload: object) {}
}

export class SetTableSettings implements Action {
  readonly type = SET_TABLE_SETTINGS;
  constructor(public payload: TabRowInterface[], public setAsDefault: boolean) {}
}

export type ProductsActions =
  | ProductsSiteDataLoading
  | ProductsSiteDataLoaded
  | DownloadProductExcel
  | DownloadProductExcelCompleted
  | UploadProductExcel
  | UploadProductExcelCompleted
  | ProductsDataLoaded
  | ProductsDataLoading
  | DeleteProducts
  | SetSelectedFilters
  | DeleteProductsCompleted
  | ProductsCustomerDataLoading
  | ProductsCustomerDataLoaded
  | AddProduct
  | AddProductCompleted
  | EditProduct
  | EditProductCompleted
  | BulkEditProduct
  | BulkEditProductCompleted
  | SetTableSettings
  | FetchError;
