import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ScwMatRatingComponent } from './scw-mat-rating.component';

@NgModule({
  imports: [CommonModule, FormsModule],
  exports: [ScwMatRatingComponent],
  declarations: [ScwMatRatingComponent],
})
export class ScwMatRatingModule {}
