import { Action } from '@ngrx/store';
import {
  ProductSpeedAnalysisFilterInterface,
  ProductSpeedAnalysisInterface,
  SetProductSpeedInterface,
  SetProductSpeedResponseInterface,
} from './product-speed-analysis.model';

export const PRODUCT_SPEED_ANALYSIS_DATA_LOADING =
  '[PRODUCT SPEED ANALYSIS] PRODUCT SPEED ANALYSIS ACTION DATA LOADING';
export const PRODUCT_SPEED_ANALYSIS_DATA_LOADED = '[PRODUCT SPEED ANALYSIS] PRODUCT SPEED ANALYSIS ACTION DATA LOADED';
export const FETCH_ERROR = '[PRODUCT SPEED ANALYSIS] PRODUCT SPEED ANALYSIS FETCH ERROR';
export const PRODUCT_SPEED_ANALYSIS_SET_PRODUCT_SPEED_LOAD =
  '[PRODUCT SPEED ANALYSIS SET PRODUCT SPEED] PRODUCT SPEED ANALYSIS SET PRODUCT SPEED LOAD';
export const PRODUCT_SPEED_ANALYSIS_SET_PRODUCT_SPEED_LOADED =
  '[PRODUCT SPEED ANALYSIS SET PRODUCT SPEED] PRODUCT SPEED ANALYSIS SET PRODUCT SPEED LOADED';
export const PRODUCT_SPEED_ANALYSIS_RESET = '[PRODUCT SPEED ANALYSIS SET PRODUCT SPEED] PRODUCT SPEED ANALYSIS RESET';

export class ProductSpeedAnalysisDataLoading implements Action {
  readonly type = PRODUCT_SPEED_ANALYSIS_DATA_LOADING;

  constructor(public productSpeedAnalysisFilter: ProductSpeedAnalysisFilterInterface) {}
}

export class ProductSpeedAnalysisSetProductSpeedLoading implements Action {
  readonly type = PRODUCT_SPEED_ANALYSIS_SET_PRODUCT_SPEED_LOAD;

  constructor(public productSpeed: SetProductSpeedInterface) {}
}

export class ProductSpeedAnalysisSetProductSpeedLoaded implements Action {
  readonly type = PRODUCT_SPEED_ANALYSIS_SET_PRODUCT_SPEED_LOADED;

  constructor(public productSpeed: SetProductSpeedResponseInterface) {}
}

export class ProductSpeedAnalysisDataLoaded implements Action {
  readonly type = PRODUCT_SPEED_ANALYSIS_DATA_LOADED;

  constructor(public payload: ProductSpeedAnalysisInterface[]) {}
}

export class ProductSpeedAnalysisReset implements Action {
  readonly type = PRODUCT_SPEED_ANALYSIS_RESET;
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;

  constructor(public payload: any[]) {}
}

export type ProductSpeedAnalysisActions =
  | ProductSpeedAnalysisDataLoading
  | ProductSpeedAnalysisDataLoaded
  | ProductSpeedAnalysisReset
  | FetchError;
