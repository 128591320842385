import { Action } from '@ngrx/store';
import {
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { IEquipmentTypesRequestParams } from '../../../view/settings/equipment-assets/equipment-types/equipment-types.model';
import { IEquipmentType } from './equipment-types.model';
import { SiteCRUDInterface } from '../../../shared/component/filter/filter.class';
import { TagInterface } from '../tags/tags.model';

export const LOAD_EQUIPMENT_TYPES_DATA = '[EQUIPMENT TYPES] LOAD EQUIPMENT TYPES DATA';
export const EQUIPMENT_TYPES_DATA_LOADED = '[EQUIPMENT TYPES] EQUIPMENT TYPES DATA LOADED';
export const LOAD_SITE_DATA = '[EQUIPMENT TYPES] LOAD SITE DATA';
export const SITE_DATA_LOADED = '[EQUIPMENT TYPES] SITE DATA LOADED';
export const LOAD_TAGS_DATA = '[EQUIPMENT TYPES] LOAD TAGS DATA';
export const TAGS_DATA_LOADED = '[EQUIPMENT TYPES] TAGS DATA LOADED';
export const SINGLE_EDIT_EQUIPMENT_TYPES_DATA = '[EQUIPMENT TYPES] REQUEST SINGLE EDIT EQUIPMENT TYPES DATA';
export const EDITED_SINGLE_EDIT_EQUIPMENT_TYPES_DATA = '[EQUIPMENT TYPES] EDITED SINGLE EDIT EQUIPMENT TYPES DATA';
export const EDIT_EQUIPMENT_TYPES_DATA = '[EQUIPMENT TYPES] REQUEST EDIT EQUIPMENT TYPES DATA';
export const EDITED_EQUIPMENT_TYPES_DATA = '[EQUIPMENT TYPES] EDITED EQUIPMENT TYPES DATA';
export const CREATE_EQUIPMENT_TYPE = '[EQUIPMENT TYPES] CREATE EQUIPMENT TYPE DATA';
export const CREATED_EQUIPMENT_TYPE = '[EQUIPMENT TYPES] CREATED EQUIPMENT TYPE DATA';
export const DELETE_EQUIPMENT_TYPE = '[EQUIPMENT TYPES] DELETE EQUIPMENT TYPE';
export const DELETED_EQUIPMENT_TYPE = '[EQUIPMENT TYPES] DELETED EQUIPMENT TYPE';
export const DELETE_EQUIPMENT_TYPES = '[EQUIPMENT TYPES] DELETE EQUIPMENT TYPES';
export const DELETED_EQUIPMENT_TYPES = '[EQUIPMENT TYPES] DELETED EQUIPMENT TYPES';
export const EQUIPMENT_TYPES_FETCH_ERROR = '[EQUIPMENT TYPES] EQUIPMENT TYPES ACTION FETCH ERROR';

export class LoadEquipmentTypesData implements Action {
  readonly type = LOAD_EQUIPMENT_TYPES_DATA;

  constructor(public requestParams: IEquipmentTypesRequestParams) {}
}

export class LoadedEquipmentTypesData implements Action {
  readonly type = EQUIPMENT_TYPES_DATA_LOADED;

  constructor(public payload: GetManyResponseInterface<IEquipmentType>) {}
}

export class LoadSiteData implements Action {
  readonly type = LOAD_SITE_DATA;
}

export class LoadedSiteData implements Action {
  readonly type = SITE_DATA_LOADED;

  constructor(public payload: GetManyResponseInterface<SiteCRUDInterface>) {}
}

export class LoadTagsData implements Action {
  readonly type = LOAD_TAGS_DATA;

  constructor(public siteId?: number, public searchText?: string, public tagIds?: number[]) {}
}

export class LoadedTagsData implements Action {
  readonly type = TAGS_DATA_LOADED;

  constructor(public payload: GetManyResponseInterface<TagInterface>) {}
}

export class SingleEditEquipmentTypesData implements Action {
  readonly type = SINGLE_EDIT_EQUIPMENT_TYPES_DATA;

  constructor(public equipmentId: number, public payload: Partial<IEquipmentType>) {}
}

export class EditedSingleEditEquipmentTypesData implements Action {
  readonly type = EDITED_SINGLE_EDIT_EQUIPMENT_TYPES_DATA;

  constructor(public response: GetManyResponseInterface<IEquipmentType>) {}
}

export class EditEquipmentTypesData implements Action {
  readonly type = EDIT_EQUIPMENT_TYPES_DATA;

  constructor(public payload: Partial<IEquipmentType>[]) {}
}

export class EditedEquipmentTypesData implements Action {
  readonly type = EDITED_EQUIPMENT_TYPES_DATA;

  constructor(public response: BulkResponseDataInterface) {}
}

export class CreateEquipmentType implements Action {
  readonly type = CREATE_EQUIPMENT_TYPE;

  constructor(public payload: Partial<IEquipmentType>) {}
}

export class CreatedEquipmentType implements Action {
  readonly type = CREATED_EQUIPMENT_TYPE;

  constructor(public response: GetManyResponseInterface<IEquipmentType>) {}
}

export class DeleteEquipmentType implements Action {
  readonly type = DELETE_EQUIPMENT_TYPE;

  constructor(public equipmentTypeId: number) {}
}

export class DeletedEquipmentType implements Action {
  readonly type = DELETED_EQUIPMENT_TYPE;

  constructor(public response: GetManyResponseInterface<IEquipmentType>) {}
}

export class DeleteEquipmentTypes implements Action {
  readonly type = DELETE_EQUIPMENT_TYPES;

  constructor(public equipmentTypeIds: number[]) {}
}

export class DeletedEquipmentTypes implements Action {
  readonly type = DELETED_EQUIPMENT_TYPES;

  constructor(public response: BulkResponseDataInterface) {}
}

export class FetchError implements Action {
  readonly type = EQUIPMENT_TYPES_FETCH_ERROR;
  constructor(public payload: object) {}
}

export type EquipmentTypesActions =
  | LoadEquipmentTypesData
  | LoadedEquipmentTypesData
  | LoadSiteData
  | LoadedSiteData
  | LoadTagsData
  | LoadedTagsData
  | SingleEditEquipmentTypesData
  | EditedEquipmentTypesData
  | CreateEquipmentType
  | CreatedEquipmentType
  | DeleteEquipmentType
  | DeletedEquipmentType
  | DeleteEquipmentTypes
  | DeletedEquipmentTypes
  | FetchError;
