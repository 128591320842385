import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { first } from 'rxjs/operators';
import { concat, interval, Observable } from 'rxjs';

@Injectable()
export class CheckForUpdateService {
  constructor(
    update: SwUpdate,
    appRef: ApplicationRef,
  ) {
    const appIsStable$ : Observable<boolean> = appRef.isStable
      .pipe(first((isStable: boolean) => isStable === true));

    const everyTenMinutes$: Observable<number> = interval(60 * 1000 * 10);
    const everyTenMinutesOnceAppIsStable$: Observable<boolean | number> = concat(
      appIsStable$,
      everyTenMinutes$,
    );

    everyTenMinutesOnceAppIsStable$.subscribe(() => update.checkForUpdate());

    update.available.subscribe(() => {
      update.activateUpdate()
        .then(() => CheckForUpdateService.unregisterServiceWorkerAndRefresh()
          .then(() => window.location.reload()));
    });
  }

  static async unregisterServiceWorkerAndRefresh(): Promise<void> {
    const registrations = await navigator.serviceWorker.getRegistrations();

    if (!registrations.length) {
      return Promise.resolve(undefined);
    }

    for (const registration of registrations) {
      await registration.unregister();
    }

    return Promise.resolve(undefined);
  }
}
