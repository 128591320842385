import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GenericCrudRequestConstructionParameters } from '../../model/interface/generic-api-request.model';
import { HelperService } from '../helper.service';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../model/interface/crud-response-interface.model';
import {
  NotificationSetupCRUDInterface,
  NotificationSetupFormDropdownDataInterface,
} from '../../../store/settings/notification-setup/notification-setup.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationSetupService {
  private readonly routes = {
    notificationSetups: '/notifications/notification-schedules',
    sites: '/sites',
  };

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly api: string,
    public helperService: HelperService,
  ) {}

  public loadNotificationSetups(
    serviceParameters: GenericCrudRequestConstructionParameters,
  ): Observable<GetManyResponseInterface<NotificationSetupCRUDInterface>> {
    const params: HttpParams = this.helperService.insertGenericCrudRequestParameters(serviceParameters);

    return this.http.get<GetManyResponseInterface<NotificationSetupCRUDInterface>>(
      `${this.api}${this.routes.notificationSetups}`,
      {
        params,
      },
    );
  }

  public loadFormData(): Observable<GetManyResponseInterface<NotificationSetupFormDropdownDataInterface>> {
    const params: HttpParams = this.helperService.insertGenericCrudRequestParameters({
      limit: 1000,
      fields: ['notificationMailGroup.title', 'notificationReport.title', 'timezone'],
      join: ['notificationMailGroup', 'notificationReport'],
    });

    return this.http.get<GetManyResponseInterface<NotificationSetupFormDropdownDataInterface>>(
      `${this.api}${this.routes.sites}`,
      {
        params,
      },
    );
  }

  public createNotificationSetup(
    requestData: Partial<NotificationSetupCRUDInterface>,
  ): Observable<BaseOneResponseInterface<NotificationSetupCRUDInterface>> {
    return this.http.post<BaseOneResponseInterface<NotificationSetupCRUDInterface>>(
      `${this.api}${this.routes.notificationSetups}`,
      requestData,
    );
  }

  public updateNotificationSetup(
    id: number,
    requestData: Partial<NotificationSetupCRUDInterface>,
  ): Observable<BaseOneResponseInterface<NotificationSetupCRUDInterface>> {
    return this.http.patch<BaseOneResponseInterface<NotificationSetupCRUDInterface>>(
      `${this.api}${this.routes.notificationSetups}/${id}`,
      requestData,
    );
  }

  public deleteNotificationSetup(id: number): Observable<void> {
    return this.http.delete<void>(`${this.api}${this.routes.notificationSetups}/${id}`);
  }

  public bulkDeleteNotificationSetup(ids: number[]): Observable<BulkResponseDataInterface> {
    return this.http.delete<BulkResponseDataInterface>(`${this.api}${this.routes.notificationSetups}/bulk/delete`, {
      body: { notificationSchedules: ids },
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    });
  }
}
