import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { ScwMatRadioGroupComponent } from './scw-mat-radio-group.component';

@NgModule({
  imports: [CommonModule, FormsModule, MatRadioModule],
  exports: [ScwMatRadioGroupComponent],
  declarations: [ScwMatRadioGroupComponent],
})
export class ScwMatRadioGroupModule {}
