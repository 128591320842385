import { Action } from '@ngrx/store';
import {
  IErpModalDropdownOptionResponse,
  IGetErpRequest,
  IShiftSummaryErpIntegrationCrudRequest,
  ISummaryOfWorkOrderInParticularShiftResponse,
  SelectedFilters,
  ShiftInformationInterface,
  ShiftSummaryCommentInterface,
  ShiftSummaryFilterInterface,
  ShiftSummaryResponseInterface,
  ShiftSummaryShiftResponseDataInterface,
} from './shift-summary.model';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { PreparedFilterDataInterface } from '../../shared/service/page-filters-and-settings/page-filters-and-settings.service';
import { ITableHeader } from '../../../constants.model';
import { ETableType } from '../../view/shift-summary/shift-summary.model';

export enum ShiftSummaryActionTypes {
  StartShiftSummaryDataLoading = '[Shift Summary] START SHIFT SUMMARY DATA LOADING',
  ShiftSummaryDataLoaded = '[Shift Summary] SHIFT SUMMARY DATA LOADED',
  GetShiftDataAndGroups = '[Shift Summary] GET SHIFT DATA AND GROUPS',
  GetShiftDataAndGroupsLoaded = '[Shift Summary] GET SHIFT DATA AND GROUPS LOADED',
  StartFiltersSaving = '[Shift Summary] START FILTERS SAVING',
  FiltersSaved = '[Shift Summary] FILTERS SAVED',
  StartShiftSummaryCommentSave = '[Shift Summary] START SHIFT SUMMARY COMMENT SAVE',
  StartShiftSummaryCommentSaved = '[Shift Summary] START SHIFT SUMMARY COMMENT SAVED',
  SetSelectedFilters = '[Shift Summary] SET SELECTED FILTERS',
  SetSelectedFiltersLoaded = '[Shift Summary] SET SELECTED FILTERS LOADED',
  GetErpModalDataLoading = '[Shift Summary] GET ERP MODAL DATA LOADING',
  GetErpModalDataLoaded = '[Shift Summary] GET ERP MODAL DATA LOADED',
  GetErpModalDropdownOptionsLoading = '[Shift Summary] GET ERP MODAL DROPDOWN OPTIONS LOADING',
  GetErpModalDropdownOptionsLoaded = '[Shift Summary] GET ERP MODAL DROPDOWN OPTIONS LOADED',
  CreateShiftSummaryErpIntegrationLoading = '[Shift Summary] CREATE SHIFT SUMMARY ERP INTEGRATION LOADING',
  CreateShiftSummaryErpIntegrationLoaded = '[Shift Summary] CREATE SHIFT SUMMARY ERP INTEGRATION LOADED',
  UpdateShiftSummaryErpIntegrationLoading = '[Shift Summary] UPDATE SHIFT SUMMARY ERP INTEGRATION LOADING',
  UpdateShiftSummaryErpIntegrationLoaded = '[Shift Summary] UPDATE SHIFT SUMMARY ERP INTEGRATION LOADED',
  SetTableSettings = '[Shift Summary] SET TABLE SETTINGS',
  ClearState = '[Shift Summary] CLEAR STATE',
  FetchError = '[Shift Summary] FETCH ERROR',
}

export class StartShiftSummaryDataLoading implements Action {
  readonly type = ShiftSummaryActionTypes.StartShiftSummaryDataLoading;
  constructor(public payload: HttpParams) {}
}

export class ShiftSummaryDataLoaded implements Action {
  readonly type = ShiftSummaryActionTypes.ShiftSummaryDataLoaded;

  constructor(public payload: ShiftSummaryShiftResponseDataInterface) {}
}

export class GetShiftDataAndGroups implements Action {
  readonly type = ShiftSummaryActionTypes.GetShiftDataAndGroups;

  constructor(
    public siteId: number,
    public departmentId: number,
    public tableType: string,
    public shiftInformation: ShiftInformationInterface,
  ) {}
}

export class GetShiftDataAndGroupsLoaded implements Action {
  readonly type = ShiftSummaryActionTypes.GetShiftDataAndGroupsLoaded;

  constructor(public payload: ShiftSummaryResponseInterface) {}
}

export class StartFiltersSaving implements Action {
  readonly type = ShiftSummaryActionTypes.StartFiltersSaving;

  constructor(public payload: ShiftSummaryFilterInterface, public setAsDefault: boolean) {}
}

export class FiltersSaved implements Action {
  readonly type = ShiftSummaryActionTypes.FiltersSaved;

  constructor(public payload: any) {}
}

export class StartShiftSummaryCommentSave implements Action {
  readonly type = ShiftSummaryActionTypes.StartShiftSummaryCommentSave;

  constructor(public comments: ShiftSummaryCommentInterface[], public tableType: ETableType) {}
}

export class StartShiftSummaryCommentSaved implements Action {
  readonly type = ShiftSummaryActionTypes.StartShiftSummaryCommentSaved;
}

export class SetSelectedFilters implements Action {
  readonly type = ShiftSummaryActionTypes.SetSelectedFilters;

  constructor(public filters: SelectedFilters) {}
}

export class SetSelectedFiltersLoaded implements Action {
  readonly type = ShiftSummaryActionTypes.SetSelectedFiltersLoaded;
}

export class GetErpModalDataLoading implements Action {
  readonly type = ShiftSummaryActionTypes.GetErpModalDataLoading;

  constructor(public erpModalDataFetchParams: IGetErpRequest) {}
}

export class GetErpModalDataLoaded implements Action {
  readonly type = ShiftSummaryActionTypes.GetErpModalDataLoaded;

  constructor(public erpModalDataResponse: ISummaryOfWorkOrderInParticularShiftResponse) {}
}

export class GetErpModalDropdownOptionsLoading implements Action {
  readonly type = ShiftSummaryActionTypes.GetErpModalDropdownOptionsLoading;

  constructor(public workOrderId: number) {}
}

export class GetErpModalDropdownOptionsLoaded implements Action {
  readonly type = ShiftSummaryActionTypes.GetErpModalDropdownOptionsLoaded;

  constructor(public erpModalDropdownOptions: IErpModalDropdownOptionResponse[]) {}
}

export class CreateShiftSummaryErpIntegrationLoading implements Action {
  readonly type = ShiftSummaryActionTypes.CreateShiftSummaryErpIntegrationLoading;

  constructor(public dto: IShiftSummaryErpIntegrationCrudRequest) {}
}

export class CreateShiftSummaryErpIntegrationLoaded implements Action {
  readonly type = ShiftSummaryActionTypes.CreateShiftSummaryErpIntegrationLoaded;
}

export class UpdateShiftSummaryErpIntegrationLoading implements Action {
  readonly type = ShiftSummaryActionTypes.UpdateShiftSummaryErpIntegrationLoading;

  constructor(public id: number, public dto: IShiftSummaryErpIntegrationCrudRequest) {}
}

export class UpdateShiftSummaryErpIntegrationLoaded implements Action {
  readonly type = ShiftSummaryActionTypes.UpdateShiftSummaryErpIntegrationLoaded;
}

export class SetTableSettings implements Action {
  readonly type = ShiftSummaryActionTypes.SetTableSettings;

  constructor(
    public tableGroupSettings: ITableHeader[],
    public tableColumnSettings: ITableHeader[],
    public erpCalculationUseCheckedIn: boolean,
    public setAsDefault: boolean,
  ) {}
}

export class ClearState implements Action {
  readonly type = ShiftSummaryActionTypes.ClearState;
}

export class FetchError implements Action {
  readonly type = ShiftSummaryActionTypes.FetchError;

  constructor(public payload: HttpErrorResponse) {}
}

export type ShiftSummaryActions =
  | StartShiftSummaryDataLoading
  | ShiftSummaryDataLoaded
  | GetShiftDataAndGroups
  | GetShiftDataAndGroupsLoaded
  | StartFiltersSaving
  | FiltersSaved
  | StartShiftSummaryCommentSave
  | StartShiftSummaryCommentSaved
  | SetSelectedFilters
  | SetSelectedFiltersLoaded
  | GetErpModalDataLoading
  | GetErpModalDataLoaded
  | CreateShiftSummaryErpIntegrationLoading
  | CreateShiftSummaryErpIntegrationLoaded
  | UpdateShiftSummaryErpIntegrationLoading
  | UpdateShiftSummaryErpIntegrationLoaded
  | SetTableSettings
  | ClearState
  | FetchError;
