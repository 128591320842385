import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../../store/oee.reducer';
import * as UserActions from '../../../store/user/actions';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  constructor(
    public router: Router,
    public http: HttpClient,
    private store: Store<OeeAppState>,
  ) {}

  canActivate(): Observable<boolean> {
    const renewUrl = `${environment.ssoUrl}${environment.ssoSilentAuthUrl}${environment.ssoEnv}`;
    return this.http.get<any>(renewUrl).pipe(map((response) => {
      if (response['success']) {
        this.store.dispatch(new UserActions.SetAccessTokenLoaded());
        return true;
      }

      return this.redirectToLogin();
    }));
  }

  redirectToLogin(): boolean {
    window.location.replace(
      `${environment.ssoUrl + environment.ssoAuthUrl + environment.ssoEnv}?state=${encodeURIComponent(window.location.href)}`
    );
    return false;
  }
}
