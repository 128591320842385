import { Action } from '@ngrx/store';
import { SiteCRUDInterface } from '../../../../shared/component/filter/filter.class';
import {
  NotificationGroupInterface,
  NotificationGroupTableQueryParams,
  NotificationMailRecipient,
  NotificationRecipientBulkDeleteManyInterface,
  NotificationRecipientBulkSaveManyInterface,
  NotificationRecipientSystemUser,
} from './notification-groups.model';
import {
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../../../shared/model/interface/crud-response-interface.model';

export const NOTIFICATION_GROUPS_SITES_DATA_LOADING =
  '[NOTIFICATION_GROUPS] NOTIFICATION_GROUPS ACTION SITES DATA LOADING';
export const NOTIFICATION_GROUPS_SITES_DATA_LOADED =
  '[NOTIFICATION_GROUPS] NOTIFICATION_GROUPS ACTION SITES DATA LOADED';
export const NOTIFICATION_GROUPS_DATA_LOADING =
  '[NOTIFICATION_GROUPS] NOTIFICATION_GROUPS ACTION NOTIFICATION_GROUP DATA LOADING';
export const NOTIFICATION_GROUPS_DATA_LOADED =
  '[NOTIFICATION_GROUPS] NOTIFICATION_GROUPS ACTION NOTIFICATION_GROUP DATA LOADED';
export const NOTIFICATION_GROUPS_FETCH_ERROR = '[NOTIFICATION_GROUPS] NOTIFICATION_GROUPS ACTION FETCH ERROR';
export const DELETE_NOTIFICATION_GROUPS = '[NOTIFICATION_GROUPS] NOTIFICATION_GROUPS ACTION DELETE NOTIFICATION_GROUPS';
export const DELETE_NOTIFICATION_GROUPS_COMPLETED =
  '[NOTIFICATION_GROUPS] NOTIFICATION_GROUPS ACTION DELETE NOTIFICATION_GROUPS COMPLETED';
export const ADD_NOTIFICATION_GROUP = '[NOTIFICATION_GROUPS] ADD NOTIFICATION_GROUP';
export const ADD_NOTIFICATION_GROUP_COMPLETED = '[NOTIFICATION_GROUPS] ADD NOTIFICATION_GROUP COMPLETED';
export const EDIT_NOTIFICATION_GROUP = '[NOTIFICATION_GROUPS] EDIT NOTIFICATION_GROUP';
export const EDIT_NOTIFICATION_GROUP_COMPLETED = '[NOTIFICATION_GROUPS] EDIT NOTIFICATION_GROUP COMPLETED';
export const NOTIFICATION_GROUPS_RECIPIENTS_USERS_DATA_LOADING =
  '[NOTIFICATION_GROUPS] NOTIFICATION_GROUPS ACTION RECIPIENTS USERS DATA LOADING';
export const NOTIFICATION_GROUPS_RECIPIENTS_USERS_DATA_LOADED =
  '[NOTIFICATION_GROUPS] NOTIFICATION_GROUPS ACTION RECIPIENTS USERS DATA LOADED';
export const SAVE_NOTIFICATION_GROUPS_RECIPIENTS = '[NOTIFICATION_GROUPS] SAVE NOTIFICATION GROUPS RECIPIENTS';
export const SAVE_NOTIFICATION_GROUPS_RECIPIENTS_COMPLETED =
  '[NOTIFICATION_GROUPS] SAVE NOTIFICATION GROUPS RECIPIENTS COMPLETED';

export class NotificationGroupsDataLoading implements Action {
  readonly type = NOTIFICATION_GROUPS_DATA_LOADING;

  constructor(public payload: NotificationGroupTableQueryParams) {}
}

export class NotificationGroupsDataLoaded implements Action {
  readonly type = NOTIFICATION_GROUPS_DATA_LOADED;

  constructor(public payload: GetManyResponseInterface<NotificationGroupInterface>) {}
}

export class NotificationGroupsSiteDataLoading implements Action {
  readonly type = NOTIFICATION_GROUPS_SITES_DATA_LOADING;
}

export class NotificationGroupsSiteDataLoaded implements Action {
  readonly type = NOTIFICATION_GROUPS_SITES_DATA_LOADED;

  constructor(public payload: ({ id: number; name: string } & Partial<SiteCRUDInterface>)[]) {}
}

export class DeleteNotificationGroups implements Action {
  readonly type = DELETE_NOTIFICATION_GROUPS;
  constructor(public payload: number[]) {}
}

export class DeleteNotificationGroupsCompleted implements Action {
  readonly type = DELETE_NOTIFICATION_GROUPS_COMPLETED;
  constructor(public response: ({ id: number } & { errorMessages?: string })[]) {}
}

export class AddNotificationGroup implements Action {
  readonly type = ADD_NOTIFICATION_GROUP;
  constructor(public notificationGroup: Partial<NotificationGroupInterface>) {}
}

export class AddNotificationGroupCompleted implements Action {
  readonly type = ADD_NOTIFICATION_GROUP_COMPLETED;
  constructor(public payload: BaseOneResponseInterface<NotificationGroupInterface>) {}
}

export class EditNotificationGroup implements Action {
  readonly type = EDIT_NOTIFICATION_GROUP;
  constructor(public notificationGroup: Partial<NotificationGroupInterface>, public id: number) {}
}

export class EditNotificationGroupCompleted implements Action {
  readonly type = EDIT_NOTIFICATION_GROUP_COMPLETED;
  constructor(public payload: BaseOneResponseInterface<NotificationGroupInterface>) {}
}

export class NotificationGroupsRecipientsUsersDataLoading implements Action {
  readonly type = NOTIFICATION_GROUPS_RECIPIENTS_USERS_DATA_LOADING;

  constructor(public siteId: number, public searchText: string, public excludedIds?: number[]) {}
}

export class NotificationGroupsRecipientsUsersDataLoaded implements Action {
  readonly type = NOTIFICATION_GROUPS_RECIPIENTS_USERS_DATA_LOADED;

  constructor(public payload: NotificationRecipientSystemUser[]) {}
}

export class SaveNotificationGroupsRecipients implements Action {
  readonly type = SAVE_NOTIFICATION_GROUPS_RECIPIENTS;
  constructor(
    public saveMany: NotificationRecipientBulkSaveManyInterface,
    public deleteMany: NotificationRecipientBulkDeleteManyInterface,
  ) {}
}

export class SaveNotificationGroupsRecipientsCompleted implements Action {
  readonly type = SAVE_NOTIFICATION_GROUPS_RECIPIENTS_COMPLETED;
  constructor(
    public deleteMany: ({ id: number } & { errorMessages?: string })[],
    public saveMany: (NotificationMailRecipient & { errorMessages?: string })[],
  ) {}
}

export class FetchError implements Action {
  readonly type = NOTIFICATION_GROUPS_FETCH_ERROR;
  constructor(public payload: object) {}
}

export type NotificationGroupsActions =
  | NotificationGroupsDataLoading
  | NotificationGroupsDataLoaded
  | NotificationGroupsSiteDataLoading
  | NotificationGroupsSiteDataLoaded
  | DeleteNotificationGroups
  | DeleteNotificationGroupsCompleted
  | AddNotificationGroup
  | AddNotificationGroupCompleted
  | EditNotificationGroup
  | EditNotificationGroupCompleted
  | NotificationGroupsRecipientsUsersDataLoading
  | NotificationGroupsRecipientsUsersDataLoaded
  | SaveNotificationGroupsRecipients
  | SaveNotificationGroupsRecipientsCompleted
  | FetchError;
