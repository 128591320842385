import { Inject, Injectable } from '@angular/core';
import {
  CellTypes,
  CreateExcelInterface,
  CreateExcelSheetInterface,
  ExcelColumnWidthEnum,
  ExcelHelperService,
  ExcelSheetTypeEnum,
} from '../../../shared/service/excel/excel-helper.service';
import {
  ExcelDropdownInterface,
  IAnonymousCard,
  IBulkEditUser,
  LanguageInterface,
  LanguageResponseInterface,
  LinesResponseInterface,
  UserBulkSaveManyInterface,
  UserExcelContentInterface,
  UserExcelInterface,
  UserInterface,
  UsersDownloadExcelFiltersInterface,
} from './users.model';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import { TranslateService } from '@ngx-translate/core';
import { ValueType, Workbook, Worksheet } from 'exceljs';
import { forkJoin, Observable, Subject } from 'rxjs';
import {
  BaseCrudResponse,
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { excelDateFormat, excelTimeFormat, localeDateObject } from '../../../shared/model/enum/excel-date-format';
import * as moment from 'moment-timezone';
import * as _ from 'lodash';
import * as UsersActions from './users.actions';
import { SitesInterface, SitesResponseInterface } from '../../work-order-schedule/work-order-schedule.model';
import { shareReplay, takeUntil } from 'rxjs/operators';
import { UserLevelInterface, userLevelObject, UserLevels } from '../../user/model';
import { TimeZoneOptions } from '../../../shared/model/enum/timezone-list';
import { LineInterface } from '../../site-line-selection/site-line-selection.model';
import { IAddUser } from '../../../view/settings/users/users.model';
import { HelperService } from '../../../shared/service/helper.service';
import { ImageHelperService } from '../../../shared/helper/image-helper.service';
import { UserAvatarCacheService } from './user-avatar-cache.service';
import { UsersCRUDInterface } from '../../../shared/component/filter/filter.class';

@Injectable({
  providedIn: 'root',
})
export class UserSettingsService {
  private readonly USERS = {
    SITE_URL: `${this.baseUrl}/sites`,
    ANONYMOUS_CARDS_URL: `${this.baseUrl}/anonymous-cards`,
    LINE_URL: `${this.baseUrl}/lines`,
    USER_URL: `${this.baseUrl}/users`,
    BULK_DELETE_URL: `${this.baseUrl}/users/bulk/delete`,
    BULK_EDIT_URL: `${this.baseUrl}/users/bulk/edit`,
    LANGUAGE_URL: `${this.baseUrl}/languages`,
    USER_BULK_SAVE_URL: `${this.baseUrl}/users/bulk/save`,
    AVATAR: `${this.baseUrl}/users/avatar`,
  };
  private timezone: string = 'utc';
  private dateFormat$: string;
  private timeFormat$: string;
  private readonly destroySubject: Subject<boolean> = new Subject<boolean>();
  private isNonVerifiedUserPassActive: boolean;

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly excelHelper: ExcelHelperService,
    private readonly helperService: HelperService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly translate: TranslateService,
    private readonly imageHelperService: ImageHelperService,
    private readonly userAvatarCacheService: UserAvatarCacheService,
  ) {
    this.store
      .select('user')
      .pipe(takeUntil(this.destroySubject))
      .subscribe((state) => {
        if (state.isUserLoaded) {
          this.timezone = state.timezone;
          if (state.locale !== '') {
            this.dateFormat$ = excelDateFormat[state.locale];
            this.timeFormat$ = excelTimeFormat[state.locale];
          }
          this.isNonVerifiedUserPassActive = state.isNonVerifiedUserPassActive;
          this.destroySubject.next(true);
          this.destroySubject.complete();
        }
      });
  }

  public getLevels(): UserLevelInterface[] {
    return [
      { id: UserLevels.ADMIN, name: this.translate.instant('general.lookups.level.admin') },
      { id: UserLevels.EXECUTIVE, name: this.translate.instant('general.lookups.level.executive') },
      { id: UserLevels.SUPERVISOR, name: this.translate.instant('general.lookups.level.supervisor') },
      { id: UserLevels.LINE_LEADER, name: this.translate.instant('general.lookups.level.lineLeader') },
      { id: UserLevels.OPERATOR, name: this.translate.instant('general.lookups.level.operator') },
      { id: UserLevels.PLANNER, name: this.translate.instant('general.lookups.level.planner') },
    ];
  }

  public getUsers(params: HttpParams): Observable<GetManyResponseInterface<UserInterface>> {
    return this.http.get<GetManyResponseInterface<UserInterface>>(this.USERS.USER_URL, {
      params: params.set('isUserScope', 1),
    });
  }

  public getAnonymousCards(params?: HttpParams): Observable<GetManyResponseInterface<IAnonymousCard>> {
    return this.http.get<GetManyResponseInterface<IAnonymousCard>>(this.USERS.ANONYMOUS_CARDS_URL, {
      params,
    });
  }

  public getUsersForDatatable(params: HttpParams): Observable<GetManyResponseInterface<UserInterface>> {
    return this.http.get<GetManyResponseInterface<UserInterface>>(this.USERS.USER_URL, {
      params,
    });
  }

  public getUser(userId: number): Observable<BaseOneResponseInterface<UserInterface>> {
    return this.http.get<BaseOneResponseInterface<UserInterface>>(`${this.USERS.USER_URL}/${userId}`);
  }

  public addUser(user: IAddUser): Observable<BaseOneResponseInterface<UsersCRUDInterface>> {
    return this.http.post<BaseOneResponseInterface<UsersCRUDInterface>>(`${this.USERS.USER_URL}`, user);
  }

  public editUser(user: IAddUser, userId: number): Observable<BaseOneResponseInterface<UsersCRUDInterface>> {
    return this.http.patch<BaseOneResponseInterface<UsersCRUDInterface>>(`${this.USERS.USER_URL}/${userId}`, user);
  }

  public bulkEditUser(users: IBulkEditUser[]): Observable<BulkResponseDataInterface> {
    return this.http.patch<BulkResponseDataInterface>(`${this.USERS.BULK_EDIT_URL}`, { users });
  }

  deleteUsers(user: number[]): Observable<BulkResponseDataInterface> {
    if (user.length > 1) {
      const httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        body: {
          users: user,
        },
      };
      return this.http.delete<BulkResponseDataInterface>(`${this.USERS.BULK_DELETE_URL}`, httpOptions);
    }
    return this.http.delete<BulkResponseDataInterface>(`${this.USERS.USER_URL}/${user[0]}`);
  }

  public getSite(id: number): Observable<SitesResponseInterface> {
    return this.http.get<SitesResponseInterface>(`${this.USERS.SITE_URL}/?filter=id||$eq||${id}`);
  }

  public getObservableSites(): Observable<SitesResponseInterface> {
    return this.http.get<SitesResponseInterface>(`${this.USERS.SITE_URL}`);
  }

  public getSites(): Promise<SitesResponseInterface> {
    const params = new HttpParams().set('limit', '1000');

    return new Promise((resolve, reject) => {
      this.http.get(this.USERS.SITE_URL, { params }).subscribe(
        (response: SitesResponseInterface) => {
          resolve(response);
        },
        (error: HttpErrorResponse) => {
          reject(error);
        },
      );
    });
  }

  public getLines(): Promise<LinesResponseInterface> {
    const params = new HttpParams().set('limit', '1000');

    return new Promise((resolve, reject) => {
      this.http.get(this.USERS.LINE_URL, { params }).subscribe(
        (response: LinesResponseInterface) => {
          resolve(response);
        },
        (error: HttpErrorResponse) => {
          reject(error);
        },
      );
    });
  }

  public getLanguages(params?: HttpParams): Observable<LanguageResponseInterface> {
    return this.http.get<LanguageResponseInterface>(`${this.USERS.LANGUAGE_URL}`, { params });
  }

  public getTimezoneList(): ExcelDropdownInterface[] {
    return Object.values(TimeZoneOptions).map((timezone: string) => ({
      id: timezone,
      name: timezone,
    }));
  }

  public getContactTypeList(): ExcelDropdownInterface[] {
    const types = ['it', 'supervisor', 'maintenance', 'quality', 'scw', 'warehouse'];

    return types.map((type: string) => ({
      id: type,
      name: this.translate.instant(`general.contactTeam.${type}`),
    }));
  }

  public prepareLanguages(languages: LanguageInterface[]): ExcelDropdownInterface[] {
    return languages.map((language: LanguageInterface) => ({
      id: language.abbreviation,
      name: language.language,
    }));
  }

  public getLocaleFormatList(): ExcelDropdownInterface[] {
    const locales: string[] = Object.keys(localeDateObject);
    const formats: string[] = ['L', 'll'];
    const timeFormat: string = 'LTS';
    let output: ExcelDropdownInterface[] = [];

    for (const locale of locales) {
      const options: ExcelDropdownInterface[] = formats.map((format: string) => {
        return {
          id: `${locale}_${format}`,
          name: `${localeDateObject[locale]} - ${moment()
            .locale(locale)
            .format(`${format} ${timeFormat}`)}`,
        };
      });

      output = output.concat(options);
    }

    return output;
  }

  private getUserObservables(withData: boolean, filters: UsersDownloadExcelFiltersInterface, data?: UserInterface[]) {
    const observables: Observable<
      SitesResponseInterface | LanguageResponseInterface | GetManyResponseInterface<UserInterface>
    >[] = [this.getSite(filters.siteId), this.getLanguages()];

    if (withData && !data) {
      const httpParamsOfUser: HttpParams = new HttpParams()
        .set('siteId', String(filters.siteId))
        .set('page', filters.selectedDownloadOffset);
      observables.push(this.getUsers(httpParamsOfUser.set('limit', '1000')));
    }

    return observables;
  }

  private getSiteNames(siteResponse: SitesResponseInterface, currentSite: string): string {
    if (!currentSite) {
      return null;
    }

    if (currentSite === '*') {
      return this.translate.instant('general.all');
    }

    const output: string[] = [];
    const currentSiteIdArray: number[] = currentSite.split(',').map((id: string) => Number(id));

    for (const siteId of currentSiteIdArray) {
      const foundSite: SitesInterface | undefined = siteResponse.data.find(
        (site: SitesInterface) => site.id === siteId,
      );

      if (foundSite) {
        output.push(foundSite.name);
      }
    }

    return output.join(', ');
  }

  private getLineNames(
    siteResponse: SitesResponseInterface,
    lineResponse: LinesResponseInterface,
    currentLine: string,
  ): string {
    if (!currentLine) {
      return null;
    }

    if (currentLine === '*') {
      return this.translate.instant('general.all');
    }

    const output: string[] = [];
    let currentLineIdArray: number[];
    currentLineIdArray = currentLine.split(',').map((id: string) => Number(id));

    for (const lineId of currentLineIdArray) {
      const foundLine: LineInterface | undefined = lineResponse.data.find(
        (line: LineInterface) => line.id === Number(lineId),
      );

      if (foundLine) {
        const foundSite: SitesInterface | undefined = siteResponse.data.find(
          (site: SitesInterface) => site.id === foundLine.siteId,
        );

        if (foundSite) {
          output.push(`${foundLine.title} (${foundSite.name})`);
        }
      }
    }

    return output.join(', ');
  }

  public async downloadUserExcel(
    withData: boolean,
    filters: UsersDownloadExcelFiltersInterface,
    withErrorColumn: boolean = false,
    data?: UserInterface[],
  ): Promise<void> {
    const siteResponse: SitesResponseInterface = await this.getSites();
    const lineResponse: LinesResponseInterface = await this.getLines();

    forkJoin(this.getUserObservables(withData, filters, data)).subscribe((responseList) => {
      const site: SitesInterface[] = _.get(responseList, '0.data', []);
      const rawLanguages: LanguageInterface[] = _.get(responseList, '1.data', []);
      const languages: ExcelDropdownInterface[] = this.prepareLanguages(rawLanguages);
      const timezones: ExcelDropdownInterface[] = this.getTimezoneList();
      const contactTypes: ExcelDropdownInterface[] = this.getContactTypeList();
      const localeFormats: ExcelDropdownInterface[] = this.getLocaleFormatList();
      const multipleCheckInOptions: ExcelDropdownInterface[] = this.excelHelper.getMultipleCheckInsOptions();
      const levels: UserLevelInterface[] = this.getLevels();
      const sheetTitle: string = this.translate.instant('pageTitles.users');
      const excelName: string = `${sheetTitle} ${moment()
        .tz(this.timezone)
        .format(this.dateFormat$)}`;
      let excelData: UserInterface[] = [];

      if (withData) {
        excelData = _.get(responseList, '2.data', []) as UserInterface[];

        if (excelData.length === 0 && data) {
          excelData = data;
        }

        for (const user of excelData) {
          user.currentSite = this.getSiteNames(siteResponse, user.currentSite);
          user.currentLine = this.getLineNames(siteResponse, lineResponse, user.currentLine);
          const siteData: SitesInterface = site.find((site: SitesInterface) => site.id === user.defaultSite);
          if (siteData) {
            user.site = {
              id: siteData.id,
              name: siteData.name,
            };
          }

          for (const key of Object.keys(userLevelObject)) {
            if (userLevelObject[key].id === user.levelId) {
              user.levelDropdown = {
                id: userLevelObject[key].id,
                name: userLevelObject[key].name,
              };
            }
          }

          user.multipleCheckInsDropdown = multipleCheckInOptions.find(
            (option: ExcelDropdownInterface) => option.id === String(user.multipleCheckIns),
          );

          user.activeDropdown = {
            id: String(user.isActive),
            name: this.translate.instant(`general.${user.isActive ? 'yes' : 'no'}`),
          };

          const languageData: ExcelDropdownInterface = languages.find(
            (language: ExcelDropdownInterface) => language.id === user.userLanguage,
          );

          if (languageData) {
            user.languageDropdown = {
              id: languageData.id,
              name: languageData.name,
            };
          }

          user.timeZoneDropdown = { id: user.timeZone, name: user.timeZone };
          user.dateTimeFormatDropdown = localeFormats.find(
            (format: ExcelDropdownInterface) => format.id === `${user.locale}_${user.dateFormat}`,
          );

          const contactTypesOfUser: string[] = [];

          for (const type of contactTypes) {
            if (
              (type.id === 'it' && user.contactIt) ||
              (type.id === 'supervisor' && user.contactSupervisor) ||
              (type.id === 'maintenance' && user.contactMaintenance) ||
              (type.id === 'quality' && user.contactQuality) ||
              (type.id === 'scw' && user.contactScw) ||
              (type.id === 'warehouse' && user.contactWarehouse)
            ) {
              contactTypesOfUser.push(type.name);
            }
          }
          user.contactType = contactTypesOfUser.join(', ');
        }

        if (data) {
          excelData = data;
        }
      }

      const excelOptions: CreateExcelInterface = this.getUserExcelColumns(
        { levels, languages, timezones, contactTypes, localeFormats, multipleCheckInOptions, sites: site },
        withErrorColumn,
      );

      if (withData) {
        excelOptions.data = excelData;
      }

      const worksheets: CreateExcelSheetInterface[] = [
        {
          withData,
          sheetTitle,
          sheetType: ExcelSheetTypeEnum.TABLE,
          params: excelOptions,
          isDisabledColumnsFirstLine: true,
        },
      ];

      this.excelHelper
        .createExcel(excelName, worksheets, this.timezone, this.dateFormat$, this.timeFormat$, false)
        .then(
          () => {
            this.store.dispatch(new UsersActions.DownloadUsersExcelCompleted());
          },
          () => {
            this.store.dispatch(new UsersActions.FetchError({}));
          },
        );
    });
  }

  private getUserExcelColumns(content: UserExcelContentInterface, withErrorColumn: boolean): CreateExcelInterface {
    const booleanDropdownOptions: ExcelDropdownInterface[] = this.excelHelper.getExcelBooleanDropdownOptions();
    const contactTypes: string[] = content.contactTypes
      ? content.contactTypes.map((type: ExcelDropdownInterface) => type.name)
      : [];
    const excelColumns: CreateExcelInterface = {
      columns: [
        {
          header: this.translate.instant('general.excel.column.username'),
          key: 'userName',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            formulae: [],
            errorStyle: 'Error',
            showInputMessage: true,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('general.excel.column.level'),
          key: 'levelId',
          width: 10,
          type: ValueType.String,
          dropdownOptions: {
            data: content.levels,
            prop: 'name',
            dataProperty: 'levelDropdown.name',
            dataId: 'levelDropdown.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('general.excel.column.fullName'),
          key: 'fullName',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            formulae: [],
            errorStyle: 'Error',
            showInputMessage: true,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('general.excel.column.email'),
          key: 'email',
          width: ExcelColumnWidthEnum.EMAIL,
          type: ValueType.String,
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            formulae: [],
            errorStyle: 'Error',
            showInputMessage: true,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('general.excel.column.phone.name'),
          key: 'phone',
          width: ExcelColumnWidthEnum.PHONE,
          type: ValueType.String,
          style: { numFmt: '@' },
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            formulae: [],
            errorStyle: 'Error',
            showInputMessage: true,
            prompt: this.translate.instant('general.excel.column.phone.prompt'),
          },
        },
        {
          header: this.translate.instant('general.excel.column.checkInCardId'),
          key: 'checkInCardId',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            formulae: [],
            errorStyle: 'Error',
            showInputMessage: true,
          },
        },
        {
          header: this.translate.instant('general.excel.column.password.name'),
          key: 'password',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            formulae: [],
            errorStyle: 'Error',
            showInputMessage: true,
            prompt: this.translate.instant('general.excel.column.password.prompt'),
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('general.excel.column.checkInPin.name'),
          key: 'checkInsPin',
          width: ExcelColumnWidthEnum.PIN,
          type: ValueType.String,
          style: { numFmt: '@' },
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            formulae: [],
            errorStyle: 'Error',
            showInputMessage: true,
            prompt: this.translate.instant('general.excel.column.checkInPin.prompt'),
          },
        },
        {
          header: this.translate.instant('general.excel.column.multipleCheckIns.name'),
          key: 'multipleCheckIns',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: content.multipleCheckInOptions,
            prop: 'multipleCheckIns',
            dataProperty: 'multipleCheckInsDropdown.name',
            dataId: 'multipleCheckInsDropdown.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
            prompt: this.translate.instant('general.excel.column.multipleCheckIns.prompt', {
              defaultOption: this.translate.instant('general.siteDefault'),
            }),
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('general.excel.column.language'),
          key: 'userLanguage',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: content.languages,
            prop: 'userLanguage',
            dataProperty: 'languageDropdown.name',
            dataId: 'languageDropdown.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('general.excel.column.localeDateTimeFormat'),
          key: 'dateTimeFormat',
          width: ExcelColumnWidthEnum.DATE_TIME,
          type: ValueType.String,
          dropdownOptions: {
            data: content.localeFormats,
            prop: 'dateTimeFormat',
            dataProperty: 'dateTimeFormatDropdown.name',
            dataId: 'dateTimeFormatDropdown.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('general.excel.column.authorizedSites.label'),
          key: 'currentSite',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            formulae: [],
            errorStyle: 'Error',
            showInputMessage: true,
            prompt: this.translate.instant('general.excel.column.authorizedSites.prompt', {
              all: this.translate.instant('general.all'),
            }),
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('general.excel.column.authorizedLines.label'),
          key: 'currentLine',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            formulae: [],
            errorStyle: 'Error',
            showInputMessage: true,
            prompt: this.translate.instant('general.excel.column.authorizedLines.prompt', {
              all: this.translate.instant('general.all'),
            }),
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('general.excel.column.defaultSite'),
          key: 'defaultSite',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: content.sites,
            prop: 'name',
            dataProperty: 'site.name',
            dataId: 'site.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
        },
        {
          header: this.translate.instant('general.excel.column.timezone'),
          key: 'timeZone',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: content.timezones,
            prop: 'timeZone',
            dataProperty: 'timeZoneDropdown.name',
            dataId: 'timeZoneDropdown.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('general.excel.column.contactType.label'),
          key: 'contactType',
          width: ExcelColumnWidthEnum.CONTACT_TYPE,
          type: ValueType.String,
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            formulae: [],
            errorStyle: 'Error',
            showInputMessage: true,
            prompt: this.translate.instant('general.excel.column.contactType.prompt', {
              types: contactTypes.join(', '),
            }),
          },
        },
        {
          header: this.translate.instant('general.excel.column.details'),
          key: 'details',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            formulae: [],
            errorStyle: 'Error',
            showInputMessage: true,
          },
        },
        {
          header: this.translate.instant('general.excel.column.decimalScaleLimit'),
          key: 'decimalScaleLimit',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.Number,
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            formulae: [],
            errorStyle: 'Error',
            showInputMessage: true,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('general.excel.column.active'),
          key: 'isActive',
          width: ExcelColumnWidthEnum.BOOLEAN,
          type: ValueType.Boolean,
          dropdownOptions: {
            data: booleanDropdownOptions,
            prop: 'name',
            dataProperty: 'activeDropdown.name',
            dataId: 'activeDropdown.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        ...(this.isNonVerifiedUserPassActive
          ? []
          : [
              {
                header: this.translate.instant('general.excel.column.verifyUser'),
                key: 'verifyUser',
                width: 10,
                type: ValueType.Boolean,
                dropdownOptions: {
                  data: booleanDropdownOptions,
                  prop: 'name',
                  dataProperty: 'verifyUserDropdown.name',
                  dataId: 'verifyUserDropdown.id',
                },
                dataValidation: {
                  type: CellTypes.LIST,
                  prompt: `${this.translate.instant('excel.column.promptDropDown', {
                    field: this.translate.instant('general.excel.column.verifyUser'),
                  })}. ${this.translate.instant('excel.column.promptVerifyUser')}`,
                },
              },
            ]),
        {
          header: 'id',
          key: 'id',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          dataValidation: {
            type: CellTypes.CUSTOM,
          },
        },
      ],
    };

    this.excelHelper.prepareExcelColumns(excelColumns.columns, withErrorColumn);

    return excelColumns;
  }

  public async getUsersFromExcel(file: File): Promise<UserExcelInterface | null> {
    const workbook: Workbook = await this.excelHelper.getExcelWorkBookFromFile(file);
    const userSheet: Worksheet = workbook.getWorksheet(this.translate.instant('pageTitles.users'));
    const levelDataSheet: Worksheet = workbook.getWorksheet('levelIdDataSheet');
    const languageDataSheet: Worksheet = workbook.getWorksheet('userLanguageDataSheet');
    const dateTimeFormatDataSheet: Worksheet = workbook.getWorksheet('dateTimeFormatDataSheet');
    const defaultSiteDataSheet: Worksheet = workbook.getWorksheet('defaultSiteDataSheet');
    const timeZoneObjectDataSheet: Worksheet = workbook.getWorksheet('timeZoneDataSheet');

    if (
      !userSheet ||
      !levelDataSheet ||
      !languageDataSheet ||
      !dateTimeFormatDataSheet ||
      !defaultSiteDataSheet ||
      !timeZoneObjectDataSheet
    ) {
      return null;
    }

    const genericColumns = {
      id: {
        key: 'id',
        type: ValueType.String,
        dataValidationType: CellTypes.CUSTOM,
      },
      name: {
        key: 'name',
        type: ValueType.String,
        dataValidationType: CellTypes.CUSTOM,
      },
    };

    const levelData: UserLevelInterface[] = this.excelHelper.getExcelRowsFromWorkSheet<UserLevelInterface>(
      levelDataSheet,
      genericColumns,
    );

    const languageData: ExcelDropdownInterface[] = this.excelHelper.getExcelRowsFromWorkSheet<ExcelDropdownInterface>(
      languageDataSheet,
      genericColumns,
    );

    const dateTimeFormatData: ExcelDropdownInterface[] = this.excelHelper.getExcelRowsFromWorkSheet<
      ExcelDropdownInterface
    >(dateTimeFormatDataSheet, genericColumns);

    const defaultSiteData: SitesInterface[] = this.excelHelper.getExcelRowsFromWorkSheet<SitesInterface>(
      defaultSiteDataSheet,
      genericColumns,
    );

    const timeZoneObjectData: ExcelDropdownInterface[] = this.excelHelper.getExcelRowsFromWorkSheet<
      ExcelDropdownInterface
    >(timeZoneObjectDataSheet, genericColumns);

    if (
      !levelData.length ||
      !languageData.length ||
      !dateTimeFormatData.length ||
      !defaultSiteData.length ||
      !timeZoneObjectData.length
    ) {
      return null;
    }

    const { columns } = this.getUserExcelColumns(
      {
        sites: null,
        levels: null,
        languages: null,
        timezones: null,
        contactTypes: null,
        localeFormats: null,
        multipleCheckInOptions: null,
      },
      null,
    );
    const columnKeys = this.excelHelper.getSheetColumnKeys(columns);

    const siteResponse: SitesResponseInterface = await this.getSites();
    const lineResponse: LinesResponseInterface = await this.getLines();

    return {
      levelData,
      languageData,
      dateTimeFormatData,
      defaultSiteData,
      timeZoneObjectData,
      siteData: siteResponse.data,
      lineData: lineResponse.data,
      userData: {
        users: this.excelHelper.getExcelRowsFromWorkSheet<UserInterface>(userSheet, columnKeys, {
          dateFormat: this.dateFormat$,
          timeFormat: this.timeFormat$,
          timezone: this.timezone,
        }),
      },
    };
  }

  public uploadExcel(users: UserBulkSaveManyInterface): Observable<BulkResponseDataInterface> {
    return this.http.post<BulkResponseDataInterface>(this.USERS.USER_BULK_SAVE_URL, users);
  }

  public sendVerificationEmail(id: number): Observable<BaseCrudResponse> {
    return this.http.post<BaseCrudResponse>(`${this.USERS.USER_URL}/${id}/send-verification-email`, null);
  }

  public unverifyUser(userId: number): Observable<BaseOneResponseInterface<IAddUser>> {
    return this.http.patch<BaseOneResponseInterface<IAddUser>>(`${this.USERS.USER_URL}/${userId}/unverify-user`, null);
  }

  public getAvatar(avatarPath: string): Observable<BaseOneResponseInterface<string>> {
    let avatar: Observable<BaseOneResponseInterface<string>> | undefined = this.userAvatarCacheService.getValue(
      avatarPath,
    );

    if (avatar) {
      return avatar;
    }

    avatar = this.http.get<BaseOneResponseInterface<string>>(`${this.USERS.AVATAR}/${avatarPath}`).pipe(shareReplay(1));
    this.userAvatarCacheService.setValue(avatarPath, avatar);

    return avatar;
  }

  public uploadAvatar(id: number, base64ImageContent: string): Observable<BaseCrudResponse> {
    return this.http.patch<BaseCrudResponse>(
      `${this.USERS.AVATAR}/${id}`,
      this.imageHelperService.getImageUploadFormData(base64ImageContent),
    );
  }

  public deleteAvatar(id: number): Observable<BaseCrudResponse> {
    return this.http.delete<BaseCrudResponse>(`${this.USERS.AVATAR}/${id}`);
  }
}
