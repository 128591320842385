import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../../shared/shared.module';
import { AlertPauseComponent } from './alert-pause/alert-pause.component';
import { OfflineSensorIndicatorComponent } from './offline-sensor-indicator/offline-sensor-indicator.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { LineStationDropdownItemComponent } from './line-station-dropdown-item/line-station-dropdown-item.component';

@NgModule({
  imports: [NgbModule, SharedModule, ScrollingModule],
  declarations: [AlertPauseComponent, OfflineSensorIndicatorComponent, LineStationDropdownItemComponent],
  exports: [AlertPauseComponent, OfflineSensorIndicatorComponent, LineStationDropdownItemComponent],
})
export class MainModule {}
