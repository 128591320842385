import { Action } from '@ngrx/store';
import { ICapacityFilters, ICapacityResponseInterface, ITableCapacityResult } from './capacity.model';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';

export const CAPACITY_DATA_LOADING = '[CAPACITY] CAPACITY DATA LOADING';
export const CAPACITY_DATA_LOADED = '[CAPACITY] CAPACITY DATA LOADED';
export const DOWNLOAD_CAPACITY_EXCEL = '[CAPACITY] CAPACITY DOWNLOAD EXCEL';
export const DOWNLOAD_CAPACITY_EXCEL_COMPLETED = '[CAPACITY] CAPACITY DOWNLOAD EXCEL COMPLETED';
export const FETCH_ERROR = '[CAPACITY] FETCH ERROR';

export class CapacityDataLoading implements Action {
  readonly type = CAPACITY_DATA_LOADING;

  constructor(public params: ICapacityFilters) {}
}

export class CapacityDataLoaded implements Action {
  readonly type = CAPACITY_DATA_LOADED;

  constructor(public payload: GetManyResponseInterface<ICapacityResponseInterface>) {}
}

export class DownloadCapacityExcel implements Action {
  readonly type = DOWNLOAD_CAPACITY_EXCEL;

  constructor(public withData: boolean = false, public data: ITableCapacityResult[]) {}
}

export class DownloadCapacityExcelCompleted implements Action {
  readonly type = DOWNLOAD_CAPACITY_EXCEL_COMPLETED;
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;

  constructor(public payload: object) {}
}

export type CapacityActions =
  | CapacityDataLoading
  | CapacityDataLoaded
  | DownloadCapacityExcel
  | DownloadCapacityExcelCompleted
  | FetchError;
