import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { ObservationService } from '../../../shared/service/filter/observation.service';
import * as ObjectActions from './observation.actions';
import { generateHttpOption } from '../../../shared/helper/app-helper';
import { ObservationCRUDInterface } from '../../../shared/component/filter/filter.class';

@Injectable()
export class FilterObservationEffects {
  constructor(private actions$: Actions<ObjectActions.FilterObservationActions>, public service: ObservationService) {}

  loadFilterObservations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.FilterObservationActionTypes.StartLoadFilterObservations),
      switchMap((objectData: ObjectActions.StartLoadFilterObservations) => {
        const params = generateHttpOption(objectData.options);

        return from(this.service.getData(params)).pipe(
          switchMap((response: ObservationCRUDInterface[]) => {
            return of(new ObjectActions.FilterObservationsLoaded(response));
          }),
          catchError((err) => {
            return of(new ObjectActions.FilterObservationsLoaded([]), new ObjectActions.FetchError(err));
          }),
        );
      }),
    ),
  );
}
