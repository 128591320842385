import { Inject, Injectable } from '@angular/core';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ResponseArrayInterface } from '../../../shared/model/interface/generic-api-response.model';
import { DatatableInterface } from '../../../shared/service/datatable/datatable.model';
import { Observable } from 'rxjs';
import { ISkippedTreeNodeResponse, ITreeNode } from './tree-nodes.model';

@Injectable({
  providedIn: 'root',
})
export class TreeNodesService {
  private routes = {
    treeNodes: '/tree-nodes',
    skippedTreeNodes: `/tree-nodes/skip-to-first-break`,
  };

  public tableQuery: DatatableInterface = {
    page: 1,
    pageSize: 10,
    orderBy: 'id',
    orderDesc: true,
  };

  constructor(public http: HttpClient, @Inject('API_BASE_URL') private api: string) {}

  public getTreeNodes(params: HttpParams): Observable<ResponseArrayInterface<ITreeNode>> {
    return this.http.get<ResponseArrayInterface<ITreeNode>>(`${this.api}${this.routes.treeNodes}`, { params });
  }

  public getSkippedTreeNodes(params: HttpParams): Observable<BaseOneResponseInterface<ISkippedTreeNodeResponse>> {
    return this.http.get<BaseOneResponseInterface<ISkippedTreeNodeResponse>>(
      `${this.api}${this.routes.skippedTreeNodes}`,
      { params },
    );
  }

  public addTreeNode(taskFolder: Omit<ITreeNode, 'id'>): Observable<BaseOneResponseInterface<ITreeNode>> {
    return this.http.post<BaseOneResponseInterface<ITreeNode>>(`${this.api}${this.routes.treeNodes}`, taskFolder);
  }

  public editTreeNode(
    taskFolder: Omit<ITreeNode, 'id'>,
    taskFolderId: number,
  ): Observable<BaseOneResponseInterface<ITreeNode>> {
    return this.http.patch<BaseOneResponseInterface<ITreeNode>>(
      `${this.api}${this.routes.treeNodes}/${taskFolderId}`,
      taskFolder,
    );
  }

  public deleteTreeNode(taskFolderId: number): Observable<BulkResponseDataInterface> {
    return this.http.delete<BulkResponseDataInterface>(`${this.api}${this.routes.treeNodes}/${taskFolderId}`);
  }
}
