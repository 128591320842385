import { KpiCardsStateInterface } from './kpi-cards.model';
import * as Actions from './kpi-cards.actions';

export const kpiCardState: KpiCardsStateInterface = {
  kpiCardsLoading: false,
  kpiCardsLoaded: false,
  kpiCardsData: null,
  addKpiCardLoading: false,
  addKpiCardLoaded: false,
  deleteKpiCardLoading: false,
  deleteKpiCardLoaded: false,
  editKpiCardLoading: false,
  editKpiCardLoaded: false,
  duplicateKpiCardLoading: false,
  duplicateKpiCardLoaded: false,
  filters: null,
};

export function kpiCardReducer(
  state: KpiCardsStateInterface = kpiCardState,
  action: Actions.KpiCardsActions,
): KpiCardsStateInterface {
  switch (action.type) {
    case Actions.KPI_CARDS_DATA_LOADING:
      return {
        ...state,
        ...{
          kpiCardsLoading: true,
          kpiCardsLoaded: false,
        },
      };
    case Actions.KPI_CARDS_DATA_LOADED:
      return {
        ...state,
        ...{
          kpiCardsData: action.payload,
          kpiCardsLoading: false,
          kpiCardsLoaded: true,
        },
      };
    case Actions.ADD_KPI_CARD:
      return {
        ...state,
        addKpiCardLoading: true,
        addKpiCardLoaded: false,
      };
    case Actions.ADD_KPI_CARD_COMPLETED:
      return {
        ...state,
        addKpiCardLoading: false,
        addKpiCardLoaded: true,
      };
    case Actions.DELETE_KPI_CARDS:
      return {
        ...state,
        deleteKpiCardLoading: true,
        deleteKpiCardLoaded: false,
      };
    case Actions.DELETE_KPI_CARDS_COMPLETED:
      return {
        ...state,
        deleteKpiCardLoading: false,
        deleteKpiCardLoaded: true,
      };
    case Actions.EDIT_KPI_CARD:
      return {
        ...state,
        editKpiCardLoading: true,
        editKpiCardLoaded: false,
      };
    case Actions.EDIT_KPI_CARD_COMPLETED:
      return {
        ...state,
        editKpiCardLoading: false,
        editKpiCardLoaded: true,
      };
    case Actions.DUPLICATE_KPI_CARD:
      return {
        ...state,
        duplicateKpiCardLoading: true,
        duplicateKpiCardLoaded: false,
      };
    case Actions.DUPLICATE_KPI_CARD_COMPLETED:
      return {
        ...state,
        duplicateKpiCardLoading: false,
        duplicateKpiCardLoaded: true,
      };
    default:
      return state;
  }
}
