import { AbstractCacheService, ICacheService } from '../../../shared/service/cache-service';
import { Injectable } from '@angular/core';
import { BaseOneResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';


export interface IUserAvatarCacheService extends ICacheService<BaseOneResponseInterface<string>> {}

@Injectable({
  providedIn: 'root'
})
export class UserAvatarCacheService extends AbstractCacheService<BaseOneResponseInterface<string>>
  implements IUserAvatarCacheService {
  protected override MAX_CACHE_SIZE: number = 100;
}
