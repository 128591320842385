import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as ObjectActions from './weavy.actions';
import { catchError, switchMap } from 'rxjs/operators';
import { WeavyService } from '../../shared/service/weavy/weavy.service';
import { from, of } from 'rxjs';
import { IWeavyJWT } from './weavy.model';

@Injectable()
export class WeavyEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly http: HttpClient,
    private readonly weavyService: WeavyService,
  ) {}

  jwtLoading$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.WeavyActionTypes.JWT_LOADING),
      switchMap((objectData: ObjectActions.JwtLoading) => {
        if (WeavyService.jwtToken) {
          return of(new ObjectActions.JwtLoaded(WeavyService.jwtToken));
        }

        const params = new HttpParams().set('username', objectData.username).set('client', objectData.client);

        return from(this.weavyService.getJWT(params)).pipe(
          switchMap((response: IWeavyJWT) => {
            if (!response.success) {
              return of(new ObjectActions.WeavyError());
            }

            WeavyService.jwtToken = response;

            return of(new ObjectActions.JwtLoaded(response));
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchError(err));
          }),
        );
      }),
      catchError((err) => {
        return of(new ObjectActions.FetchError(err));
      }),
    ),
  );
}
