import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import { BaseOneResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { ActivityViewDataInterface } from './activity-view.model';
import { ActivityViewComponent } from '../../../view/dashboards/activity-view/activity-view.component';

@Injectable({
  providedIn: 'root',
})
export class ActivityViewService {
  public component: ActivityViewComponent;

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly api: string,
    public store: Store<oeeAppReducer.OeeAppState>,
  ) {}

  private readonly routes = {
    activityView: '/lines/:lineId/activity-view',
  };

  public getActivityViewData(lineId: number): Observable<BaseOneResponseInterface<ActivityViewDataInterface>> {
    const url: string = `${this.api}${this.routes.activityView.replace(':lineId', String(lineId))}`;

    return this.http.get<BaseOneResponseInterface<ActivityViewDataInterface>>(url);
  }
}
