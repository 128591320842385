import * as Actions from './activity-view.actions';
import { ActivityViewStateInterface } from './activity-view.model';

const initialState: ActivityViewStateInterface = {
  activityViewDataLoading: false,
  activityViewDataLoaded: false,
  activityViewData: null,
};

export function activityViewReducer(
  state: ActivityViewStateInterface = initialState,
  action: Actions.ActivityViewActions,
): ActivityViewStateInterface {
  switch (action.type) {
    case Actions.EActivityViewAction.ACTIVITY_VIEW_DATA_LOAD:
      return {
        ...state,
        activityViewDataLoading: true,
        activityViewDataLoaded: false,
      };
    case Actions.EActivityViewAction.ACTIVITY_VIEW_DATA_LOADED:
      return {
        ...state,
        activityViewDataLoading: false,
        activityViewDataLoaded: true,
        activityViewData: action.data,
      };
    default:
      return state;
  }
}
