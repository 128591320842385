import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { HelperService } from '../service/helper.service';

@Pipe({
  name: 'momentDate',
})
export class MomentDatePipe implements PipeTransform {
  constructor(private readonly datePipe: DatePipe, private readonly helperService: HelperService) {}

  transform(
    value: moment.Moment | string | number | Date,
    format?: string,
    options?: { withoutYear: boolean },
    locale?: string,
    timezone?: string,
  ): any {
    const date = moment(value);
    const dateValue = value && date.isValid() ? date.toDate() : null;

    if (dateValue) {
      const momentFormatted = date.format(format);

      if (moment(momentFormatted, format, true).isValid()) {
        return options && options.withoutYear ? this.helperService.removeYear(date, momentFormatted) : momentFormatted;
      }
    }

    return this.datePipe.transform(dateValue, format, locale, timezone);
  }
}
