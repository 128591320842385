import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';
import { take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../../store/oee.reducer';
import { User } from '../../../store/user/model';

@Injectable({
  providedIn: 'root',
})
export class PagesWithoutMenusGuard implements CanActivate {
  constructor(
    public router: Router,
    public http: HttpClient,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
  ) {}

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      this.store
        .select('user')
        .pipe(take(1))
        .subscribe((state: User) => {
          if (state.isUserLoaded && !state.isUserLoading && !_.isNil(state.userLevelId)) {
            if (state.menuPermissions) {
              this.router.navigate(['/error/forbidden']);
              observer.next(false);
            } else {
              observer.next(true);
            }
          }
        });
    });
  }
}
