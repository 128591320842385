import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as ObjectActions from './root-cause-group.actions';
import * as oeeAppReducer from '../../oee.reducer';
import { from, of } from 'rxjs';
import { RootCauseGroupService } from '../../../shared/service/settings/root-cause-group/root-cause-group.service';
import {
  RootCauseGroupBulkOperationResponseInterface,
  RootCauseGroupCRUDDataInterface,
  RootCauseGroupDeleteResponseInterface,
  RootCauseGroupGetOneInterface,
} from './root-cause-group.model';
import * as AppActions from '../../app/actions';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ErrorMessageService } from '../../../shared/service/error-message.service';
import { ExcelHelperService } from '../../../shared/service/excel/excel-helper.service';
import { RootCauseTypeCRUDInterface } from '../../../shared/component/filter/filter.class';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import * as _ from 'lodash';
import { emptyAction } from '../../../../constants';

@Injectable()
export class RootCauseGroupEffects {
  constructor(
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly actions$: Actions,
    private readonly service: RootCauseGroupService,
    private readonly toast: ToastrService,
    private readonly translate: TranslateService,
    private readonly errorMessageService: ErrorMessageService,
    private readonly excelHelperService: ExcelHelperService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
  ) {}

  getRootCauseGroupData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ROOT_CAUSE_GROUP_DATA_LOADING),
      switchMap((objectData: ObjectActions.RootCauseGroupDataLoading) => {
        let httpParams: HttpParams = new HttpParams()
          .append('join', 'site||name')
          .append('join', 'rootCauseType||name')
          .append('page', String(objectData.data.page))
          .append('limit', String(objectData.data.rowsPerPage || 1000))
          .append('sort', 'id,DESC');

        if (
          objectData.data.search !== undefined ||
          (objectData.data.siteIds !== undefined && objectData.data.siteIds !== -1)
        ) {
          httpParams = httpParams.append('s', RootCauseGroupEffects.getRootCauseGroupSearchParams(objectData));
        }

        if (!_.isEmpty(objectData.data.sort.column)) {
          httpParams = httpParams.set('sort', `${objectData.data.sort.column},${objectData.data.sort.type}`);
        }

        return this.service.getRootCauseGroups(httpParams).pipe(
          switchMap((response: GetManyResponseInterface<RootCauseGroupGetOneInterface>) => {
            return of(new ObjectActions.RootCauseGroupDataLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.RootCauseGroupFetchError(errorRes));
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.RootCauseGroupFetchError(errorRes));
      }),
    ),
  );

  createRootCauseGroup = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.CREATE_ROOT_CAUSE_GROUP),
      switchMap((objectData: ObjectActions.CreateRootCauseGroup) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return from(this.service.createRootCauseGroup(objectData.data)).pipe(
          switchMap((response: BaseOneResponseInterface<RootCauseGroupCRUDDataInterface>) => {
            return of(new ObjectActions.CreateRootCauseGroupCompleted(response), new AppActions.HideLoader());
          }),
          catchError((err) => {
            return of(new ObjectActions.RootCauseGroupFetchError(err), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.RootCauseGroupFetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  updateRootCauseGroup = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.UPDATE_ROOT_CAUSE_GROUP),
      switchMap((objectData: ObjectActions.UpdateRootCauseGroup) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return from(this.service.updateRootCauseGroup(objectData.id, objectData.data)).pipe(
          switchMap((response: RootCauseGroupCRUDDataInterface) => {
            return of(new ObjectActions.UpdateRootCauseGroupCompleted(response), new AppActions.HideLoader());
          }),
          catchError((err) => {
            return of(new ObjectActions.RootCauseGroupFetchError(err), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.RootCauseGroupFetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  deleteRootCauseGroups = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DELETE_ROOT_CAUSE_GROUPS),
      switchMap((objectData: ObjectActions.DeleteRootCauseGroups) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.deleteRootCauseGroups(objectData.payload).pipe(
          switchMap((response: RootCauseGroupDeleteResponseInterface) => {
            this.errorMessageService.getTranslatedErrorMessage(response.data);
            return of(
              new ObjectActions.DeleteRootCauseGroupsCompleted(objectData.payload.length > 1, response),
              new AppActions.HideLoader(),
            );
          }),
          catchError((err) => {
            return of(new ObjectActions.RootCauseGroupFetchError(err), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.RootCauseGroupFetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  deleteRootCauseTypes = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DELETE_ROOT_CAUSE_TYPES),
      switchMap((objectData: ObjectActions.DeleteRootCauseTypes) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return from(this.service.deleteRootCauseTypes(objectData.payload)).pipe(
          switchMap((response: RootCauseGroupDeleteResponseInterface) => {
            return of(
              new ObjectActions.DeleteRootCauseTypeCompleted(objectData.payload.length > 1, response),
              new AppActions.HideLoader(),
            );
          }),
          catchError((err) => {
            return of(new ObjectActions.RootCauseTypeFetchError(err), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.RootCauseTypeFetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getRootCauseTypeData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ROOT_CAUSE_TYPE_DATA_LOADING),
      switchMap((objectData: ObjectActions.RootCauseTypeDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        let httpParams: HttpParams = new HttpParams()
          .append('join', 'rootCauseType||name')
          .append('page', String(objectData.data.page))
          .append('limit', String(objectData.data.rowsPerPage || 1000))
          .append('sort', 'id,DESC');

        if (objectData.data.search !== undefined) {
          httpParams = httpParams.append('s', RootCauseGroupEffects.getRootCauseTypeSearchParams(objectData));
        }

        if (!_.isEmpty(objectData.data.sort.column)) {
          httpParams = httpParams.set('sort', `${objectData.data.sort.column},${objectData.data.sort.type}`);
        }

        return this.service.getRootCauseTypes(httpParams).pipe(
          switchMap((response: GetManyResponseInterface<RootCauseTypeCRUDInterface>) => {
            return of(new ObjectActions.RootCauseTypeDataLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.RootCauseTypeFetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.RootCauseTypeFetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  createRootCauseType = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.CREATE_ROOT_CAUSE_TYPE),
      switchMap((objectData: ObjectActions.CreateRootCauseType) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.createRootCauseType(objectData.data).pipe(
          switchMap((response: RootCauseTypeCRUDInterface) => {
            return of(new ObjectActions.CreateRootCauseTypeCompleted(response), new AppActions.HideLoader());
          }),
          catchError((err) => {
            return of(new ObjectActions.RootCauseTypeFetchError(err), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.RootCauseGroupFetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  updateRootCauseType = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.UPDATE_ROOT_CAUSE_TYPE),
      switchMap((objectData: ObjectActions.UpdateRootCauseType) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return from(this.service.updateRootCauseType(objectData.id, objectData.data)).pipe(
          switchMap((response: RootCauseTypeCRUDInterface) => {
            return of(new ObjectActions.UpdateRootCauseTypeCompleted(response), new AppActions.HideLoader());
          }),
          catchError((err) => {
            return of(new ObjectActions.RootCauseTypeFetchError(err), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.RootCauseGroupFetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  getRootCauseGroupCount = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_ROOT_CAUSE_GROUP_COUNT),
      switchMap((objectData: ObjectActions.GetRootCauseGroupCount) => {
        return from(this.service.getRootCauseGroupData(objectData.data, true)).pipe(
          switchMap((response: GetManyResponseInterface<RootCauseGroupGetOneInterface>) => {
            return of(new ObjectActions.GetRootCauseGroupCountCompleted(response));
          }),
          catchError((err) => {
            return of(new ObjectActions.RootCauseGroupFetchError(err));
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.RootCauseGroupFetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  downloadRootCauseGroupExcel = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DOWNLOAD_ROOT_CAUSE_GROUP_EXCEL),
      switchMap((objectData: ObjectActions.DownloadRootCauseGroupExcel) => {
        this.service.downloadRootCauseGroupExcel(objectData.withData, objectData.filters);
        return emptyAction;
      }),
    ),
  );

  uploadRootCauseGroupExcel = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.UPLOAD_ROOT_CAUSE_GROUP_EXCEL),
      switchMap((objectData: ObjectActions.UploadRootCauseGroupExcel) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.uploadRootCauseGroupExcel(objectData.rootCauseGroups).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            this.errorMessageService.getTranslatedErrorMessage(response.data);
            const mergedArray =
              this.excelHelperService.mergeBulkResponseWithRequestData<RootCauseGroupCRUDDataInterface>(
                response,
                objectData.rootCauseGroups.rootCauseGroups,
              );
            return of(new ObjectActions.UploadRootCauseGroupExcelCompleted(mergedArray), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.RootCauseGroupFetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  bulkEditRootCauseGroup = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.UPDATE_ROOT_CAUSE_GROUPS),
      switchMap((objectData: ObjectActions.UpdateRootCauseGroups) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.bulkEditRootCauseGroup(objectData.rootCauseGroups).pipe(
          switchMap((response: GetManyResponseInterface<RootCauseGroupBulkOperationResponseInterface>) => {
            return of(new ObjectActions.UpdateRootCauseGroupsCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.RootCauseGroupFetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.RootCauseGroupFetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  getRootCauseGroupsFilterData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ROOT_CAUSE_GROUP_FILTER_DATA_LOADING),
      switchMap((payload: ObjectActions.RootCauseGroupFilterDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        let httpParams: HttpParams = new HttpParams()
          .append('join', 'site||id')
          .append('limit', String(payload.limit || 1000));

        if (payload.siteId) {
          httpParams = httpParams.set(
            's',
            JSON.stringify({
              siteId: { $eq: payload.siteId },
            }),
          );
        }

        return this.service.getRootCauseGroupsFilterData(httpParams).pipe(
          switchMap((response) => {
            return of(new ObjectActions.RootCauseGroupFilterDataLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.RootCauseGroupFetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.RootCauseGroupFetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  private static getRootCauseGroupSearchParams(payload: ObjectActions.RootCauseGroupDataLoading): string {
    return JSON.stringify({
      $and: [
        payload.data.siteIds !== -1
          ? {
              siteId: {
                $in: payload.data.siteIds,
              },
            }
          : {},
        payload.data.rootCauseTypeId !== -1
          ? {
              rootCauseTypeId: {
                $in: payload.data.rootCauseTypeId,
              },
            }
          : {},
        {
          $or: [
            {
              'site.name': {
                $cont: payload.data.search,
              },
            },
            {
              'rootCauseType.name': {
                $cont: payload.data.search,
              },
            },
            {
              name: {
                $cont: payload.data.search,
              },
            },
            {
              description: {
                $cont: payload.data.search,
              },
            },
          ],
        },
      ],
    });
  }

  private static getRootCauseTypeSearchParams(payload: ObjectActions.RootCauseTypeDataLoading): string {
    return JSON.stringify({
      $and: [
        {
          $or: [
            {
              'rootCauseType.name': {
                $cont: payload.data.search,
              },
            },
            {
              name: {
                $cont: payload.data.search,
              },
            },
          ],
        },
      ],
    });
  }
}
