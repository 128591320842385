import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as ObjectActions from './source-object.actions';
import { CICOService } from '../../../shared/service/cico/cico.service';
import {
  GetDistinctCheckInObjectsInterfaceData,
  GetDistinctEquipmentsInterface,
  GetDistinctUsersInterface,
} from '../../check-in/check-in.model';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { EntityTranslatorService } from 'src/app/shared/service/entity-translator/entity-translator.service';

@Injectable()
export class FilterSourceObjectsEffects {
  constructor(
    private readonly actions$: Actions<ObjectActions.FilterSourceObjectActions>,
    public cicoService: CICOService,
    private entityTranslator: EntityTranslatorService,
  ) {}

  loadFilterSourceObjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.FilterSourceObjectActionTypes.StartLoadFilterSourceObjects),
      switchMap((objectData: ObjectActions.StartLoadFilterSourceObjects) => {
        return from(
          this.cicoService.getDistinctCheckInObjects({
            sourceTypeId: objectData.sourceObjectType,
            startDate: '',
            endDate: '',
          }),
        ).pipe(
          switchMap((response: GetManyResponseInterface<GetDistinctCheckInObjectsInterfaceData>) => {
            response.data.forEach((checkIn: GetDistinctCheckInObjectsInterfaceData) =>
              this.entityTranslator.translate(checkIn),
            );

            return of(new ObjectActions.FilterSourceObjectsLoaded(response.data));
          }),
          catchError((error) => {
            return of(new ObjectActions.FilterSourceObjectsLoaded(error));
          }),
        );
      }),
    ),
  );
}
