import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.state';
import * as ComparisonFilterCardActions from '../../../../store/comparison-filter-card/comparison-filter-card.actions';
import { DropdownOptionInterface } from '../../scw-mat-ui/scw-mat-select/scw-mat-select.model';

@Component({
  selector: 'app-comparison-filter-card',
  templateUrl: './comparison-filter-card.component.html',
  styleUrls: ['./comparison-filter-card.component.scss'],
})
export class ComparisonFilterCardComponent {
  @Input() comparisonConfiguration: any = {};
  @Input() comparisonFilterCardConfiguration: any = [];
  @Output() groupTypeChangeEvent: EventEmitter<any> = new EventEmitter<any>();
  public selectedComparisonFilterCard: DropdownOptionInterface[] = [];

  constructor(private readonly store: Store<AppState>) {}

  public comparisonGroupTypeChanged(): void {
    this.groupTypeChangeEvent.emit(this.selectedComparisonFilterCard);
    this.store.dispatch(new ComparisonFilterCardActions.SetComparisonGroupType(this.selectedComparisonFilterCard));
  }
}
