import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { LineService } from '../../../shared/service/filter/line.service';
import * as ObjectActions from './line.actions';
import { generateHttpOption } from '../../../shared/helper/app-helper';
import { LineCRUDInterface } from '../../../shared/component/filter/filter.class';

@Injectable()
export class FilterLineEffects {
  loadFilterLines$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.FilterLineActionTypes.StartLoadFilterLines),
      switchMap((objectData: ObjectActions.StartLoadFilterLines) => {
        const params = generateHttpOption(objectData.options);

        return from(this.service.getData(params)).pipe(
          switchMap((response: LineCRUDInterface[]) => {
            response.forEach((value: any) => {
              if (value.lineTypeName !== undefined) {
                value.typeName = value.lineTypeName.lineType;
                delete value.lineTypeName;
              }
            });
            return of(new ObjectActions.FilterLinesLoaded(response));
          }),
          catchError((err) => {
            return of(new ObjectActions.FilterLinesLoaded([]));
          }),
        );
      }),
    ),
  );

  constructor(private actions$: Actions<ObjectActions.FilterLineActions>, public service: LineService) {}
}
