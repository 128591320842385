import * as echarts from 'echarts';
import { ISelectWorkOrderModalConfigurations } from '../../view/home/work-order/work-order.model';
import {
  PhaseDurationsInterface,
  HomeMetricSetItemInterface,
  BaseCrudResponse,
} from '../home-screen-metric-settings/home-screen-metric-settings.model';
import { ShiftSummaryCommentInterface } from '../shift-summary/shift-summary.model';
import { ShiftSummaryCommentObjectPropertyTypes } from '../../shared/model/enum/shift-summary-comment-object-property-types';
import { WorkOrderCloseFeedbackInterface } from '../work-order/work-order.model';
import { ICicoBulkEdit } from '../../view/home/cico/cico.model';
import { WorkOrderScheduleInterface } from '../work-order-schedule/work-order-schedule.model';
import { TOtherLanguageOptions } from '../../../constants';
import {
  IEntityTranslation,
  IObjectWithEntityTranslations,
} from '../../shared/service/entity-translator/entity-translator.model';
import { BulkResponseDataInterface } from '../../shared/model/interface/crud-response-interface.model';

export interface HomeStateInterface extends HomeInformationDataInterface {
  errors: any[];
  generatedActivityButtons: HomeActivityButtonInterface[];
  homeInformationLoading: boolean;
  homeInformationLoaded: boolean;
  activityTreeGraphData: any[];
  cicoAccess: boolean;
  cicoPending: boolean;
  saveWorkOrderFeedbackLoading: boolean;
  saveWorkOrderFeedbackLoaded: boolean;
  getWorkOrderFeedbackLoading: boolean;
  getWorkOrderFeedbackLoaded: boolean;
  updateWorkOrderFeedbackLoading: boolean;
  updateWorkOrderFeedbackLoaded: boolean;
  workOrderCloseFeedback: WorkOrderCloseFeedbackInterface;
  activityTreeGraphDataLoading: boolean;
  activityTreeGraphDataLoaded: boolean;
  changeActivityLoading: boolean;
  changeActivityCompleted: boolean;
  updateCurrentActivityLoading: boolean;
  checkWorkOrderLoading: boolean;
  updateWorkOrderLoading: boolean;
  getWorkOrderLoading: boolean;
  workOrderStatusLoading: boolean;
  getVersionDetailLoading: boolean;
  getProductPerformanceLoading: boolean;
  productSearchLoading: boolean;
  getWorkOrderNumbersLoading: boolean;
  searchUsersByFilterLoading: boolean;
  searchAssetsByFilterLoading: boolean;
  homeMetricSetItemsLoading: boolean;
  homeMetricSetItemsLoaded: boolean;
  homeMetricSetItems: HomeMetricSetItemInterface[];
  phaseDurationLoading: boolean;
  phaseDurationLoaded: boolean;
  phaseDurations: PhaseDurationsInterface;
  phaseComments: PhaseCommentStoreDataInterface;
  phaseCommentsLoading: boolean;
  phaseCommentsLoaded: boolean;
  phaseCommentSubmitLoading: boolean;
  phaseCommentSubmitLoaded: boolean;
  isDigitalFormsActive: boolean;
  isAutomatedPhaseCommentsActive: boolean;
  phaseCommentsEnteredStatus: PhaseCommentsEnteredStatusInterface;
  previousPhase: number;
  getLineInfoThatSelectedWorkOrderLoading: boolean;
  getLineInfoThatSelectedWorkOrderLoaded: boolean;
  getLineInfoThatSelectedWorkOrderData: ILineInfoThatWorkOrder | null;
  splitWorkOrderOnLinePredictedWorkOrderNumberLoading: boolean;
  splitWorkOrderOnLinePredictedWorkOrderNumberLoaded: boolean;
  splitWorkOrderOnLinePredictedWorkOrderNumberData: string | null;
  requestType: RequestTypeEnum;
}

export interface UserInterface {
  isSiteTourViewed: number;
}

export interface ActivityButtonInterface {
  activityCount?: number;
  activityName: string;
  activitySubType?: string;
  activityType: string;
  activityTypeTitle: string;
  id: string;
}

export interface ActivityCardInterface extends IObjectWithEntityTranslations {
  activityAverageSpeed: string;
  activityCrewSize?: string;
  activityDescription: string;
  activityId: number;
  activityName: string;
  activityNameTranslations: TOtherLanguageOptions;
  activityStart: string;
  activitySubType?: string;
  activityTarget?: string;
  activityType: string;
  batchFinalizer: boolean;
  equipmentName: string;
  goodCount: string;
  ignoreSensor: boolean;
  initialCount: string;
  scrapCount: string;
  taskName: string;
  taskNameTranslations: TOtherLanguageOptions;
  workOrderDuration: string;
  ignorePhase: boolean;
  selectedPhase: number;
  isMissingData: boolean;
  isCommentRequired: boolean;
  missingActivityMode: boolean;
  meta?: {
    withoutWorkOrder: number;
  };
}

export interface WorkOrderCardInterface extends IObjectWithEntityTranslations {
  averageSpeed: string;
  expectedSpeed: string;
  productDescription: string;
  productId: string;
  productTableId: number;
  versionId?: number;
  versionName?: string;
  wOTableId: number;
  woDueDate: string;
  workOrderDuration: string;
  workOrderGoodCount: string;
  workOrderId: string;
  workOrderQuantityOrdered: string;
  workOrderTarget: number;
  selectedPhase?: number;
  ignorePhase?: boolean;
  phaseCommentsEnteredStatus: PhaseCommentsEnteredStatusInterface;
  unitName: string;
  quantityWithCommentCount: number;
  goodSpeed: string;
  woSpeed: string;
  speedProgress?: string;
  start?: string;
  end?: string;
}

export interface LineInterface {
  activityCard: ActivityCardInterface;
  missingDataCount: number;
  workOrderCard: WorkOrderCardInterface;
  snoozeUntil: string;
  lineType?: number;
  oeeFormula: string;
  isAutomatedPhaseCommentsActive: boolean;
  siteDecimalScaleLimit: number;
  isWorkOrderSplitActive: boolean;
  isWorkOrderTakeoverBySplitActive: boolean;
}

export interface HomeInformationDataInterface {
  line: LineInterface;
  user: UserInterface;
}

export interface HomeInformationInterface {
  data: HomeInformationDataInterface;
  success: boolean;
}

export interface HomeActivityButtonIconInterface {
  activityCardClass: string;
  activityCardIconClass: string;
  activityDurationTextClass: string;
  activityDurationProgressClass: string;
}

export interface HomeActivityButtonIcon extends HomeActivityButtonIconInterface {
  failure?: HomeActivityButtonIconInterface;
}

export interface HomeActivityButtonInterface {
  id: string;
  name: string;
  type: string;
  typeTitle: string;
  class: string;
  icon: HomeActivityButtonIcon;
}

export interface TreeChartServiceInterface {
  success: boolean;
  data: echarts.TreeSeriesOption[];
}

export interface CicoDataInterface extends ICicoBulkEdit {
  checkInTime: string;
  icon: string;
  id: number;
  name: string;
  status: number;
  message?: string;
  checkOutTime: string;
  destinationTypeId: number;
  destinationObjectId: number;
  sourceTypeId: number;
  sourceObjectId: number;
  lineStationName: string;
  checkedOut: boolean;
  recentlyCheckedIn: boolean;
  show?: boolean;
  statusName?: string;
  statusColor?: string;
  avatarPath?: string | null;
  avatar?: string | null;
}

export interface ChangeActivityRequestInterface {
  siteId?: number;
  lineId?: number;
  activityId: number;
  taskId?: number;
  goodCount?: number;
  scrapCount?: number;
  finalizeWorkOrder?: boolean;
}

export interface UpdateCurrentActivityInterface {
  selectedCrewSize?: string;
  selectedDescription?: string;
  selectedActivity?: number;
  timer?: string;
  selectedTaskId?: number;
  workOrderId?: number;
  selectedPhaseId?: number;
}

export interface CheckWorkOrderRequestInterface {
  lineId: number;
  siteId: number;
  workOrder: string;
}

export interface CheckWorkOrderResponseInterface {
  completed?: boolean;
  confirm: boolean;
  line?: CheckWorkOrderResponseLineInterface;
  lineName?: string;
  products?: CheckWorkOrderResponseProductsInterface[];
  workOrderName?: string;
}

export interface CheckWorkOrderResponseProductsInterface {
  Id: string;
  PlanningTypeId: string;
  PlanningTypeName: string;
  UnitId: string;
  UnitName: string;
  colorCode: string | null;
  customerName: string;
  description: string;
  isExistBillOfMaterials: string;
  isExistResources: string;
  isExistVersions: string;
  lineIds: string;
  materialTypeId: string;
  materialTypeName: string;
  moleculeName: string;
  packageSize: string;
  planningGroup: string;
  productId: string;
  productSpeed: string;
  siteId: string;
  siteName: string;
}

export interface CheckWorkOrderResponseLineInterface {
  ActualCompleted: boolean | null;
  ActualRunDate: string | null;
  Completed: boolean | null;
  FinalQuantity: number | null;
  FinalScrap: number | null;
  Id: string;
  ItemDescription: string | null;
  LineId: number | null;
  LineName: string | null;
  OeeA: string | null;
  OeeEndDate: string | null;
  OeeGoodCount: number | null;
  OeeInitialCount: number | null;
  oeeOverallOee: string | null;
  OeeP: string | null;
  OeeQ: string | null;
  OeeStartDate: string | null;
  OeeTotalDownDuration: string | null;
  OeeTotalRunDuration: string | null;
  PackageSize: number | null;
  ProductId: string;
  ProductTableId: number | null;
  QuantityOrdered: string;
  ScheduledEndDate: string | null;
  ScheduledLine: string | null;
  ScheduledPostDuration: string;
  ScheduledPreDuration: string;
  ScheduledRunDate: string | null;
  ScheduledRunDuration: string;
  SiteId: string;
  TargetDuration: string;
  WoDueDate: string | null;
  WoId: string;
  WoNumber: string | null;
  WoType: string | null;
  siteName: string | null;
  sequenceNumber: string;
  jobNumber: string;
}

export interface UpdateWorkOrderRequestInterface {
  completed: boolean;
  countType?: number;
}

export interface UpdateWorkOrderResponseInterface {
  test: any;
}

export interface GetWorkOrderRequestInterface {
  workOrderTableId: string;
}

export interface GetWorkOrderResponseInterface {
  Completed: boolean | null;
  FinalQuantity: string | null;
  ScheduledEndDateFromScheduler: string | null;
  ScheduledRunDateFromScheduler: string | null;
  actualCompleted: boolean | null;
  actualRunDate: string;
  actualRunDateTime: string;
  id: string;
  packageSize: string;
  productId: number;
  productName: string;
  quantityOrdered: string;
  scheduledEndDate: string;
  scheduledEndDateTime: string;
  scheduledLine: string | null;
  scheduledLineTitle: string | null;
  scheduledPostDuration: string;
  scheduledPreDuration: string;
  scheduledRunDate: string;
  scheduledRunDateTime: string;
  scheduledRunDuration: string;
  siteId: number;
  targetDuration: string;
  usedLine: string;
  versionName: string | null;
  woDueDate: string;
  woNumber: string;
  woType: string | null;
  sequenceNumber: string;
  jobNumber: string;
}

export interface WorkOrderStatusRequestInterface {
  id: number;
}

export interface GetVersionDetailRequestInterface {
  versionId: string | number;
}

export interface GetVersionDetailResponseInterface {
  data: any[];
  success: boolean;
}

export interface GetProductPerformanceRequestInterface {
  id: string;
  siteId: string;
  lineId: string;
}

export interface GetProductPerformanceResponseInterface {
  success: boolean;
  data: any[];
}

export enum ProductSearchDispatchAreas {
  OpenProductModal = 'openProductModal',
  SearchProductRequest = 'searchProductRequest',
  OnScroll = 'onScroll',
}

export interface GetWorkOrderNumbersDataResponseInterface extends WorkOrderUpdateDataInterface {
  finalScrap?: string | null;
  lineId?: string | null;
  lineName?: string | null;
  scheduledEndDate?: string | null;
  woId?: string | null;
}

export interface WorkOrderAddEditResponseInterface {
  response: GetWorkOrderNumbersDataResponseInterface;
}

export interface GetWorkOrderNumbersRequestInterface {
  workOrderFilter: 'all' | 'line';
  dispatchedBy: string;
  page?: number;
  search?: string;
  selectWorkOrderModalConfigurations?: ISelectWorkOrderModalConfigurations;
  workOrderListOrderBy?: boolean;
}

export interface WorkOrderUpdateDataInterface {
  actualCompleted?: boolean | null;
  actualRunDate?: string | null;
  completed?: boolean | null;
  finalQuantity?: string | null;
  id?: number;
  itemDescription?: string;
  oeeA?: string | null;
  oeeEndDate?: string | null;
  oeeGoodCount?: string | null;
  oeeInitialCount?: string | null;
  oeeOverallOee?: string | null;
  oeeP?: string | null;
  oeeQ?: string | null;
  oeeStartDate?: string | null;
  oeeTotalDownDuration?: string | null;
  oeeTotalRunDuration?: string | null;
  packageSize?: string | null;
  productId: number;
  productTableId?: string | null;
  quantityOrdered: string;
  scheduledLine?: string | null;
  scheduledLineId?: string;
  scheduledPostDuration: string;
  scheduledPreDuration: string;
  scheduledPreRunLaborHours: string;
  scheduledRunLaborHours: string;
  scheduledPostRunLaborHours: string;
  scheduledRunDate?: string | null;
  scheduledRunDuration: string;
  scheduledStartDateFromScheduler?: string | null;
  siteId?: number;
  siteName?: string | null;
  woDueDate?: string | null;
  woType?: string | null;
  sequenceNumber: number;
  jobNumber: string;
  woNumber?: string;
  processOrder: string;
  operationNumber: string;
}

export interface UpdateCurrentActivityResponseInterface {
  success: boolean;
  data: UpdateCurrentActivityResponseModel;
  date: string;
}

export interface WorkOrderCloseStatisticsInterface {
  actual: string;
  actualFinal: string;
  standard: string;
  variance: string;
  variancePercentage: string;
  formatScale?: string;
}

export interface OEEStatisticsInterface {
  overall: number;
  a: number;
  p: number;
  q: number;
}

export enum OEEBarStyles {
  OeeSuccess = 'oee-success',
  OeeNormal = 'oee-normal',
  OeeFail = 'oee-fail',
  OeeNone = 'oee-none',
}

export interface OEEBarStylesInterface {
  overall: OEEBarStyles;
  a: OEEBarStyles;
  p: OEEBarStyles;
  q: OEEBarStyles;
}

export interface WorkOrderCloseDataInterface extends IObjectWithEntityTranslations{
  productDescription: string;
  workOrderId: number;
  workOrderNumber: string;
  finalQuantity: string;
  finalScrap: string;
  oee: OEEStatisticsInterface;
  oeeBarStyles: OEEBarStylesInterface;
  yield: WorkOrderCloseStatisticsInterface;
  scrap: WorkOrderCloseStatisticsInterface;
  idleTime: WorkOrderCloseStatisticsInterface;
  machineHour: WorkOrderCloseStatisticsInterface;
  laborHour: WorkOrderCloseStatisticsInterface;
}

export interface WorkOrderCloseFormDataResponseInterface extends WorkOrderCloseDataInterface {
  upperLimit: number;
}

export interface WorkOrderCloseResponseInterface {
  success: boolean;
  data: WorkOrderCloseFormDataResponseInterface;
}

export interface UpdateGoodAndScrapResponseInterface {
  oldWorkOrderTableId: number;
  finalQuantity: number;
  finalScrap: number;
  type: string;
}

export interface PhaseCommentInterface {
  id: number;
  message: string | null;
}

export interface PhaseCommentStoreDataInterface {
  preRun: PhaseCommentInterface;
  run: PhaseCommentInterface;
  postRun: PhaseCommentInterface;
}

export interface PhaseCommentStoreUpdateDataInterface {
  preRun?: PhaseCommentInterface;
  run?: PhaseCommentInterface;
  postRun?: PhaseCommentInterface;
}

export interface PhaseCommentCrudDataInterface extends ShiftSummaryCommentInterface {
  objectProperty: ShiftSummaryCommentObjectPropertyTypes;
}

export interface PhaseCommentCrudUpdateResponseInterface extends BaseCrudResponse {
  data: PhaseCommentCrudDataInterface;
}

export interface PhaseCommentsInterface {
  commentDate: string;
  commentId: number;
  commentMessage: string;
  commentTypeId: number;
  objectId: number;
  objectProperty: ShiftSummaryCommentObjectPropertyTypes;
  objectType: string;
  phaseCommentsEnteredStatus: PhaseCommentsEnteredStatusInterface;
}

export interface PhaseCommentsResponseInterface {
  comments: PhaseCommentsInterface[];
  phaseCommentsEnteredStatus: PhaseCommentsEnteredStatusInterface;
}

export enum CountTypeEnum {
  MANUAL = 1,
  FINAL = 2,
}

export interface UpdateCurrentActivityResponseModel extends IObjectWithEntityTranslations{
  timer: string;
  selectedActivity: number;
  selectedTaskId: number;
  selectedDescription: string;
  selectedBatchNumber: string;
  standardSpeed: string;
  selectedCrewSize: string;
  selectedPhaseId: number | null;
  currentActivity: CurrentActivityModel;
  currentTask: CurrentTaskModel;
  currentWorkOrder: CurrentWorkOrderModel;
}

export interface CurrentWorkOrderModel {
  id: number;
  woDueDate: string;
  woNumber: string;
  quantityOrdered: string;
  product: CurrentProductModel;
  scheduledPreDuration: string;
  scheduledPostDuration: string;
  scheduledRunDuration: string;
  completed: number;
  phaseCommentsEnteredStatus: PhaseCommentsEnteredStatusInterface;
  jobNumber?: string;
  processOrder?: string;
}

export interface CurrentTaskModel {
  id: number;
  title: string;
  titleTranslations: TOtherLanguageOptions;
  batchFinalizer: number;
  target: string;
  ignorePhase: number;
  ignoreSensor: number;
  isMissingData: number;
  equipment: {
    id: number;
    equipmentName: string;
  };
  meta: {
    withoutWorkOrder: number;
  };
}

export interface CurrentActivityModel {
  id: number;
  name: string;
  nameTranslations: TOtherLanguageOptions;
  activityType: string;
  activitySubType: CurrentActivitySubTypeInterface;
}

interface CurrentProductModel {
  id: number;
  description: string;
  productId: string;
  productSpeed: string;
  productSpeedTable: CurrentProductSpeedInterface;
  productInfo?: string;
}

interface CurrentActivitySubTypeInterface {
  type: string;
}

interface CurrentProductSpeedInterface {
  speed: number;
}

export interface GetQuantityFromSensorInterface {
  success: boolean;
  data: GetQuantityFromSensorDataInterface;
  date: string;
}

export interface GetQuantityFromSensorDataInterface {
  quantity: string;
  initialQuantity: string;
  runTimeAverageSpeed: string;
  scrapQuantity: string;
  batchQuantity: string;
  batchDuration: string;
  batchAverageSpeed: string;
  batchSpeed: string;
  batchGoodSpeed: string;
  batchDurationCurrentActivity: string;
  quantityWithCommentCount: string | number;
}

export interface PhaseCommentsEnteredStatusInterface {
  isPreRunCommentEntered: boolean;
  isRunCommentEntered: boolean;
  isPostRunCommentEntered: boolean;
}

export interface ILineInfoThatWorkOrder {
  runLineIds: string | null;
  runLineName: string | null;
  predictedWorkOrderNumber: string | null;
  isWorkOrderRanOnAnotherLine: boolean;
  isWorkOrderTakeoverBySplitActive: boolean;
}

export interface CheckWorkOrderCompletedResponseInterface {
  payload: WorkOrderScheduleInterface[];
  type: string;
}

export interface IWorkOrderCloseTableData {
  actual: string;
  standard: string;
  variance: string;
  variancePercentage: string;
  actualFinal?: string;
  header?: string;
}

export interface ICurrentActivity {
  isCommentRequired: boolean;
  activityAndTaskName: string;
  activityName: string;
  comment: string;
}

export enum RequestTypeEnum {
  DEFAULT = 1,
  ACTIVITY_COMMENT = 2,
}

export interface IRemoveWorkOrderRequestBody {
  workOrderId: number | null;
  isRemoveWorkOrder: boolean;
}

export interface IUpdateLineInformationCompleteResponse {
  response: BulkResponseDataInterface;
}

export interface IRequestChangeActivityParameters {
  taskId: number | null;
  activityId: number | null;
}
