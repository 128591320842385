import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  AlertPauseGetResponseInterface,
  AlertPauseUpdateRequestInterface,
  AlertPauseUpdateResponseInterface,
} from './alert-pause.model';

@Injectable({
  providedIn: 'root',
})
export class AlertPauseService {
  constructor(public http: HttpClient, @Inject('API_BASE_URL') private baseUrl: string) {}

  private SNOOZE_UNTIL = {
    CRUD: {
      DATA_URL: `${this.baseUrl}/lines`,
    },
  };

  getAlertPause(id: number): Observable<AlertPauseGetResponseInterface> {
    const options = {
      params: new HttpParams().set('fields', 'snoozeUntil'),
    };
    return this.http.get<AlertPauseGetResponseInterface>(`${this.SNOOZE_UNTIL.CRUD.DATA_URL}/${id}`, options);
  }

  updateAlertPause(
    requestData: AlertPauseUpdateRequestInterface,
    id: number,
  ): Observable<AlertPauseUpdateResponseInterface> {
    return this.http.patch<AlertPauseUpdateResponseInterface>(
      `${this.SNOOZE_UNTIL.CRUD.DATA_URL}/${id}/alert-snooze`,
      requestData,
    );
  }
}
