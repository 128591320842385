<div class="scw-mat-button-group-container" [ngClass]="class">
  <ng-template ngFor let-button let-i="index" [ngForOf]="buttons">
    <scw-mat-button
      type="standard"
      [className]="{
        'scw-button-group-item-button': true,
        'first-item': i === 0,
        'last-item': buttons.length === 1 || buttons.length === i + 1,
        'icon-button': !button.text && button.iconClass,
        active: button.value === inputModel && !stateless,
        'button-xs': size === 'xs',
        'button-sm': size === 'sm',
        'button-md': size === 'md',
        'button-lg': size === 'lg',
        'text-truncate': textTruncate
      }"
      (onClick)="onNgModelChange(button.value)"
      [disabled]="disabled || button.disabled"
    >
      <em
        *ngIf="button.iconClass && (!button.iconPosition || button.iconPosition === 'left')"
        [ngClass]="button.iconClass"
      ></em>
      {{ button.text }}
      <em
        *ngIf="button.iconClass && button.iconPosition === 'right'"
        [ngClass]="button.iconClass"
      ></em>
    </scw-mat-button>
  </ng-template>
</div>
