import { Action } from '@ngrx/store';
import { UpdateMultipleActivityDatesRequestInterface } from '../../view/activity-review/activity-review.model';
import { UpdateMultipleActivitiesResponseInterface } from './activity-review.model';
import { ResponseArrayInterface } from '../../shared/model/interface/generic-api-response.model';
import { IActivityHistory } from '../../shared/service/activity-history/activity-history.model';
import { ICurrentLine } from '../line/model';
import { BulkResponseDataInterface } from '../../shared/model/interface/crud-response-interface.model';

export const START_ACTIVITY_HISTORY_DATA_LOADING = '[ACTIVITY_REVIEW] START ACTIVITY HISTORY DATA LOADING';
export const ACTIVITY_HISTORY_DATA_LOADED = '[ACTIVITY_REVIEW] ACTIVITY HISTORY DATA LOADED';
export const START_CURRENT_LINE_DATA_LOADING = '[ACTIVITY_REVIEW] START CURRENT LINE DATA LOADING';
export const CURRENT_LINE_DATA_LOADED = '[ACTIVITY_REVIEW] CURRENT LINE DATA LOADED';
export const UPDATE_ACTIVITY_DATES = '[ACTIVITY_REVIEW] UPDATE ACTIVITY DATES';
export const DATES_UPDATED = '[ACTIVITY_REVIEW] DATES UPDATED';
export const UPDATE_RESULT_SHOWN = '[ACTIVITY_REVIEW] UPDATE RESULT SHOWN';
export const BULK_DELETE_ACTIVITIES = '[ACTIVITY_REVIEW] BULK DELETE ACTIVITIES';
export const BULK_DELETE_ACTIVITIES_DONE = '[ACTIVITY_REVIEW] BULK DELETE ACTIVITIES DONE';
export const FETCH_ERROR = '[ACTIVITY_REVIEW] FETCH ERROR';

export class StartActivityHistoryDataLoading implements Action {
  readonly type = START_ACTIVITY_HISTORY_DATA_LOADING;

  constructor(
    public searchObject: object,
    public stallLoader: boolean = false,
    public page?: number,
    public limit?: number,
  ) {}
}

export class ActivityHistoryDataLoaded implements Action {
  readonly type = ACTIVITY_HISTORY_DATA_LOADED;

  constructor(public payload: ResponseArrayInterface<IActivityHistory>) {}
}

export class StartCurrentLineDataLoading implements Action {
  readonly type = START_CURRENT_LINE_DATA_LOADING;

  constructor(public lineId: number, public stallLoader: boolean = false) {}
}

export class CurrentLineDataLoaded implements Action {
  readonly type = CURRENT_LINE_DATA_LOADED;

  constructor(public payload: ICurrentLine) {}
}

export class UpdateActivityDates implements Action {
  readonly type = UPDATE_ACTIVITY_DATES;

  constructor(public updateData: UpdateMultipleActivityDatesRequestInterface, public stallLoader: boolean = false) {}
}

export class DatesUpdated implements Action {
  readonly type = DATES_UPDATED;

  constructor(public payload: UpdateMultipleActivitiesResponseInterface) {}
}

export class BulkDeleteActivities implements Action {
  readonly type = BULK_DELETE_ACTIVITIES;

  constructor(public activityIds: number[]) {}
}

export class BulkDeleteActivitiesDone implements Action {
  readonly type = BULK_DELETE_ACTIVITIES_DONE;

  constructor(public payload: BulkResponseDataInterface) {}
}

export class UpdateResultShown implements Action {
  readonly type = UPDATE_RESULT_SHOWN;

  constructor() {}
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;

  constructor(public payload: any[], public actionType?: string) {}
}

export type ActivityReviewActions =
  | StartActivityHistoryDataLoading
  | ActivityHistoryDataLoaded
  | StartCurrentLineDataLoading
  | CurrentLineDataLoaded
  | UpdateActivityDates
  | DatesUpdated
  | BulkDeleteActivities
  | BulkDeleteActivitiesDone
  | UpdateResultShown
  | FetchError;
