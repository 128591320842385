import { PhaseSelectionStateInterface } from './phase-selection.model';
import { GET_PHASES, GET_PHASES_COMPLETED, PhaseSelectionActions } from './phase-selection.actions';

export const initialState: PhaseSelectionStateInterface = {
  phasesLoading: false,
  phasesLoaded: false,
  phases: null,
  lineId: null,
};

export function phaseSelectionReducer(
  state = initialState,
  action: PhaseSelectionActions,
): PhaseSelectionStateInterface {
  switch (action.type) {
    case GET_PHASES:
      return {
        ...state,
        ...{
          lineId: action.lineId,
        },
        phasesLoading: true,
        phasesLoaded: false,
      };
    case GET_PHASES_COMPLETED:
      return {
        ...state,
        ...{
          phases: action.response.data,
        },
        phasesLoading: false,
        phasesLoaded: true,
      };
    default:
      return state;
  }
}
