import { Action } from '@ngrx/store';
import { SiteInterface } from '../site-line-selection/site-line-selection.model';
import {
  GetNavigationInformationResponseInterface,
  IAllowedDomain,
  InterfaceCustomMailGroupInterface,
} from './main.model';
import { IMenuPermissions } from '../../../constants';

export enum MainActionTypes {
  GetActiveSites = '[Main] Get Active Sites',
  GetActiveSitesCompleted = '[Main] Get Active Sites Completed',
  GetNavigationMenu = '[Main] Get Navigation Menu',
  GetNavigationMenuCompleted = '[Main] Get Navigation Menu Completed',
  SendContactSupport = '[Main] Send Contact Support',
  SendContactSupportCompleted = '[Main] Send Contact Support Completed',
  SwitchHomePage = '[Main] Switch Home Page',
  GetCustomMailGroups = '[Main] Get Custom Notifications Mail Groups',
  GetCustomMailGroupsCompleted = '[Main] Get Custom Notifications Mail Groups Completed',
  AllowedDomains = '[Main] Get Allowed Domains',
  AllowedDomainsCompleted = '[Main] Get Allowed Domains Completed',
  FetchError = '[Main] Fetch Error',
  SetSiteModuleStatuses = '[Main] Set site module statuses',
}

export class FetchError implements Action {
  readonly type = MainActionTypes.FetchError;

  constructor(public payload: any[]) {}
}

export class GetActiveSites implements Action {
  readonly type = MainActionTypes.GetActiveSites;
}

export class GetActiveSitesCompleted implements Action {
  readonly type = MainActionTypes.GetActiveSitesCompleted;

  constructor(public data: SiteInterface[], public success: boolean) {}
}

export class SetSiteModuleStatuses implements Action {
  readonly type = MainActionTypes.SetSiteModuleStatuses;

  constructor(public data: Partial<IMenuPermissions['modules']>) {}
}

export class GetNavigationMenu implements Action {
  readonly type = MainActionTypes.GetNavigationMenu;
}

export class GetNavigationMenuCompleted implements Action {
  readonly type = MainActionTypes.GetNavigationMenuCompleted;

  constructor(public data: GetNavigationInformationResponseInterface, public success: boolean) {}
}

export class SendContactSupport implements Action {
  readonly type = MainActionTypes.SendContactSupport;

  constructor(
    public contactType: string,
    public textSubject: string,
    public textBody: string,
    public mail: boolean,
    public sms: boolean,
    public userId: string,
    public customMailGroupId: number | null = null,
    public siteId: number,
    public lineId: number,
    public fromUserId: number,
  ) {}
}

export class SendContactSupportCompleted implements Action {
  readonly type = MainActionTypes.SendContactSupportCompleted;

  constructor(public payload: string) {}
}

export class SwitchHomePage implements Action {
  readonly type = MainActionTypes.SwitchHomePage;

  constructor(public status: boolean) {}
}

export class GetCustomMailGroups implements Action {
  readonly type = MainActionTypes.GetCustomMailGroups;

  constructor(public siteId: number) {}
}

export class GetCustomMailGroupsCompleted implements Action {
  readonly type = MainActionTypes.GetCustomMailGroupsCompleted;

  constructor(public data: InterfaceCustomMailGroupInterface[]) {}
}

export class AllowedDomains implements Action {
  readonly type = MainActionTypes.AllowedDomains;

  constructor() {}
}

export class AllowedDomainsCompleted implements Action {
  readonly type = MainActionTypes.AllowedDomainsCompleted;

  constructor(public data: IAllowedDomain[]) {}
}

export type MainActions =
  | FetchError
  | SetSiteModuleStatuses
  | GetActiveSites
  | GetActiveSitesCompleted
  | GetNavigationMenu
  | GetNavigationMenuCompleted
  | SendContactSupport
  | SendContactSupportCompleted
  | GetCustomMailGroups
  | GetCustomMailGroupsCompleted
  | AllowedDomains
  | AllowedDomainsCompleted
  | SwitchHomePage;
