import { HttpErrorResponse } from '@angular/common/http';
import { PreparedFilterDataInterface } from '../../shared/service/page-filters-and-settings/page-filters-and-settings.service';
import { ITableHeader } from '../../../constants.model';
import { DropdownOptionsInterface } from '../../view/settings/tasks/tasks.model';
import { ICustomConfigurationDbEntry } from '../../shared/component/page-configuration/page-configuration.model';
import { IObjectWithEntityTranslations } from '../../shared/service/entity-translator/entity-translator.model';

export interface ShiftSummaryStateInterface {
  shifts: Shift[];
  shiftSummaryData: ShiftSummaryDataInterface[];
  totalDurations: ShiftSummaryTotalDurationsInterface;
  taskGroups: TaskGroupsInterface[];
  shiftIsLoaded: boolean;
  shiftIsLoading: boolean;
  shiftSummaryIsLoaded: boolean;
  shiftSummaryIsLoading: boolean;
  commentsIsLoading: boolean;
  isFiltersLoading: boolean;
  isFiltersLoaded: boolean;
  isFiltersSaving: boolean;
  filters: PreparedFilterDataInterface;
  selectedFilters: SelectedFilters;
  setSelectedFiltersIsLoading: boolean;
  tableGroupSettings: ITableHeader[];
  tableSettings: ITableHeader[];
  customConfiguration: ICustomConfigurationDbEntry[];
  columnNames: ColumnNames;
  shiftComments: ShiftCommentsInterface[];
  errors: HttpErrorResponse[];
}

export interface SelectedFilters {
  filters: PreparedFilterDataInterface;
  shiftInformation: ShiftInformationInterface;
}

export interface Shift {
  client: string;
  id: number;
  shiftInfo: string;
  shiftName: string;
  weekDayEnd: number;
  weekDayStart: number;
  formattedEndDate: string;
  formattedStartDate: string;
}

export interface ShiftSummaryDataInterface extends ShiftSummaryTotalDurationsInterface, IObjectWithEntityTranslations {
  lineId: number;
  lineName: string;
  workOrderId: number;
  workOrder: string;
  productId: number;
  productDescription: string;
  descriptiveProductId: string;
  checkedInOperators: string;
  maxEndDate: string;
  minStartDate: string;
  workOrderPreRunPhaseComment: string;
  workOrderRunPhaseComment: string;
  workOrderPostRunPhaseComment: string;
  workOrderPreRunPhaseCommentCreatedAt: string;
  workOrderRunPhaseCommentCreatedAt: string;
  workOrderPostRunPhaseCommentCreatedAt: string;
  workOrderPreRunPhaseCommentChangedAt: string;
  workOrderRunPhaseCommentChangedAt: string;
  workOrderPostRunPhaseCommentChangedAt: string;
  shiftId: string;
  shiftDay: string;
  processOrder: string;
  operationNumber: string;
  taskGroups: TaskGroupDataInterface[];
  totalduration: string;
  totaldurationSecond: number;
  supervisorCommentDate: string;
  supervisorCommentId: number;
  supervisorCommentObjectType: string;
  supervisorCommentTypeId: number;
  supervisorComments: string;
  siteId: number;
  shiftName: string;
  runPhaseDurationSecond: number;
  runPhaseName: string;
  checkedIns: string;
  laborHourSecond: number;
  maintenanceCommentDate: string;
  maintenanceCommentId: number;
  maintenanceCommentObjectType: string;
  maintenanceCommentTypeId: number;
  maintenanceComments: string;
  percentage: number;
  postRunPhaseDurationSecond: number;
  postRunPhaseName: string;
  preRunPhaseDurationSecond: number;
  preRunPhaseName: string;
  shiftGoalComments: string;
  shiftEndStatusComments: string;
  shiftGoalHitComments: string | number;
  shiftTopDownTimeActivityComments: string;
  shiftActionItemComments: string;
  nextShiftGoalComments: string;
  shiftGoalCommentId: string;
  shiftEndStatusCommentId: string;
  shiftGoalHitCommentId: string;
  shiftTopDownTimeActivityCommentId: string;
  shiftActionItemCommentId: string;
  nextShiftGoalCommentId: string;
}

export interface ITableData extends ShiftSummaryDataInterface {
  workOrderPreRunPhaseComments: IPhaseComment[];
  workOrderRunPhaseComments: IPhaseComment[];
  workOrderPostRunPhaseComments: IPhaseComment[];
  shouldChangeLineHeight: boolean;
}

export interface IPhaseComment {
  workOrder: string;
  comment: string;
  createdAt: string;
  changedAt: string;
}

export interface ShiftSummaryTotalDurationsInterface {
  laborHour: string;
  preRunPhaseDuration: string;
  runPhaseDuration: string;
  postRunPhaseDuration: string;
  preRunCheckInDuration: string;
  runCheckInDuration: string;
  postRunCheckInDuration: string;
  changeOverPhaseDuration: string;
  groups: TaskGroupDataInterface[];
}

export interface TaskGroupDataInterface {
  id: number;
  name: string;
  value: string;
  seconds: number;
}

export interface TaskGroupsInterface {
  id: number;
  name: string;
}

export interface ColumnNames {
  preRunColumnName: string;
  postRunColumnName: string;
  runColumnName: string;
}

export interface ShiftCommentsInterface {
  commentId: number;
  commentMessage: string | DropdownOptionsInterface[];
  shiftId: number;
  shiftDay: string;
  shiftName: string;
  commentTypeId: number;
}

export interface ShiftSummaryResponseInterface {
  totalDurations: ShiftSummaryTotalDurationsInterface;
  data: ShiftSummaryDataInterface[];
  taskGroups: TaskGroupsInterface[];
  columnNames: ColumnNames;
  shiftComments: ShiftCommentsInterface[];
}

export interface ShiftSummaryFilterInterface {
  site: number[];
  department: number[];
  observation: number[];
}

export interface ShiftSummaryShiftResponseInterface
  extends ShiftSummaryBasicResponseInterface<ShiftSummaryShiftResponseDataInterface> {}

export interface ShiftSummaryShiftResponseDataInterface {
  shifts: Shift[];
}

export interface ShiftSummaryCommentInterface {
  id: number | null;
  commentTypeId: number;
  objectType: string;
  objectId: number | string;
  commentMessage: string;
  commentDate?: string | null;
  objectProperty?: string;
  siteId?: number;
  workOrderId?: number;
  shiftId?: number;
  shiftDay?: string;
}

export interface ShiftSummaryBasicResponseInterface<T> {
  success: boolean;
  data: T;
}

export interface ShiftSummaryCommentSaveResponseInterface {
  errors: string[];
}

export enum ShiftInformation {
  currentShift = 'current_shift',
  previousShift = 'previous_shift',
  other = 'other',
}

export enum CommentObjectType {
  lineComment = 'lines',
  shiftComment = 'department',
}

export type ShiftInformationInterface = {
  shiftInformation?: ShiftInformation;
  shiftDate?: string;
  shiftId?: number;
};

export interface IGetErpRequest {
  checkedInUsersData: boolean;
  shiftId: number;
  siteId: number;
  workOrderId: number;
  shiftDay: string;
}

export interface IConfirmedPhaseData {
  systemSuggestion: number;
  plannedForConfirmation: number | null;
  shiftActivityOrQuantity: number | null;
  shiftActivityOrQuantityOfOthers: number | null;
}

export interface IConfirmedYieldScrapData {
  systemSuggestion: string;
  plannedForConfirmation: number | null;
  shiftActivityOrQuantity: string | null;
  shiftActivityOrQuantityOfOthers: number | null;
}

export interface ISummaryOfWorkOrderInParticularShiftResponse {
  yield: IConfirmedYieldScrapData;
  scrap: IConfirmedYieldScrapData;
  preRunLabor: IConfirmedPhaseData;
  runLabor: IConfirmedPhaseData;
  postRunLabor: IConfirmedPhaseData;
  preRunMachine: IConfirmedPhaseData;
  runMachine: IConfirmedPhaseData;
  postRunMachine: IConfirmedPhaseData;
  lineNames: string;
  postRunPhaseName: string | null;
  preRunPhaseName: string | null;
  runPhaseName: string | null;
  shiftSummaryErpIntegrationId: number | null;
  totalDuration: number | null;
  unit: string;
  varianceComment: string | null;
}

export interface IErpModalDropdownOptionResponse {
  shiftId: number;
  shiftName: string;
  shiftDay: string;
  planId: number;
  planName: string;
  lineTitles: string;
}

export interface IErpModalDropdownOption extends IErpModalDropdownOptionResponse {
  name: string;
}

export interface IShiftSummaryErpIntegrationCrudRequest {
  shiftDay: string;
  shiftId: number;
  workOrderId: number;
  goodCount: string;
  scrapCount: string;
  preRunLabor: number;
  preRunMachine: number;
  runLabor: number;
  runMachine: number;
  postRunLabor: number;
  postRunMachine: number;
  varianceComment: string;
}
