import { Action } from '@ngrx/store';
import {
  IPeriodicOeeCalculationData,
  IPeriodicOeeCalculationTableQuery,
} from './periodic-oee-calculation-review.model';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';

export const PERIODIC_OEE_CALCULATION_REVIEW_DATA_LOADING =
  '[PERIODIC OEE CALCULATION] PERIODIC OEE CALCULATION REVIEW_DATA LOADING';
export const PERIODIC_OEE_CALCULATION_REVIEW_DATA_LOADED =
  '[PERIODIC OEE CALCULATION] PERIODIC OEE CALCULATION REVIEW_DATA LOADED';
export const FETCH_ERROR = '[PERIODIC OEE CALCULATION] FETCH DATA ERROR';

export class PeriodicOeeCalculationDataLoading implements Action {
  readonly type = PERIODIC_OEE_CALCULATION_REVIEW_DATA_LOADING;

  constructor(public tableQuery: IPeriodicOeeCalculationTableQuery) {}
}

export class PeriodicOeeCalculationDataLoaded implements Action {
  readonly type = PERIODIC_OEE_CALCULATION_REVIEW_DATA_LOADED;

  constructor(public payload: GetManyResponseInterface<IPeriodicOeeCalculationData>) {}
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;

  constructor(public payload: object) {}
}
export type PeriodicOeeCalculationReviewActions =
  | PeriodicOeeCalculationDataLoading
  | PeriodicOeeCalculationDataLoaded
  | FetchError;
