import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import {
  IErpModalDropdownOptionResponse,
  IGetErpRequest,
  IShiftSummaryErpIntegrationCrudRequest,
  ISummaryOfWorkOrderInParticularShiftResponse,
  ShiftSummaryBasicResponseInterface,
  ShiftSummaryCommentInterface,
  ShiftSummaryCommentSaveResponseInterface,
  ShiftSummaryResponseInterface,
} from '../../../store/shift-summary/shift-summary.model';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../model/interface/crud-response-interface.model';
import { forkJoin, Observable, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class GetShiftSummaryService {
  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly toast: ToastrService,
    private readonly translate: TranslateService,
  ) {}

  private readonly bulk = {
    save: '/comments/bulk/save',
    delete: '/comments/bulk/delete',
  };

  public getShiftSummaryAndTaskGroups(params: HttpParams): Promise<ShiftSummaryResponseInterface> {
    return new Promise((resolve, reject) => {
      this.http
        .get(`${this.baseUrl}/get-shift-summary-and-task-groups/`, {
          params,
        })
        .subscribe(
          (response: BaseOneResponseInterface<ShiftSummaryResponseInterface>) => {
            const success = response.success;

            if (success) {
              resolve(response.data);
            } else {
              reject();
            }
          },
          (error: HttpErrorResponse) => {
            reject(error);
          },
        );
    });
  }

  public saveComments(requestData: ShiftSummaryCommentInterface[]): Observable<void> {
    const saveData: ShiftSummaryCommentInterface[] = [];
    const deleteData: number[] = [];

    for (const item of requestData) {
      if (item.id && item.commentMessage === '') {
        deleteData.push(item.id);
      } else {
        saveData.push(item);
      }
    }

    const observables: Observable<
      ShiftSummaryBasicResponseInterface<{} | ShiftSummaryCommentSaveResponseInterface> | BulkResponseDataInterface
    >[] = [];

    if (saveData.length > 0) {
      observables.push(
        this.http.post<ShiftSummaryBasicResponseInterface<{} | ShiftSummaryCommentSaveResponseInterface>>(
          `${this.baseUrl}${this.bulk.save}`,
          { comments: saveData },
        ),
      );
    }

    if (deleteData.length > 0) {
      const deleteHttpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        body: {
          comments: deleteData,
        },
      };
      observables.push(
        this.http.delete<BulkResponseDataInterface>(`${this.baseUrl}${this.bulk.delete}`, deleteHttpOptions),
      );
    }

    if (deleteData.length > 0 || saveData.length > 0) {
      return forkJoin(observables).pipe(
        map((array) => {
          if (_.find(array, { success: false })) {
            this.toast.error(this.translate.instant('general.error'), this.translate.instant('general.failed'));
          }
        }),
      );
    }

    return of(null);
  }

  public getErpModalData(
    parameters: IGetErpRequest,
  ): Observable<BaseOneResponseInterface<ISummaryOfWorkOrderInParticularShiftResponse>> {
    const params: HttpParams = new HttpParams()
      .set('shiftId', parameters.shiftId)
      .set('siteId', parameters.siteId)
      .set('workOrderId', parameters.workOrderId)
      .set('shiftDay', parameters.shiftDay)
      .set('checkedInUsersData', parameters.checkedInUsersData);

    return this.http.get<BaseOneResponseInterface<ISummaryOfWorkOrderInParticularShiftResponse>>(
      `${this.baseUrl}/summary-of-work-order-in-particular-shift`,
      { params },
    );
  }

  public getErpModalDropdownOptions(
    workOrderId: number,
  ): Observable<GetManyResponseInterface<IErpModalDropdownOptionResponse>> {
    const params: HttpParams = new HttpParams().set('workOrderId', workOrderId);

    return this.http.get<GetManyResponseInterface<IErpModalDropdownOptionResponse>>(
      `${this.baseUrl}/get-erp-modal-dropdown-options`,
      { params },
    );
  }

  public createShiftSummaryErpIntegration(dto: IShiftSummaryErpIntegrationCrudRequest): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/shift-summary-erp-integration`, dto);
  }

  public updateShiftSummaryErpIntegration(id: number, dto: IShiftSummaryErpIntegrationCrudRequest): Observable<void> {
    return this.http.patch<void>(`${this.baseUrl}/shift-summary-erp-integration/${id}`, dto);
  }
}
