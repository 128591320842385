import { NotificationGroupsStateInterface } from './notification-groups.model';
import * as Actions from './notification-groups.actions';

export const notificationGroupState: NotificationGroupsStateInterface = {
  notificationGroupsDataLoading: false,
  notificationGroupsDataLoaded: false,
  notificationGroupsSiteDataLoading: false,
  notificationGroupsSiteDataLoaded: false,
  siteData: [],
  notificationGroupData: null,
  notificationGroupsData: null,
  notificationGroupsDeleteLoading: false,
  notificationGroupsDeleteLoaded: false,
  addNotificationGroupLoading: false,
  addNotificationGroupLoaded: false,
  editNotificationGroupLoading: false,
  editNotificationGroupLoaded: false,
  recipientsUserData: [],
  notificationGroupsRecipientsUsersDataLoading: false,
  notificationGroupsRecipientsUsersDataLoaded: false,
};

export function notificationGroupReducer(
  state: NotificationGroupsStateInterface = notificationGroupState,
  action: Actions.NotificationGroupsActions,
): NotificationGroupsStateInterface {
  switch (action.type) {
    case Actions.NOTIFICATION_GROUPS_DATA_LOADING:
      return {
        ...state,
        ...{
          notificationGroupsDataLoading: true,
          notificationGroupsDataLoaded: false,
        },
      };
    case Actions.NOTIFICATION_GROUPS_DATA_LOADED:
      return {
        ...state,
        ...{
          notificationGroupsData: action.payload,
          notificationGroupsDataLoading: false,
          notificationGroupsDataLoaded: true,
        },
      };
    case Actions.NOTIFICATION_GROUPS_SITES_DATA_LOADING:
      return {
        ...state,
        ...{
          notificationGroupsSiteDataLoading: true,
          notificationGroupsSiteDataLoaded: false,
        },
      };
    case Actions.NOTIFICATION_GROUPS_SITES_DATA_LOADED:
      return {
        ...state,
        ...{
          siteData: action.payload,
          notificationGroupsSiteDataLoading: false,
          notificationGroupsSiteDataLoaded: true,
        },
      };
    case Actions.DELETE_NOTIFICATION_GROUPS:
      return {
        ...state,
        notificationGroupsDeleteLoading: true,
        notificationGroupsDeleteLoaded: false,
      };
    case Actions.DELETE_NOTIFICATION_GROUPS_COMPLETED:
      return {
        ...state,
        notificationGroupsDeleteLoading: false,
        notificationGroupsDeleteLoaded: true,
      };
    case Actions.ADD_NOTIFICATION_GROUP:
      return {
        ...state,
        addNotificationGroupLoading: true,
        addNotificationGroupLoaded: false,
      };
    case Actions.ADD_NOTIFICATION_GROUP_COMPLETED:
      return {
        ...state,
        addNotificationGroupLoading: false,
        addNotificationGroupLoaded: true,
      };
    case Actions.EDIT_NOTIFICATION_GROUP:
      return {
        ...state,
        editNotificationGroupLoading: true,
        editNotificationGroupLoaded: false,
      };
    case Actions.EDIT_NOTIFICATION_GROUP_COMPLETED:
      return {
        ...state,
        editNotificationGroupLoading: false,
        editNotificationGroupLoaded: true,
      };
    case Actions.NOTIFICATION_GROUPS_RECIPIENTS_USERS_DATA_LOADING:
      return {
        ...state,
        ...{
          notificationGroupsRecipientsUsersDataLoading: true,
          notificationGroupsRecipientsUsersDataLoaded: false,
        },
      };
    case Actions.NOTIFICATION_GROUPS_RECIPIENTS_USERS_DATA_LOADED:
      return {
        ...state,
        ...{
          recipientsUserData: action.payload,
          notificationGroupsRecipientsUsersDataLoading: false,
          notificationGroupsRecipientsUsersDataLoaded: true,
        },
      };
    case Actions.NOTIFICATION_GROUPS_FETCH_ERROR:
      return {
        ...state,
        notificationGroupsDataLoaded: false,
        notificationGroupsSiteDataLoaded: false,
        notificationGroupsDeleteLoaded: false,
        addNotificationGroupLoaded: false,
        editNotificationGroupLoaded: false,
      };
    default:
      return state;
  }
}
