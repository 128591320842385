import { Action } from '@ngrx/store';
import {
  SiteDataInterface,
  SiteLineFilterDataInterface,
  SiteLineFilterDataForRequestsInterface,
} from './global-view.model';

export const GLOBAL_VIEW_FILTER_DATA_LOADING = '[GLOBAL_VIEW] FILTER DATA LOADING';
export const GLOBAL_VIEW_FILTER_DATA_LOADED = '[GLOBAL_VIEW] FILTER DATA LOADED';
export const GLOBAL_VIEW_FILTER_DATA_GENERATED = '[GLOBAL_VIEW] FILTER DATA GENERATED';
export const GLOBAL_VIEW_SET_SELECTED_SITE_FILTERS = '[GLOBAL_VIEW] SET SELECTED SITE FILTERS';
export const GLOBAL_VIEW_SET_SELECTED_LINE_FILTERS = '[GLOBAL_VIEW] SET SELECTED LINE FILTERS';
export const GLOBAL_VIEW_SET_FILTER_SETTINGS = '[GLOBAL_VIEW] SET SELECTED FILTER SETTINGS';
export const GLOBAL_VIEW_SITE_DATA_LOADING = '[GLOBAL_VIEW] SITE DATA LOADING';
export const GLOBAL_VIEW_SITE_DATA_LOADED = '[GLOBAL_VIEW] SITE DATA LOADED';
export const GLOBAL_VIEW_FETCH_DATA_ERROR = '[GLOBAL_VIEW] FETCH DATA ERROR';

export class FilterDataLoading implements Action {
  readonly type = GLOBAL_VIEW_FILTER_DATA_LOADING;

  constructor(public userId: number) {}
}

export class FilterDataLoaded implements Action {
  readonly type = GLOBAL_VIEW_FILTER_DATA_LOADED;

  constructor(public payload: SiteLineFilterDataInterface) {}
}

export class FilterDataGenerated implements Action {
  readonly type = GLOBAL_VIEW_FILTER_DATA_GENERATED;

  constructor(public payload: SiteLineFilterDataInterface) {}
}

export class SetSelectedSiteFilters implements Action {
  readonly type = GLOBAL_VIEW_SET_SELECTED_SITE_FILTERS;

  constructor(public payload: number[]) {}
}

export class SetSelectedLineFilters implements Action {
  readonly type = GLOBAL_VIEW_SET_SELECTED_LINE_FILTERS;

  constructor(public payload: number[]) {}
}

export class SetFilterSettings implements Action {
  readonly type = GLOBAL_VIEW_SET_FILTER_SETTINGS;

  constructor(public siteIds: number[], public lineIds: number[], public setAsDefault: boolean) {}
}

export class SiteDataLoading implements Action {
  readonly type = GLOBAL_VIEW_SITE_DATA_LOADING;

  constructor(public payload: SiteLineFilterDataForRequestsInterface) {}
}

export class SiteDataLoaded implements Action {
  readonly type = GLOBAL_VIEW_SITE_DATA_LOADED;

  constructor(public payload: SiteDataInterface[]) {}
}

export class FetchDataError implements Action {
  readonly type = GLOBAL_VIEW_FETCH_DATA_ERROR;

  constructor(public payload: object) {}
}

export type GlobalViewActions =
  | FilterDataLoading
  | FilterDataLoaded
  | FilterDataGenerated
  | SetSelectedSiteFilters
  | SetSelectedLineFilters
  | SetFilterSettings
  | SiteDataLoading
  | SiteDataLoaded
  | FetchDataError;
