import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import * as ObjectActions from './line-station.actions';
import { of } from 'rxjs';
import { ResponseArrayInterface } from '../../shared/model/interface/generic-api-response.model';
import { LineStationGetOneCRUDDataInterface } from './line-station.model';
import { LineStationService } from '../../shared/service/line-station/line-station.service';

@Injectable()
export class LineStationEffects {
  constructor(
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly actions$: Actions,
    private readonly http: HttpClient,
    private readonly service: LineStationService,
  ) {}

  getLineStationData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LINE_STATION_DATA_LOADING),
      switchMap((objectData: ObjectActions.LineStationLoading) => {
        return this.service.getLineStationData(objectData.lineId).pipe(
          switchMap((response: ResponseArrayInterface<LineStationGetOneCRUDDataInterface>) => {
            return of(new ObjectActions.LineStationLoaded(response));
          }),
          catchError((err) => {
            return of(new ObjectActions.LineStationFetchError(err));
          }),
        );
      }),
    ),
  );
}
