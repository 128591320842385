import { Component, OnDestroy, OnInit } from '@angular/core';
import * as PageHeaderActions from '../../../../store/page-header/page-header.actions';
import { ActionsSubject, Store } from '@ngrx/store';
import * as oeeAppReducer from '../../../../store/oee.reducer';
import { Subscription } from 'rxjs';
import { ofType } from '@ngrx/effects';
import * as AppActions from '../../../../store/app/actions';

@Component({
  selector: 'app-print-functionality-button',
  templateUrl: './print-functionality-button.component.html',
})
export class PrintFunctionalityButtonComponent implements OnInit, OnDestroy {
  public printFunctionalityButtonDisabled = false;
  private subscriptions: Subscription[] = [];

  constructor(
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly storeActions: ActionsSubject,
  ) {}

  public ngOnInit(): void {
    this.subscriptions.push(
      this.storeActions
        .pipe(ofType(PageHeaderActions.CHANGE_PRINT_BUTTON_STATUS))
        .subscribe((response: PageHeaderActions.ChangePrintButtonStatus) => {
          this.printFunctionalityButtonDisabled = response.isPrintButtonDisabled;
        }),
    );
  }

  public printFunctionalityClicked(): void {
    this.store.dispatch(new AppActions.ShowLoader());

    setTimeout(() => {
      this.store.dispatch(new PageHeaderActions.RunPrintFunctionality(true));
    }, 200);
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
