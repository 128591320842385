import { Action } from '@ngrx/store';
import { LineCRUDInterface } from '../../../shared/component/filter/filter.class';
import {
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { IEquipmentAssignmentsRequestParams } from '../../../view/settings/equipment-assets/equipment-assignments/equipment-assignments.model';
import { IEquipmentsDownloadExcelFilters } from '../equipments/equipments.model';
import { IEquipmentAssignment, IEquipmentAssignmentBulkSaveMany } from './equipment-assignment.model';

export const LOAD_EQUIPMENT_ASSIGNMENT_DATA_COUNT = '[EQUIPMENT ASSIGNMENTS] LOAD EQUIPMENT ASSIGNMENT DATA COUNT';
export const EQUIPMENT_ASSIGNMENT_DATA_COUNT_LOADED = '[EQUIPMENT ASSIGNMENTS] EQUIPMENT ASSIGNMENTS DATA COUNT LOADED';
export const DOWNLOAD_EQUIPMENT_ASSIGNMENT_EXCEL =
  '[EQUIPMENT ASSIGNMENTS] EQUIPMENTS ACTION DOWNLOAD EQUIPMENT ASSIGNMENTS EXCEL';
export const DOWNLOAD_EQUIPMENT_ASSIGNMENT_EXCEL_COMPLETED =
  '[EQUIPMENT ASSIGNMENTS] EQUIPMENTS ACTION DOWNLOAD EQUIPMENT ASSIGNMENTS EXCEL COMPLETED';
export const UPLOAD_EQUIPMENT_ASSIGNMENT_EXCEL =
  '[EQUIPMENT ASSIGNMENTS] EQUIPMENTS ACTION UPLOAD EQUIPMENT ASSIGNMENTS EXCEL';
export const UPLOAD_EQUIPMENT_ASSIGNMENT_EXCEL_COMPLETED =
  '[EQUIPMENT ASSIGNMENTS] EQUIPMENTS ACTION UPLOAD EQUIPMENT ASSIGNMENTS EXCEL COMPLETED';
export const LOAD_EQUIPMENT_ASSIGNMENTS_DATA = '[EQUIPMENT ASSIGNMENTS] LOAD EQUIPMENT ASSIGNMENTS DATA';
export const LOADED_EQUIPMENT_ASSIGNMENTS_DATA = '[EQUIPMENT ASSIGNMENTS] LOADED EQUIPMENT ASSIGNMENTS DATA';
export const SINGLE_EDIT_EQUIPMENT_ASSIGNMENTS_DATA =
  '[EQUIPMENT ASSIGNMENTS] REQUEST SINGLE EDIT EQUIPMENT ASSIGNMENTS DATA';
export const EDITED_SINGLE_EDIT_EQUIPMENT_ASSIGNMENTS_DATA =
  '[EQUIPMENT ASSIGNMENTS] EDITED SINGLE EDIT EQUIPMENT ASSIGNMENTS DATA';
export const EDIT_EQUIPMENT_ASSIGNMENTS_DATA = '[EQUIPMENT ASSIGNMENTS] REQUEST EDIT EQUIPMENT ASSIGNMENTS DATA';
export const EDITED_EQUIPMENT_ASSIGNMENTS_DATA = '[EQUIPMENT ASSIGNMENTS] EDITED EQUIPMENT ASSIGNMENTS DATA';
export const CREATE_EQUIPMENT_ASSIGNMENT = '[EQUIPMENT ASSIGNMENTS] CREATE EQUIPMENT ASSIGNMENT DATA';
export const CREATED_EQUIPMENT_ASSIGNMENT = '[EQUIPMENT ASSIGNMENTS] CREATED EQUIPMENT ASSIGNMENT DATA';
export const DELETE_EQUIPMENT_ASSIGNMENT = '[EQUIPMENT ASSIGNMENTS] DELETE EQUIPMENT ASSIGNMENT';
export const DELETED_EQUIPMENT_ASSIGNMENT = '[EQUIPMENT ASSIGNMENTS] DELETED EQUIPMENT ASSIGNMENT';
export const DELETE_EQUIPMENT_ASSIGNMENTS = '[EQUIPMENT ASSIGNMENTS] DELETE EQUIPMENT ASSIGNMENTS';
export const DELETED_EQUIPMENT_ASSIGNMENTS = '[EQUIPMENT ASSIGNMENTS] DELETED EQUIPMENT ASSIGNMENTS';
export const GET_LINES = '[EQUIPMENT ASSIGNMENTS] GET LINES';
export const GET_LINES_COMPLETED = '[EQUIPMENT ASSIGNMENTS] GET LINES COMPLETED';
export const EQUIPMENT_ASSIGNMENTS_FETCH_ERROR = '[EQUIPMENT ASSIGNMENTS] EQUIPMENT ASSIGNMENTS ACTION FETCH ERROR';

export class LoadEquipmentAssignmentsData implements Action {
  readonly type = LOAD_EQUIPMENT_ASSIGNMENTS_DATA;

  constructor(public requestParams: IEquipmentAssignmentsRequestParams) {}
}

export class LoadedEquipmentAssignmentsData implements Action {
  readonly type = LOADED_EQUIPMENT_ASSIGNMENTS_DATA;

  constructor(public payload: GetManyResponseInterface<IEquipmentAssignment>) {}
}

export class SingleEditEquipmentAssignmentsData implements Action {
  readonly type = SINGLE_EDIT_EQUIPMENT_ASSIGNMENTS_DATA;

  constructor(public equipmentId: number, public payload: Partial<IEquipmentAssignment>) {}
}

export class EditedSingleEditEquipmentAssignmentsData implements Action {
  readonly type = EDITED_SINGLE_EDIT_EQUIPMENT_ASSIGNMENTS_DATA;

  constructor(public response: GetManyResponseInterface<IEquipmentAssignment>) {}
}

export class EditEquipmentAssignmentsData implements Action {
  readonly type = EDIT_EQUIPMENT_ASSIGNMENTS_DATA;

  constructor(public payload: Partial<IEquipmentAssignment>[]) {}
}

export class EditedEquipmentAssignmentsData implements Action {
  readonly type = EDITED_EQUIPMENT_ASSIGNMENTS_DATA;

  constructor(public response: BulkResponseDataInterface) {}
}

export class CreateEquipmentAssignment implements Action {
  readonly type = CREATE_EQUIPMENT_ASSIGNMENT;

  constructor(public payload: Partial<IEquipmentAssignment>) {}
}

export class CreatedEquipmentAssignment implements Action {
  readonly type = CREATED_EQUIPMENT_ASSIGNMENT;

  constructor(public response: GetManyResponseInterface<IEquipmentAssignment>) {}
}

export class DeleteEquipmentAssignment implements Action {
  readonly type = DELETE_EQUIPMENT_ASSIGNMENT;

  constructor(public equipmentAssignmentId: number) {}
}

export class DeletedEquipmentAssignment implements Action {
  readonly type = DELETED_EQUIPMENT_ASSIGNMENT;

  constructor(public response: GetManyResponseInterface<IEquipmentAssignment>) {}
}

export class DeleteEquipmentAssignments implements Action {
  readonly type = DELETE_EQUIPMENT_ASSIGNMENTS;

  constructor(public equipmentAssignmentIds: number[]) {}
}

export class DeletedEquipmentAssignments implements Action {
  readonly type = DELETED_EQUIPMENT_ASSIGNMENTS;

  constructor(public response: BulkResponseDataInterface) {}
}

export class DownloadEquipmentAssignmentExcel implements Action {
  readonly type = DOWNLOAD_EQUIPMENT_ASSIGNMENT_EXCEL;

  constructor(public withData: boolean = false, public filters: IEquipmentsDownloadExcelFilters) {}
}

export class DownloadEquipmentAssignmentExcelCompleted implements Action {
  readonly type = DOWNLOAD_EQUIPMENT_ASSIGNMENT_EXCEL_COMPLETED;
}

export class UploadEquipmentAssignmentExcel implements Action {
  readonly type = UPLOAD_EQUIPMENT_ASSIGNMENT_EXCEL;

  constructor(public equipmentAssignment: IEquipmentAssignmentBulkSaveMany) {}
}

export class UploadEquipmentAssignmentExcelCompleted implements Action {
  readonly type = UPLOAD_EQUIPMENT_ASSIGNMENT_EXCEL_COMPLETED;

  constructor(public payload: (IEquipmentAssignment & { errorMessages?: string })[]) {}
}

export class LoadExcelDataCount implements Action {
  readonly type = LOAD_EQUIPMENT_ASSIGNMENT_DATA_COUNT;

  constructor(public siteId: number) {}
}

export class ExcelDataCountLoaded implements Action {
  readonly type = EQUIPMENT_ASSIGNMENT_DATA_COUNT_LOADED;

  constructor(public payload: GetManyResponseInterface<IEquipmentAssignment>) {}
}

export class GetLines implements Action {
  readonly type = GET_LINES;
}

export class GetLinesCompleted implements Action {
  readonly type = GET_LINES_COMPLETED;

  constructor(public lines: LineCRUDInterface[]) {}
}

export class FetchError implements Action {
  readonly type = EQUIPMENT_ASSIGNMENTS_FETCH_ERROR;
  constructor(public payload: object) {}
}

export type EquipmentAssignmentsActions =
  | GetLines
  | GetLinesCompleted
  | LoadEquipmentAssignmentsData
  | LoadedEquipmentAssignmentsData
  | SingleEditEquipmentAssignmentsData
  | EditedEquipmentAssignmentsData
  | CreateEquipmentAssignment
  | CreatedEquipmentAssignment
  | DeleteEquipmentAssignment
  | DeletedEquipmentAssignment
  | DeleteEquipmentAssignments
  | DeletedEquipmentAssignments
  | DownloadEquipmentAssignmentExcel
  | DownloadEquipmentAssignmentExcelCompleted
  | UploadEquipmentAssignmentExcel
  | UploadEquipmentAssignmentExcelCompleted
  | LoadExcelDataCount
  | ExcelDataCountLoaded
  | FetchError;
