import { TaskDataInterface, TaskGroupsStateInterface } from './task-groups.model';
import { TaskGroupsActions, TaskGroupsActionTypes } from './task-groups.actions';
import * as _ from 'lodash';

export const initialState: TaskGroupsStateInterface = {
  sitesDropdownData: [],
  sitesDropdownDataIsLoading: false,
  sitesDropdownDataIsLoaded: false,
  remainingTasksData: [],
  taskGroupsData: [],
  taskGroupsAndRemainingTasksDataIsLoading: false,
  taskGroupsAndRemainingTasksDataIsLoaded: false,
  deleteTaskGroupPending: false,
  deleteTaskGroupCompleted: false,
  updateTaskGroupPending: false,
  updateTaskGroupCompleted: false,
  createTaskGroupPending: false,
  createTaskGroupCompleted: false,
  moveTaskPending: false,
  moveTaskCompleted: false,
  errors: [],
};

export function taskGroupsReducer(
  state: TaskGroupsStateInterface = initialState,
  action: TaskGroupsActions): TaskGroupsStateInterface {
  let sitesDropdownData = _.clone(state.sitesDropdownData);
  let remainingTasksData = _.clone(state.remainingTasksData);
  let taskGroupsData = _.clone(state.taskGroupsData);

  switch (action.type) {
    case TaskGroupsActionTypes.LoadSiteDropdownData:
      return {
        ...state,
        sitesDropdownDataIsLoading: true,
        sitesDropdownDataIsLoaded: false,
      };

    case TaskGroupsActionTypes.SiteDropdownDataLoaded:
      if (action.payload.success) {
        sitesDropdownData = action.payload.data;
      }

      return {
        ...state,
        sitesDropdownData,
        sitesDropdownDataIsLoading: false,
        sitesDropdownDataIsLoaded: true,
      };

    case TaskGroupsActionTypes.LoadTaskGroupsAndRemainingTasksData:
      return {
        ...state,
        taskGroupsAndRemainingTasksDataIsLoading: true,
        taskGroupsAndRemainingTasksDataIsLoaded: false,
      };

    case TaskGroupsActionTypes.TaskGroupsAndRemainingTasksDataLoaded:
      if (action.payload.success) {
        remainingTasksData = action.payload.data.remainingTasks;
        taskGroupsData = action.payload.data.taskGroups;
      }

      return {
        ...state,
        remainingTasksData,
        taskGroupsData,
        taskGroupsAndRemainingTasksDataIsLoading: false,
        taskGroupsAndRemainingTasksDataIsLoaded: true,
      };

    case TaskGroupsActionTypes.DeleteTaskGroup:
      return {
        ...state,
        deleteTaskGroupPending: true,
        deleteTaskGroupCompleted: false,
      };

    case TaskGroupsActionTypes.DeleteTaskGroupCompleted:

      return {
        ...state,
        taskGroupsData,
        remainingTasksData,
        deleteTaskGroupPending: false,
        deleteTaskGroupCompleted: true,
      };

    case TaskGroupsActionTypes.UpdateTaskGroup:
      return {
        ...state,
        updateTaskGroupPending: true,
        updateTaskGroupCompleted: false,
      };

    case TaskGroupsActionTypes.UpdateTaskGroupCompleted:

      return {
        ...state,
        taskGroupsData,
        updateTaskGroupPending: false,
        updateTaskGroupCompleted: true,
      };

    case TaskGroupsActionTypes.CreateTaskGroup:
      return {
        ...state,
        createTaskGroupPending: true,
        createTaskGroupCompleted: false,
      };

    case TaskGroupsActionTypes.CreateTaskGroupCompleted:

      return {
        ...state,
        taskGroupsData,
        createTaskGroupPending: false,
        createTaskGroupCompleted: true,
      };

    case TaskGroupsActionTypes.MoveTask:
      return {
        ...state,
        moveTaskPending: true,
        moveTaskCompleted: false,
      };

    case TaskGroupsActionTypes.MoveTaskCompleted:
      return {
        ...state,
        moveTaskPending: false,
        moveTaskCompleted: true,
      };

    case TaskGroupsActionTypes.ClearAllData:
      return {
        ...state,
        sitesDropdownData: [],
        remainingTasksData: [],
        taskGroupsData: [],
      };

    case TaskGroupsActionTypes.FetchError:
      state.errors.push(action.payload);

      return {
        ...state,
        sitesDropdownDataIsLoading: false,
        sitesDropdownDataIsLoaded: false,
        taskGroupsAndRemainingTasksDataIsLoading: false,
        taskGroupsAndRemainingTasksDataIsLoaded: false,
        deleteTaskGroupPending: false,
        deleteTaskGroupCompleted: false,
        updateTaskGroupPending: false,
        updateTaskGroupCompleted: false,
        createTaskGroupPending: false,
        createTaskGroupCompleted: false,
        moveTaskPending: false,
        moveTaskCompleted: false,
      };

    default:
      return state;
  }
}
