import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import {
  ShiftSummaryShiftResponseDataInterface,
  ShiftSummaryShiftResponseInterface,
} from '../../../store/shift-summary/shift-summary.model';

@Injectable({
  providedIn: 'root',
})
export class GetShiftService {
  constructor(public http: HttpClient, @Inject('API_BASE_URL') private readonly baseUrl: string) {}

  public getShifts(options?: HttpParams): Promise<ShiftSummaryShiftResponseDataInterface> {
    return new Promise((resolve, reject) => {
      if (options.has('selectedDate') && options.has('siteId') && options.has('departmentId')) {
        this.http.get(`${this.baseUrl}/scheduler/shifts/shift-summaries`, { params: options }).subscribe(
          (response: ShiftSummaryShiftResponseInterface) => {
            if (response.hasOwnProperty('data')) {
              resolve(response.data);
            } else {
              reject();
            }
          },
          (error: HttpErrorResponse) => {
            reject(error);
          },
        );
      } else {
        reject();
      }
    });
  }
}
