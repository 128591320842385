<div style="min-height: 100vh; height: 100vh;" class="d-flex justify-content-center align-items-center w-100">
  <div class="maintenance-container d-flex flex-column justify-content-center align-items-center text-center">
    <div class="maintenance-hex">
      <em class="fas fa-cogs maintenance-hex-icon"></em>
    </div>
    <span class="maintenance-header">{{ 'general.maintenance.header' | translate }}</span>
    <div class="d-flex flex-column justify-content-center align-items-center text-center">
      <span class="maintenance-label">{{ 'general.maintenance.label1' | translate }}</span>
      <span class="maintenance-label">{{ 'general.maintenance.label2' | translate }}</span>
    </div>
  </div>
</div>
