import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import * as ObjectActions from './check-in-log.actions';
import { from, of } from 'rxjs';
import { CheckInLogService } from './check-in-log.service';
import {
  CheckInLogCardDataInterface,
  CheckInLogTableResponseInterface,
  CheckInTableDataCommonInterface,
  CheckInTableGroupedByLineInterface,
  CheckInTableGroupedByNoneInterface,
  GroupedByTypes,
} from './check-in-log.model';
import * as AppActions from '../../app/actions';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import { ErrorMessageService } from '../../../shared/service/error-message.service';
import { ExcelHelperService } from '../../../shared/service/excel/excel-helper.service';
import {
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { EntityTranslatorService } from 'src/app/shared/service/entity-translator/entity-translator.service';
import { emptyAction } from '../../../../constants';

@Injectable()
export class CheckInLogEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly errorMessageService: ErrorMessageService,
    private readonly excelHelperService: ExcelHelperService,
    public service: CheckInLogService,
    private translationService: EntityTranslatorService,
  ) {}

  getCheckInLogCardsData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_CHECK_IN_LOG_CARDS_DATA_LOADING),
      switchMap((objectData: ObjectActions.StartCheckInLogCardsDataLoading) => {
        return from(this.service.getCheckInCardData(objectData.query)).pipe(
          switchMap((response: CheckInLogCardDataInterface) => {
            return of(new ObjectActions.CheckInLogCardsDataLoaded(response));
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchDataError(err));
          }),
        );
      }),
    ),
  );

  getCheckInLogTableData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_CHECK_IN_LOG_TABLE_DATA_LOADING),
      switchMap((objectData: ObjectActions.StartCheckInLogTableDataLoading) => {
        return from(this.service.getCheckInTableData(objectData.query, objectData.groupedBy)).pipe(
          switchMap((response: CheckInLogTableResponseInterface) => {
            response.data.forEach(
              (
                checkInLogTable:
                  | CheckInTableDataCommonInterface
                  | CheckInTableGroupedByNoneInterface
                  | CheckInTableGroupedByLineInterface,
              ) => {
                this.translationService.translate(checkInLogTable);
              },
            );

            return of(new ObjectActions.CheckInLogTableDataLoaded(response), new AppActions.HideLoader());
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchDataError(err));
          }),
        );
      }),
    ),
  );

  $loadLines = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LOAD_LINES),
      switchMap(() => {
        return this.service.loadLines().pipe(
          switchMap((response) => {
            return of(new ObjectActions.LinesLoaded(response));
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchDataError(error));
          }),
        );
      }),
    ),
  );

  $submitCheckInLog = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SUBMIT_CHECK_IN_LOG),
      switchMap((objectData: ObjectActions.SubmitCheckInLog) => {
        this.store.dispatch(new AppActions.ShowTopLoader());

        return this.service.submitCheckInLog(objectData.formType, objectData.checkInLogForm).pipe(
          switchMap((response) => {
            return of(
              new ObjectActions.SubmitCheckInLogCompleted(objectData.formType, response),
              new AppActions.HideTopLoader(),
            );
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchDataError(error), new AppActions.HideTopLoader());
          }),
        );
      }),
    ),
  );

  $deleteCheckInLog = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DELETE_CHECK_IN_LOG),
      switchMap((objectData: ObjectActions.DeleteCheckInLog) => {
        this.store.dispatch(new AppActions.ShowTopLoader());

        return this.service.deleteCheckInLog(objectData.id).pipe(
          switchMap((response) => {
            return of(new ObjectActions.DeleteCheckInLogCompleted(response), new AppActions.HideTopLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchDataError(error), new AppActions.HideTopLoader());
          }),
        );
      }),
    ),
  );

  $bulkDeleteCheckInLog = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.BULK_DELETE_CHECK_IN_LOG),
      switchMap((objectData: ObjectActions.BulkDeleteCheckInLog) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.bulkDeleteCheckInLog(objectData.checkIns).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            this.errorMessageService.getTranslatedErrorMessage(response.data);
            const mergedArray = this.excelHelperService.mergeBulkResponseWithRequestData(
              response,
              objectData.checkIns.map((item) => {
                return { id: item };
              }),
            );
            return of(new ObjectActions.BulkDeleteCheckInLogCompleted(mergedArray), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchDataError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchDataError(error), new AppActions.HideLoader());
      }),
    ),
  );

  downloadExcel = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DOWNLOAD_CHECK_IN_LOG_EXCEL),
      switchMap((objectData: ObjectActions.DownloadCheckInLogsExcel) => {
        this.store.dispatch(new AppActions.ShowLoader());
        this.service.downloadExcel(objectData.queryParams, objectData.groupedBy);
        return emptyAction;
      }),
      catchError((err) => {
        return of(new ObjectActions.FetchDataError(err), new AppActions.HideLoader());
      }),
    ),
  );

  getCheckInLogRangeData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.CHECK_IN_LOGS_RANGE_DATA_LOADING),
      switchMap((objectData: ObjectActions.CheckInLogsRangeDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return from(this.service.getCheckInTableData(objectData.payload, objectData.groupedBy)).pipe(
          switchMap((response: CheckInLogTableResponseInterface) => {
            return of(new ObjectActions.CheckInLogsRangeDataLoaded(response), new AppActions.HideLoader());
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchDataError(err), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((err) => {
        return of(new ObjectActions.FetchDataError(err), new AppActions.HideLoader());
      }),
    ),
  );
}
