import { WorkOrderFinalizeStateInterface } from './work-order-finalize.model';
import { WorkOrderFinalizeActions, WorkOrderFinalizeActionTypes } from './work-order-finalize.actions';

export const workOrderFinalizeInitialState: WorkOrderFinalizeStateInterface = {
  inProgress: false,
  selectedActivityAndTask: null,
};

export function workOrderFinalizeReducer(
  state: WorkOrderFinalizeStateInterface = workOrderFinalizeInitialState,
  action: WorkOrderFinalizeActions,
): WorkOrderFinalizeStateInterface {
  switch (action.type) {
    case WorkOrderFinalizeActionTypes.SetWorkOrderFinalizeInProgress:
      return {
        ...state,
        inProgress: action.inProgress,
      };
    case WorkOrderFinalizeActionTypes.SetSelectedActivityAndTask:
      return {
        ...state,
        selectedActivityAndTask: action.newActivityAndTask,
      };
    default:
      return state;
  }
}
