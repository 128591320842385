import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ScwMatMissingDataComponent} from './scw-mat-missing-data.component';

@NgModule({
  imports: [CommonModule],
  exports: [ScwMatMissingDataComponent],
  declarations: [ScwMatMissingDataComponent],
})
export class ScwMatMissingDataModule {
}
