import * as Actions from './skill-matrix.actions';
import { SkillMatrixStateInterface } from './skill-matrix.model';

export const skillMatrixInitialState: SkillMatrixStateInterface = {
  isSkillMatrixDataLoading: false,
  isSkillMatrixDataLoaded: false,
  skillMatrixData: {
    productsNameArray: [],
    userNameArray: [],
    rawData: [],
    maxDurationInHour: null,
    minDurationInHour: null,
    chartData: [],
    d3ChartData: [],
  },
};

export function skillMatrixReducer(
  state: SkillMatrixStateInterface = skillMatrixInitialState,
  action: Actions.SkillMatrixActions,
): SkillMatrixStateInterface {
  switch (action.type) {
    case Actions.SKILL_MATRIX_DATA_LOADING:
      return {
        ...state,
        isSkillMatrixDataLoading: true,
        isSkillMatrixDataLoaded: false,
      };
    case Actions.SKILL_MATRIX_DATA_LOADED:
      return {
        ...state,
        isSkillMatrixDataLoading: false,
        isSkillMatrixDataLoaded: true,
        skillMatrixData: action.payload,
      };
    case Actions.RESET_SKILL_MATRIX_DATA:
      return {
        ...state,
        isSkillMatrixDataLoading: false,
        isSkillMatrixDataLoaded: true,
        skillMatrixData: skillMatrixInitialState.skillMatrixData,
      };
    case Actions.FETCH_DATA_ERROR:
      return {
        ...state,
        isSkillMatrixDataLoading: false,
        isSkillMatrixDataLoaded: true,
      };
    default:
      return state;
  }
}
