import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import {
  FilterProductActionTypes,
  FilterProductActions,
  FilterProductsLoaded,
  StartLoadFilterProducts,
} from './product.actions';
import { ProductService } from '../../../shared/service/filter/product.service';
import { generateHttpOption } from '../../../shared/helper/app-helper';
import { ProductCRUDInterface } from '../../../shared/component/filter/filter.class';
import { EntityTranslatorService } from '../../../shared/service/entity-translator/entity-translator.service';
import { ProductCRUDResponseInterface } from '../../../shared/service/filter/service.class';

@Injectable()
export class FilterProductEffects {
  loadFilterProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilterProductActionTypes.StartLoadFilterProducts),
      switchMap((objectData: StartLoadFilterProducts) => {
        const params = generateHttpOption(objectData.options);
        return from(this.service.getProductObjects(params)).pipe(
          switchMap((response: ProductCRUDResponseInterface) => {
            response.data.forEach((product: ProductCRUDInterface) => this.entityTranslator.translate(product));
            return of(new FilterProductsLoaded(response.data, response.total, objectData?.isFetchMoreRequest));
          }),
          catchError(() => {
            return of(new FilterProductsLoaded([]));
          }),
        );
      }),
      catchError(() => {
        return of(new FilterProductsLoaded([]));
      }),
    ),
  );

  constructor(
    private actions$: Actions<FilterProductActions>,
    public service: ProductService,
    private readonly entityTranslator: EntityTranslatorService,
  ) {}
}
