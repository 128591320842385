<div class="error-400">
  <div class="container-fluid">
    <div class="row mb40">
      <div class="col-sm-12">
        <div class="text-center">
          <h2>{{ 'error.forbidden' | translate }}</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="text-center">
          <a [routerLink]="['/']"
             class="btn btn-primary btn-lg ripple button-capitalization">{{ 'error.backToLandingPage' | translate }}</a>
        </div>
      </div>
    </div>
  </div>
</div>
