import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  CreateSchedulerShiftInterface,
  EditSchedulerShiftInterface,
  LineAvailabilityResponseInterface,
  LineAvailabilityShiftDataInterface,
  TableQueryParams,
} from '../../../store/line-availability/line-availability.model';
import { Observable } from 'rxjs';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
} from '../../model/interface/crud-response-interface.model';

@Injectable({
  providedIn: 'root',
})
export class ShiftService {
  constructor(public http: HttpClient, @Inject('API_BASE_URL') private api: string) {}

  private routes = {
    schedulerShift: '/scheduler/shifts',
    bulkDelete: '/bulk/delete',
  };

  public getData(
    query: TableQueryParams = {
      page: 1,
      pageSize: 1000,
      search: null,
      searchObject: null,
      query: null,
      join: 'costFactor',
    },
  ): Observable<LineAvailabilityResponseInterface<LineAvailabilityShiftDataInterface>> {
    let searchObject: object = null;

    let httpParams: HttpParams = new HttpParams()
      .set('join', query.join)
      .set('page', query.page)
      .set('limit', query.pageSize);

    if (query.orderBy && query.orderDesc !== undefined) {
      httpParams = httpParams.set('sort', `${query.orderBy},${query.orderDesc ? 'DESC' : 'ASC'}`);
    }

    if (query.searchObject) {
      searchObject = query.searchObject;
    }

    if (query.search) {
      searchObject = searchObject ? searchObject : {};
      Object.assign(searchObject, { name: { $cont: query.search } });
    }

    if (searchObject) {
      httpParams = httpParams.set('s', JSON.stringify(searchObject));
    }

    return this.http.get<LineAvailabilityResponseInterface<LineAvailabilityShiftDataInterface>>(
      `${this.api}${this.routes.schedulerShift}`,
      { params: httpParams },
    );
  }

  public editData(
    data: EditSchedulerShiftInterface,
  ): Observable<LineAvailabilityResponseInterface<LineAvailabilityShiftDataInterface>> {
    return this.http.patch<LineAvailabilityResponseInterface<LineAvailabilityShiftDataInterface>>(
      `${this.api}${this.routes.schedulerShift}/${data.id}`,
      {
        schedulerShiftsSiteAssignments: data.schedulerShiftsSiteAssignment,
        name: data.name,
        costFactorId: data.costFactorId,
        isNotAvailable: data.isNotAvailable,
      },
    );
  }

  public createData(
    data: CreateSchedulerShiftInterface,
  ): Observable<BaseOneResponseInterface<LineAvailabilityShiftDataInterface>> {
    const { name, costFactorId, isNotAvailable, siteIds } = data;
    const schedulerShiftsSiteAssignments = [];

    for (const siteId of siteIds) {
      schedulerShiftsSiteAssignments.push({ siteId: Number(siteId) });
    }

    const requestParameters = {
      name,
      costFactorId,
      isNotAvailable,
      schedulerShiftsSiteAssignments,
    };

    return this.http.post<BaseOneResponseInterface<LineAvailabilityShiftDataInterface>>(
      `${this.api}${this.routes.schedulerShift}`,
      requestParameters,
    );
  }

  public deleteData(id: number): Observable<LineAvailabilityResponseInterface<LineAvailabilityShiftDataInterface>> {
    return this.http.delete<LineAvailabilityResponseInterface<LineAvailabilityShiftDataInterface>>(
      `${this.api}${this.routes.schedulerShift}/${id}`,
    );
  }

  public deleteShifts(ids: number[]): Observable<BulkResponseDataInterface> {
    return this.http.delete<BulkResponseDataInterface>(
      `${this.api}${this.routes.schedulerShift}${this.routes.bulkDelete}`,
      {
        body: { shifts: ids },
      },
    );
  }
}
