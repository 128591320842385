import { Action } from '@ngrx/store';
import {
  DepartmentBulkResponseInterface,
  IDatatableParams,
  IDepartment,
  IEditDepartment,
  IProductionUnit,
} from './departments.model';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../../shared/model/interface/crud-response-interface.model';
import { ResponseArrayInterface } from '../../../../shared/model/interface/generic-api-response.model';

export const DEPARTMENTS_DATA_LOAD = '[DEPARTMENTS] DEPARTMENT DATA LOAD';
export const DEPARTMENTS_DATA_LOADED = '[DEPARTMENTS] DEPARTMENT DATA LOADED';
export const PRODUCTION_UNIT_DATA_LOAD = '[DEPARTMENTS] PRODUCTION UNIT DATA LOAD';
export const PRODUCTION_UNIT_DATA_LOADED = '[DEPARTMENTS] PRODUCTION UNIT DATA LOADED';
export const ADD_DEPARTMENT = '[DEPARTMENTS] ADD DEPARTMENT ';
export const ADD_DEPARTMENT_COMPLETED = '[DEPARTMENTS] ADD DEPARTMENT COMPLETED';
export const EDIT_DEPARTMENT = '[DEPARTMENTS] EDIT DEPARTMENT ';
export const EDIT_DEPARTMENT_COMPLETED = '[DEPARTMENTS] EDIT DEPARTMENT COMPLETED';
export const BULK_EDIT_DEPARTMENTS = '[DEPARTMENTS] BULK EDIT DEPARTMENTS ';
export const BULK_EDIT_DEPARTMENTS_COMPLETED = '[DEPARTMENTS] BULK EDIT DEPARTMENTS COMPLETED';
export const DELETE_DEPARTMENT = '[DEPARTMENTS] DELETE DEPARTMENT ';
export const DELETE_DEPARTMENT_COMPLETED = '[DEPARTMENTS] DELETE DEPARTMENT COMPLETED';
export const BULK_DELETE_DEPARTMENTS = '[DEPARTMENTS] BULK DELETE DEPARTMENTS ';
export const BULK_DELETE_DEPARTMENTS_COMPLETED = '[DEPARTMENTS] BULK DELETE DEPARTMENTS COMPLETED';
export const FETCH_ERROR = '[DEPARTMENTS] FETCH ERROR';

export class DepartmentsDataLoad implements Action {
  readonly type = DEPARTMENTS_DATA_LOAD;

  constructor(public tableQuery: IDatatableParams) {}
}

export class DepartmentsDataLoaded implements Action {
  readonly type = DEPARTMENTS_DATA_LOADED;

  constructor(public payload: ResponseArrayInterface<IDepartment>) {}
}

export class ProductionUnitDataLoad implements Action {
  readonly type = PRODUCTION_UNIT_DATA_LOAD;

  constructor(public tableQuery: IDatatableParams) {}
}

export class ProductionUnitDataLoaded implements Action {
  readonly type = PRODUCTION_UNIT_DATA_LOADED;

  constructor(public payload: ResponseArrayInterface<IProductionUnit>) {}
}

export class AddDepartment implements Action {
  readonly type = ADD_DEPARTMENT;

  constructor(public station: IDepartment) {}
}

export class AddDepartmentCompleted implements Action {
  readonly type = ADD_DEPARTMENT_COMPLETED;

  constructor(public payload: BaseOneResponseInterface<IDepartment>) {}
}

export class EditDepartment implements Action {
  readonly type = EDIT_DEPARTMENT;

  constructor(public station: IDepartment, public departmentId: number) {}
}

export class EditDepartmentCompleted implements Action {
  readonly type = EDIT_DEPARTMENT_COMPLETED;

  constructor(public payload: BaseOneResponseInterface<IDepartment>) {}
}

export class BulkEditDepartments implements Action {
  readonly type = BULK_EDIT_DEPARTMENTS;

  constructor(public departments: IEditDepartment[]) {}
}

export class BulkEditDepartmentsCompleted implements Action {
  readonly type = BULK_EDIT_DEPARTMENTS_COMPLETED;

  constructor(public payload: GetManyResponseInterface<DepartmentBulkResponseInterface>) {}
}

export class DeleteDepartment implements Action {
  readonly type = DELETE_DEPARTMENT;

  constructor(public departmentId: number) {}
}

export class DeleteDepartmentCompleted implements Action {
  readonly type = DELETE_DEPARTMENT_COMPLETED;

  constructor(public payload: BulkResponseDataInterface) {}
}

export class BulkDeleteDepartments implements Action {
  readonly type = BULK_DELETE_DEPARTMENTS;

  constructor(public departmentIds: number[]) {}
}

export class BulkDeleteDepartmentsCompleted implements Action {
  readonly type = BULK_DELETE_DEPARTMENTS_COMPLETED;

  constructor(public payload: BulkResponseDataInterface) {}
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;
  constructor(public payload: object) {}
}

export type DepartmentsActions =
  | DepartmentsDataLoad
  | DepartmentsDataLoaded
  | ProductionUnitDataLoad
  | ProductionUnitDataLoaded
  | EditDepartment
  | EditDepartmentCompleted
  | BulkEditDepartments
  | BulkEditDepartmentsCompleted
  | AddDepartment
  | AddDepartmentCompleted
  | DeleteDepartment
  | DeleteDepartmentCompleted
  | BulkDeleteDepartments
  | BulkDeleteDepartmentsCompleted
  | FetchError;
