import { MenuInterface, MenuSearchableKeyInterface } from '../../store/main/main.model';

export enum NavBarElements {
  live = 'live',
  profile = 'profile',
  shiftReview = 'shiftReview',
  alertControl = 'alertControl',
  notificationControl = 'notificationControl',
  lineStation = 'lineStation',
}

export function onClickedOutsideSidebar(e: Event): void {
  if (
    (this.windowWidth < 1024 && this.toggleOn && this.verticalNavType !== 'offcanvas') ||
    this.verticalEffect === 'overlay'
  ) {
    this.toggleOn = true;
    this.verticalNavType = 'offcanvas';
    this.toggleIcon = 'icon-menu';

    document.querySelector('#main_navbar').classList.remove('show-menu');

    const mainEle = document.querySelectorAll('.pcoded-trigger');

    for (let i = 0; i < mainEle.length; i = i + 1) {
      mainEle[i].classList.remove('pcoded-trigger');
    }
  }
}

export function hoverOutsideSidebar(): void {
  if (this.windowWidth > 1024) {
    const mainEle = document.querySelectorAll('.pcoded-trigger');

    for (let i = 0; i < mainEle.length; i = i + 1) {
      mainEle[i].classList.remove('pcoded-trigger');
    }
  }
}

export function onResize(event): void {
  this.windowWidth = event.target.innerWidth;
  this.setHeaderAttributes(this.windowWidth);

  let reSizeFlag = true;

  if (this.pcodedDeviceType === this.deviceType.tablet && this.windowWidth >= 768 && this.windowWidth <= 1024) {
    reSizeFlag = false;
  } else if (this.pcodedDeviceType === this.deviceType.phone && this.windowWidth < 768) {
    reSizeFlag = false;
  }

  /* for check device */
  if (reSizeFlag) {
    this.setMenuAttributes(this.windowWidth);
  }

  if (this.pcodedDeviceType !== this.deviceType.desktop) {
    this.sidebarDisplay = 'block';
    this.pageHeaderPaddingTop = '0';
  } else {
    this.setSidebarStates();
  }
}

export function toggleSidebarInDesktop(): void {
  this.isSidebarOpened = !this.isSidebarOpened;
  this.sidebarToggleIcon = this.isSidebarOpened ? 'icon-chevron-up' : 'icon-chevron-down';
  this.setSidebarStates();
}

export function setSidebarStates(): void {
  if (this.hideNavMenu) {
    this.pageHeaderPaddingTop = '0';
    this.sidebarDisplay = 'none';
  } else {
    if (this.pcodedDeviceType === this.deviceType.desktop) {
      this.pageHeaderPaddingTop = this.isSidebarOpened ? '50px' : '0';
    } else {
      this.pageHeaderPaddingTop = '0';
    }
    this.sidebarDisplay = this.isSidebarOpened ? 'block' : 'none';
  }
}

export function setHeaderAttributes(windowWidth): void {
  if (windowWidth < 992) {
    this.navRight = 'nav-off';
  } else {
    this.navRight = 'nav-on';
  }
}

export function setMenuAttributes(windowWidth): void {
  if (windowWidth >= 768 && windowWidth <= 1024) {
    this.pcodedDeviceType = this.deviceType.tablet;
    this.verticalNavType = 'offcanvas';
    this.verticalEffect = 'overlay';
    this.toggleIcon = 'icon-menu';
    this.headerFixedTop = '0';
  } else if (windowWidth < 768) {
    this.pcodedDeviceType = this.deviceType.phone;
    this.verticalNavType = 'offcanvas';
    this.verticalEffect = 'overlay';
    this.toggleIcon = 'icon-menu';
    this.headerFixedTop = '0';
  } else {
    this.pcodedDeviceType = this.deviceType.desktop;
    this.verticalNavType = 'expanded';
    this.verticalEffect = 'shrink';
    this.toggleIcon = 'icon-x';
    this.headerFixedTop = '50px';
  }
}

export function closeNavigationBar(): void {
  if (this.pcodedDeviceType === this.deviceType.desktop && this.isSidebarOpened) {
    this.toggleSidebarInDesktop();
  }
}

export function toggleHeaderNavRight(): void {
  this.navRight = this.navRight === 'nav-on' ? 'nav-off' : 'nav-on';
  this.chatTopPosition = this.chatTopPosition === 'nav-on' ? '112px' : '';

  if (this.navRight === 'nav-off' && this.innerChatSlideInOut === 'in') {
    this.toggleInnerChat();
  }

  if (this.navRight === 'nav-off' && this.chatSlideInOut === 'in') {
    this.toggleChat();
  }
}

export function toggleInnerChat(): void {
  this.innerChatSlideInOut = this.innerChatSlideInOut === 'out' ? 'in' : 'out';
}

export function toggleChat(): void {
  if (this.innerChatSlideInOut === 'in') {
    this.innerChatSlideInOut = 'out';
  } else {
    this.chatSlideInOut = this.chatSlideInOut === 'out' ? 'in' : 'out';
  }
}

export function toggleLiveNotification(): void {
  if (this.profileNotification === 'an-animate') {
    this.toggleProfileNotification();
  }

  this.liveNotification = this.liveNotification === 'an-off' ? 'an-animate' : 'an-off';
  this.liveNotificationClass = this.liveNotification === 'an-animate' ? 'show' : '';

  if (this.liveNotification === 'an-animate' && this.innerChatSlideInOut === 'in') {
    this.toggleInnerChat();
  }

  if (this.liveNotification === 'an-animate' && this.chatSlideInOut === 'in') {
    this.toggleChat();
  }
}

export function toggleProfileNotification(): void {
  if (this.liveNotification === 'an-animate') {
    this.toggleLiveNotification();
  }

  this.profileNotification = this.profileNotification === 'an-off' ? 'an-animate' : 'an-off';
  this.profileNotificationClass = this.profileNotification === 'an-animate' ? 'show' : '';

  if (this.profileNotification === 'an-animate' && this.innerChatSlideInOut === 'in') {
    this.toggleInnerChat();
  }

  if (this.profileNotification === 'an-animate' && this.chatSlideInOut === 'in') {
    this.toggleChat();
  }
}

export function toggleOpened(e): void {
  if (this.windowWidth < 1024) {
    this.toggleOn = this.verticalNavType === 'offcanvas' ? true : this.toggleOn;

    if (this.navRight === 'nav-on') {
      this.toggleHeaderNavRight();
    }

    this.verticalNavType = this.verticalNavType === 'expanded' ? 'offcanvas' : 'expanded';
  } else {
    this.verticalNavType = this.verticalNavType === 'expanded' ? 'collapsed' : 'expanded';
  }

  this.toggleIcon = this.verticalNavType === 'expanded' ? 'icon-x' : 'icon-menu';
  this.animateSidebar = 'pcoded-toggle-animate';

  if (this.verticalNavType === 'collapsed') {
    this.perfectDisable = 'disabled';
    this.sidebarFixedHeight = '100%';
  } else {
    this.perfectDisable = '';
  }

  if (this.verticalNavType === 'collapsed' && !this.isHeaderChecked) {
    this.setSidebarPosition();
  }

  setTimeout(() => {
    this.animateSidebar = '';
  }, 500);

  document.querySelector('#main_navbar').classList.toggle('show-menu');
}

export function setSidebarPosition(): void {
  if (this.verticalNavType !== 'collapsed') {
    this.isSidebarChecked = !this.isSidebarChecked;
    this.pcodedSidebarPosition = this.isSidebarChecked ? 'fixed' : 'absolute';
    this.sidebarFixedHeight = this.isSidebarChecked ? 'calc(100vh - 190px)' : '100%';

    if (!this.isHeaderChecked) {
      window.addEventListener('scroll', this.scroll, true);
      window.scrollTo(0, 0);
    }
  }
}

export function toggleShiftReview(): void {
  this.shiftReviewDropdown = this.shiftReviewDropdown === 'an-off' ? 'an-animate' : 'an-off';
  this.shiftReviewDropdownClass = this.shiftReviewDropdown === 'an-animate' ? 'show' : '';
}

export function toggleAlertControl(): void {
  this.alertControlDropdown = this.alertControlDropdown === 'an-off' ? 'an-animate' : 'an-off';
  this.alertControlDropdownClass = this.alertControlDropdown === 'an-animate' ? 'show' : '';
}

export function toggleNotificationControl(): void {
  this.notificationDropdown = this.notificationDropdown === 'an-off' ? 'an-animate' : 'an-off';
  this.notificationDropdownClass = this.notificationDropdown === 'an-animate' ? 'show' : '';
}

export function toggleLineStation(): void {
  this.lineStationDropdown = this.lineStationDropdown === 'an-off' ? 'an-animate' : 'an-off';
  this.lineStationDropdownClass = this.lineStationDropdown === 'an-animate' ? 'show' : '';
}

export function notificationOutsideClick(ele: NavBarElements): void {
  if (ele === NavBarElements.live && this.liveNotification === 'an-animate') {
    this.toggleLiveNotification();
  } else if (ele === NavBarElements.profile && this.profileNotification === 'an-animate') {
    this.toggleProfileNotification();
  } else if (ele === NavBarElements.shiftReview && this.shiftReviewDropdown === 'an-animate') {
    this.toggleShiftReview();
  } else if (ele === NavBarElements.alertControl && this.alertControlDropdown === 'an-animate') {
    this.toggleAlertControl();
  } else if (ele === NavBarElements.notificationControl && this.notificationDropdown === 'an-animate') {
    this.toggleNotificationControl();
  } else if (ele === NavBarElements.lineStation && this.lineStationDropdown === 'an-animate') {
    this.toggleLineStation();
  }
}

export function setBackgroundPattern(pattern: string): void {
  document.querySelector('body').setAttribute('themebg-pattern', pattern);
  // this.menuTitleTheme = this.freamType = this.activeItemTheme = this.headerTheme = pattern;
}

export function fireClick(e): void {
  if (this.windowWidth > 1024) {
    const parentEle = e.target.parentNode.parentNode;

    if (parentEle.classList.contains('pcoded-trigger')) {
      const subEle = parentEle.querySelectorAll('.pcoded-hasmenu');

      for (let i = 0; i < subEle.length; i = i + 1) {
        if (subEle[i].classList.contains('pcoded-trigger')) {
          subEle[i].classList.remove('pcoded-trigger');
        }
      }
    } else {
      e.target.click();
    }
  }
}

export function scroll(): void {
  const scrollPosition = window.pageYOffset;

  if (scrollPosition > 50) {
    if (this.isSidebarChecked) {
      this.pcodedSidebarPosition = 'fixed';
    }

    this.headerFixedTop = '0';
    this.sidebarFixedNavHeight = '100%';
  } else {
    this.headerFixedTop = 'auto';
    this.pcodedSidebarPosition = 'absolute';
    this.sidebarFixedNavHeight = '';
  }
}

export function fireClickLeave(e): void {
  const parentEle = <HTMLElement>e.target.parentNode.parentNode;

  if (parentEle.classList.contains('pcoded-trigger')) {
    const subEle = parentEle.querySelectorAll('.pcoded-hasmenu');

    for (let i = 0; i < subEle.length; i = i + 1) {
      if (subEle[i].classList.contains('pcoded-trigger')) {
        subEle[i].classList.remove('pcoded-trigger');
      }
    }
  }
}

export function goLink(data): void | string {
  const state = `/${data['state']}`;

  if (data['external']) {
    let url = window.location.origin;
    url = url.replace('8002', '8001');
    url += state;
    window.location.href = url;
  } else {
    this.router.navigate([state]).then((r) => r);
  }
}

export function findMenuItemInTreeMenu(
  items: MenuInterface[],
  search: MenuSearchableKeyInterface,
): MenuInterface | null {
  let output: MenuInterface | null = null;

  for (const item of items) {
    if (item.children?.length) {
      output = findMenuItemInChildrenMenu(item.children, search);
    }

    if (output !== null) {
      return output;
    }
  }

  return null;
}

function findMenuItemInChildrenMenu(
  children: MenuInterface[],
  search: MenuSearchableKeyInterface,
): MenuInterface | null {
  for (const item of children) {
    if (item.children.length > 0) {
      const output = findMenuItemInChildrenMenu(item.children, search);

      if (output !== null) {
        return output;
      }
    }

    for (const key in search) {
      if (item.hasOwnProperty(key) && item[key].includes(search[key])) {
        return item;
      }
    }
  }

  return null;
}
