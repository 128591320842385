<button class="advanced_filter" [class.disabled]="isDisabled" (click)="showAdvancedFilterModal(advancedFilterModal)">
  <div class="advanced_filter-text">{{ 'filterCard.advancedFilter.title' | translate }}</div>
  <div class="advanced_filter-decorations">
    <div class="advanced_filter-decorations-count" *ngIf="appliedFilterCount > 0">+{{ appliedFilterCount }}</div>
    <em class="fas fa-ellipsis-h advanced_filter-decorations-icon"></em>
  </div>
</button>

<!-- Modal -->
<ng-template #advancedFilterModal let-modal>
  <scw-mat-form (scwMatFormSubmit)="onSubmit($event)">
    <div class="modal-header scw-modal-header">
      <h3 class="modal-title scw-header">{{ 'filterCard.advancedFilter.title' | translate }}</h3>
      <button
        type="button"
        class="close"
        onfocus="blur()" aria-label="Close"
        (click)="isChangesApplied ? modal.close() : showConfirmationModal(confirmationModal)"
      >
        <span aria-hidden="true">
          <em class="fas fa-times"></em>
        </span>
      </button>
    </div>
    <div class="modal-body">
      <div class="advanced_filter-body">
        <div class="advanced_filter-controls">
          <div class="advanced_filter-controls-left">
            <scw-mat-small-button
              (onClick)="selectAll()"
              *ngIf="selectAllType === 'select'"
              [disabled]="filters.length === 0"
            >
              {{ 'general.selectAll' | translate }}
            </scw-mat-small-button>
            <scw-mat-small-button (onClick)="deSelectAll()" *ngIf="selectAllType === 'de-select'">
              {{ 'general.unselectAll' | translate }}
            </scw-mat-small-button>
            <scw-mat-small-button
              class="trash-icon"
              (onClick)="deleteFilters()"
              [iconButton]="true"
              [disabled]="isDeleteButtonDisabled"
            >
              <em class="fas fa-trash"></em>
            </scw-mat-small-button>
          </div>
          <div class="advanced_filter-controls-right">
            <scw-mat-small-button (onClick)="addFilter()" [disabled]="isAddButtonDisabled">
              {{ 'filterCard.advancedFilter.addFilter' | translate }}
            </scw-mat-small-button>
          </div>
        </div>

        <div class="advanced_filter-item" *ngFor="let filter of visibleFilters; index as key">
          <div class="advanced_filter-item-row">
            <div class="advanced_filter-item-checkbox pr-4">
              <scw-mat-checkbox
                class="advanced_filter-checkbox"
                [(inputModel)]="filter.isActive"
                (inputModelChange)="onFormChange()"
              ></scw-mat-checkbox>
            </div>
            <div class="advanced_filter-item-input pr-4">
              <scw-mat-select
                [data]="fields"
                [labelKey]="'label'"
                [noPadding]="true"
                [(inputModel)]="filter.selectedField"
                (inputModelChange)="onFieldChange(key)"
                [clearAll]="false"
              ></scw-mat-select>
            </div>
            <div class="advanced_filter-item-input pr-4">
              <scw-mat-select
                [data]="filter.operators ? filter.operators : []"
                [labelKey]="'translatedOperator'"
                [noDataLabel]="'filterCard.advancedFilter.noFieldSelectedForOperator' | translate"
                [noPadding]="true"
                [disabled]="
                  filter.selectedField.length > 0 &&
                  filter.selectedField[0].queryType === QueryTypes.withinRequestQueryString
                "
                [(inputModel)]="filter.selectedOperator"
                (inputModelChange)="onOperatorChange(filter)"
                [clearAll]="false"
              ></scw-mat-select>
            </div>
            <ng-template [ngIf]="!filter.isFieldToFieldComparisonEnabled" [ngIfElse]="fieldToFieldCompareInput">
              <div class="advanced_filter-item-input pr-4">
                <scw-mat-input
                  *ngIf="filter.selectedFieldType === InputTypes.text"
                  [block]="true"
                  [rules]="inputRules.text"
                  [noPadding]="true"
                  [disabled]="
                    filter.selectedField.length === 0 ||
                    filter.selectedOperator.length === 0 ||
                    filter.selectedOperator[0].type === OperatorTypes.boolean
                  "
                  [(inputModel)]="filter.value"
                  (inputModelChange)="onFormChange()"
                ></scw-mat-input>
                <scw-mat-input
                  *ngIf="filter.selectedFieldType === InputTypes.number"
                  [block]="true"
                  [rules]="inputRules.number"
                  [noPadding]="true"
                  [disabled]="
                    filter.selectedField.length === 0 ||
                    filter.selectedOperator.length === 0 ||
                    filter.selectedOperator[0].type === OperatorTypes.boolean
                  "
                  [(inputModel)]="filter.value"
                  (inputModelChange)="onFormChange()"
                ></scw-mat-input>
                <scw-mat-input
                  *ngIf="filter.selectedFieldType === InputTypes.decimal"
                  [block]="true"
                  [rules]="inputRules.decimal"
                  [noPadding]="true"
                  (isValidChange)="inputInvalid($event)"
                  [disabled]="
                    filter.selectedField.length === 0 ||
                    filter.selectedOperator.length === 0 ||
                    filter.selectedOperator[0].type === OperatorTypes.boolean
                  "
                  [(inputModel)]="filter.value"
                  (inputModelChange)="onFormChange()"
                ></scw-mat-input>
                <scw-mat-input
                  *ngIf="filter.selectedFieldType === InputTypes.duration"
                  [block]="true"
                  [rules]="inputRules.duration"
                  [noPadding]="true"
                  (isValidChange)="inputInvalid($event)"
                  [disabled]="
                    filter.selectedField.length === 0 ||
                    filter.selectedOperator.length === 0 ||
                    filter.selectedOperator[0].type === OperatorTypes.boolean
                  "
                  [(inputModel)]="filter.value"
                  (inputModelChange)="onFormChange()"
                ></scw-mat-input>
                <scw-mat-select
                  *ngIf="filter.selectedFieldType === InputTypes.select"
                  [data]="
                    filter.selectedField.length > 0 && $any(filter.selectedField[0]).predefinedValues
                      ? $any(filter.selectedField[0]).predefinedValues
                      : []
                  "
                  [disabled]="
                    filter.selectedField.length === 0 ||
                    filter.selectedOperator.length === 0 ||
                    filter.selectedOperator[0].type === OperatorTypes.boolean
                  "
                  [labelKey]="'name'"
                  [noPadding]="true"
                  [enableSearchFilter]="filter.selectedField[0]?.showSearchBox?.showSearchBox"
                  [searchBehavior]="filter.selectedField[0]?.showSearchBox?.searchBehaviour"
                  [(inputModel)]="filter.value"
                  (inputModelChange)="onFormChange()"
                  [clearAll]="false"
                ></scw-mat-select>
                <scw-mat-select
                  *ngIf="filter.selectedFieldType === InputTypes.dateFormat"
                  [data]="
                    filter.selectedField.length > 0 && $any(filter.selectedField[0]).predefinedValues
                      ? $any(filter.selectedField[0]).predefinedValues
                      : []
                  "
                  [disabled]="
                    filter.selectedField.length === 0 ||
                    filter.selectedOperator.length === 0 ||
                    filter.selectedOperator[0].type === OperatorTypes.boolean
                  "
                  [labelKey]="'name'"
                  [noPadding]="true"
                  [(inputModel)]="filter.value"
                  (inputModelChange)="onFormChange()"
                  [clearAll]="false"
                ></scw-mat-select>
                <scw-mat-datepicker
                  *ngIf="
                    filter.selectedFieldType === InputTypes.date || filter.selectedFieldType === InputTypes.dateTime
                  "
                  [singleDatePicker]="
                    filter.selectedOperator.length > 0 && filter.selectedOperator[0].sql !== SqlOperators.$between
                  "
                  [opens]="DateRangeOpens.left"
                  [drops]="key >= 5 ? 'up' : 'down'"
                  [autoApply]="true"
                  [timePicker]="filter.selectedFieldType === InputTypes.dateTime"
                  [closeOnAutoApply]="true"
                  [showCustomRangeLabel]="false"
                  [ranges]="null"
                  [wide]="filter.selectedOperator.length > 0 && filter.selectedOperator[0].type === OperatorTypes.range"
                  [disabled]="
                    filter.selectedField.length === 0 ||
                    filter.selectedOperator.length === 0 ||
                    filter.selectedOperator[0].type === OperatorTypes.boolean
                  "
                  [emitOnNull]="true"
                  [noPadding]="true"
                  [(inputModel)]="filter.value"
                  (inputModelChange)="onFormChange()"
                  [emitUtc]="true"
                ></scw-mat-datepicker>
                <scw-mat-checkbox
                  *ngIf="filter.selectedFieldType === InputTypes.checkbox"
                  class="advanced_filter-item-boolean_checkbox"
                  [disabled]="
                    filter.selectedField.length === 0 ||
                    filter.selectedOperator.length === 0 ||
                    filter.selectedOperator[0].type === OperatorTypes.boolean
                  "
                  [(inputModel)]="filter.value"
                  (inputModelChange)="onFormChange()"
                ></scw-mat-checkbox>
              </div>
            </ng-template>
            <ng-template #fieldToFieldCompareInput>
              <div class="advanced_filter-item-input pr-4">
                <scw-mat-select
                  [data]="filter.sameGroupFieldDropdownItems"
                  [disabled]="filter.selectedOperator.length === 0"
                  [labelKey]="'name'"
                  [noPadding]="true"
                  [(inputModel)]="filter.value"
                  (inputModelChange)="onFormChange()"
                  [clearAll]="false"
                ></scw-mat-select>
              </div>
            </ng-template>
            <div class="advanced_filter-item-button">
              <scw-mat-button
                class="field-to-value-button"
                *ngIf="filter.isCompareMethodChangeButtonVisible"
                type="standard"
                (onClick)="onCompareMethodChange(key)"
                [hidden]="isSetDefaultButtonHide"
              >
                <em class="fas fa-exchange-alt"></em>
                {{
                  filter.isFieldToFieldComparisonEnabled
                    ? ('general.value' | translate)
                    : ('general.field' | translate)
                }}
              </scw-mat-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="buttons-right">
        <scw-mat-button
          [isSubmitButton]="true"
          type="standard"
          (onClick)="clickSetAsDefaultButton()"
          [disabled]="isSetDefaultButtonDisabled"
          [hidden]="isSetDefaultButtonHide"
        >
          {{ 'general.setAsDefaultButton' | translate }}
        </scw-mat-button>
        <scw-mat-button [isSubmitButton]="true" [disabled]="isApplyButtonDisabled" (onClick)="clickApplyButton()">
          {{ 'general.apply' | translate }}
        </scw-mat-button>
      </div>
    </div>
  </scw-mat-form>
</ng-template>

<ng-template #confirmationModal let-modal>
  <scw-mat-form (scwMatFormSubmit)="onSubmit($event)">
    <div class="modal-header scw-modal-header">
      <h3 class="modal-title scw-header">
        {{ 'filterCard.advancedFilter.title' | translate }}
      </h3>
      <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="modal.close()">
        <span aria-hidden="true">
          <em class="fas fa-times"></em>
        </span>
      </button>
    </div>
    <div class="modal-body">
      <div class="advanced_filter-confirmation_body">
        <em class="fas fa-exclamation-circle"></em>
        <div class="advanced_filter-confirmation_body-text">
          {{ 'filterCard.advancedFilter.confirmation' | translate }}
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="buttons-left">
        <scw-mat-button type="danger" (onClick)="onDontSave()">
          {{ 'general.dontApply' | translate }}
        </scw-mat-button>
      </div>
      <div class="buttons-right">
        <scw-mat-button type="standard" (onClick)="modal.close()">
          {{ 'general.cancel' | translate }}
        </scw-mat-button>
        <scw-mat-button [isSubmitButton]="true" [disabled]="isApplyButtonDisabled" (onClick)="clickApplyButton()">
          {{ 'general.apply' | translate }}
        </scw-mat-button>
      </div>
    </div>
  </scw-mat-form>
</ng-template>
