export const InputLimit = {
  TEXT_MAX_LENGTH: '65535',
  CHAR_MAX_LENGTH: 255,
  INT_MAX_INPUT_VALUE: 2000000000,
  TABLE_SEARCH_MAX_LENGTH: 1000,
  CREW_SIZE_MAX_VALUE: 10000,
  WORKORDER_NUMBER_MAX_LENGTH: 20,
  WORKORDER_ORDERED_QUANTITY_MAX_VALUE: 100000000,
  WORKORDER_PRE_DURATION_MAX_VALUE: 10000,
  WORKORDER_POST_DURATION_MAX_VALUE: 10000,
  WORKORDER_SCHEDULED_RUN_DURATION_MAX_VALUE: 10000,
  WORKORDER_SEQUENCE_NUMBER_MAX_VALUE: 10000,
  WORKORDER_JOB_MAX_MAX_LENGTH: 12,
  CALL_SUPPORT_FROM_MAX_LENGTH: 500,
  CALL_SUPPORT_MESSAGE_MAX_LENGTH: 5000,
  CHECK_IN_CHECK_OUT_USERNAME_MAX_LENGTH: 50,
  CHECK_IN_CHECK_OUT_PIN_MAX_LENGTH: 4,
  WORK_ORDER_CLOSE_EXPLAIN_VARIANCES_MAX_LENGTH: 5000,
  WORK_ORDER_CLOSE_WORKED_WELL_MAX_LENGTH: 5000,
  WORK_ORDER_CLOSE_NEED_IMPROVE_MAX_LENGTH: 5000,
  SCHEDULER_COST_FACTOR_MAX_VALUE: 999999.99999,
  SCHEDULER_COST_FACTOR_MIN_VALUE: 0,
  TASK_GROUP_NAME_MAX_LENGTH: '255',
  WORK_ORDER_SCHEDULED_LABOR_DURATION_MAX_VALUE: 10000,
  WORK_ORDER_PROCESS_ORDER_MAX_LENGTH: 100,
  WORK_ORDER_OPERATIONAL_NUMBER_MAX_LENGTH: 100,
  DECIMAL_ZERO_VALUE: 0,
  DECIMAL_MIN_VALUE: 0,
  DECIMAL_MAX_VALUE: 10000000000000000000,
  DECIMAL_NUMERIC_PRECISION: 15,
  DECIMAL_NUMERIC_SCALE: 15,
  DEFAULT_SCALE_LIMIT: 2,
  DECIMAL_MAX_PRECISION: 30,
};

