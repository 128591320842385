import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ObjectActions from '../phase-selection/phase-selection.actions';
import { catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as AppActions from '../app/actions';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../oee.reducer';
import { PhaseSelectionService } from './phase-selection.service';

@Injectable()
export class PhaseSelectionEffects {
  constructor(
    private actions$: Actions<ObjectActions.PhaseSelectionActions>,
    private store: Store<OeeAppState>,
    private service: PhaseSelectionService,
  ) {}

  getPhases = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_PHASES),
      switchMap((objectData: ObjectActions.GetPhases) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getPhases(objectData.lineId).pipe(
          switchMap((resData) => {
            return of(new ObjectActions.GetPhasesCompleted(resData), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );
}
