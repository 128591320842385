import { Action } from '@ngrx/store';
import {
  ICreateManualCount,
  IManualCount,
  ISetHourlyCount,
  ICommentsResponse,
  IWorkOrderManualCountComment,
  IDistributionRequest,
  IDistributionResponse,
} from '../../view/home/work-order/work-order-manual-count/work-order-manual-count.model';
import { BulkResponse } from '../../shared/model/interface/generic-api-response.model';
import { GetManyResponseInterface } from '../../shared/model/interface/crud-response-interface.model';
import { IWorkOrderManualCountReviewTableQuery } from '../../view/reports/work-order-count-review/work-order-count-review.model';
import { ManualCountsReviewData } from './model';

export const SET_WORK_ORDER_MANUAL_COUNT_FORM = '[MANUAL_COUNT] Set work order manual count form state';
export const SAVE_MANUAL_COUNT_COMMENTS = '[MANUAL_COUNT] Save manual count comments';
export const MANUAL_COUNT_COMMENTS_SAVED = '[MANUAL_COUNT] Manual count comments saved';
export const RESET_MANUAL_COUNT_STORE = '[MANUAL_COUNT] Reset work order manual count store';
export const SET_HOURLY_COUNT = '[MANUAL_COUNT] Set hourly count';
export const SET_HOURLY_COUNT_COMPLETED = '[MANUAL_COUNT] Set hourly count completed';
export const SET_HOURLY_COUNT_FAILED = '[MANUAL_COUNT] Set hourly count failed';
export const SET_BULK_HOURLY_COUNT = '[MANUAL_COUNT] Set bulk hourly count';
export const SET_BULK_HOURLY_COUNT_COMPLETED = '[MANUAL_COUNT] Set bulk hourly count completed';
export const SET_BULK_HOURLY_COUNT_FAILED = '[MANUAL_COUNT] Set bulk hourly count failed';
export const CREATE_MANUAL_COUNT = '[MANUAL_COUNT] Create manual count';
export const CREATE_MANUAL_COUNT_COMPLETED = '[MANUAL_COUNT] Create manual count completed';
export const CREATE_MANUAL_COUNT_FAILED = '[MANUAL_COUNT] Create manual count failed';
export const MANUAL_COUNT_REVIEW_DATA_LOADING = '[MANUAL_COUNT] Manual Count Review Data Loading';
export const MANUAL_COUNT_REVIEW_DATA_LOADED = '[MANUAL_COUNT] Manual Count Review Data Loaded';
export const FETCH_DATA_ERROR = '[MANUAL_COUNT] FETCH DATA ERROR';
export const MANUAL_COUNT_GET_DISTRIBUTION_LOADING = '[MANUAL_COUNT] Manual count get distribution loading';
export const MANUAL_COUNT_GET_DISTRIBUTION_LOADED = '[MANUAL_COUNT] Manual count get distribution loaded';
export const MANUAL_COUNT_SET_DISTRIBUTION_LOADING = '[MANUAL_COUNT] Manual count set distribution loading';
export const MANUAL_COUNT_SET_DISTRIBUTION_LOADED = '[MANUAL_COUNT] Manual count set distribution loaded';

export class SetManualCountFormSubmitted implements Action {
  readonly type = SET_WORK_ORDER_MANUAL_COUNT_FORM;

  constructor(public isManualCountAddFormSubmitted: boolean) {}
}

export class SaveManualCountComments implements Action {
  readonly type = SAVE_MANUAL_COUNT_COMMENTS;

  constructor(public comments: IWorkOrderManualCountComment[]) {}
}

export class ManualCountCommentsSaved implements Action {
  readonly type = MANUAL_COUNT_COMMENTS_SAVED;

  constructor(public response: GetManyResponseInterface<ICommentsResponse>) {}
}

export class ManualCountReviewDataLoading implements Action {
  readonly type = MANUAL_COUNT_REVIEW_DATA_LOADING;

  constructor(public tableQuery: IWorkOrderManualCountReviewTableQuery) {}
}

export class ManualCountReviewDataLoaded implements Action {
  readonly type = MANUAL_COUNT_REVIEW_DATA_LOADED;

  constructor(public payload: GetManyResponseInterface<ManualCountsReviewData>) {}
}

export class FetchDataError implements Action {
  readonly type = FETCH_DATA_ERROR;

  constructor(public response: object) {}
}

export class ResetManualCountStore implements Action {
  readonly type = RESET_MANUAL_COUNT_STORE;

  constructor() {}
}

export class SetHourlyCount implements Action {
  readonly type = SET_HOURLY_COUNT;

  constructor(public manualCount: ISetHourlyCount) {}
}

export class SetBulkHourlyCount implements Action {
  readonly type = SET_BULK_HOURLY_COUNT;

  constructor(public manualCounts: ISetHourlyCount[]) {}
}

export class CreateManualCount implements Action {
  readonly type = CREATE_MANUAL_COUNT;

  constructor(public data: ICreateManualCount, public isCalledFromSetHourlyCount: boolean = false) {}
}

export class CreateManualCountCompleted implements Action {
  readonly type = CREATE_MANUAL_COUNT_COMPLETED;

  constructor(public payload: IManualCount) {}
}

export class SetHourlyCountCompleted implements Action {
  readonly type = SET_HOURLY_COUNT_COMPLETED;

  constructor(public payload: IManualCount) {}
}

export class SetBulkHourlyCountCompleted implements Action {
  readonly type = SET_BULK_HOURLY_COUNT_COMPLETED;

  constructor(public payload: BulkResponse[]) {}
}

export class CreateManualCountFailed implements Action {
  readonly type = CREATE_MANUAL_COUNT_FAILED;
  constructor(public payload: object) {}
}

export class SetHourlyCountFailed implements Action {
  readonly type = SET_HOURLY_COUNT_FAILED;
  constructor(public payload: object) {}
}

export class SetBulkHourlyCountFailed implements Action {
  readonly type = SET_BULK_HOURLY_COUNT_FAILED;
  constructor(public payload: object) {}
}

export class ManualCountGetDistributionLoading implements Action {
  readonly type = MANUAL_COUNT_GET_DISTRIBUTION_LOADING;

  constructor(public params: IDistributionRequest) {}
}

export class ManualCountGetDistributionLoaded implements Action {
  readonly type = MANUAL_COUNT_GET_DISTRIBUTION_LOADED;

  constructor(public response: IDistributionResponse[]) {}
}

export class ManualCountSetDistributionLoading implements Action {
  readonly type = MANUAL_COUNT_SET_DISTRIBUTION_LOADING;

  constructor(public params: IDistributionRequest, public hideLoaderAfterSuccess: boolean = false) {}
}

export class ManualCountSetDistributionLoaded implements Action {
  readonly type = MANUAL_COUNT_SET_DISTRIBUTION_LOADED;

  constructor() {}
}

export type ManualCountActions =
  | SetManualCountFormSubmitted
  | SetHourlyCount
  | SetHourlyCountCompleted
  | SetHourlyCountFailed
  | SetBulkHourlyCount
  | SetBulkHourlyCountCompleted
  | SetBulkHourlyCountFailed
  | CreateManualCount
  | CreateManualCountCompleted
  | CreateManualCountFailed
  | SaveManualCountComments
  | ManualCountCommentsSaved
  | ManualCountReviewDataLoading
  | ManualCountReviewDataLoaded
  | FetchDataError
  | ResetManualCountStore
  | ManualCountGetDistributionLoading
  | ManualCountGetDistributionLoaded
  | ManualCountSetDistributionLoading
  | ManualCountSetDistributionLoaded;
