import { Injectable } from '@angular/core';
import {
  DECIMAL_MAX_PRECISION,
  DECIMAL_NUMERIC_SCALE,
  DECIMAL_DEFAULT_SCALE_LIMIT,
  DECIMAL_MIN_VALUE_NON_ZERO,
  DECIMAL_MAX_VALUE,
  DECIMAL_NUMERIC_PRECISION,
} from '../../../../constants';
import { IDecimal } from './model/interface/decimal-model';
import * as _ from 'lodash';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../../store/oee.reducer';
import { User } from '../../../store/user/model';
import Decimal from 'decimal.js';
import { IDecimalRuleOptions, ScwMatInputRule } from '../../component/scw-mat-ui/scw-mat-input/scw-mat-input.model';

@Injectable({
  providedIn: 'root',
})
export class DecimalHelper implements IDecimal {
  private decimalScaleLimit: number = DECIMAL_DEFAULT_SCALE_LIMIT;
  private decimalSeparator: '.' | ',';
  private thousandSeparator: '.' | ',';
  private dotReg: RegExp = /[.]/g;
  private commaReg: RegExp = /[,]/g;
  private isValidRegex: RegExp = /^-?[0-9]*[.,]?[0-9]*$/;

  constructor(private store: Store<OeeAppState>) {
    Decimal.config({
      precision: DECIMAL_MAX_PRECISION,
      rounding: 4,
      toExpNeg: -7,
      toExpPos: 21,
      modulo: 1,
      defaults: true,
    });

    this.store.select('user').subscribe((state: User) => {
      if (!state.isUserLoading && state.isUserLoaded) {
        this.thousandSeparator = state.thousandSeparator;
        this.decimalSeparator = state.decimalSeparator;
        this.decimalScaleLimit = state.decimalScaleLimit;
      }
    });
  }

  add(val1: string, val2: string): string;
  add(val1: string, val2: string, precision: number): string;
  add(val1: string, val2: string, precision?: number): string {
    if (!val1 || !val2) {
      return null;
    }

    return DecimalHelper.prepareValue(Decimal.add(val1, val2), precision);
  }

  public static add1(val1: string, val2: string): string;
  public static add1(val1: string, val2: string, precision: number): string;
  public static add1(val1: string, val2: string, precision?: number): string {
    if (!val1 || !val2) {
      return null;
    }

    return DecimalHelper.prepareValue(Decimal.add(val1, val2), precision);
  }

  subtract(val1: string, val2: string): string;
  subtract(val1: string, val2: string, precision: number): string;
  subtract(val1: string, val2: string, precision?: number): string {
    if (!val1 || !val2) {
      return null;
    }

    return DecimalHelper.prepareValue(Decimal.sub(val1, val2), precision);
  }

  multiply(val1: string, val2: string): string;
  multiply(val1: string, val2: string, precision: number): string;
  multiply(val1: string, val2: string, precision?: number): string {
    return DecimalHelper.multiply(val1, val2, precision);
  }

  public static multiply(val1: string, val2: string): string;
  public static multiply(val1: string, val2: string, precision: number): string;
  public static multiply(val1: string, val2: string, precision?: number): string {
    if (!val1 || !val2) {
      return null;
    }

    if (new Decimal(val1).eq('0') || new Decimal(val2).eq('0')) {
      return '0';
    }

    return DecimalHelper.prepareValue(Decimal.mul(val1, val2), precision);
  }

  divide(val1: string, val2: string): string;
  divide(val1: string, val2: string, precision: number): string;
  divide(val1: string, val2: string, precision?: number): string {
    if (!val1 || !val2) {
      return null;
    }

    if (new Decimal(val1).eq('0') || new Decimal(val2).eq('0')) {
      return '0';
    }

    return DecimalHelper.prepareValue(Decimal.div(val1, val2), precision);
  }

  public static divide(val1: string, val2: string): string;
  public static divide(val1: string, val2: string, precision: number): string;
  public static divide(val1: string, val2: string, precision?: number): string {
    if (!val1 || !val2) {
      return null;
    }

    if (new Decimal(val1).eq('0') || new Decimal(val2).eq('0')) {
      return '0';
    }

    return DecimalHelper.prepareValue(Decimal.div(val1, val2), precision);
  }

  abs(val1: string): string;
  abs(val1: string, precision: number): string;
  abs(val1: string, precision?: number): string {
    if (!val1) {
      return null;
    }

    return DecimalHelper.prepareValue(Decimal.abs(val1), precision);
  }

  round(val: string): string;
  round(val: string, precision: number): string;
  round(val: string, precision?: number): string {
    if (!val) {
      return null;
    }

    return this.toFixedValue(Decimal.round(val).valueOf(), precision);
  }

  convertToDecimal(value: string | number): string {
    if (!value) {
      return null;
    }

    return new Decimal(value).toString();
  }

  toFixedValue(value?: string, scale: number = this.decimalScaleLimit, formatBySeparator: boolean = true): string {
    if (_.isNil(value) || typeof value !== 'string' || value === '' || value === undefined) {
      return null;
    }

    const valueConvertedToDecimal = new Decimal(this.sanitizeString(value.toString()));
    let decimalValueWithSeparator: string = valueConvertedToDecimal.toFixed(scale);

    if (formatBySeparator) {
      decimalValueWithSeparator = this.formatBySeparator(decimalValueWithSeparator);
    }

    return decimalValueWithSeparator;
  }

  isEqual(value1: string, value2: string): boolean {
    if (!value1 || !value2 || !this.isValid(value1) || !this.isValid(value2)) {
      return false;
    }

    return new Decimal(this.sanitizeString(value1)).eq(this.sanitizeString(String(value2)));
  }

  isGreaterThan(value1: string, value2: string): boolean {
    if (!value1 || !value2 || !this.isValid(value1) || !this.isValid(value2)) {
      return false;
    }

    return new Decimal(this.sanitizeString(value1)).gt(this.sanitizeString(String(value2)));
  }

  isGreaterOrEqThan(value1: string, value2: string): boolean {
    if (!value1 || !value2 || !this.isValid(value1) || !this.isValid(value2)) {
      return false;
    }

    return new Decimal(this.sanitizeString(value1)).gte(this.sanitizeString(String(value2)));
  }

  isLessThan(value1: string, value2: string): boolean {
    if (!value1 || !value2 || !this.isValid(value1) || !this.isValid(value2)) {
      return false;
    }

    return new Decimal(this.sanitizeString(value1)).lt(this.sanitizeString(String(value2)));
  }

  isLessOrEqThan(value1: string, value2: string): boolean {
    if (!value1 || !value2 || !this.isValid(value1) || !this.isValid(value2)) {
      return false;
    }

    return new Decimal(this.sanitizeString(value1)).lte(this.sanitizeString(String(value2)));
  }

  min(value: string, minValue: string): boolean {
    return new Decimal(this.sanitizeString(value)).lt(this.sanitizeString(minValue));
  }

  max(value: string, maxValue: string): boolean {
    if (!value || !maxValue) {
      return false;
    }

    return new Decimal(this.sanitizeString(value)).gt(this.sanitizeString(maxValue));
  }

  replaceDecimalSeparator(value: string, decimalSeparator: ',' | '.' = this.decimalSeparator): string {
    if (!value) {
      return null;
    }

    if (decimalSeparator === '.') {
      return value;
    }

    return value.replace('.', ',');
  }

  isNil(value: string): boolean {
    return _.isNil(value);
  }

  isDigitValid(value: string): boolean {
    const splitValue = value.split('.');

    if (!splitValue || splitValue.length === 0) {
      return false;
    }

    return (
      splitValue[0].length <= DECIMAL_NUMERIC_SCALE && (!splitValue[1] || splitValue[1].length <= DECIMAL_NUMERIC_SCALE)
    );
  }

  isValid(value: string): boolean {
    return !_.isNil(value) && value !== '' && this.isValidRegex.test(value);
  }

  removeTrailingZeros(value: string, separator: ',' | '.' = null): string {
    if (_.isNil(value) || typeof value !== 'string' || value === '') {
      return value;
    }

    let removedTrailingZeros: string;

    if (typeof value === 'string' && value.includes(separator)) {
      removedTrailingZeros = this.replaceDecimalSeparator(value, '.');
    } else {
      removedTrailingZeros = String(value);
    }

    if (separator) {
      removedTrailingZeros = this.replaceDecimalSeparator(removedTrailingZeros, separator);
    }

    return removedTrailingZeros.replace(/([\.,][0-9]*[1-9])0+$|[\.,]0*$/, '$1');
  }

  private static prepareValue(value: Decimal, precision: number): string {
    if (!value) {
      return '0';
    }

    return value.toFixed(precision);
  }

  public sanitizeString(value: string): string {
    if (_.isNil(value) || value === '') {
      return null;
    }

    return String(value).replace(',', '.');
  }

  public formattedNumberToNumber(formattedNumber: string): number {
    if (typeof formattedNumber === 'undefined' || formattedNumber === null || formattedNumber === '') {
      return 0;
    }

    const thousandRegex: RegExp = this.thousandSeparator === '.' ? /[.]/g : /[,]/g;
    const decimalRegex: RegExp = this.decimalSeparator === '.' ? /[.]/g : /[,]/g;

    return parseFloat(formattedNumber.replace(thousandRegex, '').replace(decimalRegex, '.'));
  }

  public removeThousandSeparator(formattedNumber: string): string {
    if (_.isNil(formattedNumber) || formattedNumber === '') {
      return '0';
    }

    const thousandRegex: RegExp = this.thousandSeparator === '.' ? /[.]/g : /[,]/g;
    const decimalRegex: RegExp = this.decimalSeparator === '.' ? /[.]/g : /[,]/g;

    return formattedNumber.replace(thousandRegex, '').replace(decimalRegex, this.decimalSeparator);
  }

  public decimalToNumberFormatter(value: number | string): number {
    if (value === null) {
      return null;
    }

    const formatted: string =
      this.decimalSeparator === ',' ? value.toString().replace(this.commaReg, '.') : value.toString();

    return Number(formatted);
  }

  public decimalToPercentageFormat(value: string, decimalCount: number = 2): string {
    if (this.isNil(value) || value === undefined || value === '') {
      return '0';
    }

    return this.toFixedValue(value, decimalCount);
  }

  public formatBySeparator(value: string, includeThousandSeparator: boolean = true): string | null {
    if (this.isNil(value)) {
      return null;
    }

    if (Number(value) === 0) {
      return '0';
    }

    const decimalSeparator: '.' | ',' = this.decimalSeparator;
    const thousandSeparator: '.' | ',' = this.thousandSeparator;

    const valueWithRemovedTrailingZeros: string = this.removeTrailingZeros(value);
    const valueToFormat: string[] = valueWithRemovedTrailingZeros.split('.');

    let valueBeforeDecimalSeparator: string = valueToFormat[0];

    if (includeThousandSeparator) {
      valueBeforeDecimalSeparator =
        valueBeforeDecimalSeparator.length > 3
          ? valueBeforeDecimalSeparator.replace(/\B(?=(\d{3})+(?!\d))/g, `${thousandSeparator}`)
          : valueBeforeDecimalSeparator;
    }

    const valueAfterDecimalSeparator: string = valueToFormat.length > 1 ? decimalSeparator + valueToFormat[1] : '';

    return valueBeforeDecimalSeparator + valueAfterDecimalSeparator;
  }

  public getDecimalNonZeroInputRule(
    min?: string,
    max?: string,
    options: Partial<IDecimalRuleOptions> = {},
  ): ScwMatInputRule {
    return {
      decimal: {
        min: min ?? DECIMAL_MIN_VALUE_NON_ZERO,
        max: max ?? DECIMAL_MAX_VALUE,
        integerStep: {
          max: DECIMAL_NUMERIC_PRECISION,
        },
        decimalStep: {
          max: DECIMAL_NUMERIC_SCALE,
        },
        ...options,
      },
    };
  }

  public calculateRate(
    dividend: string,
    denominator: string,
    precision?: number,
    addPercentageSymbol: boolean = false,
    isProgress: boolean = false,
    rawPercentage: boolean = false,
  ): string {
    if (_.isNil(denominator) || _.isNil(dividend) || dividend === '' || denominator === '') {
      return '0';
    }

    let dividendValue: string = _.replace(dividend, this.thousandSeparator, '');
    let denominatorValue: string = _.replace(denominator, this.thousandSeparator, '');

    if (this.decimalSeparator === ',') {
      dividendValue = _.replace(dividend, this.decimalSeparator, '.');
      denominatorValue = _.replace(denominator, this.decimalSeparator, '.');
    }

    if (
      !(this.isDigitValid(dividendValue) || this.isDigitValid(denominatorValue)) ||
      new Decimal(denominator).eq('0')
    ) {
      return '0';
    }

    let rateToReturn: string = this.multiply(this.divide(dividendValue, denominatorValue), '100');

    if (isProgress && this.isLessOrEqThan(rateToReturn, '0')) {
      rateToReturn = '0';
    }

    if (isProgress && this.isGreaterOrEqThan(rateToReturn, '100')) {
      rateToReturn = '100';
    }

    return rawPercentage
      ? rateToReturn
      : `${this.toFixedValue(rateToReturn, precision ?? this.decimalScaleLimit)} ${addPercentageSymbol ? '%' : ''}`;
  }

  public formatDecimalValueForExcel(value: string | number, isDataAnalysisFormat: boolean = false): string | number {
    return isDataAnalysisFormat
      ? this.decimalToNumberFormatter(this.formattedNumberToNumber(value.toString()))
      : this.toFixedValue(this.formattedNumberToNumber(value.toString()).toString());
  }
}
