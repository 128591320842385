import * as Actions from './tree-nodes.actions';
import { TreeNodesStateInterface } from './tree-nodes.model';

export const treeNodesState: TreeNodesStateInterface = {
  treeNodesDataLoaded: false,
  treeNodesDataLoading: false,
  treeNodes: [],
  treeNodesAddLoading: false,
  treeNodesAddLoaded: false,
  treeNodesEditLoading: false,
  treeNodesEditLoaded: false,
  treeNodesDeleteLoading: false,
  treeNodesDeleteLoaded: false,
};

export function treeNodesReducer(
  state: TreeNodesStateInterface = treeNodesState,
  action: Actions.TreeNodesActions,
): TreeNodesStateInterface {
  switch (action.type) {
    case Actions.TREE_NODES_DATA_LOAD:
      return {
        ...state,
        ...{
          treeNodesDataLoading: true,
          treeNodesDataLoaded: false,
        },
      };
    case Actions.TREE_NODES_DATA_LOADED:
      return {
        ...state,
        ...{
          treeNodes: action.payload.data,
          treeNodesDataLoading: false,
          treeNodesDataLoaded: true,
        },
      };
    case Actions.ADD_TREE_NODE:
      return {
        ...state,
        treeNodesAddLoading: true,
        treeNodesAddLoaded: false,
      };
    case Actions.ADD_TREE_NODE_COMPLETED:
      return {
        ...state,
        treeNodesAddLoading: false,
        treeNodesAddLoaded: true,
      };
    case Actions.EDIT_TREE_NODE:
      return {
        ...state,
        treeNodesEditLoading: true,
        treeNodesEditLoaded: false,
      };
    case Actions.EDIT_TREE_NODE_COMPLETED:
      return {
        ...state,
        treeNodesEditLoading: false,
        treeNodesEditLoaded: true,
      };
    case Actions.DELETE_TREE_NODE:
      return {
        ...state,
        treeNodesDeleteLoading: true,
        treeNodesDeleteLoaded: false,
      };
    case Actions.DELETE_TREE_NODE_COMPLETED:
      return {
        ...state,
        treeNodesDeleteLoading: false,
        treeNodesDeleteLoaded: true,
      };
    case Actions.FETCH_ERROR:
      return {
        ...state,
        treeNodesDataLoaded: false,
        treeNodesDataLoading: false,
        treeNodesAddLoading: false,
        treeNodesAddLoaded: false,
        treeNodesEditLoading: false,
        treeNodesEditLoaded: false,
        treeNodesDeleteLoading: false,
        treeNodesDeleteLoaded: false,
      };
    default:
      return state;
  }
}
