import { TagsStateInterface } from './tags.model';
import * as Actions from './tags.actions';

export const tagState: TagsStateInterface = {
  tagsLoading: false,
  tagsLoaded: false,
  tagsData: null,
};

export function tagReducer(
  state: TagsStateInterface = tagState,
  action: Actions.TagsActions,
): TagsStateInterface {
  switch (action.type) {
    case Actions.TagsActionTypes.LoadTags:
      return {
        ...state,
        tagsLoading: true,
        tagsLoaded: false,
      };
    case Actions.TagsActionTypes.TagsLoaded:
      return {
        ...state,
        tagsData: action.payload,
        tagsLoading: false,
        tagsLoaded: true,
      };
    default:
      return state;
  }
}
