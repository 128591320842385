import { FilterObservationActions, FilterObservationActionTypes } from './observation.actions';
import { ObservationCRUDInterface } from '../../../shared/component/filter/filter.class';
import { HttpErrorResponse } from '@angular/common/http';

export interface FilterObservationState {
  isLoading: boolean;
  isLoaded: boolean;
  data: ObservationCRUDInterface[];
  errors: HttpErrorResponse[];
}

export const initialFilterObservationState: FilterObservationState = {
  isLoaded: false,
  isLoading: false,
  data: [],
  errors: [],
};

export function filterObservationReducer(
  state: FilterObservationState = initialFilterObservationState,
  action: FilterObservationActions,
): FilterObservationState {
  switch (action.type) {
    case FilterObservationActionTypes.StartLoadFilterObservations:
      return { ...state, isLoading: true };

    case FilterObservationActionTypes.FilterObservationsLoaded:
      return { ...state, data: action.data, isLoaded: true, isLoading: false };

    case FilterObservationActionTypes.ResetObservationStore:
      return { ...state, data: [], isLoaded: false, isLoading: false };

    case FilterObservationActionTypes.FetchError:
      const errors = [...state.errors, action.payload];
      return { ...state, errors, isLoaded: false, isLoading: false };

    default:
      return state;
  }
}
