import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ObjectActions from './offline-sensor-status.actions';
import { catchError, switchMap } from 'rxjs/operators';
import * as AppActions from '../app/actions';
import { of } from 'rxjs';
import { OfflineSensorStatusResponseInterface } from './offline-sensor-status.model';
import { OfflineSensorStatusService } from './offline-sensor-status.service';

@Injectable()
export class OfflineSensorStatusEffects {
  constructor(private actions$: Actions, public service: OfflineSensorStatusService) {}

  getOfflineSensorStatusData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_OFFLINE_SENSOR_STATUS),
      switchMap((objectData: ObjectActions.GetSensorOfflineStatus) => {
        return this.service.getOfflineSensorStatus(objectData.lineId).pipe(
          switchMap((resData: OfflineSensorStatusResponseInterface) => {
            return of(new ObjectActions.GetSensorOfflineStatusCompleted(resData.data));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );
}
