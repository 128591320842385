import { INotificationTemplateState } from './notification-templates.model';
import * as Actions from './notification-templates.actions';

export const notificationTemplateState: INotificationTemplateState = {
  notificationTemplateListDataLoading: false,
  notificationTemplateListDataLoaded: false,
  notificationTemplateSiteDataLoading: false,
  notificationTemplateSiteDataLoaded: false,
  notificationTemplateCardDataLoaded: false,
  notificationTemplateCardDataLoading: false,
  editNotificationTemplateLoaded: false,
  editNotificationTemplateLoading: false,
  addNotificationTemplateLoaded: false,
  addNotificationTemplateLoading: false,
  deleteNotificationTemplatesLoaded: false,
  deleteNotificationTemplatesLoading: false,
  deleteNotificationTemplateLoaded: false,
  deleteNotificationTemplateLoading: false,
  siteData: [],
  notificationTemplateListData: null,
  notificationTemplateCards: null,
};

export function notificationTemplateReducer(
  state: INotificationTemplateState = notificationTemplateState,
  action: Actions.NotificationTemplatesActions,
): INotificationTemplateState {
  switch (action.type) {
    case Actions.NOTIFICATION_TEMPLATE_LIST_DATA_LOADING:
      return {
        ...state,
        ...{
          notificationTemplateListDataLoading: true,
          notificationTemplateListDataLoaded: false,
        },
      };

    case Actions.NOTIFICATION_TEMPLATE_LIST_DATA_LOADED:
      return {
        ...state,
        ...{
          notificationTemplateListDataLoading: false,
          notificationTemplateListDataLoaded: true,
          notificationTemplateListData: action.payload,
        },
      };

    case Actions.NOTIFICATION_TEMPLATE_SITES_DATA_LOADING:
      return {
        ...state,
        ...{
          notificationTemplateSiteDataLoading: true,
          notificationTemplateSiteDataLoaded: false,
        },
      };

    case Actions.NOTIFICATION_TEMPLATE_SITES_DATA_LOADED:
      return {
        ...state,
        ...{
          notificationTemplateSiteDataLoading: false,
          notificationTemplateSiteDataLoaded: true,
          siteData: action.payload,
        },
      };

    case Actions.NOTIFICATION_TEMPLATES_CARDS_LOADING:
      return {
        ...state,
        ...{
          notificationTemplateCardDataLoaded: false,
          notificationTemplateCardDataLoading: true,
        },
      };

    case Actions.NOTIFICATION_TEMPLATES_CARDS_LOADED:
      return {
        ...state,
        ...{
          notificationTemplateCardDataLoaded: true,
          notificationTemplateCardDataLoading: false,
          notificationTemplateCards: action.payload,
        },
      };

    case Actions.EDIT_NOTIFICATION_TEMPLATE_LOADING:
      return {
        ...state,
        ...{
          editNotificationTemplateLoaded: true,
          editNotificationTemplateLoading: false,
        },
      };

    case Actions.EDIT_NOTIFICATION_TEMPLATE_COMPLETED:
      return {
        ...state,
        ...{
          editNotificationTemplateLoaded: true,
          editNotificationTemplateLoading: false,
        },
      };

    case Actions.ADD_NOTIFICATION_TEMPLATE:
      return {
        ...state,
        ...{
          addNotificationTemplateLoaded: false,
          addNotificationTemplateLoading: true,
        },
      };

    case Actions.ADD_NOTIFICATION_TEMPLATE_COMPLETED:
      return {
        ...state,
        ...{
          editNotificationTemplateLoaded: true,
          editNotificationTemplateLoading: false,
        },
      };

    case Actions.DELETE_NOTIFICATION_TEMPLATES:
      return {
        ...state,
        ...{
          deleteNotificationTemplatesLoaded: false,
          deleteNotificationTemplatesLoading: true,
        },
      };

    case Actions.DELETE_NOTIFICATION_TEMPLATES_COMPLETED:
      return {
        ...state,
        ...{
          deleteNotificationTemplatesLoaded: true,
          deleteNotificationTemplatesLoading: false,
        },
      };

    case Actions.DELETE_NOTIFICATION_TEMPLATE:
      return {
        ...state,
        ...{
          deleteNotificationTemplateLoaded: false,
          deleteNotificationTemplateLoading: true,
        },
      };

    case Actions.DELETE_NOTIFICATION_TEMPLATE_COMPLETED:
      return {
        ...state,
        ...{
          deleteNotificationTemplateLoaded: true,
          deleteNotificationTemplateLoading: false,
        },
      };

    case Actions.NOTIFICATION_TEMPLATE_FETCH_ERROR:
      return {
        ...state,
        notificationTemplateListDataLoaded: false,
        editNotificationTemplateLoaded: false,
        addNotificationTemplateLoaded: false,
        deleteNotificationTemplatesLoaded: false,
        deleteNotificationTemplateLoaded: false,
      };

    default:
      return state;
  }
}
