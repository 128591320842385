import { Action } from '@ngrx/store';
import { SiteCRUDInterface } from '../../../shared/component/filter/filter.class';
import { HttpOptionInterface } from '../filter.model';
import { ApiServer } from '../../../shared/component/filter/dropdown/dropdown.model';

export enum FilterCheckInLogSiteActionTypes {
  StartLoadFilterCheckInLogSites = '[Filter Site] Load Filter Sites',
  FilterCheckInLogSitesLoaded = '[Filter Site] Filter Sites Loaded',
}

export class StartLoadFilterCheckInLogSites implements Action {
  readonly type = FilterCheckInLogSiteActionTypes.StartLoadFilterCheckInLogSites;
  constructor(public options: HttpOptionInterface, public server: ApiServer = ApiServer.NestJS) {}
}

export class FilterCheckInLogSitesLoaded implements Action {
  readonly type = FilterCheckInLogSiteActionTypes.FilterCheckInLogSitesLoaded;
  constructor(public data: SiteCRUDInterface[]) {}
}

export type FilterCheckInLogSiteActions = StartLoadFilterCheckInLogSites | FilterCheckInLogSitesLoaded;
