import { ITableData, ShiftCommentsInterface } from '../../store/shift-summary/shift-summary.model';

export interface IPhaseCommentPopoverContent {
  date: string;
  isUpdated: boolean;
  workOrder: string;
  comment: string;
}

export enum ErpTableRowNames {
  none = '',
  yield = 'yield',
  scrap = 'scrap',
  preRunLabor = 'preRunLabor',
  preRunMachine = 'preRunMachine',
  runLabor = 'runLabor',
  runMachine = 'runMachine',
  postRunLabor = 'postRunLabor',
  postRunMachine = 'postRunMachine',
}

export type CommentType = 'preRun' | 'run' | 'postRun';

export interface IErpModalInputValues {
  [ErpTableRowNames.yield]: string | null;
  [ErpTableRowNames.scrap]: string | null;
  [ErpTableRowNames.preRunLabor]: string | null;
  [ErpTableRowNames.preRunMachine]: string | null;
  [ErpTableRowNames.runLabor]: string | null;
  [ErpTableRowNames.runMachine]: string | null;
  [ErpTableRowNames.postRunLabor]: string | null;
  [ErpTableRowNames.postRunMachine]: string | null;
}

export enum AfterConfirmationActionTypes {
  closeErpModal = 'closeErpModal',
  selectShift = 'selectShift',
}

export enum ETableType {
  REGULAR = 'regular',
  LINE_BASED = 'lineBased',
}

export interface ISelectedColumnsOfGroups {
  generalInfo: string[];
  phases: string[];
  phaseLaborHours: string[];
  shiftOverview: string[];
  taskGroups: string[];
  comments: string[];
}

export enum EShiftSummaryGroups {
  COLUMN_GROUPS = 'columnGroups',
  COLUMNS = 'columns',
  GENERAL_INFO = 'generalInfo',
  PHASES = 'phases',
  PHASE_LABOR_HOURS = 'phaseLaborHours',
  SHIFT_OVERVIEW = 'shiftOverview',
  TASK_GROUPS = 'taskGroups',
  COMMENTS = 'comments',
}

export enum ECommentType {
  supervisorComments = 1,
  maintenanceComments = 2,
  shiftGoalComments = 4,
  shiftEndStatusComments = 5,
  shiftActionItemComments = 6,
  nextShiftGoalComments = 7,
  shiftGoalHitComments = 8,
  shiftSafetyComments = 9,
  shiftQualityComments = 10,
  shiftDataQualityComments = 11,
  shiftTopDownTimeActivityComments = 12,
  workOrderManualCountComments = 13,
  productionReviewComments = 14,
}

export interface ILineGroup {
  [key: string]: ITableData[];
}

export interface ITaskGroupDuration {
  [key: string]: string;
}

export interface IDepartmentCommentDropdownOptions {
  [key: string]: ICommentDropdownOption[];
}

export interface ICommentDropdownOption {
  id: number;
  name: string;
  typeId: ECommentType;
}

export interface ICommentDropdownLabels {
  [key: string]: string;
}

export interface IDepartmentComment extends ShiftCommentsInterface {
  class?: string;
}

export enum ErpInputValidOrInvalidClass {
  Valid = 'form-control selected-count',
  Invalid = 'form-control selected-attention',
}
