import { Injectable, Provider } from '@angular/core';
import { DateTimeAdapter, OwlDateTimeIntl, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime-ex';
import { HelperService } from './app/shared/service/helper.service';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { CustomDateFormat } from './app/store/user/model';
import { IPage } from './constants.model';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DatatableOutputSortInterface } from './app/shared/component/datatable/datatable.model';
import { Moment } from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { DateRangeTypes } from './app/shared/component/filter/filterable-objects.class';
import { ScwMatInputRule } from './app/shared/component/scw-mat-ui/scw-mat-input/scw-mat-input.model';
import { IShownFieldsForActivity } from './app/view/activity-history/activity-history.model';
import { BehaviorSubject, of } from 'rxjs';
import * as AppActions from './app/store/app/actions';

const OEE_TOOL_NAME: string = 'OEE Tracker';
export const BROWSER_TITLE_SUFFIX: string = ` | ${OEE_TOOL_NAME}`;
export const RADIX: number = 10;
export const STATIC_MAX_LIMIT_OF_CRUD = 500;
export const WORK_ORDER_RECALCULATION_LIMIT = 50;
export function setLocale(helperService: HelperService): string {
  return helperService.setLocale();
}
export function setCustomDateFormats(helperService: HelperService): CustomDateFormat {
  return helperService.setCustomDateFormats();
}
export const OWL_DATE_TIME_PROVIDERS: Provider[] = [
  { provide: OWL_DATE_TIME_LOCALE, deps: [HelperService], useFactory: setLocale },
  { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
  { provide: OWL_DATE_TIME_FORMATS, deps: [HelperService], useFactory: setCustomDateFormats },
];

export enum ActiveStatuses {
  ACTIVE = 1,
  INACTIVE = 0,
}

export enum StatusesEnum {
  ACTIVE = 1,
  INACTIVE = 2,
}

export enum OeeEnum {
  APQ = 'apq',
  AP = 'ap',
  A = 'a',
}

export enum OeeEnumTranslation {
  apq = 'A*P*Q',
  ap = 'A*P',
  a = 'A',
}

export enum CountEntryEnum {
  CUMULATIVE = 1,
  INCREMENTAL = 2,
}

export enum CountEntryLanguageKeys {
  CUMULATIVE = 'lines.excel.countEntryMethod.cumulative',
  INCREMENTAL = 'lines.excel.countEntryMethod.incremental',
}
export enum CountTypeEnum {
  YIELD_SCRAP = 1,
  INITIAL_YIELD = 2,
  INITIAL_SCRAP = 3,
}

export enum CountTypeLanguageKeys {
  YIELD_SCRAP = 'lines.excel.manualCountType.goodAndScrapCount',
  INITIAL_YIELD = 'lines.excel.manualCountType.goodCount',
  INITIAL_SCRAP = 'lines.excel.manualCountType.scrapCount',
}

/**
 * @deprecated use {@link ECommonLanguageKeys} instead
 */
export enum StatusesLanguageKeys {
  ACTIVE = 'general.active',
  INACTIVE = 'general.inactive',
}

/**
 * @deprecated use {@link ECommonLanguageKeys} instead
 */
export enum YesNoLanguageKeys {
  YES = 'general.yes',
  NO = 'general.no',
}

export enum TrueFalse {
  True = 1,
  False = 0,
}

export enum YesNoEnum {
  YES = 1,
  NO = 0,
}

export enum ECommonLanguageKeys {
  YES = 'general.yes',
  NO = 'general.no',
  ACTIVE = 'general.active',
  INACTIVE = 'general.inactive',
}

export enum MultipleCheckInsEnum {
  YES = '1',
  NO = '0',
  SITE_DEFAULT = '2',
}

export enum ShiftGantDefaultValuesEnum {
  DURATION = 7200,
}

export const DEFAULT_ICON: string = 'icon-layout-sidebar-left';
export const EDIT_ICON: string = 'fas fa-edit';
export const COGS_ICON: string = 'fas fa-cogs';
export const CALENDAR_ICON: string = 'fa fa-calendar';
export const CALENDAR_ALT_ICON: string = 'fas fa-calendar-alt';
export const CUBE_ICON: string = 'fas fa-cube';
export const COG_ICON: string = 'fa fa-cog';
export const USER_CLOCK_ICON: string = 'fas fa-user-clock';
export const COPY_ICON: string = 'fa fa-copy fa-flip-horizontal';
export const BARCODE_ICON: string = 'fas fa-barcode';
export const LIST_ICON: string = 'fas fa-list-alt';
export const FILE_SIGN_ICON: string = 'fas fa-file-signature';
export const HOURGLASS_HALF_ICON: string = 'fas fa-hourglass-half';
export const FILE_ALT_ICON: string = 'fas fa-file-alt';
export const FILE_ICON: string = 'fas fa-file-alt';
export const BAR_CHART_ICON: string = 'fa fa-bar-chart';
export const TASKS_ICON: string = 'fas fa-tasks';
export const HISTORY_ICON: string = 'fas fa-history';
export const CHART_LINE_ICON: string = 'fa fa-chart-line';
export const CHART_LINE_ICON_SOLID: string = 'fas fa-chart-line';
export const PALLET_ICON: string = 'fas fa-pallet';
const BOXES_STACKED_ICON: string = 'fas fa-boxes';

export const smallModal: NgbModalOptions = {
  keyboard: false,
  backdrop: 'static',
  windowClass: 'scw-modal-sm',
};

export const mediumModal: NgbModalOptions = {
  keyboard: false,
  backdrop: 'static',
  windowClass: 'scw-modal-md',
};

export const PAGES: IPage[] = [
  {
    title: 'activity_history',
    icon: HISTORY_ICON,
  },
  {
    title: 'activity_review',
    icon: CHART_LINE_ICON_SOLID,
  },
  {
    title: 'asset_view',
    icon: CUBE_ICON,
    relatedModule: 'navigation_dashboards_assetview',
  },
  {
    title: 'observation',
    icon: EDIT_ICON,
    relatedModule: 'flexible_observation',
  },
  {
    title: 'home_title',
    icon: PALLET_ICON,
    relatedModule: 'navigation_home',
  },
  {
    title: 'kpi_metrics',
    icon: BAR_CHART_ICON,
    relatedModule: 'kpi_metrics',
  },
  {
    title: 'home_screen_metrics',
    icon: COGS_ICON,
    relatedModule: 'home_screen_metrics',
  },
  {
    title: 'line_availability',
    icon: CALENDAR_ICON,
    relatedModule: 'sch_header_nav_line_availability',
  },
  {
    title: 'line_view',
    icon: DEFAULT_ICON,
    relatedModule: 'navigation_dashboards_liveview',
  },
  {
    title: 'asset_logs_report',
    icon: CUBE_ICON,
    relatedModule: 'navigation_reports_asset_logs',
  },
  {
    title: 'asset_logs_report',
    icon: CUBE_ICON,
    relatedModule: 'navigation_reports_asset_logs',
  },
  {
    title: 'activity_timeline_report',
    icon: HOURGLASS_HALF_ICON,
    relatedModule: 'activity_timeline_header',
  },
  {
    title: 'labor_logs_report',
    icon: USER_CLOCK_ICON,
    relatedModule: 'navigation_reports_labor_logs',
  },
  {
    title: 'scheduler_home_title',
    icon: DEFAULT_ICON,
    relatedModule: 'sch_header_nav_home',
  },
  {
    title: 'scheduler_resource_settings',
    icon: COG_ICON,
    relatedModule: 'sch_header_nav_resource_settings',
  },
  {
    title: 'scheduler_scenario',
    icon: COPY_ICON,
  },
  {
    title: 'product_multiplier',
    icon: BARCODE_ICON,
    relatedModule: 'navigation_settings_product_multipliers',
  },
  {
    title: 'root_cause_groups',
    icon: LIST_ICON,
    relatedModule: 'navigation_settings_root_cause_groups',
  },
  {
    title: 'production_review',
    icon: DEFAULT_ICON,
  },
  {
    title: 'shift_summary',
    icon: FILE_SIGN_ICON,
    relatedModule: 'shift_summary',
  },
  {
    title: 'task_groups',
    icon: TASKS_ICON,
    relatedModule: 'navigation_task_groups',
  },
  {
    title: 'work_orders',
    icon: LIST_ICON,
    relatedModule: 'work_orders',
  },
  {
    title: 'work_orders_summary',
    icon: LIST_ICON,
    relatedModule: 'work_orders_summary',
  },
  {
    title: 'sensor_reports',
    icon: FILE_ALT_ICON,
    relatedModule: 'navigation_reports_sensor_reports',
  },
  {
    title: 'root_cause_analysis_report',
    icon: FILE_ICON,
    relatedModule: 'root_cause_analysis_report',
  },
  {
    title: 'labor_performance',
    icon: USER_CLOCK_ICON,
    relatedModule: 'labor_performance',
  },
  {
    title: 'activity_view',
    icon: DEFAULT_ICON,
  },
  {
    title: 'skill_matrix_report',
    icon: FILE_ICON,
    relatedModule: 'skill_matrix_report',
  },
  {
    title: 'work_order_performance_report',
    icon: FILE_ICON,
    relatedModule: 'work_order_performance_report',
  },
  {
    title: 'capacity_report_title',
    icon: FILE_ALT_ICON,
    relatedModule: 'navigation_reports_capacity',
  },
  {
    title: 'task_performance_report',
    icon: FILE_ICON,
    relatedModule: 'task_performance_report',
  },
  {
    title: 'deep_dive_analysis_report',
    icon: FILE_ICON,
    relatedModule: 'deep_dive_analysis_report',
  },
  {
    title: 'daily_performance_summary_report',
    icon: FILE_ICON,
    relatedModule: 'daily_performance_summary_report',
  },
  {
    title: 'status_management',
    icon: BOXES_STACKED_ICON,
    relatedModule: 'status_management',
  },
  {
    title: 'alerts',
    icon: 'fas fa-exclamation-triangle',
  },
  {
    title: 'oee_calendar_report',
    icon: CALENDAR_ALT_ICON,
    relatedModule: 'oee_calendar_report',
  },
  {
    title: 'knowledge_base',
    icon: FILE_ICON,
    relatedModule: 'knowledge_base',
  },
  {
    title: 'labor_scheduler',
    icon: 'fas fa-users',
    relatedModule: 'labor_scheduler',
  },
  {
    title: 'work_order_manual_count_review',
    icon: FILE_ICON,
    relatedModule: 'work_order_manual_count_review',
  },
  {
    title: 'periodic_oee_calculation_review',
    icon: FILE_ICON,
    relatedModule: 'periodic_oee_calculation_review',
  },
  {
    title: 'station_home',
    icon: DEFAULT_ICON,
  },
  {
    title: 'my_reports',
    icon: FILE_ICON,
    relatedModule: 'my_reports',
  },
];

export type HomeMetricTypes =
  | 'workOrderDuration'
  | 'workOrderAverageSpeed'
  | 'workOrderGoodCount'
  | 'activityDuration'
  | 'activitySpeed'
  | 'sensorBadges'
  | 'preRunPhaseDuration'
  | 'runPhaseDuration'
  | 'postRunPhaseDuration'
  | 'totalRunPhaseDuration'
  | 'workOrderGoodSpeed'
  | 'workOrderSpeed'
  | 'quantityWithComment'
  | 'workOrderSpeedByHour'
  | 'activitySpeedByHour'
  | 'workOrderGoodSpeedByHour'
  | 'workOrderAverageSpeedByHour';

export enum EHomeMetrics {
  WORK_ORDER_DURATION = 'work_order_duration',
  WORK_ORDER_SPEED = 'work_order_speed',
  ACTIVITY_DURATION = 'activity_duration',
  ACTIVITY_SPEED = 'activity_speed',
  WORK_ORDER_GOOD_COUNT = 'work_order_good_count',
  SENSOR_BADGES = 'sensor_badges',
  PRE_RUN_PHASE_DURATION = 'pre_run_phase_duration',
  RUN_PHASE_DURATION = 'run_phase_duration',
  POST_RUN_PHASE_DURATION = 'post_run_phase_duration',
  TOTAL_RUN_PHASE_DURATION = 'total_run_phase_duration',
  WORK_ORDER_GOOD_SPEED = 'work_order_good_speed',
  WORK_ORDER_AVERAGE_SPEED = 'work_order_average_speed',
  QUANTITY_WITH_COMMENT = 'quantity_with_comment',
  WORK_ORDER_SPEED_BY_HOUR = 'work_order_speed_by_hour',
  ACTIVITY_SPEED_BY_HOUR = 'activity_speed_by_hour',
  WORK_ORDER_GOOD_SPEED_BY_HOUR = 'work_order_good_speed_by_hour',
  WORK_ORDER_AVERAGE_SPEED_BY_HOUR = 'work_order_average_speed_by_hour',
}

export enum ToastTypeEnum {
  success = 'success',
  error = 'error',
  info = 'info',
  warning = 'warning',
}

export enum MultipleCheckInOptionsForSource {
  No = 0,
  Yes = 1,
  SiteDefault = 2,
}

export enum SensorManufacturerEnum {
  MONNIT = 'monnit',
  SCW = 'scw',
  AUTO_COUNTER = 'autocounter',
}

export enum SensorTypeEnum {
  GOOD_COUNTER = 'good_counter',
  MID_COUNTER = 'mid_counter',
  TOTAL_COUNTER = 'total_counter',
  VIBRATION = 'vibration',
  VOLTAGE = 'voltage',
  CARD_READER = 'card_reader',
  SUB_SENSOR = 'sub_sensor',
}

export enum SensorDataType {
  DAY = 'day',
  HOUR = 'hour',
}

export const sensorTypeTranslations = {
  [SensorTypeEnum.GOOD_COUNTER]: 'sensorTypes.sensor_type_good_counter',
  [SensorTypeEnum.MID_COUNTER]: 'sensorTypes.sensor_type_mid_counter',
  [SensorTypeEnum.TOTAL_COUNTER]: 'sensorTypes.sensor_type_total_counter',
  [SensorTypeEnum.VIBRATION]: 'sensorTypes.sensor_type_vibration',
  [SensorTypeEnum.VOLTAGE]: 'sensorTypes.sensor_type_voltage',
  [SensorTypeEnum.CARD_READER]: 'sensorTypes.sensor_type_card_reader',
  [SensorTypeEnum.SUB_SENSOR]: 'sensorTypes.sensor_type_sub_sensor',
};

export const defaultPageSort: DatatableOutputSortInterface = {
  type: 'descending',
  column: 'id',
};

export type DateRanges = {
  [key in
    | 'today'
    | 'todayWhole'
    | 'yesterday'
    | 'thisWeek'
    | 'lastWeek'
    | 'thisMonth'
    | 'lastMonth'
    | 'thisYear'
    | 'lastYear'
    | 'nextSevenDays'
    | 'nextThirtyDays'
    | 'lastThreeMonths'
    | DateRangeTypes]?: [Moment, Moment];
};

export const unitTypes = {
  4: 'products.lookups.unit.unitGram',
  5: 'products.lookups.unit.unitKilogram',
  6: 'products.lookups.unit.unitLiter',
  7: 'products.lookups.unit.unitMeter',
  8: 'products.lookups.unit.unitCentimeter',
  9: 'products.lookups.unit.unitPiece',
  12: 'products.lookups.unit.unitYard',
  13: 'products.lookups.unit.unitFeet',
  14: 'products.lookups.unit.unitTs',
  15: 'products.lookups.unit.m2',
};

export const genericModalOptions: NgbModalOptions = {
  size: 'lg',
  windowClass: 'full-modal',
  keyboard: false,
  backdrop: 'static',
};

export enum ELanguages {
  EN = 'en',
  TR = 'tr',
  DE = 'de',
  ES = 'es',
  FR = 'fr',
  IT = 'it',
  PL = 'pl',
  JA = 'ja',
}

export enum EOtherLanguages {
  TR = 'tr',
  DE = 'de',
  ES = 'es',
  FR = 'fr',
  IT = 'it',
  PL = 'pl',
  JA = 'ja',
}

export type TLanguageOptions = {
  [key in ELanguages]?: string;
};

export type TOtherLanguageOptions = {
  [key in EOtherLanguages]: string;
};

export const SIGNED_INT_11_MAX_VALUE = 2147483647;
export const MYSQL_MAX_TEXT_LENGTH = 65535;
export const MYSQL_DEFAULT_VARCHAR_LENGTH = 255;
export const DEFAULT_CHECK_IN_PIN = '1234';
export const PRODUCT_ID_DESCRIPTION_MAX_LENGTH = 60;

export const KPI_METRICS_DECIMAL_COUNT = 2;

@Injectable({ providedIn: 'root' })
export class DefaultIntl extends OwlDateTimeIntl {
  constructor(private readonly translate: TranslateService) {
    super();
  }

  public override cancelBtnLabel = this.translate.instant('general.cancel');

  public override setBtnLabel = this.translate.instant('owlDatePicker.apply');
}

export const DECIMAL_ONE_MILLION: string = '1000000';
export const DECIMAL_ZERO_VALUE: string = '0';
export const DECIMAL_MIN_VALUE: string = '0';
export const DECIMAL_MIN_VALUE_NON_ZERO: string = '0,0000000000000001';
export const DECIMAL_MAX_VALUE: string = '1000000000000000';
export const NEGATIVE_DECIMAL_MIN_VALUE: string = '-1000000000000000';
export const DECIMAL_MAX_VALUE_AS_INT: number = 1000000000000000;
export const DECIMAL_NUMERIC_PRECISION: number = 15;
export const DECIMAL_NUMERIC_SCALE: number = 15;
export const DECIMAL_DEFAULT_SCALE_LIMIT: number = 2;
export const DECIMAL_MAX_PRECISION: number = 30;
export const DEFAULT_DECIMAL_INPUT_RULE: ScwMatInputRule = {
  decimal: {
    min: DECIMAL_MIN_VALUE,
    max: DECIMAL_MAX_VALUE,
    integerStep: {
      max: DECIMAL_NUMERIC_PRECISION,
    },
    decimalStep: {
      max: DECIMAL_NUMERIC_SCALE,
    },
  },
};
export const DEFAULT_DECIMAL_INPUT_RULE_MIN_ONE: ScwMatInputRule = {
  decimal: {
    min: '0,000000000000001',
    max: DECIMAL_MAX_VALUE,
    integerStep: {
      max: DECIMAL_NUMERIC_PRECISION,
    },
    decimalStep: {
      max: DECIMAL_NUMERIC_SCALE,
    },
  },
};

export const WeavySpaces = {
  DEV: {
    id: 4,
    spaceName: 'TF-Cincinnati-DEV',
  },
  TEST: {
    id: 5,
    spaceName: 'TF-Cincinnati-TEST',
  },
  UAT: {
    id: 6,
    spaceName: 'TF-Cincinnati-UAT',
  },
  TRAINING: {
    id: 12,
    spaceName: 'TF-Cincinnati-TRAINING',
  },
  PROD: {
    id: 13,
    spaceName: 'TF-Cincinnati',
  },
};

export enum EModules {
  LABOR_TRACKER = 'laborTracker',
  ASSET_TRACKER = 'assetTracker',
  SCHEDULER = 'scheduler',
  DIGITAL_FORMS = 'digitalForms',
  GMP_DISCLAIMER = 'gmpDisclaimer',
  STATION_TRACKER = 'stationTracker',
  CAMERA_VIEW = 'cameraView',
}

export interface IMenuPermissions {
  modules: { [module in EModules]: boolean };
  inactiveMenuItemIds: number[];
}

export enum ECoordinateLimits {
  MIN_LATITUDE = -90.0,
  MAX_LATITUDE = 90.0,
  MIN_LONGITUDE = -180.0,
  MAX_LONGITUDE = 180.0,
}

export enum EErrorMessages {
  FILE_HAS_ERRORS_ERROR = 'excel.messages.fileHasErrors',
  FILE_TYPE_ERROR = 'excel.messages.fileTypeError',
  FILE_CANNOT_READ_ERROR_TITLE = 'excel.messages.fileCannotReadErrorTitle',
  FILE_CANNOT_READ_ERROR = 'excel.messages.fileCannotReadError',
  FILE_EMPTY_ERROR = 'excel.messages.fileEmptyError',
  FILE_LIMIT_EXCEEDED_ERROR = 'excel.messages.fileUploadLimitExceed',
}

export const emptyAction = of(new AppActions.EmptyAction());

export const advancedFilterFilterCardSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
