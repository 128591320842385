import { Action } from '@ngrx/store';
import { RootCauseGroupCRUDInterface } from '../../../shared/component/filter/filter.class';
import { HttpOptionInterface } from '../filter.model';
import { ApiServer } from '../../../shared/component/filter/dropdown/dropdown.model';

export enum FilterRootCauseGroupActionGroup {
  StartLoadFilterRootCauseGroups = '[Filter RootCauseGroup] Load Filter Root Cause Groups',
  FilterRootCauseGroupsLoaded = '[Filter RootCauseGroup] Filter Root Cause Groups Loaded',
}

export class StartLoadFilterRootCauseGroups implements Action {
  readonly type = FilterRootCauseGroupActionGroup.StartLoadFilterRootCauseGroups;

  constructor(public options: HttpOptionInterface, public server: ApiServer = ApiServer.NestJS) {}
}
export class FilterRootCauseGroupsLoaded implements Action {
  readonly type = FilterRootCauseGroupActionGroup.FilterRootCauseGroupsLoaded;

  constructor(public data: RootCauseGroupCRUDInterface[]) {}
}

export type FilterRootCauseGroupAction = StartLoadFilterRootCauseGroups | FilterRootCauseGroupsLoaded;
