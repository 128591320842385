import { Action } from '@ngrx/store';
import { SiteCRUDInterface } from '../../../shared/component/filter/filter.class';
import {
  EquipmentListBulkSaveManyInterface, EquipmentListInterface,
  EquipmentListsDownloadExcelFiltersInterface,
} from './equipment-lists.model';

export const EQUIPMENT_LISTS_SITES_DATA_LOADING = '[EQUIPMENT_LISTS] EQUIPMENT LISTS ACTION SITES DATA LOADING';
export const EQUIPMENT_LISTS_SITES_DATA_LOADED = '[EQUIPMENT_LISTS] EQUIPMENT LISTS ACTION SITES DATA LOADED';
export const EQUIPMENT_LISTS_EQUIPMENT_LIST_DATA_LOADING =
  '[EQUIPMENT_LISTS] EQUIPMENT LISTS ACTION EQUIPMENT_LIST DATA LOADING';
export const EQUIPMENT_LISTS_EQUIPMENT_LIST_DATA_LOADED =
  '[EQUIPMENT_LISTS] EQUIPMENT LISTS ACTION EQUIPMENT_LIST DATA LOADED';
export const DOWNLOAD_EQUIPMENT_LIST_EXCEL = '[EQUIPMENT_LISTS] EQUIPMENT LISTS ACTION DOWNLOAD EQUIPMENT_LIST EXCEL';
export const DOWNLOAD_EQUIPMENT_LIST_EXCEL_COMPLETED =
  '[EQUIPMENT_LISTS] EQUIPMENT LISTS ACTION DOWNLOAD EQUIPMENT_LIST EXCEL COMPLETED';
export const UPLOAD_EQUIPMENT_LIST_EXCEL = '[EQUIPMENT_LISTS] EQUIPMENT LISTS ACTION UPLOAD EQUIPMENT_LIST EXCEL';
export const UPLOAD_EQUIPMENT_LIST_EXCEL_COMPLETED =
  '[EQUIPMENT_LISTS] EQUIPMENT LISTS ACTION UPLOAD EQUIPMENT_LIST EXCEL COMPLETED';
export const EQUIPMENT_LISTS_FETCH_ERROR = '[EQUIPMENT_LISTS] EQUIPMENT LISTS ACTION FETCH ERROR';

export class EquipmentListsEquipmentListDataLoading implements Action {
  readonly type = EQUIPMENT_LISTS_EQUIPMENT_LIST_DATA_LOADING;

  constructor(public siteId: number, public limit?: number) {}
}

export class EquipmentListsEquipmentListDataLoaded implements Action {
  readonly type = EQUIPMENT_LISTS_EQUIPMENT_LIST_DATA_LOADED;

  constructor(public payload) {}
}

export class EquipmentListsSiteDataLoading implements Action {
  readonly type = EQUIPMENT_LISTS_SITES_DATA_LOADING;

  constructor() {}
}

export class EquipmentListsSiteDataLoaded implements Action {
  readonly type = EQUIPMENT_LISTS_SITES_DATA_LOADED;

  constructor(public payload: ({ id: number; name: string } & Partial<SiteCRUDInterface>)[]) {}
}

export class DownloadEquipmentListExcel implements Action {
  readonly type = DOWNLOAD_EQUIPMENT_LIST_EXCEL;
  constructor(public withData: boolean = false, public filters: EquipmentListsDownloadExcelFiltersInterface) {}
}

export class DownloadEquipmentListExcelCompleted implements Action {
  readonly type = DOWNLOAD_EQUIPMENT_LIST_EXCEL_COMPLETED;
}

export class UploadEquipmentListExcel implements Action {
  readonly type = UPLOAD_EQUIPMENT_LIST_EXCEL;

  constructor(public equipments: EquipmentListBulkSaveManyInterface) {}
}

export class UploadEquipmentListExcelCompleted implements Action {
  readonly type = UPLOAD_EQUIPMENT_LIST_EXCEL_COMPLETED;

  constructor(public payload: (EquipmentListInterface & { errorMessages?: string })[]) {}
}

export class FetchError implements Action {
  readonly type = EQUIPMENT_LISTS_FETCH_ERROR;

  constructor(public payload: object) {}
}

export type EquipmentListsActions =
  | EquipmentListsEquipmentListDataLoading
  | EquipmentListsEquipmentListDataLoaded
  | EquipmentListsSiteDataLoading
  | EquipmentListsSiteDataLoaded
  | DownloadEquipmentListExcel
  | DownloadEquipmentListExcelCompleted
  | UploadEquipmentListExcel
  | UploadEquipmentListExcelCompleted
  | FetchError;
