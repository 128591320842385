import { Action } from '@ngrx/store';
import { IMaintenanceModeData } from './model';

export const FETCH_ERROR = '[APP] Fetch Error';
export const SHOW_LOADER = '[APP] Show loader';
export const HIDE_LOADER = '[APP] Hide loader';
export const SHOW_TOP_LOADER = '[APP] Show top loader';
export const HIDE_TOP_LOADER = '[APP] Hide top loader';
export const SET_CICO_ACCESS = '[APP] Set CICO access';
export const SET_SHOW_TOUR = '[APP] Set SHOW TOUR';
export const SET_ACTIVITY_LIST_UPDATE = '[APP] Update Activity List';
export const GET_CURRENT_USER = '[APP] Get Current User';
export const SHOW_MASK = '[APP] Show Mask';
export const HIDE_MASK = '[APP] Hide Mask';
export const SET_MAINTENANCE_MODE = '[APP] Set Maintenance Mode';
export const GET_MAINTENANCE_MODE = '[APP] Get Maintenance Mode';
export const EMPTY_ACTION = '[APP] Empty Action';

export class FetchError implements Action {
  readonly type = FETCH_ERROR;

  constructor(public payload: any[]) {}
}

export class ShowLoader implements Action {
  readonly type = SHOW_LOADER;

  constructor(public loadingText: string = null, public loading: boolean = true) { }
}

export class HideLoader implements Action {
  readonly type = HIDE_LOADER;

  constructor(public loading: boolean = false) { }
}

export class ShowTopLoader implements Action {
  readonly type = SHOW_TOP_LOADER;
}

export class HideTopLoader implements Action {
  readonly type = HIDE_TOP_LOADER;
}

export class SetCicoAccess implements Action {
  readonly type = SET_CICO_ACCESS;

  constructor(public cicoAccess: boolean) { }
}

export class SetShowTour implements Action {
  readonly type = SET_SHOW_TOUR;

  constructor(public showTour: boolean) { }
}

export class SetActivityListUpdate implements Action {
  readonly type = SET_ACTIVITY_LIST_UPDATE;

  constructor(public activityListUpdate: boolean) { }
}

export class GetCurrentUser implements Action {
  readonly type = GET_CURRENT_USER;
}

export class ShowMask implements Action {
  readonly type = SHOW_MASK;
}

export class HideMask implements Action {
  readonly type = HIDE_MASK;
}

export class GetMaintenanceModeData implements Action {
  readonly type = GET_MAINTENANCE_MODE;
}

export class SetMaintenanceModeData implements Action {
  readonly type = SET_MAINTENANCE_MODE;

  constructor(public payload: IMaintenanceModeData) {}
}

export class EmptyAction implements Action {
  readonly type = EMPTY_ACTION;
}

export type AppActions =
  | ShowLoader
  | HideLoader
  | ShowTopLoader
  | HideTopLoader
  | SetCicoAccess
  | SetActivityListUpdate
  | SetShowTour
  | ShowMask
  | HideMask
  | GetMaintenanceModeData
  | SetMaintenanceModeData
  | EmptyAction;
