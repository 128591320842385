import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../../store/oee.reducer';
import { AdminLevels, User, UserLevels } from '../../../store/user/model';
import { ActiveStatuses, RADIX } from '../../../../constants';
import { UserInterface, UserGetManyCRUDDataInterface } from './user.model';
import { Subscription } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class UserService implements OnDestroy {
  private readonly user: UserInterface = {
    levelId: null,
    authorizedSites: null,
    authorizedLines: null,
  };
  public currentUser: User;
  private readonly subscription: Subscription;

  constructor(
    private readonly store: Store<OeeAppState>,
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
  ) {
    this.subscription = this.store.select('user').subscribe((state: User) => {
      this.user.levelId = parseInt(state.userLevelId, RADIX);
      this.user.authorizedSites = state.authorizedSites;
      this.user.authorizedLines = state.authorizedLines;
      this.currentUser = state;
    });
  }

  private readonly routes: string = 'users';

  public isAdminLevel(adminLevel: AdminLevels): boolean {
    let isAuthorized: boolean = false;
    const isSiteAdmin: boolean = this.user.levelId === UserLevels.ADMIN && this.user.authorizedLines === '*';
    const isGlobalAdmin: boolean =
      this.user.levelId === UserLevels.ADMIN && this.user.authorizedLines === '*' && this.user.authorizedSites === '*';

    if (
      (adminLevel === AdminLevels.GLOBAL_ADMIN && isGlobalAdmin) ||
      (adminLevel === AdminLevels.SITE_ADMIN && isSiteAdmin)
    ) {
      isAuthorized = true;
    }

    return isAuthorized;
  }

  getLineUsers(
    lineId: number,
    searchText: string,
    userId: number,
    forCheckOutAll: boolean,
    excludedIds: number[],
  ): Promise<UserGetManyCRUDDataInterface> {
    const queryParams: {
      $and: object[];
    } = {
      $and: [],
    };
    const searchObject: object = {};
    let params: HttpParams = new HttpParams();
    _.set(searchObject, 'isActive.$eq', ActiveStatuses.ACTIVE);

    const orFilter = [];
    orFilter.push({ fullName: { $cont: searchText } }, { userName: { $cont: searchText } });
    queryParams.$and.push({ $or: orFilter });

    if (excludedIds && excludedIds.length > 0) {
      _.set(searchObject, 'id.$notin', excludedIds);
    }

    queryParams.$and.push(searchObject);

    if (forCheckOutAll) {
      queryParams.$and.push({ levelId: { $in: [UserLevels.ADMIN, UserLevels.SUPERVISOR, UserLevels.LINE_LEADER] } });
    }

    if (userId !== null) {
      params = params.set('sort', `id=${userId},DESC`);
    }
    params = params.set('lineId', String(lineId));
    params = params.set('s', JSON.stringify(queryParams));

    return this.getUsersRequest(params);
  }

  public getUsers(
    userId: number,
    searchText: string = null,
    siteId?: number,
    httpParams: HttpParams = new HttpParams(),
  ): Promise<UserGetManyCRUDDataInterface> {
    const orFilter = { $or: [] };
    let queryParams: HttpParams = httpParams;
    let searchParameterValue = null;
    if (!queryParams.has('limit')) {
      queryParams = queryParams.set('limit', '5');
    }

    if (searchText !== null) {
      orFilter.$or.push({ fullName: { $cont: searchText } }, { id: { $eq: userId } });
    }
    if (userId !== null) {
      queryParams = queryParams.set('sort', `id=${userId},DESC`);
    }

    const searchObject: object = {
      isActive: {
        $eq: ActiveStatuses.ACTIVE,
      },
    };

    searchParameterValue = {
      $and: [orFilter, searchObject],
    };
    queryParams = queryParams.set('s', JSON.stringify(searchParameterValue));

    if (!_.isNil(siteId)) {
      queryParams = queryParams.set('siteId', siteId);
    }

    return this.getUsersRequest(queryParams);
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public async getUsersRequest(params: HttpParams): Promise<UserGetManyCRUDDataInterface> {
    return new Promise((resolve, reject) => {
      this.http
        .get(`${this.baseUrl}/${this.routes}`, { params })
        .subscribe((response: UserGetManyCRUDDataInterface) => {
          if (response.hasOwnProperty('data')) {
            resolve(response);
          } else {
            reject();
          }
        });
    });
  }
}
