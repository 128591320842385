import { Inject, Injectable } from '@angular/core';
import {
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../shared/model/interface/crud-response-interface.model';
import {
  ICreateManualCount,
  IManualCount,
  ISetHourlyCount,
  ICommentsResponse,
  IWorkOrderManualCountComment,
  IDistributionResponse,
  IDistributionRequest,
} from '../../view/home/work-order/work-order-manual-count/work-order-manual-count.model';
import { Observable } from 'rxjs';
import {
  AllSettledResolvedInterface,
  BulkResponse,
  ResponseInterface,
} from '../../shared/model/interface/generic-api-response.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ManualCountsReviewData } from './model';

@Injectable({
  providedIn: 'root',
})
export class ManualCountService {
  private readonly routes = {
    productionCount: '/production-counts',
    setHourlyCount: 'set-hourly-count',
    saveComment: '/comments/bulk/save',
    distribution: '/production-counts/distribution',
  };

  constructor(private readonly http: HttpClient, @Inject('API_BASE_URL') private readonly baseUrl: string) {}

  public createManualCount(manualCount: ICreateManualCount): Observable<BaseOneResponseInterface<IManualCount>> {
    return this.http.post<BaseOneResponseInterface<IManualCount>>(
      `${this.baseUrl}${this.routes.productionCount}`,
      manualCount,
    );
  }

  public setHourlyCount(manualCount: ISetHourlyCount): Observable<BaseOneResponseInterface<IManualCount>> {
    return this.http.post<BaseOneResponseInterface<IManualCount>>(
      `${this.baseUrl}${this.routes.productionCount}/${this.routes.setHourlyCount}`,
      manualCount,
    );
  }

  public setBulkHourlyCount(manualCounts: ISetHourlyCount[]): Observable<ResponseInterface<BulkResponse[]>> {
    return this.http.post<ResponseInterface<BulkResponse[]>>(
      `${this.baseUrl}${this.routes.productionCount}/bulk/${this.routes.setHourlyCount}`,
      { hourlyCounts: manualCounts },
    );
  }

  public saveComments(
    comments: IWorkOrderManualCountComment[],
  ): Observable<GetManyResponseInterface<ICommentsResponse>> {
    return this.http.post<GetManyResponseInterface<ICommentsResponse>>(`${this.baseUrl}${this.routes.saveComment}`, {
      comments,
    });
  }

  public getManualCountsData(params: HttpParams): Observable<GetManyResponseInterface<ManualCountsReviewData>> {
    return this.http.get<GetManyResponseInterface<ManualCountsReviewData>>(
      `${this.baseUrl}${this.routes.productionCount}`,
      { params },
    );
  }

  public getDistribution(httpParams: HttpParams): Observable<GetManyResponseInterface<IDistributionResponse>> {
    return this.http.get<GetManyResponseInterface<IDistributionResponse>>(
      `${this.baseUrl}${this.routes.distribution}`,
      { params: httpParams },
    );
  }

  public setDistribution(params: IDistributionRequest): Observable<GetManyResponseInterface<IDistributionResponse>> {
    return this.http.post<GetManyResponseInterface<IDistributionResponse>>(
      `${this.baseUrl}${this.routes.distribution}`,
      params,
    );
  }
}
