import { Action } from '@ngrx/store';
import { GetFeedTokenInterface, NotificationList } from '../../shared/service/notification/notification.service';
import { User } from './model';

export const UPDATE_CURRENT_USER = '[USER] Update current user';
export const UPDATE_CURRENT_USER_LOADED = '[USER] Update current user loaded';
export const CLEAR_USER_LOAD_STATUS = '[USER] Clear User Load Status';
export const SET_AUTH_TOKEN = '[USER] Set Auth Token';
export const CURRENT_USER_LOADING = '[USER] Current user is loading';
export const CURRENT_USER_LOADED = '[USER] Current user is loaded';
export const FETCH_ERROR = '[USER] Fetch Error';
export const SET_ACCESS_TOKEN_LOADED = '[USER] Set Access token is loaded';
export const GET_USER_FEED_TOKEN = '[USER] Get user feed token';
export const GET_USER_FEED_TOKEN_LOADED = '[USER] Get user feed token loaded';
export const GET_NOTIFICATION_LIST = '[USER] Get notification list';
export const GET_NOTIFICATION_LIST_LOADED = '[USER] Get notification list loaded';
export const GET_NOTIFICATION_LIST_WITH_LIMIT_ZERO_LOADED = '[USER] Get notification list with limit zero loaded';
export const SET_IS_NON_VERIFIED_USER_PASS_ACTIVE = '[USER] Set Is Non Verified User Pass Active';
export const CURRENT_USER_DATA_LOADED = '[USER] Current user data loaded';

export class UpdateCurrentUser implements Action {
  readonly type = UPDATE_CURRENT_USER;

  constructor(
    public selectedSiteId: number,
    public selectedLineId: number,
    public navigateToHomeAfterwards?: boolean,
  ) {}
}

export class UpdateCurrentUserLoaded implements Action {
  constructor(public navigateToHomeAfterwards: boolean) {}

  readonly type = UPDATE_CURRENT_USER_LOADED;
}

export class ClearUserLoadStatus implements Action {
  readonly type = CLEAR_USER_LOAD_STATUS;
}

export class CurrentUserLoading implements Action {
  readonly type = CURRENT_USER_LOADING;
}

export class CurrentUserLoaded implements Action {
  readonly type = CURRENT_USER_LOADED;
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;

  constructor(public payload: any[]) {}
}

export class SetAccessTokenLoaded implements Action {
  readonly type = SET_ACCESS_TOKEN_LOADED;
}

export class GetUserFeedToken implements Action {
  readonly type = GET_USER_FEED_TOKEN;
}

export class GetUserFeedTokenLoaded implements Action {
  readonly type = GET_USER_FEED_TOKEN_LOADED;

  constructor(public userFeed: GetFeedTokenInterface) {}
}

export class GetNotificationList implements Action {
  readonly type = GET_NOTIFICATION_LIST;

  constructor(public justCount: boolean = false, public nextId: string = null) {}
}

export class GetNotificationListLoaded implements Action {
  readonly type = GET_NOTIFICATION_LIST_LOADED;

  constructor(public notifications: NotificationList) {}
}

export class GetNotificationListWithLimitZeroLoaded implements Action {
  readonly type = GET_NOTIFICATION_LIST_WITH_LIMIT_ZERO_LOADED;

  constructor(public notifications: NotificationList) {}
}

export class SetIsNonVerifiedUserPassActive implements Action {
  readonly type = SET_IS_NON_VERIFIED_USER_PASS_ACTIVE;

  constructor(public payload: boolean) {}
}

export class CurrentUserDataLoaded implements Action {
  readonly type = CURRENT_USER_DATA_LOADED;

  constructor(public user: User) {}
}

export type UserActions =
  | UpdateCurrentUser
  | UpdateCurrentUserLoaded
  | CurrentUserDataLoaded
  | ClearUserLoadStatus
  | CurrentUserLoading
  | CurrentUserLoaded
  | FetchError
  | SetAccessTokenLoaded
  | GetUserFeedToken
  | GetUserFeedTokenLoaded
  | GetNotificationList
  | GetNotificationListLoaded
  | GetNotificationListWithLimitZeroLoaded
  | SetIsNonVerifiedUserPassActive;
