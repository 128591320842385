import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as ObjectActions from './activity-type.actions';
import { HttpParams } from '@angular/common/http';
import { ActivityTypeInterface } from '../../../shared/model/interface/activities.model';
import { ActivityTypeService } from '../../../shared/service/filter/activity-type.service';

@Injectable()
export class FilterActivityTypeEffects {
  constructor(
    private readonly actions$: Actions<ObjectActions.FilterActivityTypesActions>,
    public service: ActivityTypeService,
  ) {}

  loadFilterActivityTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.FilterActivityTypeActionTypes.StartLoadFilterActivityType),
      switchMap((objectData: ObjectActions.StartLoadFilterActivityTypes) => {
        const params = new HttpParams().set('limit', objectData.options.limit).set('page', objectData.options.page);

        return from(this.service.getData(params)).pipe(
          switchMap((response: ActivityTypeInterface[]) => {
            return of(new ObjectActions.FilterActivityTypesLoaded(response));
          }),
          catchError(() => {
            return of(new ObjectActions.FilterActivityTypesLoaded([]));
          }),
        );
      }),
    ),
  );
}
