import { Action } from '@ngrx/store';
import { ResponseArrayInterface } from '../../shared/model/interface/generic-api-response.model';
import { LineStationGetOneCRUDDataInterface } from './line-station.model';
export const LINE_STATION_DATA_LOADING = '[LINE STATION] Line Station Data Loading';
export const LINE_STATION_DATA_LOADED = '[LINE STATION] Line Station Data Loaded';
export const LINE_STATION_FETCH_ERROR = '[LINE STATION] Line Station Data Fetch Error';

export class LineStationLoading implements Action {
  readonly type = LINE_STATION_DATA_LOADING;

  constructor(public lineId: number) {}
}

export class LineStationLoaded implements Action {
  readonly type = LINE_STATION_DATA_LOADED;

  constructor(public payload: ResponseArrayInterface<LineStationGetOneCRUDDataInterface>) {}
}

export class LineStationFetchError implements Action {
  readonly type = LINE_STATION_FETCH_ERROR;

  constructor(public payload: any[]) {}
}

export type LineStationActions =
  | LineStationLoading
  | LineStationLoaded
  | LineStationFetchError;
