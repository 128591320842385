import { NotificationSetupStateInterface } from './notification-setup.model';
import { NotificationSetupActions, NotificationSetupActionTypes } from './notification-setup.actions';

export const initialState: NotificationSetupStateInterface = {
  notificationSetupsLoading: false,
  notificationSetupsLoaded: false,
};

export function notificationSetupReducer(
  state: NotificationSetupStateInterface = initialState,
  action: NotificationSetupActions,
): NotificationSetupStateInterface {
  switch (action.type) {
    case NotificationSetupActionTypes.LoadNotificationSetups:
      return {
        ...state,
        notificationSetupsLoading: true,
        notificationSetupsLoaded: false,
      };

    case NotificationSetupActionTypes.NotificationSetupsLoaded:
      return {
        ...state,
        notificationSetupsLoading: false,
        notificationSetupsLoaded: true,
      };

    default:
      return state;
  }
}
