import { FilterStartOfDayActions, FilterStartOfDayActionTypes } from './startOfDay.action';
import { FilterStartOfDayState } from './startOfDay.model';

export const initialFilterStartOfDayState: FilterStartOfDayState = {
  isLoaded: false,
  isLoading: false,
  data: [],
};

export function filterStartOfDayReducer(
  state = initialFilterStartOfDayState,
  action: FilterStartOfDayActions,
): FilterStartOfDayState {
  switch (action.type) {
    case FilterStartOfDayActionTypes.StartLoadFilterStartOfDay:
      return { ...state, isLoading: true, isLoaded: false };

    case FilterStartOfDayActionTypes.StartOfDaFilterLoaded:
      return { ...state, data: action.data, isLoaded: true, isLoading: false };

    default:
      return state;
  }
}
