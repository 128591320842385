<mat-checkbox
  [(ngModel)]="inputModel"
  [disabled]="disabled"
  [id]="id"
  [labelPosition]="labelPosition"
  [name]="name"
  [required]="required"
  [value]="value"
  (change)="onChangeEmitter($event)"
  (ngModelChange)="onNgModelChange()"
  [class.checkbox-sm]="size === 'sm'"
  [class.checkbox-md]="size === 'md'"
  [class.checkbox-lg]="size === 'lg'"
>
  <div><ng-content></ng-content></div>
</mat-checkbox>
