import * as Actions from './departments.actions';
import { DepartmentsStateInterface } from './departments.model';

export const departmentsState: DepartmentsStateInterface = {
  departmentsDataLoaded: false,
  departmentsDataLoading: false,
  departments: [],
  productionUnitsDataLoaded: false,
  productionUnitsDataLoading: false,
  productionUnits: [],
  departmentsAddLoading: false,
  departmentsAddLoaded: false,
  departmentsEditLoading: false,
  departmentsEditLoaded: false,
  departmentsBulkEditLoading: false,
  departmentsBulkEditLoaded: false,
  departmentsDeleteLoading: false,
  departmentsDeleteLoaded: false,
  departmentsBulkDeleteLoading: false,
  departmentsBulkDeleteLoaded: false,
  total: null,
};

export function departmentsReducer(
  state: DepartmentsStateInterface = departmentsState,
  action: Actions.DepartmentsActions,
): DepartmentsStateInterface {
  switch (action.type) {
    case Actions.DEPARTMENTS_DATA_LOAD:
      return {
        ...state,
        ...{
          departmentsDataLoading: true,
          departmentsDataLoaded: false,
        },
      };
    case Actions.DEPARTMENTS_DATA_LOADED:
      return {
        ...state,
        ...{
          departments: action.payload.data,
          total: action.payload.total,
          departmentsDataLoading: false,
          departmentsDataLoaded: true,
        },
      };
    case Actions.PRODUCTION_UNIT_DATA_LOAD:
      return {
        ...state,
        ...{
          productionUnitsDataLoading: true,
          productionUnitsDataLoaded: false,
        },
      };
    case Actions.PRODUCTION_UNIT_DATA_LOADED:
      return {
        ...state,
        ...{
          productionUnits: action.payload.data,
          productionUnitsDataLoading: false,
          productionUnitsDataLoaded: true,
        },
      };

    case Actions.ADD_DEPARTMENT:
      return {
        ...state,
        departmentsAddLoading: true,
        departmentsAddLoaded: false,
      };
    case Actions.ADD_DEPARTMENT_COMPLETED:
      return {
        ...state,
        departmentsAddLoading: false,
        departmentsAddLoaded: true,
      };
    case Actions.EDIT_DEPARTMENT:
      return {
        ...state,
        departmentsEditLoading: true,
        departmentsEditLoaded: false,
      };
    case Actions.EDIT_DEPARTMENT_COMPLETED:
      return {
        ...state,
        departmentsEditLoading: false,
        departmentsEditLoaded: true,
      };
    case Actions.BULK_EDIT_DEPARTMENTS:
      return {
        ...state,
        departmentsBulkEditLoading: true,
        departmentsBulkEditLoaded: false,
      };
    case Actions.BULK_EDIT_DEPARTMENTS_COMPLETED:
      return {
        ...state,
        departmentsBulkEditLoading: false,
        departmentsBulkEditLoaded: true,
      };
    case Actions.DELETE_DEPARTMENT:
      return {
        ...state,
        departmentsDeleteLoading: true,
        departmentsDeleteLoaded: false,
      };
    case Actions.DELETE_DEPARTMENT_COMPLETED:
      return {
        ...state,
        departmentsDeleteLoading: false,
        departmentsDeleteLoaded: true,
      };
    case Actions.BULK_DELETE_DEPARTMENTS:
      return {
        ...state,
        departmentsBulkDeleteLoading: true,
        departmentsBulkDeleteLoaded: false,
      };
    case Actions.BULK_DELETE_DEPARTMENTS_COMPLETED:
      return {
        ...state,
        departmentsBulkDeleteLoading: false,
        departmentsBulkDeleteLoaded: true,
      };
    default:
      return state;
  }
}
