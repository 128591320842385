import { ActivityButtonActions, ActivityButtonActionTypes } from './activity-button.actions';
import { ActivityButtonStateInterface } from './activity-button.model';

export const activityButtonInitialState: ActivityButtonStateInterface = {
  isLoading: false,
  isLoaded: false,
  activities: [],
  errors: [],
};

export function activityButtonReducer(
  state: ActivityButtonStateInterface = activityButtonInitialState,
  action: ActivityButtonActions,
) {
  switch (action.type) {
    case ActivityButtonActionTypes.StartActivityButtonDataLoading:
      return {
        ...state,
        isLoading: true,
      };
    case ActivityButtonActionTypes.StartActivityButtonDataLoaded:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        activities: action.payload.data,
      };
    case ActivityButtonActionTypes.FetchError:
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        errors: action.payload,
      };
    case ActivityButtonActionTypes.ResetStore:
      return {
        ...activityButtonInitialState,
      };
    default:
      return state;
  }
}
