import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, switchMap } from 'rxjs/operators';
import { PeriodicOeeCalculationReviewService } from './periodic-oee-calculation-review.service';
import * as oeeAppReducer from '../../oee.reducer';
import * as ObjectActions from './periodic-oee-calculation-review.actions';
import * as AppActions from '../../app/actions';
import { HttpParams } from '@angular/common/http';
import { mysqlDateFormat } from '../../../shared/helper/date';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { of } from 'rxjs';
import { IPeriodicOeeCalculationData } from './periodic-oee-calculation-review.model';

@Injectable()
export class PeriodicOeeCalculationReviewEffects {
  constructor(
    private readonly actions$: Actions,
    public readonly service: PeriodicOeeCalculationReviewService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
  ) {}

  getPeriodicOeeCalculationReviewData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.PERIODIC_OEE_CALCULATION_REVIEW_DATA_LOADING),
      switchMap((objectData: ObjectActions.PeriodicOeeCalculationDataLoading) => {
        let params: HttpParams = new HttpParams()
          .append('join', 'line')
          .append('join', 'line.site')
          .append('join', 'schedulerShift')
          .append('join', 'workOrderSchedule')
          .append('page', objectData.tableQuery.page ?? 1)
          .append('limit', objectData.tableQuery.rowsPerPage ?? 1000)
          .append('sort', 'id,DESC');

        const filter: object = {};

        if (Array.isArray(objectData.tableQuery.sites)) {
          filter['line.siteId'] = { $in: objectData.tableQuery.sites };
        }

        if (Array.isArray(objectData.tableQuery.line)) {
          filter['line_id'] = { $in: objectData.tableQuery.line };
        }

        if (Array.isArray(objectData.tableQuery.workOrderId)) {
          filter['work_order_schedule_id'] = { $in: objectData.tableQuery.workOrderId };
        }

        if (Array.isArray(objectData.tableQuery.shifts)) {
          filter['scheduler_shift_id'] = { $in: objectData.tableQuery.shifts };
        }

        if (objectData.tableQuery.dateRange.startDate && objectData.tableQuery.dateRange.endDate) {
          const startDate: string = objectData.tableQuery.dateRange.startDate.format(mysqlDateFormat);
          const endDate: string = objectData.tableQuery.dateRange.endDate.format(mysqlDateFormat);
          filter['shift_day'] = { $between: [startDate, endDate] };
        }

        params = params.append('s', JSON.stringify(filter));
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.getPeriodicOeeCalculationData(params).pipe(
          switchMap((response: GetManyResponseInterface<IPeriodicOeeCalculationData>) => {
            return of(new ObjectActions.PeriodicOeeCalculationDataLoaded(response));
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchError(err), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );
}
