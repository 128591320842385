import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  CreateExcelSheetInterface,
  ExcelColumnDefinitionInterface,
  ExcelDateFormatInformationInterface,
  ExcelHelperService,
  ExcelSheetTypeEnum,
} from '../../../shared/service/excel/excel-helper.service';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import { TranslateService } from '@ngx-translate/core';
import { ActivityHistoryService } from '../../../shared/service/activity-history/activity-history.service';
import {
  ActivityLogFormattedInterface,
  ActivityLogInterface,
  ActivityLogReportFormattedInterface,
  ActivityLogReportInterface,
} from './activity-logs.model';
import { HelperService } from '../../../shared/service/helper.service';
import { MomentDatePipe } from '../../../shared/pipe/moment-date.pipe';
import {
  ActivityLogsFormattedQueryParams,
  ActivityLogsQueryParams,
} from '../../../view/reports/activity-logs/activity-logs.model';
import { mysqlDateFormat, mysqlTimestampFormat } from '../../../shared/helper/date';
import { forkJoin, Observable, Subject } from 'rxjs';
import { BaseOneResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import * as moment from 'moment-timezone';
import * as ObjectActions from './activity-logs.actions';
import { takeUntil } from 'rxjs/operators';
import { excelDateFormat, excelTimeFormat } from '../../../shared/model/enum/excel-date-format';
import {
  FieldTypes,
  IFilterOutput,
  QueryTypes,
} from '../../../shared/component/filter/advanced-filter/advanced-filter.model';
import { AdvancedFilterService } from '../../../shared/component/filter/advanced-filter/advanced-filter.service';
import * as AppActions from '../../app/actions';
import * as ActivityLogsTableActions from '../../../store/activity-logs-table/activity-logs-table.actions';
import { PhaseOptionNames, PhaseOptions } from '../../../shared/model/enum/phase-options';
import { ActivityTypes } from '../../../shared/model/enum/activity-types';
import { DecimalHelper } from '../../../shared/helper/decimal/decimal-helper';
import * as _ from 'lodash';
import { EntityTranslatorService } from '../../../shared/service/entity-translator/entity-translator.service';

@Injectable({
  providedIn: 'root',
})
export class ActivityLogsService {
  private dateFormatInformation: ExcelDateFormatInformationInterface;
  private readonly routes = {
    activityHistory: `${this.baseUrl}/activity-histories`,
  };
  private readonly destroySubject: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly excelHelper: ExcelHelperService,
    private readonly helperService: HelperService,
    private readonly translate: TranslateService,
    private readonly activityHistoryService: ActivityHistoryService,
    private readonly momentDatePipe: MomentDatePipe,
    private readonly advancedFilterService: AdvancedFilterService,
    private readonly decimalHelper: DecimalHelper,
    private readonly entityTranslatorService: EntityTranslatorService,
  ) {
    let timezone: string = 'utc';
    let dateFormat$: string;
    let timeFormat$: string;
    let locale$: string;
    let dateFormatRaw$: string;
    let dateTimeFormatRaw$: string;

    this.store
      .select('user')
      .pipe(takeUntil(this.destroySubject))
      .subscribe((state) => {
        if (state.isUserLoaded) {
          timezone = state.timezone;

          if (state.locale !== '') {
            dateFormat$ = excelDateFormat[state.locale];
            timeFormat$ = excelTimeFormat[state.locale];
          }

          dateFormatRaw$ = state.dateFormat;
          dateTimeFormatRaw$ = state.dateTimeFormat;
          locale$ = state.locale;

          this.dateFormatInformation = {
            timezone,
            dateFormat$,
            timeFormat$,
            locale$,
            dateFormatRaw$,
            dateTimeFormatRaw$,
          };
          this.destroySubject.next(true);
          this.destroySubject.complete();
        }
      });
  }

  public getActivityLogs(body): Observable<BaseOneResponseInterface<ActivityLogReportInterface>> {
    return this.http.post<BaseOneResponseInterface<ActivityLogReportInterface>>(
      `${this.routes.activityHistory}/activity-logs`,
      body,
      { headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }) },
    );
  }

  public getActivityLogsExport(body): Observable<BaseOneResponseInterface<ActivityLogInterface[]>> {
    return this.http.post<BaseOneResponseInterface<ActivityLogInterface[]>>(
      `${this.routes.activityHistory}/activity-logs-export`,
      body,
      { headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }) },
    );
  }

  public formatActivityLogsData(data: ActivityLogReportInterface): ActivityLogReportFormattedInterface {
    return {
      ...data,
      totalCount: Number(data.totalCount),
      totalDuration: !_.isNil(data.totalDuration)
        ? this.helperService.formatDuration(Number(data.totalDuration))
        : null,
      exceptionDuration: !_.isNil(data.exceptionDuration)
        ? this.helperService.formatDuration(Number(data.exceptionDuration))
        : null,
      rows: this.formatActivityLogsRows(data?.rows) as unknown as ActivityLogFormattedInterface[],
    };
  }

  public formatActivityLogsRows(
    rows: ActivityLogInterface[] = [],
    formatAdditionalFields: boolean = false,
    formatWithUserFormat: boolean = true,
    isDataAnalysisFormat: boolean = false,
  ): ActivityLogFormattedInterface[] {
    return rows.map(({ goodCount, initialCount, ...row }) => {
      const targetDuration: string | number =
        row.activityTypeText === ActivityTypes.RUN_TIME || _.isNil(row.targetDuration)
          ? null
          : isDataAnalysisFormat
          ? this.decimalHelper.toFixedValue(row.targetDuration.toString())
          : formatWithUserFormat
          ? this.decimalHelper.toFixedValue(row.targetDuration.toString())
          : row.targetDuration;
      const formattedRow: ActivityLogFormattedInterface = {
        ...row,
        targetDuration,
        duration: !_.isNil(row.duration) ? this.helperService.formatDuration(Number(row.duration)) : null,
        start: isDataAnalysisFormat
          ? row.start
          : this.momentDatePipe.transform(row.start, this.dateFormatInformation.dateTimeFormatRaw$),
        end: isDataAnalysisFormat
          ? row.end === 'Ongoing'
            ? null
            : row.end
          : !_.isNil(row.end) && row.end !== 'Ongoing'
          ? this.momentDatePipe.transform(row.end, this.dateFormatInformation.dateTimeFormatRaw$)
          : this.translate.instant('general.ongoing'),
        shiftDay: isDataAnalysisFormat
          ? row.shiftDay
          : this.momentDatePipe.transform(row.shiftDay, this.dateFormatInformation.dateFormatRaw$),
        durationMin: !_.isNil(row.duration)
          ? this.decimalHelper.toFixedValue(moment.duration(Number(row.duration), 's').as('m').toString())
          : null,
        crewSize: !_.isNil(row?.crewSize) ? this.decimalHelper.toFixedValue(row?.crewSize, 0) : null,
        phaseName: this.getPhaseName(row),
        activityType: row.activityTypeText,
        activityTypeText: this.getActivityTypeName(row),
        lcl: _.isNil(row.lcl) ? null : this.decimalHelper.formatDecimalValueForExcel(row.lcl),
        ucl: _.isNil(row.ucl) ? null : this.decimalHelper.formatDecimalValueForExcel(row.ucl),
      };
      if (formatAdditionalFields) {
        Object.assign(formattedRow, {
          goodCount: _.isNil(goodCount) ? null : this.decimalHelper.formatDecimalValueForExcel(goodCount),
          initialCount: _.isNil(initialCount) ? null : this.decimalHelper.formatDecimalValueForExcel(initialCount),
        });
      }
      return formattedRow;
    });
  }

  public getPhaseName(row: ActivityLogInterface): string {
    const phaseOptions = {
      [PhaseOptionNames[PhaseOptions.NO_PHASE]]: this.translate.instant(
        'activityCards.activityEditForm.phaseOptions.noPhase',
      ),
      [PhaseOptionNames[PhaseOptions.PRE_RUN]]: this.translate.instant(
        'activityCards.activityEditForm.phaseOptions.pre',
      ),
      [PhaseOptionNames[PhaseOptions.RUN]]: this.translate.instant('activityCards.activityEditForm.phaseOptions.run'),
      [PhaseOptionNames[PhaseOptions.POST_RUN]]: this.translate.instant(
        'activityCards.activityEditForm.phaseOptions.post',
      ),
    };

    let phaseName: string = null;

    switch (row.phaseId) {
      case 1:
        phaseName =
          row.preRunPhaseName && row.preRunPhaseName.trim() !== ''
            ? row.preRunPhaseName
            : phaseOptions[PhaseOptionNames[Number(row.phaseId)]];
        break;
      case 2:
        phaseName =
          row.postRunPhaseName && row.postRunPhaseName.trim() !== ''
            ? row.postRunPhaseName
            : phaseOptions[PhaseOptionNames[Number(row.phaseId)]];
        break;
      case 3:
        phaseName =
          row.runPhaseName && row.runPhaseName.trim() !== ''
            ? row.runPhaseName
            : phaseOptions[PhaseOptionNames[Number(row.phaseId)]];
        break;
      default:
        phaseName = phaseOptions[PhaseOptionNames[PhaseOptions.NO_PHASE]];
        break;
    }

    return phaseName;
  }

  public getActivityTypeName(row: ActivityLogInterface): string {
    switch (row.activityTypeText) {
      case 'runTime':
        return this.translate.instant('general.activityType.runTime');
      case 'idleTime':
        return this.translate.instant('general.activityType.idleTime');
      case 'downTime':
        return this.translate.instant('general.activityType.downTime');
      default:
        return this.translate.instant('general.activityType.downTimePlanned');
    }
  }

  public formatActivityLogParams(params: ActivityLogsQueryParams): ActivityLogsFormattedQueryParams {
    const dateFormat = params.isBusinessDate ? mysqlDateFormat : mysqlTimestampFormat;
    const data: ActivityLogsFormattedQueryParams = {
      page: params.page,
      offset: params.offset ? Number(params.offset) : 10,
      isBusinessDate: params.isBusinessDate,
      sites: HelperService.formatDropdownFilterOptionOutput(params?.sites),
      lines: HelperService.formatDropdownFilterOptionOutput(params?.lines),
      shifts: HelperService.formatDropdownFilterOptionOutput(params?.shifts),
      activities: HelperService.formatDropdownFilterOptionOutput(params?.activities),
      lineTypes: HelperService.formatDropdownFilterOptionOutput(params?.lineTypes),
      workOrders: HelperService.formatDropdownFilterOptionOutput(params?.workOrders),
      start: params.dateRange?.startDate.format(dateFormat),
      end: params.dateRange?.endDate.format(dateFormat),
      advancedFilterPage: params.advancedFilterPage ?? params.advancedFilter?.page,
      ...(_.isNil(params?.isQuantityBased) ? undefined : { isQuantityBased: params.isQuantityBased }),
      ...(params.sort?.length ? { sort: `${params.sort[0].field},${params.sort[0].order}` } : undefined),
    };
    if (params.advancedFilter.page === 'root-cause-analysis') {
      data.activityTypes = params.activityTypes;
    }
    const searchParams = {
      $and: [],
    };

    if (params.advancedFilter?.filters) {
      const queryStringFilters: IFilterOutput[] = params.advancedFilter.filters.filter((filter: IFilterOutput) => {
        return filter.queryType === QueryTypes.withinRequestQueryString;
      });

      queryStringFilters.forEach((filter: IFilterOutput) => {
        const queryStringFilters: IFilterOutput[] = params.advancedFilter.filters.filter((filter: IFilterOutput) => {
          return filter.queryType === QueryTypes.withinRequestQueryString;
        });

        queryStringFilters.forEach((filter: IFilterOutput) => {
          if (filter.type === FieldTypes.predefined) {
            _.set(data, filter.path, _.get(filter.value, `[0][${filter.searchBy}]`, ''));
          } else {
            _.set(data, filter.path, filter.value);
          }
        });

        if (filter.type === FieldTypes.predefined) {
          _.set(data, filter.path, _.get(filter.value, `[0][${filter.searchBy}]`, ''));
        } else {
          _.set(data, filter.path, filter.value);
        }
      });

      for (const filter of params.advancedFilter.filters) {
        if (filter.queryType !== QueryTypes.withinAdvancedFilterParams) continue;

        if (filter.type === FieldTypes.predefined) {
          searchParams.$and.push(
            this.advancedFilterService.generateQuery(
              filter.path,
              filter.type,
              filter.operator.name,
              filter.operator.type,
              params.advancedFilter.target,
              _.get(filter.value, `[0][${filter.searchBy}]`, ''),
              filter.isDbPath,
            ),
          );
          continue;
        }

        searchParams.$and.push(
          this.advancedFilterService.generateQuery(
            filter.path,
            filter.type,
            filter.operator.name,
            filter.operator.type,
            params.advancedFilter.target,
            filter.value,
            filter.isDbPath,
          ),
        );
      }

      _.set(
        data,
        this.advancedFilterService.getRequestQueryParameterName(params.advancedFilter.target),
        JSON.stringify(searchParams),
      );
    }

    return data;
  }

  public downloadExcel(
    params: ActivityLogsQueryParams,
    worksheetsColumnDefinitions: ExcelColumnDefinitionInterface[][],
    createAsCsvFile: boolean = false,
    data?: ActivityLogInterface[],
  ): void {
    this.store.dispatch(new AppActions.ShowLoader());

    if (data) {
      this.generateExcelFile(params, worksheetsColumnDefinitions, createAsCsvFile, data);
      return;
    }

    const sourcesObject: { activityLogs: Observable<BaseOneResponseInterface<ActivityLogInterface[]>> } = {
      activityLogs: this.getActivityLogsExport(this.formatActivityLogParams(params)),
    };

    forkJoin(sourcesObject).subscribe(
      (response: { activityLogs: BaseOneResponseInterface<ActivityLogInterface[]> }) => {
        response.activityLogs.data.forEach((activityLog: ActivityLogInterface, index: number) => {
          this.entityTranslatorService.translate(activityLog);
          this.setActivityLogProductInfo(activityLog);
          this.setActivityLogTaskName(activityLog);
        });
        this.generateExcelFile(params, worksheetsColumnDefinitions, createAsCsvFile, response.activityLogs?.data);
      },
    );
  }

  private generateExcelFile(
    params: ActivityLogsQueryParams,
    worksheetsColumnDefinitions: ExcelColumnDefinitionInterface[][],
    createAsCsvFile: boolean = false,
    data: ActivityLogInterface[],
  ): void {
    const sheetTitle: string | any = this.translate.instant('pageTitles.activity_logs');
    const excelName: string = `${sheetTitle} ${moment()
      .tz(this.dateFormatInformation.timezone)
      .format(this.dateFormatInformation.dateFormat$)}`;
    const formatNumbersWithUserFormat: boolean =
      params.advancedFilter.page === 'root-cause-analysis' || params.advancedFilter.page === 'work-order-performance';
    const worksheets: CreateExcelSheetInterface[] = [
      {
        sheetTitle: this.translate.instant('activityLogs.excel.readme.worksheetName'),
        sheetType: ExcelSheetTypeEnum.README,
      },
      {
        sheetTitle: this.translate.instant('activityLogs.excel.readme.templateFormatTitle'),
        sheetType: ExcelSheetTypeEnum.TABLE,
        params: {
          columns: worksheetsColumnDefinitions[0],
          data: this.formatActivityLogsRows(data, true, !formatNumbersWithUserFormat),
        },
        withData: true,
        isDisabledColumnsFirstLine: true,
        addDateTimeFormula: undefined,
        excelRowFormatLimit: params.offset + 1,
      },
      {
        sheetTitle: this.translate.instant('activityLogs.excel.readme.dataAnalysisFormatTitle'),
        sheetType: ExcelSheetTypeEnum.TABLE,
        params: {
          columns: worksheetsColumnDefinitions[1],
          data: this.formatActivityLogsRows(data, true, true, true),
        },
        withData: true,
        isDisabledColumnsFirstLine: true,
        addDateTimeFormula: undefined,
        excelRowFormatLimit: params.offset + 1,
      },
    ];

    this.excelHelper
      .createExcel(
        excelName,
        worksheets,
        this.dateFormatInformation.timezone,
        this.dateFormatInformation.dateFormat$,
        this.dateFormatInformation.timeFormat$,
        createAsCsvFile,
        2,
      )
      .then(
        () => {
          if (
            params.advancedFilterPage === 'root-cause-analysis' ||
            params.advancedFilterPage === 'work-order-performance'
          ) {
            this.store.dispatch(new ActivityLogsTableActions.ActivityLogsTableDownloadExcelCompleted());
            this.store.dispatch(new AppActions.HideLoader());
            this.store.dispatch(new AppActions.HideTopLoader());
          } else {
            this.store.dispatch(new ObjectActions.ActivityLogsDownloadExcelCompleted());
            this.store.dispatch(new AppActions.HideLoader());
            this.store.dispatch(new AppActions.HideTopLoader());
          }
        },
        () => {
          this.store.dispatch(new ObjectActions.FetchError({}));
          this.store.dispatch(new AppActions.HideLoader());
        },
      );
  }

  public setActivityLogProductInfo(activityLogData: ActivityLogInterface): void {
    if (!_.isNil(activityLogData.productId) && !_.isNil(activityLogData.productDescription)) {
      activityLogData.productInfo = `${activityLogData.productId} - ${activityLogData.productDescription}`;
    }
  }

  public setActivityLogTaskName(activityLogData: ActivityLogInterface): void {
    if (activityLogData.activityTypeText === ActivityTypes.RUN_TIME) {
      activityLogData.taskName = activityLogData.productDescription ?? '';
    }
  }
}
