import { Action } from '@ngrx/store';
import { ILookupData } from './lookups.model';

export const START_LOOKUPS_DATA_LOADING = '[LOOKUPS] START LOOKUPS DATA LOADING';
export const LOOKUPS_DATA_LOADED = '[LOOKUPS] LOOKUPS DATA LOADED';
export const FETCH_DATA_ERROR = '[LOOKUPS] FETCH DATA ERROR';

export class StartLookupsDataLoading implements Action {
  readonly type = START_LOOKUPS_DATA_LOADING;

  constructor(public id?: number) {}
}

export class LookupsDataLoaded implements Action {
  readonly type = LOOKUPS_DATA_LOADED;

  constructor(public payload: ILookupData[]) {}
}

export class FetchDataError implements Action {
  readonly type = FETCH_DATA_ERROR;

  constructor(public payload: object) {}
}

export type LookupActions = StartLookupsDataLoading | LookupsDataLoaded | FetchDataError;
