import { Action } from '@ngrx/store';
import {
  AddKpiCardInterface,
  IKpiCardsFilters,
  KpiCardInterface,
  KpiCardTableQueryParams,
  BulkResponseDataInterface,
} from './kpi-cards.model';
import { TabRowInterface } from '../../../../shared/component/side-config-bar/side-config-bar.model';
import { LinesResponseInterface, SitesResponseInterface } from '../../../work-order-schedule/work-order-schedule.model';
import {
  BaseCrudResponse,
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../../../shared/model/interface/crud-response-interface.model';

export const KPI_CARDS_DATA_LOADING = '[KPI_CARDS] KPI CARDS DATA LOADING';
export const KPI_CARDS_DATA_LOADED = '[KPI_CARDS] KPI CARDS DATA LOADED';
export const KPI_CARDS_FETCH_ERROR = '[KPI_CARDS] KPI CARDS ACTION FETCH ERROR';
export const DELETE_KPI_CARDS = '[KPI_CARDS] KPI CARDS ACTION DELETE KPI CARDS';
export const DELETE_KPI_CARDS_COMPLETED = '[KPI_CARDS] KPI CARDS ACTION DELETE KPI CARDS COMPLETED';
export const ADD_KPI_CARD = '[KPI_CARDS] ADD KPI CARD';
export const ADD_KPI_CARD_COMPLETED = '[KPI_CARDS] ADD KPI CARD COMPLETED';
export const EDIT_KPI_CARD = '[KPI_CARDS] EDIT KPI CARD';
export const EDIT_KPI_CARD_COMPLETED = '[KPI_CARDS] EDIT KPI CARD COMPLETED';
export const DUPLICATE_KPI_CARD = '[KPI_CARDS] DUPLICATE KPI CARD';
export const DUPLICATE_KPI_CARD_COMPLETED = '[KPI_CARDS] DUPLICATE KPI CARD COMPLETED';
export const SET_SELECTED_FILTERS = '[KPI_CARDS] SET SELECTED FILTERS';
export const SET_TABLE_SETTINGS = '[KPI_CARDS] SET TABLE SETTINGS';

export class KpiCardsDataLoading implements Action {
  readonly type = KPI_CARDS_DATA_LOADING;

  constructor(
    public query: KpiCardTableQueryParams = {
      page: 1,
      pageSize: 10,
    },
  ) {}
}

export class KpiCardsDataLoaded implements Action {
  readonly type = KPI_CARDS_DATA_LOADED;
  constructor(public payload: GetManyResponseInterface<KpiCardInterface>) {}
}

export class DeleteKpiCards implements Action {
  readonly type = DELETE_KPI_CARDS;
  constructor(public payload: number[]) {}
}

export class DeleteKpiCardsCompleted implements Action {
  readonly type = DELETE_KPI_CARDS_COMPLETED;
  constructor(public isBulk: boolean, public response: BaseCrudResponse | BulkResponseDataInterface) {}
}

export class AddKpiCard implements Action {
  readonly type = ADD_KPI_CARD;
  constructor(public kpiCard: AddKpiCardInterface) {}
}

export class AddKpiCardCompleted implements Action {
  readonly type = ADD_KPI_CARD_COMPLETED;
  constructor(public payload: BaseOneResponseInterface<AddKpiCardInterface>) {}
}

export class EditKpiCard implements Action {
  readonly type = EDIT_KPI_CARD;
  constructor(public kpiCard: AddKpiCardInterface, public kpiCardId: number) {}
}

export class EditKpiCardCompleted implements Action {
  readonly type = EDIT_KPI_CARD_COMPLETED;
  constructor(public payload: BaseOneResponseInterface<AddKpiCardInterface>) {}
}

export class DuplicateKpiCard implements Action {
  readonly type = DUPLICATE_KPI_CARD;
  constructor(public kpiCard: AddKpiCardInterface) {}
}

export class DuplicateKpiCardCompleted implements Action {
  readonly type = DUPLICATE_KPI_CARD_COMPLETED;
  constructor(public payload: BaseOneResponseInterface<AddKpiCardInterface>) {}
}

export class SetSelectedFilters implements Action {
  readonly type = SET_SELECTED_FILTERS;
  constructor(public filters: IKpiCardsFilters) {}
}

export class SetTableSettings implements Action {
  readonly type = SET_TABLE_SETTINGS;
  constructor(public payload: TabRowInterface[], public setAsDefault: boolean) {}
}

export class FetchError implements Action {
  readonly type = KPI_CARDS_FETCH_ERROR;
  constructor(public payload: object) {}
}

export type KpiCardsActions =
  | KpiCardsDataLoading
  | KpiCardsDataLoaded
  | DeleteKpiCards
  | DeleteKpiCardsCompleted
  | AddKpiCard
  | AddKpiCardCompleted
  | EditKpiCard
  | EditKpiCardCompleted
  | DuplicateKpiCard
  | DuplicateKpiCardCompleted
  | SetSelectedFilters
  | SetTableSettings
  | FetchError;
