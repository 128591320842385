import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { forkJoin, Observable, Subject } from 'rxjs';
import { IFilterSettings, LineInterface, SiteInterface } from '../line-view-work-order-summary/line-view-work-order-summary.model';
import {
  BaseCrudResponse,
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../shared/model/interface/crud-response-interface.model';
import { map, take, takeUntil } from 'rxjs/operators';
import {
  CreateExcelInterface,
  CreateExcelSheetInterface,
  EXCEL_DEFAULT_DATE_FORMAT,
  EXCEL_DEFAULT_TIME_FORMAT,
  ExcelHelperService,
  ExcelSheetTypeEnum,
} from '../../shared/service/excel/excel-helper.service';
import {
  AssetDashboardMailBodyInterface,
  AssetDashboardResponseInterface,
  FilterSettingsPostBodyInterface,
  IWidgetData,
} from './asset-view.model';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../oee.reducer';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment-timezone';
import { excelDateFormat, excelTimeFormat } from '../../shared/model/enum/excel-date-format';
import { PageFiltersAndSettingsService } from '../../shared/service/page-filters-and-settings/page-filters-and-settings.service';
import {
  IComponentConfiguration,
  UserConfigurationStateInterface,
} from '../user-configuration/user-configuration.model';
import { StatusesEnum } from '../../../constants';
import * as ObjectActions from '../asset-view/asset-view.actions';
import * as AppActions from '../app/actions';

@Injectable({
  providedIn: 'root',
})
export class AssetViewService {
  private readonly ASSET_VIEW = {
    GET: {
      FILTER_URL: `${this.baseUrl}/user-filters-and-settings`,
      SITE_URL: `${this.baseUrl}/sites`,
      ASSET_URL: `${this.baseUrl}/lines`,
      ASSET_REPORT_URL: `${this.baseUrl}/check-ins/dashboard/asset`,
    },
    POST: {
      FILTER_URL: '/api/user-filters-and-settings',
      ASSET_MAIL_URL: '/api/asset-view',
    },
  };
  private readonly defaultTable: string[] = [
    'assetName',
    'locationName',
    'lineStationName',
    'status',
    'checkInTime',
    'checkOutTime',
    'ongoingWoNumber',
    'ongoingProductDescription',
    'equipmentType',
    'equipmentBrand',
    'equipmentModel',
    'equipmentDescription',
  ];
  private readonly tableOptions: string[] = [...this.defaultTable];
  private readonly defaultWidgets: string[] = ['totalUniqueCount'];
  private readonly widgetOptions: string[] = [...this.defaultWidgets];
  private timezone: string = 'utc';
  private dateFormat: string = EXCEL_DEFAULT_DATE_FORMAT;
  private timeFormat: string = EXCEL_DEFAULT_TIME_FORMAT;
  private readonly destroySubject: Subject<boolean> = new Subject<boolean>();

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly excelHelper: ExcelHelperService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly translate: TranslateService,
  ) {
    this.store
      .select('user')
      .pipe(takeUntil(this.destroySubject))
      .subscribe((state) => {
        if (state.isUserLoaded) {
          this.timezone = state.timezone;

          if (state.locale !== '') {
            this.dateFormat = excelDateFormat[state.locale];
            this.timeFormat = excelTimeFormat[state.locale];
          }

          this.destroySubject.next(true);
          this.destroySubject.complete();
        }
      });
  }

  public getFiltersAndSettings(): Observable<{ data: IWidgetData }> {
    const baseHttpParams = new HttpParams().append('limit', '1000');
    const observables: Observable<
      GetManyResponseInterface<SiteInterface> | GetManyResponseInterface<LineInterface>
    >[] = [
      this.http.get<GetManyResponseInterface<SiteInterface>>(this.ASSET_VIEW.GET.SITE_URL, {
        params: baseHttpParams.append('s', JSON.stringify({ status: StatusesEnum.ACTIVE })),
      }),
      this.http.get<GetManyResponseInterface<LineInterface>>(this.ASSET_VIEW.GET.ASSET_URL, {
        params: baseHttpParams
          .append('join', 'lineTypeName')
          .append('s', JSON.stringify({ status: StatusesEnum.ACTIVE })),
      }),
    ];

    return forkJoin(observables).pipe(
      map((array) => {
        let configuration: IComponentConfiguration[] | undefined = [];
        this.store
          .select('userConfigurationStore')
          .pipe(take(1))
          .subscribe((state: UserConfigurationStateInterface) => {
            configuration = state.userConfigurationData.AssetViewComponent;
          });
        const [sitesResponse, linesResponse] = array;

        let filterObject: IFilterSettings = {
          table: this.defaultTable,
          widgets: this.defaultWidgets,
        };

        if (configuration !== undefined) {
          const filters = configuration.find((conf) => conf.name === 'filters');

          const sitesConfiguration =
            filters === undefined ? undefined : filters.children.find((conf) => conf.name === 'siteIds');

          const linesConfiguration =
            filters === undefined ? undefined : filters.children.find((conf) => conf.name === 'lineIds');

          const widgetSettings = configuration.find((conf) => conf.name === 'widgetSettings');

          const tableColumns = configuration.find((conf) => conf.name === 'tableColumns');

          filterObject = Object.assign(filterObject, {
            filterLineId: linesConfiguration === undefined ? null : linesConfiguration.value,
            filterSiteId: sitesConfiguration === undefined ? null : sitesConfiguration.value,
            table: tableColumns === undefined ? this.defaultTable : tableColumns.value,
            widgets: widgetSettings === undefined ? this.defaultWidgets : widgetSettings.value,
          });
        } else {
          filterObject = Object.assign(filterObject, {
            filterSiteId: sitesResponse.data.map((site) => {
              return site.id;
            }),
            filterLineId: linesResponse.data.map((line) => {
              return line.id;
            }),
            table: this.defaultTable,
            widgets: this.defaultWidgets,
          });
        }

        return {
          data: {
            sites: filterObject.filterSiteId,
            lines: filterObject.filterLineId,
            table: PageFiltersAndSettingsService.getObjectConfigurationOfUser(
              filterObject.table as string[],
              this.tableOptions,
            ),
            widgets: PageFiltersAndSettingsService.getObjectConfigurationOfUser(
              filterObject.widgets as string[],
              this.widgetOptions,
            ),
          },
        };
      }),
    );
  }

  public getAssetDashboardData(
    params: HttpParams,
  ): Observable<BaseOneResponseInterface<AssetDashboardResponseInterface>> {
    return this.http.get<BaseOneResponseInterface<AssetDashboardResponseInterface>>(
      this.ASSET_VIEW.GET.ASSET_REPORT_URL,
      {
        params,
      },
    );
  }

  public downloadAssetViewExcel(excelCreateOptions: CreateExcelInterface, mode: string): void {
    const title: string = this.translate.instant('pageTitles.asset_view');

    const worksheets: CreateExcelSheetInterface[] = [
      {
        sheetTitle: title,
        sheetType: ExcelSheetTypeEnum.TABLE,
        params: excelCreateOptions,
        withData: true,
      },
    ];

    this.excelHelper
      .createExcel(
        `${title} ${moment()
          .tz(this.timezone)
          .format(this.dateFormat)}`,
        worksheets,
        this.timezone,
        this.dateFormat,
        this.timeFormat,
        mode === 'csv',
      )
      .then(
        () => {
          this.store.dispatch(new AppActions.HideLoader());
        },
        () => {
          this.store.dispatch(new ObjectActions.FetchError({}));
          this.store.dispatch(new AppActions.HideLoader());
        },
      );
  }

  public saveSelectedFilterSettings(body: FilterSettingsPostBodyInterface): Observable<BaseCrudResponse> {
    return this.http.post<BaseCrudResponse>(this.ASSET_VIEW.POST.FILTER_URL, body);
  }

  public sendAssetViewMail(body: AssetDashboardMailBodyInterface): Observable<BaseCrudResponse> {
    return this.http.post<BaseCrudResponse>(this.ASSET_VIEW.POST.ASSET_MAIL_URL, body);
  }
}
