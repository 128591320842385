import { AbstractCacheService, ICacheService } from '../../../shared/service/cache-service';
import { BaseOneResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { Injectable } from '@angular/core';

export interface IEquipmentAvatarCacheService extends ICacheService<BaseOneResponseInterface<string>> {}

@Injectable({
  providedIn: 'root'
})
export class EquipmentAvatarCacheService extends AbstractCacheService<BaseOneResponseInterface<string>>
  implements IEquipmentAvatarCacheService {
  protected override MAX_CACHE_SIZE: number = 100;
}
