import { Inject, Injectable } from '@angular/core';
import { FilterServiceInterface, SensorTypeCRUDResponseInterface } from './service.class';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SensorTypeCRUDInterface } from '../../component/filter/filter.class';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SensorTypesService implements FilterServiceInterface {
  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private baseUrl: string,
  ) {}
  getData(): Promise<SensorTypeCRUDInterface[]> {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.baseUrl}/sensor-type`).subscribe(
        (response: SensorTypeCRUDResponseInterface) => {
          if (response.hasOwnProperty('data')) {
            resolve(response.data);
          } else {
            reject();
          }
        });
    });
  }

  public getSensorType(params?: HttpParams): Observable<SensorTypeCRUDResponseInterface> {
    return this.http.get<SensorTypeCRUDResponseInterface>(`${this.baseUrl}/sensor-type`, {
      params,
    });
  }
}
