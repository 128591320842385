import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ObjectActions from './equipments.actions';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { combineLatest, from, of } from 'rxjs';
import * as AppActions from '../../app/actions';
import { HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import { ErrorMessageService } from '../../../shared/service/error-message.service';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { ExcelHelperService } from '../../../shared/service/excel/excel-helper.service';
import { EquipmentsService } from '../../../shared/service/settings/equipments/equipments.service';
import { IEquipment } from './equipments.model';
import { AnonymousCardsService } from '../../../shared/service/anonymous-cards/anonymous-cards.service';
import { IAnonymousCard } from '../users/users.model';
import { EntityTranslatorService } from '../../../shared/service/entity-translator/entity-translator.service';
import { User } from '../../user/model';
import { emptyAction } from '../../../../constants';

@Injectable()
export class EquipmentsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: EquipmentsService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly errorMessageService: ErrorMessageService,
    private readonly excelHelperService: ExcelHelperService,
    private readonly anonymousCardsService: AnonymousCardsService,
    private translatorService: EntityTranslatorService,
  ) {}

  loadAnonymousCards = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LOAD_ANONYMOUS_CARDS),
      switchMap((objectData: ObjectActions.LoadAnonymousCards) => {
        this.store.dispatch(new AppActions.ShowLoader());
        let httpParams = new HttpParams().set('limit', '1000');

        if (objectData.search) {
          httpParams = httpParams.set('search', String(objectData.search));
        }

        httpParams = httpParams.set('sites', String(objectData.site));
        return this.anonymousCardsService.getAnonymousCards(httpParams).pipe(
          switchMap((response: GetManyResponseInterface<IAnonymousCard>) => {
            return of(new ObjectActions.AnonymousCardsLoaded(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getTotalDataCount = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LOAD_EQUIPMENT_DATA_COUNT),
      switchMap((objectData: ObjectActions.LoadExcelDataCount) => {
        this.store.dispatch(new AppActions.ShowLoader());

        const httpParams: HttpParams = new HttpParams()
          .set('limit', 1)
          .set('fields', 'id')
          .set('join', 'site||id')
          .set('s', JSON.stringify({ 'site.id': objectData.siteId }));

        return this.service.getData(httpParams).pipe(
          switchMap((response: GetManyResponseInterface<IEquipment>) => {
            return of(new ObjectActions.ExcelDataCountLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getEquipmentData = createEffect(() =>
    combineLatest([this.actions$.pipe(ofType(ObjectActions.LOAD_EQUIPMENTS_DATA)), this.store.select('user')]).pipe(
      switchMap(([payload, state]: [payload: ObjectActions.LoadEquipmentsData, state: User]) => {
        const { page, pageSize, sort, search, siteId } = payload.requestParams;

        const searchParams = { $and: [] };

        if (search !== '') {
          searchParams.$and.push({
            $or: [
              { equipmentName: { $cont: search } },
              { equipmentDescription: { $cont: search } },
              { equipmentShortName: { $cont: search } },
              { equipmentModel: { $cont: search } },
              { 'equipmentBrandRelation.name': { $cont: search } },
              { 'equipmentTypeRelation.description': { $cont: search } },
              { validatedSpeed: { $cont: search } },
            ],
          });
        }

        if (siteId !== undefined && siteId !== -1) {
          searchParams.$and.push({ 'site.id': { $in: siteId } });
        }

        if (searchParams.$and.length === 0) {
          delete searchParams.$and;
        }

        let httpParams: HttpParams = new HttpParams()
          .append('join', 'site||name')
          .append('join', 'equipmentTypeRelation')
          .append('join', 'equipmentBrandRelation')
          .set('page', String(page))
          .set('limit', String(pageSize))
          .set('s', JSON.stringify(searchParams))
          .set('searchText', search);

        if (sort) {
          const direction: 'DESC' | 'ASC' = sort.type === 'descending' ? 'DESC' : 'ASC';
          httpParams = httpParams.set('sort', `${sort.column},${direction}`);
        }

        return this.service.getData(httpParams).pipe(
          switchMap((response: GetManyResponseInterface<IEquipment>) => {
            response.data.forEach((equipment: IEquipment) => this.translatorService.translate(equipment));

            return of(new ObjectActions.LoadedEquipmentsData(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes));
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes));
      }),
    ),
  );

  editSingleEquipmentData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SINGLE_EDIT_EQUIPMENTS_DATA),
      switchMap((objectData: ObjectActions.SingleEditEquipmentsData) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return from(this.service.patchEquipmentData(objectData.equipmentId, objectData.payload)).pipe(
          switchMap((response: BaseOneResponseInterface<IEquipment>) => {
            const actions = [
              new ObjectActions.EditedSingleEditEquipmentsData(response),
              ...(objectData.hideLoaderAfterSuccess ? [new AppActions.HideLoader()] : []),
            ];
            return of(...actions);
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchError(err), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  editEquipmentsData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.EDIT_EQUIPMENTS_DATA),
      switchMap((objectData: ObjectActions.EditEquipmentsData) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return from(this.service.patchEquipmentsData(objectData.payload)).pipe(
          switchMap((response) => {
            return of(new ObjectActions.EditedEquipmentsData(response), new AppActions.HideLoader());
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchError(err), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  createEquipment = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.CREATE_EQUIPMENT),
      switchMap((objectData: ObjectActions.CreateEquipment) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return from(this.service.createEquipment(objectData.payload)).pipe(
          switchMap((response) => {
            const actions = [
              new ObjectActions.CreatedEquipment(response),
              ...(objectData.hideLoaderAfterSuccess ? [new AppActions.HideLoader()] : []),
            ];
            return of(...actions);
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchError(err), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  deleteEquipment = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DELETE_EQUIPMENT),
      switchMap((objectData: ObjectActions.DeleteEquipment) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return from(this.service.deleteEquipment(objectData.equipmentId)).pipe(
          switchMap((response) => {
            return of(new ObjectActions.DeletedEquipment(response), new AppActions.HideLoader());
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchError(err), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  deleteEquipments = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DELETE_EQUIPMENTS),
      switchMap((objectData: ObjectActions.DeleteEquipments) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return from(this.service.deleteEquipments(objectData.equipmentIds)).pipe(
          switchMap((response) => {
            return of(new ObjectActions.DeletedEquipments(response), new AppActions.HideLoader());
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchError(err), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  downloadEquipmentExcel = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DOWNLOAD_EQUIPMENT_EXCEL),
      switchMap((objectData: ObjectActions.DownloadEquipmentExcel) => {
        this.store.dispatch(new AppActions.ShowLoader());
        this.service.downloadEquipmentExcel(objectData.filters, undefined, {
          withData: objectData.withData,
          withErrorColumn: false,
        });
        return emptyAction;
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  downloadEquipmentExcelCompleted = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DOWNLOAD_EQUIPMENT_EXCEL_COMPLETED),
      switchMap(() => {
        return of(new AppActions.HideLoader());
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  uploadExcel = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.UPLOAD_EQUIPMENT_EXCEL),
      switchMap((objectData: ObjectActions.UploadEquipmentExcel) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.uploadExcel(objectData.equipments).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            this.errorMessageService.getTranslatedErrorMessage(response.data);
            const mergedArray = this.excelHelperService.mergeBulkResponseWithRequestData<IEquipment>(
              response,
              objectData.equipments.equipments,
            );
            return of(new ObjectActions.UploadEquipmentExcelCompleted(mergedArray), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getAvatar = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.GET_AVATAR_LOADING, ObjectActions.GET_AVATAR_FOR_LOGS_LOADING),
      mergeMap((objectData: ObjectActions.GetAvatarLoading | ObjectActions.GetAvatarForLogsLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.getAvatar(objectData.avatarPath).pipe(
          mergeMap((response: BaseOneResponseInterface<string>) => {
            return of(
              new ObjectActions.GetAvatarLoaded(response, objectData.avatarPath),
              new ObjectActions.GetAvatarForLogsLoaded(response, objectData.avatarPath),
              new AppActions.HideLoader(),
            );
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  uploadAvatar = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.UPLOAD_AVATAR_LOADING),
      switchMap((objectData: ObjectActions.UploadAvatarLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.uploadAvatar(objectData.id, objectData.base64ImageContent).pipe(
          switchMap(() => {
            return of(new ObjectActions.UploadAvatarLoaded());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  deleteAvatar = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DELETE_AVATAR_LOADING),
      switchMap((objectData: ObjectActions.DeleteAvatarLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.deleteAvatar(objectData.id).pipe(
          switchMap(() => {
            return of(new ObjectActions.DeleteAvatarLoaded());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );
}
