import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FilterComponentInterface, SimpleOutputOptionsInterface } from '../filter.class';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss'],
})
export class SearchBoxComponent implements OnInit, OnDestroy, FilterComponentInterface {
  outputOptions: SimpleOutputOptionsInterface;
  searchBoxNgModel: string;
  elementID: string;
  outputSubject: Subject<any>;

  @Input() maxLength: number = 100;
  @Input() placeHolder: string = this.translate.instant('filterCard.searchBox.placeHolder');
  @Input() cls: string = 'form-control';
  public disabledSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private delayTimer: ReturnType<typeof setTimeout>;
  private disabledSubjectSubscription: Subscription;
  public isDisabled: boolean = false;

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    this.disabledSubjectSubscription = this.disabledSubject.asObservable().subscribe((disabledStatus:boolean) => {
      this.isDisabled = disabledStatus;
    });
  }
  public getFiltersOutputs(): void | {} {
    clearTimeout(this.delayTimer);
    this.delayTimer = setTimeout(() => {
      const output = {};
      output[this.outputOptions.filterObjectId] = this.searchBoxNgModel;
      this.outputSubject.next(output);
      return output;
    }, 600);
  }
  ngOnDestroy(): void {
    this.disabledSubjectSubscription.unsubscribe();
    this.disabledSubject.complete();
  }
}
