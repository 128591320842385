import { Action } from '@ngrx/store';
import { LineTypeNameInterface } from '../../../shared/component/filter/filter.class';
import { HttpOptionInterface } from '../filter.model';
import { HttpErrorResponse } from '@angular/common/http';

export enum FilterDepartmentActionTypes {
  StartLoadFilterDepartments = '[Filter Department] Load Filter Departments',
  FilterDepartmentsLoaded = '[Filter Department] Filter Departments Loaded',
  ResetDepartmentStore = '[Filter Department] Reset data stored in department store',
  FetchError = '[Filter Department] Fetch Error',
}

export class StartLoadFilterDepartments implements Action {
  readonly type = FilterDepartmentActionTypes.StartLoadFilterDepartments;

  constructor(public options: HttpOptionInterface) {}
}

export class FilterDepartmentsLoaded implements Action {
  readonly type = FilterDepartmentActionTypes.FilterDepartmentsLoaded;

  constructor(public data: LineTypeNameInterface[]) {}
}

export class ResetDepartmentStore implements Action {
  readonly type = FilterDepartmentActionTypes.ResetDepartmentStore;

  constructor() {}
}

export class FetchError implements Action {
  readonly type = FilterDepartmentActionTypes.FetchError;

  constructor(public payload: HttpErrorResponse) {}
}

export type FilterDepartmentActions =
  | StartLoadFilterDepartments
  | FilterDepartmentsLoaded
  | ResetDepartmentStore
  | FetchError;
