import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as ObjectActions from './notification-setup.actions';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import * as AppActions from '../../app/actions';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { NotificationSetupCRUDInterface, NotificationSetupFormDropdownDataInterface } from './notification-setup.model';
import { NotificationSetupService } from '../../../shared/service/notification-setup/notification-setup.service';

@Injectable()
export class NotificationSetupEffects {
  constructor(
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly actions$: Actions<ObjectActions.NotificationSetupActions>,
    private readonly service: NotificationSetupService,
  ) {}

  $loadNotificationSetups = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.NotificationSetupActionTypes.LoadNotificationSetups),
      switchMap((objectData: ObjectActions.LoadNotificationSetups) => {
        return this.service.loadNotificationSetups(objectData.parameters).pipe(
          switchMap((response: GetManyResponseInterface<NotificationSetupCRUDInterface>) => {
            return of(new ObjectActions.NotificationSetupsLoaded(response));
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error));
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error));
      }),
    ),
  );

  $loadFormDropdownData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.NotificationSetupActionTypes.LoadFormDropdownData),
      switchMap(() => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.loadFormData().pipe(
          switchMap((response: GetManyResponseInterface<NotificationSetupFormDropdownDataInterface>) => {
            return of(new ObjectActions.FormDropdownDataLoaded(response), new AppActions.HideLoader());
          }),
          catchError(() => {
            return of(new AppActions.HideLoader());
          }),
        );
      }),
      catchError(() => {
        return of(new AppActions.HideLoader());
      }),
    ),
  );

  $createNotificationSetup = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.NotificationSetupActionTypes.CreateNotificationSetup),
      switchMap((objectData: ObjectActions.CreateNotificationSetup) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.createNotificationSetup(objectData.requestParameters).pipe(
          switchMap((response: BaseOneResponseInterface<NotificationSetupCRUDInterface>) => {
            return of(new ObjectActions.CreateNotificationSetupCompleted(response));
          }),
          catchError(() => {
            return of(new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  $updateNotificationSetup = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.NotificationSetupActionTypes.UpdateNotificationSetup),
      switchMap((objectData: ObjectActions.UpdateNotificationSetup) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.updateNotificationSetup(objectData.id, objectData.requestParameters).pipe(
          switchMap((response: BaseOneResponseInterface<NotificationSetupCRUDInterface>) => {
            return of(new ObjectActions.UpdateNotificationSetupCompleted(response));
          }),
          catchError(() => {
            return of(new AppActions.HideLoader());
          }),
        );
      }),
      catchError(() => {
        return of(new AppActions.HideLoader());
      }),
    ),
  );

  $deleteNotificationSetup = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.NotificationSetupActionTypes.DeleteNotificationSetup),
      switchMap((objectData: ObjectActions.DeleteNotificationSetup) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.deleteNotificationSetup(objectData.id).pipe(
          switchMap(() => {
            return of(new ObjectActions.DeleteNotificationSetupCompleted());
          }),
          catchError(() => {
            return of(new AppActions.HideLoader());
          }),
        );
      }),
      catchError(() => {
        return of(new AppActions.HideLoader());
      }),
    ),
  );

  $bulkDeleteNotificationSetup = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.NotificationSetupActionTypes.BulkDeleteNotificationSetup),
      switchMap((objectData: ObjectActions.BulkDeleteNotificationSetup) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.bulkDeleteNotificationSetup(objectData.ids).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            return of(new ObjectActions.BulkDeleteNotificationSetupCompleted(response));
          }),
          catchError(() => {
            return of(new AppActions.HideLoader());
          }),
        );
      }),
      catchError(() => {
        return of(new AppActions.HideLoader());
      }),
    ),
  );
}
