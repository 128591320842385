import { Action } from '@ngrx/store';
import { PageHeaderStateInterface } from './page-header.model';

export const UPDATE_PAGE_MODE = '[PAGE HEADER] Update page mode';
export const COUNTDOWN_TIMEOUT = '[PAGE HEADER] Countdown timeout';
export const UPDATE_FILTER_BAR_VISIBILITY = '[PAGE HEADER] Update filter bar visibility';
export const RUN_PRINT_FUNCTIONALITY = '[PAGE HEADER] Run print functionality';
export const CHANGE_PRINT_BUTTON_STATUS = '[PAGE HEADER] Change print button status';
export const UPDATE_PAGE_HEADER_BUTTONS_VISIBILITY = '[PAGE HEADER] Update page header buttons visibility';
export const FETCH_PAGE_DATA = '[WORK_ORDER_SCHEDULE] FETCH PAGE DATA';
export const SET_PAGE_TO_EDIT_OR_VIEW_MODE = '[PAGE HEADER] Set Page To Edit Or View Mode';
export const RESET_STORE_TO_DEFAULT = '[PAGE HEADER] Reset Store To Default';
export const CHANGE_ROOT_CAUSE_ANALYSIS_MODE = '[PAGE HEADER] Change root cause analysis mode';

export class UpdatePageMode implements Action {
  readonly type = UPDATE_PAGE_MODE;

  constructor(public isDashboardMode: boolean) {}
}

export class CountdownTimeout implements Action {
  readonly type = COUNTDOWN_TIMEOUT;
}

export class UpdateFilterBarVisibility implements Action {
  readonly type = UPDATE_FILTER_BAR_VISIBILITY;

  constructor(public isVisible: boolean) {}
}

export class RunPrintFunctionality implements Action {
  readonly type = RUN_PRINT_FUNCTIONALITY;

  constructor(public isPrintAllowed: boolean) {}
}

export class ChangePrintButtonStatus implements Action {
  readonly type = CHANGE_PRINT_BUTTON_STATUS;

  constructor(public isPrintButtonDisabled: boolean) {}
}

export class UpdatePageHeaderButtonsVisibility implements Action {
  readonly type = UPDATE_PAGE_HEADER_BUTTONS_VISIBILITY;

  constructor(public isPageHeaderButtonsVisible: Partial<PageHeaderStateInterface>) {}
}

export class FetchPageData implements Action {
  readonly type = FETCH_PAGE_DATA;
}

export class SetPageToEditOrViewMode implements Action {
  readonly type = SET_PAGE_TO_EDIT_OR_VIEW_MODE;

  constructor(public isEditMode: boolean, public isSaveAction: boolean) {}
}

export class ResetStoreToDefault implements Action {
  readonly type = RESET_STORE_TO_DEFAULT;
}

export class ChangeRootCauseAnalysisMode implements Action {
  readonly type = CHANGE_ROOT_CAUSE_ANALYSIS_MODE;

  constructor(public isRootCauseAnalysisComparisonMode: boolean) {}
}

export type PageHeaderActions =
  | UpdatePageMode
  | CountdownTimeout
  | UpdateFilterBarVisibility
  | RunPrintFunctionality
  | ChangePrintButtonStatus
  | ResetStoreToDefault
  | FetchPageData
  | UpdatePageHeaderButtonsVisibility
  | ChangeRootCauseAnalysisMode
  | SetPageToEditOrViewMode;
