import { Action } from '@ngrx/store';
import { LineCRUDInterface, SensorTypeCRUDInterface } from '../../../shared/component/filter/filter.class';
import { IRequestParams } from '../../../shared/model/interface/common-page.model';
import {
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { DownloadExcelFiltersInterface } from '../../../shared/service/excel/excel-helper.service';
import { IProductMultipliersFilterCardParams } from '../../../view/settings/product-multipliers/product-multipliers.model';
import { ProductInterface } from '../products/products.model';
import {
  IProductMultipliers,
  IProductMultipliersBulkSaveMany,
  ProductMultiplierCreateDataInterface,
  ProductMultiplierCRUDResponseInterface,
  ProductMultiplierUpdateInterface,
} from './product-multiplier.model';

export const CREATE_PRODUCT_MULTIPLIER = '[PRODUCT MULTIPLIER] Create product multiplier table data';
export const PRODUCT_MULTIPLIER_CREATED = '[PRODUCT MULTIPLIER] Create product multiplier table data complete';
export const LOAD_PRODUCT_MULTIPLIERS = '[PRODUCT MULTIPLIER] Load product multipliers data';
export const PRODUCT_MULTIPLIERS_LOADED = '[PRODUCT MULTIPLIER] Product multipliers data loaded';
export const UPDATE_PRODUCT_MULTIPLIER = '[PRODUCT MULTIPLIER] Update product multiplier data';
export const PRODUCT_MULTIPLIER_UPDATED = '[PRODUCT MULTIPLIER] Update product multiplier data completed';
export const UPDATE_PRODUCT_MULTIPLIERS = '[PRODUCT MULTIPLIER] Update product multipliers data';
export const PRODUCT_MULTIPLIERS_UPDATED = '[PRODUCT MULTIPLIER] Update product multipliers data completed';
export const DELETE_PRODUCT_MULTIPLIER = '[PRODUCT MULTIPLIER] Delete product multiplier data';
export const PRODUCT_MULTIPLIER_DELETED = '[PRODUCT MULTIPLIER] Delete product multiplier data completed';
export const DELETE_PRODUCT_MULTIPLIERS = '[PRODUCT MULTIPLIER] Delete product multipliers data';
export const PRODUCT_MULTIPLIERS_DELETED = '[PRODUCT MULTIPLIER] Delete product multipliers data completed';
export const GET_PRODUCT_MULTIPLIER_COUNT = '[PRODUCT MULTIPLIER] Get product multiplier count';
export const GET_PRODUCT_MULTIPLIER_COUNT_COMPLETED = '[PRODUCT MULTIPLIER] Get product multiplier count is completed';
export const DOWNLOAD_PRODUCT_MULTIPLIER_EXCEL = '[PRODUCT MULTIPLIER] Download product multipliers excel';
export const PRODUCT_MULTIPLIER_EXCEL_DOWNLOADED =
  '[PRODUCT MULTIPLIER] Download product multipliers excel action completed';
export const UPLOAD_PRODUCT_MULTIPLIER_EXCEL = '[PRODUCT MULTIPLIER] Upload product multipliers excel';
export const PRODUCT_MULTIPLIER_EXCEL_UPLOADED =
  '[PRODUCT MULTIPLIER] Upload product multipliers excel action completed';
export const GET_LINES = '[PRODUCT MULTIPLIER] Get Lines';
export const GET_LINES_COMPLETED = '[PRODUCT MULTIPLIER] Get Lines Completed';
export const GET_PRODUCTS = '[PRODUCT MULTIPLIER] Get Products';
export const GET_PRODUCTS_COMPLETED = '[PRODUCT MULTIPLIER] Get Products Completed';
export const GET_SENSOR_TYPES = '[PRODUCT MULTIPLIER] Get Sensor Types';
export const GET_SENSOR_TYPES_COMPLETED = '[PRODUCT MULTIPLIER] Get Sensor Types Completed';
export const FETCH_ERROR = '[PRODUCT MULTIPLIER] Fetch Error';
export const CLEAR_STATE = '[PRODUCT MULTIPLIER] Clear state';

export class CreateProductMultiplier implements Action {
  readonly type = CREATE_PRODUCT_MULTIPLIER;

  constructor(public data: ProductMultiplierCreateDataInterface) {}
}

export class ProductMultiplierCreated implements Action {
  readonly type = PRODUCT_MULTIPLIER_CREATED;

  constructor(public response: ProductMultiplierCRUDResponseInterface) {}
}

export class LoadProductMultipliers implements Action {
  readonly type = LOAD_PRODUCT_MULTIPLIERS;

  constructor(public requestParams: IRequestParams<IProductMultipliersFilterCardParams>) {}
}

export class ProductMultipliersLoaded implements Action {
  readonly type = PRODUCT_MULTIPLIERS_LOADED;

  constructor(public response: GetManyResponseInterface<IProductMultipliers>) {}
}

export class UpdateProductMultiplier implements Action {
  readonly type = UPDATE_PRODUCT_MULTIPLIER;

  constructor(public data: ProductMultiplierUpdateInterface) {}
}

export class ProductMultiplierUpdated implements Action {
  readonly type = PRODUCT_MULTIPLIER_UPDATED;

  constructor(public response: ProductMultiplierCRUDResponseInterface) {}
}

export class UpdateProductMultipliers implements Action {
  readonly type = UPDATE_PRODUCT_MULTIPLIERS;

  constructor(public data: Partial<IProductMultipliers>[]) {}
}

export class ProductMultipliersUpdated implements Action {
  readonly type = PRODUCT_MULTIPLIERS_UPDATED;

  constructor(public response: BulkResponseDataInterface) {}
}

export class DeleteProductMultiplier implements Action {
  readonly type = DELETE_PRODUCT_MULTIPLIER;

  constructor(public id: number) {}
}

export class ProductMultiplierDeleted implements Action {
  readonly type = PRODUCT_MULTIPLIER_DELETED;

  constructor(public response: ProductMultiplierCRUDResponseInterface) {}
}

export class DeleteProductMultipliers implements Action {
  readonly type = DELETE_PRODUCT_MULTIPLIERS;

  constructor(public ids: number[]) {}
}

export class ProductMultipliersDeleted implements Action {
  readonly type = PRODUCT_MULTIPLIERS_DELETED;

  constructor(public response: BulkResponseDataInterface) {}
}

export class GetProductMultiplierCount implements Action {
  readonly type = GET_PRODUCT_MULTIPLIER_COUNT;

  constructor(public siteId: number) {}
}

export class GetProductMultiplierCountCompleted implements Action {
  readonly type = GET_PRODUCT_MULTIPLIER_COUNT_COMPLETED;

  constructor(public data: GetManyResponseInterface<IProductMultipliers>) {}
}

export class DownloadProductMultiplierExcel implements Action {
  readonly type = DOWNLOAD_PRODUCT_MULTIPLIER_EXCEL;
  constructor(public withData: boolean = false, public filters: DownloadExcelFiltersInterface) {}
}

export class ProductMultiplierExcelDownloaded implements Action {
  readonly type = PRODUCT_MULTIPLIER_EXCEL_DOWNLOADED;
}

export class UploadProductMultiplierExcel implements Action {
  readonly type = UPLOAD_PRODUCT_MULTIPLIER_EXCEL;

  constructor(public productMultipliers: IProductMultipliersBulkSaveMany) {}
}

export class ProductMultiplierExcelUploaded implements Action {
  readonly type = PRODUCT_MULTIPLIER_EXCEL_UPLOADED;

  constructor(public payload: (IProductMultipliers & { errorMessages?: string })[]) {}
}

export class GetLines implements Action {
  readonly type = GET_LINES;
}

export class GetLinesCompleted implements Action {
  readonly type = GET_LINES_COMPLETED;

  constructor(public lines: LineCRUDInterface[]) {}
}

export class GetProducts implements Action {
  readonly type = GET_PRODUCTS;

  constructor(public search?: string) {}
}

export class GetProductsCompleted implements Action {
  readonly type = GET_PRODUCTS_COMPLETED;

  constructor(public products: ProductInterface[]) {}
}

export class GetSensorTypes implements Action {
  readonly type = GET_SENSOR_TYPES;
}

export class GetSensorTypesCompleted implements Action {
  readonly type = GET_SENSOR_TYPES_COMPLETED;

  constructor(public sensorTypes: SensorTypeCRUDInterface[]) {}
}

export class ClearState implements Action {
  readonly type = CLEAR_STATE;
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;

  constructor(public payload: any[]) {}
}

export type ProductMultiplierActions =
  | CreateProductMultiplier
  | ProductMultiplierCreated
  | LoadProductMultipliers
  | ProductMultipliersLoaded
  | UpdateProductMultiplier
  | ProductMultiplierUpdated
  | UpdateProductMultipliers
  | ProductMultipliersUpdated
  | DeleteProductMultiplier
  | ProductMultiplierDeleted
  | DeleteProductMultipliers
  | ProductMultipliersDeleted
  | GetProductMultiplierCount
  | GetProductMultiplierCountCompleted
  | DownloadProductMultiplierExcel
  | ProductMultiplierExcelDownloaded
  | UploadProductMultiplierExcel
  | ProductMultiplierExcelUploaded
  | GetLines
  | GetLinesCompleted
  | GetProducts
  | GetProductsCompleted
  | GetSensorTypes
  | GetSensorTypesCompleted
  | ClearState
  | FetchError;
