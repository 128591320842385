import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ObjectActions from './equipment-lists.actions';
import { catchError, switchMap, take } from 'rxjs/operators';
import { SiteService } from '../../../shared/service/filter/site.service';
import { of } from 'rxjs';
import * as AppActions from '../../app/actions';
import { HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import { ErrorMessageService } from '../../../shared/service/error-message.service';
import { ExcelHelperService } from '../../../shared/service/excel/excel-helper.service';
import { EquipmentListService } from '../../../shared/service/equipment-list/equipment-list.service';
import { BulkResponseDataInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { EquipmentListInterface } from './equipment-lists.model';
import { emptyAction } from '../../../../constants';

@Injectable()
export class EquipmentListsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: EquipmentListService,
    private readonly siteService: SiteService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly errorMessageService: ErrorMessageService,
    private readonly excelHelperService: ExcelHelperService,
  ) {}

  getSiteData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.EQUIPMENT_LISTS_SITES_DATA_LOADING),
      switchMap((payload: ObjectActions.EquipmentListsSiteDataLoading) => {
        const httpParams: HttpParams = new HttpParams().set('limit', '1000').append('fields', 'name');

        return this.siteService.getSites(httpParams).pipe(
          switchMap((response) => {
            return of(new ObjectActions.EquipmentListsSiteDataLoaded(response.data));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getEquipmentListData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.EQUIPMENT_LISTS_EQUIPMENT_LIST_DATA_LOADING),
      switchMap((payload: ObjectActions.EquipmentListsEquipmentListDataLoading) => {
        const httpParams: HttpParams = new HttpParams()
          .set('s', JSON.stringify({ siteId: { $eq: payload.siteId } }))
          .append('limit', String(payload.limit || 1000));
        return this.service.getEquipmentLists(httpParams).pipe(
          switchMap((response) => {
            return of(new ObjectActions.EquipmentListsEquipmentListDataLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  downloadEquipmentListExcel = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DOWNLOAD_EQUIPMENT_LIST_EXCEL),
      switchMap((objectData: ObjectActions.DownloadEquipmentListExcel) => {
        this.service.downloadEquipmentListExcel(objectData.withData, objectData.filters);
        return emptyAction;
      }),
    ),
  );

  uploadExcel = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.UPLOAD_EQUIPMENT_LIST_EXCEL),
      switchMap((objectData: ObjectActions.UploadEquipmentListExcel) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.uploadExcel(objectData.equipments).pipe(
          switchMap((response: BulkResponseDataInterface) => {
            this.errorMessageService.getTranslatedErrorMessage(response.data);
            const mergedArray = this.excelHelperService.mergeBulkResponseWithRequestData<EquipmentListInterface>(
              response,
              objectData.equipments.equipments,
              true,
            );
            return of(new ObjectActions.UploadEquipmentListExcelCompleted(mergedArray), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );
}
