import { Action } from '@ngrx/store';
import {
  IMyReportWithMetrics,
  IMyReportMetricCreateRequestParameters,
  IMyReportMetricUpdateRequestParameters,
  IRequiredChartData,
  IMyReportMetricFilterData,
  EMyReportMetricDataSource,
} from '../../../view/reports/my-reports/my-report-metrics/my-report-metrics.model';
import { BulkResponseDataInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { IOeeCalculationRequest, IOeeCalculationResult } from '../work-order-performance/work-order-performance.model';

export enum EMyReportMetricsActionTypes {
  BulkCreateMyReportMetrics = '[MyReportMetrics] Bulk Create New MyReportMetrics',
  BulkCreateMyReportMetricsCompleted = '[MyReportMetrics] Bulk Create MyReportMetrics Completed',
  BulkUpdateMyReportMetrics = '[MyReportMetrics] Bulk Update New MyReportMetrics',
  BulkUpdateMyReportMetricsCompleted = '[MyReportMetrics] Bulk Update MyReportMetrics Completed',
  BulkDeleteMyReportMetrics = '[MyReportMetrics] Bulk Delete New MyReportMetrics',
  BulkDeleteMyReportMetricsCompleted = '[MyReportMetrics] Bulk Delete MyReportMetrics Completed',
  FetchMyReportMetricsData = '[MyReportMetrics] Fetch MyReportMetrics Data',
  FetchMyReportMetricsDataCompleted = '[MyReportMetrics] Fetch MyReportMetrics Data Completed',
  GetOeeCalculationDataHourly = '[MyReportMetrics] Fetch Oee Calculation Data Hourly',
  GetOeeCalculationDataHourlyCompleted = '[MyReportMetrics] Fetch Oee Calculation Data Hourly Completed',
  GetOeeCalculationDataDaily = '[MyReportMetrics] Fetch Oee Calculation Data Daily',
  GetOeeCalculationDataDailyCompleted = '[MyReportMetrics] Fetch Oee Calculation Data Daily Completed',
  GetOeeCalculationDataWeekly = '[MyReportMetrics] Fetch Oee Calculation Data Weekly',
  GetOeeCalculationDataWeeklyCompleted = '[MyReportMetrics] Fetch Oee Calculation Data Weekly Completed',
  GetOeeCalculationDataMonthly = '[MyReportMetrics] Fetch Oee Calculation Data Monthly',
  GetOeeCalculationDataMonthlyCompleted = '[MyReportMetrics] Fetch Oee Calculation Data Monthly Completed',
  SetSelectedReportName = '[MyReportMetrics] Set Selected Report Name',
  SetRequiredChartData = '[MyReportMetrics] Set Selected Filters',
  SetFilterData = '[MyReportMetrics] Set Filter Data',
  FetchError = '[MyReportMetrics] Fetch Error',
}

export class BulkCreateMyReportMetrics implements Action {
  readonly type = EMyReportMetricsActionTypes.BulkCreateMyReportMetrics;

  constructor(public parameters: IMyReportMetricCreateRequestParameters[]) {}
}

export class BulkCreateMyReportMetricsCompleted implements Action {
  readonly type = EMyReportMetricsActionTypes.BulkCreateMyReportMetricsCompleted;

  constructor(public payload: BulkResponseDataInterface) {}
}

export class BulkUpdateMyReportMetrics implements Action {
  readonly type = EMyReportMetricsActionTypes.BulkUpdateMyReportMetrics;

  constructor(public parameters: IMyReportMetricUpdateRequestParameters[]) {}
}

export class BulkUpdateMyReportMetricsCompleted implements Action {
  readonly type = EMyReportMetricsActionTypes.BulkUpdateMyReportMetricsCompleted;

  constructor(public payload: BulkResponseDataInterface) {}
}

export class BulkDeleteMyReportMetrics implements Action {
  readonly type = EMyReportMetricsActionTypes.BulkDeleteMyReportMetrics;

  constructor(public ids: number[]) {}
}

export class BulkDeleteMyReportMetricsCompleted implements Action {
  readonly type = EMyReportMetricsActionTypes.BulkDeleteMyReportMetricsCompleted;

  constructor(public payload: BulkResponseDataInterface) {}
}

export class FetchMyReportMetricsData implements Action {
  readonly type = EMyReportMetricsActionTypes.FetchMyReportMetricsData;

  constructor(public id: number) {}
}

export class FetchMyReportMetricsDataCompleted implements Action {
  readonly type = EMyReportMetricsActionTypes.FetchMyReportMetricsDataCompleted;

  constructor(public payload: IMyReportWithMetrics) {}
}

export class SetSelectedReportName implements Action {
  readonly type = EMyReportMetricsActionTypes.SetSelectedReportName;

  constructor(public payload: string) {}
}

export class SetRequiredChartData implements Action {
  readonly type = EMyReportMetricsActionTypes.SetRequiredChartData;

  constructor(public dataSource: EMyReportMetricDataSource, public requiredChartData: IRequiredChartData) {}
}

export class GetOeeCalculationDataHourly implements Action {
  readonly type = EMyReportMetricsActionTypes.GetOeeCalculationDataHourly;

  constructor(public filters: IOeeCalculationRequest) {}
}

export class GetOeeCalculationDataHourlyCompleted implements Action {
  readonly type = EMyReportMetricsActionTypes.GetOeeCalculationDataHourlyCompleted;

  constructor(public payload: IOeeCalculationResult[]) {}
}

export class GetOeeCalculationDataDaily implements Action {
  readonly type = EMyReportMetricsActionTypes.GetOeeCalculationDataDaily;

  constructor(public filters: IOeeCalculationRequest) {}
}

export class GetOeeCalculationDataDailyCompleted implements Action {
  readonly type = EMyReportMetricsActionTypes.GetOeeCalculationDataDailyCompleted;

  constructor(public payload: IOeeCalculationResult[]) {}
}

export class GetOeeCalculationDataWeekly implements Action {
  readonly type = EMyReportMetricsActionTypes.GetOeeCalculationDataWeekly;

  constructor(public filters: IOeeCalculationRequest) {}
}

export class GetOeeCalculationDataWeeklyCompleted implements Action {
  readonly type = EMyReportMetricsActionTypes.GetOeeCalculationDataWeeklyCompleted;

  constructor(public payload: IOeeCalculationResult[]) {}
}

export class GetOeeCalculationDataMonthly implements Action {
  readonly type = EMyReportMetricsActionTypes.GetOeeCalculationDataMonthly;

  constructor(public filters: IOeeCalculationRequest) {}
}

export class GetOeeCalculationDataMonthlyCompleted implements Action {
  readonly type = EMyReportMetricsActionTypes.GetOeeCalculationDataMonthlyCompleted;

  constructor(public payload: IOeeCalculationResult[]) {}
}

export class SetFilterData implements Action {
  readonly type = EMyReportMetricsActionTypes.SetFilterData;

  constructor(public filterData: IMyReportMetricFilterData) {}
}

export class FetchError implements Action {
  readonly type = EMyReportMetricsActionTypes.FetchError;

  constructor(public payload: object) {}
}

export type TMyReportMetricsActions =
  | BulkCreateMyReportMetrics
  | BulkCreateMyReportMetricsCompleted
  | BulkDeleteMyReportMetrics
  | BulkDeleteMyReportMetricsCompleted
  | FetchMyReportMetricsData
  | FetchMyReportMetricsDataCompleted
  | SetSelectedReportName
  | SetRequiredChartData
  | SetFilterData
  | GetOeeCalculationDataHourly
  | GetOeeCalculationDataHourlyCompleted
  | GetOeeCalculationDataDaily
  | GetOeeCalculationDataDailyCompleted
  | GetOeeCalculationDataWeekly
  | GetOeeCalculationDataWeeklyCompleted
  | GetOeeCalculationDataMonthly
  | GetOeeCalculationDataMonthlyCompleted
  | FetchError;
