import { Action } from '@ngrx/store';
import { LineCRUDInterface } from '../../../shared/component/filter/filter.class';
import { HttpOptionInterface } from '../filter.model';

export enum FilterLineActionTypes {
  StartLoadFilterLines = '[Filter Line] Load Filter Lines',
  FilterLinesLoaded = '[Filter Line] Filter Lines Loaded',
  ResetLineStore = '[Filter Line] Reset data stored in line store',
}

export class StartLoadFilterLines implements Action {
  readonly type = FilterLineActionTypes.StartLoadFilterLines;

  constructor(public options: HttpOptionInterface) {}
}

export class FilterLinesLoaded implements Action {
  readonly type = FilterLineActionTypes.FilterLinesLoaded;

  constructor(public data: LineCRUDInterface[]) {}
}

export class ResetLineStore implements Action {
  readonly type = FilterLineActionTypes.ResetLineStore;

  constructor() {}
}

export type FilterLineActions =
  StartLoadFilterLines |
  FilterLinesLoaded |
  ResetLineStore;
