import { IOeeCalendarState } from './oee-calendar.model';
import * as Actions from './oee-calendar.actions';

export const oeeCalendarInitialState: IOeeCalendarState = {
  isOeeCalendarDataLoading: false,
  isOeeCalendarDataLoaded: false,
  isOeeChartColorDataLoaded: false,
  isOeeChartColorDataLoading: false,
  oeeCalendarData: [],
  oeeCalendarChartColorData: [],
};

export function oeeCalendarReducer(
  state: IOeeCalendarState = oeeCalendarInitialState,
  action: Actions.OeeCalendarActions,
): IOeeCalendarState {
  switch (action.type) {
    case Actions.OEE_CALENDAR_DATA_LOADING:
      return {
        ...state,
        isOeeCalendarDataLoading: true,
        isOeeCalendarDataLoaded: false,
      };
    case Actions.OEE_CALENDAR_DATA_LOADED:
      return {
        ...state,
        isOeeCalendarDataLoading: false,
        isOeeCalendarDataLoaded: true,
        oeeCalendarData: action.payload,
      };
    case Actions.OEE_CHART_COLOR_DATA_LOADING:
      return {
        ...state,
        isOeeChartColorDataLoading: true,
        isOeeChartColorDataLoaded: false,
      };
    case Actions.OEE_CHART_COLOR_DATA_LOADED:
      return {
        ...state,
        isOeeChartColorDataLoading: false,
        isOeeChartColorDataLoaded: true,
        oeeCalendarChartColorData: action.payload,
      };
    case Actions.RESET_OEE_CALENDAR_DATA:
      return {
        ...state,
        isOeeCalendarDataLoading: false,
        isOeeCalendarDataLoaded: true,
        oeeCalendarData: oeeCalendarInitialState.oeeCalendarData,
      };
    case Actions.FETCH_DATA_ERROR:
      return {
        ...state,
        isOeeCalendarDataLoading: false,
        isOeeCalendarDataLoaded: true,
      };
    default:
      return state;
  }
}
