import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IAnonymousCard } from '../../../store/settings/users/users.model';
import { GetManyResponseInterface } from '../../model/interface/crud-response-interface.model';

@Injectable({
  providedIn: 'root',
})
export class AnonymousCardsService {
  private readonly ROUTES = {
    GET: {
      GET_ANONYMOUS_CARDS: `${this.apiBaseUrl}/anonymous-cards`,
    },
  };

  constructor(
    @Inject('API_BASE_URL')
    private readonly apiBaseUrl: string,
    public http: HttpClient,
  ) {}

  public getAnonymousCards(params?: HttpParams): Observable<GetManyResponseInterface<IAnonymousCard>> {
    return this.http.get<GetManyResponseInterface<IAnonymousCard>>(this.ROUTES.GET.GET_ANONYMOUS_CARDS, {
      params,
    });
  }
}
