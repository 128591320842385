import { FilterRootCauseTypeAction, FilterRootCauseTypeActionType } from './root-cause-type.actions';
import { RootCauseTypeCRUDInterface } from '../../../shared/component/filter/filter.class';

export interface FilterRootCauseTypeState {
  isLoading: boolean;
  isLoaded: boolean;
  data: RootCauseTypeCRUDInterface[];
}

export const initialFilterRootCauseTypeState: FilterRootCauseTypeState = {
  isLoaded: false,
  isLoading: false,
  data: [],
};

export function filterRootCauseTypeReducer(
  state = initialFilterRootCauseTypeState,
  action: FilterRootCauseTypeAction,
): FilterRootCauseTypeState {
  switch (action.type) {
    case FilterRootCauseTypeActionType.StartLoadFilterRootCauseTypes:
      return { ...state, isLoading: true };

    case FilterRootCauseTypeActionType.FilterRootCauseTypesLoaded:
      return { ...state, data: action.data, isLoaded: true, isLoading: false };

    default:
      return state;
  }
}
