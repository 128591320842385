import { Action } from '@ngrx/store';
import {
  ICurrentAndPreviousShift,
  IDateShifts,
  IExpectedSpeedAndUnit,
  IProductionReviewDataResponse,
  IShift,
  NextShiftInterface,
} from './production-review.model';
import { TabRowInterface } from '../../shared/component/side-config-bar/side-config-bar.model';
import { ShiftDataRequestParameterInterface } from './production-review-list-view.model';

export const START_PRODUCTION_REVIEW_DATA_LOADING = '[PRODUCTION_REVIEW] START PRODUCTION REVIEW DATA LOADING';
export const PRODUCTION_REVIEW_DATA_LOADED = '[PRODUCTION_REVIEW] PRODUCTION REVIEW DATA LOADED';
export const GET_SHIFTS_BY_DATE = '[PRODUCTION_REVIEW] GET SHIFTS BY DATE';
export const SHIFTS_LOADED_BY_DATE = '[PRODUCTION_REVIEW] SHIFTS LOADED BY DATE';
export const CLEAR_TO_STATE = '[PRODUCTION_REVIEW] CLEAR TO STATE';
export const START_NEXT_SHIFT_LOADING = '[PRODUCTION_REVIEW] START NEXT SHIFT LOADING';
export const NEXT_SHIFT_LOADED = '[PRODUCTION_REVIEW] NEXT SHIFT LOADED';
export const IS_NEXT_SHIFT_STARTED = '[PRODUCTION_REVIEW] IS NEXT SHIFT STARTED';
export const SET_SELECTED_SHIFT = '[PRODUCTION_REVIEW] SET SELECTED SHIFT';
export const SET_SELECTED_SHIFT_BY_DATE = '[PRODUCTION_REVIEW] SET SELECTED SHIFT BY DATE';
export const SET_TABLE_SETTINGS = '[PRODUCTION_REVIEW] SET SELECTED FILTERS';
export const EXPECTED_SPEED_AND_UNIT_LOADING = '[PRODUCTION_REVIEW] EXPECTED SPEED AND UNIT LOADING';
export const EXPECTED_SPEED_AND_UNIT_LOADED = '[PRODUCTION_REVIEW] EXPECTED SPEED AND UNIT LOADED';
export const PRODUCTION_REVIEW_FETCH_DATA_LOADED = '[PRODUCTION_REVIEW] FETCH DATA LOADED';
export const PRODUCTION_REVIEW_FETCH_DATA = '[PRODUCTION_REVIEW] FETCH DATA';

export class StartProductionReviewDataLoading implements Action {
  readonly type = START_PRODUCTION_REVIEW_DATA_LOADING;

  constructor(public lineId: number) {}
}

export class ProductionReviewDataLoaded implements Action {
  readonly type = PRODUCTION_REVIEW_DATA_LOADED;

  constructor(public payload: ICurrentAndPreviousShift) {}
}

export class GetShiftsByDate implements Action {
  readonly type = GET_SHIFTS_BY_DATE;

  constructor(public payload: { selectedDate: string; lineId: number }) {}
}

export class ShiftsLoadedByDate implements Action {
  readonly type = SHIFTS_LOADED_BY_DATE;

  constructor(public payload: IDateShifts[]) {}
}

export class ClearToState implements Action {
  readonly type = CLEAR_TO_STATE;
}

export class StartNextShiftLoading implements Action {
  readonly type = START_NEXT_SHIFT_LOADING;
}

export class NextShiftLoaded implements Action {
  readonly type = NEXT_SHIFT_LOADED;

  constructor(public payload: NextShiftInterface) {}
}

export class IsNextShiftStarted implements Action {
  readonly type = IS_NEXT_SHIFT_STARTED;
}

export class SetSelectedShift implements Action {
  readonly type = SET_SELECTED_SHIFT;

  constructor(public payload: IShift) {}
}

export class SetSelectedShiftByDate implements Action {
  readonly type = SET_SELECTED_SHIFT_BY_DATE;
}

export class SetTableSettings implements Action {
  readonly type = SET_TABLE_SETTINGS;

  constructor(public payload: TabRowInterface[], public setAsDefault: boolean, public mode?: boolean) {}
}

export class GetExpectedSpeedAndUnitLoading implements Action {
  readonly type = EXPECTED_SPEED_AND_UNIT_LOADING;

  constructor(public lineId: number) {}
}

export class GetExpectedSpeedAndUnitLoaded implements Action {
  readonly type = EXPECTED_SPEED_AND_UNIT_LOADED;

  constructor(public payload: IExpectedSpeedAndUnit) {}
}

export class ProductionReviewFetchDataLoaded implements Action {
  readonly type = PRODUCTION_REVIEW_FETCH_DATA_LOADED;

  constructor(public payload: IProductionReviewDataResponse) {}
}

export class ProductionReviewFetchData implements Action {
  readonly type = PRODUCTION_REVIEW_FETCH_DATA;

  constructor(public payload: ShiftDataRequestParameterInterface) {}
}

export type ProductionReviewActions =
  | StartProductionReviewDataLoading
  | ProductionReviewDataLoaded
  | GetShiftsByDate
  | ShiftsLoadedByDate
  | ClearToState
  | StartNextShiftLoading
  | NextShiftLoaded
  | IsNextShiftStarted
  | SetSelectedShift
  | SetSelectedShiftByDate
  | SetTableSettings
  | GetExpectedSpeedAndUnitLoading
  | GetExpectedSpeedAndUnitLoaded
  | ProductionReviewFetchDataLoaded
  | ProductionReviewFetchData;
