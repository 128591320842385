import { LineAvailabilityActions, LineAvailabilityActionTypes } from './line-availability.actions';
import {
  ExceptionPlanDataInterface,
  LineAvailabilityPlanCostFactorInterface,
  LineAvailabilityPlanDataInterface,
  LineAvailabilityResponseInterface,
  LineAvailabilityShiftDataInterface,
  LineAvailabilityStateInterface,
} from './line-availability.model';
import * as _ from 'lodash';
import { ShiftGantDefaultValuesEnum } from '../../../constants';
import { DurationUnit } from '../../view/line-availability/line-availability.model';
import { HelperService } from '../../shared/service/helper.service';

export const initialState: LineAvailabilityStateInterface = {
  planItemSaveLoaded: false,
  planItemSaveLoading: false,
  exceptionPlanItemsIsLoaded: false,
  exceptionPlanItemsIsLoading: false,
  isPlanItemsPrepared: false,
  isExceptionPlanItemsPrepared: false,
  planItemsIsLoaded: false,
  planItemsIsLoading: false,
  shiftsLoading: false,
  shiftsLoaded: false,
  plansLoading: false,
  plansLoaded: false,
  costFactorLoading: false,
  costFactorLoaded: false,
  exceptionPlanIsLoading: false,
  exceptionPlanIsLoaded: false,
  shiftManagement: {
    data: [],
    count: null,
    date: null,
    page: null,
    pageCount: null,
    success: null,
    total: null,
  },
  shifts: {
    data: [],
    count: null,
    date: null,
    page: null,
    pageCount: null,
    success: null,
    total: null,
  },
  plans: {
    data: [],
    count: null,
    date: null,
    page: null,
    pageCount: null,
    success: null,
    total: null,
  },
  planItems: {
    data: [],
    count: null,
    date: null,
    page: null,
    pageCount: null,
    success: null,
    total: null,
  },
  costFactors: {
    data: [],
    count: null,
    date: null,
    page: null,
    pageCount: null,
    success: null,
    total: null,
  },
  exceptionPlans: {
    data: [],
    count: null,
    date: null,
    page: null,
    pageCount: null,
    success: null,
    total: null,
  },
  exceptionPlanItems: {
    data: [],
    count: null,
    date: null,
    page: null,
    pageCount: null,
    success: null,
    total: null,
  },
  factorsLoading: false,
  factorsLoaded: false,
  factors: [],
  selectedPlan: null,
  errors: [],
};

export function lineAvailabilityReducer(
  state = initialState,
  action: LineAvailabilityActions,
): LineAvailabilityStateInterface {
  let shifts: LineAvailabilityResponseInterface<LineAvailabilityShiftDataInterface>;
  let shiftManagement: LineAvailabilityResponseInterface<LineAvailabilityShiftDataInterface>;
  let plans: LineAvailabilityResponseInterface<LineAvailabilityPlanDataInterface>;
  let exceptionPlans: LineAvailabilityResponseInterface<ExceptionPlanDataInterface>;
  let costFactors: LineAvailabilityPlanCostFactorInterface;
  let index: number;

  switch (action.type) {
    case LineAvailabilityActionTypes.LoadLineAvailabilityShiftsData:
      return {
        ...state,
        shiftsLoading: true,
      };

    case LineAvailabilityActionTypes.LineAvailabilityShiftsDataLoaded:
      const shiftState = {
        ...state,
        shiftsLoading: false,
        shiftsLoaded: true,
      };

      if (action.isGanttShift) {
        const shifts = HelperService.cloneDeep(action.payload);
        shifts.data.forEach((shift: LineAvailabilityShiftDataInterface, index) => {
          const colors = ['#B3A7EE', '#E27B69', '#ACE195', '#50497F', '#88D0EE',
            '#FAB354', '#9D8F92', '#EEA4E6', '#BB693B', '#EADD2E'];
          const colorIndex = index % 10;
          const twoHoursInSeconds = 7200;
          shift.duration = twoHoursInSeconds;
          shift.durationUnit = DurationUnit.SECOND;
          shift.eventColor = colors[colorIndex];
        });
        shiftState.shifts = shifts;
      } else {
        shiftState.shiftManagement = action.payload;
      }

      return <LineAvailabilityStateInterface>shiftState;

    case LineAvailabilityActionTypes.CreateShiftLoaded:
      const duration: number = ShiftGantDefaultValuesEnum.DURATION;
      state?.shifts.data.splice(0, 0, {
        ...action.payload.data,
        ...{
          duration,
          durationUnit: DurationUnit.SECOND,
        },
      });
      return {
        ...state,
        shiftsLoading: false,
        shiftsLoaded: true,
      };

    case LineAvailabilityActionTypes.CreateShift:
    case LineAvailabilityActionTypes.EditShift:
    case LineAvailabilityActionTypes.DeleteShift:
      return {
        ...state,
        shiftsLoading: true,
      };

    case LineAvailabilityActionTypes.EditShiftCompleted:
      return {
        ...state,
        shiftsLoading: false,
        shiftsLoaded: true,
      };

    case LineAvailabilityActionTypes.DeleteShiftCompleted:
      shifts = _.cloneDeep(state.shifts);
      _.remove(shifts.data, (shift) => {
        return shift.id === action.id;
      });

      shiftManagement = _.cloneDeep(state.shiftManagement);
      _.remove(shiftManagement.data, (shift) => {
        return shift.id === action.id;
      });

      return {
        ...state,
        shifts,
        shiftManagement,
        shiftsLoading: false,
      };

    case LineAvailabilityActionTypes.LoadLineAvailabilityPlansData:
      return {
        ...state,
        plansLoading: true,
      };

    case LineAvailabilityActionTypes.LineAvailabilityPlansDataLoaded:
      return {
        ...state,
        plansLoading: false,
        plansLoaded: true,
        plans: action.payload,
      };

    case LineAvailabilityActionTypes.LoadLineAvailabilityExceptionPlan:
      return {
        ...state,
        exceptionPlanIsLoading: true,
      };

    case LineAvailabilityActionTypes.LineAvailabilityExceptionPlanLoaded:
      return {
        ...state,
        exceptionPlanIsLoading: false,
        exceptionPlanIsLoaded: true,
        exceptionPlans: action.payload,
      };

    case LineAvailabilityActionTypes.LoadLineAvailabilityCostFactorsData:
      return {
        ...state,
        costFactorLoading: true,
      };

    case LineAvailabilityActionTypes.LineAvailabilityCostFactorsDataLoaded:
      return {
        ...state,
        costFactorLoading: false,
        costFactorLoaded: true,
        costFactors: action.payload,
      };

    case LineAvailabilityActionTypes.CreatePlan:
      return {
        ...state,
        plansLoading: true,
      };

    case LineAvailabilityActionTypes.CreatePlanLoaded:
      const newPlan = action.payload;
      plans = state.plans;
      plans.data.push(newPlan);
      return {
        ...state,
        plans,
        plansLoading: false,
      };

    case LineAvailabilityActionTypes.CreateExceptionPlan:
      return {
        ...state,
        exceptionPlanIsLoading: true,
      };

    case LineAvailabilityActionTypes.CreateExceptionPlanLoaded:
      return {
        ...state,
        exceptionPlanIsLoading: false,
      };

    case LineAvailabilityActionTypes.EditPlan:
      return {
        ...state,
        plansLoading: true,
      };

    case LineAvailabilityActionTypes.EditPlanLoaded:
      return {
        ...state,
        plansLoading: false,
      };

    case LineAvailabilityActionTypes.EditExceptionPlan:
      return {
        ...state,
        exceptionPlanIsLoading: true,
      };

    case LineAvailabilityActionTypes.EditExceptionPlanLoaded:
      exceptionPlans = state.exceptionPlans;
      index = _.findIndex(exceptionPlans.data, { id: action.id });

      if (index !== -1) {
        _.set(exceptionPlans.data, `[${index}].name`, action.payload.name);
        _.set(exceptionPlans.data, `[${index}].startDate`, action.payload.startDate);
        _.set(exceptionPlans.data, `[${index}].endDate`, action.payload.endDate);
      }

      return {
        ...state,
        exceptionPlans,
        exceptionPlanIsLoading: false,
      };

    case LineAvailabilityActionTypes.DeletePlan:
      return {
        ...state,
        plansLoading: true,
      };

    case LineAvailabilityActionTypes.DeletePlanCompleted:
      plans = state.plans;
      const plansData = state.plans.data.filter((shift) => shift.id !== action.id);
      plans.data = plansData;
      return {
        ...state,
        plans,
        plansLoading: false,
      };

    case LineAvailabilityActionTypes.DeleteExceptionPlan:
      return {
        ...state,
        exceptionPlanIsLoading: true,
      };

    case LineAvailabilityActionTypes.DeleteExceptionPlanCompleted:
      return {
        ...state,
        exceptionPlanIsLoaded: false,
      };

    case LineAvailabilityActionTypes.SetSelectedPlan:
      let selectedPlan = null;
      const selectedPlanData = state.plans.data.find((shift) => shift.id === action.id);
      if (selectedPlanData !== undefined) {
        selectedPlan = selectedPlanData;
      }
      return {
        ...state,
        selectedPlan,
      };

    case LineAvailabilityActionTypes.CreateCostFactor:
    case LineAvailabilityActionTypes.EditCostFactor:
    case LineAvailabilityActionTypes.DeleteCostFactor:
      return {
        ...state,
        costFactorLoading: true,
      };

    case LineAvailabilityActionTypes.CreateCostFactorLoaded:
      return {
        ...state,
        costFactorLoading: false,
      };

    case LineAvailabilityActionTypes.EditCostFactorCompleted:
      const editedCostFactor = action.payload;
      costFactors = _.cloneDeep(state.costFactors);

      index = _.findIndex(costFactors.data, (element) => {
        return element.id === editedCostFactor.id;
      });

      if (index !== -1) {
        _.set(costFactors.data, `[${index}].name`, editedCostFactor.name);
        _.set(costFactors.data, `[${index}].factor`, parseFloat(editedCostFactor.factor.toString()).toFixed(5));
      }

      return {
        ...state,
        costFactors,
        costFactorLoading: false,
      };

    case LineAvailabilityActionTypes.DeleteCostFactorCompleted:
      costFactors = _.cloneDeep(state.costFactors);
      _.remove(costFactors.data, (costFactor) => {
        return costFactor.id === action.id;
      });

      return {
        ...state,
        costFactors,
        costFactorLoading: false,
      };

    case LineAvailabilityActionTypes.LoadLineAvailabilityDefaultPlanItems:
      return {
        ...state,
        planItemsIsLoading: true,
        planItemsIsLoaded: false,
        isPlanItemsPrepared: false,
      };

    case LineAvailabilityActionTypes.LineAvailabilityDefaultPlanItemsLoaded:
      return {
        ...state,
        planItemsIsLoading: false,
        planItemsIsLoaded: true,
        planItems: action.payload,
      };

    case LineAvailabilityActionTypes.DefaultPlanItemsPrepared:
      return {
        ...state,
        isPlanItemsPrepared: action.status,
      };

    case LineAvailabilityActionTypes.LoadLineAvailabilityExceptionPlanItems:
      return {
        ...state,
        exceptionPlanItems: initialState.exceptionPlanItems,
        exceptionPlanItemsIsLoaded: false,
        exceptionPlanItemsIsLoading: true,
        isExceptionPlanItemsPrepared: false,
      };

    case LineAvailabilityActionTypes.LineAvailabilityExceptionPlanItemsLoaded:
      return {
        ...state,
        exceptionPlanItemsIsLoaded: true,
        exceptionPlanItemsIsLoading: false,
        exceptionPlanItems: action.payload,
      };

    case LineAvailabilityActionTypes.ExceptionPlanItemsPrepared:
      return {
        ...state,
        isExceptionPlanItemsPrepared: action.status,
      };

    case LineAvailabilityActionTypes.SavePlanItems:
      return {
        ...state,
        planItemSaveLoaded: false,
        planItemSaveLoading: true,
      };

    case LineAvailabilityActionTypes.SavePlanItemsLoaded:
      return {
        ...state,
        planItemSaveLoaded: true,
        planItemSaveLoading: false,
      };

    case LineAvailabilityActionTypes.ClearState:
      return {
        ...initialState,
      };

    case LineAvailabilityActionTypes.ClearSelectedPlan:
      return {
        ...state,
        selectedPlan: initialState.selectedPlan,
        planItems: initialState.planItems,
        exceptionPlans: initialState.exceptionPlans,
        exceptionPlanItems: initialState.exceptionPlanItems,
        exceptionPlanItemsIsLoaded: false,
        exceptionPlanItemsIsLoading: false,
        isPlanItemsPrepared: false,
        isExceptionPlanItemsPrepared: false,
        planItemsIsLoaded: false,
        planItemsIsLoading: false,
        exceptionPlanIsLoading: false,
        exceptionPlanIsLoaded: false,
      };

    case LineAvailabilityActionTypes.CheckExceptionDatesInvalid:
      return { ...state, exceptionPlanIsLoading: false };

    case LineAvailabilityActionTypes.FetchError:
      return {
        ...state,
        planItemSaveLoading: false,
        exceptionPlanItemsIsLoading: false,
        planItemsIsLoading: false,
        shiftsLoading: false,
        plansLoading: false,
        costFactorLoading: false,
        exceptionPlanIsLoading: false,
        factorsLoading: false,
      };

    case LineAvailabilityActionTypes.PlanFetchError:
      const errors = [...state.errors, action.payload];
      return {
        ...state,
        ...errors,
        plansLoading: false,
      };

    default:
      return state;
  }
}
