import { Action } from '@ngrx/store';
import {
  ILinesReportInterface,
  IWidgetDataInterface,
  SiteLineFilterDataInterface,
  TableColumnInterface,
  WidgetDataInterface,
} from './line-view-work-order-summary.model';
import { TabRowInterface } from '../../shared/component/side-config-bar/side-config-bar.model';

export const START_LINE_VIEW_WORK_ORDER_SUMMARY_FILTER_DATA_LOADING =
  '[LINE-VIEW_WORK_ORDER_SUMMARY] START LINE VIEW FILTER DATA LOADING';
export const LINE_VIEW_WORK_ORDER_SUMMARY_FILTER_DATA_LOADED =
  '[LINE-VIEW_WORK_ORDER_SUMMARY] LINE VIEW FILTER DATA LOADED';
export const WORK_ORDER_SUMMARY_FILTER_DATA_GENERATED = '[LINE-VIEW_WORK_ORDER_SUMMARY] FILTER DATA GENERATED';
export const WORK_ORDER_SUMMARY_SET_SELECTED_SITE_FILTERS = '[LINE-VIEW_WORK_ORDER_SUMMARY] SET SELECTED SITE FILTERS';
export const WORK_ORDER_SUMMARY_SET_SELECTED_LINE_FILTERS = '[LINE-VIEW_WORK_ORDER_SUMMARY] SET SELECTED LINE FILTERS';
export const WORK_ORDER_SUMMARY_SET_SELECTED_TABLE_COLUMN_SETTINGS =
  '[LINE-VIEW_WORK_ORDER_SUMMARY] SET SELECTED TABLE COLUMN SETTINGS';
export const SET_SELECTED_WIDGET_SETTINGS = '[LINE-VIEW_WORK_ORDER_SUMMARY] SET SELECTED WIDGET SETTINGS';
export const EXPORT_LINE_VIEW_WORK_ORDER_SUMMARY_FILES = '[LINE-VIEW_WORK_ORDER_SUMMARY] EXPORT LINE VIEW FILES';
export const EXPORT_LINE_VIEW_WORK_ORDER_SUMMARY_FILES_DONE =
  '[LINE-VIEW_WORK_ORDER_SUMMARY] EXPORT LINE VIEW FILES DONE';
export const GET_LINE_VIEW_WORK_ORDER_SUMMARY_DATA_STARTED =
  '[LINE-VIEW_WORK_ORDER_SUMMARY] GET LINE VIEW DATA STARTED';
export const LINE_VIEW_WORK_ORDER_SUMMARY_DATA_LOADED = '[LINE-VIEW_WORK_ORDER_SUMMARY] LINE VIEW DATA LOADED';
export const WORK_ORDER_SUMMARY_START_FILTERS_AND_SETTINGS_SAVING =
  '[LINE-VIEW_WORK_ORDER_SUMMARY] START FILTERS AND SETTINGS SAVING';
export const WORK_ORDER_SUMMARY_FILTERS_AND_SETTINGS_SAVED =
  '[LINE-VIEW_WORK_ORDER_SUMMARY]  FILTERS AND SETTINGS SAVED';
export const WORK_ORDER_SUMMARY_FETCH_DATA_ERROR = '[LINE-VIEW_WORK_ORDER_SUMMARY] FETCH DATA ERROR';
export const WORK_ORDER_SUMMARY_CANCEL_LOADING = '[LINE-VIEW_WORK_ORDER_SUMMARY] CANCEL LOADING';
export const SET_TABLE_SETTINGS = '[LINE-VIEW_WORK_ORDER_SUMMARY] SET TABLE SETTINGS';
export const WORK_ORDER_SUMMARY_SET_FILTER_SETTINGS = '[LINE-VIEW_WORK_ORDER_SUMMARY] SET FILTER SETTINGS';
export const LINE_COUNT_OF_SITES_LOADING = '[LINE-VIEW_WORK_ORDER_SUMMARY] LINE COUNT OF SITES LOADING';
export const LINE_COUNT_OF_SITES_LOADED = '[LINE-VIEW_WORK_ORDER_SUMMARY] LINE COUNT OF SITES LOADED';

export class StartLineViewFilterDataLoading implements Action {
  readonly type = START_LINE_VIEW_WORK_ORDER_SUMMARY_FILTER_DATA_LOADING;

  constructor(public userId: number) {}
}

export class LineViewFilterDataLoaded implements Action {
  readonly type = LINE_VIEW_WORK_ORDER_SUMMARY_FILTER_DATA_LOADED;

  constructor(public payload: { data: IWidgetDataInterface }) {}
}

export class SetFilterDataGenerated implements Action {
  readonly type = WORK_ORDER_SUMMARY_FILTER_DATA_GENERATED;

  constructor(public payload: SiteLineFilterDataInterface) {}
}

export class SetSelectedSiteFilters implements Action {
  readonly type = WORK_ORDER_SUMMARY_SET_SELECTED_SITE_FILTERS;

  constructor(public payload: number[]) {}
}

export class SetSelectedLineFilters implements Action {
  readonly type = WORK_ORDER_SUMMARY_SET_SELECTED_LINE_FILTERS;

  constructor(public payload: number[]) {}
}

export class SetSelectedTableColumnSettings implements Action {
  readonly type = WORK_ORDER_SUMMARY_SET_SELECTED_TABLE_COLUMN_SETTINGS;

  constructor(public payload: TableColumnInterface[]) {}
}

export class SetSelectedWidgetSettings implements Action {
  readonly type = SET_SELECTED_WIDGET_SETTINGS;

  constructor(public payload: WidgetDataInterface[]) {}
}

export class ExportLineViewFiles implements Action {
  readonly type = EXPORT_LINE_VIEW_WORK_ORDER_SUMMARY_FILES;

  constructor(public exportType: string) {}
}

export class ExportLineViewFilesDone implements Action {
  readonly type = EXPORT_LINE_VIEW_WORK_ORDER_SUMMARY_FILES_DONE;

  constructor() {}
}

export class GetLineViewDataStarted implements Action {
  readonly type = GET_LINE_VIEW_WORK_ORDER_SUMMARY_DATA_STARTED;

  constructor(public mode: string) {}
}

export class LineViewDataLoaded implements Action {
  readonly type = LINE_VIEW_WORK_ORDER_SUMMARY_DATA_LOADED;

  constructor(public payload: ILinesReportInterface, public mode: string) {}
}

export class StartFiltersAndSettingsSaving implements Action {
  readonly type = WORK_ORDER_SUMMARY_START_FILTERS_AND_SETTINGS_SAVING;

  constructor() {}
}

export class FiltersAndSettingsSaved implements Action {
  readonly type = WORK_ORDER_SUMMARY_FILTERS_AND_SETTINGS_SAVED;

  constructor() {}
}

export class SetTableSettings implements Action {
  readonly type = SET_TABLE_SETTINGS;
  constructor(public payload: TabRowInterface[], public setAsDefault: boolean) {}
}

export class SetFilterSettings implements Action {
  readonly type = WORK_ORDER_SUMMARY_SET_FILTER_SETTINGS;

  constructor(public siteIds: number[], public lineIds: number[], public setAsDefault: boolean) {}
}

export class LineCountOfSitesLoading implements Action {
  readonly type = LINE_COUNT_OF_SITES_LOADING;

  constructor(public selectedSites: number[]) {}
}

export class LineCountOfSitesLoaded implements Action {
  readonly type = LINE_COUNT_OF_SITES_LOADED;

  constructor(public payload: number) {}
}

export class FetchDataError implements Action {
  readonly type = WORK_ORDER_SUMMARY_FETCH_DATA_ERROR;

  constructor(public payload: object) {}
}

export class CancelLoading implements Action {
  readonly type = WORK_ORDER_SUMMARY_CANCEL_LOADING;
}

export type LineViewWorkOrderSummaryActions =
  | StartLineViewFilterDataLoading
  | LineViewFilterDataLoaded
  | SetFilterDataGenerated
  | SetSelectedSiteFilters
  | SetSelectedLineFilters
  | ExportLineViewFiles
  | ExportLineViewFilesDone
  | GetLineViewDataStarted
  | LineViewDataLoaded
  | SetSelectedTableColumnSettings
  | SetSelectedWidgetSettings
  | StartFiltersAndSettingsSaving
  | FiltersAndSettingsSaved
  | SetTableSettings
  | SetFilterSettings
  | LineCountOfSitesLoading
  | LineCountOfSitesLoaded
  | FetchDataError
  | CancelLoading;
