import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { EquipmentTypesService } from '../../../shared/service/settings/equipment-types/equipment-types.service';
import * as AppActions from '../../app/actions';
import * as oeeAppReducer from '../../oee.reducer';
import * as ObjectActions from './equipment-types.actions';
import { IEquipmentType } from './equipment-types.model';
import { SiteCRUDInterface } from '../../../shared/component/filter/filter.class';
import { TagInterface } from '../tags/tags.model';

@Injectable()
export class EquipmentTypesEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: EquipmentTypesService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
  ) {}

  getEquipmentData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LOAD_EQUIPMENT_TYPES_DATA),
      switchMap((payload: ObjectActions.LoadEquipmentTypesData) => {
        const { page, pageSize, sort, search } = payload.requestParams;

        const searchParams = { $and: [] };

        if (search !== '') {
          searchParams.$and.push({ $or: [{ description: { $cont: search } }] });
        }

        if (searchParams.$and.length === 0) {
          delete searchParams.$and;
        }

        let httpParams: HttpParams = new HttpParams()
          .set('page', String(page))
          .set('limit', String(pageSize))
          .set('s', JSON.stringify(searchParams))
          .append('join', 'site||name');

        if (sort) {
          const direction: 'DESC' | 'ASC' = sort.type === 'descending' ? 'DESC' : 'ASC';
          httpParams = httpParams.set('sort', `${sort.column},${direction}`);
        }

        return this.service.getEquipmentTypes(httpParams).pipe(
          switchMap((response: GetManyResponseInterface<IEquipmentType>) => {
            return of(new ObjectActions.LoadedEquipmentTypesData(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes));
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes));
      }),
    ),
  );

  $loadSiteData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LOAD_SITE_DATA),
      switchMap(() => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.loadSiteData().pipe(
          switchMap((response: GetManyResponseInterface<SiteCRUDInterface>) => {
            return of(new ObjectActions.LoadedSiteData(response), new AppActions.HideLoader());
          }),
          catchError(() => {
            return of(new AppActions.HideLoader());
          }),
        );
      }),
      catchError(() => {
        return of(new AppActions.HideLoader());
      }),
    ),
  );

  $loadTagsData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.LOAD_TAGS_DATA),
      switchMap((objectData: ObjectActions.LoadTagsData) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.loadTagsData(objectData.siteId, objectData.searchText, objectData.tagIds).pipe(
          switchMap((response: GetManyResponseInterface<TagInterface>) => {
            return of(new ObjectActions.LoadedTagsData(response), new AppActions.HideLoader());
          }),
          catchError(() => {
            return of(new AppActions.HideLoader());
          }),
        );
      }),
      catchError(() => {
        return of(new AppActions.HideLoader());
      }),
    ),
  );

  editSingleEquipmentData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SINGLE_EDIT_EQUIPMENT_TYPES_DATA),
      switchMap((objectData: ObjectActions.SingleEditEquipmentTypesData) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return from(this.service.patchEquipmentTypeData(objectData.equipmentId, objectData.payload)).pipe(
          switchMap((response) => {
            return of(new ObjectActions.EditedSingleEditEquipmentTypesData(response), new AppActions.HideLoader());
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchError(err), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  editEquipmentTypesData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.EDIT_EQUIPMENT_TYPES_DATA),
      switchMap((objectData: ObjectActions.EditEquipmentTypesData) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return from(this.service.patchEquipmentTypesData(objectData.payload)).pipe(
          switchMap((response) => {
            return of(new ObjectActions.EditedEquipmentTypesData(response), new AppActions.HideLoader());
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchError(err), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  createEquipment = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.CREATE_EQUIPMENT_TYPE),
      switchMap((objectData: ObjectActions.CreateEquipmentType) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return from(this.service.createEquipmentType(objectData.payload)).pipe(
          switchMap((response) => {
            return of(new ObjectActions.CreatedEquipmentType(response), new AppActions.HideLoader());
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchError(err), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  deleteEquipment = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DELETE_EQUIPMENT_TYPE),
      switchMap((objectData: ObjectActions.DeleteEquipmentType) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return from(this.service.deleteEquipmentType(objectData.equipmentTypeId)).pipe(
          switchMap((response: GetManyResponseInterface<IEquipmentType>) => {
            return of(new ObjectActions.DeletedEquipmentType(response), new AppActions.HideLoader());
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchError(err), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  deleteEquipmentTypes = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DELETE_EQUIPMENT_TYPES),
      switchMap((objectData: ObjectActions.DeleteEquipmentTypes) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return from(this.service.deleteEquipmentTypes(objectData.equipmentTypeIds)).pipe(
          switchMap((response) => {
            return of(new ObjectActions.DeletedEquipmentTypes(response), new AppActions.HideLoader());
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchError(err), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );
}
