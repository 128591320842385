import { Action } from '@ngrx/store';
import { ProductCRUDInterface } from '../../../shared/component/filter/filter.class';
import { HttpOptionInterface } from '../filter.model';

export enum FilterProductActionTypes {
  StartLoadFilterProducts = '[Filter Product] Load Filter Products',
  FilterProductsLoaded = '[Filter Product] Filter Products Loaded',
  ResetProductStore = '[Filter Product] Reset store data for product filter',
}

export class StartLoadFilterProducts implements Action {
  readonly type = FilterProductActionTypes.StartLoadFilterProducts;
  constructor(public options: HttpOptionInterface, public isFetchMoreRequest: boolean = false) {}
}
export class FilterProductsLoaded implements Action {
  readonly type = FilterProductActionTypes.FilterProductsLoaded;
  constructor(
    public data: ProductCRUDInterface[],
    public totalCount: number = 0,
    public isFetchMoreRequest: boolean = false,
  ) {}
}
export class ResetProductStore implements Action {
  readonly type = FilterProductActionTypes.ResetProductStore;
  constructor() {}
}

export type FilterProductActions = StartLoadFilterProducts | FilterProductsLoaded | ResetProductStore;
