import { EquipmentTaskActions, EquipmentTaskActionTypes } from './equipment-task.actions';
import { EquipmentTaskStateInterface } from './equipment-task.model';

export const equipmentTaskInitialState: EquipmentTaskStateInterface = {
  isLoading: false,
  isLoaded: false,
  equipmentTask: null,
  nodes: [],
  nodesDataLoading: false,
  nodesDataLoaded: false,
  errors: [],
};

export function equipmentTaskReducer(
  state: EquipmentTaskStateInterface = equipmentTaskInitialState,
  action: EquipmentTaskActions,
) {
  switch (action.type) {
    case EquipmentTaskActionTypes.StartEquipmentTaskDataLoading:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      };
    case EquipmentTaskActionTypes.StartEquipmentTaskDataLoaded:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        equipmentTask: action.payload.data,
      };
    case EquipmentTaskActionTypes.NodeDataLoading:
      return {
        ...state,
        nodesDataLoading: true,
        nodesDataLoaded: false,
      };
    case EquipmentTaskActionTypes.NodeDataLoaded:
      return {
        ...state,
        nodesDataLoading: false,
        nodesDataLoaded: true,
        nodes: action.payload,
      };
    case EquipmentTaskActionTypes.FetchError:
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        nodesDataLoading: false,
        nodesDataLoaded: false,
        errors: action.payload,
      };
    default:
      return state;
  }
}
