import * as Actions from './equipment-assignment.actions';
import { IEquipmentAssignmentState } from './equipment-assignment.model';

export const equipmentAssignmentState: IEquipmentAssignmentState = {
  isEquipmentAssignmentDataLoading: false,
  isEquipmentAssignmentDataLoaded: false,
  equipmentAssignmentData: null,
  isLinesLoading: false,
  isLinesLoaded: false,
  lines: [],
  fileDownloadStatus: false,
  excelUploadResult: [],
  isUploadExcelLoading: false,
  isUploadExcelLoaded: false,
  totalEquipmentAssignmentDataCount: 0,
};

export function equipmentAssignmentReducer(
  state: IEquipmentAssignmentState = equipmentAssignmentState,
  action: Actions.EquipmentAssignmentsActions,
): IEquipmentAssignmentState {
  switch (action.type) {
    case Actions.GET_LINES:
      return {
        ...state,
        isLinesLoading: true,
        isLinesLoaded: false,
      };

    case Actions.GET_LINES_COMPLETED:
      return {
        ...state,
        isLinesLoading: false,
        isLinesLoaded: true,
        lines: action.lines,
      };

    case Actions.EQUIPMENT_ASSIGNMENT_DATA_COUNT_LOADED:
      return {
        ...state,
        totalEquipmentAssignmentDataCount: action.payload.total,
      };

    case Actions.LOAD_EQUIPMENT_ASSIGNMENTS_DATA:
      return {
        ...state,
        isEquipmentAssignmentDataLoading: true,
        isEquipmentAssignmentDataLoaded: false,
      };

    case Actions.LOADED_EQUIPMENT_ASSIGNMENTS_DATA:
      return {
        ...state,
        equipmentAssignmentData: action.payload,
        isEquipmentAssignmentDataLoading: false,
        isEquipmentAssignmentDataLoaded: true,
      };

    case Actions.DOWNLOAD_EQUIPMENT_ASSIGNMENT_EXCEL:
      return {
        ...state,
        ...{
          fileDownloadStatus: true,
        },
      };

    case Actions.DOWNLOAD_EQUIPMENT_ASSIGNMENT_EXCEL_COMPLETED:
      return {
        ...state,
        ...{
          fileDownloadStatus: false,
        },
      };

    case Actions.UPLOAD_EQUIPMENT_ASSIGNMENT_EXCEL:
      return {
        ...state,
        ...{
          isUploadExcelLoading: true,
          isUploadExcelLoaded: false,
        },
      };

    case Actions.UPLOAD_EQUIPMENT_ASSIGNMENT_EXCEL_COMPLETED:
      return {
        ...state,
        ...{
          excelUploadResult: action.payload,
          isUploadExcelLoading: false,
          isUploadExcelLoaded: true,
        },
      };
    default:
      return state;
  }
}
