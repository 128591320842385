<div class="scw-mat-picker-container" [class.block]="block">
  <label *ngIf="label" class="input-label">{{ label }}</label>
  <button
    mat-stroked-button
    class="scw-mat-picker"
    [disabled]="disabled"
    [class.has-errors]="hasErrors"
    [class.mat-default]="pickerType === 'default'"
    [class.mat-planned-down-time]="pickerType === 'planned-down-time'"
    [class.mat-busy]="pickerType === 'busy'"
    [class.mat-unplanned-down-time]="pickerType === 'unplanned-down-time'"
    [class.mat-attention]="pickerType === 'attention'"
    [class.mat-missing-data]="pickerType === 'missing-data'"
    [class.mat-run-time]="pickerType === 'run-time'"
    [class.mat-available]="pickerType === 'available'"
    [ngClass]="className"
    (click)="onClickEmitter()"
  >
    <div class="scw-mat-picker-inner-container">
      <span class="text-field" [class.placeholder]="!inputModel?.value" [class.value]="!!inputModel?.value">
        <ng-template [ngIf]="!!inputModel?.value" [ngIfElse]="placeholderText">{{ inputModel?.text }}</ng-template>
        <ng-template #placeholderText>{{ placeholder }}</ng-template>
      </span>
      <em class="fas fa-ellipsis-h ellipsis-horizontal-icon"></em>
    </div>
  </button>
  <ng-template [ngIf]="errorText || hint">
    <mat-hint [class.error]="errorText" [class.hint]="!errorText && hint">
      <span>
        <em
          class="sub-text-icon fas"
          [class.fa-exclamation-circle]="errorText"
          [class.fa-info-circle]="!errorText && hint"
        ></em>
        <ng-template [ngIf]="errorText">{{ errorText }}</ng-template>
        <ng-template [ngIf]="!errorText && hint">{{ hint }}</ng-template>
      </span>
    </mat-hint>
  </ng-template>
</div>
