import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, mergeMap } from 'rxjs/operators';
import * as ObjectActions from './activity-view.actions';
import { from, of } from 'rxjs';
import { ActivityViewDataInterface } from './activity-view.model';
import { ActivityViewService } from './activity-view.service';
import { BaseOneResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import * as AppActions from '../../app/actions';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import { EntityTranslatorService } from '../../../shared/service/entity-translator/entity-translator.service';

@Injectable()
export class ActivityViewEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: ActivityViewService,
    private store: Store<oeeAppReducer.OeeAppState>,
    private readonly entityTranslatorService: EntityTranslatorService,
  ) {}

  getActivityViewData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.EActivityViewAction.ACTIVITY_VIEW_DATA_LOAD),
      mergeMap((objectData: ObjectActions.ActivityViewDataLoading) => {
        return from(this.service.getActivityViewData(objectData.lineId)).pipe(
          mergeMap((response: BaseOneResponseInterface<ActivityViewDataInterface>) => {
            this.entityTranslatorService.translate(response.data);
            return of(new ObjectActions.ActivityViewDataLoaded(response.data));
          }),
          catchError((err) => {
            return of(new ObjectActions.ActivityViewFetchError(err), new AppActions.HideTopLoader());
          }),
        );
      }),
    ),
  );
}
