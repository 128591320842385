import { Inject, Injectable } from '@angular/core';
import { FilterServiceInterface } from './service.class';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivityCRUDInterface } from '../../component/filter/filter.class';
import { GetManyResponseInterface } from '../../model/interface/crud-response-interface.model';

@Injectable({
  providedIn: 'root',
})
export class ActivityService implements FilterServiceInterface {
  constructor(public http: HttpClient, @Inject('API_BASE_URL') private readonly baseUrl: string) {}

  public getData(options?: HttpParams): Promise<ActivityCRUDInterface[]> {
    return new Promise((resolve, reject) => {
      this.http
        .get(`${this.baseUrl}/activities?fields=name,activityType&join=activityTypeRelation`, { params: options })
        .subscribe((response: GetManyResponseInterface<ActivityCRUDInterface>) => {
          if (response.hasOwnProperty('data')) {
            resolve(response.data);
          } else {
            reject();
          }
        });
    });
  }
}
