import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ObjectActions from './alerts.actions';
import { catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as AppActions from '../../app/actions';
import { AlertsService } from './alerts.service';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import { ErrorMessageService } from '../../../shared/service/error-message.service';
import { ExcelHelperService } from '../../../shared/service/excel/excel-helper.service';
import { AdvancedFilterService } from '../../../shared/component/filter/advanced-filter/advanced-filter.service';
import { HelperService } from '../../../shared/service/helper.service';
import {
  BaseCrudResponse,
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import * as UsersActions from '../users/users.actions';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { TasksService } from '../tasks/tasks.service';
import { AlertInterface, IAlertFormData } from './alerts.model';
import { TaskCRUDInterface } from '../tasks/tasks.model';

@Injectable()
export class AlertsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: AlertsService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly errorMessageService: ErrorMessageService,
    private readonly excelHelperService: ExcelHelperService,
    private readonly advancedFilterService: AdvancedFilterService,
    private readonly helperService: HelperService,
    private readonly tasksService: TasksService,
  ) {}

  getAlerts = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ALERTS_DATA_LOADING),
      switchMap((payload: ObjectActions.AlertsDataLoading) => {
        return this.service.getAlerts(this.helperService.insertGenericCrudRequestParameters(payload.query)).pipe(
          switchMap((response: GetManyResponseInterface<AlertInterface>) => {
            return of(new ObjectActions.AlertsDataLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes));
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes));
      }),
    ),
  );

  deleteAlerts = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.DELETE_ALERTS),
      switchMap((objectData: ObjectActions.DeleteAlerts) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.service.deleteAlerts(objectData.payload).pipe(
          switchMap((response: BaseCrudResponse | BulkResponseDataInterface) => {
            const responseMessages = 'data' in response && Array.isArray(response.data) ? response.data : [response];
            this.errorMessageService.getTranslatedErrorMessage(responseMessages);
            const mergedArray: ({ id: number } & {
              errorMessages?: string;
            })[] = this.excelHelperService.mergeBulkResponseWithRequestData(
              { ...response, data: responseMessages },
              objectData.payload.map((item) => {
                return { id: item };
              }),
              true,
            );

            return of(new ObjectActions.DeleteAlertsCompleted(mergedArray), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  getFormData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ALERTS_FORM_DATA_LOADING),
      switchMap((objectData: ObjectActions.AlertsFormDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const httpParams: HttpParams = new HttpParams().set('limit', '5000');

        return this.service.getFormData(httpParams).pipe(
          switchMap((response: IAlertFormData) => {
            return of(new ObjectActions.AlertsFormDataLoaded(response), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new UsersActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new UsersActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  getTasksData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ALERTS_TASKS_DATA_LOADING),
      switchMap((objectData: ObjectActions.AlertsTasksDataLoading) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const httpParams: HttpParams = new HttpParams()
          .append('overrideLimit', 'true')
          .append('groupBy', 'Task.title,Task.activityId,Task.siteId')
          .append('join', 'activity||activityType')
          .set('alertTaskLineCount', objectData.lineIds.length)
          .set(
            's',
            JSON.stringify({
              siteId: { $in: objectData.siteIds },
              lineId: { $in: objectData.lineIds },
              activityId: { $in: objectData.activityIds },
              'activity.activityType': { $ne: 'runTime' },
            }),
          );

        return this.tasksService.getTasks(httpParams).pipe(
          switchMap((response: GetManyResponseInterface<Partial<TaskCRUDInterface>>) => {
            return of(new ObjectActions.AlertsTasksDataLoaded(response.data), new AppActions.HideLoader());
          }),
          catchError((errorRes) => {
            return of(new UsersActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new UsersActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  addAlert = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ADD_ALERT),
      switchMap((objectData: ObjectActions.AddAlert) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.createAlert(objectData.alert).pipe(
          switchMap((response: BaseOneResponseInterface<AlertInterface>) => {
            return of(new ObjectActions.AddAlertCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error: HttpErrorResponse) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  editAlert = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.EDIT_ALERT),
      switchMap((objectData: ObjectActions.EditAlert) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.updateAlert(objectData.alert, objectData.alertId).pipe(
          switchMap((response: BaseOneResponseInterface<AlertInterface>) => {
            return of(new ObjectActions.EditAlertCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );
}
