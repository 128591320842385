import * as Actions from './equipment-brands.actions';
import { IEquipmentBrandsState } from './equipment-brands.model';

export const equipmentBrandsState: IEquipmentBrandsState = {
  isEquipmentBrandsLoading: false,
  isEquipmentBrandsLoaded: false,
  equipmentBrands: [],
  totalEquipmentBrandsDataCount: 0,
};

export function equipmentBrandsReducer(
  state: IEquipmentBrandsState = equipmentBrandsState,
  action: Actions.EquipmentBrandsActions,
): IEquipmentBrandsState {
  switch (action.type) {
    case Actions.LOAD_EQUIPMENT_BRANDS_DATA:
      return {
        ...state,
        isEquipmentBrandsLoading: true,
        isEquipmentBrandsLoaded: false,
      };

    case Actions.LOADED_EQUIPMENT_BRANDS_DATA:
      return {
        ...state,
        isEquipmentBrandsLoading: false,
        isEquipmentBrandsLoaded: true,
        equipmentBrands: action.payload.data,
        totalEquipmentBrandsDataCount: action.payload.total,
      };

    default:
      return state;
  }
}
