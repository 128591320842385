import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap, take, tap } from 'rxjs/operators';
import * as ObjectActions from './root-cause-analysis.actions';
import { forkJoin, from, Observable, of } from 'rxjs';
import { RootCauseAnalysisService } from './root-cause-analysis.service';
import {
  IActivityLog,
  IActivityHistoryData,
  ILineData,
  IRootCauseAnalysisChartNode,
} from './root-cause-analysis.model';
import * as AppActions from '../../app/actions';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import { ResponseArrayInterface, ResponseInterface } from '../../../shared/model/interface/generic-api-response.model';
import { User } from '../../user/model';
import { ICurrentShift } from '../../../shared/service/filter/service.class';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { SiteCRUDInterface } from '../../../shared/component/filter/filter.class';
import { ActivityLogInterface } from '../activity-logs/activity-logs.model';
import { ActivityLogsService } from '../activity-logs/activity-logs.service';
import { EntityTranslatorService } from '../../../shared/service/entity-translator/entity-translator.service';
import { IObjectWithEntityTranslations } from '../../../shared/service/entity-translator/entity-translator.model';

@Injectable()
export class RootCauseAnalysisEffects {
  constructor(
    private readonly actions$: Actions,
    public service: RootCauseAnalysisService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    public activityLogsService: ActivityLogsService,
    private readonly entityTranslatorService: EntityTranslatorService,
  ) {}

  private timezone$: string;
  private serverDataLimit: number = 90000;

  getRootCauseAnalysisData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.START_ROOT_CAUSE_ANALYSIS_DATA_LOADING),
      switchMap((objectData: ObjectActions.StartRootCauseAnalysisDataLoading) => {
        this.store
          .select('user')
          .pipe(take(1))
          .subscribe((user: User) => {
            this.timezone$ = user.timezone;
          });

        return from(this.service.getActivityHistoryData(objectData.params, 1, 1, objectData.filterActivityType)).pipe(
          switchMap((response: ResponseArrayInterface<IActivityHistoryData>) => {
            response.data.forEach((activityHistory: IActivityHistoryData) =>
              this.entityTranslatorService.translate(activityHistory),
            );
            if (response.total > this.serverDataLimit) {
              return of(new ObjectActions.ShowManyDataWarning());
            }

            return of(
              new ObjectActions.RootCauseAnalysisFetchData(
                objectData.params,
                response.total,
                objectData.filterActivityType,
              ),
            );
          }),
          catchError((err) => {
            return of(new ObjectActions.RootCauseAnalysisDataError(err));
          }),
        );
      }),
    ),
  );

  fetchData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ROOT_CAUSE_ANALYSIS_FETCH_DATA),
      switchMap((objectData: ObjectActions.RootCauseAnalysisFetchData) => {
        const observables: Observable<
          | ResponseArrayInterface<IActivityHistoryData>
          | ResponseArrayInterface<ILineData>
          | ResponseArrayInterface<ICurrentShift>
          | GetManyResponseInterface<SiteCRUDInterface>
        >[] = this.service.getRootCauseAnalysisObservables(
          objectData.params,
          objectData.totalCount,
          objectData.filterActivityType,
        );
        let fetchedPartCount: number = 0;
        const totalPartCount: number = observables.length;

        return forkJoin(
          observables.map(
            (
              o: Observable<
                | ResponseArrayInterface<IActivityHistoryData>
                | ResponseArrayInterface<ILineData>
                | ResponseArrayInterface<ICurrentShift>
                | GetManyResponseInterface<SiteCRUDInterface>
              >,
            ) =>
              o.pipe(
                tap(() => {
                  fetchedPartCount += 1;
                  const value: number = Math.ceil((fetchedPartCount / totalPartCount) * 100);
                  this.store.dispatch(new ObjectActions.SetRootCauseAnalysisProgress(value));
                }),
              ),
          ),
        ).pipe(
          switchMap(
            (
              response: (
                | ResponseArrayInterface<IActivityHistoryData>
                | ResponseArrayInterface<ILineData>
                | ResponseArrayInterface<ICurrentShift>
                | GetManyResponseInterface<SiteCRUDInterface>
              )[],
            ) => {
              let activityHistories: IActivityHistoryData[] = [];
              let lines: ILineData[] = [];
              let sites: SiteCRUDInterface[] = [];
              let currentShifts: ICurrentShift[] = [];

              response.forEach(
                (
                  currentResponse:
                    | ResponseArrayInterface<IActivityHistoryData | ILineData | ICurrentShift>
                    | GetManyResponseInterface<SiteCRUDInterface>,
                ) => {
                  if (currentResponse.hasOwnProperty('data')) {
                    currentResponse.data.forEach(
                      (currentData: IActivityHistoryData | ILineData | ICurrentShift | SiteCRUDInterface) =>
                        this.entityTranslatorService.translate(currentData as IObjectWithEntityTranslations),
                    );
                  }
                },
              );

              response.map((value, index) => {
                switch (index) {
                  case 0:
                    currentShifts = value.data as ICurrentShift[];
                    break;
                  case 1:
                    lines = value.data as ILineData[];
                    break;
                  case 2:
                    sites = value.data as SiteCRUDInterface[];
                    break;
                  default:
                    activityHistories = activityHistories.concat(value.data as IActivityHistoryData[]);
                }
              });

              activityHistories.forEach((activityHistory: IActivityHistoryData) => {
                this.entityTranslatorService.translate(activityHistory);
              });

              const formattedActivityHistoryData: IActivityLog[] =
                this.service.formatActivityHistoryData(activityHistories);
              const formattedLineData: IActivityLog[] = this.service.formatLineData(
                lines,
                currentShifts,
                objectData.params,
                this.timezone$,
              );
              const data: IActivityLog[] = formattedLineData.concat(formattedActivityHistoryData).map((row) => {
                const { preRunPhaseName, postRunPhaseName, runPhaseName } = sites.find(
                  (site) => site.id === row.siteId,
                );

                return {
                  ...row,
                  preRunPhaseName,
                  postRunPhaseName,
                  runPhaseName,
                  phaseName: this.activityLogsService.getPhaseName({
                    preRunPhaseName,
                    postRunPhaseName,
                    runPhaseName,
                    phaseId: row.phaseId,
                  } as ActivityLogInterface),
                };
              });

              return of(new ObjectActions.RootCauseAnalysisDataLoaded(data));
            },
          ),
          catchError((err) => {
            return of(new ObjectActions.RootCauseAnalysisDataError(err));
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.RootCauseAnalysisDataError(errorRes));
      }),
    ),
  );

  getRootCauseAnalysisChartData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.ROOT_CAUSE_ANALYSIS_CHART_DATA_LOAD),
      switchMap((objectData: ObjectActions.LoadRootCauseAnalysisChartData) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return from(this.service.getRootCauseAnalysisChartData(objectData.params)).pipe(
          switchMap((response: ResponseInterface<IRootCauseAnalysisChartNode[]>) => {
            response.data.forEach((rootCauseAnalysisChartNode: IRootCauseAnalysisChartNode) =>
              this.entityTranslatorService.translate(rootCauseAnalysisChartNode),
            );

            return of(
              new AppActions.HideLoader(),
              new ObjectActions.LoadedRootCauseAnalysisChartData(response.data, objectData.isRegularMode),
            );
          }),
          catchError((err) => {
            return of(new ObjectActions.RootCauseAnalysisChartDataError(err), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((err) => {
        return of(new ObjectActions.RootCauseAnalysisChartDataError(err), new AppActions.HideLoader());
      }),
    ),
  );
}
