import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { IWeavyJWT } from '../../../store/weavy/weavy.model';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../../store/oee.reducer';
import * as WeavyActions from '../../../store/weavy/weavy.actions';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HelperService } from '../helper.service';

declare let Weavy: any;

@Injectable({
  providedIn: 'root',
})
export class WeavyService implements OnDestroy {
  public static jwtToken: IWeavyJWT;
  public static weavy: any;
  public openWeavy: EventEmitter<void> = new EventEmitter<void>();
  public messageCount: EventEmitter<string> = new EventEmitter<string>();
  public weavyHasErrors: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(public http: HttpClient, private readonly store: Store<oeeAppReducer.OeeAppState>) {}

  public initializeWeavy(weavyToken: IWeavyJWT): void {
    WeavyService.weavy = new Weavy({ jwt: weavyToken.token });

    this.store.dispatch(new WeavyActions.WeavyInitialized());
  }

  public getJWT(parameters: HttpParams): Observable<IWeavyJWT> {
    return this.http.post<IWeavyJWT>(environment.weavyUserAuthUrl, parameters);
  }

  public getWeavyUnreadMessageCountWithRequest(): Observable<string> {
    return this.http.get<string>(environment.weavyUnreadMessageUrl);
  }

  public ngOnDestroy(): void {
    WeavyService.weavy?.destroy();
  }
}
