import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { SensorListInterface, SensorReportsDataInterface } from './sensor-reports.model';

@Injectable({
  providedIn: 'root',
})
export class SensorReportsService {
  constructor(public http: HttpClient, @Inject('API_BASE_URL') private readonly baseUrl: string) {}

  private readonly routes = {
    sensorList: '/sensor-list',
    sensorReports: '/sensor-list/sensor-reports',
  };

  public getSensorList(params: HttpParams): Observable<GetManyResponseInterface<SensorListInterface>> {
    return this.http.get<GetManyResponseInterface<SensorListInterface>>(`${this.baseUrl}${this.routes.sensorList}`, {
      params,
    });
  }

  public getSensorReportsData(params: HttpParams): Observable<GetManyResponseInterface<SensorReportsDataInterface>> {
    return this.http.get<GetManyResponseInterface<SensorReportsDataInterface>>(`${this.baseUrl}${this.routes.sensorReports}`, {
      params,
    });
  }
}
