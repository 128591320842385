import { Component, OnInit } from '@angular/core';
import { ActionsSubject, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as AppActions from '../../../../store/app/actions';
import * as PageHeaderActions from '../../../../store/page-header/page-header.actions';
import * as oeeAppReducer from '../../../../store/oee.reducer';
import { ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root-cause-analysis-comparison-button',
  templateUrl: './root-cause-analysis-comparison-button.component.html',
})
export class RootCauseAnalysisComparisonButtonComponent implements OnInit {
  public isRootCauseAnalysisComparisonMode: boolean = false;
  private subscriptions: Subscription[] = [];
  public comparisonModeTranslation: string;
  public regularModeTranslation: string;

  constructor(
    public translate: TranslateService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly storeActions: ActionsSubject,
  ) {}

  public ngOnInit(): void {
    this.comparisonModeTranslation = this.translate.instant('general.rootCauseAnalysis.comparisonMode');
    this.regularModeTranslation = this.translate.instant('general.regularMode');

    this.subscriptions.push(
      this.storeActions
        .pipe(ofType(PageHeaderActions.CHANGE_ROOT_CAUSE_ANALYSIS_MODE))
        .subscribe((response: PageHeaderActions.ChangeRootCauseAnalysisMode) => {
          this.isRootCauseAnalysisComparisonMode = response.isRootCauseAnalysisComparisonMode;
        }),
    );
  }

  public changeRootCauseAnalysisMode(): void {
    this.store.dispatch(new AppActions.ShowLoader());

    setTimeout(() => {
      this.store.dispatch(new PageHeaderActions.ChangeRootCauseAnalysisMode(!this.isRootCauseAnalysisComparisonMode));
      this.store.dispatch(new AppActions.HideLoader());
    }, 200);
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
