import { Action } from '@ngrx/store';
import { IUserConfiguration } from './user-configuration.model';
export const GET_USER_CONFIGURATION_DATA_LOADING = '[USER CONFIGURATION] Get User Configuration Data Loading';
export const GET_USER_CONFIGURATION_DATA_LOADED = '[USER CONFIGURATION] Get User Configuration Data Loaded';
export const UPDATE_USER_CONFIGURATION_LOADING = '[USER CONFIGURATION] Update User Configuration Loading';
export const UPDATE_USER_CONFIGURATION_LOADED = '[USER CONFIGURATION] Update User Configuration Loaded';
export const UPDATE_TEMPORARY_MODE = '[USER CONFIGURATION] Update Temporary Mode';
export const USER_CONFIGURATION_FETCH_ERROR = '[USER CONFIGURATION] User Configuration Data Fetch Error';

export class GetUserConfigurationLoading implements Action {
  readonly type = GET_USER_CONFIGURATION_DATA_LOADING;

  constructor() {}
}

export class GetUserConfigurationLoaded implements Action {
  readonly type = GET_USER_CONFIGURATION_DATA_LOADED;

  constructor(public payload: IUserConfiguration | null) {}
}

export class UpdateUserConfigurationLoading implements Action {
  readonly type = UPDATE_USER_CONFIGURATION_LOADING;

  constructor(public configuration: IUserConfiguration) {}
}

export class UpdateUserConfigurationLoaded implements Action {
  readonly type = UPDATE_USER_CONFIGURATION_LOADED;

  constructor(public payload: IUserConfiguration | null) {}
}

export class UpdateTemporaryMode implements Action {
  readonly type = UPDATE_TEMPORARY_MODE;

  constructor(public temporaryMode: boolean) {}
}

export class UserConfigurationFetchError implements Action {
  readonly type = USER_CONFIGURATION_FETCH_ERROR;

  constructor(public payload: any[]) {}
}

export type UserConfigurationActions =
  | GetUserConfigurationLoading
  | GetUserConfigurationLoaded
  | UpdateUserConfigurationLoading
  | UpdateUserConfigurationLoaded
  | UpdateTemporaryMode
  | UserConfigurationFetchError;
