import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as ObjectActions from './task.actions';
import { TaskFilterCRUDInterface } from '../../../shared/component/filter/filter.class';
import { TaskService } from '../../../shared/service/filter/task.service';
import { generateHttpOption } from '../../../shared/helper/app-helper';
import { EntityTranslatorService } from 'src/app/shared/service/entity-translator/entity-translator.service';

@Injectable()
export class FilterTaskEffects {
  constructor(
    private readonly actions$: Actions<ObjectActions.FilterTaskActions>,
    public taskService: TaskService,
    private entityTranslator: EntityTranslatorService,
  ) {}

  loadFilterSites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.FilterTaskActionTypes.StartLoadFilterTasks),
      switchMap((objectData: ObjectActions.StartLoadFilterTasks) => {
        const params = generateHttpOption(objectData.options);

        return from(this.taskService.getData(params)).pipe(
          switchMap((data: TaskFilterCRUDInterface[]) => {
            data.forEach((taskFilter: TaskFilterCRUDInterface) => {
              this.taskService.setTaskOriginalTitle(taskFilter);
              this.entityTranslator.translate(taskFilter);
            });

            return of(new ObjectActions.FilterTasksLoaded(data));
          }),
          catchError(() => {
            return of(new ObjectActions.FilterTasksLoaded([]));
          }),
        );
      }),
    ),
  );
}
