import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as ObjectActions from './root-cause-group.actions';
import { RootCauseGroupCRUDInterface } from '../../../shared/component/filter/filter.class';
import { generateHttpOption } from '../../../shared/helper/app-helper';
import { RootCauseGroupService } from '../../../shared/service/filter/root-cause-group.service';

@Injectable()
export class FilterRootCauseGroupEffects {
  loadFilterRootCauseGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.FilterRootCauseGroupActionGroup.StartLoadFilterRootCauseGroups),
      switchMap((objectData: ObjectActions.StartLoadFilterRootCauseGroups) => {
        const params = generateHttpOption(objectData.options);
        return from(this.rootCauseGroupService.getData(params)).pipe(
          switchMap((response: RootCauseGroupCRUDInterface[]) => {
            return of(new ObjectActions.FilterRootCauseGroupsLoaded(response));
          }),
          catchError(() => {
            return of(new ObjectActions.FilterRootCauseGroupsLoaded([]));
          }),
        );
      }),
    ),
  );

  constructor(
    private readonly actions$: Actions<ObjectActions.FilterRootCauseGroupAction>,
    public rootCauseGroupService: RootCauseGroupService,
  ) {}
}
