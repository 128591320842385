import { Action } from '@ngrx/store';
import {
  GetManyHomeScreenMetricCrudResponse,
  GetOneHomeScreenMetricCrudResponse,
  GetSitesCrudResponse,
  HomeScreenMetricSetInterface,
  PaginationInterface,
} from './home-screen-metric-settings.model';
import { HomeActionTypes } from '../home/home.actions';

export const HOME_SCREEN_METRICS_DATA_LOADING = '[HOME_SCREEN_METRICS] START HOME SCREEN METRICS DATA LOADING';
export const START_HOME_SCREEN_METRICS_DATA_LOADED = '[HOME_SCREEN_METRICS] START HOME SCREEN METRICS DATA LOADED';
export const COPY_HOME_SCREEN_METRIC_LOADING = '[HOME_SCREEN_METRICS] COPY HOME SCREEN METRIC LOADING';
export const COPY_HOME_SCREEN_METRIC_LOADED = '[HOME_SCREEN_METRICS] COPY HOME SCREEN METRIC LOADED';
export const DELETE_HOME_SCREEN_METRIC = '[HOME_SCREEN_METRICS] DELETE HOME SCREEN METRIC';
export const DELETE_HOME_SCREEN_METRIC_COMPLETED = '[HOME_SCREEN_METRICS] DELETE HOME SCREEN METRIC COMPLETED';
export const CLEAR_TO_STATE = '[HOME_SCREEN_METRICS] CLEAR TO STATE';
export const TREE_LIST_LOADING = '[HOME_SCREEN_METRICS] TREE LIST LOADING';
export const TREE_LIST_LOADED = '[HOME_SCREEN_METRICS] TREE_LIST LOADED';
export const UPDATE_HOME_SCREEN_METRIC = '[HOME_SCREEN_METRICS] UPDATE HOME SCREEN METRIC';
export const UPDATE_HOME_SCREEN_METRIC_COMPLETED = '[HOME_SCREEN_METRICS] UPDATE HOME SCREEN METRIC COMPLETED';
export const CREATE_HOME_SCREEN_METRIC = '[HOME_SCREEN_METRICS] CREATE HOME SCREEN METRIC';
export const CREATE_HOME_SCREEN_METRIC_COMPLETED = '[HOME_SCREEN_METRICS] CREATE HOME SCREEN METRIC COMPLETED';
export const HOME_SCREEN_METRIC_INFORMATION_LOADING = '[HOME_SCREEN_METRICS] HOME SCREEN METRIC INFORMATION LOADING';
export const HOME_SCREEN_METRIC_INFORMATION_LOADED = '[HOME_SCREEN_METRICS] HOME SCREEN METRIC INFORMATION LOADED';

export class HomeScreenMetricsDataLoading implements Action {
  readonly type = HOME_SCREEN_METRICS_DATA_LOADING;

  constructor(public payload: PaginationInterface) {}
}

export class StartHomeScreenMetricsDataLoaded implements Action {
  readonly type = START_HOME_SCREEN_METRICS_DATA_LOADED;

  constructor(public payload: GetManyHomeScreenMetricCrudResponse) {}
}

export class CopyHomeScreenMetricLoading implements Action {
  readonly type = COPY_HOME_SCREEN_METRIC_LOADING;

  constructor(public payload: HomeScreenMetricSetInterface) {}
}

export class CopyHomeScreenMetricLoaded implements Action {
  readonly type = COPY_HOME_SCREEN_METRIC_LOADED;

  constructor(public payload: GetOneHomeScreenMetricCrudResponse) {}
}

export class DeleteHomeScreenMetric implements Action {
  readonly type = DELETE_HOME_SCREEN_METRIC;

  constructor(public id: number) {}
}

export class DeleteHomeScreenMetricCompleted implements Action {
  readonly type = DELETE_HOME_SCREEN_METRIC_COMPLETED;

  constructor(public payload: GetOneHomeScreenMetricCrudResponse) {}
}

export class ClearToState implements Action {
  readonly type = CLEAR_TO_STATE;
}

export class HomeScreenMetricSetTreeListLoading implements Action {
  readonly type = TREE_LIST_LOADING;

  constructor(public metricSet: HomeScreenMetricSetInterface) {}
}

export class HomeScreenMetricSetTreeListLoaded implements Action {
  readonly type = TREE_LIST_LOADED;

  constructor(public payload: GetSitesCrudResponse) {}
}

export class UpdateHomeScreenMetric implements Action {
  readonly type = UPDATE_HOME_SCREEN_METRIC;

  constructor(public id: number, public payload: HomeScreenMetricSetInterface) {}
}

export class UpdateHomeScreenMetricCompleted implements Action {
  readonly type = UPDATE_HOME_SCREEN_METRIC_COMPLETED;

  constructor(public payload: GetOneHomeScreenMetricCrudResponse) {}
}

export class CreateHomeScreenMetric implements Action {
  readonly type = CREATE_HOME_SCREEN_METRIC;

  constructor(public payload: HomeScreenMetricSetInterface) {}
}

export class CreateHomeScreenMetricCompleted implements Action {
  readonly type = CREATE_HOME_SCREEN_METRIC_COMPLETED;

  constructor(public payload: GetOneHomeScreenMetricCrudResponse) {}
}

export class HomeScreenMetricInformationLoading implements Action {
  readonly type = HOME_SCREEN_METRIC_INFORMATION_LOADING;

  constructor(public id: number) {}
}

export class StartHomeScreenMetricInformationLoaded implements Action {
  readonly type = HOME_SCREEN_METRIC_INFORMATION_LOADED;

  constructor(public payload: GetOneHomeScreenMetricCrudResponse) {}
}

export class FetchError implements Action {
  readonly type = HomeActionTypes.FetchError;

  constructor(public payload: any[]) {}
}

export type HomeScreenMetricSettingsActions =
  | HomeScreenMetricsDataLoading
  | StartHomeScreenMetricsDataLoaded
  | CopyHomeScreenMetricLoading
  | CopyHomeScreenMetricLoaded
  | DeleteHomeScreenMetric
  | DeleteHomeScreenMetricCompleted
  | ClearToState
  | HomeScreenMetricSetTreeListLoading
  | HomeScreenMetricSetTreeListLoaded
  | UpdateHomeScreenMetric
  | UpdateHomeScreenMetricCompleted
  | CreateHomeScreenMetric
  | CreateHomeScreenMetricCompleted
  | HomeScreenMetricInformationLoading
  | StartHomeScreenMetricInformationLoaded;
