import { ProductsStateInterface } from './products.model';
import * as Actions from './products.actions';

export const productState: ProductsStateInterface = {
  productsLoading: false,
  productsLoaded: false,
  productsSiteDataLoading: false,
  productsSiteDataLoaded: false,
  siteData: [],
  productsData: null,
  fileDownloadStatus: false,
  excelUploadResult: [],
  isUploadExcelLoading: false,
  isUploadExcelLoaded: false,
  productsDeleteLoading: false,
  productsDeleteLoaded: false,
  productsCustomerDataLoading: false,
  productsCustomerDataLoaded: false,
  customerData: null,
  addProductLoading: false,
  addProductLoaded: false,
  editProductLoading: false,
  editProductLoaded: false,
  bulkEditProductLoading: false,
  bulkEditProductLoaded: false,
  filters: null,
  tableSettings: [],
};

export function productReducer(
  state: ProductsStateInterface = productState,
  action: Actions.ProductsActions,
): ProductsStateInterface {
  switch (action.type) {
    case Actions.PRODUCTS_SITES_DATA_LOADING:
      return {
        ...state,
        ...{
          productsSiteDataLoading: true,
          productsSiteDataLoaded: false,
        },
      };
    case Actions.PRODUCTS_SITES_DATA_LOADED:
      return {
        ...state,
        ...{
          siteData: action.payload,
          productsSiteDataLoading: false,
          productsSiteDataLoaded: true,
        },
      };
    case Actions.DOWNLOAD_PRODUCT_EXCEL:
      return {
        ...state,
        ...{
          fileDownloadStatus: true,
        },
      };
    case Actions.DOWNLOAD_PRODUCT_EXCEL_COMPLETED:
      return {
        ...state,
        ...{
          fileDownloadStatus: false,
        },
      };
    case Actions.UPLOAD_PRODUCT_EXCEL:
      return {
        ...state,
        ...{
          isUploadExcelLoading: true,
          isUploadExcelLoaded: false,
        },
      };
    case Actions.UPLOAD_PRODUCT_EXCEL_COMPLETED:
      return {
        ...state,
        ...{
          excelUploadResult: action.payload,
          isUploadExcelLoading: false,
          isUploadExcelLoaded: true,
        },
      };

    case Actions.SET_SELECTED_FILTERS:
      return {
        ...state,
        filters: action.filters,
      };

    case Actions.PRODUCTS_DATA_LOADING:
      return {
        ...state,
        ...{
          productsLoading: true,
          productsLoaded: false,
        },
      };
    case Actions.PRODUCTS_DATA_LOADED:
      return {
        ...state,
        ...{
          productsData: action.payload,
          productsLoading: false,
          productsLoaded: true,
        },
      };
    case Actions.DELETE_PRODUCTS:
      return {
        ...state,
        productsDeleteLoading: true,
        productsDeleteLoaded: false,
      };
    case Actions.DELETE_PRODUCTS_COMPLETED:
      return {
        ...state,
        productsDeleteLoading: false,
        productsDeleteLoaded: true,
      };
    case Actions.PRODUCTS_CUSTOMER_DATA_LOADING:
      return {
        ...state,
        productsCustomerDataLoading: true,
        productsCustomerDataLoaded: false,
      };
    case Actions.PRODUCTS_CUSTOMER_DATA_LOADED:
      return {
        ...state,
        customerData: action.payload,
        productsCustomerDataLoading: false,
        productsCustomerDataLoaded: true,
      };
    case Actions.ADD_PRODUCT:
      return {
        ...state,
        addProductLoading: true,
        addProductLoaded: false,
      };
    case Actions.ADD_PRODUCT_COMPLETED:
      return {
        ...state,
        addProductLoading: false,
        addProductLoaded: true,
      };
    case Actions.EDIT_PRODUCT:
      return {
        ...state,
        editProductLoading: true,
        editProductLoaded: false,
      };
    case Actions.EDIT_PRODUCT_COMPLETED:
      return {
        ...state,
        editProductLoading: false,
        editProductLoaded: true,
      };
    case Actions.BULK_EDIT_PRODUCT:
      return {
        ...state,
        bulkEditProductLoading: true,
        bulkEditProductLoaded: false,
      };
    case Actions.BULK_EDIT_PRODUCT_COMPLETED:
      return {
        ...state,
        bulkEditProductLoading: false,
        bulkEditProductLoaded: true,
      };
    case Actions.SET_TABLE_SETTINGS:
      return {
        ...state,
        tableSettings: action.payload,
      };
    default:
      return state;
  }
}
