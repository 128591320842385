import { FilterRootCauseGroupAction, FilterRootCauseGroupActionGroup } from './root-cause-group.actions';
import { RootCauseGroupCRUDInterface } from '../../../shared/component/filter/filter.class';

export interface FilterRootCauseGroupState {
  isLoading: boolean;
  isLoaded: boolean;
  data: RootCauseGroupCRUDInterface[];
}

export const initialFilterRootCauseGroupState: FilterRootCauseGroupState = {
  isLoaded: false,
  isLoading: false,
  data: [],
};

export function filterRootCauseGroupReducer(
  state = initialFilterRootCauseGroupState,
  action: FilterRootCauseGroupAction,
): FilterRootCauseGroupState {
  switch (action.type) {
    case FilterRootCauseGroupActionGroup.StartLoadFilterRootCauseGroups:
      return { ...state, isLoading: true };

    case FilterRootCauseGroupActionGroup.FilterRootCauseGroupsLoaded:
      return { ...state, data: action.data, isLoaded: true, isLoading: false };

    default:
      return state;
  }
}
