import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as ObjectActions from './task-groups.actions';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../oee.reducer';
import * as AppActions from '../app/actions';
import { TaskGroupsService } from '../../shared/service/task-groups/task-groups.service';
import {
  CreateTaskGroupResponseInterface,
  DeleteTaskGroupResponseInterface,
  MoveTaskResponseInterface,
  SiteDropdownDataResponseInterface,
  TaskGroupsAndRemainingTasksDataResponseInterface,
  UpdateTaskGroupResponseInterface,
  ResponseInterface,
  SiteDataInterface,
  TaskGroupsDataInterface,
  TaskDataInterface,
} from './task-groups.model';
import { GetManyResponseInterface } from '../../shared/model/interface/crud-response-interface.model';
import { EntityTranslatorService } from 'src/app/shared/service/entity-translator/entity-translator.service';

@Injectable()
export class TaskGroupsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<oeeAppReducer.OeeAppState>,
    private taskGroupsService: TaskGroupsService,
    private translatorService: EntityTranslatorService,
  ) {}

  private defaultResponse: ResponseInterface<null> = {
    success: false,
    data: null,
  };

  $loadSiteDropdownData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.TaskGroupsActionTypes.LoadSiteDropdownData),
      switchMap(() => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.taskGroupsService.loadSiteDropdownData().pipe(
          switchMap((response: GetManyResponseInterface<SiteDataInterface>) => {
            return of(new AppActions.HideLoader(), new ObjectActions.SiteDropdownDataLoaded(response));
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  $loadTaskGroupsAndRemainingTasksData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.TaskGroupsActionTypes.LoadTaskGroupsAndRemainingTasksData),
      switchMap((objectActions: ObjectActions.LoadTaskGroupsAndRemainingTasksData) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.taskGroupsService.loadTaskGroupsAndRemainingTasksData(objectActions.siteId).pipe(
          switchMap((response: TaskGroupsAndRemainingTasksDataResponseInterface) => {
            const result = response.hasOwnProperty('success') ? response : this.defaultResponse;

            result.data?.taskGroups.forEach((taskGroupsData: TaskGroupsDataInterface) => {
              taskGroupsData.tasks?.forEach((taskData: TaskDataInterface) => {
                this.translatorService.translate(taskData);
              });
            });

            result.data?.remainingTasks.forEach((taskData: TaskDataInterface) => {
              this.translatorService.translate(taskData);
            });

            return of(new ObjectActions.TaskGroupsAndRemainingTasksDataLoaded(result), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
    ),
  );

  $deleteTaskGroup = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.TaskGroupsActionTypes.DeleteTaskGroup),
      switchMap((objectActions: ObjectActions.DeleteTaskGroup) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.taskGroupsService.deleteTaskGroup(objectActions.id).pipe(
          switchMap((response: DeleteTaskGroupResponseInterface) => {
            const result = response.hasOwnProperty('success') ? response : this.defaultResponse;

            return of(
              new ObjectActions.DeleteTaskGroupCompleted(result, objectActions.id),
              new AppActions.HideLoader(),
            );
          }),
          catchError((error) => {
            return of(
              new ObjectActions.DeleteTaskGroupCompleted(this.defaultResponse, objectActions.id),
              new ObjectActions.FetchError(error),
              new AppActions.HideLoader(),
            );
          }),
        );
      }),
    ),
  );

  $updateTaskGroup = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.TaskGroupsActionTypes.UpdateTaskGroup),
      switchMap((objectActions: ObjectActions.UpdateTaskGroup) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.taskGroupsService.updateTaskGroup(objectActions.id, objectActions.name).pipe(
          switchMap((response: UpdateTaskGroupResponseInterface) => {
            const result = response.hasOwnProperty('success') ? response : this.defaultResponse;

            return of(new ObjectActions.UpdateTaskGroupCompleted(result), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(
              new ObjectActions.UpdateTaskGroupCompleted(this.defaultResponse),
              new ObjectActions.FetchError(error),
              new AppActions.HideLoader(),
            );
          }),
        );
      }),
    ),
  );

  $createTaskGroup = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.TaskGroupsActionTypes.CreateTaskGroup),
      switchMap((objectActions: ObjectActions.CreateTaskGroup) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.taskGroupsService.createTaskGroup(objectActions.siteId, objectActions.name).pipe(
          switchMap((response: CreateTaskGroupResponseInterface) => {
            const result = response.hasOwnProperty('success') ? response : this.defaultResponse;

            return of(new ObjectActions.CreateTaskGroupCompleted(result), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(
              new ObjectActions.CreateTaskGroupCompleted(this.defaultResponse),
              new ObjectActions.FetchError(error),
              new AppActions.HideLoader(),
            );
          }),
        );
      }),
    ),
  );

  $moveTask = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.TaskGroupsActionTypes.MoveTask),
      switchMap((objectActions: ObjectActions.MoveTask) => {
        this.store.dispatch(new AppActions.ShowTopLoader());

        return this.taskGroupsService
          .moveTask(objectActions.siteId, objectActions.taskGroupId, objectActions.name, objectActions.activityId)
          .pipe(
            switchMap((response: MoveTaskResponseInterface) => {
              const result = response.hasOwnProperty('success') ? response : this.defaultResponse;

              return of(
                new ObjectActions.MoveTaskCompleted(
                  result,
                  objectActions.previousContainerData,
                  objectActions.currentContainerData,
                  objectActions.previousIndex,
                  objectActions.currentIndex,
                ),
                new AppActions.HideTopLoader(),
              );
            }),
            catchError((error) => {
              return of(
                new ObjectActions.MoveTaskCompleted(
                  this.defaultResponse,
                  objectActions.previousContainerData,
                  objectActions.currentContainerData,
                  objectActions.previousIndex,
                  objectActions.currentIndex,
                ),
                new ObjectActions.FetchError(error),
                new AppActions.HideTopLoader(),
              );
            }),
          );
      }),
    ),
  );
}
