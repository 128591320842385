import { CustomersStateInterface } from './customers.model';
import * as Actions from './customers.actions';

export const customersState: CustomersStateInterface = {
  customersDataLoaded: false,
  customersDataLoading: false,
  customersData: null,
  addCustomerLoading: false,
  addCustomerLoaded: false,
  editCustomerLoading: false,
  editCustomerLoaded: false,
  bulkEditCustomerLoading: false,
  bulkEditCustomerLoaded: false,
  customersDeleteLoading: false,
  customersDeleteLoaded: false,
};

export function customerReducer(
  state: CustomersStateInterface = customersState,
  action: Actions.CustomersActions,
): CustomersStateInterface {
  switch (action.type) {
    case Actions.CUSTOMERS_DATA_LOADING:
      return {
        ...state,
        ...{
          customersDataLoading: true,
          customersDataLoaded: false,
        },
      };
    case Actions.CUSTOMERS_DATA_LOADED:
      return {
        ...state,
        ...{
          customersData: action.payload,
          customersDataLoading: false,
          customersDataLoaded: true,
        },
      };
    case Actions.ADD_CUSTOMER:
      return {
        ...state,
        addCustomerLoading: true,
        addCustomerLoaded: false,
      };
    case Actions.ADD_CUSTOMER_COMPLETED:
      return {
        ...state,
        addCustomerLoading: false,
        addCustomerLoaded: true,
      };
    case Actions.EDIT_CUSTOMER:
      return {
        ...state,
        editCustomerLoading: true,
        editCustomerLoaded: false,
      };
    case Actions.EDIT_CUSTOMER_COMPLETED:
      return {
        ...state,
        editCustomerLoading: false,
        editCustomerLoaded: true,
      };
    case Actions.BULK_EDIT_CUSTOMER:
      return {
        ...state,
        bulkEditCustomerLoading: true,
        bulkEditCustomerLoaded: false,
      };
    case Actions.BULK_EDIT_CUSTOMER_COMPLETED:
      return {
        ...state,
        bulkEditCustomerLoading: false,
        bulkEditCustomerLoaded: true,
      };
    case Actions.DELETE_CUSTOMERS:
      return {
        ...state,
        customersDeleteLoading: true,
        customersDeleteLoaded: false,
      };
    case Actions.DELETE_CUSTOMERS_COMPLETED:
      return {
        ...state,
        customersDeleteLoading: false,
        customersDeleteLoaded: true,
      };
    default:
      return state;
  }
}
