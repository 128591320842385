import { filter, takeUntil, map } from 'rxjs/operators';
import {
  Component,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  CronJobsConfig,
  CronJobsFrequency,
  CronJobsValidationConfig,
  OptionType,
  ScwCronInputRule,
} from '../scw-mat-cron-input.model';
import { ControlValueAccessor, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { CronPosixService } from '../services/cron-posix.service';
import { CronDataService } from '../services/cron-data.service';
import { DropdownOptionInterface } from '../../scw-mat-select/scw-mat-select.model';
import { HelperService } from '../../../../service/helper.service';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import * as cronstrue from 'cronstrue/i18n';

@Component({
  selector: 'scw-mat-cron-input',
  templateUrl: './scw-mat-cron-input.component.html',
  styleUrls: ['./scw-mat-cron-input.component.scss'],
  providers: [
    CronPosixService,
    CronDataService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ScwMatCronInputComponent),
      multi: true,
    },
  ],
})
export class ScwMatCronInputComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {
  @Input() inputModel: string = '';
  @Input() hasErrors: boolean = null;
  @Input() isValid: boolean = false;
  @Input() label: string = null;
  @Input() hint: string = null;
  @Input() errorText: string;
  @Input() rules: ScwCronInputRule[];
  @Input() noPadding: boolean = false;
  @Input() containerClass: string = '';
  @Input() block: boolean = false;
  @Input() config: CronJobsConfig = {
    multiple: true,
    option: {
      minute: false,
    },
    bootstrap: true,
  };
  @Input() validate: CronJobsValidationConfig;
  @Input() withFrame: boolean = false;
  @Input() dataPropertyKey?: string;
  @Output() inputModelChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() isValidChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onKeyup: EventEmitter<string> = new EventEmitter<string>();
  @Output() onKeyupEnter: EventEmitter<string> = new EventEmitter<string>();

  public control: UntypedFormControl = new UntypedFormControl();
  public isDisabled = false;
  public baseFrequencyData: DropdownOptionInterface[];
  public daysOfWeekData: DropdownOptionInterface[] = [];
  public daysOfMonthData: DropdownOptionInterface[] = [];
  public monthsData: DropdownOptionInterface[] = [];
  public hoursData: DropdownOptionInterface[] = [];
  public minutesData: DropdownOptionInterface[] = [];
  public onChange: any = (cronValue: string) => {};
  public onTouched: any = () => {};
  public cronJobsForm: UntypedFormGroup;
  public formData;
  private isPatching = false;
  private readonly unSubscribe = new Subject();
  private cronPosixService: CronPosixService;
  public humanReadableExpression: string = '';
  public isCronIncomplete: boolean;

  constructor(
    private readonly cronDataService: CronDataService,
    private readonly injector: Injector,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly helperService: HelperService,
    private readonly translate: TranslateService,
  ) {
    this.cronJobsForm = this.formBuilder.group({
      baseFrequency: -1,
      daysOfWeek: [],
      daysOfMonth: [],
      months: [],
      hours: [],
      minutes: [],
    });

    this.config = this.cronDataService.getConfig();
    this.validate = this.cronDataService.getValidate();
    this.setService();
  }

  public ngOnInit(): void {
    let baseFreq = this.cronDataService.baseFrequency;

    if (this.config.option) {
      baseFreq = baseFreq.filter(
        (x) => !(this.config.option.hasOwnProperty(OptionType[x.type]) && !this.config.option[OptionType[x.type]]),
      );
    }
    this.baseFrequencyData = baseFreq.map((item) => {
      return { id: item.value, name: String(item.label) };
    });

    this.daysOfMonthData = this.cronDataService.daysOfMonth.map((item) => {
      return { id: item.value, name: String(item.label) };
    });

    this.daysOfWeekData = this.cronDataService.getDaysOfWeek().map((item) => {
      return { id: item.value, name: String(item.label) };
    });

    this.monthsData = this.cronDataService.months.map((item) => {
      return { id: item.value, name: String(item.label) };
    });

    this.hoursData = this.cronDataService.hours.map((item) => {
      return { id: item.value, name: String(item.label) };
    });

    this.minutesData = this.cronDataService.minutes.map((item) => {
      return { id: item.value, name: String(item.label) };
    });

    this.formData = {
      form: {
        baseFrequency: {
          isEnabled: true,
          value: null,
          rules: [],
        },
        daysOfWeek: {
          isEnabled: true,
          value: null,
          rules: [],
        },
        daysOfMonth: {
          isEnabled: true,
          value: null,
          rules: [],
        },
        months: {
          isEnabled: true,
          value: null,
          rules: [],
        },
        hours: {
          isEnabled: true,
          value: null,
          rules: [],
        },
        minutes: {
          isEnabled: true,
          value: null,
          rules: [],
        },
      },
      dropdownOptions: {
        baseFrequencyData: this.baseFrequencyData,
        daysOfWeekData: this.daysOfWeekData,
        daysOfMonthData: this.daysOfMonthData,
        monthsData: this.monthsData,
        hoursData: this.hoursData,
        minutesData: this.minutesData,
      },
      rules: {
        baseFrequency: [this.helperService.getRequiredFormRule()],
        daysOfWeek: [this.helperService.getRequiredFormRule()],
        daysOfMonth: [this.helperService.getRequiredFormRule()],
        months: [this.helperService.getRequiredFormRule()],
        hours: [this.helperService.getRequiredFormRule()],
        minutes: [this.helperService.getRequiredFormRule()],
      },
    };

    this.cronJobsForm.valueChanges
      .pipe(
        takeUntil(this.unSubscribe),
        filter(() => !this.isPatching),
        map((freq: CronJobsFrequency) => {
          freq.baseFrequency = +freq.baseFrequency;

          if (this.cronJobsForm.value.baseFrequency !== -1) {
            this.formData.form.baseFrequency.value = _.filter(
              this.baseFrequencyData,
              (item) => +this.cronJobsForm.value.baseFrequency === item.id,
            );
            this.formData.form.daysOfWeek.value = _.filter(this.daysOfWeekData, (item) =>
              _.includes(this.cronJobsForm.value.daysOfWeek, item.id),
            );

            this.formData.form.daysOfMonth.value = _.filter(this.daysOfMonthData, (item) =>
              _.includes(this.cronJobsForm.value.daysOfMonth, item.id),
            );

            this.formData.form.months.value = _.filter(this.monthsData, (item) =>
              _.includes(this.cronJobsForm.value.months, item.id),
            );

            this.formData.form.hours.value = _.filter(this.hoursData, (item) =>
              _.includes(this.cronJobsForm.value.hours, item.id),
            );

            this.formData.form.minutes.value = _.filter(this.minutesData, (item) =>
              _.includes(this.cronJobsForm.value.minutes, item.id),
            );
          }

          return freq;
        }),
      )
      .subscribe((values: CronJobsFrequency) => {
        if (values.baseFrequency === -1) {
          const defaultFrequencyValues = this.cronPosixService.getDefaultFrequency();
          this.cronJobsForm.patchValue(defaultFrequencyValues, { emitEvent: false });
        }

        this.onChange(this.cronPosixService.setCron(values));
      });

    this.cronJobsForm.patchValue(this.cronPosixService.fromCron(this.inputModel));
  }

  public onBlur(): void {
    this.onTouched();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['config']) {
      this.config = this.cronDataService.getConfig(<CronJobsConfig>changes['config'].currentValue);
      Promise.resolve().then(() => {
        if (!changes['config'].previousValue || !changes['config'].currentValue) {
          this.daysOfWeekData = this.cronDataService.getDaysOfWeek().map((item) => {
            return { id: item.value, name: String(item.label) };
          });
          this.cronJobsForm.patchValue({ daysOfWeek: this.daysOfWeekData[0].id });
        }
      });

      this.setService();
    }

    if (changes['validate']) {
      this.validate = this.cronDataService.getValidate(<CronJobsValidationConfig>changes['validate'].currentValue);
    }

    this.isCronIncomplete = this.getCronIsIncomplete();
    this.humanReadableExpression = this.inputModel ? cronstrue.default.toString(this.inputModel) : null;

    if (!this.isCronIncomplete) {
      this.clearErrorMessage();
    }
  }

  public setService(): void {
    this.cronPosixService = this.injector.get(CronPosixService);
  }

  public writeValue(cronValue: string): void {
    this.isPatching = true;
    let valueToPatch: CronJobsFrequency;
    const patchCron = cronValue || this.inputModel;
    valueToPatch = this.cronPosixService.getDefaultFrequency();

    if (patchCron) {
      valueToPatch = this.cronPosixService.fromCron(patchCron);
    }

    Promise.resolve().then(() => {
      this.cronJobsForm.patchValue(valueToPatch);
      this.isPatching = false;
    });
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;

    if (this.isDisabled) {
      this.cronJobsForm.disable();
    } else {
      this.cronJobsForm.enable();
    }
  }

  public onCronFieldChanged(value, field) {
    const itemIds = value.map((item) => item.id);
    if (field === 'baseFrequency') {
      if (itemIds.length === 1) {
        this.cronJobsForm.patchValue({ baseFrequency: itemIds[0] });
      } else if (itemIds.length === 0) {
        this.cronJobsForm.patchValue(this.cronPosixService.getDefaultFrequency());
      }
    } else {
      this.cronJobsForm.patchValue({ [field]: itemIds });
    }

    this.inputModel = this.cronPosixService.setCron(this.cronJobsForm.value);
    this.inputModelChange.emit(this.inputModel);
  }

  private checkRules(): void {
    if (this.rules.length === 0) {
      this.isValidEqualizer(true);
      return;
    }

    this.isCronIncomplete = this.getCronIsIncomplete();

    if (this.isCronIncomplete) {
      this.showErrorMessage(this.translate.instant('scwMatForm.validation.required'));
      return;
    }

    this.clearErrorMessage();
  }

  private getCronIsIncomplete(): boolean {
    if (this.cronJobsForm.value.baseFrequency === -1) {
      return true;
    }

    if (this.cronJobsForm.value.baseFrequency === 4 && this.cronJobsForm.value.daysOfWeek.length === 0) {
      return true;
    }

    if (this.cronJobsForm.value.baseFrequency >= 5 && this.cronJobsForm.value.daysOfMonth.length === 0) {
      return true;
    }

    if (this.cronJobsForm.value.baseFrequency === 6 && this.cronJobsForm.value.months.length === 0) {
      return true;
    }

    if (this.cronJobsForm.value.baseFrequency >= 3 && this.cronJobsForm.value.hours.length === 0) {
      return true;
    }

    return this.cronJobsForm.value.baseFrequency >= 2 && this.cronJobsForm.value.minutes.length === 0;
  }

  private isValidEqualizer(isValid: boolean): void {
    this.isValid = isValid;
    this.isValidChange.emit(this.isValid);
  }

  private showErrorMessage(message: string): void {
    this.isValidEqualizer(false);
    this.hasErrors = true;
    this.errorText = message ? message : '';
  }

  public clearErrorMessage(): void {
    this.isValidEqualizer(true);
    this.hasErrors = false;
    this.errorText = null;
  }

  public reset(): void {
    this.inputModel = '';
    this.clearErrorMessage();
  }

  public ngOnDestroy(): void {
    this.unSubscribe.next(undefined);
    this.unSubscribe.complete();
  }
}
