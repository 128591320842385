import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScwMatWizardComponent } from './scw-mat-wizard.component';
import { ScwMatWizardStepModule } from '../scw-mat-wizard-step/scw-mat-wizard-step.module';

@NgModule({
  imports: [CommonModule, ScwMatWizardStepModule],
  exports: [ScwMatWizardComponent],
  declarations: [ScwMatWizardComponent],
})
export class ScwMatWizardModule {}
