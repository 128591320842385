import { Action } from '@ngrx/store';
import { LineInterface, SiteInterface } from './site-line-selection.model';

export enum SiteLineSelectionActionTypes {
  FETCH_ERROR = '[SiteLineSelection] Fetch Error',
  LOAD_SITES_LINES = '[SiteLineSelection] Load Sites and Lines',
  SITES_OF_SITE_LINE_SELECTION_LOADED = '[SiteLineSelection] Loaded sites of site line selection',
  LOAD_LINES_OF_SITE_LINE_SELECTION = '[SiteLineSelection] Load lines of site line selection',
  SITES_LINES_LOADED = '[SiteLineSelection] Sites and Lines loaded',
}

export class FetchDataError implements Action {
  readonly type = SiteLineSelectionActionTypes.FETCH_ERROR;
  constructor(public payload: any[]) {}
}
export class LoadSitesLines implements Action {
  readonly type = SiteLineSelectionActionTypes.LOAD_SITES_LINES;
}

export class SiteOfSiteLineSelectionLoaded implements Action {
  readonly type = SiteLineSelectionActionTypes.SITES_OF_SITE_LINE_SELECTION_LOADED;
}

export class LoadLinesOfSiteLineSelection implements Action {
  readonly type = SiteLineSelectionActionTypes.LOAD_LINES_OF_SITE_LINE_SELECTION;
  constructor(public sites: SiteInterface[]) {}
}

export class SitesLinesLoaded implements Action {
  readonly type = SiteLineSelectionActionTypes.SITES_LINES_LOADED;
  constructor(public linesAndLineTypes: LineInterface[]) {}
}

export type SiteLineSelectionActions =
  | LoadSitesLines
  | SiteOfSiteLineSelectionLoaded
  | LoadLinesOfSiteLineSelection
  | SitesLinesLoaded
  | FetchDataError;
