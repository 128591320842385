import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { OeeAppState } from './store/oee.reducer';
import { take } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private lineId$: number;
  private siteId$: number;
  private headers: HttpHeaders;

  constructor(private store: Store<OeeAppState>) {
    this.store.select('user').subscribe((state) => {
      this.lineId$ = state.lineId;
      this.siteId$ = state.siteId;
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.lineId$ && this.siteId$) {
      this.headers = req.headers
        .set('site-id', this.siteId$.toString())
        .set('line-id', this.lineId$.toString());
    }

    const clonedRequest = req.clone({
      headers: this.headers,
      withCredentials: true,
    });
    return next.handle(clonedRequest);
  }
}
