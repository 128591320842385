import { Action } from '@ngrx/store';
import {
  IKpiViewLaborHoursTrend,
  IKpiViewOeeAndGoodCountTrend,
  IKpiViewParameters,
  IKpiViewWidgetOeeData, IKpiViewWorkOrderCountAndTarget,
  IKpiViewWorkOrderTrend,
} from './kpi-view.model';
import { TabRowInterface } from '../../../shared/component/side-config-bar/side-config-bar.model';

export const KPI_VIEW_WIDGET_OEE_DATA_LOADING = '[KPI_VIEW] KPI VIEW WIDGET OEE DATA LOADING';
export const KPI_VIEW_WIDGET_OEE_DATA_LOADED = '[KPI_VIEW] KPI VIEW WIDGET OEE DATA LOADED';
export const KPI_VIEW_WIDGET_OEE_DATA_CLEAR = '[KPI_VIEW] KPI VIEW WIDGET OEE DATA CLEAR';
export const KPI_VIEW_WIDGET_WORK_ORDER_COUNT_DATA_LOADING = '[KPI_VIEW] KPI VIEW WIDGET WORK ORDER COUNT DATA LOADING';
export const KPI_VIEW_WIDGET_WORK_ORDER_COUNT_DATA_LOADED = '[KPI_VIEW] KPI VIEW WIDGET WORK ORDER COUNT DATA LOADED';
export const KPI_VIEW_WIDGET_WORK_ORDER_COUNT_DATA_CLEAR = '[KPI_VIEW] KPI VIEW WIDGET WORK ORDER COUNT DATA CLEAR';
export const KPI_VIEW_CHART_OEE_DATA_LOADING = '[KPI_VIEW] KPI VIEW CHART OEE DATA LOADING';
export const KPI_VIEW_CHART_OEE_DATA_LOADED = '[KPI_VIEW] KPI VIEW CHART OEE DATA LOADED';
export const KPI_VIEW_CHART_OEE_DATA_CLEAR = '[KPI_VIEW] KPI VIEW CHART OEE DATA CLEAR';
export const KPI_VIEW_CHART_WORK_ORDER_COUNT_DATA_LOADING = '[KPI_VIEW] KPI VIEW CHART WORK ORDER COUNT DATA LOADING';
export const KPI_VIEW_CHART_WORK_ORDER_COUNT_DATA_LOADED = '[KPI_VIEW] KPI VIEW CHART WORK ORDER COUNT DATA LOADED';
export const KPI_VIEW_CHART_WORK_ORDER_COUNT_DATA_CLEAR = '[KPI_VIEW] KPI VIEW CHART WORK ORDER COUNT DATA CLEAR';
export const KPI_VIEW_LABOR_HOURS_DATA_LOADING = '[KPI_VIEW] KPI VIEW LABOR HOURS DATA LOADING';
export const KPI_VIEW_LABOR_HOURS_DATA_LOADED = '[KPI_VIEW] KPI VIEW LABOR HOURS DATA LOADED';
export const KPI_VIEW_LABOR_HOURS_DATA_CLEAR = '[KPI_VIEW] KPI VIEW LABOR HOURS DATA CLEAR';
export const SET_TABLE_SETTINGS = '[KPI_VIEW] SET SELECTED FILTERS';
export const KPI_VIEW_FETCH_ERROR = '[KPI_VIEW] Fetch Error';
export const KPI_VIEW_CLEAR_CHILD_COMPONENT_STATES = '[KPI_VIEW] KPI VIEW CLEAR CHILD COMPONENT STATES';

export class KpiViewWidgetOeeDataLoading implements Action {
  readonly type = KPI_VIEW_WIDGET_OEE_DATA_LOADING;

  constructor(public parameters: IKpiViewParameters) {}
}

export class KpiViewWidgetOeeDataLoaded implements Action {
  readonly type = KPI_VIEW_WIDGET_OEE_DATA_LOADED;

  constructor(public data: IKpiViewWidgetOeeData) {}
}

export class KpiViewWidgetOeeDataClear implements Action {
  readonly type = KPI_VIEW_WIDGET_OEE_DATA_CLEAR;
}

export class KpiViewWidgetWorkOrderCountDataLoading implements Action {
  readonly type = KPI_VIEW_WIDGET_WORK_ORDER_COUNT_DATA_LOADING;

  constructor(public parameters: IKpiViewParameters) {}
}

export class KpiViewWidgetWorkOrderCountDataLoaded implements Action {
  readonly type = KPI_VIEW_WIDGET_WORK_ORDER_COUNT_DATA_LOADED;

  constructor(public data: IKpiViewWorkOrderCountAndTarget) {}
}

export class KpiViewWidgetWorkOrderCountDataClear implements Action {
  readonly type = KPI_VIEW_WIDGET_WORK_ORDER_COUNT_DATA_CLEAR;
}

export class KpiViewChartOeeDataLoading implements Action {
  readonly type = KPI_VIEW_CHART_OEE_DATA_LOADING;

  constructor(public parameters: IKpiViewParameters) {}
}

export class KpiViewChartOeeDataLoaded implements Action {
  readonly type = KPI_VIEW_CHART_OEE_DATA_LOADED;

  constructor(public data: IKpiViewOeeAndGoodCountTrend) {}
}

export class KpiViewChartOeeDataClear implements Action {
  readonly type = KPI_VIEW_CHART_OEE_DATA_CLEAR;
}

export class KpiViewChartWorkOrderCountDataLoading implements Action {
  readonly type = KPI_VIEW_CHART_WORK_ORDER_COUNT_DATA_LOADING;

  constructor(public parameters: IKpiViewParameters) {}
}

export class KpiViewChartWorkOrderCountDataLoaded implements Action {
  readonly type = KPI_VIEW_CHART_WORK_ORDER_COUNT_DATA_LOADED;

  constructor(public data: IKpiViewWorkOrderTrend[]) {}
}

export class KpiViewChartWorkOrderCountDataClear implements Action {
  readonly type = KPI_VIEW_CHART_WORK_ORDER_COUNT_DATA_CLEAR;
}

export class KpiViewLaborHoursDataLoading implements Action {
  readonly type = KPI_VIEW_LABOR_HOURS_DATA_LOADING;

  constructor(public parameters: IKpiViewParameters) {}
}

export class KpiViewLaborHoursDataLoaded implements Action {
  readonly type = KPI_VIEW_LABOR_HOURS_DATA_LOADED;

  constructor(public data: IKpiViewLaborHoursTrend) {}
}

export class KpiViewLaborHoursDataClear implements Action {
  readonly type = KPI_VIEW_LABOR_HOURS_DATA_CLEAR;
}

export class SetTableSettings implements Action {
  readonly type = SET_TABLE_SETTINGS;

  constructor(public payload: TabRowInterface[], public setAsDefault: boolean) {}
}

export class KpiViewFetchError implements Action {
  readonly type = KPI_VIEW_FETCH_ERROR;

  constructor(public error: unknown) {}
}

export class KpiViewClearChildComponentStates implements Action {
  readonly type = KPI_VIEW_CLEAR_CHILD_COMPONENT_STATES;
}

export type KpiViewActions =
  | KpiViewWidgetOeeDataLoading
  | KpiViewWidgetOeeDataLoaded
  | KpiViewWidgetOeeDataClear
  | KpiViewWidgetWorkOrderCountDataLoading
  | KpiViewWidgetWorkOrderCountDataLoaded
  | KpiViewWidgetWorkOrderCountDataClear
  | KpiViewChartOeeDataLoading
  | KpiViewChartOeeDataLoaded
  | KpiViewChartOeeDataClear
  | KpiViewChartWorkOrderCountDataLoading
  | KpiViewChartWorkOrderCountDataLoaded
  | KpiViewChartWorkOrderCountDataClear
  | KpiViewLaborHoursDataLoading
  | KpiViewLaborHoursDataLoaded
  | KpiViewLaborHoursDataClear
  | SetTableSettings
  | KpiViewClearChildComponentStates
  | KpiViewFetchError;
