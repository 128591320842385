import { Action } from '@ngrx/store';
import { SelectedDataInterface, SearchableDropdownConfigurationSetInterface } from './searchable-dropdown.model';

export const SET_CONFIGURATION = '[SEARCHABLE DROPDOWN] Set number input configuration';
export const DATA_IS_SET = '[SEARCHABLE DROPDOWN] An item from a dropdown is selected';
export const SET_FORM_IS_SUBMITTED = '[SEARCHABLE DROPDOWN] The form of dropdown is submitted';
export const RESET_SEARCHABLE_DROPDOWN_STORE = '[SEARCHABLE DROPDOWN] Reset Searchable Dropdown Store';

export class SetConfiguration implements Action {
  readonly type = SET_CONFIGURATION;

  constructor(public configuration: SearchableDropdownConfigurationSetInterface) {}
}

export class DropdownDataIsSelected implements Action {
  readonly type = DATA_IS_SET;

  constructor(public selectedData: SelectedDataInterface) {}
}

export class SetFormIsSubmitted implements Action {
  readonly type = SET_FORM_IS_SUBMITTED;

  constructor(public newValue: boolean) {}
}

export class ResetSearchableDropdownStore implements Action {
  readonly type = RESET_SEARCHABLE_DROPDOWN_STORE;
}

export type SearchableDropdownActions =
  | SetConfiguration
  | DropdownDataIsSelected
  | SetFormIsSubmitted
  | ResetSearchableDropdownStore;
