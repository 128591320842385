import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { HelperService } from '../../../shared/service/helper.service';
import { Observable } from 'rxjs';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import {
  IMyReportMetricCreateRequestParameters,
  IMyReportMetricUpdateRequestParameters,
  IMyReportWithMetrics,
} from '../../../view/reports/my-reports/my-report-metrics/my-report-metrics.model';
import { IOeeCalculationRequest, IOeeCalculationResult } from '../work-order-performance/work-order-performance.model';

export enum EMyReportMetricsRoutes {
  BASE_MY_REPORT = 'my-report',
  BASE_MY_REPORT_METRICS = 'my-report-metrics',
  BULK_CREATE_MY_REPORT_METRICS = 'bulk-create-my-report-metrics',
  BULK_UPDATE_MY_REPORT_METRICS = 'bulk-update-my-report-metrics',
  BULK_DELETE_MY_REPORT_METRICS = 'bulk-delete-my-report-metrics',
  CALCULATE_OEE = 'calculate-oee',
}

const basePath: string = 'my-report-metrics';
const calculateOee: string = 'oee-calculation/calculate-oee';
const reportPath: string = 'my-reports';

@Injectable({
  providedIn: 'root',
})
export class MyReportsMetricsService {
  private readonly routes: Record<EMyReportMetricsRoutes, string> = {
    [EMyReportMetricsRoutes.BASE_MY_REPORT]: `${this.baseUrl}/${reportPath}`,
    [EMyReportMetricsRoutes.BASE_MY_REPORT_METRICS]: `${this.baseUrl}/${basePath}`,
    [EMyReportMetricsRoutes.BULK_CREATE_MY_REPORT_METRICS]: `${this.baseUrl}/${basePath}/bulk/create`,
    [EMyReportMetricsRoutes.BULK_UPDATE_MY_REPORT_METRICS]: `${this.baseUrl}/${basePath}/bulk/edit`,
    [EMyReportMetricsRoutes.BULK_DELETE_MY_REPORT_METRICS]: `${this.baseUrl}/${basePath}/bulk/delete`,
    [EMyReportMetricsRoutes.CALCULATE_OEE]: `${this.baseUrl}/${calculateOee}`,
  };

  constructor(
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly http: HttpClient,
    private readonly helperService: HelperService,
  ) {}

  public getMyReportWithMetrics(id: number): Observable<BaseOneResponseInterface<IMyReportWithMetrics>> {
    const params: HttpParams = this.helperService.insertGenericCrudRequestParameters({
      limit: 1,
      join: ['site', 'myReportMetrics'],
      sort: [{ column: 'myReportMetrics.createdAt', type: 'descending' }],
    });

    return this.http.get<BaseOneResponseInterface<IMyReportWithMetrics>>(
      `${this.routes[EMyReportMetricsRoutes.BASE_MY_REPORT]}/${id}`,
      {
        params,
      },
    );
  }

  public bulkCreateNewMyReportMetric(
    parameters: IMyReportMetricCreateRequestParameters[],
  ): Observable<BulkResponseDataInterface> {
    return this.http.post<BulkResponseDataInterface>(
      this.routes[EMyReportMetricsRoutes.BULK_CREATE_MY_REPORT_METRICS],
      { metrics: parameters },
    );
  }

  public bulkUpdateNewMyReportMetric(
    parameters: IMyReportMetricUpdateRequestParameters[],
  ): Observable<BulkResponseDataInterface> {
    return this.http.patch<BulkResponseDataInterface>(
      this.routes[EMyReportMetricsRoutes.BULK_UPDATE_MY_REPORT_METRICS],
      { metrics: parameters },
    );
  }

  public bulkDeleteMyReportMetrics(ids: number[]): Observable<BulkResponseDataInterface> {
    return this.http.delete<BulkResponseDataInterface>(
      this.routes[EMyReportMetricsRoutes.BULK_DELETE_MY_REPORT_METRICS],
      {
        body: { metrics: ids },
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      },
    );
  }

  public getOeeCalculationData(
    body: IOeeCalculationRequest,
  ): Observable<GetManyResponseInterface<IOeeCalculationResult>> {
    return this.http.post<GetManyResponseInterface<IOeeCalculationResult>>(
      this.routes[EMyReportMetricsRoutes.CALCULATE_OEE],
      body,
      {
        headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
      },
    );
  }
}
