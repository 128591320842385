import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import {
  ETableType,
  ESqlMode,
  IExpectedSpeed,
  IExpectedSpeedFilter,
  IOeeCalculationRequest,
  IOeeCalculationResult,
  IWorkOrderComment,
  IWorkOrderLaborAssetInformation,
  IWorkOrderLaborAssetTableInformation,
  IWorkOrderPerformanceFilter,
  IWorkOrderPhaseInformation,
} from './work-order-performance.model';
import {
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../oee.reducer';
import {
  ActivityLogsResponseInterface,
  IActivityLog,
  IActivityLogsRequest
} from '../root-cause-analysis/root-cause-analysis.model';
import { GetWorkOrderResponseInterface } from '../../work-order-schedule/work-order-schedule.model';
import * as _ from 'lodash';
import * as moment from 'moment';
import {
  CellTypes,
  CreateExcelInterface,
  CreateExcelSheetInterface,
  ExcelColumnWidthEnum,
  ExcelHelperService,
} from '../../../shared/service/excel/excel-helper.service';
import * as ObjectActions from '../capacity/capacity.actions';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { ValueType } from 'exceljs';
import {ActivityTypes} from "../../../shared/model/enum/activity-types";

@Injectable({
  providedIn: 'root',
})
export class WorkOrderPerformanceService {
  private readonly destroySubject: Subject<boolean> = new Subject<boolean>();
  private timezone: string = 'utc';
  private dateFormat$: string;
  private timeFormat$: string;

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly api: string,
    public store: Store<oeeAppReducer.OeeAppState>,
    private translate: TranslateService,
    private readonly excelHelper: ExcelHelperService,
  ) {
    this.store
      .select('user')
      .pipe(takeUntil(this.destroySubject))
      .subscribe((state) => {
        if (state.isUserLoaded) {
          this.timezone = state.timezone;
          this.dateFormat$ = state.dateFormat;
          this.timeFormat$ = state.dateTimeFormat;

          this.destroySubject.next(true);
          this.destroySubject.complete();
        }
      });
  }

  private readonly workOrders: string = '/work-orders/';
  private readonly routes = {
    activityLogs: '/activity-histories/activity-logs',
    workOrders: `${this.workOrders}`,
    calculateOee: '/oee-calculation/calculate-oee',
    laborAndAssetDurations: `${this.workOrders}labor-and-asset-durations`,
    phaseInformation: `${this.workOrders}phase-information`,
    workOrderCloseFeedback: '/work-order-close-feedbacks/',
    products: '/products',
    getExpectedSpeed: '/product-expected-speed',
    comments: '/comments',
  };

  public getWorkOrderActivityLogs(
    params: IWorkOrderPerformanceFilter,
  ): Observable<BaseOneResponseInterface<ActivityLogsResponseInterface>> {
    const body: IActivityLogsRequest = {
      isBusinessDate: true,
      sites: -1,
      lines: -1,
      lineTypes: -1,
      activities: -1,
      products: -1,
      rootCauseGroups: -1,
      shifts: -1,
      advancedFilterPage: 'work-order-performance',
      advancedFilterParams: JSON.stringify({ $and: [] }),
      workOrders: params.workOrderId,
    };

    return this.http.post<BaseOneResponseInterface<ActivityLogsResponseInterface>>(
      `${this.api}${this.routes.activityLogs}`,
      body,
      {
        headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
      },
    );
  }

  public getWorkOrderInformation(params: IWorkOrderPerformanceFilter): Observable<GetWorkOrderResponseInterface> {
    const httpParams: HttpParams = new HttpParams()
      .append('join', 'product||productId,description,packageSize,productSpeed,unit')
      .append('join', 'workOrderCloseFeedback')
      .append('join', 'scheduledLineDetail')
      .append('join', 'actualLineDetail')
      .append('join', 'site');

    return this.http.get<GetWorkOrderResponseInterface>(
      `${this.api}${this.routes.workOrders}${_.first(params.workOrderId)}`,
      {
        params: httpParams,
      },
    );
  }

  public getOeeCalculationResult(
    params: IWorkOrderPerformanceFilter,
  ): Observable<GetManyResponseInterface<IOeeCalculationResult>> {
    const body: IOeeCalculationRequest = {
      workOrderId: _.first(params.workOrderId),
      sqlMode: ESqlMode.BATCH,
      useReplica: true,
    };

    return this.http.post<GetManyResponseInterface<IOeeCalculationResult>>(
      `${this.api}${this.routes.calculateOee}`,
      body,
      {
        headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
      },
    );
  }

  public getPhaseInformation(
    params: IWorkOrderPerformanceFilter,
  ): Observable<BaseOneResponseInterface<IWorkOrderPhaseInformation>> {
    const httpParams: HttpParams = new HttpParams()
      .set('workOrderId', String(_.first(params.workOrderId)))
      .set('useReplica', true);

    return this.http.get<BaseOneResponseInterface<IWorkOrderPhaseInformation>>(
      `${this.api}${this.routes.phaseInformation}`,
      { params: httpParams },
    );
  }

  public getLaborInformation(
    params: IWorkOrderPerformanceFilter,
  ): Observable<BaseOneResponseInterface<IWorkOrderLaborAssetInformation[]>> {
    const httpParams: HttpParams = new HttpParams()
      .set('workOrderId', String(_.first(params.workOrderId)))
      .set('useReplica', true);

    return this.http.get<BaseOneResponseInterface<IWorkOrderLaborAssetInformation[]>>(
      `${this.api}${this.routes.laborAndAssetDurations}`,
      { params: httpParams },
    );
  }

  public getProductExpectedSpeed(params: IExpectedSpeedFilter): Observable<BaseOneResponseInterface<IExpectedSpeed>> {
    const httpParams: HttpParams = new HttpParams().set('lineIds', params.lineIds.join(','));

    return this.http.get<BaseOneResponseInterface<IExpectedSpeed>>(
      `${this.api}${this.routes.products}/${params.productId}${this.routes.getExpectedSpeed}`,
      { params: httpParams },
    );
  }

  public getWorkOrderComments(workOrderId: number): Observable<GetManyResponseInterface<IWorkOrderComment>> {
    const httpParams: HttpParams = new HttpParams().set('s', JSON.stringify({ workOrderId }));

    return this.http.get<GetManyResponseInterface<IWorkOrderComment>>(`${this.api}${this.routes.comments}`, {
      params: httpParams,
    });
  }

  public downloadLaborAssetInformationExcel(data: IWorkOrderLaborAssetTableInformation[], excelType: ETableType): void {
    const excelName: string =
      excelType === ETableType.LABOR
        ? this.translate.instant('excel.laborLogs.excelName', {
            date: moment()
              .tz(this.timezone)
              .format(this.dateFormat$),
          })
        : this.translate.instant('excel.assetLogs.excelName', {
            date: moment()
              .tz(this.timezone)
              .format(this.dateFormat$),
          });
    const sheetTitle: string =
      excelType === ETableType.LABOR
        ? this.translate.instant('excel.laborLogs.excelSheetName')
        : this.translate.instant('excel.assetLogs.excelSheetName');

    const excelOptions: CreateExcelInterface = this.getLaborAssetInformationExcelColumns(excelType);

    excelOptions.data = data;

    const worksheets: CreateExcelSheetInterface[] = [
      {
        sheetTitle,
        params: excelOptions,
        withData: true,
      },
    ];

    this.excelHelper.createExcel(excelName, worksheets, this.timezone, this.dateFormat$, this.timeFormat$).then(
      () => {
        this.store.dispatch(new ObjectActions.DownloadCapacityExcelCompleted());
      },
      () => {
        this.store.dispatch(new ObjectActions.FetchError({}));
      },
    );
  }

  private getLaborAssetInformationExcelColumns(excelType: ETableType): CreateExcelInterface {
    const excelColumns: CreateExcelInterface = {
      columns: [
        {
          header: this.translate.instant('general.dataTable.header.siteName'),
          key: 'siteName',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          dataValidation: {
            type: CellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('main.workOrder.line'),
          key: 'lineName',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          dataValidation: {
            type: CellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('general.input.lineStation.label'),
          key: 'stationName',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          dataValidation: {
            type: CellTypes.CUSTOM,
          },
        },
        {
          header:
            excelType === ETableType.LABOR
              ? this.translate.instant('cico.modules.laborTracker.name')
              : this.translate.instant('cico.modules.assetTracker.name'),
          key: 'sourceObjectName',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          dataValidation: {
            type: CellTypes.CUSTOM,
          },
        },
        {
          header: this.translate.instant('general.dataTable.header.duration'),
          key: 'duration',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          style: { numFmt: '@' },
          dataValidation: {
            type: CellTypes.CUSTOM,
          },
        },
      ],
    };

    this.excelHelper.prepareExcelColumns(excelColumns.columns, false);

    return excelColumns;
  }

  public setWorkOrderPerformanceTaskDescription(activityLog: IActivityLog): void {
    if (activityLog.activityTypeText === ActivityTypes.RUN_TIME) {
      activityLog.taskName = activityLog.productDescription;
    }
  }
}
