import * as Actions from './product-multiplier.actions';
import { IProductMultipliersState } from './product-multiplier.model';

const initialState: IProductMultipliersState = {
  isProductMultipliersLoading: false,
  isProductMultipliersLoaded: false,
  productMultipliers: [],
  totalDataCount: 0,
  filteredDataCount: 0,
  lines: [],
  isLinesLoading: false,
  isLinesLoaded: false,
  products: [],
  isProductsLoading: false,
  isProductsLoaded: false,
  sensorTypes: [],
  isSensorTypesLoading: false,
  isSensorTypesLoaded: false,
  isUploadExcelLoading: false,
  isUploadExcelLoaded: false,
  excelUploadResult: [],
  fileDownloadStatus: false,
};

export function productMultiplierReducer(
  state: IProductMultipliersState = initialState,
  action: Actions.ProductMultiplierActions,
): IProductMultipliersState {
  switch (action.type) {
    case Actions.GET_PRODUCT_MULTIPLIER_COUNT_COMPLETED:
      return {
        ...state,
        totalDataCount: action.data.total,
      };

    case Actions.GET_SENSOR_TYPES:
      return {
        ...state,
        isSensorTypesLoading: true,
        isSensorTypesLoaded: false,
      };

    case Actions.GET_SENSOR_TYPES_COMPLETED:
      return {
        ...state,
        isSensorTypesLoading: false,
        isSensorTypesLoaded: true,
        sensorTypes: action.sensorTypes,
      };

    case Actions.GET_PRODUCTS:
      return {
        ...state,
        isProductsLoading: true,
        isProductsLoaded: false,
      };

    case Actions.GET_PRODUCTS_COMPLETED:
      return {
        ...state,
        isProductsLoading: false,
        isProductsLoaded: true,
        products: action.products,
      };

    case Actions.GET_LINES:
      return {
        ...state,
        isLinesLoading: true,
        isLinesLoaded: false,
      };

    case Actions.GET_LINES_COMPLETED:
      return {
        ...state,
        isLinesLoading: false,
        isLinesLoaded: true,
        lines: action.lines,
      };

    case Actions.LOAD_PRODUCT_MULTIPLIERS:
      return {
        ...state,
        isProductMultipliersLoading: true,
        isProductMultipliersLoaded: false,
      };

    case Actions.PRODUCT_MULTIPLIERS_LOADED:
      return {
        ...state,
        isProductMultipliersLoading: false,
        isProductMultipliersLoaded: true,
        productMultipliers: action.response.data,
        filteredDataCount: action.response.total,
      };

    case Actions.DOWNLOAD_PRODUCT_MULTIPLIER_EXCEL:
      return {
        ...state,
        ...{
          fileDownloadStatus: true,
        },
      };

    case Actions.PRODUCT_MULTIPLIER_EXCEL_DOWNLOADED:
      return {
        ...state,
        ...{
          fileDownloadStatus: false,
        },
      };

    case Actions.UPLOAD_PRODUCT_MULTIPLIER_EXCEL:
      return {
        ...state,
        ...{
          isUploadExcelLoading: true,
          isUploadExcelLoaded: false,
        },
      };

    case Actions.PRODUCT_MULTIPLIER_EXCEL_UPLOADED:
      return {
        ...state,
        ...{
          excelUploadResult: action.payload,
          isUploadExcelLoading: false,
          isUploadExcelLoaded: true,
        },
      };

    case Actions.CLEAR_STATE:
      return { ...initialState };

    default:
      return state;
  }
}
