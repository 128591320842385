import { IPeriodicOeeCalculationReviewState } from './periodic-oee-calculation-review.model';
import * as Actions from './periodic-oee-calculation-review.actions';
export const periodicOeeCalculationReviewInitialState: IPeriodicOeeCalculationReviewState = {
  isPeriodicOeeCalculationDataLoading: false,
  isPeriodicOeeCalculationDataLoaded: false,
  periodicOeeCalculationData: null,
};

export function periodicOeeCalculationReviewReducer(
  state: IPeriodicOeeCalculationReviewState = periodicOeeCalculationReviewInitialState,
  action: Actions.PeriodicOeeCalculationReviewActions,
): IPeriodicOeeCalculationReviewState {
  switch (action.type) {
    case Actions.PERIODIC_OEE_CALCULATION_REVIEW_DATA_LOADING:
      return {
        ...state,
        isPeriodicOeeCalculationDataLoading: true,
        isPeriodicOeeCalculationDataLoaded: false,
      };
    case Actions.PERIODIC_OEE_CALCULATION_REVIEW_DATA_LOADED:
      return {
        ...state,
        isPeriodicOeeCalculationDataLoading: false,
        isPeriodicOeeCalculationDataLoaded: true,
        periodicOeeCalculationData: action.payload,
      };

    case Actions.FETCH_ERROR:
      return {
        ...state,
        isPeriodicOeeCalculationDataLoading: false,
        isPeriodicOeeCalculationDataLoaded: true,
      };
    default:
      return state;
  }
}
