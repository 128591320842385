import { Inject, Injectable } from '@angular/core';
import { FilterServiceInterface, LineCRUDResponseInterface } from './service.class';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LineCRUDInterface } from '../../component/filter/filter.class';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LineService implements FilterServiceInterface {
  constructor(public http: HttpClient, @Inject('API_BASE_URL') private baseUrl: string) {}

  getData(options?: HttpParams): Promise<LineCRUDInterface[]> {
    return new Promise((resolve, reject) => {
      this.http
        .get(`${this.baseUrl}/lines?join=lineTypeName`, { params: options })
        .subscribe((response: LineCRUDResponseInterface) => {
          if (response.hasOwnProperty('data')) {
            resolve(response.data);
          } else {
            reject();
          }
        });
    });
  }

  public getLines(params?: HttpParams): Observable<LineCRUDResponseInterface> {
    return this.http.get<LineCRUDResponseInterface>(`${this.baseUrl}/lines`, {
      params,
    });
  }
}
