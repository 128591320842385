import { Action } from '@ngrx/store';
import {
  ActivitiesInterface,
  ActivityTypeInterface,
  ActivitySubTypeInterface, ActivityInactiveChangeInterface, ActivitiesUpdateInterface,
} from '../../../shared/model/interface/activities.model';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { IActivitiesRequestParams } from '../../../view/settings/activities/activities.model';
import {
  ActivitiesDownloadExcelFiltersInterface,
  ActivityBulkSaveManyInterface,
  ActivityInterface,
} from './activities.model';

export const DOWNLOAD_ACTIVITY_EXCEL = '[ACTIVITIES] ACTIVITIES ACTION DOWNLOAD ACTIVITY EXCEL';
export const DOWNLOAD_ACTIVITY_EXCEL_COMPLETED = '[ACTIVITIES] ACTIVITIES ACTION DOWNLOAD ACTIVITY EXCEL COMPLETED';
export const UPLOAD_ACTIVITY_EXCEL = '[ACTIVITIES] ACTIVITIES ACTION UPLOAD ACTIVITY EXCEL';
export const UPLOAD_ACTIVITY_EXCEL_COMPLETED = '[ACTIVITIES] ACTIVITIES ACTION UPLOAD ACTIVITY EXCEL COMPLETED';
export const LOAD_ACTIVITIES_DATA = '[ACTIVITIES] LOAD ACTIVITIES DATA';
export const LOADED_ACTIVITIES_DATA = '[ACTIVITIES] LOADED ACTIVITIES DATA';
export const SINGLE_EDIT_ACTIVITIES_DATA = '[ACTIVITIES] REQUEST SINGLE EDIT ACTIVITIES DATA';
export const EDITED_SINGLE_EDIT_ACTIVITIES_DATA = '[ACTIVITIES] EDITED SINGLE EDIT ACTIVITIES DATA';
export const EDIT_ACTIVITIES_DATA = '[ACTIVITIES] REQUEST EDIT ACTIVITIES DATA';
export const EDITED_ACTIVITIES_DATA = '[ACTIVITIES] EDITED ACTIVITIES DATA';
export const CREATE_ACTIVITY = '[ACTIVITIES] CREATE ACTIVITY DATA';
export const CREATED_ACTIVITY = '[ACTIVITIES] CREATED ACTIVITY DATA';
export const DELETE_ACTIVITY = '[ACTIVITIES] DELETE ACTIVITY';
export const DELETED_ACTIVITY = '[ACTIVITIES] DELETED ACTIVITY';
export const DELETE_ACTIVITIES = '[ACTIVITIES] DELETE ACTIVITIES';
export const DELETED_ACTIVITIES = '[ACTIVITIES] DELETED ACTIVITIES';
export const LOAD_ACTIVITY_TYPES = '[ACTIVITIES] LOAD ACTIVITY TYPES';
export const LOADED_ACTIVITY_TYPES = '[ACTIVITIES] LOADED ACTIVITY TYPES';
export const LOAD_ACTIVITY_SUBTYPES = '[ACTIVITIES] LOAD ACTIVITY SUBTYPES';
export const LOADED_ACTIVITY_SUBTYPES = '[ACTIVITIES] LOADED ACTIVITY SUBTYPES';
export const ACTIVITIES_FETCH_ERROR = '[ACTIVITIES] ACTIVITIES ACTION FETCH ERROR';
export const ACTIVITIES_GET_DATA_COUNT = '[ACTIVITIES] ACTIVITIES GET DATA COUNT';
export const ACTIVITIES_GOT_DATA_COUNT = '[ACTIVITIES] ACTIVITIES GOT DATA COUNT';
export const GET_ONE_ACTIVITY = '[ACTIVITIES] GET ONE ACTIVITY';
export const GET_ONE_ACTIVITY_COMPLETED = '[ACTIVITIES] GET ONE ACTIVITY COMPLETED';
export const LOAD_USE_ACTIVITY_IN_ALERTS_AND_LINES_DATA = '[ACTIVITIES] LOAD USE ACTIVITY IN ALERTS AND LINES DATA';
export const LOADED_USE_ACTIVITY_IN_ALERTS_AND_LINES_DATA = '[ACTIVITIES] LOADED USE ACTIVITY IN ALERTS AND LINES DATA';

export class GetExcelDataCount implements Action {
  readonly type = ACTIVITIES_GET_DATA_COUNT;
}

export class GotExcelDataCount implements Action {
  readonly type = ACTIVITIES_GOT_DATA_COUNT;

  constructor(public payload: GetManyResponseInterface<ActivitiesInterface>) {}
}

export class LoadActivitiesData implements Action {
  readonly type = LOAD_ACTIVITIES_DATA;

  constructor(public requestParams: IActivitiesRequestParams) {}
}

export class LoadedActivitiesData implements Action {
  readonly type = LOADED_ACTIVITIES_DATA;

  constructor(public payload: GetManyResponseInterface<ActivitiesInterface>) {}
}

export class SingleEditActivitiesData implements Action {
  readonly type = SINGLE_EDIT_ACTIVITIES_DATA;

  constructor(public activityId: number, public payload: Partial<ActivitiesUpdateInterface>) {}
}

export class LoadUseActivityInAlertsAndLinesData implements Action {
  readonly type = LOAD_USE_ACTIVITY_IN_ALERTS_AND_LINES_DATA;

  constructor(public activityId: number) {}
}

export class LoadedUseActivityInAlertsAndLinesData implements Action {
  readonly type = LOADED_USE_ACTIVITY_IN_ALERTS_AND_LINES_DATA;

  constructor(public response: BaseOneResponseInterface<ActivityInactiveChangeInterface>) {}
}

export class EditedSingleEditActivitiesData implements Action {
  readonly type = EDITED_SINGLE_EDIT_ACTIVITIES_DATA;

  constructor(public response: GetManyResponseInterface<ActivitiesInterface>) {}
}

export class EditActivitiesData implements Action {
  readonly type = EDIT_ACTIVITIES_DATA;

  constructor(public payload: Partial<ActivitiesInterface>[]) {}
}

export class EditedActivitiesData implements Action {
  readonly type = EDITED_ACTIVITIES_DATA;

  constructor(public response: BulkResponseDataInterface) {}
}

export class CreateActivity implements Action {
  readonly type = CREATE_ACTIVITY;

  constructor(public payload: Partial<ActivitiesInterface>) {}
}

export class CreatedActivity implements Action {
  readonly type = CREATED_ACTIVITY;

  constructor(public response: GetManyResponseInterface<ActivitiesInterface>) {}
}

export class DeleteActivity implements Action {
  readonly type = DELETE_ACTIVITY;

  constructor(public activityId: number) {}
}

export class DeletedActivity implements Action {
  readonly type = DELETED_ACTIVITY;

  constructor(public response: GetManyResponseInterface<ActivitiesInterface>) {}
}

export class DeleteActivities implements Action {
  readonly type = DELETE_ACTIVITIES;

  constructor(public activityIds: number[]) {}
}

export class DeletedActivities implements Action {
  readonly type = DELETED_ACTIVITIES;

  constructor(public response: BulkResponseDataInterface) {}
}

export class LoadActivityTypes implements Action {
  readonly type = LOAD_ACTIVITY_TYPES;
}

export class LoadedActivityTypes implements Action {
  readonly type = LOADED_ACTIVITY_TYPES;

  constructor(public payload: GetManyResponseInterface<ActivityTypeInterface>) {}
}

export class LoadActivitySubTypes implements Action {
  readonly type = LOAD_ACTIVITY_SUBTYPES;
}

export class LoadedActivitySubTypes implements Action {
  readonly type = LOADED_ACTIVITY_SUBTYPES;

  constructor(public payload: GetManyResponseInterface<ActivitySubTypeInterface>) {}
}

export class DownloadActivityExcel implements Action {
  readonly type = DOWNLOAD_ACTIVITY_EXCEL;
  constructor(public withData: boolean = false, public filters: ActivitiesDownloadExcelFiltersInterface) {}
}

export class DownloadActivityExcelCompleted implements Action {
  readonly type = DOWNLOAD_ACTIVITY_EXCEL_COMPLETED;
}

export class UploadActivityExcel implements Action {
  readonly type = UPLOAD_ACTIVITY_EXCEL;

  constructor(public activities: ActivityBulkSaveManyInterface) {}
}

export class UploadActivityExcelCompleted implements Action {
  readonly type = UPLOAD_ACTIVITY_EXCEL_COMPLETED;

  constructor(public payload: (ActivityInterface & { errorMessages?: string })[]) {}
}

export class FetchError implements Action {
  readonly type = ACTIVITIES_FETCH_ERROR;
  constructor(public payload: object) {}
}

export class GetOneActivity implements Action {
  readonly type = GET_ONE_ACTIVITY;

  constructor(public id: number) {}
}

export class GetOneActivityCompleted implements Action {
  readonly type = GET_ONE_ACTIVITY_COMPLETED;

  constructor(public payload: BaseOneResponseInterface<ActivitiesInterface>) {}
}

export type ActivitiesActions =
  | GetExcelDataCount
  | GotExcelDataCount
  | LoadActivityTypes
  | LoadedActivityTypes
  | LoadActivitySubTypes
  | LoadedActivitySubTypes
  | LoadActivitiesData
  | LoadedActivitiesData
  | DownloadActivityExcel
  | DownloadActivityExcelCompleted
  | UploadActivityExcel
  | UploadActivityExcelCompleted
  | GetOneActivity
  | GetOneActivityCompleted
  | FetchError;
