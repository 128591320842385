import { Action } from '@ngrx/store';
import { ActivityLogsQueryParams } from '../../../view/reports/activity-logs/activity-logs.model';
import { ActivityLogReportInterface, IActivityLogsFilters } from './activity-logs.model';
import { TabRowInterface } from '../../../shared/component/side-config-bar/side-config-bar.model';
import { ExcelColumnDefinitionInterface } from '../../../shared/service/excel/excel-helper.service';
import { BaseOneResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';

export const ACTIVITY_LOGS_DATA_LOADING = '[ACTIVITY_LOGS] ACTIVITY LOGS DATA LOADING';
export const ACTIVITY_LOGS_DATA_LOADED = '[ACTIVITY_LOGS] ACTIVITY LOGS DATA LOADED';
export const FETCH_ERROR = '[ACTIVITY_LOGS] FETCH ERROR';
export const SET_SELECTED_FILTERS = '[ACTIVITY_LOGS] SET SELECTED FILTERS';
export const SET_TABLE_SETTINGS = '[ACTIVITY_LOGS] SET TABLE SETTINGS';
export const ACTIVITY_LOGS_DOWNLOAD_EXCEL = '[ACTIVITY_LOGS] ACTIVITY LOGS DOWNLOAD EXCEL';
export const ACTIVITY_LOGS_DOWNLOAD_EXCEL_COMPLETED = '[ACTIVITY_LOGS] ACTIVITY LOGS DOWNLOAD EXCEL COMPLETED';

export class ActivityLogsDataLoading implements Action {
  readonly type = ACTIVITY_LOGS_DATA_LOADING;

  constructor(public params: ActivityLogsQueryParams) {}
}

export class ActivityLogsDataLoaded implements Action {
  readonly type = ACTIVITY_LOGS_DATA_LOADED;

  constructor(public payload: BaseOneResponseInterface<ActivityLogReportInterface>) {}
}

export class SetSelectedFilters implements Action {
  readonly type = SET_SELECTED_FILTERS;

  constructor(public filters: IActivityLogsFilters) {}
}

export class SetTableSettings implements Action {
  readonly type = SET_TABLE_SETTINGS;

  constructor(public payload: TabRowInterface[], public setAsDefault: boolean) {}
}

export class ActivityLogsDownloadExcel implements Action {
  readonly type = ACTIVITY_LOGS_DOWNLOAD_EXCEL;

  constructor(
    public params: ActivityLogsQueryParams,
    public worksheetsColumnDefinitions: ExcelColumnDefinitionInterface[][],
    public fileDownloadType: string,
  ) {}
}

export class ActivityLogsDownloadExcelCompleted implements Action {
  readonly type = ACTIVITY_LOGS_DOWNLOAD_EXCEL_COMPLETED;
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;

  constructor(public payload: object) {}
}

export type ActivityLogsActions =
  | ActivityLogsDataLoading
  | ActivityLogsDataLoaded
  | SetTableSettings
  | SetSelectedFilters
  | ActivityLogsDownloadExcel
  | ActivityLogsDownloadExcelCompleted
  | FetchError;
