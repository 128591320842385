import { IUserMenuPermissions } from '../store/user/model';

export interface LayoutInterface {
  animateSidebar: string;
  navType: string;
  themeLayout: string;
  verticalPlacement: string;
  verticalLayout: string;
  pcodedDeviceType: string;
  verticalNavType: string;
  verticalEffect: string;
  vnavigationView: string;
  freamType: string;
  sidebarImg: string;
  sidebarImgType: string;
  layoutType: string;
  headerTheme: string;
  pcodedHeaderPosition: string;
  liveNotification: string;
  liveNotificationClass: string;
  profileNotification: string;
  profileNotificationClass: string;
  shiftReviewDropdown: string;
  shiftReviewDropdownClass: string;
  alertControlDropdown: string;
  alertControlDropdownClass: string;
  rippleClass: string;
  chatSlideInOut: string;
  innerChatSlideInOut: string;
  searchWidth: number;
  searchWidthString: string;
  navRight: string;
  windowWidth: number;
  chatTopPosition: string;
  toggleOn: boolean;
  toggleIcon: string;
  navBarTheme: string;
  activeItemTheme: string;
  pcodedSidebarPosition: string;
  headerFixedTop: string;
  menuTitleTheme: string;
  dropDownIcon: string;
  subItemIcon: string;
  configOpenRightBar: string;
  displayBoxLayout: string;
  isVerticalLayoutChecked: boolean;
  isSidebarChecked: boolean;
  isHeaderChecked: boolean;
  headerFixedMargin: string;
  sidebarFixedHeight: string;
  sidebarFixedNavHeight: string;
  itemBorderStyle: string;
  subItemBorder: boolean;
  itemBorder: boolean;
  pageHeaderPaddingTop: string;
  sidebarDisplay: string;
  isSidebarOpened: boolean;
  sidebarToggleIcon: string;
  isCollapsedSideBar: string;
  psDisabled: string;
  perfectDisable: string;
  hideNavMenu: boolean;
  missingDataCount: number;
  clientLogo: string;
  lineName: string;
  siteName: string;
  userName: string;
  changeSiteAndLine: boolean;
  config: any;
  isCallSupportSubmitButtonClicked: boolean;
  userLevel: string;
}

export class DeviceType {
  public static desktop: string = 'desktop';
  public static tablet: string = 'tablet';
  public static phone: string = 'phone';
}

export class LayoutConstants implements LayoutInterface {
  public animateSidebar = '';
  public navType = 'st2';
  public themeLayout = 'horizontal';
  public verticalPlacement = 'top';
  public verticalLayout = 'wide';
  public pcodedDeviceType = DeviceType.desktop;
  public verticalNavType = 'expanded';
  public verticalEffect = 'shrink';
  public vnavigationView = 'view1';
  public freamType = 'theme6';
  public sidebarImg = 'false';
  public sidebarImgType = 'img1';
  public layoutType = 'light';
  public headerTheme = 'theme6';
  public pcodedHeaderPosition = 'fixed';
  public headerFixedTop = 'auto';
  public liveNotification = 'an-off';
  public profileNotification = 'an-off';
  public shiftReviewDropdown = 'an-off';
  public shiftReviewDropdownClass = 'an-off';
  public alertControlDropdown = 'an-off';
  public alertControlDropdownClass = 'an-off';
  public notificationDropdown = 'an-off';
  public notificationDropdownClass = 'an-off';
  public lineStationDropdown = 'an-off';
  public lineStationDropdownClass = 'an-off';
  public rippleClass = 'ripple';
  public chatSlideInOut = 'out';
  public innerChatSlideInOut = 'out';
  public searchWidth = 0;
  public navRight = 'nav-on';
  public windowWidth = window.innerWidth;
  public toggleOn = true;
  public toggleIcon = 'icon-x';
  public navBarTheme = 'themelight1';
  public activeItemTheme = 'theme9';
  public pcodedSidebarPosition = 'fixed';
  public menuTitleTheme = 'theme6';
  public dropDownIcon = 'style1';
  public subItemIcon = 'style1';
  public displayBoxLayout = 'd-none';
  public isVerticalLayoutChecked = false;
  public isSidebarChecked = true;
  public isHeaderChecked = true;
  public headerFixedMargin = '50px';
  public sidebarFixedHeight = '100%';
  public itemBorderStyle = 'none';
  public subItemBorder = true;
  public itemBorder = true;
  public pageHeaderPaddingTop = '0';
  public sidebarDisplay = 'none';
  public isSidebarOpened = true;
  public sidebarToggleIcon = 'icon-chevron-up';
  public isCollapsedSideBar = 'no-block';
  public perfectDisable = '';
  public isCallSupportSubmitButtonClicked = false;
  public changeSiteAndLine = true;
  public chatTopPosition: string;
  public clientLogo: string;
  public config: any;
  public configOpenRightBar: string;
  public hideNavMenu = true;
  public lineName: string;
  public liveNotificationClass: string;
  public missingDataCount: number;
  public profileNotificationClass: string;
  public psDisabled: string;
  public searchWidthString: string;
  public sidebarFixedNavHeight: string;
  public siteName: string;
  public userName: string;
  public userLevel: string;
  public userMenuPermissions$: IUserMenuPermissions;
}
