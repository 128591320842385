import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AllSettledResolvedInterface, ResponseArrayInterface } from '../../model/interface/generic-api-response.model';
import {
  FormattedSaveLaborSchedulerItemInterface,
  LaborSchedulerGanttAllItemsResponseInterface,
  ILaborSchedulerItemResponse,
  SaveLaborSchedulerItemInterface,
} from './labor-scheduler.interface';
import { forkJoin, from, Observable } from 'rxjs';
import { TaskType } from '../../../view/scheduler/gantt-view/gantt-view.model';
import moment from 'moment';
import { mysqlTimestampFormat } from '../../helper/date';
import { CheckInTableQueryParams } from '../../../view/reports/check-in-logs/check-in-logs.model';
import { map } from 'rxjs/operators';
import { CheckInLogService } from '../../../store/reports/check-in-log/check-in-log.service';
import {
  CheckInLogTableResponseInterface,
  CheckInTableGroupedByNoneInterface,
  GroupedByTypes,
} from '../../../store/reports/check-in-log/check-in-log.model';

@Injectable({
  providedIn: 'root',
})
export class LaborSchedulerService {
  private readonly routes = {
    laborSchedulerItems: '/labor-scheduler-items',
    laborSchedulerItemsSaveAndDeploy: '/labor-scheduler-items/save-and-deploy',
  };

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly api: string,
    private checkInLogService: CheckInLogService,
  ) {}

  public loadLaborSchedulerItems(scenarioId: number): Observable<ResponseArrayInterface<ILaborSchedulerItemResponse>> {
    let params: HttpParams = new HttpParams();
    params = params.set('scenarioId', scenarioId.toString());
    return this.http.get<ResponseArrayInterface<ILaborSchedulerItemResponse>>(
      `${this.api}${this.routes.laborSchedulerItems}`,
      {
        params,
      },
    );
  }

  public saveLaborSchedulerItems(
    scenarioId: number,
    saveLaborSchedulerItems: FormattedSaveLaborSchedulerItemInterface[],
    isDeploy: boolean,
  ): Observable<AllSettledResolvedInterface[]> {
    return this.http.post<AllSettledResolvedInterface[]>(`${this.api}${this.routes.laborSchedulerItemsSaveAndDeploy}`, {
      scenarioId,
      saveLaborSchedulerItems,
      isDeploy,
    });
  }

  public formattedSaveLaborSchedulerItems(
    items: SaveLaborSchedulerItemInterface[],
    type: TaskType,
  ): FormattedSaveLaborSchedulerItemInterface[] {
    return items?.map((item: SaveLaborSchedulerItemInterface) => {
      return {
        userId: type === TaskType.line ? item.resourceId : item.objectId,
        lineId: type === TaskType.line ? item.objectId : item.resourceId,
        startDate: moment(item.startDate).format(mysqlTimestampFormat),
        endDate: moment(item.endDate).format(mysqlTimestampFormat),
        scenarioId: item.scenarioId,
      };
    });
  }

  public loadLaborSchedulerGanttAllItems(
    scenarioId: number,
    checkInQuery: CheckInTableQueryParams,
  ): Observable<LaborSchedulerGanttAllItemsResponseInterface> {
    const laborSchedulerItemsParams: HttpParams = new HttpParams().set('scenarioId', scenarioId.toString());

    const observables: {
      laborSchedulerItems: Observable<ResponseArrayInterface<ILaborSchedulerItemResponse>>;
      checkIns: Observable<CheckInLogTableResponseInterface>;
    } = {
      laborSchedulerItems: this.http.get<ResponseArrayInterface<ILaborSchedulerItemResponse>>(
        `${this.api}${this.routes.laborSchedulerItems}`,
        {
          params: laborSchedulerItemsParams,
        },
      ),
      checkIns: from(this.checkInLogService.getCheckInTableData(checkInQuery, GroupedByTypes.NONE)),
    };

    return forkJoin(observables).pipe(
      map(
        (resolvedObservables: {
          laborSchedulerItems: ResponseArrayInterface<ILaborSchedulerItemResponse>;
          checkIns: CheckInLogTableResponseInterface;
        }) => {
          return {
            laborSchedulerItems: resolvedObservables.laborSchedulerItems.data,
            checkIns: resolvedObservables.checkIns.data as CheckInTableGroupedByNoneInterface[],
          };
        },
      ),
    );
  }
}
