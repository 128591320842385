import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap, take } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as ObjectActions from './work-orders-summary.actions';
import * as oeeAppReducer from '../oee.reducer';
import { from, of } from 'rxjs';
import { GetShiftService } from '../../shared/service/shift-summary/getShift.service';
import {
  ShiftInformation,
  WorkOrdersSummaryDataInterface,
  WorkOrdersSummaryResponseInterface,
  WorkOrdersSummaryShiftResponseDataInterface,
} from './work-orders-summary.model';
import { GetWorkOrdersSummaryService } from '../../shared/service/work-orders-summary/getWorkOrdersSummary.service';
import { PageFiltersAndSettingsService } from '../../shared/service/page-filters-and-settings/page-filters-and-settings.service';
import { IUserConfiguration, IUserConfigurationResponse } from '../user-configuration/user-configuration.model';
import { ResponseInterface } from '../../shared/model/interface/generic-api-response.model';
import { EntityTranslatorService } from '../../shared/service/entity-translator/entity-translator.service';

@Injectable()
export class WorkOrdersSummaryEffects {
  private readonly pageName: string = 'work_orders_summary';
  private userId: string = '';

  constructor(
    private readonly actions$: Actions,
    private readonly http: HttpClient,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly getShiftService: GetShiftService,
    private readonly getWorkOrdersSummaryService: GetWorkOrdersSummaryService,
    private readonly pageFiltersAndSettings: PageFiltersAndSettingsService,
    private readonly entityTranslation: EntityTranslatorService,
  ) {}

  fetchProductionReviewData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.WorkOrdersSummaryActionTypes.StartWorkOrdersSummaryDataLoading),
      switchMap((objectData: ObjectActions.StartWorkOrdersSummaryDataLoading) => {
        return from(this.getShiftService.getShifts(objectData.payload)).pipe(
          switchMap((response: WorkOrdersSummaryShiftResponseDataInterface) => {
            return of(new ObjectActions.WorkOrdersSummaryDataLoaded(response));
          }),
          catchError((err: HttpErrorResponse) => {
            return of(new ObjectActions.FetchError(err));
          }),
        );
      }),
    ),
  );

  getWorkOrdersSummaryDataAndTaskGroups = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.WorkOrdersSummaryActionTypes.GetShiftDataAndGroups),
      switchMap((objectData: ObjectActions.GetShiftDataAndGroups) => {
        let param = new HttpParams();
        param = param.set('siteId', encodeURIComponent(String(objectData.siteId)));
        param = param.set('departmentId', encodeURIComponent(String(objectData.departmentId)));
        param = param.set('shiftInformation', encodeURIComponent(String(objectData.shiftInformation.shiftInformation)));

        if (objectData.shiftInformation.shiftInformation === ShiftInformation.other) {
          param = param.set('shiftDate', encodeURIComponent(objectData.shiftInformation.shiftDate));
          param = param.set('shiftId', encodeURIComponent(String(objectData.shiftInformation.shiftId)));
        }

        return from(this.getWorkOrdersSummaryService.getWorkOrdersSummaryAndTaskGroups(param)).pipe(
          switchMap((response: WorkOrdersSummaryResponseInterface) => {
            response.data.data.forEach((e: WorkOrdersSummaryDataInterface) => this.entityTranslation.translate(e));
            return of(new ObjectActions.GetShiftDataAndGroupsLoaded(response));
          }),
          catchError((err: HttpErrorResponse) => {
            return of(new ObjectActions.FetchError(err));
          }),
        );
      }),
    ),
  );

  startFiltersSaving = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.WorkOrdersSummaryActionTypes.StartFiltersSaving),
      switchMap((objectData: ObjectActions.StartFiltersSaving) => {
        const siteIds: number[] = objectData.payload.site;
        const departmentIds: number[] = objectData.payload.department;

        const configuration: IUserConfiguration = {
          WorkOrderSummaryComponentFilters: [
            {
              name: 'filters',
              children: [
                {
                  name: 'siteIds',
                  value: siteIds,
                },
                {
                  name: 'departmentIds',
                  value: departmentIds,
                },
              ],
            },
          ],
        };

        return this.pageFiltersAndSettings.updateUserConfigurationData(configuration).pipe(
          switchMap((response: ResponseInterface<IUserConfigurationResponse>) => {
            return of(new ObjectActions.FiltersSaved(response.data.configuration));
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchError(err));
          }),
        );
      }),
    ),
  );

  setFiltersAndSettingsLoading = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.WorkOrdersSummaryActionTypes.SetSelectedFilters),
      switchMap(() => {
        return of(new ObjectActions.SetSelectedFiltersLoaded());
      }),
    ),
  );
}
