import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ScwMatTextAreaRule } from './scw-mat-textarea.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'scw-mat-textarea',
  templateUrl: './scw-mat-textarea.component.html',
  styleUrls: ['./scw-mat-textarea.component.scss'],
})
export class ScwMatTextareaComponent {
  @Input() inputModel: any;
  @Input() isValid: boolean = false;
  @Input() label: string = null;
  @Input() placeholder: string = null;
  @Input() hint: string = null;
  @Input() rows: number = 1;
  @Input() autosize: boolean = true;
  @Input() autosizeMinRows: number = 1;
  @Input() autosizeMaxRows: number = 30;
  @Input() maxlength: string = null;
  @Input() disabled: boolean = false;
  @Input() block: boolean = false;
  @Input() className:
    | string[]
    | {
        [klass: string]: any;
      };
  @Input() hasErrors: boolean = false;
  @Input() errorText: string;
  @Input() rules: ScwMatTextAreaRule[] = [];
  @Input() noPadding: boolean = false;

  @Output() inputModelChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() isValidChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onKeyup: EventEmitter<any> = new EventEmitter<any>();
  @Output() onKeyupEnter: EventEmitter<any> = new EventEmitter<any>();

  private isAnyError: boolean = false;
  private onChange: any = () => {};
  private onTouch: any = () => {};

  constructor(private translate: TranslateService) {}

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public writeValue(input: string): void {
    this.inputModel = input;
  }

  public reset(): void {
    this.inputModel = '';
    this.clearErrorMessage();
  }

  private isValidEqualizer(isValid: boolean): void {
    this.isValid = isValid;
    this.isValidChange.emit(this.isValid);
  }

  private showErrorMessage(message: string): void {
    this.isValidEqualizer(false);
    this.isAnyError = true;
    this.hasErrors = true;
    this.errorText = message ? message : '';
  }

  public clearErrorMessage(): void {
    this.isValidEqualizer(true);
    this.isAnyError = false;
    this.hasErrors = false;
    this.errorText = null;
  }

  private requiredRule(rule: ScwMatTextAreaRule): void {
    if (!!!this.inputModel || this.inputModel === 0) {
      this.showErrorMessage(rule.message ?? this.translate.instant('scwMatForm.validation.required'));
    }
  }

  private minLengthRule(rule: ScwMatTextAreaRule): void {
    if (this.inputModel && this.inputModel.length < rule.minLength) {
      this.showErrorMessage(
        rule.message ?? this.translate.instant('scwMatForm.validation.minLength', { minLength: rule.minLength }),
      );
    }
  }

  private maxLengthRule(rule: ScwMatTextAreaRule): void {
    if (this.inputModel && this.inputModel.length > rule.maxLength) {
      this.showErrorMessage(
        rule.message ?? this.translate.instant('scwMatForm.validation.maxLength', { maxLength: rule.maxLength }),
      );
    }
  }

  private rangeLengthRule(rule: ScwMatTextAreaRule): void {
    if (
      this.inputModel &&
      (this.inputModel.length < rule.rangeLength.min || this.inputModel.length > rule.rangeLength.max)
    ) {
      this.showErrorMessage(
        rule.message ??
          this.translate.instant('scwMatForm.validation.rangeLength', {
            min: rule.rangeLength.min,
            max: rule.rangeLength.max,
          }),
      );
    }
  }

  private patternRule(rule: ScwMatTextAreaRule): void {
    if (this.inputModel && !rule.pattern.test(this.inputModel)) {
      this.showErrorMessage(rule.message);
    }
  }

  private customRule(rule: ScwMatTextAreaRule): void {
    if (this.inputModel && rule.custom && !rule.validator(this.inputModel)) {
      this.showErrorMessage(rule.message);
    }
  }

  private checkRules(): void {
    if (this.rules.length === 0) {
      this.isValidEqualizer(true);
      return;
    }

    this.isAnyError = false;

    for (const rule of this.rules) {
      if (this.isAnyError) {
        return;
      }

      switch (true) {
        case 'required' in rule:
          this.requiredRule(rule);
          break;
        case 'minLength' in rule:
          this.minLengthRule(rule);
          break;
        case 'maxLength' in rule:
          this.maxLengthRule(rule);
          break;
        case 'rangeLength' in rule:
          this.rangeLengthRule(rule);
          break;
        case 'pattern' in rule:
          this.patternRule(rule);
          break;
        case 'custom' in rule:
          this.customRule(rule);
          break;
        default:
          return;
      }
    }

    if (this.isAnyError) {
      return;
    }

    this.clearErrorMessage();
  }

  public onNgModelChange(): void {
    this.inputModelChange.emit(this.inputModel);
    this.checkRules();
  }
}
