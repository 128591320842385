import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as oeeAppReducer from '../../oee.reducer';
import { Observable } from 'rxjs';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { IPeriodicOeeCalculationData } from './periodic-oee-calculation-review.model';

@Injectable({
  providedIn: 'root',
})
export class PeriodicOeeCalculationReviewService {
  private readonly routes = {
    url: `${this.baseUrl}/periodic-oee-calculations`,
  };
  constructor(
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    public http: HttpClient,
  ) {}

  public getPeriodicOeeCalculationData(
    params: HttpParams,
  ): Observable<GetManyResponseInterface<IPeriodicOeeCalculationData>> {
    return this.http.get<GetManyResponseInterface<IPeriodicOeeCalculationData>>(`${this.routes.url}`, { params });
  }
}
