import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PageHeaderInterface } from '../../../service/page-header/page-header.model';
import { ActionsSubject, Store } from '@ngrx/store';
import { OeeAppState } from '../../../../store/oee.reducer';
import { Subscription } from 'rxjs';
import { EPageMode } from './page-mode-switch-button.interface';
import { TranslateService } from '@ngx-translate/core';
import * as PageHeaderActions from '../../../../store/page-header/page-header.actions';
import { ofType } from '@ngrx/effects';

@Component({
  selector: 'app-page-mode-switch-button',
  templateUrl: './page-mode-switch-button.component.html',
  styleUrls: ['./page-mode-switch-button.component.scss'],
})
export class PageModeSwitchButtonComponent implements OnInit, OnDestroy {
  @Input() pageHeader: PageHeaderInterface = {
    title: null,
    titleKey: null,
    icon: null,
    fullScreenButton: false,
    fullScreenTargetElementId: null,
  };
  @Input() displayEditModeButtons: boolean = false;

  public pageModeEditTranslation: string;
  public pageModeSaveTranslation: string;
  public pageModeCancelTranslation: string;
  public ePageMode = EPageMode;
  public pageMode: EPageMode = EPageMode.VIEW;

  private readonly subscriptions: Subscription[] = [];

  constructor(
    private readonly store: Store<OeeAppState>,
    private readonly actionsSubject: ActionsSubject,
    private readonly translate: TranslateService,
  ) {}

  public ngOnInit(): void {
    this.subscriptions.push(
      this.actionsSubject
        .pipe(ofType(PageHeaderActions.SET_PAGE_TO_EDIT_OR_VIEW_MODE))
        .subscribe((response: PageHeaderActions.SetPageToEditOrViewMode) => {
          this.pageMode = response.isEditMode ? EPageMode.EDIT : EPageMode.VIEW;
        }),
    );

    this.pageModeEditTranslation = this.translate.instant(
      `general.pageModeButton.edit.${this.pageHeader.titleKey}.label`,
    );
    this.pageModeSaveTranslation = this.translate.instant(
      `general.pageModeButton.save.${this.pageHeader.titleKey}.label`,
    );
    this.pageModeCancelTranslation = this.translate.instant(
      `general.pageModeButton.cancel.${this.pageHeader.titleKey}.label`,
    );
  }

  public onModeChange(nextMode: EPageMode, isSave: boolean = false): void {
    this.store.dispatch(new PageHeaderActions.SetPageToEditOrViewMode(nextMode === EPageMode.EDIT, isSave));

    this.pageMode = nextMode;
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription?.unsubscribe());
  }
}
