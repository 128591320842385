import { SelectElementOptions, ProductSpeedsStateInterface } from './product-speeds.model';
import * as Actions from './product-speeds.actions';

export const productSpeedState: ProductSpeedsStateInterface = {
  productSpeedsLoading: false,
  productSpeedsLoaded: false,
  productSpeedsSiteDataLoading: false,
  productSpeedsSiteDataLoaded: false,
  siteData: [],
  productSpeedsData: null,
  fileDownloadStatus: false,
  excelUploadResult: [],
  isUploadExcelLoading: false,
  isUploadExcelLoaded: false,
  productSpeedsDeleteLoading: false,
  productSpeedsDeleteLoaded: false,
  addProductSpeedLoading: false,
  addProductSpeedLoaded: false,
  editProductSpeedLoading: false,
  editProductSpeedLoaded: false,
  bulkEditProductSpeedLoading: false,
  bulkEditProductSpeedLoaded: false,
  lines: [],
  products: [],
  getLinesLoading: false,
  getLinesLoaded: false,
  getProductsLoading: false,
  getProductsLoaded: false,
  filters: null,
  tableSettings: [],
};

export function productSpeedReducer(
  state: ProductSpeedsStateInterface = productSpeedState,
  action: Actions.ProductSpeedsActions,
): ProductSpeedsStateInterface {
  switch (action.type) {
    case Actions.PRODUCT_SPEEDS_SITES_DATA_LOADING:
      return {
        ...state,
        ...{
          productSpeedsSiteDataLoading: true,
          productSpeedsSiteDataLoaded: false,
        },
      };
    case Actions.PRODUCT_SPEEDS_SITES_DATA_LOADED:
      return {
        ...state,
        ...{
          siteData: action.payload,
          productSpeedsSiteDataLoading: false,
          productSpeedsSiteDataLoaded: true,
        },
      };
    case Actions.DOWNLOAD_PRODUCT_SPEED_EXCEL:
      return {
        ...state,
        ...{
          fileDownloadStatus: true,
        },
      };
    case Actions.DOWNLOAD_PRODUCT_SPEED_EXCEL_COMPLETED:
      return {
        ...state,
        ...{
          fileDownloadStatus: false,
        },
      };
    case Actions.UPLOAD_PRODUCT_SPEED_EXCEL:
      return {
        ...state,
        ...{
          isUploadExcelLoading: true,
          isUploadExcelLoaded: false,
        },
      };
    case Actions.UPLOAD_PRODUCT_SPEED_EXCEL_COMPLETED:
      return {
        ...state,
        ...{
          excelUploadResult: action.payload,
          isUploadExcelLoading: false,
          isUploadExcelLoaded: true,
        },
      };

    case Actions.SET_SELECTED_FILTERS:
      return {
        ...state,
        filters: action.filters,
      };

    case Actions.PRODUCT_SPEEDS_DATA_LOADING:
      return {
        ...state,
        ...{
          productSpeedsLoading: true,
          productSpeedsLoaded: false,
        },
      };
    case Actions.PRODUCT_SPEEDS_DATA_LOADED:
      return {
        ...state,
        ...{
          productSpeedsData: action.payload,
          productSpeedsLoading: false,
          productSpeedsLoaded: true,
        },
      };
    case Actions.DELETE_PRODUCT_SPEEDS:
      return {
        ...state,
        productSpeedsDeleteLoading: true,
        productSpeedsDeleteLoaded: false,
      };
    case Actions.DELETE_PRODUCT_SPEEDS_COMPLETED:
      return {
        ...state,
        productSpeedsDeleteLoading: false,
        productSpeedsDeleteLoaded: true,
      };
    case Actions.ADD_PRODUCT_SPEED:
      return {
        ...state,
        addProductSpeedLoading: true,
        addProductSpeedLoaded: false,
      };
    case Actions.ADD_PRODUCT_SPEED_COMPLETED:
      return {
        ...state,
        addProductSpeedLoading: false,
        addProductSpeedLoaded: true,
      };
    case Actions.EDIT_PRODUCT_SPEED:
      return {
        ...state,
        editProductSpeedLoading: true,
        editProductSpeedLoaded: false,
      };
    case Actions.EDIT_PRODUCT_SPEED_COMPLETED:
      return {
        ...state,
        editProductSpeedLoading: false,
        editProductSpeedLoaded: true,
      };
    case Actions.BULK_EDIT_PRODUCT_SPEED:
      return {
        ...state,
        bulkEditProductSpeedLoading: true,
        bulkEditProductSpeedLoaded: false,
      };
    case Actions.BULK_EDIT_PRODUCT_SPEED_COMPLETED:
      return {
        ...state,
        bulkEditProductSpeedLoading: false,
        bulkEditProductSpeedLoaded: true,
      };
    case Actions.GET_LINES:
      return {
        ...state,
        getLinesLoading: true,
        getLinesLoaded: false,
      };
    case Actions.GET_LINES_COMPLETED:
      const lineDropdownResource: SelectElementOptions[] = [];

      for (const item of action.payload.data) {
        lineDropdownResource.push({
          label: item.title,
          value: String(item.id),
        });
      }

      return {
        ...state,
        lines: lineDropdownResource,
        getLinesLoading: false,
        getLinesLoaded: true,
      };
    case Actions.GET_PRODUCTS:
      return {
        ...state,
        getProductsLoading: true,
        getProductsLoaded: false,
      };
    case Actions.GET_PRODUCTS_COMPLETED:
      const productDropdownResource: SelectElementOptions[] = [];

      for (const item of action.payload.data) {
        productDropdownResource.push({
          label: `${item.productId} - ${item.description}`,
          value: String(item.id),
          unit: item.unitType.name,
        });
      }

      return {
        ...state,
        products: productDropdownResource,
        getProductsLoading: false,
        getProductsLoaded: true,
      };
    case Actions.SET_TABLE_SETTINGS:
      return {
        ...state,
        tableSettings: action.payload,
      };
    case Actions.CLEAR_STATE:
      return { ...productSpeedState };
    default:
      return state;
  }
}
