import { Inject, Injectable } from '@angular/core';
import { FilterServiceInterface, UserCRUDResponseInterface } from './service.class';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UsersCRUDInterface } from '../../component/filter/filter.class';
import * as _ from 'lodash';
import { ActiveStatuses } from '../../../../constants';
import { HttpOptionInterface } from '../../../store/filter/filter.model';
import { generateHttpOption } from '../../helper/app-helper';

@Injectable({
  providedIn: 'root',
})
export class UserService implements FilterServiceInterface {
  constructor(public http: HttpClient, @Inject('API_BASE_URL') private baseUrl: string) {}

  private readonly routes: string = 'users';

  getUsers(options: HttpOptionInterface): Promise<UsersCRUDInterface[]> {
    const searchObject: object[] = options.s ? JSON.parse(options.s).$and : [];
    const lineFilter: { currentLine: object } = _.find(searchObject, 'currentLine');

    searchObject.push({ isActive: { $eq: ActiveStatuses.ACTIVE } });

    if (lineFilter) {
      const lineIndex: number = _.indexOf(searchObject, lineFilter);
      searchObject.splice(lineIndex, 1);
    }

    options.s = JSON.stringify({ $and: searchObject });
    const params: HttpParams = generateHttpOption(options);

    if (lineFilter) {
      params.set(
        'currentLineIds',
        _.first(Object.values(lineFilter.currentLine)),
      );
    }

    return this.getData(params);
  }

  public getData(params?: HttpParams): Promise<UsersCRUDInterface[]> {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.baseUrl}/${this.routes}`, { params }).subscribe((response: UserCRUDResponseInterface) => {
        if (response.hasOwnProperty('data')) {
          resolve(response.data);
        } else {
          reject();
        }
      });
    });
  }
}
