import { Action } from '@ngrx/store';
import { IEditStation, IStation, ITableQuery, StationBulkResponseInterface } from './stations.model';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../../shared/model/interface/crud-response-interface.model';
import { ResponseArrayInterface } from '../../../../shared/model/interface/generic-api-response.model';

export const STATIONS_DATA_LOAD = '[STATIONS] STATION DATA LOAD';
export const STATIONS_DATA_LOADED = '[STATIONS] STATION DATA LOADED';
export const ADD_STATION = '[STATIONS] ADD STATION ';
export const ADD_STATION_COMPLETED = '[STATIONS] ADD STATION COMPLETED';
export const EDIT_STATION = '[STATIONS] EDIT STATION ';
export const EDIT_STATION_COMPLETED = '[STATIONS] EDIT STATION COMPLETED';
export const BULK_EDIT_STATIONS = '[STATIONS] BULK EDIT STATIONS ';
export const BULK_EDIT_STATIONS_COMPLETED = '[STATIONS] BULK EDIT STATIONS COMPLETED';
export const DELETE_STATION = '[STATIONS] DELETE STATION ';
export const DELETE_STATION_COMPLETED = '[STATIONS] DELETE STATION COMPLETED';
export const BULK_DELETE_STATIONS = '[STATIONS] BULK DELETE STATIONS ';
export const BULK_DELETE_STATIONS_COMPLETED = '[STATIONS] BULK DELETE STATIONS COMPLETED';
export const FETCH_ERROR = '[STATIONS] FETCH ERROR';

export class StationsDataLoad implements Action {
  readonly type = STATIONS_DATA_LOAD;

  constructor(public tableQuery: ITableQuery) {}
}

export class StationsDataLoaded implements Action {
  readonly type = STATIONS_DATA_LOADED;

  constructor(public payload: ResponseArrayInterface<IStation>) {}
}

export class AddStation implements Action {
  readonly type = ADD_STATION;

  constructor(public station: IStation) {}
}

export class AddStationCompleted implements Action {
  readonly type = ADD_STATION_COMPLETED;

  constructor(public payload: BaseOneResponseInterface<IStation>) {}
}

export class EditStation implements Action {
  readonly type = EDIT_STATION;

  constructor(public station: IStation, public stationId: number) {}
}

export class EditStationCompleted implements Action {
  readonly type = EDIT_STATION_COMPLETED;

  constructor(public payload: BaseOneResponseInterface<IStation>) {}
}

export class BulkEditStations implements Action {
  readonly type = BULK_EDIT_STATIONS;

  constructor(public stations: IEditStation[]) {}
}

export class BulkEditStationsCompleted implements Action {
  readonly type = BULK_EDIT_STATIONS_COMPLETED;

  constructor(public payload: GetManyResponseInterface<StationBulkResponseInterface>) {}
}

export class DeleteStation implements Action {
  readonly type = DELETE_STATION;

  constructor(public stationId: number) {}
}

export class DeleteStationCompleted implements Action {
  readonly type = DELETE_STATION_COMPLETED;

  constructor(public payload: BulkResponseDataInterface) {}
}

export class BulkDeleteStations implements Action {
  readonly type = BULK_DELETE_STATIONS;

  constructor(public stationIds: number[]) {}
}

export class BulkDeleteStationsCompleted implements Action {
  readonly type = BULK_DELETE_STATIONS_COMPLETED;

  constructor(public payload: BulkResponseDataInterface) {}
}

export class FetchError implements Action {
  readonly type = FETCH_ERROR;
  constructor(public payload: object) {}
}

export type StationsActions =
  | StationsDataLoad
  | StationsDataLoaded
  | EditStation
  | EditStationCompleted
  | BulkEditStations
  | BulkEditStationsCompleted
  | AddStation
  | AddStationCompleted
  | DeleteStation
  | DeleteStationCompleted
  | BulkDeleteStations
  | BulkDeleteStationsCompleted
  | FetchError;
