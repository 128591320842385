import { Action } from '@ngrx/store';
import { NumberInputConfigurationSetInterface } from './number-input.model';

export const SET_CONFIGURATION = '[NUMBER INPUT] Set number input configuration';
export const SET_FORM_IS_SUBMITTED = '[NUMBER INPUT] The form of number input is submitted';
export const RESET_NUMBER_INPUT_STORE = '[NUMBER INPUT] Reset store data of number input reducer';

export class SetConfiguration implements Action {
  readonly type = SET_CONFIGURATION;

  constructor(public configuration: NumberInputConfigurationSetInterface) {}
}

export class SetFormIsSubmitted implements Action {
  readonly type = SET_FORM_IS_SUBMITTED;

  constructor(public newValue: boolean) {}
}

export class ResetNumberInputStore implements Action {
  readonly type = RESET_NUMBER_INPUT_STORE;

  constructor() {}
}

export type NumberInputActions = SetConfiguration | SetFormIsSubmitted | ResetNumberInputStore;
