import { IMyReportMetricsState } from '../../../view/reports/my-reports/my-report-metrics/my-report-metrics.model';
import * as Actions from './my-report-metrics.actions';

export const myReportMetricsState: IMyReportMetricsState = {
  myReportMetricsData: null,
  myReportMetricsLoading: false,
  myReportMetricsLoaded: false,
  selectedReportName: null,
  requiredChartData: null,
  filterData: null,
  oeeCalculationDataHourly: null,
  oeeCalculationDataDaily: null,
  oeeCalculationDataWeekly: null,
  oeeCalculationDataMonthly: null,
  oeeCalculationDataHourlyLoading: false,
  oeeCalculationDataHourlyLoaded: false,
  oeeCalculationDataDailyLoading: false,
  oeeCalculationDataDailyLoaded: false,
  oeeCalculationDataWeeklyLoading: false,
  oeeCalculationDataWeeklyLoaded: false,
  oeeCalculationDataMonthlyLoading: false,
  oeeCalculationDataMonthlyLoaded: false,
};

export function myReportMetricsReducer(
  state: IMyReportMetricsState = myReportMetricsState,
  action: Actions.TMyReportMetricsActions,
): IMyReportMetricsState {
  switch (action.type) {
    case Actions.EMyReportMetricsActionTypes.FetchMyReportMetricsData:
      return {
        ...state,
        myReportMetricsLoading: true,
        myReportMetricsLoaded: false,
      };
    case Actions.EMyReportMetricsActionTypes.FetchMyReportMetricsDataCompleted:
      return {
        ...state,
        myReportMetricsLoading: false,
        myReportMetricsLoaded: true,
        myReportMetricsData: action.payload,
      };
    case Actions.EMyReportMetricsActionTypes.GetOeeCalculationDataHourly:
      return {
        ...state,
        oeeCalculationDataHourlyLoading: true,
        oeeCalculationDataHourlyLoaded: false,
      };
    case Actions.EMyReportMetricsActionTypes.GetOeeCalculationDataHourlyCompleted:
      return {
        ...state,
        oeeCalculationDataHourly: action.payload,
        oeeCalculationDataHourlyLoading: false,
        oeeCalculationDataHourlyLoaded: true,
      };
    case Actions.EMyReportMetricsActionTypes.GetOeeCalculationDataDaily:
      return {
        ...state,
        oeeCalculationDataDailyLoading: true,
        oeeCalculationDataDailyLoaded: false,
      };
    case Actions.EMyReportMetricsActionTypes.GetOeeCalculationDataDailyCompleted:
      return {
        ...state,
        oeeCalculationDataDaily: action.payload,
        oeeCalculationDataDailyLoading: false,
        oeeCalculationDataDailyLoaded: true,
      };
    case Actions.EMyReportMetricsActionTypes.GetOeeCalculationDataWeekly:
      return {
        ...state,
        oeeCalculationDataWeeklyLoading: true,
        oeeCalculationDataWeeklyLoaded: false,
      };
    case Actions.EMyReportMetricsActionTypes.GetOeeCalculationDataWeeklyCompleted:
      return {
        ...state,
        oeeCalculationDataWeekly: action.payload,
        oeeCalculationDataWeeklyLoading: false,
        oeeCalculationDataWeeklyLoaded: true,
      };
    case Actions.EMyReportMetricsActionTypes.GetOeeCalculationDataMonthly:
      return {
        ...state,
        oeeCalculationDataMonthlyLoading: true,
        oeeCalculationDataMonthlyLoaded: false,
      };
    case Actions.EMyReportMetricsActionTypes.GetOeeCalculationDataMonthlyCompleted:
      return {
        ...state,
        oeeCalculationDataMonthly: action.payload,
        oeeCalculationDataMonthlyLoading: false,
        oeeCalculationDataMonthlyLoaded: true,
      };
    case Actions.EMyReportMetricsActionTypes.SetSelectedReportName:
      return {
        ...state,
        selectedReportName: action.payload,
      };
    case Actions.EMyReportMetricsActionTypes.SetRequiredChartData:
      return {
        ...state,
        requiredChartData: {
          ...state.requiredChartData,
          ...action.requiredChartData,
        },
      };
    case Actions.EMyReportMetricsActionTypes.SetFilterData:
      return {
        ...state,
        filterData: action.filterData,
      };
    default:
      return state;
  }
}
