import { ShiftSummaryDataInterface, ShiftSummaryStateInterface, TaskGroupDataInterface } from './shift-summary.model';
import { ShiftSummaryActions, ShiftSummaryActionTypes } from './shift-summary.actions';

export const shiftSummaryInitialState: ShiftSummaryStateInterface = {
  shiftIsLoaded: false,
  shiftIsLoading: false,
  shiftSummaryIsLoaded: false,
  shiftSummaryIsLoading: false,
  commentsIsLoading: false,
  shiftSummaryData: [],
  totalDurations: null,
  taskGroups: [],
  shifts: [],
  isFiltersLoading: false,
  isFiltersLoaded: false,
  isFiltersSaving: false,
  filters: null,
  selectedFilters: null,
  setSelectedFiltersIsLoading: false,
  tableGroupSettings: [],
  tableSettings: [],
  customConfiguration: [],
  columnNames: null,
  shiftComments: [],
  errors: [],
};

export function shiftSummaryReducer(
  state: ShiftSummaryStateInterface = shiftSummaryInitialState,
  action: ShiftSummaryActions,
): ShiftSummaryStateInterface {
  switch (action.type) {
    case ShiftSummaryActionTypes.StartShiftSummaryDataLoading:
      return {
        ...state,
        shiftIsLoaded: false,
        shiftIsLoading: true,
      };

    case ShiftSummaryActionTypes.ShiftSummaryDataLoaded:
      return {
        ...state,
        shiftIsLoaded: true,
        shiftIsLoading: false,
        shifts: action.payload.shifts,
      };

    case ShiftSummaryActionTypes.GetShiftDataAndGroups:
      return {
        ...state,
        shiftSummaryIsLoading: true,
        shiftSummaryIsLoaded: false,
      };

    case ShiftSummaryActionTypes.GetShiftDataAndGroupsLoaded:
      const shiftSummaryData = action.payload.data;

      return {
        ...state,
        shiftSummaryData,
        shiftSummaryIsLoading: false,
        shiftSummaryIsLoaded: true,
        taskGroups: action.payload.taskGroups,
        columnNames: action.payload.columnNames,
        shiftComments: action.payload.shiftComments,
        totalDurations: action.payload.totalDurations,
      };

    case ShiftSummaryActionTypes.StartFiltersSaving:
      return {
        ...state,
        isFiltersSaving: true,
      };

    case ShiftSummaryActionTypes.FiltersSaved:
      return {
        ...state,
        isFiltersSaving: false,
      };

    case ShiftSummaryActionTypes.StartShiftSummaryCommentSave:
      return {
        ...state,
        commentsIsLoading: true,
      };

    case ShiftSummaryActionTypes.StartShiftSummaryCommentSaved:
      return {
        ...state,
        shiftSummaryIsLoaded: false,
        commentsIsLoading: false,
      };

    case ShiftSummaryActionTypes.SetSelectedFilters:
      return {
        ...state,
        setSelectedFiltersIsLoading: true,
        selectedFilters: action.filters,
      };

    case ShiftSummaryActionTypes.SetSelectedFiltersLoaded:
      return {
        ...state,
        shiftSummaryIsLoaded: false,
        setSelectedFiltersIsLoading: false,
      };

    case ShiftSummaryActionTypes.SetTableSettings:
      return {
        ...state,
        tableGroupSettings: action.tableGroupSettings,
        tableSettings: action.tableColumnSettings,
        customConfiguration: [
          {
            name: 'erpCalculation',
            value: [...(action.erpCalculationUseCheckedIn ? ['erpCalculationUseCheckedIn'] : [])],
          },
        ],
      };

    case ShiftSummaryActionTypes.ClearState:
      return { ...shiftSummaryInitialState };

    case ShiftSummaryActionTypes.FetchError:
      const errors = [...state.errors, action.payload];
      return {
        ...state,
        errors,
        shiftIsLoaded: false,
        shiftIsLoading: false,
        shiftSummaryIsLoading: false,
        commentsIsLoading: false,
        isFiltersLoading: false,
        isFiltersSaving: false,
      };

    default:
      return state;
  }
}
