import { KpiTargetsStateInterface } from './kpi-targets.model';
import * as Actions from './kpi-targets.actions';
import { HelperService } from '../../../../shared/service/helper.service';

export const kpiTargetState: KpiTargetsStateInterface = {
  kpiTargetsLoading: false,
  kpiTargetsLoaded: false,
  kpiTargetsData: null,
  editKpiTargetLoading: false,
  editKpiTargetLoaded: false,
  bulkEditKpiTargetLoading: false,
  bulkEditKpiTargetLoaded: false,
  filters: null,
};

export function kpiTargetReducer(
  state: KpiTargetsStateInterface = kpiTargetState,
  action: Actions.KpiTargetsActions,
): KpiTargetsStateInterface {
  switch (action.type) {
    case Actions.KPI_TARGETS_DATA_LOADING:
      return {
        ...state,
        ...{
          kpiTargetsLoading: true,
          kpiTargetsLoaded: false,
        },
      };
    case Actions.KPI_TARGETS_DATA_LOADED:
      const kpiTargetsData = HelperService.cloneDeep(action.payload.data).map((value, index) => {
        return {
          ...value,
          id: index,
        };
      });

      return {
        ...state,
        ...{
          kpiTargetsData: action.payload,
          kpiTargetsLoading: false,
          kpiTargetsLoaded: true,
        },
      };
    case Actions.DELETE_KPI_TARGETS:
      return {
        ...state,
        editKpiTargetLoading: true,
        editKpiTargetLoaded: false,
      };
    case Actions.DELETE_KPI_TARGETS_COMPLETED:
      return {
        ...state,
        editKpiTargetLoading: false,
        editKpiTargetLoaded: true,
      };
    case Actions.EDIT_KPI_TARGET:
      return {
        ...state,
        editKpiTargetLoading: true,
        editKpiTargetLoaded: false,
      };
    case Actions.EDIT_KPI_TARGET_COMPLETED:
      return {
        ...state,
        editKpiTargetLoading: false,
        editKpiTargetLoaded: true,
      };
    case Actions.BULK_EDIT_KPI_TARGET:
      return {
        ...state,
        bulkEditKpiTargetLoading: true,
        bulkEditKpiTargetLoaded: false,
      };
    case Actions.BULK_EDIT_KPI_TARGET_COMPLETED:
      return {
        ...state,
        bulkEditKpiTargetLoading: false,
        bulkEditKpiTargetLoaded: true,
      };
    default:
      return state;
  }
}
