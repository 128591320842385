import { Injectable } from '@angular/core';
import {
  CronJobsConfig,
  CronJobsSelectOption,
  CronJobsValidationConfig,
  OptionType,
} from '../scw-mat-cron-input.model';

@Injectable()
export class CronDataService {
  private defaultConfig: CronJobsConfig = {
    bootstrap: true,
    multiple: false,
  };

  private defaultValidateConfig: CronJobsValidationConfig = {
    validate: false,
  };

  private daysOfWeekPosix: CronJobsSelectOption[] = [
    { value: 0, label: 'Sunday' },
    { value: 1, label: 'Monday' },
    { value: 2, label: 'Tuesday' },
    { value: 3, label: 'Wednesday' },
    { value: 4, label: 'Thursday' },
    { value: 5, label: 'Friday' },
    { value: 6, label: 'Saturday' },
  ];

  private numeral: CronJobsSelectOption[] = [
    { value: 1, label: '1st' },
    { value: 2, label: '2nd' },
    { value: 3, label: '3rd' },
    { value: 4, label: '4th' },
    { value: 5, label: '5th' },
    { value: 6, label: '6th' },
    { value: 7, label: '7th' },
    { value: 8, label: '8th' },
    { value: 9, label: '9th' },
    { value: 10, label: '10th' },
    { value: 11, label: '11th' },
    { value: 12, label: '12th' },
    { value: 13, label: '13th' },
    { value: 14, label: '14th' },
    { value: 15, label: '15th' },
    { value: 16, label: '16th' },
    { value: 17, label: '17th' },
    { value: 18, label: '18th' },
    { value: 19, label: '19th' },
    { value: 20, label: '20th' },
    { value: 21, label: '21st' },
    { value: 22, label: '22nd' },
    { value: 23, label: '23rd' },
    { value: 24, label: '24th' },
    { value: 25, label: '25th' },
    { value: 26, label: '26th' },
    { value: 27, label: '27th' },
    { value: 28, label: '28th' },
    { value: 29, label: '29th' },
    { value: 30, label: '30th' },
    { value: 31, label: '31st' },
  ];

  private monthOptions: CronJobsSelectOption[] = [
    { value: 1, label: 'January' },
    { value: 2, label: 'February' },
    { value: 3, label: 'March' },
    { value: 4, label: 'April' },
    { value: 5, label: 'May' },
    { value: 6, label: 'June' },
    { value: 7, label: 'July' },
    { value: 8, label: 'August' },
    { value: 9, label: 'September' },
    { value: 10, label: 'October' },
    { value: 11, label: 'November' },
    { value: 12, label: 'December' },
  ];

  private baseFrequencyOptions: CronJobsSelectOption[] = [
    { value: 2, type: OptionType.hour, label: 'Hour' },
    { value: 3, type: OptionType.day, label: 'Day' },
    { value: 4, type: OptionType.week, label: 'Week' },
    { value: 5, type: OptionType.month, label: 'Month' },
    { value: 6, type: OptionType.year, label: 'Year' },
  ];

  private hourOptions: CronJobsSelectOption[];
  private minuteOptions: CronJobsSelectOption[];

  public get baseFrequency(): CronJobsSelectOption[] {
    return [...this.baseFrequencyOptions];
  }

  public get daysOfMonth(): CronJobsSelectOption[] {
    return [...this.numeral];
  }

  public get months(): CronJobsSelectOption[] {
    return [...this.monthOptions];
  }

  public get hours(): CronJobsSelectOption[] {
    return [...this.hourOptions];
  }

  public get minutes(): CronJobsSelectOption[] {
    return [...this.minuteOptions];
  }

  constructor() {
    this.hourOptions = [];
    this.minuteOptions = [];

    for (let x = 0; x < 24; x += 1) {
      this.hourOptions.push(<CronJobsSelectOption>{ value: x, label: `${x}` });
    }

    for (let x = 0; x < 60; x += 1) {
      this.minuteOptions.push(<CronJobsSelectOption>{ value: x, label: `${x}` });
    }
  }

  public getConfig(config: CronJobsConfig = {}): CronJobsConfig {
    return {
      ...this.defaultConfig,
      ...config,
    };
  }

  public getValidate(validateConfig: CronJobsValidationConfig = {}): CronJobsValidationConfig {
    return {
      ...this.defaultValidateConfig,
      ...validateConfig,
    };
  }

  public getDaysOfWeek(): CronJobsSelectOption[] {
    return [...this.daysOfWeekPosix];
  }
}
