import {
  IndependentFilterProductActions,
  IndependentFilterProductActionTypes,
} from './no-dependency-product.actions';
import { ProductCRUDInterface } from '../../../shared/component/filter/filter.class';

export interface IndependentFilterProductState {
  isLoading: boolean;
  isLoaded: boolean;
  data: ProductCRUDInterface[];
  selectedProducts: ProductCRUDInterface[];
}

export const initialIndependentFilterProductState: IndependentFilterProductState = {
  isLoaded: false,
  isLoading: false,
  data: [],
  selectedProducts: [],
};

export function independentFilterProductReducer(
  state = initialIndependentFilterProductState,
  action: IndependentFilterProductActions,
): IndependentFilterProductState {
  switch (action.type) {
    case IndependentFilterProductActionTypes.StartLoadIndependentFilterProducts:
      return { ...state, isLoading: true };

    case IndependentFilterProductActionTypes.IndependentFilterProductsLoaded:
      return { ...state, data: action.data, isLoaded: true, isLoading: false };

    case IndependentFilterProductActionTypes.ResetIndependentProductStore:
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
        data: [],
        selectedProducts: [],
      };

    case IndependentFilterProductActionTypes.SetSelectedProductOptions:
      return {
        ...state,
        selectedProducts: action.selectedProducts,
      };
    default:
      return state;
  }
}
