import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { GetManyResponseInterface } from '../../model/interface/crud-response-interface.model';
import { KpiTargetsService } from '../../../store/settings/kpi-metrics/kpi-targets/kpi-targets.service';
import { map } from 'rxjs/operators';
import { KpiTargetInterface } from '../../../store/settings/kpi-metrics/kpi-targets/kpi-targets.model';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { IOeeCalendarChartColorData } from '../../../store/reports/oee-calendar/oee-calendar.model';

export enum EOeeColor {
  GREEN = '#6FC764',
  YELLOW = '#F6C143',
  RED = '#FF726B',
}

@Injectable({
  providedIn: 'root',
})
export class OeeService {
  private readonly minPercentage: number = 0;
  private readonly maxPercentage: number = 100;
  private readonly defaultUpperLimit: number = 60;
  private readonly defaultLowerLimit: number = 40;

  constructor(private kpiTargetsService: KpiTargetsService) {}

  public getAverageOeeTargetData(params: HttpParams): Observable<number> {
    return this.kpiTargetsService.getKpiTargets(params).pipe(
      map((response: GetManyResponseInterface<KpiTargetInterface>): number => {
        return _.mean(
          response.data.map((kpiTarget: KpiTargetInterface) => {
            return kpiTarget.oeeTarget;
          }),
        );
      }),
    );
  }

  public getOeeCalendarChartColorData(params: HttpParams): Observable<IOeeCalendarChartColorData[]> {
    return this.getAverageOeeTargetData(params).pipe(
      map((averageOeeTarget: number): IOeeCalendarChartColorData[] => {
        const upperLimit: number = averageOeeTarget ? averageOeeTarget : this.defaultUpperLimit;
        const lowerLimit: number = averageOeeTarget ? (upperLimit * 80) / 100 : this.defaultLowerLimit;

        return [
          { min: this.minPercentage, max: lowerLimit, color: EOeeColor.RED },
          { min: lowerLimit, max: upperLimit, color: EOeeColor.YELLOW },
          { min: upperLimit, max: this.maxPercentage, color: EOeeColor.GREEN },
        ];
      }),
    );
  }
}
