import { Action } from '@ngrx/store';
import { GenericCrudRequestConstructionParameters } from '../../../shared/model/interface/generic-api-request.model';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import {
  NotificationSetupCRUDBaseInterface,
  NotificationSetupCRUDInterface,
  NotificationSetupFormDropdownDataInterface,
} from './notification-setup.model';
import { HttpErrorResponse } from '@angular/common/http';

export enum NotificationSetupActionTypes {
  LoadNotificationSetups = '[NotificationSetup] Load NotificationSetups',
  NotificationSetupsLoaded = '[NotificationSetup] NotificationSetups Loaded',
  LoadFormDropdownData = '[NotificationSetup] Load Form Dropdown Data',
  FormDropdownDataLoaded = '[NotificationSetup] Form Dropdown Loaded',
  CreateNotificationSetup = '[NotificationSetup] Create NotificationSetup',
  CreateNotificationSetupCompleted = '[NotificationSetup] Create NotificationSetup Completed',
  UpdateNotificationSetup = '[NotificationSetup] Update NotificationSetup',
  UpdateNotificationSetupCompleted = '[NotificationSetup] Update NotificationSetup Completed',
  DeleteNotificationSetup = '[NotificationSetup] Delete NotificationSetup',
  DeleteNotificationSetupCompleted = '[NotificationSetup] Delete NotificationSetup Completed',
  BulkDeleteNotificationSetup = '[NotificationSetup] Bulk Delete NotificationSetup',
  BulkDeleteNotificationSetupCompleted = '[NotificationSetup] Bulk Delete NotificationSetup Completed',
  FetchError = '[NotificationSetup] Fetch Error',
}

export class LoadNotificationSetups implements Action {
  readonly type = NotificationSetupActionTypes.LoadNotificationSetups;
  constructor(public parameters: GenericCrudRequestConstructionParameters) {}
}

export class NotificationSetupsLoaded implements Action {
  readonly type = NotificationSetupActionTypes.NotificationSetupsLoaded;
  constructor(public payload: GetManyResponseInterface<NotificationSetupCRUDInterface>) {}
}

export class LoadFormDropdownData implements Action {
  readonly type = NotificationSetupActionTypes.LoadFormDropdownData;
}

export class FormDropdownDataLoaded implements Action {
  readonly type = NotificationSetupActionTypes.FormDropdownDataLoaded;
  constructor(public payload: GetManyResponseInterface<NotificationSetupFormDropdownDataInterface>) {}
}

export class CreateNotificationSetup implements Action {
  readonly type = NotificationSetupActionTypes.CreateNotificationSetup;
  constructor(public requestParameters: NotificationSetupCRUDBaseInterface) {}
}

export class CreateNotificationSetupCompleted implements Action {
  readonly type = NotificationSetupActionTypes.CreateNotificationSetupCompleted;
  constructor(public payload: BaseOneResponseInterface<NotificationSetupCRUDInterface>) {}
}

export class UpdateNotificationSetup implements Action {
  readonly type = NotificationSetupActionTypes.UpdateNotificationSetup;
  constructor(public id: number, public requestParameters: NotificationSetupCRUDBaseInterface) {}
}

export class UpdateNotificationSetupCompleted implements Action {
  readonly type = NotificationSetupActionTypes.UpdateNotificationSetupCompleted;
  constructor(public payload: BaseOneResponseInterface<NotificationSetupCRUDInterface>) {}
}

export class DeleteNotificationSetup implements Action {
  readonly type = NotificationSetupActionTypes.DeleteNotificationSetup;
  constructor(public id: number) {}
}

export class DeleteNotificationSetupCompleted implements Action {
  readonly type = NotificationSetupActionTypes.DeleteNotificationSetupCompleted;
}

export class BulkDeleteNotificationSetup implements Action {
  readonly type = NotificationSetupActionTypes.BulkDeleteNotificationSetup;
  constructor(public ids: number[]) {}
}

export class BulkDeleteNotificationSetupCompleted implements Action {
  readonly type = NotificationSetupActionTypes.BulkDeleteNotificationSetupCompleted;
  constructor(public payload: BulkResponseDataInterface) {}
}

export class FetchError implements Action {
  readonly type = NotificationSetupActionTypes.FetchError;

  constructor(public payload: HttpErrorResponse) {}
}

export type NotificationSetupActions =
  | LoadNotificationSetups
  | NotificationSetupsLoaded
  | LoadFormDropdownData
  | FormDropdownDataLoaded
  | CreateNotificationSetup
  | CreateNotificationSetupCompleted
  | UpdateNotificationSetup
  | UpdateNotificationSetupCompleted
  | DeleteNotificationSetup
  | DeleteNotificationSetupCompleted
  | BulkDeleteNotificationSetup
  | BulkDeleteNotificationSetupCompleted;
