import { FilterLineActions, FilterLineActionTypes } from './line.actions';
import { LineCRUDInterface } from '../../../shared/component/filter/filter.class';

export interface FilterLineState {
  isLoading: boolean;
  isLoaded: boolean;
  data: LineCRUDInterface[];
}

export const initialFilterLineState: FilterLineState = {
  isLoaded: false,
  isLoading: false,
  data: [],
};

export function filterLineReducer(
  state: FilterLineState = initialFilterLineState,
  action: FilterLineActions,
): FilterLineState {
  switch (action.type) {
    case FilterLineActionTypes.StartLoadFilterLines:
      return { ...state, isLoading: true };
    case FilterLineActionTypes.FilterLinesLoaded:
      return { ...state, data: action.data, isLoaded: true, isLoading: false };
    case FilterLineActionTypes.ResetLineStore:
      return { ...state, data: [], isLoaded: false, isLoading: false };
    default:
      return state;
  }
}
