import { Action } from '@ngrx/store';
import { DropdownOptionInterface } from '../../shared/component/scw-mat-ui/scw-mat-select/scw-mat-select.model';

export enum ComparisonFilterCardActionTypes {
  SetComparisonGroupType = '[Comparison Filter Card] Set Comparison Group Type',
}

export class SetComparisonGroupType implements Action {
  readonly type = ComparisonFilterCardActionTypes.SetComparisonGroupType;

  constructor(public comparisonGroupType: DropdownOptionInterface[]) {}
}

export type ComparisonFilterCardActions = SetComparisonGroupType;
