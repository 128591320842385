import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import {
  CellTypes,
  CreateExcelInterface,
  CreateExcelSheetInterface,
  DownloadExcelFiltersInterface,
  ExcelColumnKeysInterface,
  ExcelColumnWidthEnum,
  ExcelHelperService,
  ExcelSheetTypeEnum,
  twoDigitNumberFormat,
} from '../../../../shared/service/excel/excel-helper.service';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../../oee.reducer';
import { TranslateService } from '@ngx-translate/core';
import {
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../../shared/model/interface/crud-response-interface.model';
import { forkJoin, Observable, Subject } from 'rxjs';
import * as moment from 'moment-timezone';
import { excelDateFormat, excelTimeFormat } from '../../../../shared/model/enum/excel-date-format';
import * as _ from 'lodash';
import { ValueType, Workbook, Worksheet } from 'exceljs';
import * as ObjectActions from './lines.actions';
import * as AppActions from '../../../app/actions';
import { LineCRUDInterface } from '../../../../shared/component/filter/filter.class';
import {
  CountEntryEnum,
  CountEntryLanguageKeys,
  CountTypeEnum,
  CountTypeLanguageKeys,
  OeeEnum,
  OeeEnumTranslation,
  StatusesEnum,
  StatusesLanguageKeys,
  TrueFalse,
  YesNoLanguageKeys,
} from '../../../../../constants';
import {
  ExcelDropdownDataWithDescription,
  ExcelDropdownDataWithDescriptionFormatted,
  GetManyShiftPlanItemsCrudResponse,
  IExcelDropdownWithStringId,
  ILineExcelResponse,
  LineExcelInterface,
  LinesExcelDataInterface,
  LinesExcelDropdownContentInterface,
  ShiftPlanInterface,
} from './lines.model';
import { takeUntil } from 'rxjs/operators';
import { GetManyHomeMetricSetAssignmentCrudResponse } from '../../../home-screen-metric-settings/home-screen-metric-settings.model';
import { SimplifiedDataInterface } from '../../tasks/tasks.model';
import { LineTypeInterface } from '../../../site-line-selection/site-line-selection.model';
import { ActivityInterface } from '../../../task-groups/task-groups.model';
import { HelperService } from '../../../../shared/service/helper.service';
import { DecimalHelper } from '../../../../shared/helper/decimal/decimal-helper';
import { LineAvailabilityPlanItemInterface } from '../../../line-availability/line-availability.model';
import { ExcelDropdownInterface } from '../../users/users.model';

@Injectable({
  providedIn: 'root',
})
export class LinesExcelService {
  private readonly urls = {
    SITES_URL: `${this.baseUrl}/sites`,
    LINES_URL: `${this.baseUrl}/lines`,
    LINE_TYPES_URL: `${this.baseUrl}/line-types`,
    HOME_METRIC_SETS_URL: `${this.baseUrl}/home-metric-sets`,
    SHIFT_PLANS_URL: `${this.baseUrl}/scheduler/shift-plans`,
    ACTIVITY_URL: `${this.baseUrl}/activities`,
    BULK_SAVE_URL: `${this.baseUrl}/lines/bulk/save`,
  };
  private readonly destroySubject: Subject<boolean> = new Subject<boolean>();
  private readonly standardSpeed: string = 'general.excel.column.standardSpeed';
  private readonly excelUploadLimit: number = 1000;
  private timezone: string = 'utc';
  private dateFormat$: string;
  private timeFormat$: string;
  private activities: ActivityInterface[];

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly excelHelper: ExcelHelperService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly translate: TranslateService,
    private readonly helperService: HelperService,
    private readonly decimalHelper: DecimalHelper,
  ) {
    this.store
      .select('user')
      .pipe(takeUntil(this.destroySubject))
      .subscribe((state) => {
        if (state.isUserLoaded) {
          this.timezone = state.timezone;

          if (state.locale !== '') {
            this.dateFormat$ = excelDateFormat[state.locale];
            this.timeFormat$ = excelTimeFormat[state.locale];
          }

          this.destroySubject.next(true);
          this.destroySubject.complete();
        }
      });
  }

  public getHomeMetricSets(params: HttpParams): Observable<GetManyHomeMetricSetAssignmentCrudResponse> {
    return this.http.get<GetManyHomeMetricSetAssignmentCrudResponse>(this.urls.HOME_METRIC_SETS_URL, {
      params,
    });
  }

  public getShiftPlans(params: HttpParams): Observable<GetManyShiftPlanItemsCrudResponse> {
    return this.http.get<GetManyShiftPlanItemsCrudResponse>(this.urls.SHIFT_PLANS_URL, {
      params,
    });
  }

  public getSite(params: HttpParams, id: number): Observable<{ id: number; name: string }> {
    return this.http.get<{ id: number; name: string }>(`${this.urls.SITES_URL}/${id}`, { params });
  }

  public getLineTypes(params: HttpParams): Observable<{ id: number; name: string }> {
    return this.http.get<{ id: number; name: string }>(`${this.urls.LINE_TYPES_URL}`, { params });
  }

  public getLines(params: HttpParams): Observable<GetManyResponseInterface<Partial<LineCRUDInterface>>> {
    return this.http.get<GetManyResponseInterface<Partial<LineCRUDInterface>>>(this.urls.LINES_URL, { params });
  }

  public getActivities(): Promise<GetManyResponseInterface<ActivityInterface>> {
    const params: HttpParams = new HttpParams().set('limit', String(this.excelUploadLimit));

    return new Promise((resolve, reject) => {
      this.http.get(this.urls.ACTIVITY_URL, { params }).subscribe(
        (response: GetManyResponseInterface<ActivityInterface>) => {
          resolve(response);
        },
        (error: HttpErrorResponse) => {
          reject(error);
        },
      );
    });
  }

  public async downloadExcel(
    withData: boolean,
    filters: DownloadExcelFiltersInterface,
    withErrorColumn: boolean = false,
    data?: LineExcelInterface[],
  ): Promise<void> {
    const httpParams: HttpParams = new HttpParams().set('limit', String(this.excelUploadLimit));
    const lineTypesParams: HttpParams = httpParams.set('s', JSON.stringify({ status: { $in: [StatusesEnum.ACTIVE] } }));
    const lineParams: HttpParams = httpParams
      .set('s', JSON.stringify({ siteId: { $eq: filters.siteId } }))
      .set('page', filters.selectedDownloadOffset)
      .append('join', 'homeMetricSetAssignment||homeMetricSetId');
    const homeMetricParams: HttpParams = httpParams.set('s', JSON.stringify({ siteId: { $eq: filters.siteId } }));
    const siteParams: HttpParams = httpParams.set('fields', 'id,name');
    const shiftParams: HttpParams = httpParams
      .append('join', 'schedulerShiftPlansSiteAssignment')
      .append('join', 'schedulerShiftPlanItem')
      .set(
        's',
        JSON.stringify({
          'schedulerShiftPlansSiteAssignment.siteId': { $eq: filters.siteId.toString() },
          isOfficial: { $eq: true },
        }),
      );
    const observables: any = [
      this.getLineTypes(lineTypesParams),
      this.getSite(siteParams, filters.siteId),
      this.getHomeMetricSets(homeMetricParams),
      this.getShiftPlans(shiftParams),
    ];
    this.activities = (await this.getActivities()).data;

    if (withData && !data) {
      observables.push(this.getLines(lineParams));
    }

    forkJoin(observables).subscribe((responseList) => {
      const lineTypes: LineTypeInterface[] = _.get(responseList, '0.data', [])
        .map((lineType: LineTypeInterface) => {
          return { ...lineType, name: lineType.lineType };
        })
        .sort(this.helperService.dropdownOptionCompareFunction);
      const site: SimplifiedDataInterface = _.get(responseList, '1.data', []);
      const homeMetrics: SimplifiedDataInterface[] = _.get(responseList, '2.data', []);
      const shiftPlans: ShiftPlanInterface[] = (_.get(responseList, '3.data', []) as ShiftPlanInterface[]).filter(
        (item: ShiftPlanInterface) =>
          item.schedulerShiftPlanItem.length &&
          item.schedulerShiftPlanItem.some(
            (planItem: LineAvailabilityPlanItemInterface) => !planItem.schedulerShiftExceptionId,
          ),
      );
      const statuses: ExcelDropdownDataWithDescription[] = [
        { id: StatusesEnum.ACTIVE, description: this.translate.instant(StatusesLanguageKeys.ACTIVE) },
        { id: StatusesEnum.INACTIVE, description: this.translate.instant(StatusesLanguageKeys.INACTIVE) },
      ];
      const oeeFormula: IExcelDropdownWithStringId[] = [
        { id: OeeEnum.APQ, description: OeeEnumTranslation.apq },
        { id: OeeEnum.AP, description: OeeEnumTranslation.ap },
        { id: OeeEnum.A, description: OeeEnumTranslation.a },
      ];
      const countEntries: ExcelDropdownDataWithDescription[] = [
        { id: CountEntryEnum.CUMULATIVE, description: this.translate.instant(CountEntryLanguageKeys.CUMULATIVE) },
        { id: CountEntryEnum.INCREMENTAL, description: this.translate.instant(CountEntryLanguageKeys.INCREMENTAL) },
      ];
      const countTypes: ExcelDropdownDataWithDescription[] = [
        { id: CountTypeEnum.INITIAL_SCRAP, description: this.translate.instant(CountTypeLanguageKeys.INITIAL_SCRAP) },
        { id: CountTypeEnum.INITIAL_YIELD, description: this.translate.instant(CountTypeLanguageKeys.INITIAL_YIELD) },
        { id: CountTypeEnum.YIELD_SCRAP, description: this.translate.instant(CountTypeLanguageKeys.YIELD_SCRAP) },
      ];
      const automatedPhaseComments: IExcelDropdownWithStringId[] = [
        { id: TrueFalse['1'], description: this.translate.instant(YesNoLanguageKeys.YES) },
        { id: TrueFalse['0'], description: this.translate.instant(YesNoLanguageKeys.NO) },
      ];
      const automatedPhaseDuration: IExcelDropdownWithStringId[] = [
        { id: TrueFalse['1'], description: this.translate.instant(YesNoLanguageKeys.YES) },
        { id: TrueFalse['0'], description: this.translate.instant(YesNoLanguageKeys.NO) },
      ];
      const automatedRunDuration: IExcelDropdownWithStringId[] = [
        { id: TrueFalse['1'], description: this.translate.instant(YesNoLanguageKeys.YES) },
        { id: TrueFalse['0'], description: this.translate.instant(YesNoLanguageKeys.NO) },
      ];
      const sheetTitle: string = this.translate.instant('excel.items.lines');
      const excelName: string = `${sheetTitle} ${moment()
        .tz(this.timezone)
        .format(this.dateFormat$)}`;
      const defaultHomeMetricsIndex: number = homeMetrics.findIndex(
        (homeMetric: SimplifiedDataInterface) => homeMetric.id === 1,
      );

      if (defaultHomeMetricsIndex !== -1) {
        homeMetrics[defaultHomeMetricsIndex].name = this.translate.instant('lines.excel.homeMetricsDefault');
      }

      const content: LinesExcelDropdownContentInterface = {
        site,
        lineTypes,
        homeMetrics,
        shiftPlans,
        statuses,
        oeeFormula,
        countEntries,
        countTypes,
        automatedPhaseComments,
        automatedPhaseDuration,
        automatedRunDuration,
      };
      const excelOptions: CreateExcelInterface = this.getExcelColumns(content, withErrorColumn);

      if (withData) {
        excelOptions.data = (!data ? _.get(responseList, '4.data', []) : data).map((line: LineExcelInterface) => {
          return this.getExcelFormattedData(line, automatedPhaseComments, content, !_.isEmpty(data));
        });
      }

      const worksheets: CreateExcelSheetInterface[] = [
        {
          withData,
          sheetTitle,
          sheetType: ExcelSheetTypeEnum.TABLE,
          params: excelOptions,
          isDisabledColumnsFirstLine: true,
          addDateTimeFormula: true,
          excelRowFormatLimit: filters.limit + 1,
        },
      ];

      this.excelHelper
        .createExcel(excelName, worksheets, this.timezone, this.dateFormat$, this.timeFormat$, false)
        .then(
          () => {
            this.store.dispatch(new ObjectActions.DownloadLinesExcelCompleted());
            this.store.dispatch(new AppActions.HideLoader());
          },
          () => {
            this.store.dispatch(new ObjectActions.FetchError({}));
            this.store.dispatch(new AppActions.HideLoader());
          },
        );
    });
  }

  private getExcelColumns(content: LinesExcelDropdownContentInterface, withErrorColumn: boolean): CreateExcelInterface {
    const booleanDropdownOptions: ExcelDropdownInterface[] = this.excelHelper.getExcelBooleanDropdownOptions();
    const numberInputTitle: string = 'general.excel.column.promptNumberInputTitle';
    const decimalInputPrompt: string = 'general.excel.column.promptDecimalInput';
    const excelColumns: CreateExcelInterface = {
      columns: [
        {
          header: this.translate.instant('general.excel.column.siteName'),
          key: 'siteId',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: [content.site],
            prop: 'siteDropdown.name',
            dataProperty: 'siteDropdown.name',
            dataId: 'siteDropdown.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('general.excel.column.lineTitle'),
          key: 'title',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            formulae: [],
            errorStyle: 'Error',
            showInputMessage: true,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('general.excel.column.activities.name'),
          key: 'activityNames',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            formulae: [],
            errorStyle: 'Error',
            showInputMessage: true,
            prompt: this.translate.instant('general.excel.column.activities.prompt'),
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('excel.headers.status'),
          key: 'statusId',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: content.statuses,
            prop: 'description',
            dataProperty: 'statusDropdown.description',
            dataId: 'statusDropdown.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant(this.standardSpeed),
          key: 'standardSpeed',
          width: ExcelColumnWidthEnum.DECIMAL,
          type: ValueType.Number,
          style: { numFmt: '0.000000000000000###############' },
          allowPunctuation: true,
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            formulae: [],
            errorStyle: 'Error',
            showInputMessage: true,
            promptTitle: this.translate.instant(numberInputTitle, {
              field: this.translate.instant(this.standardSpeed),
            }),
            prompt: this.translate.instant(decimalInputPrompt, {
              field: this.translate.instant(this.standardSpeed),
            }),
          },
          isRequired: true,
          isDecimalNumber: true,
        },
        {
          header: this.translate.instant('general.excel.column.oeeFormula'),
          key: 'oeeFormula',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: content.oeeFormula,
            prop: 'description',
            dataProperty: 'oeeFormulaDropdown.description',
            dataId: 'oeeFormulaDropdown.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('general.excel.column.kpiCard.name'),
          key: 'homeMetricSetId',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: content.homeMetrics,
            prop: 'homeMetricsDropdown.name',
            dataProperty: 'homeMetricsDropdown.name',
            dataId: 'homeMetricsDropdown.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
            prompt: this.translate.instant('general.excel.column.kpiCard.prompt', {
              homeMetrics: this.translate.instant('lines.excel.homeMetricsDefault'),
            }),
          },
        },
        {
          header: this.translate.instant('general.excel.column.countEntry'),
          key: 'countEntryMethod',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: content.countEntries,
            prop: 'countEntryDropdown.description',
            dataProperty: 'countEntryDropdown.description',
            dataId: 'countEntryDropdown.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('general.excel.column.countType'),
          key: 'manualCountType',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: content.countTypes,
            prop: 'countTypeDropdown.description',
            dataProperty: 'countTypeDropdown.description',
            dataId: 'countTypeDropdown.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('general.excel.column.lineAvailability.name'),
          key: 'shiftPlanId',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: content.shiftPlans,
            prop: 'shiftPlansDropdown.name',
            dataProperty: 'shiftPlansDropdown.name',
            dataId: 'shiftPlansDropdown.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('general.excel.column.isWorkOrderTakeoverBySplitActive'),
          key: 'woTakeoverBySplit',
          width: 25,
          type: ValueType.Boolean,
          dropdownOptions: {
            data: booleanDropdownOptions,
            prop: 'name',
            dataProperty: 'workOrderTakeoverBySplitActiveDropdown.name',
            dataId: 'workOrderTakeoverBySplitActiveDropdown.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('general.excel.column.isWorkOrderSplitActive'),
          key: 'isWorkOrderSplitActive',
          width: 25,
          type: ValueType.Boolean,
          dropdownOptions: {
            data: booleanDropdownOptions,
            prop: 'name',
            dataProperty: 'workOrderSplitActiveDropdown.name',
            dataId: 'workOrderSplitActiveDropdown.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('general.excel.column.isAutomatedPhaseCommentsActive'),
          key: 'automatedPhaseComments',
          width: ExcelColumnWidthEnum.PHASE,
          type: ValueType.String,
          dropdownOptions: {
            data: content.automatedPhaseComments,
            prop: 'automatedPhaseCommentsDropdown.description',
            dataProperty: 'automatedPhaseCommentsDropdown.description',
            dataId: 'automatedPhaseCommentsDropdown.id',
            primaryKeyColumnWidth: 25,
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('settings.lines.lineForm.phaseDuration.label'),
          key: 'automatedPhaseDuration',
          width: 25,
          type: ValueType.String,
          dropdownOptions: {
            data: content.automatedPhaseDuration,
            prop: 'automatedPhaseDurationDropdown.description',
            dataProperty: 'automatedPhaseDurationDropdown.description',
            dataId: 'automatedPhaseDurationDropdown.id',
            primaryKeyColumnWidth: 25,
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('general.excel.column.isAutomatedScheduledRunDurationUpdateActive'),
          key: 'automatedRunDuration',
          width: 35,
          type: ValueType.String,
          dropdownOptions: {
            data: content.automatedRunDuration,
            prop: 'automatedRunDurationDropdown.description',
            dataProperty: 'automatedRunDurationDropdown.description',
            dataId: 'automatedRunDurationDropdown.id',
            primaryKeyColumnWidth: 35,
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('general.excel.column.lineType'),
          key: 'lineType',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dropdownOptions: {
            data: content.lineTypes,
            prop: 'lineTypesDropdown.lineType',
            dataProperty: 'lineTypesDropdown.lineType',
            dataId: 'lineTypesDropdown.id',
          },
          dataValidation: {
            type: CellTypes.LIST,
          },
          isRequired: true,
        },
        {
          header: this.translate.instant('general.excel.column.description'),
          key: 'description',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.String,
          dataValidation: {
            type: CellTypes.CUSTOM,
            allowBlank: false,
            showErrorMessage: true,
            formulae: [],
            errorStyle: 'Error',
            showInputMessage: true,
          },
        },
        {
          header: 'id',
          key: 'id',
          width: ExcelColumnWidthEnum.DEFAULT,
          type: ValueType.Number,
          style: { numFmt: '@' },
          dataValidation: {
            type: CellTypes.CUSTOM,
          },
        },
      ],
    };

    this.excelHelper.prepareExcelColumns(excelColumns.columns, withErrorColumn);

    return excelColumns;
  }

  public async getLinesFromExcel(file: File): Promise<ILineExcelResponse | null> {
    const workbook: Workbook = await this.excelHelper.getExcelWorkBookFromFile(file);
    const lineSheet: Worksheet = workbook.getWorksheet(this.translate.instant('excel.items.lines'));
    const siteIdDataSheet: Worksheet = workbook.getWorksheet('siteIdDataSheet');
    const dataSheetsToBeValidated: Worksheet[] = [
      lineSheet,
      siteIdDataSheet,
      workbook.getWorksheet('statusIdDataSheet'),
      workbook.getWorksheet('oeeFormulaDataSheet'),
      workbook.getWorksheet('homeMetricSetIdDataSheet'),
      workbook.getWorksheet('countEntryMethodDataSheet'),
      workbook.getWorksheet('manualCountTypeDataSheet'),
      workbook.getWorksheet('shiftPlanIdDataSheet'),
      workbook.getWorksheet('automatedPhaseCommentsDataSheet'),
      workbook.getWorksheet('automatedPhaseDurationDataSheet'),
      workbook.getWorksheet('automatedRunDurationDataSheet'),
      workbook.getWorksheet('lineTypeDataSheet'),
    ];

    if (!dataSheetsToBeValidated.every((dataSheet) => dataSheet)) {
      return null;
    }

    const sites: SimplifiedDataInterface[] = this.excelHelper.getExcelRowsFromWorkSheet<SimplifiedDataInterface>(
      siteIdDataSheet,
      {
        id: {
          key: 'id',
          type: ValueType.String,
          dataValidationType: CellTypes.CUSTOM,
        },
        name: {
          key: 'name',
          type: ValueType.String,
          dataValidationType: CellTypes.CUSTOM,
        },
      },
    );

    if (!sites.length) {
      return null;
    }

    const { columns }: CreateExcelInterface = this.getExcelColumns(
      {
        site: null,
        lineTypes: [],
        homeMetrics: [],
        shiftPlans: [],
        statuses: [],
        oeeFormula: [],
        countEntries: [],
        countTypes: [],
        automatedPhaseComments: [],
        automatedPhaseDuration: [],
        automatedRunDuration: [],
      },
      false,
    );
    const columnKeys: ExcelColumnKeysInterface = this.excelHelper.getSheetColumnKeys(columns);
    const excelLines: LinesExcelDataInterface[] = this.excelHelper.getExcelRowsFromWorkSheet<LinesExcelDataInterface>(
      lineSheet,
      columnKeys,
      {
        dateFormat: this.dateFormat$,
        timeFormat: this.timeFormat$,
        timezone: this.timezone,
      },
    );

    this.activities = (await this.getActivities()).data;
    const linesRequestData: LinesExcelDataInterface[] = excelLines.map(
      (line: LinesExcelDataInterface): LinesExcelDataInterface => {
        const activityIds: number[] | null =
          line.activityNames?.split(',').map((name) => {
            return (
              _.find(this.activities, { name: name.trim(), active: 1 })?.id ??
              _.find(this.activities, { name: name.trim() })?.id ??
              0
            );
          }) ?? null;

        return {
          activityIds,
          activityNames: line.activityNames,
          siteId: line.siteId,
          title: line.title,
          statusId: line.statusId,
          standardSpeed: line.standardSpeed === null ? '' : line.standardSpeed,
          oeeFormula: line.oeeFormula,
          countEntryMethod: line.countEntryMethod,
          manualCountType: line.manualCountType,
          shiftPlanId: line.shiftPlanId,
          lineType: line.lineType,
          description: line.description,
          isAutomatedPhaseCommentsActive: line.automatedPhaseComments
            ? Boolean(TrueFalse[line.automatedPhaseComments])
            : null,
          isAutomatedScheduledPhaseDurationUpdateActive: line.automatedPhaseDuration
            ? Boolean(TrueFalse[line.automatedPhaseDuration])
            : null,
          isAutomatedScheduledRunDurationUpdateActive: line.automatedRunDuration
            ? Boolean(TrueFalse[line.automatedRunDuration])
            : null,
          ...(line.homeMetricSetId ? { homeMetricSetId: line.homeMetricSetId } : {}),
          ...(line.id ? { id: line.id } : {}),
          isWorkOrderSplitActive: line.isWorkOrderSplitActive,
          isWorkOrderTakeoverBySplitActive: line.woTakeoverBySplit
        };
      },
    );

    return {
      linesData: {
        lines: linesRequestData,
      },
      siteData: sites,
    };
  }

  public uploadExcel(lines: LineCRUDInterface[]): Observable<BulkResponseDataInterface> {
    return this.http.post<BulkResponseDataInterface>(this.urls.BULK_SAVE_URL, {
      lines: lines.map((line: LineCRUDInterface) => _.omit(line, 'activityNames')),
    });
  }

  private getExcelFormattedData(
    line: LineExcelInterface,
    checkboxFieldDropdown: IExcelDropdownWithStringId[],
    content: LinesExcelDropdownContentInterface,
    isErrorExcel: boolean,
  ): LineExcelInterface {
    const activityNames: string = isErrorExcel ? line.activityNames : this.getSelectedActivityNames(line.activityIds);
    line.automatedPhaseComments = line.isAutomatedPhaseCommentsActive;
    line.automatedPhaseDuration = line.isAutomatedScheduledPhaseDurationUpdateActive;
    line.automatedRunDuration = line.isAutomatedScheduledRunDurationUpdateActive;

    if (!line.homeMetricSetId && !isErrorExcel) {
      line.homeMetricSetId = line.homeMetricSetAssignment?.homeMetricSetId ?? 1;
    }

    return {
      ...line,
      ...LinesExcelService.getCheckboxDropdowns(line, checkboxFieldDropdown),
      activityNames,
      siteDropdown: content.site,
      standardSpeed: this.decimalHelper.removeTrailingZeros(line.standardSpeed),
      statusDropdown: _.find(content.statuses, { id: line.statusId }),
      oeeFormulaDropdown: _.find(content.oeeFormula, { id: line.oeeFormula }),
      homeMetricsDropdown: _.find(content.homeMetrics, { id: line.homeMetricSetId }),
      shiftPlansDropdown: _.find(content.shiftPlans, { id: line.shiftPlanId }),
      countEntryDropdown: _.find(content.countEntries, { id: line.countEntryMethod }),
      countTypeDropdown: _.find(content.countTypes, { id: line.manualCountType }),
      lineTypesDropdown: _.find(content.lineTypes, { id: line.lineType }),
      workOrderSplitActiveDropdown: !_.isNil(line.isWorkOrderSplitActive)
      ? _.find(this.excelHelper.getExcelBooleanDropdownOptions(), { id: String(line.isWorkOrderSplitActive) })
      : null,
      workOrderTakeoverBySplitActiveDropdown: !_.isNil(line.isWorkOrderTakeoverBySplitActive)
      ? _.find(this.excelHelper.getExcelBooleanDropdownOptions(), { id: String(line.isWorkOrderTakeoverBySplitActive) })
      : null,
    };
  }

  private static getCheckboxDropdowns(
    line,
    checkboxFieldDropdown: IExcelDropdownWithStringId[],
  ): {
    automatedPhaseCommentsDropdown: ExcelDropdownDataWithDescriptionFormatted;
    automatedPhaseDurationDropdown: ExcelDropdownDataWithDescriptionFormatted;
    automatedRunDurationDropdown: ExcelDropdownDataWithDescriptionFormatted;
  } {
    const automatedPhaseComments: ExcelDropdownDataWithDescriptionFormatted | null =
      line.isAutomatedPhaseCommentsActive !== null
        ? _.find(checkboxFieldDropdown, { id: TrueFalse[Number(line.isAutomatedPhaseCommentsActive)] })
        : null;
    const automatedRunDuration: ExcelDropdownDataWithDescriptionFormatted | null = !_.isNil(
      line.isAutomatedScheduledRunDurationUpdateActive,
    )
      ? _.find(checkboxFieldDropdown, { id: TrueFalse[Number(line.isAutomatedScheduledRunDurationUpdateActive)] })
      : null;

    const automatedPhaseDuration =
      line.isAutomatedPhaseDurationActive !== null
        ? _.find(checkboxFieldDropdown, { id: TrueFalse[Number(line.isAutomatedScheduledPhaseDurationUpdateActive)] })
        : null;
    return {
      automatedPhaseCommentsDropdown: {
        ...automatedPhaseComments,
        id: automatedPhaseComments !== null ? automatedPhaseComments.id : null,
      },
      automatedPhaseDurationDropdown: {
        ...automatedPhaseDuration,
        id: automatedPhaseDuration !== null ? TrueFalse[automatedPhaseDuration.id] : null,
      },
      automatedRunDurationDropdown: {
        ...automatedRunDuration,
        id: automatedRunDuration !== null ? automatedRunDuration.id : null,
      },
    };
  }

  private getSelectedActivityNames(activityIds: string): string | null {
    return (
      activityIds
        ?.split(',')
        .map((activityId: string) => {
          return _.find(this.activities, { id: Number(activityId) })?.name;
        })
        .filter((activityId: number) => activityId)
        .join(', ') ?? null
    );
  }
}
