import { Action } from '@ngrx/store';
import { LineCRUDInterface, SiteCRUDInterface } from '../../../shared/component/filter/filter.class';
import {
  AddProductSpeedInterface,
  AddProductSpeedResponseInterface,
  BulkEditProductSpeedInterface,
  BulkEditProductSpeedResponseInterface,
  EditProductSpeedInterface,
  EditProductSpeedResponseInterface,
  IProductSpeedsFilters,
  ProductSpeedBulkSaveManyInterface,
  ProductSpeedInterface,
  ProductSpeedsDownloadExcelFiltersInterface,
  ProductSpeedTableQueryParams,
} from './product-speeds.model';
import { TabRowInterface } from '../../../shared/component/side-config-bar/side-config-bar.model';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { ProductInterface } from '../products/products.model';

export const PRODUCT_SPEEDS_SITES_DATA_LOADING = '[PRODUCT_SPEEDS] PRODUCT SPEEDS ACTION SITES DATA LOADING';
export const PRODUCT_SPEEDS_SITES_DATA_LOADED = '[PRODUCT_SPEEDS] PRODUCT SPEEDS ACTION SITES DATA LOADED';
export const PRODUCT_SPEEDS_PRODUCT_SPEED_DATA_LOADING =
  '[PRODUCT_SPEEDS] PRODUCT SPEEDS ACTION PRODUCT SPEED DATA LOADING';
export const PRODUCT_SPEEDS_PRODUCT_SPEED_DATA_LOADED =
  '[PRODUCT_SPEEDS] PRODUCT SPEEDS ACTION PRODUCT SPEED DATA LOADED';
export const PRODUCT_SPEEDS_DATA_LOADING = '[PRODUCT_SPEEDS] PRODUCT SPEEDS DATA LOADING';
export const PRODUCT_SPEEDS_DATA_LOADED = '[PRODUCT_SPEEDS] PRODUCT SPEEDS DATA LOADED';
export const DOWNLOAD_PRODUCT_SPEED_EXCEL = '[PRODUCT_SPEEDS] PRODUCT SPEEDS ACTION DOWNLOAD PRODUCT SPEED EXCEL';
export const DOWNLOAD_PRODUCT_SPEED_EXCEL_COMPLETED =
  '[PRODUCT_SPEEDS] PRODUCT SPEEDS ACTION DOWNLOAD PRODUCT SPEED EXCEL COMPLETED';
export const UPLOAD_PRODUCT_SPEED_EXCEL = '[PRODUCT_SPEEDS] PRODUCT SPEEDS ACTION UPLOAD PRODUCT SPEED EXCEL';
export const UPLOAD_PRODUCT_SPEED_EXCEL_COMPLETED =
  '[PRODUCT_SPEEDS] PRODUCT SPEEDS ACTION UPLOAD PRODUCT SPEED EXCEL COMPLETED';
export const PRODUCT_SPEEDS_FETCH_ERROR = '[PRODUCT_SPEEDS] PRODUCT SPEEDS ACTION FETCH ERROR';
export const DELETE_PRODUCT_SPEEDS = '[PRODUCT_SPEEDS] PRODUCT SPEEDS ACTION DELETE PRODUCT SPEEDS';
export const DELETE_PRODUCT_SPEEDS_COMPLETED = '[PRODUCT_SPEEDS] PRODUCT SPEEDS ACTION DELETE PRODUCT SPEEDS COMPLETED';
export const ADD_PRODUCT_SPEED = '[PRODUCT_SPEEDS] ADD PRODUCT SPEED';
export const ADD_PRODUCT_SPEED_COMPLETED = '[PRODUCT_SPEEDS] ADD PRODUCT SPEED COMPLETED';
export const EDIT_PRODUCT_SPEED = '[PRODUCT_SPEEDS] EDIT PRODUCT SPEED';
export const EDIT_PRODUCT_SPEED_COMPLETED = '[PRODUCT_SPEEDS] EDIT PRODUCT SPEED COMPLETED';
export const BULK_EDIT_PRODUCT_SPEED = '[PRODUCT_SPEEDS] BULK EDIT PRODUCT SPEED';
export const BULK_EDIT_PRODUCT_SPEED_COMPLETED = '[PRODUCT_SPEEDS] BULK EDIT PRODUCT SPEED COMPLETED';
export const GET_LINES = '[PRODUCT_SPEEDS] GET LINES';
export const GET_LINES_COMPLETED = '[PRODUCT_SPEEDS] GET LINES COMPLETED';
export const GET_PRODUCTS = '[PRODUCT_SPEEDS] GET PRODUCTS';
export const GET_PRODUCTS_COMPLETED = '[PRODUCT_SPEEDS] GET PRODUCTS COMPLETED';
export const SET_SELECTED_FILTERS = '[PRODUCT_SPEEDS] SET SELECTED FILTERS';
export const SET_TABLE_SETTINGS = '[PRODUCT_SPEEDS] SET TABLE SETTINGS';
export const CLEAR_STATE = '[PRODUCT_SPEEDS] CLEAR STATE';

export class ProductSpeedsSiteDataLoading implements Action {
  readonly type = PRODUCT_SPEEDS_SITES_DATA_LOADING;

  constructor() {}
}

export class ProductSpeedsSiteDataLoaded implements Action {
  readonly type = PRODUCT_SPEEDS_SITES_DATA_LOADED;

  constructor(public payload: ({ id: number; name: string } & Partial<SiteCRUDInterface>)[]) {}
}

export class DownloadProductSpeedExcel implements Action {
  readonly type = DOWNLOAD_PRODUCT_SPEED_EXCEL;
  constructor(
    public withData: boolean = false,
    public filters: ProductSpeedsDownloadExcelFiltersInterface,
    public tableQuery: ProductSpeedTableQueryParams,
  ) {}
}

export class DownloadProductSpeedExcelCompleted implements Action {
  readonly type = DOWNLOAD_PRODUCT_SPEED_EXCEL_COMPLETED;
}

export class UploadProductSpeedExcel implements Action {
  readonly type = UPLOAD_PRODUCT_SPEED_EXCEL;

  constructor(public productSpeeds: ProductSpeedBulkSaveManyInterface) {}
}

export class UploadProductSpeedExcelCompleted implements Action {
  readonly type = UPLOAD_PRODUCT_SPEED_EXCEL_COMPLETED;

  constructor(public payload: (ProductSpeedInterface & { errorMessages?: string })[]) {}
}

export class ProductSpeedsDataLoading implements Action {
  readonly type = PRODUCT_SPEEDS_DATA_LOADING;

  constructor(
    public query: ProductSpeedTableQueryParams = {
      page: 1,
      pageSize: 10,
    },
  ) {}
}

export class ProductSpeedsDataLoaded implements Action {
  readonly type = PRODUCT_SPEEDS_DATA_LOADED;

  constructor(public payload) {}
}

export class DeleteProductSpeeds implements Action {
  readonly type = DELETE_PRODUCT_SPEEDS;
  constructor(public payload: number[]) {}
}

export class DeleteProductSpeedsCompleted implements Action {
  readonly type = DELETE_PRODUCT_SPEEDS_COMPLETED;
  constructor(public isBulk: boolean, public response: any) {}
}

export class AddProductSpeed implements Action {
  readonly type = ADD_PRODUCT_SPEED;
  constructor(public productSpeed: AddProductSpeedInterface) {}
}

export class AddProductSpeedCompleted implements Action {
  readonly type = ADD_PRODUCT_SPEED_COMPLETED;
  constructor(public payload: AddProductSpeedResponseInterface) {}
}

export class EditProductSpeed implements Action {
  readonly type = EDIT_PRODUCT_SPEED;
  constructor(public productSpeed: EditProductSpeedInterface, public productSpeedId: number) {}
}

export class EditProductSpeedCompleted implements Action {
  readonly type = EDIT_PRODUCT_SPEED_COMPLETED;
  constructor(public payload: EditProductSpeedResponseInterface) {}
}

export class BulkEditProductSpeed implements Action {
  readonly type = BULK_EDIT_PRODUCT_SPEED;
  constructor(public productSpeeds: BulkEditProductSpeedInterface[]) {}
}

export class BulkEditProductSpeedCompleted implements Action {
  readonly type = BULK_EDIT_PRODUCT_SPEED_COMPLETED;
  constructor(public payload: BulkEditProductSpeedResponseInterface) {}
}

export class GetLines implements Action {
  readonly type = GET_LINES;
  constructor(public siteId?: number) {}
}

export class GetLinesCompleted implements Action {
  readonly type = GET_LINES_COMPLETED;
  constructor(public payload: GetManyResponseInterface<LineCRUDInterface>) {}
}

export class GetProducts implements Action {
  readonly type = GET_PRODUCTS;
  constructor(public siteId?: number, public searchText: string = null, public productId: number = null) {}
}

export class GetProductsCompleted implements Action {
  readonly type = GET_PRODUCTS_COMPLETED;
  constructor(public payload: GetManyResponseInterface<ProductInterface>) {}
}

export class SetSelectedFilters implements Action {
  readonly type = SET_SELECTED_FILTERS;
  constructor(public filters: IProductSpeedsFilters) {}
}

export class SetTableSettings implements Action {
  readonly type = SET_TABLE_SETTINGS;
  constructor(public payload: TabRowInterface[], public setAsDefault: boolean) {}
}

export class ClearState implements Action {
  readonly type = CLEAR_STATE;
}

export class FetchError implements Action {
  readonly type = PRODUCT_SPEEDS_FETCH_ERROR;
  constructor(public payload: object) {}
}

export type ProductSpeedsActions =
  | ProductSpeedsSiteDataLoading
  | ProductSpeedsSiteDataLoaded
  | DownloadProductSpeedExcel
  | DownloadProductSpeedExcelCompleted
  | UploadProductSpeedExcel
  | UploadProductSpeedExcelCompleted
  | ProductSpeedsDataLoading
  | ProductSpeedsDataLoaded
  | DeleteProductSpeeds
  | DeleteProductSpeedsCompleted
  | AddProductSpeed
  | AddProductSpeedCompleted
  | EditProductSpeed
  | EditProductSpeedCompleted
  | BulkEditProductSpeed
  | BulkEditProductSpeedCompleted
  | GetLines
  | GetLinesCompleted
  | GetProducts
  | GetProductsCompleted
  | SetSelectedFilters
  | SetTableSettings
  | ClearState
  | FetchError;
