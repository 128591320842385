import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../../store/oee.reducer';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-mask',
  templateUrl: './mask.component.html',
  styleUrls: ['./mask.component.scss'],
})
export class MaskComponent {
  public isMaskVisible: boolean = false;
  private appStore: Subscription;

  constructor(private store: Store<OeeAppState>) {
    this.appStore = this.store.select('app').subscribe((state) => {
      this.isMaskVisible = state.masking;
    });
  }

  ngOnDestroy(): void {
    this.isMaskVisible = false;
    this.appStore.unsubscribe();
  }
}
