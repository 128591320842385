import { DropdownOptionInterface } from '../../shared/component/scw-mat-ui/scw-mat-select/scw-mat-select.model';

export interface IComparisonFilterCardState {
  comparisonGroupType: DropdownOptionInterface[] | null;
}

export enum EComparisonGroupType {
  SITE = 'site',
  DEPARTMENT = 'department',
  LINE = 'line',
  SHIFT = 'shift',
  PRODUCT = 'product',
}
