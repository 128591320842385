import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../../store/oee.reducer';
import { MainStateInterface, MenuInterface } from '../../../store/main/main.model';
import { HttpClient } from '@angular/common/http';
import { skipWhile, take } from 'rxjs/operators';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class MenuPermissionGuard implements CanActivate {
  constructor(
    public router: Router,
    public http: HttpClient,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
  ) {}

  private static findMenuByRoute(menus: MenuInterface[], route: string): MenuInterface {
    for (const menu of menus) {
      if (!_.isEmpty(menu.link) &&  _.startsWith(route, menu.link)) {
        return menu;
      }
    }

    return null;
  }

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      this.store
        .select('mainStore')
        .pipe(
          skipWhile((store: MainStateInterface) => store.getActiveSitesLoading && !store.getActiveSitesLoaded),
          skipWhile((store: MainStateInterface) => store.getNavigationMenuLoading && !store.getNavigationMenuLoaded),
          take(1),
        )
        .subscribe((store: MainStateInterface) => {
          try {
            const formattedUrl: string = decodeURIComponent(state.url.substring(1));
            let isAuthorized: boolean = false;
            store.authorizedPages.forEach((page: string) => {
              if (_.startsWith(formattedUrl, page)) {
                isAuthorized = true;
              }
            });

            if (!isAuthorized) {
              this.router.navigate(['/error/forbidden']);
            }

            const findMenu: MenuInterface = MenuPermissionGuard.findMenuByRoute(store.menu.menus, formattedUrl);
            isAuthorized = !_.get(findMenu, 'isUpgradeBadgeEnabled', true);

            if (!isAuthorized) {
              this.router.navigate(['/book-a-demo'], { queryParams: { module: findMenu.name } });
            }

            observer.next(isAuthorized);
          } catch (error) {
            this.router.navigate(['/error/forbidden']);
            console.error(error);
            observer.next(false);
          } finally {
            observer.complete();
          }
        });
    });
  }
}
