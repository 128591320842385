import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ImageHelperService {
  constructor() {}

  public getImageUploadFormData(base64ImageContent: string): FormData {
    const imageName = 'image-upload.jpeg';
    const imageFile = new File([ImageHelperService.dataURItoBlob(base64ImageContent)], imageName, {
      type: 'image/jpeg',
    });

    const formData = new FormData();
    formData.append('file', imageFile, imageFile.name);
    return formData;
  }

  public static removeVersionInfoFromPath(path: string): string {
    const pathParts = path.split('?');
    return pathParts[0];
  }

  private static dataURItoBlob(base64): Blob {
    const byteString = window.atob(base64.replace('data:image/jpeg;base64,', ''));
    const int8Array = new Uint8Array(new ArrayBuffer(byteString.length));

    for (let i = 0; i < byteString.length; i += 1) {
      int8Array[i] = byteString.charCodeAt(i);
    }

    return new Blob([int8Array], { type: 'image/jpeg' });
  }
}
