import { Action } from '@ngrx/store';
import { TagCRUDRequestInterface, TagFormDropdownData, TagInterface } from './tags.model';
import { GenericCrudRequestConstructionParameters } from '../../../shared/model/interface/generic-api-request.model';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';

export enum TagsActionTypes {
  LoadTags = '[Tags] Load Tags',
  TagsLoaded = '[Tags] Tags Loaded',
  LoadFormDropdownData = '[Tags] Load Form Dropdown Data',
  FormDropdownDataLoaded = '[Tags] Form Dropdown Loaded',
  DeleteTag = '[Tags] Delete Tag',
  DeleteTagCompleted = '[Tags] Delete Tag Completed',
  BulkDeleteTag = '[Tags] Bulk Delete Tag',
  BulkDeleteTagCompleted = '[Tags] Bulk Delete Tag Completed',
  CreateTag = '[Tags] Create Tag',
  CreateTagCompleted = '[Tags] Create Tag Completed',
  UpdateTag = '[Tags] Update Tag',
  UpdateTagCompleted = '[Tags] Update Tag Completed',
  FetchError = '[Tags] Fetch Error',
}

export class LoadTags implements Action {
  readonly type = TagsActionTypes.LoadTags;

  constructor(public parameters: GenericCrudRequestConstructionParameters) {}
}

export class TagsLoaded implements Action {
  readonly type = TagsActionTypes.TagsLoaded;

  constructor(public payload: GetManyResponseInterface<TagInterface>) {}
}

export class LoadFormDropdownData implements Action {
  readonly type = TagsActionTypes.LoadFormDropdownData;
}

export class FormDropdownDataLoaded implements Action {
  readonly type = TagsActionTypes.FormDropdownDataLoaded;

  constructor(public payload: TagFormDropdownData) {}
}

export class DeleteTag implements Action {
  readonly type = TagsActionTypes.DeleteTag;

  constructor(public id: number) {}
}

export class DeleteTagCompleted implements Action {
  readonly type = TagsActionTypes.DeleteTagCompleted;

  constructor(public response: GetManyResponseInterface<TagInterface>) {}
}

export class BulkDeleteTag implements Action {
  readonly type = TagsActionTypes.BulkDeleteTag;

  constructor(public ids: number[]) {}
}

export class BulkDeleteTagCompleted implements Action {
  readonly type = TagsActionTypes.BulkDeleteTagCompleted;

  constructor(public payload: BulkResponseDataInterface) {}
}

export class CreateTag implements Action {
  readonly type = TagsActionTypes.CreateTag;

  constructor(public requestParameters: TagCRUDRequestInterface) {}
}

export class CreateTagCompleted implements Action {
  readonly type = TagsActionTypes.CreateTagCompleted;

  constructor(public payload: BaseOneResponseInterface<TagCRUDRequestInterface>) {}
}

export class UpdateTag implements Action {
  readonly type = TagsActionTypes.UpdateTag;

  constructor(public id: number, public requestParameters: TagCRUDRequestInterface) {}
}

export class UpdateTagCompleted implements Action {
  readonly type = TagsActionTypes.UpdateTagCompleted;

  constructor(public payload: BaseOneResponseInterface<TagCRUDRequestInterface>) {}
}

export class FetchError implements Action {
  readonly type = TagsActionTypes.FetchError;

  constructor(public payload: object) {}
}

export type TagsActions =
  | LoadTags
  | TagsLoaded
  | LoadFormDropdownData
  | FormDropdownDataLoaded
  | DeleteTag
  | DeleteTagCompleted
  | BulkDeleteTag
  | BulkDeleteTagCompleted
  | CreateTag
  | CreateTagCompleted
  | UpdateTag
  | UpdateTagCompleted
  | FetchError;
