import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DropdownSettings } from 'angular2-multiselect-dropdown/lib/multiselect.interface';
import { ScwMatSelectRule } from './scw-mat-select.model';
import { ScwMatInputRule } from '../scw-mat-input/scw-mat-input.model';
import { ScwMatSearchBehavior, ScwMatSearchButtonPosition } from '../scw-mat-search/scw-mat-search.model';
import * as _ from 'lodash';
import { ScwMatSearchComponent } from '../scw-mat-search/scw-mat-search.component';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'scw-mat-select',
  templateUrl: './scw-mat-select.component.html',
  styleUrls: ['./scw-mat-select.component.scss'],
})
export class ScwMatSelectComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('searchComponent') searchComponent: ScwMatSearchComponent;
  @ContentChild(TemplateRef, { static: false }) searchResultsListTemplateRef: TemplateRef<any>;

  @Input() inputModel: any;
  @Input() isValid: boolean = false;
  @Input() data: any[] = [];
  @Input() label: string;
  @Input() isLoading: boolean = false;
  @Input() rules: ScwMatSelectRule[] = [];
  @Input() hasErrors: boolean = false;
  @Input() errorText: string;
  @Input() singleSelection: boolean = true;
  @Input() limitSelection: number = null;
  @Input() text: string;
  @Input() enableCheckAll: boolean = false;
  @Input() selectAllText: string = this.translate.instant('filterCard.dropdown.selectAllText');
  @Input() unSelectAllText: string = this.translate.instant('filterCard.dropdown.unSelectAllText');
  @Input() filterSelectAllText: string = this.translate.instant('filterCard.dropdown.filterSelectAllText');
  @Input() filterUnSelectAllText: string = this.translate.instant('filterCard.dropdown.filterUnSelectAllText');
  @Input() enableSearchFilter: boolean = false;
  @Input() searchBy: any[] = [];
  @Input() maxHeight: number = 300;
  @Input() badgeShowLimit: number = 1;
  @Input() classes: string = '';
  @Input() disabled: boolean = false;
  @Input() searchPlaceholderText: string = this.translate.instant('filterCard.dropdown.searchPlaceholderText');
  @Input() showCheckbox: boolean;
  @Input() noDataLabel: string = this.translate.instant('filterCard.dropdown.noDataLabel');
  @Input() searchAutofocus: boolean = true;
  @Input() lazyLoading: boolean = false;
  @Input() labelKey: string = 'name';
  @Input() primaryKey: string = 'id';
  @Input() position: string = 'bottom';
  @Input() autoPosition: boolean = true;
  @Input() enableFilterSelectAll: boolean = false;
  @Input() selectGroup: boolean = false;
  @Input() addNewItemOnFilter: boolean = false;
  @Input() addNewButtonText: string = this.translate.instant('filterCard.dropdown.addNewButtonText');
  @Input() escapeToClose: boolean = true;
  @Input() clearAll: boolean = true;
  @Input() isRequired: boolean = false;
  @Input() noPadding: boolean = false;
  @Input() groupBy: string;
  @Input() tagToBody: boolean = false;
  @Input() belongsToFilterCardComponent: boolean = false;
  @Input() hint: string = null;
  @Input() advancedSelect: boolean = false;
  @Input() enableSearchResultList: boolean = false;
  @Input() dataPropertyKey?: string;
  // parameters of scw-mat-search-component
  @Input() searchModel: string = '';
  @Input() searchButtonPosition: ScwMatSearchButtonPosition = 'right';
  @Input() searchBehavior: ScwMatSearchBehavior = 'live';
  @Input() searchDelay: number = 600;
  @Input() searchMinLength: number = 0;
  @Input() searchMaxLength: number = 1000;
  @Input() searchPreventSameSearchString: boolean = true;
  @Input() clearSearchOnOpen: boolean = false;
  @Input() cItemTemplate: TemplateRef<any>;
  @Input() showLoadMoreButton: boolean = false;
  @Input() size: 'sm' | 'md' = 'md';
  @Input() colorSelect: boolean = false;
  @Input() createComponent: boolean = false;
  @Input() createItemLabel: string = '';

  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDeSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSelectAll: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() onDeSelectAll: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Output() onGroupSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output() onGroupDeSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output() onScrollToEnd: EventEmitter<any> = new EventEmitter<any>();
  @Output() onOpen: EventEmitter<any> = new EventEmitter<any>();
  @Output() inputModelChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSearchModelChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() isValidChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onSearch: EventEmitter<string> = new EventEmitter<string>();
  @Output() onAdd: EventEmitter<void> = new EventEmitter<void>();
  @Output() onCreate: EventEmitter<string> = new EventEmitter<string>();

  public _ = _;
  public settings: DropdownSettings;
  public searchResult: any[] = [];
  private isAnyError: boolean = false;
  public search: string = '';
  public isCreateSearchData: boolean = false;
  private defaultSearchData: any[] = [];
  private loadMoreButtonClickCount: number = 0;

  constructor(
    private readonly translate: TranslateService,
    private changeRef: ChangeDetectorRef,
    private elRef: ElementRef,
  ) {}

  public onCloseEmitter($event: any): void {
    this.onClose.emit($event);
  }

  public onSelectEmitter($event: any): void {
    this.onSelect.emit($event);
  }

  public onDeSelectEmitter($event: any): void {
    this.onDeSelect.emit($event);
  }

  public onSelectAllEmitter($event: any[]): void {
    this.onSelectAll.emit($event);
  }

  public onDeSelectAllEmitter($event: any[]): void {
    this.onDeSelectAll.emit($event);
  }

  public onGroupSelectEmitter($event: any): void {
    this.onGroupSelect.emit($event);
  }

  public onGroupDeSelectEmitter($event: any): void {
    this.onGroupDeSelect.emit($event);
  }

  public onScrollToEndEmitter($event: any): void {
    this.onScrollToEnd.emit($event);
  }

  public onOpenEmitter($event: any): void {
    this.loadMoreButtonClickCount = 0;

    if (this.clearSearchOnOpen) {
      this.searchModel = '';

      if (this.searchComponent) {
        this.searchComponent.lastValidSearch = '';
        this.searchComponent.emitSearchString();
      }
    }

    this.onOpen.emit($event);
  }

  public onSearchModelEmitter($event: string): void {
    this.onSearchModelChange.emit($event);
  }

  public onSearchStringRefresh(): void {
    if (
      !(
        _.isNil(this.searchModel) ||
        this.searchModel.length > this.searchMaxLength ||
        this.searchModel.length < this.searchMinLength ||
        (this.searchPreventSameSearchString && this.searchMinLength === this.inputModel)
      )
    ) {
      this.onSearch.emit(this.searchModel);
    }
  }

  private applySettings(): void {
    this.settings = {
      singleSelection: this.singleSelection,
      limitSelection: this.limitSelection,
      text: this.text,
      enableCheckAll: this.enableCheckAll,
      selectAllText: this.selectAllText,
      unSelectAllText: this.unSelectAllText,
      filterSelectAllText: this.filterSelectAllText,
      filterUnSelectAllText: this.filterUnSelectAllText,
      enableSearchFilter: this.enableSearchFilter,
      searchBy: this.searchBy,
      maxHeight: this.maxHeight,
      badgeShowLimit: this.badgeShowLimit,
      classes: this.classes,
      disabled: this.disabled,
      searchPlaceholderText: this.searchPlaceholderText,
      showCheckbox: this.showCheckbox !== undefined ? this.showCheckbox : !this.singleSelection,
      noDataLabel: this.noDataLabel,
      searchAutofocus: this.searchAutofocus,
      lazyLoading: this.lazyLoading,
      labelKey: this.labelKey,
      primaryKey: this.primaryKey,
      position: this.position,
      autoPosition: this.autoPosition,
      enableFilterSelectAll: this.enableFilterSelectAll,
      selectGroup: this.selectGroup,
      addNewItemOnFilter: this.addNewItemOnFilter,
      addNewButtonText: this.addNewButtonText,
      escapeToClose: this.escapeToClose,
      clearAll: this.clearAll,
      groupBy: this.groupBy,
      tagToBody: this.tagToBody,
    };

    if (this.advancedSelect) {
      this.searchButtonPosition = 'right';
      this.enableSearchFilter = true;
    }
  }

  public reset(): void {
    this.search = '';
    this.searchModel = '';
    this.inputModel = null;
    this.clearErrorMessage();
  }

  private isValidEqualizer(isValid: boolean): void {
    this.isValid = isValid;
    this.isValidChange.emit(this.isValid);
  }

  private showErrorMessage(message: string): void {
    this.isValidEqualizer(false);
    this.isAnyError = true;
    this.hasErrors = true;
    this.errorText = message ? message : '';
  }

  public clearErrorMessage(): void {
    this.isValidEqualizer(true);
    this.isAnyError = false;
    this.hasErrors = false;
    this.errorText = null;
  }

  private requiredRule(rule: ScwMatInputRule): void {
    if (!this.inputModel || this.inputModel.length === 0) {
      this.showErrorMessage(rule.message ?? this.translate.instant('scwMatForm.validation.required'));
    }
  }

  private customRule(rule: ScwMatInputRule): void {
    if (this.inputModel && rule.custom && !rule.validator(this.inputModel)) {
      this.showErrorMessage(rule.message);
    }
  }

  public checkRules(): void {
    if (this.rules.length === 0) {
      this.isValidEqualizer(true);
      return;
    }

    this.isAnyError = false;

    for (const rule of this.rules) {
      if (this.isAnyError) {
        return;
      }

      if ('required' in rule) {
        this.requiredRule(rule);
      }

      if ('custom' in rule) {
        this.customRule(rule);
      }
    }

    if (this.isAnyError) {
      return;
    }

    this.clearErrorMessage();
  }

  public onSearchString(searchString: string): void {
    if (_.isNil(searchString)) {
      this.inputModel = _.cloneDeep(this.inputModel);
      this.changeRef.detectChanges();

      return;
    }

    this.search = searchString.trim().toLowerCase();

    if (this.searchBehavior === 'live') {
      this.searchResult = this.data.filter((value: any) => {
        return (value[this.labelKey] ?? '').trim().toLowerCase().includes(this.search);
      });
    } else {
      this.searchResult = _.cloneDeep(this.data);
      this.onSearch.emit(searchString);
    }
    if (this.createComponent) {
      this.data = _.cloneDeep(this.defaultSearchData);
      this.isCreateSearchData = searchString !== '';
      this.data.map((value: any) => {
        if (
          (value[this.labelKey] ?? '').trim().toLowerCase() === searchString.trim().toLowerCase() &&
          this.createComponent
        ) {
          this.isCreateSearchData = false;
        }
      });
    }

    this.inputModel = _.cloneDeep(this.inputModel);
    this.changeRef.detectChanges();
  }

  public onAddEmitter(): void {
    if (!this.data) {
      this.data = [
        {
          [this.settings.primaryKey]: null,
          [this.settings.labelKey]: '',
          disabled: true,
        },
      ];
    }

    this.inputModel = [
      {
        [this.settings.primaryKey]: null,
        [this.settings.labelKey]: this.searchModel,
      },
    ];

    this.onNgModelChange();
    this.onAdd.emit();
  }

  public onCreateEmitter(): void {
    this.onCreate.emit(this.searchModel);
    this.searchModel = null;
    this.isCreateSearchData = false;
  }

  public onNgModelChange(): void {
    this.checkRules();
    this.inputModelChange.emit(this.inputModel);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (Array.isArray(this.data) && this.data.length > this.defaultSearchData.length) {
      this.defaultSearchData = _.cloneDeep(this.data);
    }

    this.applySettings();
  }

  public onLoadMoreButtonClicked(searchTooltip: NgbTooltip): void {
    const element: Element | null = this.elRef.nativeElement.querySelector('.lazyContainer');
    if (!element) {
      return;
    }

    this.loadMoreButtonClickCount = this.loadMoreButtonClickCount + 1;
    searchTooltip.close();

    if (this.loadMoreButtonClickCount % 3 === 0) {
      searchTooltip.open();
    }

    element.scroll({
      top: 9999999,
      left: 0,
    });
  }

  public ngOnInit(): void {
    this.applySettings();
  }

  public ngOnDestroy(): void {}
}
