import { LaborPerformanceStateInterface } from './labor-performance.model';
import { LaborPerformanceActions, LaborPerformanceActionTypes } from './labor-performance.actions';

export const laborPerformanceInitialState: LaborPerformanceStateInterface = {
  laborPerformanceIsLoaded: false,
  laborPerformanceIsLoading: false,
  laborPerformanceData: [],
  laborPerformanceWeeklyChartData: [],
  laborPerformanceBasedOnLineChartData: [],
  columnNames: null,
  errors: [],
};

export function laborPerformanceReducer(
  state: LaborPerformanceStateInterface = laborPerformanceInitialState,
  action: LaborPerformanceActions,
) {
  switch (action.type) {
    case LaborPerformanceActionTypes.StartLaborPerformanceDataLoading:
      return {
        ...state,
        laborPerformanceIsLoaded: false,
        laborPerformanceIsLoading: true,
      };

    case LaborPerformanceActionTypes.LaborPerformanceDataLoaded:
      const laborPerformanceData = action.payload.data.gridData;
      const laborPerformanceWeeklyChartData = action.payload.data.weeklyChartData;
      const laborPerformanceBasedOnLineChartData = action.payload.data.basedOnLineChartData;

      return {
        ...state,
        laborPerformanceData,
        laborPerformanceWeeklyChartData,
        laborPerformanceBasedOnLineChartData,
        laborPerformanceIsLoading: false,
        laborPerformanceIsLoaded: true,
        columnNames: action.payload.data.columnNames,
      };

    case LaborPerformanceActionTypes.ClearState:
      return { ...laborPerformanceInitialState };

    case LaborPerformanceActionTypes.FetchError:
      const errors = [...state.errors, action.payload];
      return {
        ...state,
        errors,
        isLoaded: false,
        isLoading: false,
        laborPerformanceIsLoading: false,
        commentsIsLoading: false,
        isFiltersLoading: false,
        isFiltersSaving: false,
      };

    default:
      return state;
  }
}
