import { Action } from '@ngrx/store';
import {
  BulkEditKpiTargetsInterface,
  EditKpiTargetInterface,
  KpiTargetBulkResponse,
  KpiTargetInterface,
  KpiTargetTableQueryParams,
} from './kpi-targets.model';
import {
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../../../shared/model/interface/crud-response-interface.model';

export const KPI_TARGETS_DATA_LOADING = '[KPI_TARGETS] KPI TARGETS DATA LOADING';
export const KPI_TARGETS_DATA_LOADED = '[KPI_TARGETS] KPI TARGETS DATA LOADED';
export const KPI_TARGETS_FETCH_ERROR = '[KPI_TARGETS] KPI TARGETS ACTION FETCH ERROR';
export const DELETE_KPI_TARGETS = '[KPI_TARGETS] KPI TARGET DELETE KPI TARGETS';
export const DELETE_KPI_TARGETS_COMPLETED = '[KPI_TARGETS] KPI TARGET DELETE KPI TARGETS COMPLETED';
export const EDIT_KPI_TARGET = '[KPI_TARGETS] EDIT KPI TARGET';
export const EDIT_KPI_TARGET_COMPLETED = '[KPI_TARGETS] EDIT KPI TARGET COMPLETED';
export const BULK_EDIT_KPI_TARGET = '[KPI_TARGETS] BULK EDIT KPI TARGET';
export const BULK_EDIT_KPI_TARGET_COMPLETED = '[KPI_TARGETS] BULK EDIT KPI TARGET COMPLETED';

export class KpiTargetsDataLoading implements Action {
  readonly type = KPI_TARGETS_DATA_LOADING;
  constructor(
    public query: KpiTargetTableQueryParams = {
      page: 1,
      pageSize: 10,
    },
  ) {}
}

export class KpiTargetsDataLoaded implements Action {
  readonly type = KPI_TARGETS_DATA_LOADED;
  constructor(public payload: GetManyResponseInterface<KpiTargetInterface>) {}
}

export class DeleteKpiTargets implements Action {
  readonly type = DELETE_KPI_TARGETS;
  constructor(public payload: BulkEditKpiTargetsInterface[]) {}
}

export class DeleteKpiTargetsCompleted implements Action {
  readonly type = DELETE_KPI_TARGETS_COMPLETED;
  constructor(public isBulk: boolean, public response: GetManyResponseInterface<KpiTargetBulkResponse>) {}
}

export class EditKpiTarget implements Action {
  readonly type = EDIT_KPI_TARGET;
  constructor(public lineId: number, public kpiTarget: EditKpiTargetInterface) {}
}

export class EditKpiTargetCompleted implements Action {
  readonly type = EDIT_KPI_TARGET_COMPLETED;
  constructor(public payload: BaseOneResponseInterface<EditKpiTargetInterface>) {}
}

export class BulkEditKpiTargets implements Action {
  readonly type = BULK_EDIT_KPI_TARGET;
  constructor(public kpiTargets: BulkEditKpiTargetsInterface[]) {}
}

export class BulkEditKpiTargetsCompleted implements Action {
  readonly type = BULK_EDIT_KPI_TARGET_COMPLETED;
  constructor(public payload: GetManyResponseInterface<KpiTargetBulkResponse>) {}
}

export class FetchError implements Action {
  readonly type = KPI_TARGETS_FETCH_ERROR;
  constructor(public payload: object) {}
}

export type KpiTargetsActions =
  | KpiTargetsDataLoading
  | KpiTargetsDataLoaded
  | DeleteKpiTargets
  | DeleteKpiTargetsCompleted
  | EditKpiTarget
  | EditKpiTargetCompleted
  | BulkEditKpiTargets
  | BulkEditKpiTargetsCompleted
  | FetchError;
