import { Injectable } from '@angular/core';
import {
  GetManyResponseInterface,
  BaseOneResponseInterface,
} from '../../shared/model/interface/crud-response-interface.model';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as AppActions from '../app/actions';
import * as oeeAppReducer from '../oee.reducer';
import * as ObjectActions from './actions';
import {
  IDistributionResponse,
  IManualCount,
  ICommentsResponse,
} from '../../view/home/work-order/work-order-manual-count/work-order-manual-count.model';
import { ManualCountService } from './service';
import { ManualCountsReviewData } from './model';
import { mysqlDateFormat } from '../../shared/helper/date';
import { BulkResponse, ResponseInterface } from '../../shared/model/interface/generic-api-response.model';

@Injectable()
export class ManualCountEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly http: HttpClient,
    private readonly service: ManualCountService,
    private store: Store<oeeAppReducer.OeeAppState>,
  ) {}


  saveManualCountComments = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.SAVE_MANUAL_COUNT_COMMENTS),
    switchMap((objectData: ObjectActions.SaveManualCountComments) => {
      return this.service.saveComments(objectData.comments).pipe(
        switchMap((response: GetManyResponseInterface<ICommentsResponse>) => {
          return of(new ObjectActions.ManualCountCommentsSaved(response));
        }),
        catchError((err) => {
          return of(new ObjectActions.FetchDataError(err));
        }),
      );
    }),
    catchError((err) => {
      return of(new ObjectActions.FetchDataError(err));
    }),
  ));

  
  setHourlyCount = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.SET_HOURLY_COUNT),
    switchMap((objectData: ObjectActions.SetHourlyCount) => {
      this.store.dispatch(new AppActions.ShowLoader());

      return from(this.service.setHourlyCount(objectData.manualCount)).pipe(
        switchMap((response: BaseOneResponseInterface<IManualCount>) => {
          return of(new ObjectActions.SetHourlyCountCompleted(response.data), new AppActions.HideLoader());
        }),
        catchError((err) => {
          return of(new ObjectActions.SetHourlyCountFailed(err), new AppActions.HideLoader());
        }),
      );
    }),
    catchError((err) => {
      return of(new ObjectActions.SetHourlyCountFailed(err), new AppActions.HideLoader());
    }),
  ));

  
  setBulkHourlyCount = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.SET_BULK_HOURLY_COUNT),
    switchMap((objectData: ObjectActions.SetBulkHourlyCount) => {
      this.store.dispatch(new AppActions.ShowLoader());

      return from(this.service.setBulkHourlyCount(objectData.manualCounts)).pipe(
        switchMap((response: ResponseInterface<BulkResponse[]>) => {
          return of(new ObjectActions.SetBulkHourlyCountCompleted(response.data));
        }),
        catchError((err) => {
          return of(new ObjectActions.SetBulkHourlyCountFailed(err));
        }),
      );
    }),
    catchError((err) => {
      return of(new ObjectActions.SetHourlyCountFailed(err));
    }),
  ));

  
  createManualCount = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.CREATE_MANUAL_COUNT),
    switchMap((objectData: ObjectActions.CreateManualCount) => {
      this.store.dispatch(new AppActions.ShowLoader());

      return from(this.service.createManualCount(objectData.data)).pipe(
        switchMap((response: BaseOneResponseInterface<IManualCount>) => {
          if (objectData.isCalledFromSetHourlyCount) {
            return of(new ObjectActions.SetHourlyCountCompleted(response.data));
          }

          return of(new ObjectActions.CreateManualCountCompleted(response.data));
        }),
        catchError((err) => {
          if (objectData.isCalledFromSetHourlyCount) {
            return of(new ObjectActions.SetHourlyCountFailed(err));
          }

          return of(new ObjectActions.CreateManualCountFailed(err));
        }),
      );
    }),
    catchError((err) => {
      return of(new ObjectActions.CreateManualCountFailed(err));
    }),
  ));

  
  getManualCountsData = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.MANUAL_COUNT_REVIEW_DATA_LOADING),
    switchMap((objectData: ObjectActions.ManualCountReviewDataLoading) => {
      let params: HttpParams = new HttpParams()
        .append('join', 'schedulerShift')
        .append('join', 'workOrderSchedule')
        .append('page', objectData.tableQuery.page ?? 1)
        .append('limit', objectData.tableQuery.rowsPerPage ?? 1000)
        .append('sort', 'id,DESC');

      const filter: object = {};

      if (Array.isArray(objectData.tableQuery.workOrderId)) {
        filter['work_order_id'] = { $in: objectData.tableQuery.workOrderId };
      }

      if (Array.isArray(objectData.tableQuery.shifts)) {
        filter['shift_id'] = { $in: objectData.tableQuery.shifts };
      }

      if (objectData.tableQuery.dateRange.startDate && objectData.tableQuery.dateRange.endDate) {
        const startDate: string = objectData.tableQuery.dateRange.startDate.format(mysqlDateFormat);
        const endDate: string = objectData.tableQuery.dateRange.endDate.format(mysqlDateFormat);
        filter['created_at'] = { $between: [startDate, endDate] };
      }

      params = params.append('s', JSON.stringify(filter));
      this.store.dispatch(new AppActions.ShowLoader());
      return this.service.getManualCountsData(params).pipe(
        switchMap((response: GetManyResponseInterface<ManualCountsReviewData>) => {
          return of(new ObjectActions.ManualCountReviewDataLoaded(response));
        }),
        catchError((err) => {
          return of(new ObjectActions.FetchDataError(err), new AppActions.HideLoader());
        }),
      );
    }),
  ));

  
  getDistribution = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.MANUAL_COUNT_GET_DISTRIBUTION_LOADING),
    switchMap((objectData: ObjectActions.ManualCountGetDistributionLoading) => {
      this.store.dispatch(new AppActions.ShowLoader());

      const httpParams: HttpParams = new HttpParams()
        .append('goodCount', objectData.params.goodCount)
        .append('scrapCount', objectData.params.scrapCount)
        .append('workOrderId', objectData.params.workOrderId);

      return from(this.service.getDistribution(httpParams)).pipe(
        switchMap((response: GetManyResponseInterface<IDistributionResponse>) => {
          return of(new ObjectActions.ManualCountGetDistributionLoaded(response.data), new AppActions.HideLoader());
        }),
        catchError((err) => {
          return of(new ObjectActions.FetchDataError(err), new AppActions.HideLoader());
        }),
      );
    }),
    catchError((err) => {
      return of(new ObjectActions.FetchDataError(err), new AppActions.HideLoader());
    }),
  ));

  
  setDistribution = createEffect(() => this.actions$.pipe(
    ofType(ObjectActions.MANUAL_COUNT_SET_DISTRIBUTION_LOADING),
    switchMap((objectData: ObjectActions.ManualCountSetDistributionLoading) => {
      this.store.dispatch(new AppActions.ShowLoader());
      return from(this.service.setDistribution(objectData.params)).pipe(
        switchMap(() => {
          const actions: Action[] = [
            new ObjectActions.ManualCountSetDistributionLoaded(),
            ...(objectData.hideLoaderAfterSuccess ? [new AppActions.HideLoader()] : []),
          ];
          return of(...actions);
        }),
        catchError((err) => {
          return of(new ObjectActions.FetchDataError(err), new AppActions.HideLoader());
        }),
      );
    }),
    catchError((err) => {
      return of(new ObjectActions.FetchDataError(err), new AppActions.HideLoader());
    }),
  ));
}
