import { DigitalFormActions, ActivityButtonActionTypes } from './digital-form.actions';
import { DigitalFormStateInterface } from './digital-form.model';

export const digitalFormInitialState: DigitalFormStateInterface = {
  getTokenIsLoading: false,
  getTokenIsLoaded: false,
  token: null,
};

export function digitalFormReducer(
  state: DigitalFormStateInterface = digitalFormInitialState,
  action: DigitalFormActions,
) {
  switch (action.type) {
    case ActivityButtonActionTypes.DigitalFormsTokenLoad:
      return {
        ...state,
        getTokenIsLoading: true,
        getTokenIsLoaded: false,
      };
    case ActivityButtonActionTypes.DigitalFormsTokenLoaded:
      return {
        ...state,
        getTokenIsLoading: false,
        getTokenIsLoaded: true,
        token: action.token,
      };
    case ActivityButtonActionTypes.FetchError:
      return {
        ...state,
        getTokenIsLoading: false,
        getTokenIsLoaded: true,
      };
    default:
      return state;
  }
}
