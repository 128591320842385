import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import {
  PresetInterface,
  PresetItemsInterface,
  PresetLinesInterface,
} from '../../../store/scheduler-resource-setting/scheduler-resource-setting.model';
import {
  BaseOneResponseInterface,
  BulkResponseDataInterface,
  GetManyResponseInterface,
} from '../../model/interface/crud-response-interface.model';

@Injectable({
  providedIn: 'root',
})
export class SchedulerResourceSettingService {
  constructor(public http: HttpClient, @Inject('API_BASE_URL') private baseUrl: string) {}

  private RESOURCE_SETTINGS = {
    GET: {
      DATA_URL: '/scheduler/settings',
    },
  };

  private RESOURCE_SETTINGS_LINES = {
    BULK: {
      CREATE: '/scheduler/setting-lines/bulk/create',
      UPDATE: '/scheduler/setting-lines/bulk/edit',
      DELETE: '/scheduler/setting-lines/bulk/delete',
    },
  };

  private RESOURCE_SETTING_ITEMS = {
    GET: {
      DATA_URL: '/scheduler/setting-items',
    },
  };

  getResourceSettings(httpParams: HttpParams): Observable<GetManyResponseInterface<PresetInterface>> {
    return this.http.get<GetManyResponseInterface<PresetInterface>>(
      `${this.baseUrl}${this.RESOURCE_SETTINGS.GET.DATA_URL}`,
      {
        params: httpParams,
      },
    );
  }

  getResourceSetting(id: number, httpParams: HttpParams): Observable<BaseOneResponseInterface<PresetInterface>> {
    return this.http.get<BaseOneResponseInterface<PresetInterface>>(
      `${this.baseUrl}${this.RESOURCE_SETTINGS.GET.DATA_URL}/${id}`,
      {
        params: httpParams,
      },
    );
  }

  deleteResourceSetting(id: number): Observable<BaseOneResponseInterface<PresetInterface>> {
    return this.http.delete<BaseOneResponseInterface<PresetInterface>>(
      `${this.baseUrl}${this.RESOURCE_SETTINGS.GET.DATA_URL}/${id}`,
    );
  }

  createResourceSetting(name: string): Observable<BaseOneResponseInterface<PresetInterface>> {
    return this.http.post<BaseOneResponseInterface<PresetInterface>>(
      `${this.baseUrl}${this.RESOURCE_SETTINGS.GET.DATA_URL}`,
      {
        name,
      },
    );
  }

  createResourceSettingLines(body: { schSettingLines: PresetLinesInterface[] }): Observable<BulkResponseDataInterface> {
    if (Array.isArray(body.schSettingLines) && body.schSettingLines.length) {
      return this.http.post<BulkResponseDataInterface>(
        `${this.baseUrl}${this.RESOURCE_SETTINGS_LINES.BULK.CREATE}`,
        body,
      );
    }
    return of(null);
  }

  createResourceSettingItems(body: PresetItemsInterface): Observable<BaseOneResponseInterface<PresetItemsInterface>> {
    return this.http.post<BaseOneResponseInterface<PresetItemsInterface>>(
      `${this.baseUrl}${this.RESOURCE_SETTING_ITEMS.GET.DATA_URL}`,
      body,
    );
  }

  updateResourceSetting(id: number, name: string): Observable<BaseOneResponseInterface<PresetInterface>> {
    return this.http.patch<BaseOneResponseInterface<PresetInterface>>(
      `${this.baseUrl}${this.RESOURCE_SETTINGS.GET.DATA_URL}/${id}`,
      { name },
    );
  }

  updateResourceSettingItems(
    id: number,
    body: PresetItemsInterface,
  ): Observable<BaseOneResponseInterface<PresetItemsInterface>> {
    return this.http.patch<BaseOneResponseInterface<PresetItemsInterface>>(
      `${this.baseUrl}${this.RESOURCE_SETTING_ITEMS.GET.DATA_URL}/${id}`,
      body,
    );
  }

  updateResourceSettingLines(body: {
    schSettingLines: Partial<PresetLinesInterface>[];
  }): Observable<BulkResponseDataInterface> {
    if (Array.isArray(body.schSettingLines) && body.schSettingLines.length) {
      return this.http.patch<BulkResponseDataInterface>(
        `${this.baseUrl}${this.RESOURCE_SETTINGS_LINES.BULK.UPDATE}`,
        body,
      );
    }

    return of(null);
  }

  deleteResourceSettingLines(ids: number[]): Observable<BulkResponseDataInterface> {
    if (Array.isArray(ids) && ids.length) {
      const httpOptions = {
        body: { schSettingLines: ids },
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      };

      return this.http.delete<BulkResponseDataInterface>(
        `${this.baseUrl}${this.RESOURCE_SETTINGS_LINES.BULK.DELETE}`,
        httpOptions,
      );
    }
    return of(null);
  }
}
