import { BulkResponseDataInterface } from '../../../store/work-order-schedule/work-order-schedule.model';

export interface WorkOrderDeleteResponseInterface {
  data?: BulkResponseDataInterface[];
  date: Date;
  success: boolean;
  message?: string;
}

export enum CustomDependFilterKeysEnum {
  FILTER_DATE_RANGE = 'filterDateRange',
  FILTER_DATE_TYPE = 'filterDateType',
  FILTER_START_DATE = 'filterStartDate',
  FILTER_END_DATE = 'filterEndDate',
  SITE_ID = 'siteId',
  LINE_IDS = 'lineIds',
  ACTIVITY_IDS = 'activityIds',
  COMPLETE_STATUS = 'completeStatus',
  PRODUCT_ID = 'productId',
  SEARCH_TYPE = 'searchType',
  SEARCH = 'search',
  EQUIPMENT_IDS = 'equipmentIds',
  TASK_IDS = 'taskIds',
  TASK_TITLES = 'taskTitles',
  SHIFT_IDS = 'shiftIds',
  DATE_RANGE = 'dateRange',
  STATUS_IDS = 'statusIds',
}
