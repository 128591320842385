import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { forkJoin, Observable, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { GetManyResponseInterface } from '../../model/interface/crud-response-interface.model';
import {
  IFilterSettings,
  ILinesReportInterface,
  IWidgetDataInterface,
  LineInterface,
  SiteInterface,
} from '../../../store/line-view-work-order-summary/line-view-work-order-summary.model';
import {
  CreateExcelInterface,
  CreateExcelSheetInterface,
  ExcelHelperService,
  ExcelSheetTypeEnum,
} from '../excel/excel-helper.service';
import * as ObjectActions from '../../../store/work-order-schedule/work-order-schedule.actions';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../../../store/oee.reducer';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment-timezone';
import { PageFiltersAndSettingsService } from '../page-filters-and-settings/page-filters-and-settings.service';
import {
  IComponentConfiguration,
  UserConfigurationStateInterface,
} from '../../../store/user-configuration/user-configuration.model';
import * as AppActions from '../../../store/app/actions';

export const phaseTargetColumnNames = {
  preRunPercentage: 'preRunVersusTargetPercentage',
  runPercentage: 'runVersusTargetPercentage',
  postRunPercentage: 'postRunVersusTargetPercentage',
  preRunNumerical: 'preRunVersusTargetNumerical',
  runNumerical: 'runVersusTargetNumerical',
  postRunNumerical: 'postRunVersusTargetNumerical',
};

@Injectable({
  providedIn: 'root',
})
export class LineViewWorkOrderSummaryService {
  private readonly LINE_VIEW = {
    GET: {
      SITE_URL: `${this.baseUrl}/sites`,
      LINE_URL: `${this.baseUrl}/lines`,
      LINE_REPORT_URL: `${this.baseUrl}/lines/reportsExtend`,
    },
  };
  private readonly defaultTable: string[] = [
    'siteName',
    'departmentName',
    'lineName',
    'lineState',
    'activityName',
    'activityStartTime',
    'activityDuration',
    'workOrderName',
    'workOrderPhase',
    'workOrderPhaseDuration',
    'workOrderGoodCount',
    'averageSpeed',
    'workOrderDuration',
    'runTimeAverageSpeed',
    'scrapQuantity',
    'activitySpeed',
    'workOrderSpeed',
    'workOrderScrapCount',
    'oeeCalculationStatus',
  ];
  private readonly tableOptions: string[] = [
    ...this.defaultTable,
    ...Object.values(phaseTargetColumnNames),
    'workOrderPhaseStart',
    'operatorName',
    'taskIssueName',
    'todayOEE',
    'availability',
    'performance',
    'quality',
    'productId',
    'productDescription',
    'PreRunDuration',
    'RunDuration',
    'PostRunDuration',
    'nameOfCheckInUsers',
    'numberOfCheckInUsers',
    'nameOfCheckInAssets',
    'numberOfCheckInAssets',
    'nameOfCheckInMaintenanceUsers',
  ];
  private readonly defaultWidgets: string[] = ['runningLines', 'downLines', 'idleLines', 'todayOEE'];
  private readonly widgetOptions: string[] = [...this.defaultWidgets, 'totalNumberOfUsers', 'totalNumberOfAssets'];
  private timezone = 'utc';
  private dateFormat: string;
  private timeFormat: string;
  private readonly destroySubject: Subject<boolean> = new Subject<boolean>();

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly excelHelper: ExcelHelperService,
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly translate: TranslateService,
  ) {
    this.store
      .select('user')
      .pipe(takeUntil(this.destroySubject))
      .subscribe((state) => {
        if (state.isUserLoaded) {
          this.timezone = state.timezone;
          this.dateFormat = state.dateFormat;
          this.timeFormat = state.timeFormat;
          this.destroySubject.next(true);
          this.destroySubject.complete();
        }
      });
  }

  getFiltersAndSettings(userId: number): Observable<{ data: IWidgetDataInterface }> {
    const baseHttpParams = new HttpParams().append('limit', '1000');
    const observables: Observable<
      GetManyResponseInterface<SiteInterface> | GetManyResponseInterface<LineInterface>
    >[] = [
      this.http.get<GetManyResponseInterface<SiteInterface>>(this.LINE_VIEW.GET.SITE_URL, {
        params: baseHttpParams.append('s', JSON.stringify({ status: 1 })),
      }),
      this.http.get<GetManyResponseInterface<LineInterface>>(this.LINE_VIEW.GET.LINE_URL, {
        params: baseHttpParams.append('join', 'lineTypeName').append('s', JSON.stringify({ status: 1 })),
      }),
    ];

    return forkJoin(observables).pipe(
      map((array) => {
        let configuration: IComponentConfiguration[] | undefined = [];
        this.store
          .select('userConfigurationStore')
          .pipe(take(1))
          .subscribe((state: UserConfigurationStateInterface) => {
            configuration = state.userConfigurationData.LineViewWorkOrderSummaryComponent;
          });
        const [sitesResponse, linesResponse] = array;

        let filterObject: IFilterSettings = {
          table: this.defaultTable,
          widgets: this.defaultWidgets,
        };

        if (configuration !== undefined) {
          const filters = configuration.find((conf) => conf.name === 'filters');

          const sitesConfiguration =
            filters === undefined ? undefined : filters.children.find((conf) => conf.name === 'siteIds');

          const linesConfiguration =
            filters === undefined ? undefined : filters.children.find((conf) => conf.name === 'lineIds');

          const widgetSettings = configuration.find((conf) => conf.name === 'widgetSettings');

          const tableColumns = configuration.find((conf) => conf.name === 'tableColumns');

          filterObject = Object.assign(filterObject, {
            filterLineId: linesConfiguration === undefined ? null : linesConfiguration.value,
            filterSiteId: sitesConfiguration === undefined ? null : sitesConfiguration.value,
            table: tableColumns === undefined ? this.defaultTable : tableColumns.value,
            widgets: widgetSettings === undefined ? this.defaultWidgets : widgetSettings.value,
          });
        } else {
          filterObject = Object.assign(filterObject, {
            filterLineId: linesResponse.data.map((line) => {
              return line.id;
            }),
            filterSiteId: sitesResponse.data.map((site) => {
              return site.id;
            }),
            table: this.defaultTable,
            widgets: this.defaultWidgets,
          });
        }

        return {
          data: {
            sites: filterObject.filterSiteId,
            lines: filterObject.filterLineId,
            table: PageFiltersAndSettingsService.getObjectConfigurationOfUser(filterObject.table, this.tableOptions),
            widgets: PageFiltersAndSettingsService.getObjectConfigurationOfUser(
              filterObject.widgets,
              this.widgetOptions,
            ),
          },
        };
      }),
    );
  }

  getLineViewData(params: HttpParams): Observable<ILinesReportInterface> {
    return this.http.get<ILinesReportInterface>(this.LINE_VIEW.GET.LINE_REPORT_URL, {
      params,
    });
  }

  public downloadLineViewExcel(excelCreateOptions: CreateExcelInterface, mode: string): void {
    const title = this.translate.instant('pageTitles.line_view');
    const fileName = `${title} ${moment()
      .tz(this.timezone)
      .format(this.dateFormat)}`;

    const worksheets: CreateExcelSheetInterface[] = [
      {
        sheetTitle: title,
        sheetType: ExcelSheetTypeEnum.TABLE,
        params: excelCreateOptions,
        withData: true,
      },
    ];

    this.excelHelper
      .createExcel(fileName, worksheets, this.timezone, this.dateFormat, this.timeFormat, mode === 'csv')
      .then(
        () => {
          this.store.dispatch(new ObjectActions.DownloadExcelCompleted());
          this.store.dispatch(new AppActions.HideLoader());
        },
        () => {
          this.store.dispatch(new ObjectActions.FetchError({}));
          this.store.dispatch(new AppActions.HideLoader());
        },
      );
  }
}
