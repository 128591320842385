import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IOeeCalculationRequest, IOeeCalculationResult } from '../work-order-performance/work-order-performance.model';

@Injectable({
  providedIn: 'root',
})
export class OeeCalendarService {
  constructor(public http: HttpClient, @Inject('API_BASE_URL') private readonly api: string) {}

  private readonly routes = {
    oeeCalendar: '/oee-calculation/calculate-oee',
  };

  public getData(params: IOeeCalculationRequest): Observable<GetManyResponseInterface<IOeeCalculationResult>> {
    return this.http.post<GetManyResponseInterface<IOeeCalculationResult>>(
      `${this.api}${this.routes.oeeCalendar}`,
      params,
      {
        headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
      },
    );
  }
}
