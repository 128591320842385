import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResponseArrayInterface } from '../../../model/interface/generic-api-response.model';
import { IActivityHistory, ILine, IWorkOrder } from './daily-performance-summary.model';
import { STATIC_MAX_LIMIT_OF_CRUD } from '../../../../../constants';
import {
  DailyPerformanceSummaryFilterInterface,
  IDailyPerformanceWorkOrderRequest,
} from '../../../../store/reports/daily-performance-summary/daily-performance-summary.model';
import { mysqlTimestampFormat } from '../../../helper/date';

@Injectable({
  providedIn: 'root',
})
export class DailyPerformanceSummaryService {
  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL')
    private readonly api: string,
  ) {}

  private readonly routes = {
    activityHistory: `${this.api}/activity-histories`,
    workOrder: `${this.api}/work-orders`,
    lines: `${this.api}/lines`,
  };

  private readonly linesDefaultParams: HttpParams = new HttpParams()
    .append('join', 'currentWorkOrder')
    .append('join', 'currentActivity');

  public getLines(search: object): Observable<ResponseArrayInterface<ILine>> {
    const params: HttpParams = this.linesDefaultParams.set('s', JSON.stringify(search));
    return this.http.get<ResponseArrayInterface<ILine>>(this.routes.lines, {
      params,
    });
  }

  private readonly activityHistoryDefaultParams: HttpParams = new HttpParams()
    .append('join', 'shift')
    .append('join', 'line')
    .append('join', 'workOrder')
    .append('join', 'activity')
    .append('join', 'productsLaborsAssetsHour');

  public getActivities(
    search: object,
    page: number = 1,
    limit: number = STATIC_MAX_LIMIT_OF_CRUD,
  ): Observable<ResponseArrayInterface<IActivityHistory>> {
    const params: HttpParams = this.activityHistoryDefaultParams
      .append('join', 'workOrder.runningLine')
      .set('s', JSON.stringify(search))
      .set('page', String(page))
      .set('limit', String(limit))
      .set('withEntityTranslation', true);
    return this.http.get<ResponseArrayInterface<IActivityHistory>>(this.routes.activityHistory, {
      params,
    });
  }

  public getDailyPerformanceObservables(
    paramsForLineAndActivityHistory: DailyPerformanceSummaryFilterInterface,
    paramsForWorkOrder: IDailyPerformanceWorkOrderRequest,
  ): Observable<
    ResponseArrayInterface<IActivityHistory> | ResponseArrayInterface<ILine> | ResponseArrayInterface<IWorkOrder>
  >[] {
    return [
      this.getLines({
        workOrderId: paramsForLineAndActivityHistory.workOrderId,
      }),
      this.getActivities({
        workOrderId: paramsForLineAndActivityHistory.workOrderId,
        siteId: paramsForLineAndActivityHistory.siteId,
      }),
      this.getWorkOrder(paramsForWorkOrder),
    ];
  }

  private readonly workOrderDefaultParams: HttpParams = new HttpParams()
    .append('join', 'product')
    .append('join', 'actualLineDetail');

  public getWorkOrder(search: object): Observable<ResponseArrayInterface<IWorkOrder>> {
    const params: HttpParams = this.workOrderDefaultParams.set('s', JSON.stringify(search)).set('getCounts', 'true');
    return this.http.get<ResponseArrayInterface<IWorkOrder>>(this.routes.workOrder, {
      params,
    });
  }

  public formatLineData(activities: ILine[]): IActivityHistory[] {
    const formattedActivities: IActivityHistory[] = [];

    for (const activity of activities) {
      const formattedActivity: IActivityHistory = {
        id: activity.id,
        phaseId: activity.phaseId,
        siteId: activity.siteId,
        lineId: activity.id,
        line: { id: null, title: activity.title },
        start: activity.timer,
        end: null,
        activityId: activity.selectedActivity,
        activity: activity.currentActivity,
        taskId: activity.selectedTaskId,
        workOrder: activity.currentWorkOrder,
        crewSize: activity.selectedCrewSize,
        description: activity.selectedDescription,
        shift: null,
        userId: activity.activeUser,
        batchNumber: null,
        shiftDay: activity.shiftDay,
        shiftId: activity.shiftId,
        shiftPlanId: activity.shiftPlanId,
        startDate: activity.timer,
        endDate: null,
      };

      formattedActivities.push(formattedActivity);
    }

    return formattedActivities;
  }
}
