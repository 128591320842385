import { SensorTypeStateInterface } from './sensor-type.model';
import { FilterSensorTypeActions, FilterSensorTypeActionTypes } from './sensor-type.actions';

export const initialFilterSensorTypeState: SensorTypeStateInterface = {
  isLoaded: false,
  isLoading: false,
  data: [],
};

export function filterSensorTypeReducer(
  state: SensorTypeStateInterface = initialFilterSensorTypeState,
  action: FilterSensorTypeActions,
): SensorTypeStateInterface {
  switch (action.type) {
    case FilterSensorTypeActionTypes.StartLoadFilterSensorTypes:
      return { ...state, isLoading: true };
    case FilterSensorTypeActionTypes.FilterSensorTypesLoaded:
      return { ...state, data: action.data, isLoaded: true, isLoading: false };
    default:
      return state;
  }
}
