import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import * as ObjectActions from './performance-deep-dive.actions';
import { of } from 'rxjs';
import { PerformanceDeepDiveService } from './performance-deep-dive.service';
import {
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { ActivityLogsResponseInterface } from '../root-cause-analysis/root-cause-analysis.model';
import { IProductionPerformanceDeepDiveResponse, IRootCauseAnalysisData } from './performance-deep-dive.model';

@Injectable()
export class PerformanceDeepDiveEffects {
  constructor(private readonly actions$: Actions, public service: PerformanceDeepDiveService) {}

  getActivityLogs = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.PerformanceDeepDiveActionTypes.PerformanceDeepDiveActivityLogsLoading),
      switchMap((objectData: ObjectActions.PerformanceDeepDiveActivityLogsLoading) => {
        return this.service.getActivityLogs(objectData?.params).pipe(
          switchMap((response: BaseOneResponseInterface<ActivityLogsResponseInterface>) => {
            return of(new ObjectActions.PerformanceDeepDiveActivityLogsLoaded(response.data.rows));
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchError(err));
          }),
        );
      }),
      catchError((err) => {
        return of(new ObjectActions.FetchError(err));
      }),
    ),
  );

  getProductionDeepDiveData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.PerformanceDeepDiveActionTypes.ProductionPerformanceDeepDiveDataLoading),
      switchMap((objectData: ObjectActions.ProductionPerformanceDeepDiveDataLoading) => {
        return this.service.getProductionPerformanceDeepDiveData(objectData.lineId, objectData?.params).pipe(
          switchMap((response: BaseOneResponseInterface<IProductionPerformanceDeepDiveResponse>) => {
            return of(new ObjectActions.ProductionPerformanceDeepDiveDataLoaded(response.data));
          }),
          catchError((err) => {
            return of(new ObjectActions.FetchError(err));
          }),
        );
      }),
      catchError((err) => {
        return of(new ObjectActions.FetchError(err));
      }),
    ),
  );
}
