import { Action } from '@ngrx/store';
import { ObservationCRUDInterface } from '../../../shared/component/filter/filter.class';
import { HttpOptionInterface } from '../filter.model';
import { HttpErrorResponse } from '@angular/common/http';

export enum FilterObservationActionTypes {
  StartLoadFilterObservations = '[Filter Observation] Load Filter Observations',
  FilterObservationsLoaded = '[Filter Observation] Filter Observations Loaded',
  ResetObservationStore = '[Filter Observation] Reset data stored in line store',
  FetchError = '[Filter Observation] Fetch Error',
}

export class StartLoadFilterObservations implements Action {
  readonly type = FilterObservationActionTypes.StartLoadFilterObservations;

  constructor(public options: HttpOptionInterface) {}
}

export class FilterObservationsLoaded implements Action {
  readonly type = FilterObservationActionTypes.FilterObservationsLoaded;

  constructor(public data: ObservationCRUDInterface[]) {}
}

export class ResetObservationStore implements Action {
  readonly type = FilterObservationActionTypes.ResetObservationStore;

  constructor() {}
}

export class FetchError implements Action {
  readonly type = FilterObservationActionTypes.FetchError;

  constructor(public payload: HttpErrorResponse) {}
}

export type FilterObservationActions =
  | StartLoadFilterObservations
  | FilterObservationsLoaded
  | ResetObservationStore
  | FetchError;
