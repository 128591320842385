import { FilterWorkOrderActions, FilterWorkOrderActionTypes } from './work-order.actions';
import { WorkOrderCRUDInterface } from '../../../shared/component/filter/filter.class';

export interface FilterWorkOrderState {
  isLoading: boolean;
  isLoaded: boolean;
  data: WorkOrderCRUDInterface[];
}

export const initialFilterWorkOrderState: FilterWorkOrderState = {
  isLoaded: false,
  isLoading: false,
  data: [],
};

export function filterWorkOrderReducer(
  state = initialFilterWorkOrderState,
  action: FilterWorkOrderActions,
): FilterWorkOrderState {
  switch (action.type) {
    case FilterWorkOrderActionTypes.StartLoadFilterWorkOrders:
      return { ...state, isLoading: true, isLoaded: false };

    case FilterWorkOrderActionTypes.FilterWorkOrdersLoaded:
      return { ...state, data: action.data, isLoaded: true, isLoading: false };

    case FilterWorkOrderActionTypes.AppendWorkOrder:
      if (!state.data.find((workOrder: WorkOrderCRUDInterface) => workOrder.id === action.workOrder.id)) {
        state.data.push(action.workOrder);
      }
      return state;

    case FilterWorkOrderActionTypes.ResetWorkOrderStore:
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
        data: [],
      };
    default:
      return state;
  }
}
